/** @format */

import { VegaUserStatus } from './user';

export interface IUsersProps {
  clientId: any;
  name: string;
  mobileNumber: string;
  email: string;
  address: string;
  idType: string;
  idNumber: string;
  status: string;
  team: string;
}

export interface IUserPaginatedReqProps {
  clientId: string;
  page: number;
  size: number;
}

export interface IAccessProps {
  programRoles?: IProgramRoles[];
  feature: string;
}

export interface IRolesProps {
  programId?: string;
  clientId: any;
  roleName: string;
  accessFeatures: IAccessProps[];
  createdBy: string;
  status: string;
}

export interface IProgramRoles {
  roles: string[];
  programId: string;
}

export enum VegaTeamType {
  OPERATIONS = 'OPERATIONS',
  COLLECTIONS = 'COLLECTIONS',
  FINANCE = 'FINANCE',
  SETTLEMENT = 'SETTLEMENT',
  PRODUCT_MANAGEMENT = 'PRODUCT_MANAGEMENT',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  DESIGN = 'DESIGN',
  DEVELOPER = 'DEVELOPER',
  CXOS = 'CXOS',
  MARKETING_AND_BRANDING = 'MARKETING_AND_BRANDING',
  SUPPORT = 'SUPPORT',
  BUSINESS_INTELLIGENCE = 'BUSINESS_INTELLIGENCE',
  BUSINESS = 'BUSINESS',
  RISK = 'RISK',
  AGENCY_COLLECTION = 'AGENCY_COLLECTION',
  AGENCY_SUPPORT = 'AGENCY_SUPPORT',
  AGENCY_OPERATIONS = 'AGENCY_OPERATIONS',
}

export interface AddCognitoUserForClientParams {
  clientId: any;
  name: string;
  mobileNumber?: string;
  email: string;
  status: string;
}

export interface UpdateUserStatusParams {
  userId: string;
  status: VegaUserStatus;
}
