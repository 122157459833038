import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { VegaProgramType } from '../../types/program';
import { AppState } from '..';
import { STORE_SLICE } from '../../enums/commons';

type SimulatorState = {
  selectedProgram?: VegaProgramType;
};

const initialState: SimulatorState = {};

const SimulatorSlice = createSlice({
  name: STORE_SLICE.SIMULATOR,
  initialState,
  reducers: {
    updateProgramOnSimulator: (
      state: SimulatorState,
      action: PayloadAction<VegaProgramType | undefined>
    ) => {
      state.selectedProgram = action.payload;
    },
  },
});

export const { updateProgramOnSimulator } = SimulatorSlice.actions;
export const getSimulatorState = (state: AppState) => state.simulator;
export default SimulatorSlice;
