/** @format */

import { ExpandMore } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { fetchAllEffectivePolicesForProgram } from '../../api/policy';
import { INewProgramState } from '../../pages/ClientDashBoard';
import { AnyPolicyType } from '../../types/policy';
import { VegaAccordian, VegaTextField } from '../common';
import VegaFadeWrapper from '../common/v2/VegaFadeWrapper';
import VegaDatePicker from '../common/VegaDatePicker';
import KeyDataPoints from './KeyDataPoints';

interface IEditProgramProps {
  state: INewProgramState;
  handleChange: (name: string, value: any) => void;
}

const EditProgram = ({ state, handleChange }: IEditProgramProps) => {
  const [policies, setPolicies] = useState<AnyPolicyType[]>([]);
  const [loadingPolicies, setLoadingPolicies] = useState<boolean>();
  const fetchProgramPolicies = async () => {
    setLoadingPolicies(true);
    fetchAllEffectivePolicesForProgram(state.id)
      .then((res) => {
        const policiesForProgram = res.data as AnyPolicyType[];
        setPolicies(policiesForProgram);
      })
      .catch((err) => {})
      .finally(() => setLoadingPolicies(false));
  };

  console.log('State value in side drawer', state);

  const PolicyCard = (data: { policy: AnyPolicyType }) => {
    return (
      <Box sx={{ px: 1, py: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <VegaTextField
              value={_.startCase(_.toLower(data.policy.policy_name))}
              disabled
              key="name"
              label="Name"
              type={'text'}
              placeholder="Program Name"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <VegaTextField
              value={_.startCase(_.toLower(data.policy.policy_type))}
              disabled
              key="category"
              label="Category"
              type={'text'}
              placeholder="Category"
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const NoPolicyCard = () => {
    return (
      <Box sx={{ px: 1, py: 2 }}>
        <Grid container spacing={2}>
          <Typography>No Policies configured</Typography>
        </Grid>
      </Box>
    );
  };

  const PolicyLoadingCard = () => {
    return (
      <Box sx={{ px: 1, py: 2 }}>
        <Grid container spacing={2}>
          <CircularProgress />
        </Grid>
      </Box>
    );
  };

  const PlanCharterView = () => {
    return (
      <div>
        {loadingPolicies && <PolicyLoadingCard />}
        {loadingPolicies == false && policies.length <= 0 && <NoPolicyCard />}
        {policies.map((item) => (
          <PolicyCard policy={item} />
        ))}
      </div>
    );
  };

  const hasCobrandPartner = () => state.cobrandName != null;

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <VegaAccordian
          expandIcon={<ExpandMore fontSize="small" />}
          // expanded={expanded === kyc.heading}
          // onChange={handleChange(kyc.heading)}
          header={'Program Details'}
          content={
            <Box sx={{ px: 1, py: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <VegaTextField
                    value={state?.text ?? ''}
                    disabled
                    key="name"
                    label="Name"
                    type={'text'}
                    placeholder="Program Name"
                  />
                </Grid>
                {Boolean(state?.programCode) && (
                  <Grid item xs={12} sm={12}>
                    <VegaTextField
                      value={state?.programCode ?? '--'}
                      disabled
                      key="programCode"
                      label="Program Code"
                      type={'text'}
                      placeholder="Program Code"
                    />
                  </Grid>
                )}
                <VegaFadeWrapper show={hasCobrandPartner()}>
                  <Grid item xs={12} sm={6}>
                    <VegaTextField
                      value={state?.cobrandName ?? ''}
                      key="partner"
                      label="Co-branding Partner"
                      type={'text'}
                      disabled
                      placeholder="Co-branding Partner"
                    />
                  </Grid>
                </VegaFadeWrapper>
                <Grid item xs={12} sm={hasCobrandPartner() ? 6 : 12}>
                  <VegaDatePicker
                    value={state?.date ?? ''}
                    onChange={(e) => {}}
                    disabled
                    key="date"
                    label="Creation Date"
                    placeholder="Creation Date"
                  />
                </Grid>
              </Grid>
            </Box>
          }
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <VegaAccordian
          expandIcon={<ExpandMore fontSize="small" />}
          // expanded={expanded === kyc.heading}
          // onChange={handleChange(kyc.heading)}
          onChange={(e: any, expanded: boolean) => {
            if (expanded) {
              fetchProgramPolicies();
            }
          }}
          header={'Plan Charter'}
          content={<PlanCharterView />}
        />
      </Box>
      {state.status !== 'DRAFT' && (
        <Box sx={{ mb: 3 }}>
          <VegaAccordian
            expandIcon={<ExpandMore fontSize="small" />}
            header={'Key Data Points - Current Month'}
            content={<KeyDataPoints programId={state.id} />}
          />
        </Box>
      )}
    </Box>
  );
};

export default EditProgram;
