import { Stack } from '@mui/material';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';
import VegaTableV4 from '../../common/v4/VegaTableV4';
import VegaTextfieldV4 from '../../common/v4/VegaTextfieldV4';
import VegaSelectV4 from '../../common/v4/VegaSelectV4';
import { MouseEvent, useEffect, useState } from 'react';
import { useKeyValHook } from '../../../hooks/useKeyValHook';
import { DATE_FORMATE_SHORT } from '../../../constants/url';
import dayjs from 'dayjs';
import { getDateInputIcons } from '../../../utils/common.utils';
import { pascalCaseFromCamelCase } from '../../../utils/stringUtils';
import VegaDateRangePickerPopoverV4 from '../../common/v4/VegaDateRangePickerPopoverV4';
import { PhysicalStatementStatus } from '../../../types/bill';
import { usePhysicalStatementListQuery } from '../../../store/queries/billQueries';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import VegaNoResults from '../../common/VegaNoResults';
import { getPhysicalStatementStructuredData } from '../Support_New/supportUtils';
import useDateRangeStandardHook from '../../../hooks/useDateRangeStandardHook';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import { updateBulkPhysicalStatementStatus } from '../../../api/bill';
import { useSnackbar } from '../../../providers/SnackbarProvider';

interface IPSFilter {
  date?: Date;
  status?: PhysicalStatementStatus | 'All';
  search?: string;
  startDate?: string;
  endDate?: string;
}

type Keys = 'status' | 'search' | 'startDate' | 'endDate';

type btnNums = 1 | 2 | 3 | 4 | 5 | 6 | 7;

const PhysicalStatementOperations = () => {
  const { selectedProgram } = useProgramData();
  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const [btn2Update, setBtn2Update] = useState<btnNums>(1);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [requestIndexList, setRequestIndexList] = useState<number[]>([]);
  const [refreshNum, setRefreshNum] = useState(0);

  const updatePage2Zero = () => {
    setPage(0);
  };

  const { updateValue, values } = useKeyValHook<IPSFilter, Keys>(
    {
      status: 'All',
      search: undefined,
    },
    updatePage2Zero
  );

  const updateRecord = (key: Keys) => (e: any) => {
    updateValue(
      key,
      key === 'search' && e.target.value === ''
        ? undefined
        : (e.target.value as string)
    );
  };

  const statusList =
    values.status && values.status !== 'All' ? [values.status] : undefined;

  const { startDate2Pass, endDate2Pass } = useDateRangeStandardHook(
    values.startDate,
    values.endDate
  );

  const { data, isFetching, refetch } = usePhysicalStatementListQuery({
    programId: selectedProgram?.programId || '',
    pageNumber: page,
    pageSize,
    search: values.search,
    startDate: startDate2Pass,
    endDate: endDate2Pass,
    statusList,
    enabled: Boolean(selectedProgram?.programId),
  });

  const updateStatus =
    (status2Update: PhysicalStatementStatus, btnNum: btnNums) => () => {
      setLoadingUpdateStatus(true);
      setBtn2Update(btnNum);
      const requestIdList: string[] = requestIndexList.map((el) => {
        return data?.records?.[el]?.id || '';
      });
      updateBulkPhysicalStatementStatus({
        requestIdList,
        newStatus: status2Update,
      })
        .then(() => {
          setSnackbar('Status Updated for the marked request IDs');
          refetch();
        })
        .catch(() => {
          setSnackbar(
            'Error updating status for the marked Request IDs',
            'error'
          );
        })
        .finally(() => {
          setLoadingUpdateStatus(false);
        });
    };

  const clearDate = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    updateValue('startDate', undefined);
    updateValue('endDate', undefined);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitAndClose = (startDate: string, endDate: string) => {
    updateValue('startDate', startDate);
    updateValue('endDate', endDate);
    handleClose();
  };

  const clearSearch = () => {
    updateValue('search', undefined);
  };

  const structuredData = getPhysicalStatementStructuredData(
    data?.records,
    true,
    setSnackbar
  );

  const addSelection = (selected: number[]) => {
    setRequestIndexList(selected);
  };

  const openRange = Boolean(anchorEl);
  const id = openRange ? 'simple-popover' : undefined;
  const status = values.status;

  useEffect(() => {
    setRefreshNum((prev) => prev + 1);
  }, [isFetching]);

  const isSelectionEmpty = !Boolean(requestIndexList.length);

  return (
    <Stack gap={2.5}>
      <Stack justifyContent="space-between" direction="row" gap={1}>
        <Stack direction="row" gap={1}>
          <VegaTextfieldV4
            onClick={handleClick}
            value={
              Boolean(values.endDate && values.endDate)
                ? `${dayjs(values.startDate).format(
                    DATE_FORMATE_SHORT
                  )} - ${dayjs(values.endDate).format(DATE_FORMATE_SHORT)}`
                : undefined
            }
            standardWidth={Boolean(values.endDate && values.endDate) ? 190 : 95}
            placeholder="By Date"
            sx={{ caretColor: 'transparent', background: 'white' }}
            InputProps={getDateInputIcons(Boolean(values.startDate), clearDate)}
          />
          <VegaSelectV4
            onChange={updateRecord('status')}
            value={values.status}
            options={['All', ...Object.values(PhysicalStatementStatus)].map(
              (el) => ({
                name: el === 'All' ? 'All status' : pascalCaseFromCamelCase(el),
                id: el,
              })
            )}
            standardWidth={130}
          />

          {!isFetching && (
            <Stack gap={1} direction="row">
              {status === PhysicalStatementStatus.REQUESTED && (
                <VegaButtonV4
                  disabled={!Boolean(data?.records.length) || isSelectionEmpty}
                  onClick={updateStatus(PhysicalStatementStatus.ACCEPTED, 1)}
                  loading={loadingUpdateStatus && btn2Update === 1}
                >
                  Accept All
                </VegaButtonV4>
              )}
              {status === PhysicalStatementStatus.REQUESTED && (
                <VegaButtonV4
                  disabled={!Boolean(data?.records.length) || isSelectionEmpty}
                  onClick={updateStatus(PhysicalStatementStatus.DECLINED, 7)}
                  loading={loadingUpdateStatus && btn2Update === 7}
                >
                  Decline All
                </VegaButtonV4>
              )}
              {status === PhysicalStatementStatus.ACCEPTED && (
                <VegaButtonV4
                  disabled={!Boolean(data?.records.length) || isSelectionEmpty}
                  onClick={updateStatus(PhysicalStatementStatus.PRINTED, 2)}
                  loading={loadingUpdateStatus && btn2Update === 2}
                >
                  Mark as Printed
                </VegaButtonV4>
              )}
              {(status === PhysicalStatementStatus.ACCEPTED ||
                status === PhysicalStatementStatus.DECLINED) && (
                <VegaButtonV4
                  disabled={!Boolean(data?.records.length) || isSelectionEmpty}
                  onClick={updateStatus(PhysicalStatementStatus.REQUESTED, 3)}
                  loading={loadingUpdateStatus && btn2Update === 3}
                >
                  Change to Requested
                </VegaButtonV4>
              )}
              {status === PhysicalStatementStatus.PRINTED && (
                <VegaButtonV4
                  disabled={!Boolean(data?.records.length) || isSelectionEmpty}
                  onClick={updateStatus(PhysicalStatementStatus.DELIVERED, 4)}
                  loading={loadingUpdateStatus && btn2Update === 4}
                >
                  Mark as Delivered
                </VegaButtonV4>
              )}
              {status === PhysicalStatementStatus.PRINTED && (
                <VegaButtonV4
                  disabled={!Boolean(data?.records.length) || isSelectionEmpty}
                  onClick={updateStatus(PhysicalStatementStatus.ACCEPTED, 5)}
                  loading={loadingUpdateStatus && btn2Update === 5}
                >
                  Change to Accepted
                </VegaButtonV4>
              )}
              {status === PhysicalStatementStatus.DELIVERED && (
                <VegaButtonV4
                  disabled={!Boolean(data?.records.length) || isSelectionEmpty}
                  onClick={updateStatus(PhysicalStatementStatus.PRINTED, 6)}
                  loading={loadingUpdateStatus && btn2Update === 6}
                >
                  Change to Printed
                </VegaButtonV4>
              )}
            </Stack>
          )}
        </Stack>
        <VegaSearchfieldV4
          value={values.search}
          onChange={updateRecord('search')}
          onClear={clearSearch}
          placeholder="Search by request Id..."
        />
      </Stack>
      {!Boolean(data?.records.length) && !isFetching ? (
        <VegaNoResults errorText="Physical Statement list is Empty" />
      ) : (
        <VegaTableV4
          head={[
            'Request ID',
            'Bill ID',
            'Bill Generation Date',
            'Bill Cycle Start Date',
            'Bill Cycle End Date',
            'Last Modified on',
            'Request Status',
            'Actions',
          ]}
          data={structuredData}
          isLoading={isFetching}
          checkBoxSelectFunc={status === 'All' ? undefined : addSelection}
          pagination={{
            rowsPerPage: pageSize,
            setRowsPerPage: setPageSize,
            setPage,
            page,
            totalRecords: data?.totalItems || 0,
          }}
          refreshNum={refreshNum}
        />
      )}
      <VegaDateRangePickerPopoverV4
        open={openRange}
        onClose={handleClose}
        submitDateRange={submitAndClose}
        popoverProps={{
          anchorEl,
          onClose: handleClose,
          open: openRange,
          id,
        }}
        initialValues={{
          startDate: values.startDate || '',
          endDate: values.endDate || '',
        }}
      />
    </Stack>
  );
};

export default PhysicalStatementOperations;
