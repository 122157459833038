/* eslint-disable react/jsx-pascal-case */
import { Box, InputAdornment, SelectChangeEvent, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSnackbar } from '../../../../../../providers/SnackbarProvider';
import { useSupport } from '../../../../../../providers/SupportProvider';
import {
  CardCategory,
  CardLocationType,
  CardStatus,
  CustomerPreferenceStatus,
  CustomerPreferenceType,
} from '../../../../../../types/card';
import { useCardListQuery } from '../../../../../../store/queries/cardQueries';
import VegaSelectV4 from '../../../../../common/v4/VegaSelectV4';
import VegaTableV4 from '../../../../../common/v4/VegaTableV4';
import { getCardControlStructuredData } from '../../../supportUtils';
import {
  ComponentHeadingTypo,
  PageHeadingTypo,
} from '../../../../../common/Typography';
import { pascalCaseFromCamelCase } from '../../../../../../utils/stringUtils';
import VegaDialogV4 from '../../../../../common/v4/VegaDialogV4';
import VegaTextfieldV4 from '../../../../../common/v4/VegaTextfieldV4';
import { QUICK_ACCESS } from '../../../../../../constants/style';
import VegaNoResults from '../../../../../common/VegaNoResults';
import { updateCardControlPreference } from '../../../../../../api/card';

export interface CardControlPreferenceType {
  type: CustomerPreferenceType;
  status: CustomerPreferenceStatus;
  perTransaction: number | undefined;
}

const Tickets = () => {
  const { accountData } = useSupport();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPref, setSelectedPref] = useState<
    CardControlPreferenceType | undefined
  >(undefined);
  const [editVal, setEditVal] = useState<number | string | undefined>(
    undefined
  );
  const [cardLocation, setCardLocation] = useState<CardLocationType>(
    CardLocationType.DOMESTIC
  );

  const toggleModal = () => {
    setShowModal((val) => !val);
  };

  const onEditModalOpen = (rec: CardControlPreferenceType) => {
    setEditVal(rec.perTransaction);
    setSelectedPref(rec);
    toggleModal();
  };

  const handleCardControlChange = (e: SelectChangeEvent<string | number>) => {
    setCardLocation(e.target.value as CardLocationType);
  };

  const {
    data: cardData,
    isFetching,
    isLoading,
    refetch,
  } = useCardListQuery({
    accountId: accountData?.accountId || '',
    pageSize: 100,
    pageNumber: 0,
    cardStatusList: [CardStatus.ACTIVATED],
    enabled: Boolean(accountData?.accountId),
  });

  const card = useMemo(() => {
    return cardData?.records.find(
      (card) => card.cardCategory === CardCategory.PRIMARY_CARD
    );
  }, [cardData]);

  const tableData: CardControlPreferenceType[] = useMemo(
    () =>
      Object.values(CustomerPreferenceType).map((item) => {
        const cardControlType =
          cardLocation === CardLocationType.DOMESTIC
            ? 'domestic'
            : 'international';
        const typePref = pascalCaseFromCamelCase(item);

        return {
          type: item,
          status: (card as any)?.[
            `${cardControlType}${typePref}`
          ] as CustomerPreferenceStatus,
          perTransaction: (card as any)?.[
            `${cardControlType}${typePref}Limit`
          ] as number | undefined,
        };
      }),
    [card, cardLocation]
  );

  const onChangeStatus = (rec: CardControlPreferenceType) => {
    updateCardControlPreference({
      cardId: card?.id || '',
      locationType: cardLocation,
      type: rec.type,
      customerPreferenceStatus:
        rec.status === CustomerPreferenceStatus.DISABLED
          ? CustomerPreferenceStatus.ENABLED
          : CustomerPreferenceStatus.DISABLED,
      limit: rec.perTransaction || 0,
    })
      .then((res) => {
        setSnackbar('Preference Updated');
        refetch();
      })
      .catch(() => {
        setSnackbar('Problem Updating Preference', 'error');
      });
  };

  const onEditPrefSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true);
    if (!selectedPref) {
      setSnackbar('Problem Updating Preference', 'error');
      return;
    }
    updateCardControlPreference({
      cardId: card?.id || '',
      locationType: cardLocation,
      type: selectedPref?.type,
      customerPreferenceStatus: selectedPref?.status,
      limit: editVal ? Number(editVal) : 0,
    })
      .then((res) => {
        setSnackbar('Preference Updated');
        refetch();
      })
      .catch(() => {
        setSnackbar('Problem Updating Preference', 'error');
      })
      .finally(() => {
        setLoading(false);
        setShowModal(false);
      });
  };

  const structuredTableData = getCardControlStructuredData(
    tableData,
    onChangeStatus,
    onEditModalOpen
  );

  return (
    <Stack gap={3}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} gap={3} alignItems={'flex-start'}>
          <PageHeadingTypo>Card Controls</PageHeadingTypo>
          {card && (
            <VegaSelectV4
              onChange={handleCardControlChange}
              value={cardLocation}
              defaultValue={cardLocation}
              options={Object.values(CardLocationType).map((el) => ({
                name: el,
                id: el,
              }))}
              standardWidth={200}
            />
          )}
        </Stack>
      </Stack>
      <Box mt={2}>
        {!card && !isLoading && !isFetching ? (
          <VegaNoResults errorText="No Active Primary Cards to control" />
        ) : (
          <VegaTableV4
            head={['Transaction Type', 'Status', 'Per Transaction']}
            isLoading={isLoading}
            data={card ? structuredTableData : []}
          />
        )}
      </Box>
      <VegaDialogV4
        open={showModal}
        title={`Update "Per Transaction" amount for ${
          selectedPref?.type || '--'
        } (${pascalCaseFromCamelCase(cardLocation)})`}
        onClose={toggleModal}
        actionBtnSecondaryText={'Update'}
        closeBtnRequired
        actionBtnSecondaryProps={{
          onClick: onEditPrefSubmit,
          loading: loading,
          disabled: loading || editVal === undefined || editVal === '',
        }}
      >
        <Stack px={5} py={3}>
          <VegaTextfieldV4
            value={editVal}
            header={`Per Transaction - ${pascalCaseFromCamelCase(
              selectedPref?.type || '--'
            )}`}
            onChange={(e) => setEditVal(e.target.value)}
            type="number"
            standardWidth={170}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ComponentHeadingTypo sx={{ color: QUICK_ACCESS.text[100] }}>
                    ₹
                  </ComponentHeadingTypo>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </VegaDialogV4>
    </Stack>
  );
};

export default Tickets;
