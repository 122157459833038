import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { NEW_PRIMARY } from '../../../../../../constants/style';
import { dateFormat } from '../../../../../../mcc/variable';
import Vega_FormWrapper from '../../../../../common/v3/Vega_FormWrapper';
import Vega_Text from '../../../../../common/v3/Vega_Text';
import { TextWrapper } from '../../common/TextWrapper';
import { IAadharState, IPanState } from '../SupportKycDetails';
import KycDetailsContainer from './KycDetailsContainer';

interface IProps {
  panState: IPanState;
  aadharState: IAadharState;
}

const KycPanDetails = ({ panState, aadharState }: IProps) => {
  return (
    <>
      <KycDetailsContainer
        header="PAN"
        rightText={
          panState.data ? 'Verified by Signzy on 23/06/23 at 11:23 am' : ''
        }
      >
        {panState.data ? (
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <TextWrapper
                name="number"
                value={panState.data?.number ?? '--'}
                isBorder
                loading={panState.loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <TextWrapper
                name="name"
                value={panState.data?.firstName ?? '--'}
                isBorder
                loading={panState.loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <TextWrapper
                name={`father's name`}
                value={panState.data?.middleName ?? '--'}
                loading={panState.loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <TextWrapper
                name={`mother's name`}
                value=""
                isBorder
                loading={panState.loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <TextWrapper
                name="dob"
                value={
                  aadharState.data?.dob
                    ? dateFormat(aadharState.data?.dob)
                    : '--'
                }
                isBorder
                loading={panState.loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <TextWrapper
                name="tax status"
                value={panState.data?.panStatus ?? '--'}
                loading={panState.loading}
              />
            </Grid>

            <Grid item xs={6} sm={12}>
              <Vega_FormWrapper
                header={'Document'}
                sx={{
                  position: 'relative',
                }}
              >
                <Stack direction={'row'} gap={1}>
                  <Box
                    sx={{
                      border: '1px solid #E1E4EB',
                      borderRadius: '4px',
                      height: '200px',
                      width: '140px',
                    }}
                  >
                    {panState.loading ? (
                      <Skeleton width={'100%'} height={'100%'} />
                    ) : (
                      <>
                        <Box
                          sx={{
                            height: '160px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            px: 1,
                          }}
                        >
                          <img
                            src={require('../../../../../../assets/images/pancard.avif')}
                            alt=""
                            width={'100%'}
                            height={'40%'}
                          />
                        </Box>
                        <Box sx={{ height: '40px', p: 1.5 }}>
                          <Vega_Text
                            text={'PAN 1'}
                            fontWeight={500}
                            fontSize={12}
                            color={NEW_PRIMARY.BLACK}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </Stack>
              </Vega_FormWrapper>
            </Grid>
          </Grid>
        ) : (
          <Typography fontStyle={'italic'} fontSize={13} textAlign={'center'}>
            Pan not found
          </Typography>
        )}
      </KycDetailsContainer>
    </>
  );
};

export default KycPanDetails;
