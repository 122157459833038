/** @format */

export const VEGA_BOX_SHADOW = '-3px 3px 20px rgba(0, 0, 0, 0.04)';
export const PRIMARY = {
  main: '#131432',
  light: '#4785FF',
  lighter: '#4585ff5c',
  blue: '#293543',
  grey: '#EEEEEE',
  darkBlue: '#001AA1',
};
export const BACKGROUND = {
  BLUE: '#303263',
  GERY: '#EBEFF4',
  GERY_LIGHT: '#C9C8C8',
  WHITE: '#FFFFFF',
  LIGHT_BLUE: '#EFF2F7',
  PURPLE: '#131432',
  PURPLE_LIGHT: '#AAADFF',
};
export const GREY = {
  darkest: '#202020',
  dark: '#8D8D8D',
  lightGray: '#777777',
  light: '#F9F9F9',
  lighter: '#565656',
  grey: '#D1D1D1',
  grey_light: '#5F5F5F',
};
export const BLACK = {
  dark: '#414141',
};
export const RED = {
  dark: '#FF0000',
  light: '#FFCCCB',
  lightDark: '#E8505B',
};
export const SUCCESS = {
  light: '#1DC9A0',
};
export const BLUE = {
  main: '#4E83AA',
  dark: '#002054',
  light: '#D8E7FF',
  lighter: '#4585FF',
  lightest: '#F3F7FF',
  brightBlue: '#E8F5FC',
};
export const PINK = {
  light: '#FFCBE7',
};
export const VIOLET = {
  dark: '#5029DB',
};
export const PURPLE = {
  main: '#9589DA',
  dark: '#4E60AA',
  lightest: '#D2D9F4',
  violet: '#EEEAFB',
};

export const ORANGE = {
  main: '#F6AC77',
  dark: '#f26424',
  light: '#FFDBCB',
  lightest: '#F4E3D2',
};
export const GREEN = {
  main: '#4EAA9F',
  dark: '#12B38C',
  light: '#D0FFD2',
  lighter: '#EBFBFF',
  lightest: '#CBFFE3',
};
export const YELLOW = {
  main: '#bd8300',
  light: '#ffff65',
  lighter: '#FFF9DC',
  lightest: '#E7F4D2',
};

export const COMMON = {
  gray: '#3A4A5F',
  darkGray: '#181F28',
};

export const NEW_PRIMARY = {
  MAIN: '#05162A',
  ACTIVE_MAIN: '#031120',
  GOLD: '#C8A864',
  GRAY: '#676B76',
  BLUE: '#1047DC',
  BLACK: '#1B1D22',
  RED: '#E80101',
  GREEN: '#318439',
  YELLOW: '#C9A907',
  ORANGE: '#E87001',
  LIGHT_BLUE: '#1A89C9',
};

export const VEGA_COMMON = {
  text75: '#999DA8',
  text100: '#676B76',
  text200: '#1B1D22',
  primaryBlue: '#1047DC',

  background: {
    50: '#FFFFFF',
    100: '#F7F7F7',
  },
  border: {
    50: '#E1E4EB',
  },
};

export const NEUTRALS = {
  grey: {
    50: '#EBEDEF',
    100: '#C2C7CD',
    200: '#A4ACB5',
    300: '#7B8694',
    400: '#616E7F',
    600: '#354356',
    800: '#202934',
  },
  white: {
    50: '#FDFDFD',
    100: '#F7F7F7',
    300: '#EEEEEE',
  },
};

export const INDICATIVE = {
  red: {
    50: '#F8E8E8',
    500: '#BA1C1C',
  },
  green: {
    50: '#EBF4EC',
    500: '#36913F',
    600: '#318439',
  },
  yellow: {
    50: '#FCF8E6',
    500: '#DDBA08',
    600: '#C9A907',
  },
  blue: {
    50: '#E8F5FC',
    100: '#B9DFF4',
    200: '#97CFEF',
    400: '#4AACE4',
    600: '#1A89C9',
  },
};

export const ACCENT = {
  violet: {
    50: '#EEEAFB',
    300: '#8A70E7',
    500: '#5029DB',
  },
  pink: {
    50: '#F9EBF5',
    500: '#C3329C',
    600: '#B12E8E',
  },
  seaGreen: {
    50: '#EAF5F6',
    200: '#DF9797',
    600: '#2C9097',
  },
};

export const PRIMARY_COLOR = {
  blue: {
    50: '#E7EDFC',
    100: '#7494EB',
    300: '#1047DC',
    400: '#0B329A',
    500: '#0A2B86',
  },
  orange: {
    50: '#FFF2E6',
    400: '#FF9534',
    600: '#E87001',
  },
  purple: {
    800: '#B12E8E',
  },
};

export const QUICK_ACCESS = {
  text: {
    50: '#FFF',
    75: '#999DA8',
    100: '#676B76',
    200: '#1B1D22',
  },
  border: {
    50: '#E1E4EB',
    75: '#E1E4EB',
  },
  background: {
    50: '#fff',
    75: '#FAFAFA',
    100: '#F7F7F7',
  },
  logo: {
    orange: '#FF912C',
  },
  primary: {
    blue: '#1047DC',
  },
};

export const GENERIC_BORDER = `1px solid ${QUICK_ACCESS.border[50]}`;

export const STROKE_COLOR = '#5F5F5F';

export const BORDER = '1px solid #BFABDB';
export const BORDER_BLUE = '1px solid #9EBFFF';
export const BORDER_GRAY = '1px solid #D1D1D1';
export const BORDER_GRAY_DARK = '1px solid #323336DE';

export const LINEAR_GRADIANT_COLOR = {
  BACKGROUND: `linear-gradient(to right, #E7EDFC80 , #E7EDFC40)`,
};

export const GENERIC_COLORS = {
  LINEAR_GRADIANT: {
    blue1: 'linear-gradient(91deg, #0B329A 0%, #1A1A6A 99.95%)',
  },
};
