import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { PageHeadingTypo } from '../../../common/Typography';
import VegaSearchfieldV4 from '../../../common/v4/VegaSearchfieldV4';
import VegaTableV4, { ITableDataProps } from '../../../common/v4/VegaTableV4';

const CollectionSummaryAnalytics = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const structuredData: ITableDataProps[] = data;

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <PageHeadingTypo>Collection Summary</PageHeadingTypo>
      </Stack>
      <VegaTableV4
        head={['DPD Bucket', 'Overdue Amount', 'Customer Count']}
        data={structuredData}
        pagination={{
          rowsPerPage: pageSize,
          setRowsPerPage: setPageSize,
          setPage,
          page,
          totalRecords: 5,
        }}
        // isLoading={isFetching}
      />
    </Stack>
  );
};

const data: ITableDataProps[] = [
  {
    rowData: [
      {
        value: 'X',
        type: 'string',
      },
      {
        value: '₹ 3,34,11,250',
        type: 'string',
      },
      {
        value: '456',
        type: 'string',
      },
    ],
  },
  {
    rowData: [
      {
        value: '1-30',
        type: 'string',
      },
      {
        value: '₹ 3,34,11,250',
        type: 'string',
      },
      {
        value: '456',
        type: 'string',
      },
    ],
  },
  {
    rowData: [
      {
        value: '31-60',
        type: 'string',
      },
      {
        value: '₹ 3,34,11,250',
        type: 'string',
      },
      {
        value: '456',
        type: 'string',
      },
    ],
  },
  {
    rowData: [
      {
        value: '61-90',
        type: 'string',
      },
      {
        value: '₹ 3,34,11,250',
        type: 'string',
      },
      {
        value: '456',
        type: 'string',
      },
    ],
  },
  {
    rowData: [
      {
        value: 'NPA',
        type: 'string',
      },
      {
        value: '₹ 3,34,11,250',
        type: 'string',
      },
      {
        value: '456',
        type: 'string',
      },
    ],
  },
];

export default CollectionSummaryAnalytics;
