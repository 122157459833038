// Hack to navigate back to rewards home if program selection changes

import { throttle } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import { fetchAccountForCustomer } from '../api/account';
import { getFilteredCustomers } from '../api/customer';
import { AccountDataDto } from '../types/account';
import { ISupportCustomerDtoProps } from '../types/support';
import { useClientAuth } from './ClientProvider';
import { SupportStep } from '../components/BackOffice/Support/SupportSearch';
import { EMIData } from '../types/emis';
import { ICardState } from '../types/card';

export enum AccountDetailsStep {
  SUMMARY = 'SUMMARY',
  PHYSICAL_CARD = 'PHYSICAL_CARD',
  CARD_CONTROLS = 'CARD_CONTROLS',
  TRANSACTIONS = 'TRANSACTIONS',
  BILLS = 'BILLS',
  REWARDS = 'REWARDS',
  TCS = 'TCS',
  // TICKETS = 'TICKETS',
  DELINQUENCY = 'DELINQUENCY',
  LOANS = 'LOANS',
  SURPLUS = 'SURPLUS',
  EMI = 'EMIs',
  WAIVER = 'Waiver',
  CHARGEBACK = 'Chargeback',
  PHYSICAL_STATEMENT = 'Physical Statement',
}

type SupportContextType = {
  filter: string;
  loading: boolean;
  isEmptyFilter: boolean;
  isDisabled: boolean;
  selected: string;
  accountData?: Partial<AccountDataDto>;
  cardsList: ICardState;
  setCardsList: React.Dispatch<React.SetStateAction<ICardState>>;
  filteredCustomer: ISupportCustomerDtoProps | null;
  elementHeight: number | null;
  setElementHeight: React.Dispatch<React.SetStateAction<number | null>>;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  setIsEmptyFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setFilteredCustomer: React.Dispatch<
    React.SetStateAction<ISupportCustomerDtoProps | null>
  >;
  setAccountData: React.Dispatch<
    React.SetStateAction<Partial<AccountDataDto> | undefined>
  >;
  display: SupportDisplayType;
  throttledGetFilterCustomer: () => void;
  selectedEMI: EMIData | undefined;
  setSelectedEMI: React.Dispatch<React.SetStateAction<EMIData | undefined>>;
  selectedTab: AccountDetailsStep;
  setSelectedTab: React.Dispatch<React.SetStateAction<AccountDetailsStep>>;
};

type SupportDisplayType = 'account' | 'search';

const SupportContext = createContext<SupportContextType | null>(null);

export const useSupport = () =>
  useContext(SupportContext) as SupportContextType;

export const SupportProvider = ({ children }: any) => {
  const { clientId } = useClientAuth();
  const [display, setDisplay] = useState<SupportDisplayType>('search');

  const [filteredCustomer, setFilteredCustomer] =
    useState<ISupportCustomerDtoProps | null>(null);
  const [selected, setSelected] = useState<string>(SupportStep.ACCOUNT);
  const [accountData, setAccountData] = useState<
    Partial<AccountDataDto> | undefined
  >(undefined);
  const [filter, setFilter] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [elementHeight, setElementHeight] = useState<number | null>(null);
  const [selectedEMI, setSelectedEMI] = useState<EMIData | undefined>(
    undefined
  );
  const [selectedTab, setSelectedTab] = useState<AccountDetailsStep>(
    AccountDetailsStep.SUMMARY
  );

  const [cardsList, setCardsList] = useState<ICardState>({
    data: [],
    isLoading: false,
  });

  const getAccountForCustomer = async (customerId: string) => {
    try {
      const response = await fetchAccountForCustomer(customerId as string);
      return response.data.records;
    } catch (error) {
      setLoading(false);
      setIsEmptyFilter(false);
      throw error;
    }
  };

  const getFilterCustomer = async (value: string, clientId: string) => {
    const checkValue = parseInt(value);

    try {
      setLoading(true);
      const emailBody = {
        clientId: clientId,
        emailId: value,
      };
      const mobileBody = {
        clientId: clientId,
        mobileNumber: value,
      };

      const paginatedResponse = await getFilteredCustomers(
        isNaN(checkValue) ? emailBody : mobileBody
      );
      const customerRecords = paginatedResponse.records;
      if (customerRecords.length <= 0) {
        console.error('Customer is undefined');
        setFilteredCustomer(null);
        setLoading(false);
        setIsEmptyFilter(false);
        setIsDisabled(true);
        return;
      }
      const customer = customerRecords[0];

      setLoading(false);
      const accountsResponse = await getAccountForCustomer(customer.customerId);

      if (accountsResponse.length) {
        setFilteredCustomer({
          ...customer,
          account: accountsResponse,
        });
        setLoading(false);
      } else {
        setFilteredCustomer({
          ...customer,
          account: null,
        });
      }
      setIsDisabled(true);
      setIsEmptyFilter(false);
    } catch (err) {
      console.error(err);
      setIsDisabled(true);
    } finally {
      setLoading(false);
      setIsDisabled(true);
    }
  };

  const throttledGetFilterCustomer = throttle(async () => {
    if (!clientId) return;
    if (filter.length) {
      await getFilterCustomer(filter, clientId);
    } else {
      setFilteredCustomer(null);
    }
  }, 200);

  useEffect(() => {
    setDisplay(accountData ? 'account' : 'search');
  }, [accountData]);

  return (
    <SupportContext.Provider
      value={{
        filter,
        elementHeight,
        loading,
        filteredCustomer,
        cardsList,
        setCardsList,
        selected,
        accountData,
        isEmptyFilter,
        isDisabled,
        setFilteredCustomer,
        setIsEmptyFilter,
        setIsDisabled,
        setLoading,
        setSelected,
        setElementHeight,
        setFilter,
        setAccountData: setAccountData,
        display,
        throttledGetFilterCustomer,
        selectedEMI,
        setSelectedEMI,
        selectedTab,
        setSelectedTab,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};
