import { useCallback, useEffect, useState } from 'react';
import { VegaCard } from '../../../../components/common';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import { IConsolidatedAccountData } from '../../../types/Commons';
import { IFilterCorporateCustomer } from '../../../types/CorporateCustomer';

import { useClientAuth } from '../../../../providers/ClientProvider';
import { IFilterProps } from '../../../Back-office/pages/MCCAplicationManagement';
import FilterAction from '../../../Program-Dashboard/components/FilterAction';
import { listCorporateAccounts } from '../../api/Corporate';
import { useCorporate } from '../../hooks/corporate';
import {
  IReloadCardData,
  IReloadCardRequest,
  PAYMENT_MODE,
  VALUE_TYPE,
} from './Card';
import ListOfCorporateCustomerForCardReload from './ListOfCorporateCustomerForCardReload';
import ReloadCardDetails from './ReloadCardDetails';

const getInitalData = (
  data?: IConsolidatedAccountData
): Partial<IReloadCardData> => {
  const base = {
    accountId: '',
    branchId: '',
    corporateId: '',
    cardId: '',
    customerName: '',
    programName: '',
    paymentMode: PAYMENT_MODE.ONLINE,
    sourceCurrency: '',
    destinationCurrency: '',
    amount: 0,
    markupValueType: VALUE_TYPE.PERCENTAGE,
    markupValue: 0,
    transactionTime: '',
  };

  return data
    ? {
        ...base,
        accountId: data.account?.id || '',
        cardId: data.card?.id || '',
        customerName: data.customer?.firstName || '',
        programName: data.program?.name || '',
        corporateId: data.account?.corporateCustomerId || '',
      }
    : base;
};
interface IProps {
  filterData: IFilterProps;
  handleChange: (key: keyof IFilterCorporateCustomer, value: string) => void;
  handleReloadAccount: (data: IReloadCardRequest) => void;
}

function ReloadCards(props: IProps) {
  const { filterData, handleChange, handleReloadAccount } = props;
  const { mccPlatformId: corporateId } = useClientAuth();
  const corporateQuery = useCorporate(corporateId);
  const [listOfCorporateAccounts, setListOfCorporateAccounts] = useState<
    Array<IConsolidatedAccountData>
  >([]);
  const [bulkSelections, setBulkSelections] = useState<Array<string>>([]);
  const [isOpenBuldLoad, setIsOpenBuldLoad] = useState<boolean>(false);

  const getInitialReloadData = useCallback(
    (data?: IConsolidatedAccountData) => {
      return {
        ...getInitalData(data),
        branchId: corporateQuery.data?.branchId || '',
      };
    },
    [corporateQuery.data]
  );
  const [reloadCardData, setReloadCardData] = useState<
    Partial<IReloadCardData>
  >(getInitialReloadData());

  const handleFilterByBin = () => {
    //
  };

  const openBulkReloadHanlder = () => {
    //
    setIsOpenBuldLoad(true);
  };

  const handleReloadDataChange = (
    key: keyof IReloadCardData,
    value: string | PAYMENT_MODE | VALUE_TYPE | number
  ) => {
    setReloadCardData((pre) => ({
      ...pre,
      [key]: value,
    }));
  };

  const clearBulkHandle = () => {
    getInitialReloadData();
    setIsOpenBuldLoad(false);
  };
  const submitBulkHandle = () => {
    //
    const { customerName, programName, paymentMode, accountId, ...rest } =
      reloadCardData;

    // bulkSelections.forEach((selected) => {
    //   if (
    //     listOfCorporateAccounts &&
    //     listOfCorporateAccounts.length > 0 &&
    //     listOfCorporateAccounts[parseInt(selected)]?.account?.id
    //   ) {
    //     handleReloadAccount({
    //       accountId: listOfCorporateAccounts[parseInt(selected)].account.id,
    //       ...rest,
    //     });
    //   }
    // });
    setIsOpenBuldLoad(false);
  };

  useEffect(() => {
    // filter the account for a corporate
    listCorporateAccounts({
      corporateCustomerId: corporateId,
      status: ['ACTIVE'],
    })
      .then((res) => {
        setListOfCorporateAccounts(
          (res.data?.records as IConsolidatedAccountData[]).reduce(
            (pre, curr) => {
              return curr.card
                ? [
                    ...pre,
                    {
                      ...curr,
                      id: curr.account?.id || pre.length + 1 + '',
                    },
                  ]
                : pre;
            },
            [] as IConsolidatedAccountData[]
          )
        );
      })
      .catch((err) => {
        console.log('Error in fetching accounts for corporate ', err);
      });
  }, []);

  return (
    <VegaCard noPad fullHeight>
      {/*  –----------- FILTER AND BULK OPTIONS–-----------  */}

      {/* <Stack p={2} mb={2}>
        <Grid container>
          <Grid container spacing={2} xs={12} sm={8} alignItems={'center'}>
            <Grid item xs={12} sm={3}>
              <Box>Filter by BIN Number</Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SearchByValue
                value={filterData.binNumber}
                handleChange={(value) => handleChange('binNumber', value)}
                label="Enter Bin number"
              />
            </Grid>
            <Grid container xs={12} sm={4} alignSelf={'flex-end'}>
              <VegaOutlineButton
                text={'Search'}
                isPrimary
                onClick={handleFilterByBin}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={4} alignItems={'center'}>
            <Grid item xs={12} sm={6} justifyContent={'flex-end'}>
              <VegaContainedButton
                text={'Selected Application ' + bulkSelections.length}
                isPrimary
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VegaContainedButton
                text={'Bulk Reload'}
                isSuccess
                onClick={openBulkReloadHanlder}
              />
            </Grid>
          </Grid>
        </Grid>
      </Stack> */}
      <FilterAction
        options={['PENDING', 'APPROVED', 'REJECTED']}
        selected={'PENDING'}
        onApprove={openBulkReloadHanlder}
        onReject={openBulkReloadHanlder}
        selectedCount={bulkSelections.length}
      />

      {/* ----------------------------- ACCOUNTS LIST  -----------------------------  */}
      <ListOfCorporateCustomerForCardReload
        listOfCorporateAccounts={listOfCorporateAccounts}
        handleReloadAccount={handleReloadAccount}
        setBulkSelections={setBulkSelections}
        reloadCardData={reloadCardData as IReloadCardData}
        setReloadCardData={setReloadCardData as any}
        setInitialReloadData={getInitialReloadData as any}
        handleReloadDataChange={handleReloadDataChange}
      />

      {/* ----------------------------- BULK LOAD -----------------------------  */}
      <VegaDrawer
        open={isOpenBuldLoad}
        header={'Bulk Reload Card'}
        closeText="Clear"
        isClose
        isSubmit
        submitText="Submit"
        onClose={clearBulkHandle}
        onSubmit={submitBulkHandle}
      >
        <ReloadCardDetails
          reloadCardData={reloadCardData as IReloadCardData}
          handleReloadDataChange={handleReloadDataChange}
          isBulkLoad
        />
      </VegaDrawer>
    </VegaCard>
  );
}

export default ReloadCards;
