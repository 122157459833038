import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Grid from '@mui/material/Grid';
import DueDateCalendar from '../DueDateCalendar';
import { useAppSelector } from '../../../store/hook';
import VegaAccordionV4, {
  VegaAccordionDetails,
  VegaAccordionSummary,
} from '../../common/v4/VegaAccordionV4';
import { ListHead2Typo, SectionHeadingTypo } from '../../common/Typography';
import { QUICK_ACCESS } from '../../../constants/style';

function PaymentDueDate() {
  const { selectedProgram } = useAppSelector(
    (state) => state.programManagement
  );
  const pddList = selectedProgram?.pddList ?? [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 3,
        pb: 2,
      }}
    >
      <Typography className="font-aspekta-600" fontSize={17}>
        Payment Due Date
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <VegaAccordionV4 expandable={false}>
            <VegaAccordionSummary>
              <SectionHeadingTypo>Due Dates</SectionHeadingTypo>
            </VegaAccordionSummary>
            <VegaAccordionDetails>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <DueDateCalendar selectDate={pddList} />
              </Box>
            </VegaAccordionDetails>
          </VegaAccordionV4>
        </Grid>
        <Grid item xs={12} lg={4}>
          <VegaAccordionV4
            expandable={false}
            sx={{
              height: '100%',
            }}
          >
            <VegaAccordionSummary>
              <SectionHeadingTypo>Due Dates</SectionHeadingTypo>
            </VegaAccordionSummary>
            <VegaAccordionDetails sx={{ height: '100%' }}>
              {pddList.length ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <ul
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      paddingInlineStart: 16,
                    }}
                  >
                    {pddList.map((date, index) => (
                      <li key={index}>
                        <ListHead2Typo
                          sx={{
                            color: QUICK_ACCESS.text[200],
                          }}
                        >{`${date} of every month`}</ListHead2Typo>
                      </li>
                    ))}
                  </ul>
                </Box>
              ) : (
                <Box>
                  <ListHead2Typo
                    sx={{
                      color: QUICK_ACCESS.text[75],
                    }}
                  >
                    No Due Dates Present
                  </ListHead2Typo>
                </Box>
              )}
            </VegaAccordionDetails>
          </VegaAccordionV4>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PaymentDueDate;
