import { Stack } from '@mui/material';
import React from 'react';
import Vega_Text from '../../../../../common/v3/Vega_Text';
import { NEW_PRIMARY } from '../../../../../../constants/style';
import { VerifyIcon } from '../../../icons/SupportIcons';

interface IProps {
  header: string;
  rightText: string;
  children: JSX.Element;
}

const KycDetailsContainer = ({ header, rightText, children }: IProps) => {
  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={2}
      >
        <Vega_Text
          text={header}
          fontWeight={600}
          color={NEW_PRIMARY.BLACK}
          fontSize={17}
        />
        {rightText && (
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <VerifyIcon />
            <Vega_Text
              text={rightText}
              fontWeight={600}
              color={'#616E7F'}
              fontSize={10}
            />
          </Stack>
        )}
      </Stack>
      {children}
    </>
  );
};

export default KycDetailsContainer;
