export enum VegaWebhookStatus {
  NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum VegaEventType {
  BILL_UPDATE_DATA = 'BILL_UPDATE_DATA',
  SURPLUS_CREATE = 'SURPLUS_CREATE',
  ONBOARDING_APPLICATION_APPROVED_TO_ISIC = 'ONBOARDING_APPLICATION_APPROVED_TO_ISIC',
  CARD_LIMIT_UPDATE_DATA = 'CARD_LIMIT_UPDATE_DATA',
  CARD_CREATE_DATA = 'CARD_CREATE_DATA',
  CARD_DELIVERY_ORDER_DATA = 'CARD_DELIVERY_ORDER_DATA',
  LOAN_CREATE_DATA = 'LOAN_CREATE_DATA',
  SURPLUS_PAYOUT_CREATE = 'SURPLUS_PAYOUT_CREATE',
  LOAN_UPDATE_DATA = 'LOAN_UPDATE_DATA',
  TRANSACTION_COMPLETE_DATA = 'TRANSACTION_COMPLETE_DATA',
  ACCOUNT_LIMIT_UPDATE_DATA = 'ACCOUNT_LIMIT_UPDATE_DATA',
  OFFER_CREATE_DATA = 'OFFER_CREATE_DATA',
  DISPUTE_UPDATE_DATA = 'DISPUTE_UPDATE_DATA',
  CARD_PIN_SET_RESET_DATA = 'CARD_PIN_SET_RESET_DATA',
  CARD_STATUS_UPDATE_DATA = 'CARD_STATUS_UPDATE_DATA',
  APPLICATION_UPDATE_DATA = 'APPLICATION_UPDATE_DATA',
  BILL_CREATE_DATA = 'BILL_CREATE_DATA',
  CARD_DELIVERED_REMINDER = 'CARD_DELIVERED_REMINDER',
  ACCOUNT_CREATE_DATA = 'ACCOUNT_CREATE_DATA',
  OFFER_UPDATE_DATA = 'OFFER_UPDATE_DATA',
  REWARD_CREATE_DATA = 'REWARD_CREATE_DATA',
  ACCOUNT_STATUS_UPDATE_DATA = 'ACCOUNT_STATUS_UPDATE_DATA',
  BILL_REMINDER_COMMS = 'BILL_REMINDER_COMMS',
  DISPUTE_CREATE_DATA = 'DISPUTE_CREATE_DATA',
  REWARD_UPDATE_DATA = 'REWARD_UPDATE_DATA',
}

/**
 * {
  
    "events": {
        "BILL_UPDATE_DATA": "NOT_SUBSCRIBED",
        "SURPLUS_CREATE": "NOT_SUBSCRIBED",
        "ONBOARDING_APPLICATION_APPROVED_TO_ISIC": "NOT_SUBSCRIBED",
        "CARD_LIMIT_UPDATE_DATA": "NOT_SUBSCRIBED",
        "CARD_CREATE_DATA": "NOT_SUBSCRIBED",
        "CARD_DELIVERY_ORDER_DATA": "NOT_SUBSCRIBED",
        "LOAN_CREATE_DATA": "NOT_SUBSCRIBED",
        "SURPLUS_PAYOUT_CREATE": "NOT_SUBSCRIBED",
        "LOAN_UPDATE_DATA": "NOT_SUBSCRIBED",
        "TRANSACTION_COMPLETE_DATA": "NOT_SUBSCRIBED",
        "ACCOUNT_LIMIT_UPDATE_DATA": "NOT_SUBSCRIBED",
        "OFFER_CREATE_DATA": "NOT_SUBSCRIBED",
        "DISPUTE_UPDATE_DATA": "NOT_SUBSCRIBED",
        "CARD_PIN_SET_RESET_DATA": "NOT_SUBSCRIBED",
        "CARD_STATUS_UPDATE_DATA": "NOT_SUBSCRIBED",
        "APPLICATION_UPDATE_DATA": "NOT_SUBSCRIBED",
        "BILL_CREATE_DATA": "NOT_SUBSCRIBED",
        "CARD_DELIVERED_REMINDER": "NOT_SUBSCRIBED",
        "ACCOUNT_CREATE_DATA": "NOT_SUBSCRIBED",
        "OFFER_UPDATE_DATA": "NOT_SUBSCRIBED",
        "REWARD_CREATE_DATA": "NOT_SUBSCRIBED",
        "ACCOUNT_STATUS_UPDATE_DATA": "NOT_SUBSCRIBED",
        "BILL_REMINDER_COMMS": "NOT_SUBSCRIBED",
        "DISPUTE_CREATE_DATA": "NOT_SUBSCRIBED",
        "REWARD_UPDATE_DATA": "NOT_SUBSCRIBED"
    },
    "keyCredentialsId": null,
    "secret": "dasas",
    "description": "Process Delinquencies For Accouns in Program",
    "status": "ACTIVE",
    "subscribeEvents": false
}
 */
export interface VegaWebHook {
  id?: string;
  programId: string;
  url?: string;
  event: string;
  secret?: string;
  description?: string;
  status: VegaWebhookStatus;
  entityId: null;
  keyCredentialsId: null;
  events: Record<VegaEventType, string>;
  subscribeEvents: false;
  eventName?: string;
  eventStatus: string;
}
