import { Box, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import VegaCardV4 from '../../common/v4/VegaCardV4';
import { useBillListQuery } from '../../../store/queries/accountQueries';
import VegaNoResults from '../../common/VegaNoResults';
import VegaTableV4 from '../../common/v4/VegaTableV4';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import { Controller, useForm } from 'react-hook-form';
import VegaDatePickerV4 from '../../common/v4/VegaDatePickerV4';
import { generateBillForAccount, getBillPdf } from '../../../api/bill';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  getBillListStructuredData,
  searchByOptionsForTransactions,
} from '../simulatorUtils';
import { ListGroupHeadingTypo } from '../../common/Typography';
import { useSimulatorContext } from '../SimulatorMainWrap';
import VegaSelectV4 from '../../common/v4/VegaSelectV4';
import VegaAutocompleteSearchV4 from '../../common/v4/VegaAutocompleteSearchV4';
import { TextFieldSearchProps } from '../../../utils/common.utils';

interface IBillCreationFormProps {
  date: string;
}

const BillListPage = () => {
  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    getValues,
  } = useForm<IBillCreationFormProps>({
    mode: 'onTouched',
  });

  const {
    searchValue: value,
    updateSearchValue,
    customerRecords,
    customerListQuery,
    activeSearchBy,
    updateActiveSearchBy,
    onTextChange,
    isCustDataFetching,
    selectedAccId,
  } = useSimulatorContext();

  const { isFetching: isFetchingCustomerData } = customerListQuery || {};

  const {
    data: ledgerListData,
    isFetching: isFetchingLedgerListData,
    refetch,
  } = useBillListQuery({
    accountId: selectedAccId as string,
    page,
    pageSize,
  });

  const records = useMemo(
    () => (value ? ledgerListData?.records : []),
    [value, ledgerListData]
  );

  const handleSubmitClick = () => {
    setIsLoading(true);
    const values = getValues();
    generateBillForAccount(value as string, values.date)
      .then((res) => {
        refetch();
        setSnackbar('Bill Generated!');
        reset();
      })
      .catch((err) => {
        setSnackbar('Failed to generate bill', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onViewBillClick = (id: string) => {
    getBillPdf(id).then((res) => {
      const link = res.data?.url;
      window.open(link);
    });
  };

  const structuredData = useMemo(() => {
    return getBillListStructuredData(records, onViewBillClick);
  }, [records]);

  useEffect(() => {
    setPage(0);
  }, [value]);

  return (
    <Box>
      <VegaHeaderV4 title="Bills" sx={{ marginTop: 4 }} />
      <Stack p={3} gap={5}>
        <VegaCardV4>
          <Stack direction="row" gap={3}>
            <Stack direction="row" gap={1} alignItems={'center'}>
              <VegaSelectV4
                header="Search By"
                options={searchByOptionsForTransactions}
                value={activeSearchBy}
                onChange={updateActiveSearchBy}
                wrapperProps={{
                  flex: 0.7,
                }}
                sx={{ height: 36.5 }}
                standardWidth={180}
                headerTypo={ListGroupHeadingTypo}
              />
              <VegaAutocompleteSearchV4
                textFieldProps={{
                  ...TextFieldSearchProps,
                  onChange: onTextChange,
                }}
                options={customerRecords || []}
                value={value || ''}
                onChange={updateSearchValue}
                freeSolo
                loading={isCustDataFetching}
              />
            </Stack>
          </Stack>
          <Stack gap={1} direction="row" alignItems="flex-end">
            <Controller
              name="date"
              control={control}
              rules={{
                required: 'Required field',
              }}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <VegaDatePickerV4
                    disabled={!Boolean(value)}
                    dateProps={{ ...rest, views: ['month', 'day'] }}
                    textProps={{
                      header: 'Start Date',
                      required: true,
                      labelText: errors.date?.message,
                      error: Boolean(errors.date),
                      standardWidth: 250,
                      placeholder: 'Select Date',
                      headerTypo: ListGroupHeadingTypo,
                    }}
                  />
                );
              }}
            />
            <VegaButtonV4
              variant="outlined"
              onClick={handleSubmit(handleSubmitClick)}
              disabled={isLoading || !Boolean(value)}
              loading={isLoading}
            >
              Create Bill
            </VegaButtonV4>
          </Stack>
        </VegaCardV4>
        {!records?.length &&
        !(isFetchingLedgerListData || isFetchingCustomerData) ? (
          <VegaNoResults
            errorText={
              value
                ? 'No Bill Found for the customer. Please select another customer'
                : 'Please select the customer'
            }
          />
        ) : (
          <VegaTableV4
            head={[
              'Account ID',
              'Bill Start Date',
              'Bill ID',
              'Due Date',
              'Due Amount',
              'MAD',
              'Status',
              'Action',
            ]}
            data={structuredData}
            isLoading={isFetchingLedgerListData || isFetchingCustomerData}
            pagination={{
              rowsPerPage: pageSize,
              setRowsPerPage: setPageSize,
              setPage,
              page,
              totalRecords: ledgerListData?.totalItems || 0,
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

export default BillListPage;
