import {
  VegaCreditCardProductType,
  VegaForexCardProductType,
  VegaLoanProductType,
  VegaLoanType,
  VegaPrepaidCardProductType,
  VegaProgramCategoryType,
  VegaProgramIssuanceType,
} from '../types/program';

export const CATEGORIES = [
  VegaProgramCategoryType.CREDIT_CARD,
  VegaProgramCategoryType.PREPAID_CARD,
  VegaProgramCategoryType.FOREX_CARD,
  VegaProgramCategoryType.LOANS,
];
export const ISSUANCE_TYPES = [
  VegaProgramIssuanceType.SELF,
  VegaProgramIssuanceType.CO_BRAND,
];

export const CREDIT_CARD_PRODUCTS = [
  VegaCreditCardProductType.CHARGE_CARD,
  VegaCreditCardProductType.CORPORATE_CARD,
  VegaCreditCardProductType.RETAIL_UNSECURED,
  VegaCreditCardProductType.RETAIL_SECURED,
];

export const FOREX_CARD_PRODUCTS = [
  VegaForexCardProductType.CORPORATE_CARD,
  VegaForexCardProductType.RETAIL_CARD,
];
export const PREPAID_CARD_PRODUCTS = [
  VegaPrepaidCardProductType.FUEL_CARD,
  VegaPrepaidCardProductType.MEAL_CARD,
  VegaPrepaidCardProductType.TRAVEL_CARD,
  VegaPrepaidCardProductType.GIFT_CARD,
];

export const LOAN_PRODUCTS = [
  VegaLoanProductType.SECURED,
  VegaLoanProductType.UNSECURED,
  VegaLoanProductType.COMMODITY_FINANCING,
];

export const SECURED_LOAN_TYPES = [
  VegaLoanType.LOAN_AGAINST_GOLD,
  VegaLoanType.LAM_OR_LAS,
  VegaLoanType.LOAN_AGAINST_PROPERTY,
  VegaLoanType.SOLAR_FINANCING,
];
export const UNSECURED_LOAN_TYPES = [
  VegaLoanType.PERSONAL_LOAN,
  VegaLoanType.BUSINESS_LOAN,
  VegaLoanType.MSME_LOAN,
];
export const COMMODITY_LOAN_TYPES = [
  VegaLoanType.TWO_WHEELER_LOAN,
  VegaLoanType.CAR_LOAN,
];
