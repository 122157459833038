import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../common/Typography';
import VegaTableV4 from '../../../common/v4/VegaTableV4';
import { BillingTableData } from '../../../../types/analytics';
import { ANALYTICS_QUERY_NAME } from '../../../../enums/analytics.enum';
import { getBillingAndRepaymentSummaryData } from '../analyticsUtils';
import { useAnalyticsHook } from '../../../../hooks/useAnalyticsHook';

const BillingAndRepaymentSummaryTable = () => {
  const {
    structuredTableData,
    tableData,
    isTableDataFetching,
    page,
    pageSize,
    setPage,
    setPageSize,
  } = useAnalyticsHook<undefined, undefined, BillingTableData>({
    tableQueryName: ANALYTICS_QUERY_NAME.CCMS_BILLING_DATA,
    tableDataFunction: getBillingAndRepaymentSummaryData,
  });

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <PageHeadingTypo>Billing and Repayment Summary</PageHeadingTypo>
      </Stack>
      <VegaTableV4
        head={[
          'Date',
          'Total Bills Generated',
          'Total Billed Amount',
          'Total MAD Amount',
          'Unpaid Bills',
          'Unpaid Bill Amount',
          'Paid by MAD',
          'MAD Amount',
          'Paid by TAD Count',
          'Paid by TAD Amount',
        ]}
        data={structuredTableData}
        pagination={{
          rowsPerPage: pageSize,
          setRowsPerPage: setPageSize,
          setPage,
          page,
          totalRecords: tableData?.totalItems || 0,
        }}
        isLoading={isTableDataFetching}
      />
    </Stack>
  );
};

export default BillingAndRepaymentSummaryTable;
