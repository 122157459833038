/** @format */

import axios from 'axios';
import { UpdateClientLogoRequestData } from '../types/client';
import { BASE_URL, ONBOARDING, CREDENTIAL_MANAGER } from './endpoints';

const ONBOARDING_BASE_URL = BASE_URL + ONBOARDING.BASE;
const CREDENTIAL_MANAGER_BASE = BASE_URL + CREDENTIAL_MANAGER.BASE;

const ONBOARDING_CLIENT_URL = ONBOARDING_BASE_URL + ONBOARDING.CLIENT;

const ROLE_MANAGEMENT_BASE =
  CREDENTIAL_MANAGER_BASE + CREDENTIAL_MANAGER.ROLE_MANAGEMENT.BASE;

export const getClient = (clientId: string) => {
  return axios.get(ONBOARDING_CLIENT_URL + '/' + clientId);
};

export const fetchUserData = (email: string) => {
  return axios.get(ROLE_MANAGEMENT_BASE + '/user/' + email);
};

export const updateClientLogo = (updatedData: UpdateClientLogoRequestData) => {
  const formData: FormData = new FormData();
  formData.append('clientId', updatedData.clientId);
  formData.append('logoType', updatedData.logoType);
  formData.append('logo', updatedData.logo);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.patch(ONBOARDING_CLIENT_URL + '/logo', formData, config);
};
