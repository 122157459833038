import axios from 'axios';
import { IdentityFRMPolicy } from '../types/policy';
import { BASE_URL, FRM } from './endpoints';

const BASE_FRM_URL = BASE_URL + FRM.BASE;
export async function getDefaultFRMPolicy(): Promise<IdentityFRMPolicy> {
  try {
    const endpoint = BASE_FRM_URL + FRM.GET_DEFAULT_POLICY;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}
