import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../common/Typography';
import VegaFilterButtonsV4 from '../../../common/v4/VegaFilterBtnsV4';
import { PRIMARY_COLOR, QUICK_ACCESS } from '../../../../constants/style';
import VegaMetricCardV4 from '../../../common/v4/VegaMetricCardV4';
import VegaLineChartV4 from '../../../common/Charts/VegaLineChartV4';
import { ANALYTICS_QUERY_NAME } from '../../../../enums/analytics.enum';
import {
  ApplicationAnalyticsGraphData,
  ApplicationAnalyticsMetaData,
} from '../../../../types/analytics';
import { useAnalyticsHook } from '../../../../hooks/useAnalyticsHook';

const AccHolderAppData = () => {
  const {
    onFilterCellClick,
    submitDateRange,
    onCloseCustomRangeClick,
    structuredGraphData,
    structuredMetaData,
    activeIdx,
    filterOptions,
  } = useAnalyticsHook<
    ApplicationAnalyticsGraphData,
    ApplicationAnalyticsMetaData,
    undefined
  >({
    graphProps: ['month', 'created_at', 'total_applications'],
    graphLabel: 'New Applications Created',
    graphQueryNames: {
      monthly: ANALYTICS_QUERY_NAME.CCMS_APPLICATION_CREATED_BY_MONTH,
      daily: ANALYTICS_QUERY_NAME.CCMS_NEW_APPLICATION_CREATED_BY_DATE,
    },
    metaDataQueryName: ANALYTICS_QUERY_NAME.CCMS_APPLICATION_CREATED_DATE_RANGE,
    filterOptionsType: 'shorter',
  });

  return (
    <Stack
      px={2}
      py={3}
      gap={4}
      sx={{
        backgroundColor: QUICK_ACCESS.background[50],
        border: `1px solid ${QUICK_ACCESS.border[50]}`,
        borderRadius: '16px',
      }}
    >
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="space-between"
        flex={1}
      >
        <PageHeadingTypo>Application Data</PageHeadingTypo>
        <VegaFilterButtonsV4
          data={filterOptions}
          activeIdx={activeIdx}
          onCellClick={onFilterCellClick}
          submitDateRange={submitDateRange}
          customIdx={0}
          onCloseCustomRangeClick={onCloseCustomRangeClick}
        />
      </Stack>
      <Stack direction="row">
        <Stack gap={2} flex={1} maxWidth={610}>
          <VegaMetricCardV4
            mainInfo={{
              text: structuredMetaData?.total_applications,
              label: 'Application Created',
              sx: {
                color: QUICK_ACCESS.primary.blue,
              },
              labelSx: {
                color: QUICK_ACCESS.primary.blue,
              },
            }}
            paperSx={{
              backgroundColor: PRIMARY_COLOR.blue[50],
            }}
            badge={{
              props: {
                text: 30,
              },
              helperText: 'from last day',
            }}
          />
          <Stack direction="row" gap={2} flexWrap={'wrap'}>
            <VegaMetricCardV4
              mainInfo={{
                text: structuredMetaData?.onboarded_applications,
                label: 'Application Completed',
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: 30,
                },
                helperText: 'from last day',
              }}
            />
            <VegaMetricCardV4
              mainInfo={{
                text: structuredMetaData?.in_progress_applications,
                label: 'Application Inprogress',
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: 0,
                },
                helperText: 'from last day',
              }}
            />
          </Stack>
          <Stack direction="row" gap={2} flexWrap={'wrap'}>
            <VegaMetricCardV4
              mainInfo={{
                text: structuredMetaData?.rejected_applications,
                label: 'Application Rejected',
                sx: {
                  color: QUICK_ACCESS.text[100],
                },
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: -30,
                },
                helperText: 'from last day',
              }}
            />
            <VegaMetricCardV4
              mainInfo={{
                text: structuredMetaData?.expired_applications,
                label: 'Application Expired',
                sx: {
                  color: QUICK_ACCESS.text[100],
                },
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: 30,
                },
                helperText: 'from last day',
              }}
            />
          </Stack>
        </Stack>
        <Stack flex={1}>
          <VegaLineChartV4
            YAxisLabels={{
              left: { text: 'New Applications Created' },
            }}
            mainData={structuredGraphData}
            linePropsArray={[
              {
                stroke: PRIMARY_COLOR.blue[300],
                dataKey: 'New Applications Created',
              },
            ]}
            XAxisData={{ dataKey: 'date' }}
            wrapperSx={{
              p: 2,
            }}
            respContainerData={{ height: 432 }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AccHolderAppData;
