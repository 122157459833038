import {
  TransactionCode,
  TransactionIndicator,
  TransactionStatus,
} from '../enums/transaction';
import { Currency } from './cardRefund';
import { ChargebackStatus } from './chargeBack';
import { VegaProgramNetworkType } from './program';

export interface TransactionToEmiPreview {
  transactionId: string;
  options: EMIPreview[];
}

export interface EMIPreview {
  installmentAmount: number;
  durationInMonths: number;
  interestRate: number;
}
interface TransactionMetaDataDto {
  merchantName: string;
  merchantBrandName: string;
  merchantLogo: string;
  categoryName: string;
  categoryLogo: string;
  categoryDescription: string;
}

export enum TransactionCategory {
  RETAIL = 'RETAIL',
  CASH = 'CASH',
  FEE = 'FEE',
  INTEREST = 'INTEREST',
  REPAYMENT = 'REPAYMENT',
  INTERNAL = 'INTERNAL',
  TCS = 'TCS',
  FOREX = 'FOREX',
}

export enum TransactionSource {
  MANUAL = 'MANUAL',
  NETWORK = 'NETWORK',
}

export enum LoanType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  COLENDED = 'COLENDED',
}

export enum LoanDispersalType {
  SINGLE_DISPERSAL = 'SINGLE_DISPERSAL',
  MULTIPLE_DISPERSAL = 'MULTIPLE_DISPERSAL',
}

export enum LoanStatus {
  INIT = 'INIT',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  CLOSED = 'CLOSED',
}

export enum WaiverStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export enum WaiverType {
  PARTIAL_WITHOUT_GST = 'PARTIAL_WITHOUT_GST',
  FULL_WITH_GST = 'FULL_WITH_GST',
}

export enum TransactionJourneyStatus {
  AUTHORIZED = 'AUTHORIZED',
  AUTH_NOT_REQUIRED = 'AUTH_NOT_REQUIRED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  SETTLED = 'SETTLED',
  FAILED = 'FAILED',
}

export enum ChargeCode {
  ANNUAL_MEMBERSHIP_FEE = 'ANNUAL_MEMBERSHIP_FEE',
  LATE_FEE = 'LATE_FEE',
  OVERLIMIT_FEE = 'OVERLIMIT_FEE',
  CASH_ADVANCE_FEE = 'CASH_ADVANCE_FEE',
  LOAN_PROCESSING_FEE = 'LOAN_PROCESSING_FEE',
  INSTALLMENT_DUE_FEE = 'INSTALLMENT_DUE_FEE',
  PRECLOSURE_FEE = 'PRECLOSURE_FEE',
  PHYSICAL_STATEMENT_FEE = 'PHYSICAL_STATEMENT_FEE',
  REWARDS_REDEMPTION_FEE = 'REWARDS_REDEMPTION_FEE',
  DISPUTE_FEE = 'DISPUTE_FEE',
  BILL_TO_EMI_FEE = 'BILL_TO_EMI_FEE',
  TRANSACTION_TO_EMI_FEE = 'TRANSACTION_TO_EMI_FEE',
  ADDITIONAL_CARD_FEE = 'ADDITIONAL_CARD_FEE',
  REISSUE_CARD_FEE = 'REISSUE_CARD_FEE',
  LOAN_PRE_CLOSURE_FEE = 'LOAN_PRE_CLOSURE_FEE',
  FOREIGN_CURRENCY_FEE = 'FOREIGN_CURRENCY_FEE',
  BALANCE_TRANSFER_PROCESSING_FEE = 'BALANCE_TRANSFER_PROCESSING_FEE',
  CHEQUE_RETURN_FEE = 'CHEQUE_RETURN_FEE',
  ECS_RETURN_FEE = 'ECS_RETURN_FEE',
  NACH_RETURN_FEE = 'NACH_RETURN_FEE',
  CHARGE_SUMMARY_RETRIEVAL_FEE = 'CHARGE_SUMMARY_RETRIEVAL_FEE',
  CARD_VALIDATION_FEE = 'CARD_VALIDATION_FEE',
  FOREIGN_CURRENCY_DCC = 'FOREIGN_CURRENCY_DCC',
  FOREIGN_CURRENCY_CCC = 'FOREIGN_CURRENCY_CCC',
  REWARDS_REINSTATEMENT_FEE = 'REWARDS_REINSTATEMENT_FEE',
  CASH_REPAYMENT_FEE = 'CASH_REPAYMENT_FEE',
  DAIL_A_DRAFT_FEE = 'DAIL_A_DRAFT_FEE',
  SURCHARGE_FEE = 'SURCHARGE_FEE',
}

export interface TransactionDto {
  id: string;
  accountId: string;
  parentTransactionId: string | null;
  amount: number;
  rePaidAmount: number;
  overlimitUsed: number;
  currency: string;
  transactionIndicator: TransactionIndicator;
  transactionCategory?: TransactionCategory;
  transactionCode: TransactionCode;
  providerTxnId: string;
  txnReferenceId: string;
  merchantName: string;
  narration: string;
  status: TransactionStatus;
  substatus: string;
  transactionTime: string;
  createdAt: string;
  updatedAt: string;
  loanId: string;
  installmentId: string;
  loanConversionDate: string;
  emiConvertible: boolean;
  afterTransactionBalance: number;
  mcc: number;
  mid: number;
  lat: number;
  lng: number;
  attributes: string;
  cardProcessor: string;
  cardId: string;
  merchantId: string;
  metaData?: TransactionMetaDataDto;
  merchantLocation: string;
  latestChargebackId: string;
  chargebackStatus: ChargebackStatus;
  source: TransactionSource;
  addedBy: string;
  txnProxyId: string;
}

export interface ListTransactionsFilterRequestDto {
  accountId?: string;
  programId?: string;
  parentTransactionId?: string;
  transactionCategory?: string;
  status?: string;
  page?: number;
  pageSize?: number;
  startTime?: string;
  endTime?: string;
  statementDate?: string;
  emiConvertible?: boolean;
  includeMetaData?: boolean;
}

export interface LoanDto {
  id: string;
  account_id: string;
  program_id: string;
  installment_policy_id: any;
  loan_amount: number;
  loan_type: LoanType;
  loan_dispersal_type: LoanDispersalType;
  lan: any;
  parent_loan_id: any;
  lender_id: string;
  loan_percentage: number;
  currency: string;
  interest_rate: number;
  interest_type: string;
  interest_amount: number;
  repayment_amount: number;
  installment_amount: any;
  installment_count: number;
  loan_start_date: string;
  over_due_amount: number;
  interest_accure: any;
  due_principal: number;
  application_id: any;
  lending_type: any;
  child_loans: any;
  first_emi_date: any;
  paid_amount: any;
  surplus: number;
  status: LoanStatus;
  tentative_loan_closing_date: any;
  loan_closed_date: any;
  tenure: number;
  tenure_unit: string;
  first_installment_payment: any;
  installment_frequency: any;
  amortization_type: string;
  gap_interest: any;
  re_amortisation_required: any;
  file_id: any;
  created_at: string;
  updated_at: string;
}

export interface TransactionChargesDto {
  currency: Currency;
  id: string;
  chargePolicyId?: string;
  accountId: string;
  eventReferenceId: string;
  chargeAmount: number;
  baseCharge: number;
  totalTax: number;
  dueAmount: number;
  paidAmount: number;
  chargeCode: ChargeCode;
  transactionCode: TransactionCode;
  popPincode: string;
  posPincode: string;
  igstTax: string;
  sgstTax: string;
  cgstTax: string;
  status: string;
  accruedAt: string;
  paidAt: string;
  description: string;
  invoiceId: string;
  invoiceLink: string;
  state: string;
  country: string;
  createdAt: string;
  updatedAt: string;
}

export interface MccInfoResponse {
  code: number;
  description: string;
  category: string;
  risk: string;
}

export interface IMccCodesProps {
  code: number;
  description: string;
  paymentBrands: VegaProgramNetworkType[];
  logo: File;
  category: MCCCodeCategory;
}

export interface MCCCodeCategory {
  id: string;
  name: string;
  riskProfile: string;
}

export interface TransactionChargeResponse {
  transactionId: string;
  chargeDto: TransactionChargesDto;
}

export interface TransactionJourneyResponse {
  id: string;
  accountId: string;
  transactionId: string;
  status: TransactionJourneyStatus;
  updateTime: string;
  createdAt: string;
  updatedAt: string;
}

export interface WaiverDto {
  id: string;
  accountId: string;
  chargeTxnId: string;
  chargeWaiverTxnId: string;
  taxesWaiverTxnId: string;
  currency: Currency;
  waiverAmount: number;
  waiverType: WaiverType;
  chargeAmount: number;
  status: WaiverStatus;
  addedBy: string;
  remarks: string;
  chargeTxnTime: string;
  createdAt: string;
  updatedAt: string;
}

export interface MidCodeDto {
  mid: string;
  id: string;
}

export interface TidDto {
  tid: string;
  id: string;
}

export interface MerchantDto {
  id: string;
  name: string;
  brandName: string;
  logo: File;
  mids: MidCodeDto[];
  tids: TidDto[];
  mccCodes: IMccCodesProps[];
}
