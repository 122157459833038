import { ISVGProps } from '../../mcc/types/Commons';

const PhoneIcon = (props: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <path
        d="M10.3317 9.38551C10.3317 9.38551 9.75233 9.95451 9.61036 10.1213C9.37909 10.3681 9.10659 10.4847 8.74938 10.4847C8.71503 10.4847 8.67839 10.4847 8.64404 10.4824C7.96396 10.439 7.33196 10.1739 6.85796 9.94766C5.56191 9.32152 4.42385 8.43259 3.47815 7.306C2.69731 6.3668 2.17522 5.49843 1.82945 4.56608C1.6165 3.99708 1.53864 3.55375 1.57299 3.13557C1.59589 2.8682 1.69893 2.64654 1.88899 2.45687L2.66983 1.67763C2.78203 1.57251 2.9011 1.51538 3.01789 1.51538C3.16215 1.51538 3.27893 1.60222 3.3522 1.67534C3.35449 1.67763 3.35678 1.67991 3.35907 1.6822C3.49875 1.81245 3.63157 1.94728 3.77125 2.09124C3.84223 2.16437 3.91551 2.23749 3.98878 2.31291L4.61391 2.93676C4.85663 3.17898 4.85663 3.40293 4.61391 3.64516C4.54751 3.71143 4.48339 3.7777 4.41698 3.84168C4.22464 4.03821 4.37574 3.88742 4.17652 4.06566C4.17194 4.07023 4.16736 4.07252 4.16507 4.07709C3.96814 4.27361 4.00478 4.46556 4.046 4.59582C4.04829 4.60267 4.05058 4.60953 4.05287 4.61639C4.21545 5.00944 4.44443 5.37963 4.79249 5.82067L4.79478 5.82296C5.42678 6.59991 6.09312 7.20548 6.82816 7.66937C6.92205 7.72879 7.01822 7.77678 7.10982 7.82248C7.19225 7.86361 7.2701 7.90246 7.33651 7.94359C7.34567 7.94816 7.35483 7.95502 7.36399 7.95959C7.44184 7.99844 7.51512 8.01672 7.59068 8.01672C7.78074 8.01672 7.89981 7.89789 7.93874 7.85904L8.38758 7.41112C8.46544 7.33342 8.58909 7.23973 8.73335 7.23973C8.87532 7.23973 8.9921 7.32885 9.06309 7.40655C9.06538 7.40883 9.06538 7.40883 9.06767 7.41112L10.3294 8.67025C10.5652 8.90334 10.3317 9.38551 10.3317 9.38551Z"
        stroke={props.stroke || '#676B76'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
