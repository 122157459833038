/** @format */

import { getEnv } from './constants/url';
import { ENV } from './enums/commons';

export const COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_BQjAePmed',
  APP_CLIENT_ID: '7j9br921aff0cspse6pjhc2vp7',
};

export const DEV_COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_8afkpIMGf',
  APP_CLIENT_ID: '7qi6r2cf60ah35at75mc0tklrs',
};

export const SANDBOX_COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_d9Sdy9CTG',
  APP_CLIENT_ID: '6qdhl94p004b8t2lj2sgb70ev5',
};

export const getCognitoConfiguration = () => {
  const env = getEnv();

  let cognito: {
    REGION?: string;
    USER_POOL_ID?: string;
    APP_CLIENT_ID?: string;
  } = {};

  switch (env) {
    case ENV.DEV:
      cognito = DEV_COGNITO;
      break;
    case ENV.SANDBOX:
      cognito = SANDBOX_COGNITO;
      break;
    case ENV.PROD:
      cognito = COGNITO;
      break;
  }

  return {
    aws_cognito_region: cognito.REGION,
    aws_user_pools_id: cognito.USER_POOL_ID,
    aws_user_pools_web_client_id: cognito.APP_CLIENT_ID,
  };
};
