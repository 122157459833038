import { Stack } from '@mui/material';
import SideNavigationMenuV1 from '../../../../../../common/SideNavigationMenuV1';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IBillResponseData } from '../../../../../../../types/bill';
import { useCallback, useState } from 'react';
import {
  PageHeadingTypo,
  SectionHeadingTypo,
} from '../../../../../../common/Typography';
import { QUICK_ACCESS } from '../../../../../../../constants/style';
import VegaButtonV4 from '../../../../../../common/v4/VegaButtonV4';
import FileCheckIcon from '../../../../../../icon/FileCheckIcon';
import ShareIcon from '../../../../../../icon/ShareIcon';
import DownloadIcon from '../../../../../../icon/DownloadIcon';
import BillDetailsTransactions from './BillDetailsTransactions';
import BillDetailsOverview from './BillDetailsOverview';
import BillDetailsEMI from './BillDetailsEMI';
import BillDetailsRepayment from './BillDetailsRepayment';
import { useSnackbar } from '../../../../../../../providers/SnackbarProvider';
import {
  generatePhysicalStatement,
  getBillPdf,
  getDigitalBillPdf,
} from '../../../../../../../api/bill';

interface IBillDetailMainProps {
  data: IBillResponseData | undefined;
  goBack: () => void;
}

type MenuItemType = 'overview' | 'trans' | 'emi' | 'repay';

const BillDetailMain = ({ data, goBack }: IBillDetailMainProps) => {
  const [menu, setMenu] = useState<MenuItemType>('overview');
  const { setSnackbar } = useSnackbar();

  const menuItem = useCallback(() => {
    switch (menu) {
      case 'overview':
        return <BillDetailsOverview data={data} />;
      case 'trans':
        return <BillDetailsTransactions data={data} />;
      case 'emi':
        return <BillDetailsEMI data={data} />;
      default:
        return <BillDetailsRepayment data={data} />;
    }
  }, [menu, data]);

  const downloadBill = async () => {
    if (data?.id) {
      try {
        getBillPdf(data.id).then((res) => {
          const link = res.data?.url;
          window.open(link);
        });
      } catch (err) {
        setSnackbar('Not able to extract bill', 'error');
      }
    }
  };

  const shareBill2Customer = () => {
    if (data?.id) {
      getDigitalBillPdf(data?.id)
        .then(() => {
          setSnackbar('Bill successfully shared with customer');
        })
        .catch(() => {
          setSnackbar('Not able to share bill to customer', 'error');
        });
    }
  };

  const requestPhysicalStatement = () => {
    if (data?.id) {
      generatePhysicalStatement(data.id)
        .then(() => {
          setSnackbar(
            'Physical Statement for the bill has been successfully generated!'
          );
        })
        .catch(() => {
          setSnackbar(
            'Not able to generate Physical Statement for the bill',
            'error'
          );
        });
    }
  };

  return (
    <SideNavigationMenuV1
      header={
        <Stack
          py={2}
          px={2.5}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          borderBottom={`1px solid ${QUICK_ACCESS.border[50]}`}
        >
          <Stack direction="row" gap={1.5} alignItems="center">
            <ArrowBackIcon
              fontSize="small"
              onClick={goBack}
              className="cursor-pointer"
            />
            <Stack direction="row" gap={1} alignItems="center">
              <PageHeadingTypo>Bill ID -</PageHeadingTypo>
              <SectionHeadingTypo>{data?.id}</SectionHeadingTypo>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <VegaButtonV4
              startIcon={<FileCheckIcon />}
              onClick={requestPhysicalStatement}
            >
              Request Physical Statement
            </VegaButtonV4>
            <VegaButtonV4
              startIcon={<ShareIcon />}
              onClick={shareBill2Customer}
            >
              Share bill to customer
            </VegaButtonV4>
            <VegaButtonV4
              startIcon={<DownloadIcon />}
              variant="mellowed"
              onClick={downloadBill}
            >
              Download bill
            </VegaButtonV4>
          </Stack>
        </Stack>
      }
      menuItem={menuItem()}
      menuSx={{
        height: `calc(100% - 35px)`,
      }}
      menu={menu}
      setMenu={setMenu}
      menuValues={MenuValues}
    />
  );
};

const MenuValues = [
  {
    value: 'Overview',
    key: 'overview',
  },
  { value: 'Transactions', key: 'trans' },
  { value: 'EMIs', key: 'emi' },
  { value: 'Repayments', key: 'repay' },
];

export default BillDetailMain;
