import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MakerCheckerItem from '../MakerCheckerItem';
import { VegaUser, VegaUserStatus } from '../../../types/user';
import VegaDialogV4 from '../../common/v4/VegaDialogV4';
import Stack from '@mui/material/Stack';
import { SectionHeadingTypo } from '../../common/Typography';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import { QUICK_ACCESS } from '../../../constants/style';
import {
  fetchListOfUsersForClient,
  updateUserStatus,
} from '../../../api/roleManagement';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { setSelectedProgram } from '../../../store/common/programManagementSlice';
import EditContainedIcon from '../../icon/EditContainedIcon';
import VegaDrawerV4 from '../../common/v4/VegaDrawerV4';
import VegaSelectV4, {
  VegaSelectOptionType,
} from '../../common/v4/VegaSelectV4';
import { useClientAuth } from '../../../providers/ClientProvider';

type ActiveUserToggleType = 'maker' | 'checker' | undefined;
function MakerChecker() {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const activeProgram = useAppSelector(
    (state) => state.programManagement.selectedProgram
  );
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [teamOptions, setTeamOptions] = useState<VegaSelectOptionType[]>([]);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [selectedMakerId, setSelectedMakerId] = useState('');
  const [selectedCheckerId, setSelectedCheckerId] = useState('');
  const [activeUserToToggle, setActiveUserToToggle] =
    useState<ActiveUserToggleType>(undefined);

  const [selectedUser, setSelectedUser] = useState<VegaUser | undefined>();

  const onToggleClicked = (
    activeType: ActiveUserToggleType,
    user: VegaUser
  ) => {
    setSelectedUser(user);
    setActiveUserToToggle(activeType);
  };

  const toggleUserStatus = () => {
    if (!selectedUser || !activeUserToToggle || !activeProgram) return;
    const { userId, status } = selectedUser;
    const newStatus =
      status === VegaUserStatus.ACTIVE
        ? VegaUserStatus.INACTIVE
        : VegaUserStatus.ACTIVE;
    updateUserStatus({
      userId: userId,
      status: newStatus,
    })
      .then((res) => {
        dispatch(
          setSelectedProgram({
            ...activeProgram,
            [activeUserToToggle]: { ...selectedUser, status: newStatus },
          })
        );

        setSnackbar('Successfully updated status');
      })
      .catch((err) => {
        console.log(err);

        setSnackbar('Failed to update status', 'error');
      })
      .finally(() => {
        setActiveUserToToggle(undefined);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    function fetchClientTeams() {
      if (!clientId) {
        return;
      }
      fetchListOfUsersForClient(clientId)
        .then((response) => {
          const team = response.data as VegaUser[];
          const mappedOptions: VegaSelectOptionType[] = team.map((user) => ({
            id: user.userId,
            name: user.name,
          }));
          setTeamOptions(mappedOptions);
        })
        .catch((error) => {
          setSnackbar('Error while fetching users', 'error');
        });
    }
    fetchClientTeams();
  }, []);

  return (
    <>
      <Stack gap={5}>
        <Stack gap={3} pb={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className="font-aspekta-600" fontSize={17}>
              Maker
            </Typography>
            <VegaButtonV4
              value="Edit"
              variant="outlined"
              startIcon={<EditContainedIcon />}
              onClick={() => setIsEditDrawerOpen(true)}
            />
          </Stack>
          <Stack gap={2}>
            <MakerCheckerItem
              user={activeProgram?.maker}
              onToggleClick={() =>
                onToggleClicked('maker', activeProgram?.maker!!)
              }
            />
          </Stack>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            pb: 2,
          }}
        >
          <Typography className="font-aspekta-600" fontSize={17}>
            Checker
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <MakerCheckerItem
              user={activeProgram?.checker}
              onToggleClick={() =>
                onToggleClicked('checker', activeProgram?.checker!!)
              }
            />
          </Box>
        </Box>
      </Stack>
      <VegaDialogV4
        open={!!activeUserToToggle && !!selectedUser}
        title={
          activeUserToToggle === 'maker' ? 'Maker Status' : 'Checker Status'
        }
        closeBtnRequired
        onClose={() => setActiveUserToToggle(undefined)}
      >
        <Stack>
          <Stack px={5} py={4}>
            <SectionHeadingTypo>{`Are you sure you want to make ${
              selectedUser?.name
            } ${
              selectedUser?.status === VegaUserStatus.ACTIVE
                ? 'inactive'
                : 'active'
            }`}</SectionHeadingTypo>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            px={5}
            py={3}
            borderTop={`1px solid ${QUICK_ACCESS.border[50]}`}
          >
            <VegaButtonV4
              value="No Thanks"
              variant="text"
              onClick={() => setActiveUserToToggle(undefined)}
            />
            <VegaButtonV4
              value={
                selectedUser?.status === VegaUserStatus.ACTIVE
                  ? 'Inactive'
                  : 'Active'
              }
              variant="contained"
              loading={isLoading}
              onClick={() => toggleUserStatus()}
            />
          </Stack>
        </Stack>
      </VegaDialogV4>
      <VegaDrawerV4
        open={isEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}
        header="Edit Maker-Checker"
        submitBtn={{
          show: true,
          props: {
            value: 'Edit Maker-Checker',
            disabled: !selectedCheckerId || !selectedMakerId,
          },
        }}
      >
        <Stack gap={3}>
          <VegaSelectV4
            options={teamOptions}
            header="Maker"
            standardWidth={400}
            value={selectedMakerId}
            onChange={(e) => setSelectedMakerId(e.target.value as string)}
          />
          <VegaSelectV4
            options={teamOptions}
            header="Checker"
            standardWidth={400}
            value={selectedCheckerId}
            onChange={(e) => setSelectedCheckerId(e.target.value as string)}
          />
        </Stack>
      </VegaDrawerV4>
    </>
  );
}

export default MakerChecker;
