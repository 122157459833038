import React, { useState } from 'react';
import { Tooltip, Box, Typography, Grid } from '@mui/material';
import {
  CUSTOM_DATA,
  INITIAL_DATA,
  ITeamsProps,
  IUserRoleProps,
} from '../../pages/MCCTeamManagement';
import { BORDER_GRAY } from '../../../../constants/style';
import VegaLabel from '../../../../components/common/VegaLabel';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import { VegaCard, VegaTable } from '../../../../components/common';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import { toLowerCase } from '../../../../constants/commonFunction';
import { dateFormat } from '../../../variable';
import VegaModal from '../../../../components/common/VegaModal';
import { AddCircleOutline } from '@mui/icons-material';
import VegaSelect from '../../../../components/common/VegaSelect';
import BranchSelector from '../../../components/BranchSelector';
import CorporateSelector from '../../../components/CorporateSelector';
import {
  UserPlatformEnums,
  UserRoleEnums,
} from '../../../Program-Dashboard/api/roles';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { ICorporateEntity } from '../../../types/CorporateCustomer';

interface IProps {
  state: any;
  loading: boolean;
  onSendChecker: (data: any) => void;
  onUpdateSendChecker: (data: any) => void;
  allocateRoleForUser: (userId: string, platformId: string) => void;
  setRoleDetails: React.Dispatch<React.SetStateAction<IUserRoleProps>>;
  defaultBranchUser: IUserRoleProps;
  defaultCorporateAdmin: IUserRoleProps;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  rowsCount: number;
}

interface IAllocationRoleProps {
  roleType: string;
  platformId: string;
  platformName: string;
}

const ListOfTeamManagement = ({
  state,
  onSendChecker,
  onUpdateSendChecker,
  loading,
  setRoleDetails,
  defaultBranchUser,
  defaultCorporateAdmin,
  allocateRoleForUser,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  rowsCount,
}: IProps) => {
  const { mccPlatformId } = useClientAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [openAllocateRole, setOpenAllocateRole] = useState<boolean>(false);
  const [allocation, setAllocation] = useState<IAllocationRoleProps>({
    platformId: '',
    roleType: '',
    platformName: UserPlatformEnums.FOREX_BRANCH,
  });

  const [customerDetails, setCustomerDetails] = useState<ITeamsProps>({
    ...INITIAL_DATA,
  });

  const getButtons = (
    status: string,
    checkerMakerStatus: string,
    data: any
  ) => {
    if (status === 'DRAFT') {
      return {
        action: (
          <VegaContainedButton
            text="Send to Checker"
            onClick={() => onSendChecker(data)}
            isPrimary
          />
        ),
      };
    }
    if (status === 'UNDER_REVIEW' && checkerMakerStatus === 'CHECKER_PENDING') {
      return {
        action: (
          <VegaContainedButton text="sent to checker" isPrimary disabled />
        ),
      };
    }
    if (
      status === 'UNDER_REVIEW' &&
      checkerMakerStatus === 'CHANGES_REQUESTED'
    ) {
      return {
        action: (
          <VegaContainedButton
            text="Send to Checker"
            onClick={() => onUpdateSendChecker(data)}
            isPrimary
          />
        ),
      };
    }
    if (checkerMakerStatus === 'APPROVED') {
      return {
        action: (
          <VegaContainedButton
            text={toLowerCase(checkerMakerStatus)}
            isSuccess
            disabled
          />
        ),
      };
    }
  };
  const columnDef = [
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),
      flex: 0.6,
    },
    {
      field: 'userId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          User ID
        </Typography>
      ),

      flex: 0.5,
    },
    {
      field: 'email',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Email
        </Typography>
      ),

      flex: 1,
    },
    {
      field: 'userRoles',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Alloted Roles
        </Typography>
      ),
      flex: 1,
      cellClassName: 'tooltip',
      renderCell: (props: any) => {
        return props.row.userRoles.length ? (
          props.row.userRoles.map((role: any) => (
            <div className="" title={toLowerCase(role?.status)}>
              <VegaLabel text={toLowerCase(role?.roleName)} color={'primary'} />
            </div>
          ))
        ) : (
          <VegaContainedButton
            text="Allocate Role"
            isPrimary
            onClick={() => allocateRole(props.row)}
          />
        );
      },
    },
    // {
    //   field: 'activationDate',
    //   headerName: <Typography className="font-aspekta-500" variant="body2">Activation Date</Typography>,
    //   flex: 0.7,
    //   renderCell: (props: any) => {
    //     return (
    //       <VegaLabel text={dateFormat(props.row.createdAt)} color={'success'} />
    //     );
    //   },
    // },
    // {
    //   field: 'status',
    //   headerName: (
    //     <Typography className="font-aspekta-500" variant="body2">
    //       Status
    //     </Typography>
    //   ),
    //   flex: 0.7,
    //   renderCell: (props: any) => {
    //     const roles = props.row.userRoles[0];
    //     const status = props.row.userRoles[0]?.status;
    //     const checkerMakerStatus = props.row.userRoles[0]?.checkerMakerStatus;
    //     return getButtons(status, checkerMakerStatus, roles)?.status;
    //   },
    // },
    {
      field: 'isDraft',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        const roles = props.row.userRoles[0];
        const status = props.row.userRoles[0]?.status;
        const checkerMakerStatus = props.row.userRoles[0]?.checkerMakerStatus;
        return props.row.userRoles.length
          ? getButtons(status, checkerMakerStatus, roles)?.action
          : // <VegaContainedButton
            //   text="View Roles"
            //   onClick={() => showDetails(props.row.userRoles)}
            //   isPrimary
            // />
            '';
      },
      flex: 0.7,
    },

    // {
    //   field: 'action',
    //   headerName: <Typography className="font-aspekta-500" variant="body2">Action</Typography>,
    //   renderCell: (props: any) => {
    //     return (
    //       <VegaContainedButton
    //         text="edit"
    //         onClick={() => showDetails(props.row)}
    //         isPrimary
    //       />
    //     );
    //   },
    //   flex: 0.7,
    // },
  ];
  const roleColumn = [
    {
      field: 'roleName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Role Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return <Typography>{toLowerCase(props.row.roleName)}</Typography>;
      },
    },

    {
      field: 'status',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        return (
          <Box position={'relative'}>
            <VegaLabel text={toLowerCase(props.row.status)} color={'primary'} />
            {show && (
              <Box position={'absolute'} bottom={0}>
                <VegaLabel
                  text={toLowerCase(props.row.status)}
                  color={'primary'}
                />
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      field: 'isDraft',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          props.row.status === 'DRAFT' && (
            <VegaContainedButton
              text="Send Checker"
              onClick={() => {
                onSendChecker(props.row);
                setOpen(!open);
              }}
              isPrimary
            />
          )
        );
      },
      flex: 0.7,
    },
  ];

  const handleRoleChange = (value: UserRoleEnums) => {
    handleChange('roleType', value);
    handleChange('platformId', '');

    if (value === UserRoleEnums.HQ_USER) {
      handleChange('platformId', mccPlatformId);
    }
  };

  const handleBranch = (name: keyof IAllocationRoleProps, value: any) => {
    // setBranchCode(value.branchCode);
    handleChange(name, value.branchId || '');
  };
  const handleCorporate = (value: ICorporateEntity | null) => {
    handleChange('platformId', value?.id || '');
  };

  const handleChange = (
    key: keyof IAllocationRoleProps,
    value: string | File | null
  ) => {
    // apply filter on platformName -> UserPlatformEnums
    setAllocation((pre) => {
      let platformName = pre.platformName;
      if (key === 'roleType') {
        switch (value) {
          // case UserRoleEnums.HQ_USER:
          //   platformName = UserPlatformEnums.FOREX_HQ;
          //   setRoleDetails({ ...DEFAUL_HQ_USERS });
          //   break;
          case UserRoleEnums.CORPORATE_ADMIN:
            platformName = UserPlatformEnums.FOREX_CORPORATE;
            setRoleDetails({ ...defaultCorporateAdmin });
            break;
          // case UserRoleEnums.BRANCH_ADMIN:
          //   setRoleDetails({ ...DEFUALT_BRANCH_ADMIN });
          //   break;
          default:
            // default branch user
            platformName = UserPlatformEnums.FOREX_BRANCH;
            setRoleDetails({ ...defaultBranchUser });
        }
      }

      return {
        ...pre,
        [key]: value,
        platformName,
      };
    });
  };

  const showDetails = (data: ITeamsProps) => {
    setCustomerDetails(data);
    setOpen(!open);
  };

  const onAllocateOpen = () => {
    setOpenAllocateRole(!openAllocateRole);
  };
  const allocateRole = (data: ITeamsProps) => {
    setUserId(data.userId);
    onAllocateOpen();
  };
  const decline = () => {
    setOpen(!open);
  };
  const onClose = () => {
    onAllocateOpen();
    setAllocation({ platformId: '', roleType: '', platformName: '' });
  };

  const isButtonDisabled = allocation.platformId === '';
  console.log('isButtonDisabled', isButtonDisabled, allocation);
  return (
    <VegaCard fullHeight noPad>
      <VegaTable
        data={state}
        columnDef={columnDef}
        idColumn={'userId'}
        loading={loading}
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        rowCount={rowsCount}
      />
      <VegaDrawer
        open={open}
        header={'User Id'}
        closeText="Close"
        isClose
        onClose={decline}
      >
        <VegaTable
          data={customerDetails}
          columnDef={roleColumn}
          idColumn={'id'}
        />
      </VegaDrawer>
      <VegaModal
        header={'Allocate Role'}
        open={openAllocateRole}
        handleClose={onClose}
        submitText={'Allocate'}
        disabled={isButtonDisabled}
        handleSubmit={async () => {
          await allocateRoleForUser(userId, allocation.platformId);
          onClose();
        }}
        buttonIcon={<AddCircleOutline />}
      >
        <Box mx={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <VegaSelect
                options={['BRANCH_USER', 'CORPORATE_ADMIN']}
                key="roleType"
                selected={allocation.roleType}
                label="Select Role"
                onChange={(value) => handleRoleChange(value as UserRoleEnums)}
              />
            </Grid>

            {(allocation.roleType === UserRoleEnums.BRANCH_ADMIN ||
              allocation.roleType === UserRoleEnums.BRANCH_USER) && (
              <Grid item xs={12} sm={12}>
                <BranchSelector
                  name="branchId"
                  selected={allocation.platformId ?? ''}
                  handleChange={(value) => handleBranch('platformId', value)}
                />
              </Grid>
            )}
            {allocation.roleType === UserRoleEnums.CORPORATE_ADMIN && (
              <Grid item xs={12} sm={12}>
                <CorporateSelector
                  name="corporateId"
                  selected={allocation.platformId}
                  handleChange={(value) => handleCorporate(value)}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </VegaModal>
    </VegaCard>
  );
};

export default ListOfTeamManagement;
