import { Box, Divider, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  ActionLargeTypo,
  ComponentBody1Typo,
  PageHeadingTypo,
  SectionHeadingTypo,
} from '../../../../../common/Typography';
import VegaCardV4_2 from '../../../../../common/v4/VegaCardV4_2';
import VegaBadgeStatusTagV4 from '../../../../../common/v4/VegaBadgeStatusTagV4';
import { QUICK_ACCESS, VEGA_COMMON } from '../../../../../../constants/style';
import { getEMIStatusColor } from '../../../supportUtils';
import { EMIData } from '../../../../../../types/emis';
import dayjs from 'dayjs';
import { DATE_FORMATE_SHORT } from '../../../../../../constants/url';
import { useLoanQuery } from '../../../../../../store/queries/loanQueries';
import { convert2IndianNumberSystem } from '../../../../../../utils/stringUtils';

interface IEMIDetailsProps {
  data: EMIData;
  goBack: () => void;
}

const EMIDetails = ({ data, goBack }: IEMIDetailsProps) => {
  const { data: loanData, isFetching } = useLoanQuery({
    loanId: data.loan_id || '',
  });

  const emiStatusDetails = [
    {
      key: 'Due amount',
      value: data.installment_amount,
    },
    {
      key: 'Start-End date',
      value: `${
        data.created_at
          ? dayjs(data.created_at).format(DATE_FORMATE_SHORT)
          : '--'
      } - ${
        data.due_date ? dayjs(data.due_date).format(DATE_FORMATE_SHORT) : '--'
      }`,
    },
    {
      key: 'Generation date',
      value: data.created_at
        ? dayjs(data.created_at).format(DATE_FORMATE_SHORT)
        : '--',
    },
  ];

  const otherEMIDetails = [
    {
      key: 'EMI principal',
      value: convert2IndianNumberSystem(data.principle),
    },
    {
      key: 'Interest charges',
      value: (loanData?.interest_amount ?? '--') + '%',
    },
    {
      key: 'EMI interest',
      value: '₹--',
    },
    {
      key: 'Outstanding principal',
      value: convert2IndianNumberSystem(loanData?.due_principal),
    },
    {
      key: 'Outstanding interest',
      value: convert2IndianNumberSystem(loanData?.gap_interest),
    },
  ];

  const emiPaymentStatusDetails = [
    {
      key: 'Due date',
      value: dayjs(data.due_date).format(DATE_FORMATE_SHORT),
    },
    {
      key: 'Repayment date',
      value: '--',
    },
  ];

  return (
    <Box mx={-2.5} my={-2} height={'100%'}>
      <Stack
        py={2}
        px={2.5}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Stack direction="row" gap={1.5} alignItems="flex-start">
          <ArrowBackIcon
            fontSize="small"
            onClick={goBack}
            className="cursor-pointer"
          />
          <Stack gap={0.5}>
            <Stack direction="row" gap={1} alignItems="center">
              <PageHeadingTypo>EMI ID - </PageHeadingTypo>
              <SectionHeadingTypo>{data.id}</SectionHeadingTypo>
            </Stack>
            <ComponentBody1Typo sx={{ color: QUICK_ACCESS.text[100] }}>
              Loan ID - {data.loan_id}
            </ComponentBody1Typo>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ height: `calc(100% - 40px)` }} px={3}>
        <Stack direction="row" gap={2.5} alignItems="flex-start">
          <Stack>
            <VegaCardV4_2 sx={{ minWidth: 315 }}>
              <Stack gap={1.5}>
                <Stack direction="row" gap={5} alignItems="center">
                  <ActionLargeTypo
                    sx={{ color: QUICK_ACCESS.text[200] }}
                    width={160}
                  >
                    EMI status
                  </ActionLargeTypo>
                  <VegaBadgeStatusTagV4
                    text={data.status}
                    color={getEMIStatusColor(data.status)}
                    variant="stroked"
                  />
                </Stack>
                {emiStatusDetails.map((item) => (
                  <Stack
                    direction="row"
                    gap={5}
                    alignItems="center"
                    key={item.key}
                  >
                    <ComponentBody1Typo
                      sx={{ color: QUICK_ACCESS.text[100] }}
                      width={160}
                    >
                      {item.key}
                    </ComponentBody1Typo>
                    <ComponentBody1Typo sx={{ wordWrap: 'break-word' }}>
                      {item.value}
                    </ComponentBody1Typo>
                  </Stack>
                ))}
              </Stack>
            </VegaCardV4_2>
            <Stack gap={1.5} p={2}>
              {emiStatusDetails.map((item) => (
                <Stack
                  direction="row"
                  gap={5}
                  alignItems="center"
                  key={item.key}
                >
                  <ComponentBody1Typo
                    sx={{ color: QUICK_ACCESS.text[100] }}
                    width={160}
                  >
                    {item.key}
                  </ComponentBody1Typo>
                  <ComponentBody1Typo sx={{ wordWrap: 'break-word' }}>
                    {item.value}
                  </ComponentBody1Typo>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Divider
            sx={{
              borderStyle: 'dashed',
              borderColor: VEGA_COMMON.text100,
            }}
            orientation="vertical"
          />
          <VegaCardV4_2 sx={{ minWidth: 315 }}>
            <Stack gap={1.5}>
              <Stack direction="row" gap={5} alignItems="center">
                <ActionLargeTypo
                  sx={{ color: QUICK_ACCESS.text[200] }}
                  width={160}
                >
                  EMI payment status
                </ActionLargeTypo>
                <VegaBadgeStatusTagV4
                  text={data.status}
                  color={getEMIStatusColor(data.status)}
                  variant="text"
                />
              </Stack>
              {emiPaymentStatusDetails.map((item) => (
                <Stack
                  direction="row"
                  gap={5}
                  alignItems="center"
                  key={item.key}
                >
                  <ComponentBody1Typo
                    sx={{ color: QUICK_ACCESS.text[100] }}
                    width={160}
                  >
                    {item.key}
                  </ComponentBody1Typo>
                  <ComponentBody1Typo sx={{ wordWrap: 'break-word' }}>
                    {item.value}
                  </ComponentBody1Typo>
                </Stack>
              ))}
            </Stack>
          </VegaCardV4_2>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EMIDetails;
