/** @format */

import { useMemo, useState } from 'react';
import {
  ResourceType,
  useInstitutionalResources,
} from '../../hooks/useInstitutionalResources';
import { Mcg, RiskProfiles } from '../../types/resources';
import EditCategoryRiskModal from './EditCategoryRiskModal';
import { useQueryClient } from 'react-query';
import { useSearchContext } from './searchValueContext';
import VegaDataGridV4 from '../common/v4/VegaDataGridV4';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { convertCamelCase } from '../../utils/stringUtils';
import VegaBadgeStatusTagV4, {
  BadgeStatusTagColor,
} from '../common/v4/VegaBadgeStatusTagV4';
import VegaButtonV4 from '../common/v4/VegaButtonV4';
import EditIconV4 from '../iconography/EditIconV4';
import Stack from '@mui/material/Stack';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useResourcesQuery } from '../../store/queries/resourcesQueries';

type EditCategoryRiskModalData = {
  show: boolean;
  category: Mcg | null;
};

function McgTable() {
  const { updateMcgRiskProfile } = useInstitutionalResources();
  const { setSnackbar } = useSnackbar();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [showEditCategory, setShowEditCategory] =
    useState<EditCategoryRiskModalData>({
      show: false,
      category: null,
    });
  const queryClient = useQueryClient();
  const { searchValue, searchBy } = useSearchContext();
  const { isLoading, data: paginatedData } = useResourcesQuery<Mcg>({
    type: ResourceType.MCG,
    key: 'fetchMcg',
    page,
    pageSize,
    searchBy: searchBy as string,
    searchQuery: searchValue,
  });

  function closeEditCategoryModal() {
    setShowEditCategory({
      show: false,
      category: null,
    });
  }

  async function onSubmit(risk: string) {
    const id = showEditCategory.category?.id;
    if (!id) return;
    setShowEditCategory({
      show: false,
      category: null,
    });

    try {
      await updateMcgRiskProfile({
        id: id,
        riskProfile: risk,
      });
      setSnackbar('Successfully updated risk profile');
      queryClient.invalidateQueries({ queryKey: 'fetchMcg' });
    } catch (error) {
      setSnackbar('Failed to update risk profile, please try again', 'error');
      console.error(error);
    }
  }

  const columnMcg = useMemo(() => getColumns(setShowEditCategory), []);

  return (
    <div>
      <div>
        <VegaDataGridV4
          data={paginatedData?.records ?? []}
          columns={columnMcg}
          idColumn={'id'}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          rowCount={paginatedData?.totalItems}
          paginationMode="server"
          loading={isLoading}
        />
        <EditCategoryRiskModal
          open={showEditCategory.show}
          onClose={function (): void {
            closeEditCategoryModal();
          }}
          category={showEditCategory.category}
          onSubmit={function (riskProfile: string): void {
            onSubmit(riskProfile);
          }}
        />
      </div>
    </div>
  );
}

export default McgTable;

const badgeColor: Record<RiskProfiles, BadgeStatusTagColor> = {
  [RiskProfiles.HIGH_RISK]: 'red',
  [RiskProfiles.LOW_RISK]: 'green',
  [RiskProfiles.MEDIUM_RISK]: 'yellow',
};

const getColumns = (
  onEditClick: (data: { show: boolean; category: Mcg }) => void
): GridColDef<Mcg>[] => [
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,
    valueGetter: (params) => params.row.name,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      convertCamelCase(params.value),
  },
  {
    field: 'riskProfile',
    headerName: 'Risk Profile',
    renderCell: (props) => {
      const riskProfile = props.row.riskProfile;
      const displayText = riskProfile ? convertCamelCase(riskProfile) : '-';
      if (!riskProfile) {
        return <>-</>;
      }

      return (
        <VegaBadgeStatusTagV4
          text={displayText}
          color={badgeColor[riskProfile]}
          variant="stroked"
        />
      );
    },
    flex: 1,
  },
  {
    field: 'action',
    headerName: 'Action',

    renderCell: (props: any) => {
      return (
        <Stack direction="row" alignItems="center" gap={1}>
          <VegaButtonV4
            startIcon={<EditIconV4 />}
            variant="mellowed"
            size="small"
            onClick={() =>
              onEditClick({
                show: true,
                category: props.row,
              })
            }
          />
          {/* <VegaButtonV4
            startIcon={<EyeOpenIcon />}
            variant="mellowed"
            size="small"
          /> */}
        </Stack>
      );
    },
    flex: 0.5,
  },
];
