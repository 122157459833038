import React, { Dispatch, useMemo } from 'react';
import VegaDialogV4 from '../common/v4/VegaDialogV4';
import { Stack } from '@mui/material';
import { VegaProgramWithMetaDataType } from '../../types/program';
import dayjs from 'dayjs';
import { DATE_FORMATE } from '../../constants/url';
import { ComponentBody1Typo } from '../common/Typography';
import { INDICATIVE } from '../../constants/style';

interface IPartnerProgramModalViewProps {
  program?: VegaProgramWithMetaDataType;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}

const PartnerProgramModalView = ({
  program,
  open,
  setOpen,
}: IPartnerProgramModalViewProps) => {
  const onModalClose = () => {
    setOpen(false);
  };

  const structuredData = useMemo(
    () => [
      {
        key: 'Program Name',
        value: program?.name,
      },
      {
        key: 'Program Type',
        value: program?.category,
      },
      {
        key: 'Product',
        value: program?.type,
      },
      {
        key: 'Created on',
        value: dayjs(program?.createdAt).format(DATE_FORMATE),
      },
      {
        key: 'Status',
        value: program?.status,
      },
      {
        key: 'Live Date',
        value: dayjs(program?.updatedAt).format(DATE_FORMATE),
      },
      {
        key: 'Maker',
        value: program?.maker?.name,
      },
      {
        key: 'Checker',
        value: program?.checker?.name,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      program?.programId,
      program?.clientId,
      program?.name,
      program?.status,
      program,
    ]
  );

  return (
    <VegaDialogV4
      open={open && Boolean(program)}
      title="Program Details"
      onClose={onModalClose}
      closeBtnRequired
      sx={{
        '.MuiPaper-root': {
          minWidth: '516px',
        },
      }}
    >
      <Stack p={4} gap={2}>
        {structuredData.map((item) => {
          const text =
            item.key === 'Status' && item.value === 'ACTIVE'
              ? 'LIVE'
              : item.value;
          const color =
            item.key === 'Status'
              ? item.value === 'ACTIVE'
                ? INDICATIVE.green[600]
                : INDICATIVE.yellow[600]
              : undefined;

          return item.value ? (
            <Stack direction="row" key={item.key}>
              <ComponentBody1Typo width={160}>{item.key}</ComponentBody1Typo>
              <ComponentBody1Typo
                sx={{
                  color,
                }}
              >
                {text}
              </ComponentBody1Typo>
            </Stack>
          ) : null;
        })}
      </Stack>
    </VegaDialogV4>
  );
};

export default PartnerProgramModalView;
