import { Box, Stack } from '@mui/material';
import { PageHeadingTypo } from '../common/Typography';
import VegaSearchfieldV4 from '../common/v4/VegaSearchfieldV4';
import { useState } from 'react';
import VegaNoResults from '../common/VegaNoResults';
import VegaTableV4, { ITableDataProps } from '../common/v4/VegaTableV4';
import dayjs from 'dayjs';
import { DATE_FORMATE } from '../../constants/url';
import { VegaProgramWithMetaDataType } from '../../types/program';
import PartnerProgramModalView from './PartnerProgramModalView';
import { useClientAuth } from '../../providers/ClientProvider';
import { useProgramList } from '../../store/queries/programQueries';

const PartnerProgramListView = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const { clientId } = useClientAuth();
  const [showModal, setShowModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<
    VegaProgramWithMetaDataType | undefined
  >(undefined);

  const { data, isFetching } = useProgramList({
    clientId: clientId || '',
    page,
    pageSize,
    name: search ? search : undefined,
  });

  const records = data?.records || [];

  const onSearchUpdate = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(e.target.value);
  };

  const programViewDetailOnClick = ({ programId }: { programId: string }) => {
    const program = records.find((el) => el.programId === programId);
    setSelectedProgram(program);
    setShowModal(true);
  };

  const structuredData: ITableDataProps[] =
    records.map((rec) => {
      return {
        rowData: [
          {
            value: rec.name,
            type: 'string',
          },
          {
            value: rec.category,
            type: 'string',
          },
          {
            value: rec.type,
            type: 'string',
          },
          {
            value: dayjs(rec.createdAt).format(DATE_FORMATE),
            type: 'string',
          },
          {
            value: rec.status,
            type: 'status',
            statusColor: rec.status === 'ACTIVE' ? 'green' : 'yellow',
          },
          {
            value: dayjs(rec.updatedAt).format(DATE_FORMATE),
            type: 'string',
          },
          {
            value: 'View Details',
            type: 'link',
            linkOnClick: programViewDetailOnClick,
          },
        ],
        id: { programId: rec.programId },
      };
    }) || [];

  return (
    <Box my={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <PageHeadingTypo>Programs</PageHeadingTypo>
        <VegaSearchfieldV4 value={search} onChange={onSearchUpdate} />
      </Stack>

      <Box mt={2}>
        {!Boolean(records.length) && !isFetching ? (
          <VegaNoResults errorText="No Programs Found" />
        ) : (
          <VegaTableV4
            head={[
              'Program Name',
              'Program Type',
              'Product',
              'Created Date',
              'Status',
              'Live Date',
              'Action',
            ]}
            data={structuredData}
            pagination={{
              rowsPerPage: pageSize,
              setRowsPerPage: setPageSize,
              setPage,
              page,
              totalRecords: data?.totalItems || 0,
            }}
            isLoading={isFetching}
          />
        )}
      </Box>
      <PartnerProgramModalView
        program={selectedProgram}
        open={showModal}
        setOpen={setShowModal}
      />
    </Box>
  );
};

export default PartnerProgramListView;
