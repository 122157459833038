import { useQuery } from 'react-query';
import { QUERY_NAMES } from '../../enums/commons';
import { getEMIScheduleList, getEMISchedulesFromLoanId } from '../../api/emi';
import { CommonQuery } from '../../types/commonType';
import { EMIStatus } from '../../types/emis';
import useDebounce from '../../hooks/useDebounce';

export const useEMIListQuery = ({
  accountId,
  pageNumber,
  pageSize,
  enabled,
  statusList,
  startDate,
  endDate,
  search,
}: {
  accountId: string;
  statusList?: EMIStatus[];
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(search, 500);

  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.EMI_LIST,
      accountId,
      statusList,
      { pageNumber, pageSize, startDate, endDate, search: debouncedSearch },
    ],
    queryFn: () =>
      getEMIScheduleList({
        accountId,
        page: pageNumber,
        pageSize,
        statusList,
        startDate,
        endDate,
        q: debouncedSearch,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useEMIListFromLoanIDQuery = ({
  loanId,
  enabled,
}: {
  loanId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.EMI_LIST_FROM_LOAN_ID, loanId],
    queryFn: () => getEMISchedulesFromLoanId({ loanId }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(loanId),
  });

  return { data: data?.data, ...rest };
};
