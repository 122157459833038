import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Box, Stack, ThemeProvider } from '@mui/material';
import { ChargeBackProvider } from '../../ChargeBack/hooks';
import AccountDetailsAction from '../Support_New/ApplcaitonDetails/AccountDetailsAction';
import Bills from '../Support_New/ApplcaitonDetails/AccountDetailsStep/Bills/Bills';
import CardControls from '../Support_New/ApplcaitonDetails/AccountDetailsStep/CardControls/CardControls';
import Delinquency from '../Support_New/ApplcaitonDetails/AccountDetailsStep/Delinquency/Delinquency';
import Loans from '../Support_New/ApplcaitonDetails/AccountDetailsStep/Loans/Loans';
import PhysicalCard from '../Support_New/ApplcaitonDetails/AccountDetailsStep/PhysicalCard/PhysicalCard';
import Rewards from '../Support_New/ApplcaitonDetails/AccountDetailsStep/Rewards/Rewards';
import Summary from '../Support_New/ApplcaitonDetails/AccountDetailsStep/Summary/Summary';
import TCS from '../Support_New/ApplcaitonDetails/AccountDetailsStep/TCS/TCS';
import Tickets from '../Support_New/ApplcaitonDetails/AccountDetailsStep/Tickets/Tickets';
import Transactions from '../Support_New/ApplcaitonDetails/AccountDetailsStep/Transactions/Transactions';
import Vega_Tab from '../../common/v3/Vega_Tab';
import Vega_Text from '../../common/v3/Vega_Text';
import { NEW_PRIMARY } from '../../../constants/style';
import {
  AccountDetailsStep,
  useSupport,
} from '../../../providers/SupportProvider';
import { VP_THEME } from '../../../ui-components/theme';
import EMIs from '../Support_New/ApplcaitonDetails/AccountDetailsStep/EMIs/EMIs';
import Surplus from '../Support_New/ApplcaitonDetails/AccountDetailsStep/Surplus/Surplus';
import Waiver from '../Support_New/ApplcaitonDetails/AccountDetailsStep/Waiver/Waiver';
import Chargeback from '../Support_New/ApplcaitonDetails/AccountDetailsStep/Chargeback/Chargeback';
import PhysicalStatement from '../Support_New/ApplcaitonDetails/AccountDetailsStep/PhysicalStatement/PhysicalStatement';

const AccountDetails = () => {
  const {
    setAccountData,
    elementHeight,
    selectedTab,
    setSelectedTab,
    setSelectedEMI,
  } = useSupport();

  const onTabSelect = (value: AccountDetailsStep) => {
    if (value === AccountDetailsStep.EMI) {
      setSelectedEMI(undefined);
    }
    setSelectedTab(value);
  };

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        sx={{
          height: '40px',
          bgcolor: 'white',
          px: 2.5,
          py: 1.5,
          borderBottom: '1px solid #EEEEEE',
        }}
      >
        <Vega_Text
          text={'Support'}
          fontWeight={400}
          color={'#333333'}
          fontSize={12}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => setAccountData(undefined)}
        />
        <KeyboardArrowRightOutlined
          sx={{ color: NEW_PRIMARY.BLUE, fontSize: 14 }}
        />
        <Vega_Text
          text={'Account Details'}
          fontWeight={400}
          color={NEW_PRIMARY.BLUE}
          fontSize={12}
        />
      </Stack>
      <AccountDetailsAction />
      <Box
        sx={{
          height: `calc(100vh - (40px + ${elementHeight}px + 28px + 69px))`,

          py: 2,
          px: 2.5,
        }}
      >
        <Vega_Tab
          tabData={Object.values(AccountDetailsStep)}
          selected={selectedTab}
          onSelect={onTabSelect}
          color={NEW_PRIMARY.BLUE}
        />
        <Box
          sx={{
            height: `calc(100% -  28px )`,
            border: `1px solid #E1E4EB`,
            borderRadius: '12px',
            bgcolor: 'white',
            py: 2,
            px: 2.5,
            mt: 1,
            overflow: 'auto',
          }}
        >
          {getStepContainer(selectedTab)}
        </Box>
      </Box>
    </>
  );
};

export default AccountDetails;

const getStepContainer = (step: string) => {
  switch (step) {
    case AccountDetailsStep.PHYSICAL_CARD:
      return <PhysicalCard />;
    case AccountDetailsStep.CARD_CONTROLS:
      return <CardControls />;
    case AccountDetailsStep.TRANSACTIONS:
      return (
        <ThemeProvider theme={VP_THEME}>
          <Transactions />
        </ThemeProvider>
      );
    case AccountDetailsStep.BILLS:
      return <Bills />;
    case AccountDetailsStep.SURPLUS:
      return <Surplus />;
    case AccountDetailsStep.REWARDS:
      return <Rewards />;
    case AccountDetailsStep.TCS:
      return <TCS />;
    // case AccountDetailsStep.TICKETS:
    //   return (
    //     <ChargeBackProvider>
    //       <ThemeProvider theme={VP_THEME}>
    //         <Tickets />
    //       </ThemeProvider>
    //     </ChargeBackProvider>
    //   );
    case AccountDetailsStep.DELINQUENCY:
      return <Delinquency />;
    case AccountDetailsStep.LOANS:
      return <Loans />;
    case AccountDetailsStep.EMI:
      return <EMIs />;
    case AccountDetailsStep.WAIVER:
      return <Waiver />;
    case AccountDetailsStep.CHARGEBACK:
      return <Chargeback />;
    case AccountDetailsStep.PHYSICAL_STATEMENT:
      return <PhysicalStatement />;
    default:
      return <Summary />;
  }
};
