/** @format */

import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ACCESS_MAP, HOSTNAME, getUrl, isDev } from '../../constants/url';
import ClientDashboardContainer from '../../containers/ClientDashboardContainer';
import CorporateContainer from '../../containers/CorporateContainer';
import CrmContainer from '../../containers/CrmContainer';
import MCCBackOfficeContainer from '../../containers/MCCBackOfficeContainer';
import MCCSimulatorContainer from '../../containers/MCCSimulatorContainer';
import MCCProgramContainer from '../../containers/MccProgramContainer';
import ProgramContainer from '../../containers/ProgramContainer';
import SettingContainer from '../../containers/SettingContainer';
import SimulatorContainer from '../../containers/SimulatorContainer';
import ForgotPassord from '../../pages/ForgotPassord';
import Login from '../../pages/Login';
import ResetPassword from '../../pages/ResetPassword';
import SetNewPassword from '../../pages/SetNewPassword';
import { useClientAuth } from '../../providers/ClientProvider';
import { DASHBOARD_ROUTES, ROUTES, ROUTE_CODE } from '../../routes';
import { PrivateRoute } from './PrivateRoute';
import { useAppSelector } from '../../store/hook';

type Props = {};

const RoutingComponent = (props: Props) => {
  const {
    isAuth,
    canAccessModule,
    getCurrentModule,
    scope,
    mccUser,
    forexAdmin,
    logout,
  } = useClientAuth();
  const navigate = useNavigate();
  const { loggedInUserDetails } = useClientAuth();
  const clientEmail = loggedInUserDetails?.email;
  const { restrictedUsers } = useAppSelector(({ header }) => ({
    restrictedUsers: header.restrictedUsers,
  }));

  const currentHost = window.location.hostname;
  const currentPath = window.location.pathname;

  const isRestrictedUser =
    clientEmail && clientEmail.trim() !== ''
      ? restrictedUsers.includes(clientEmail)
      : false;

  useEffect(() => {
    if (isAuth) {
      if (isRestrictedUser) {
        navigate(ROUTE_CODE.CCMS + '/' + ACCESS_MAP[HOSTNAME.BACK_OFFICE], {
          replace: true,
        });
      } else if (canAccessModule(getCurrentModule())) {
        navigateToApplicableRouteForDashboard();
      } else {
        if (mccUser && isDev) {
          navigateToApplicableRouteForDashboard();
          return;
        }
        navigateToAccessibleModule();
      }
    } else {
      navigate(ROUTES.LOGIN);
    }
  }, [isAuth, isRestrictedUser, isDev, mccUser]);

  const navigateToApplicableRouteForDashboard = () => {
    if (isDev || (!isDev && [ROUTES.LOGIN, '/', ''].includes(currentPath))) {
      if (mccUser) {
        if (
          (forexAdmin && currentHost == HOSTNAME.CLIENT) ||
          scope.scope['FOREX_HQ']
        ) {
          navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_PROGRAM, {
            replace: true,
          });
        } else if (
          (forexAdmin && currentHost == HOSTNAME.FOREX_BRANCH) ||
          scope.scope['FOREX_BRANCH']
        ) {
          navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_BACK_OFFICE, {
            replace: true,
          });
        } else if (
          (forexAdmin && currentHost == HOSTNAME.FOREX_CORPORATE) ||
          scope.scope['FOREX_CORPORATE']
        ) {
          navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_CORPORATE, {
            replace: true,
          });
        } else if (
          (forexAdmin && currentHost == HOSTNAME.SIMULATOR) ||
          scope.scope['FOREX_SIMULATOR']
        ) {
          navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_SIMULATOR, {
            replace: true,
          });
        } else {
          navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_SIMULATOR, {
            replace: true,
          });
        }
        return;
      }
      navigate(ROUTE_CODE.CCMS + '/' + ACCESS_MAP[currentHost], {
        replace: true,
      });
    }
  };

  const navigateToAccessibleModule = () => {
    const module: any = Object.keys(scope.scope ?? {})?.[0] || 'BACK_OFFICE';
    const url = getUrl(module);
    if (isDev) {
      if (mccUser) {
        navigate(ROUTE_CODE.MCC + '/' + DASHBOARD_ROUTES.MCC_SIMULATOR, {
          replace: true,
        });
      } else {
        navigate(`${ROUTE_CODE.CCMS}/${DASHBOARD_ROUTES.CLIENT_DASHBOARD}`);
      }
    } else {
      if (currentHost !== url) {
        window.location.replace(url);
      }
    }
  };
  const [isActive, setIsActive] = useState(true);
  useEffect(() => {
    let inactivityTimeout: NodeJS.Timeout;

    const autoLogOut = () => {
      logout();
    };

    const handleActivity = () => {
      if (!isActive) {
        setIsActive(true);
        clearTimeout(inactivityTimeout);
        inactivityTimeout = setTimeout(autoLogOut, 30 * 60 * 1000);
      }
    };

    const addActivityListeners = () => {
      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('keydown', handleActivity);
    };

    const removeActivityListeners = () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };

    addActivityListeners();

    inactivityTimeout = setTimeout(autoLogOut, 30 * 60 * 1000);

    return () => {
      removeActivityListeners();
      clearTimeout(inactivityTimeout);
    };
  }, [isActive]);

  return (
    <Routes>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.SET_NEW_PASSWORD} element={<SetNewPassword />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassord />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route
        path={ROUTE_CODE.CCMS}
        // element={<Navigate to={DASHBOARD_ROUTES.CLIENT_DASHBOARD} replace />}
      >
        <Route
          path={DASHBOARD_ROUTES.BACK_OFFICE}
          element={
            <PrivateRoute>
              <CrmContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.SIMULATOR}
          element={
            <PrivateRoute>
              <SimulatorContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.PROGRAM_DASHBOARD}
          element={
            <PrivateRoute>
              <ProgramContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.CLIENT_DASHBOARD}
          element={
            <PrivateRoute>
              <ClientDashboardContainer />
            </PrivateRoute>
          }
        />
      </Route>

      <Route
        path={ROUTES.SETTING}
        element={
          <PrivateRoute>
            <SettingContainer />
          </PrivateRoute>
        }
      />

      {/*-------------------------------- MCC-------------------------------- */}

      <Route path={ROUTE_CODE.MCC}>
        <Route
          path={DASHBOARD_ROUTES.MCC_PROGRAM}
          element={
            <PrivateRoute>
              <MCCProgramContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.MCC_BACK_OFFICE}
          element={
            <PrivateRoute>
              <MCCBackOfficeContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.MCC_CORPORATE}
          element={
            <PrivateRoute>
              <CorporateContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={DASHBOARD_ROUTES.MCC_SIMULATOR}
          element={
            <PrivateRoute>
              <MCCSimulatorContainer />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
    // <RouterProvider router={router} />
  );
};

export default RoutingComponent;
