import { Paper, Stack, SxProps } from '@mui/material';
import {
  INDICATIVE,
  NEW_PRIMARY,
  QUICK_ACCESS,
} from '../../../constants/style';
import { GenericTypo1, GenericTypo3, GenericTypo4 } from '../Typography';
import VegaBadgeStatusTagV4, {
  VegaBadgeStatusTagV4Props,
} from './VegaBadgeStatusTagV4';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { BadgeStatusTagColor } from './VegaBadgeStatusTagV4';
import { useMemo } from 'react';

interface IVegaMetricCardV4Props {
  mainInfo?: {
    sx?: SxProps;
    text?: string | number;
    label?: string;
    labelSx?: SxProps;
  };
  badge?: {
    props: VegaBadgeStatusTagV4Props;
    helperText?: string | number;
    helperSx?: SxProps;
    show?: boolean;
  };
  paperSx?: SxProps;
}

const VegaMetricCardV4 = ({
  mainInfo,
  badge,
  paperSx,
}: IVegaMetricCardV4Props) => {
  const badgeProps = badge?.props || ({} as any);
  const { sx: badgeSx, text: badgeText, ...badgeRest } = badgeProps;
  const isTextValid = badgeText !== undefined && badgeText !== null;

  const { statusColor, iconColor } = useMemo(() => {
    const val = Number(badgeText);
    if (val > 0) {
      return {
        statusColor: 'green' as BadgeStatusTagColor,
        iconColor: INDICATIVE.green[500],
      };
    } else if (val < 0) {
      return {
        statusColor: 'red' as BadgeStatusTagColor,
        iconColor: INDICATIVE.red[500],
      };
    }
    return {
      statusColor: 'grey' as BadgeStatusTagColor,
      iconColor: QUICK_ACCESS.text[100],
    };
  }, [badgeText]);

  return (
    <Paper
      sx={{
        border: `1px solid ${QUICK_ACCESS.border[75]}`,
        p: 2,
        boxShadow: 'none',
        borderRadius: '12px',
        ...paperSx,
      }}
    >
      <Stack gap={1}>
        <Stack
          direction="row"
          alignItems="flex-start"
          gap={2}
          justifyContent="space-between"
        >
          <GenericTypo1 sx={mainInfo?.sx}>
            {mainInfo?.text ?? '--'}
          </GenericTypo1>
          <Stack direction="row" gap={0.5} alignItems="center">
            {isTextValid && (
              <VegaBadgeStatusTagV4
                icon={
                  Number(badgeText) === 0 ? undefined : Number(badgeText) >
                    0 ? (
                    <ArrowUpwardIcon
                      sx={{ fontSize: '12px', color: iconColor }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{ fontSize: '12px', color: iconColor }}
                    />
                  )
                }
                variant="light-filled"
                sx={{ px: 0.8, py: 0.4, fontWeight: 700, ...badgeSx }}
                text={isTextValid ? Math.abs(badgeText) + '%' : undefined}
                color={statusColor}
                {...badgeRest}
              />
            )}
            {Boolean(badge?.helperText) && (
              <GenericTypo4
                sx={{ color: QUICK_ACCESS.text[75], ...badge?.helperSx }}
              >
                {badge?.helperText}
              </GenericTypo4>
            )}
          </Stack>
        </Stack>
        <GenericTypo3 sx={{ color: NEW_PRIMARY.GRAY, ...mainInfo?.labelSx }}>
          {mainInfo?.label}
        </GenericTypo3>
      </Stack>
    </Paper>
  );
};

export default VegaMetricCardV4;
