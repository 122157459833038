import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import DataTile from '../DataTile';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import {
  InterestChargeConfig,
  TxnToEmiPolicyInterface,
} from '../../../types/policy';
import ProgramDataGrid from './ProgramDataGrid';
import { convertCamelCase } from '../../../utils/stringUtils';

const getTransactionToEmiPolicyTableColumns =
  (): GridColDef<InterestChargeConfig>[] => [
    {
      field: 'duration_in_months',
      headerName: 'Duration in months',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
    {
      field: 'interest_rate',
      headerName: 'Interest Rate',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
  ];

type ViewTransactionToEmiPolicyProps = {
  policy: TxnToEmiPolicyInterface;
};

function ViewTransactionToEmiPolicy({
  policy,
}: ViewTransactionToEmiPolicyProps) {
  const columnsRef = useMemo(() => getTransactionToEmiPolicyTableColumns(), []);

  const { installment_policy } = policy;

  return (
    <Box
      sx={{
        py: 3,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      {installment_policy?.map((subPolicy, index) => (
        <Box
          key={index}
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4}>
              <DataTile
                title="Minimum Amount"
                value={`${policy.minimum_amount ?? '-'}`}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DataTile title="MCC" value={subPolicy?.mcc?.join(',') ?? '-'} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DataTile
                title="Amortization Type"
                value={subPolicy?.amortizationType ?? '-'}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DataTile
                title="Interest Type"
                value={
                  subPolicy?.interestType
                    ? convertCamelCase(subPolicy.interestType)
                    : '-'
                }
              />
            </Grid>
          </Grid>
          <ProgramDataGrid
            data={subPolicy.interestChargeConfig ?? []}
            columns={columnsRef}
            idColumn={'policy_id'}
            getRowId={(row: any) =>
              `${row.duration_in_months}-${row.interest_rate}`
            }
          />
        </Box>
      ))}
    </Box>
  );
}

export default ViewTransactionToEmiPolicy;
