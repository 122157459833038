import { SVGProps } from 'react';

const MessageSquareInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.00068 8.80023V6.40023M8.00068 4.00023V4.06039M7.79128 11.061L4.45215 14.4001V11.061H3.19998C2.31632 11.061 1.59998 10.3446 1.59998 9.46097V3.2001C1.59998 2.31644 2.31632 1.6001 3.19998 1.6001H12.8C13.6836 1.6001 14.4 2.31644 14.4 3.2001V9.46096C14.4 10.3446 13.6836 11.061 12.8 11.061H7.79128Z"
      stroke={props.stroke || '#1047DC'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default MessageSquareInfo;
