/** @format */

import { IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import React, { forwardRef, ReactNode } from 'react';
import { BACKGROUND, BORDER_GRAY } from '../../constants/style';
import Vega_Text from './v3/Vega_Text';

interface ITabsProps {
  onChange: (e: any) => void;
  active: any;
  value: any;
  bg?: any;
  id?: any;
  isLabel?: boolean;
  lableText?: string;
  activeBg?: string;
  color?: any;
  icon: React.ReactNode;
  ignoreCase?: boolean;
  endIcon?: ReactNode;
}

const VegaTabs = forwardRef(
  (
    {
      onChange,
      active,
      value,
      bg,
      icon,
      isLabel,
      lableText,
      activeBg,
      color,
      id,
      ignoreCase = false,
      endIcon,
    }: ITabsProps,
    ref
  ) => {
    const labelValue = ignoreCase ? value : _.startCase(_.toLower(value));
    return (
      <Box
        ref={ref}
        onClick={onChange}
        sx={{
          position: 'relative',
          border: BORDER_GRAY,
          ...(active === id && {
            bgcolor: activeBg,
          }),
          borderRadius: '13px',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          height: '90px',
          cursor: 'pointer',
          ':active': {
            scale: '0.95',
            transition: 'all 0.2s ease-in-out',
          },
        }}
      >
        <IconButton
          sx={{
            mr: 1,
            border: BORDER_GRAY,
            bgcolor: bg,
            ':hover': { bgcolor: bg },
          }}
        >
          {icon}
        </IconButton>
        <Stack
          direction={'row'}
          justifyContent="space-between"
          spacing={1}
          alignItems="center"
          style={{ flex: 1 }}
        >
          <Vega_Text
            text={labelValue}
            fontWeight={500}
            fontSize={14}
            sx={{
              lineHeight: '18px',
              color: color ?? '',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          />
          {endIcon && <IconButton>{endIcon}</IconButton>}
        </Stack>
        {isLabel && (
          <Typography
            variant="caption"
            className="font-aspekta-500"
            fontSize={10}
            sx={{
              ...(active === id
                ? {
                    bgcolor: BACKGROUND.BLUE,
                    color: 'white',
                  }
                : {
                    bgcolor: 'white',
                    color: BACKGROUND.BLUE,
                    borderLeft: BORDER_GRAY,
                    borderBottom: BORDER_GRAY,
                  }),
            }}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              borderTopRightRadius: '14px',
              borderBottomLeftRadius: '14px',
              padding: '2px 15px',
            }}
          >
            {lableText}
          </Typography>
        )}
      </Box>
    );
  }
);

export default VegaTabs;
