import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { VegaTextField } from '../../../../../components/common';
import VegaDatePicker from '../../../../../components/common/VegaDatePicker';
import VegaSelect, {
  VegaSelectSelectOptionType,
} from '../../../../../components/common/VegaSelect';
import { useClientAuth } from '../../../../../providers/ClientProvider';
import {
  fetchMCCProgramList,
  getMccProgramById,
} from '../../../../Program-Dashboard/api/Program';
import ProgramSelect from '../../../../components/ProgramSelect';
import { fetchMCCCorporatesList } from '../../../../corporate/api/Corporate';
import { ICorporateEntity } from '../../../../types/CorporateCustomer';
import { IProgramTypeEnum } from '../../../../types/Program';
import { fetchListForInventory } from '../../../api/inventory';
import { ICommonProps } from '../../../../../types/commonType';
import { listBinForClient } from '../../../../../api/bin';
import BinSelector from '../../../../components/BinSelector';

interface IProps extends ICommonProps {
  checkValue: string;
}

const KitDretails = ({ handleChange, state, checkValue }: IProps) => {
  const { clientId } = useClientAuth();

  const [binData, setBinData] = useState<VegaSelectSelectOptionType[]>([]);

  const [programType, setProgramType] = useState<IProgramTypeEnum>(
    IProgramTypeEnum.RETAIL
  );
  const [programTypeForProgram, setProgramTypeForProgram] =
    useState<string>('ALL');
  const [corporateEntities, setCorportateEntities] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [programsList, setProgram] = useState<any[]>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== programsList.length) {
        setPage(page + 1);
      }
    }
  };

  const getProgramList = async () => {
    fetchMCCProgramList(clientId as string, page, 10).then((res) => {
      setProgram([...programsList, ...res.data.records]);
      setTotalItem(res.data.totalItems);
    });
  };

  useEffect(() => {
    getProgramList();
  }, [page]);

  const fetchCorporateEntities = () => {
    fetchMCCCorporatesList({
      clientId,
      status: 'ACTIVE',
      // branchId : branchId
    }).then((res) => {
      setCorportateEntities(
        res.data.map((corporate: ICorporateEntity) => ({
          id: corporate.id,
          name: corporate.corporateName,
        }))
      );
    });
  };

  useEffect(() => {
    if (state?.programId) {
      getMccProgramById(state?.programId).then((res) => {
        setProgramType(res.data.programType);
      });
    }
  }, [state.programId]);

  useEffect(() => {
    if (programType === IProgramTypeEnum.CORPORATE) {
      fetchCorporateEntities();
    } else {
      handleChange('corporateCustomerId', null);
    }
  }, [programType]);

  // const getInventoryList = () => {
  //   // fetchListForInventory(clientId as string)
  //   listBinForClient(clientId as string, 0, 100)
  //     .then((res) => {
  //       const binArr = res.data?.records?.map((val: any) => ({
  //         id: val.id,
  //         name: val.binName,
  //       }));
  //       setBinData(binArr);
  //     })
  //     .catch((err) => {});
  // };

  // useEffect(() => {
  //   getInventoryList();
  // }, []);

  const getFilteredProgram = (arr: any) => {
    if (!programTypeForProgram.length) {
      return arr;
    } else {
      if (programTypeForProgram === 'ALL') {
        return arr;
      } else {
        arr.filter((val: any) => val.programType === programTypeForProgram);
      }
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {checkValue === 'CREATE_ACCOUNT' ? (
          <>
            <Grid item xs={12} sm={12} mt={2}>
              <VegaSelect
                placeholder="Program Type"
                label="Program Type"
                size="small"
                key={'programType'}
                selected={programTypeForProgram ?? ''}
                onChange={(value) => setProgramTypeForProgram(value)}
                options={['ALL', 'RETAIL', 'CORPORATE']}
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={2}>
              {/* <ProgramSelect
                name="programId"
                selected={state.programId ?? ''}
                handleChange={(value) => handleChange('programId', value)}
              /> */}
              <VegaSelect
                placeholder="Program Name"
                label="Program Name"
                size="small"
                key={'programId'}
                selected={state.programId ?? ''}
                onChange={(value) => handleChange('programId', value)}
                options={getProgramOption(programsList, programTypeForProgram)}
                onScroll={loadMoreItems}
              />
            </Grid>

            {programType === IProgramTypeEnum.CORPORATE && (
              <Grid item xs={12} sm={12} mt={2}>
                <VegaSelect
                  placeholder="Corporate Name"
                  label="Corporate Name"
                  size="small"
                  key={'corporate'}
                  selected={state?.corporateCustomerId ?? ''}
                  onChange={(value) =>
                    handleChange('corporateCustomerId', value)
                  }
                  options={corporateEntities}
                />
              </Grid>
            )}
          </>
        ) : checkValue === 'GENERATE_OTP' ? (
          <Grid item xs={12} sm={12} mt={2}>
            <BinSelector
              selected={state.binId}
              name="binId"
              handleChange={(value) => handleChange('binId', value)}
            />
            {/* <VegaSelect
              options={binData}
              key="binId"
              selected={state.binId}
              label="Select Bin"
              onChange={(value) => handleChange('binId', value)}
            /> */}
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12} mt={2}>
              <VegaTextField
                value={state?.id}
                onChange={(e) => handleChange('customerId', e.target.value)}
                key="customerId"
                label="Account ID"
                type={'text'}
                placeholder="Account ID"
                disabled
              />
            </Grid>
            {checkValue === 'VIEW' && (
              <Grid item xs={12} sm={12} mt={2}>
                <VegaTextField
                  value={state.card?.vendorCardNumber ?? ''}
                  onChange={(e) =>
                    handleChange('vendorCardNumber', e.target.value)
                  }
                  key="vendorCardNumber"
                  label="Kit ID"
                  type={'text'}
                  placeholder="Kit ID"
                  disabled={checkValue === 'VIEW'}
                />
              </Grid>
            )}
            {checkValue !== 'VIEW' && (
              <>
                <Grid item xs={12} sm={6} mt={2}>
                  {/* <VegaSelect
                    options={binData}
                    key="binId"
                    selected={state.binId}
                    label="Select Bin"
                    onChange={(value) => handleChange('binId', value)}
                    disabled={isMapCardShow}
                  /> */}
                  <BinSelector
                    selected={state.binId}
                    name="binId"
                    handleChange={(value) => handleChange('binId', value)}
                  />
                </Grid>
              </>
            )}
            {checkValue === 'VIEW' && (
              <>
                <Grid item xs={12} sm={6} mt={2}>
                  <VegaTextField
                    value={state?.card?.lastFourDigits}
                    onChange={(e) => handleChange('cardNo', e.target.value)}
                    key="cardNo"
                    label="Card No"
                    type={'text'}
                    placeholder="Card No"
                    disabled={checkValue === 'VIEW'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} mt={2}>
                  <VegaTextField
                    value={state?.card?.expiryDate ?? ''}
                    disabled
                    key="expiryDate"
                    label="Expiry Date"
                    placeholder="Expiry Date"
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default KitDretails;

const getProgramOption = (programsList: any, programTypeForProgram: string) => {
  const newArr = programsList.filter((val: any) =>
    programTypeForProgram === 'ALL'
      ? val
      : val.programCustomerType === programTypeForProgram
  );
  return newArr.map((val: any) => ({
    id: val.programId,
    name: val.name,
  }));
};
