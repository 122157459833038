import { Dispatch, createContext, useContext, useState } from 'react';
import InstitutionStandard from '../../../components/InstitutionStandard/InstitutionStandard';

interface IInstitutionStandardContextProps {
  currState: InstitutionStandardState;
  setCurrState?: Dispatch<React.SetStateAction<InstitutionStandardState>>;
}

export type InstitutionStandardState = 'holidays';

export const InstitutionStandardContext =
  createContext<IInstitutionStandardContextProps>({
    currState: 'holidays',
    setCurrState: undefined,
  });

export const useInstitutionStandardContext = () =>
  useContext(InstitutionStandardContext);

const InstitutionStandardWrap = () => {
  const [currState, setCurrState] =
    useState<InstitutionStandardState>('holidays');

  return (
    <InstitutionStandardContext.Provider
      value={{
        currState,
        setCurrState,
      }}
    >
      <InstitutionStandard />
    </InstitutionStandardContext.Provider>
  );
};

export default InstitutionStandardWrap;
