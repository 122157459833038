import * as React from 'react';
import { SVGProps } from 'react';
const AddSquare02Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g opacity={0.9}>
      <path
        d="M10.3996 7.99971H7.99961M7.99961 7.99971H5.59961M7.99961 7.99971V10.3997M7.99961 7.99971L7.99961 5.59971M14.3996 8.0001C14.3996 11.5347 11.5342 14.4001 7.99961 14.4001C4.46499 14.4001 1.59961 11.5347 1.59961 8.0001C1.59961 4.46548 4.46499 1.6001 7.99961 1.6001C11.5342 1.6001 14.3996 4.46548 14.3996 8.0001Z"
        stroke="white"
        strokeLinecap="round"
      />
    </g>
  </svg>
);
export default AddSquare02Icon;
