/** @format */

import axios from 'axios';
import { VegaCreateCustomersType } from '../types/customers';
import { PaginatedResponse } from '../utils/api';
import { ACCOUNT, BASE_URL, ONBOARDING } from './endpoints';
import { ISupportCustomerDtoProps } from '../types/support';
import { CommonRequestQuery } from '../types/commonType';
import { CUSTOMER } from '../mcc/Program-Dashboard/api/endpoints';
import { SearchOptions } from '../components/Simulator/simulatorUtils';
import { AccountStatus } from '../enums/account.enum';

const ONBOARDING_BASE_URL = BASE_URL + ONBOARDING.BASE;
const ONBOARDING_CUSTOMER_URL = ONBOARDING_BASE_URL + ONBOARDING.CUSTOMER;

export const listForCustomers = async (
  data: {
    programId: string;
    clientId: string;
    searchBy?: SearchOptions;
    allAccounts?: boolean;
  } & CommonRequestQuery
): Promise<PaginatedResponse<VegaCreateCustomersType>> => {
  try {
    const { data: apiData } = await axios.get(
      ONBOARDING_CUSTOMER_URL + '/list/withAccount',
      {
        params: {
          programId: data.programId,
          clientId: data.clientId,
          page: data.page,
          pageSize: data.pageSize,
          allAccounts: Boolean(data.allAccounts),
          [data.searchBy as string]:
            data.q && data.searchBy ? data.q : undefined,
        },
      }
    );
    return apiData;
  } catch (err) {
    throw err;
  }
};

export const listCustomersForClient = (
  clientId: string,
  currentMonth = false
) => {
  return axios.get(
    ONBOARDING_CUSTOMER_URL +
      '/list/{clientId}'.replace('{clientId}', clientId),
    {
      params: { currentMonth },
    }
  );
};

export const createNewCustomer = (customer: VegaCreateCustomersType) => {
  return axios.post(ONBOARDING_CUSTOMER_URL, customer);
};

export const filterCustomer = (mobileNumber: string, clientId: any) => {
  return axios.get(ONBOARDING_CUSTOMER_URL + '/filter', {
    params: { mobileNumber, clientId },
    data: { mobileNumber: mobileNumber, clientId: clientId },
  });
};

export const fetchCustomerByCustomerId = (customerId: string) => {
  return axios.get(ONBOARDING_CUSTOMER_URL + '/' + customerId);
};

type FilterCustomerData = {
  clientId: string;
  programId: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  emailId: string;
};

export async function getFilteredCustomers(
  data: Partial<FilterCustomerData>
): Promise<PaginatedResponse<ISupportCustomerDtoProps>> {
  try {
    const endpoint = ONBOARDING_CUSTOMER_URL + '/filter';
    const response = await axios.post(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const accountListForCustomers = async (
  data: {
    programId: string;
    clientId: string;
    searchBy?: SearchOptions;
    statusList?: AccountStatus[];
  } & CommonRequestQuery
): Promise<PaginatedResponse<VegaCreateCustomersType>> => {
  const url = BASE_URL + ACCOUNT.BASE + ACCOUNT.FILTER + CUSTOMER.BASE;
  try {
    const { data: apiData } = await axios.post(url, {
      programId: data.programId,
      clientId: data.clientId,
      page: data.page,
      pageSize: data.pageSize,
      [data.searchBy as string]: data.q && data.searchBy ? data.q : undefined,
      statusList: data.statusList,
    });
    return apiData;
  } catch (err) {
    throw err;
  }
};
