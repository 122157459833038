/** @format */

import axios from 'axios';
import { IReportConfigRequest } from '../types/reports';

import { BASE_URL, REPORT } from './endpoints';

const REPORT_BASE_URL = BASE_URL + REPORT.BASE;

export const listReportCategories = (programId: string | undefined) => {
  return axios.get(REPORT_BASE_URL + '/category/list/' + programId, {});
};

export const listReportCategoriesForex = (
  programId: string | undefined,
  platform: string
) => {
  return axios.get(
    REPORT_BASE_URL +
      '/category/list/forex/' +
      programId +
      '?forexEntity=' +
      platform,
    {}
  );
};

export const getReportsByCategory = (categoryId: string) => {
  return axios.get(REPORT_BASE_URL + '/report/list/' + categoryId, {
    params: {},
  });
};
export const getAllReportsForProgram = (programId: string) => {
  return axios.get(REPORT_BASE_URL + '/report/listForProgram/' + programId);
};

export const getAllReportConfigsForProgram = (programId: string) => {
  return axios.get(REPORT_BASE_URL + '/config/listForProgram/' + programId);
};
export const getAllReportRunsForProgram = (programId: string) => {
  return axios.get(REPORT_BASE_URL + '/run/listForProgram/' + programId);
};

export const getReportsConfig = (reportId: string) => {
  return axios.get(REPORT_BASE_URL + '/config/list/' + reportId, {});
};

export const addReportConfig = (
  reportConfig: Omit<IReportConfigRequest, 'reportConfigId'>
) => {
  return axios.post(REPORT_BASE_URL + '/config', reportConfig);
};

export const updateReportConfig = (reportConfig: IReportConfigRequest) => {
  return axios.put(REPORT_BASE_URL + '/config', reportConfig);
};
