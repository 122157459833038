import { useQuery } from 'react-query';
import { QUERY_NAMES } from '../../enums/commons';
import {
  fetchTransactionJourney,
  getMccCodeInfo,
  getMccCodes,
  getMerchantNames,
  getMidCodes,
  getSurplusList,
  getWaiverList,
  listTransactionChargeback,
  listTransactionCharges,
  listTransactionChargesByAccountId,
  listTransactionRefund,
  listTransactionsForAccount,
  listUnwaivedTransactionChargesByAccountId,
} from '../../api/transaction';
import {
  TransactionCode,
  TransactionIndicator,
  TransactionStatus,
} from '../../enums/transaction';
import { CommonQuery } from '../../types/commonType';
import useDebounce from '../../hooks/useDebounce';
import { ChargebackStatus } from '../../types/chargeBack';
import { DelinquencyServices } from '../../services/DelinquencyServices';
import { KeyValuePairs } from './customerQueries';

export const useTransactionListQuery = ({
  accountId,
  pageNumber,
  pageSize,
  statusList,
  transactionCodeList,
  transactionIndicatorList,
  search,
  startDate,
  endDate,
  enabled = true,
  searchBy,
  searchQuery,
  requestObject,
}: {
  accountId: string;
  statusList?: TransactionStatus[];
  transactionCodeList?: TransactionCode[];
  transactionIndicatorList?: TransactionIndicator[];
  searchBy?: string;
  searchQuery?: string;
  requestObject?: KeyValuePairs | {};
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(search, 500);

  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.TRANSACTION_LIST,
      accountId,
      statusList,
      transactionCodeList,
      transactionIndicatorList,
      {
        pageNumber,
        pageSize,
        startDate,
        endDate,
        search: debouncedSearch,
        searchBy,
        searchQuery,
        accountId,
      },
    ],
    queryFn: () =>
      listTransactionsForAccount({
        accountId,
        page: pageNumber || 0,
        pageSize: pageSize || 5,
        statusList,
        transactionCodeList,
        transactionIndicatorList,
        proxyId: debouncedSearch,
        startDate,
        endDate,
        requestObject: requestObject ?? {},
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useTransactionChargesListQuery = ({
  transactionId,
  enabled,
}: {
  transactionId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.TRANSACTION_CHARGES_LIST, transactionId],
    queryFn: () => listTransactionCharges(transactionId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(transactionId),
  });

  return { data: data?.data, ...rest };
};

export const useChargesListFromAccountQuery = ({
  accountId,
  enabled,
}: {
  accountId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.TRANSACTION_CHARGES_LIST_BY_ACC, accountId],
    queryFn: () => listTransactionChargesByAccountId(accountId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useUnwaivedChargesListFromAccountQuery = ({
  accountId,
  enabled,
}: {
  accountId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.UNWAIVED_TRANSACTION_CHARGES_LIST_BY_ACC, accountId],
    queryFn: () => listUnwaivedTransactionChargesByAccountId(accountId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useTransactionRefundsListQuery = ({
  transactionId,
  enabled,
}: {
  transactionId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.TRANSACTION_REFUNDS_LIST, transactionId],
    queryFn: () => listTransactionRefund(transactionId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(transactionId),
  });

  return { data: data?.data, ...rest };
};

export const useTransactionChargebackListQuery = ({
  transactionId,
  accountId,
  pageNumber,
  pageSize,
  enabled,
  statusList,
  startDate,
  endDate,
  search,
}: {
  transactionId?: string;
  accountId?: string;
  statusList?: ChargebackStatus[];
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(search, 500);

  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.TRANSACTION_CHARGEBACK_LIST,
      transactionId,
      accountId,
      statusList,
      { pageNumber, pageSize, startDate, endDate, debouncedSearch },
    ],
    queryFn: () =>
      listTransactionChargeback({
        transactionId,
        accountId,
        page: pageNumber,
        pageSize,
        statusList,
        startDate,
        endDate,
        q: debouncedSearch,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled,
  });

  return { data: data?.data, ...rest };
};

export const useSurplusListQuery = ({
  accountId,
  pageNumber,
  pageSize,
  enabled,
  endDate,
  startDate,
  search,
}: {
  accountId: string;
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(search, 500);
  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.SURPLUS_LIST,
      accountId,
      { pageNumber, pageSize, endDate, startDate, debouncedSearch },
    ],
    queryFn: () =>
      getSurplusList({
        accountId,
        page: pageNumber,
        pageSize,
        endDate,
        startDate,
        q: search,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useWaiverListQuery = ({
  accountId,
  pageNumber,
  pageSize,
  enabled,
  endDate,
  startDate,
  search,
}: {
  accountId: string;
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(search, 500);

  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.WAIVER_LIST,
      accountId,
      { pageNumber, pageSize, endDate, startDate, debouncedSearch },
    ],
    queryFn: () =>
      getWaiverList({
        accountId,
        page: pageNumber,
        pageSize,
        endDate,
        startDate,
        q: debouncedSearch,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useMccInfoQuery = ({ mcc }: { mcc: number | undefined }) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.MCC_INFO, { mcc }],
    queryFn: () => getMccCodeInfo(mcc || 0),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: Infinity,
    enabled: mcc !== 0 ? Boolean(mcc) : true,
  });

  return { data: data?.data, ...rest };
};

export const useTransactionJourneyQuery = ({
  transactionId,
  enabled,
}: {
  transactionId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.TRANSACTION_JOURNEY, transactionId],
    queryFn: () => fetchTransactionJourney(transactionId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(transactionId),
  });

  return { data: data?.data, ...rest };
};

export const useDelinquencyHistoryListQuery = ({
  accountIds,
  enabled,
}: {
  accountIds: string[];
} & CommonQuery) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.DELINQUENCY_HISTORY_LIST, { accountIds }],
    queryFn: () =>
      DelinquencyServices.getDelinquencyHistoryByAccountId(accountIds),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountIds),
  });

  return { data: data?.data, ...rest };
};

export const useDelinquencyCurrentListQuery = ({
  accountId,
  enabled,
}: {
  accountId: string;
} & CommonQuery) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.DELINQUENCY_CURRENT_LIST, accountId],
    queryFn: () =>
      DelinquencyServices.getDelinquencyCurrentByAccountId(accountId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useDelinquencyStatQuery = ({
  accountId,
  enabled,
}: {
  accountId: string;
} & CommonQuery) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.DELINQUENCY_STATS_LIST, accountId],
    queryFn: () =>
      DelinquencyServices.getDelinquencyStatsByAccountId(accountId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useMidListQuery = ({ page }: { page: number }) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.MID_CODE, { page }],
    queryFn: () => getMidCodes(String(page)),
    refetchOnWindowFocus: false,
    staleTime: 1000000,
  });
  return { data: data?.data, ...rest };
};

export const useMccListQuery = ({
  page,
  pageSize,
  mid,
}: {
  page: string | number;
  pageSize: string | number;
  mid: string | number | undefined;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.MCC_LIST, { page, pageSize, mid }],
    queryFn: () => getMccCodes(page, pageSize, mid),
    refetchOnWindowFocus: false,
    staleTime: 1000000,
    enabled: Boolean(mid),
  });
  return { data: data?.data, ...rest };
};

export const useMerchantListQuery = ({
  pageNumber,
  pageSize,
  search,
  enabled = true,
}: CommonQuery) => {
  const debouncedName = useDebounce<string | undefined>(search, 500);
  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.MERCHANT_NAME_LIST,
      debouncedName,
      { page: pageNumber, pageSize },
    ],
    queryFn: () =>
      getMerchantNames({ page: pageNumber, pageSize, name: debouncedName }),
    refetchOnWindowFocus: false,
    staleTime: 1000000,
    enabled,
  });
  return { data: data?.data, ...rest };
};
