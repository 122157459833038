import React from 'react';
import { Stack, Box, SxProps, Theme } from '@mui/material';
import Vega_Text from '../../../../../../common/v3/Vega_Text';
import Vega_FilterSection from '../../../../../../common/v3/Vega_FilterSection';
import { NEW_PRIMARY } from '../../../../../../../constants/style';
import Vega_Select from '../../../../../../common/v3/Vega_Select';

interface IProps {
  header: string;
  children: JSX.Element;
  isDropdown?: boolean;
  sx?: SxProps<Theme>;
}

const TCSContainer = ({ header, children, isDropdown, sx }: IProps) => {
  return (
    <Box sx={sx}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Vega_Text
            text={header}
            fontWeight={600}
            color={NEW_PRIMARY.BLACK}
            fontSize={17}
          />
          {isDropdown && (
            <Vega_Select
              sx={{ width: '140px' }}
              value={'2023'}
              options={['2023']}
            />
          )}
        </Stack>
      </Stack>
      {children}
    </Box>
  );
};

export default TCSContainer;
