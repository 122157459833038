/* eslint-disable react/jsx-pascal-case */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ProgramInfo from './DataTile';
import { VegaUser, VegaUserStatus } from '../../types/user';
import { VegaSwitchV4 } from '../common/v4/VegaSwitchV4';

type MakerCheckerItemProps = {
  user?: VegaUser;
  onToggleClick?: () => void;
};

function MakerCheckerItem({ user, onToggleClick }: MakerCheckerItemProps) {
  return (
    <Box
      sx={{
        py: 1.5,
        borderRadius: 1,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6} sm={4} lg={2}>
          <ProgramInfo title="Name" value={user?.name ?? '-'} />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <ProgramInfo title="Employee ID" value={user?.corporateId ?? '-'} />
        </Grid>
        <Grid item xs={6} sm={5} lg={3}>
          <ProgramInfo title="Email ID" value={user?.email ?? '-'} />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <ProgramInfo title="Phone Number" value={user?.mobileNumber ?? '-'} />
        </Grid>

        {!!user?.status && (
          <Grid item xs={6} sm={3} lg={1} display="flex" alignItems="center">
            <VegaSwitchV4
              checked={user?.status === VegaUserStatus.ACTIVE}
              onChange={() => onToggleClick?.()}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default MakerCheckerItem;
