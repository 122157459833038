import { Box, Stack, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { QUICK_ACCESS } from '../../../constants/style';

interface IVegaCardV4Props extends PropsWithChildren {
  sxOuter?: SxProps;
  sxInner?: SxProps;
}

const VegaCardV4 = ({ sxOuter, sxInner, children }: IVegaCardV4Props) => {
  return (
    <Stack direction="row" sx={sxOuter}>
      <Box
        sx={{
          backgroundColor: QUICK_ACCESS.primary.blue,
          borderRadius: '8px 0px 0px 8px',
          width: '4px',
        }}
      />
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
        alignItems="flex-end"
        py={2}
        px={5}
        sx={{
          backgroundColor: QUICK_ACCESS.background[50],
          borderRadius: '0px 8px 8px 0px',
          width: '100%',
          ...sxInner,
        }}
        flexWrap="wrap"
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default VegaCardV4;
