import { Controller, useForm } from 'react-hook-form';
import VegaDrawerV4, { IVegaDrawerV4Props } from '../../common/v4/VegaDrawerV4';
import { Skeleton, Stack } from '@mui/material';
import VegaSelectV4 from '../../common/v4/VegaSelectV4';
import { ListHead2Typo } from '../../common/Typography';
import { convertCamelCase } from '../../../utils/stringUtils';
import VegaTextfieldV4 from '../../common/v4/VegaTextfieldV4';
import { useEffect, useMemo, useState } from 'react';
import VegaDateTimePickerV4 from '../../common/v4/VegaDateTimePicker';
import { createTransactionForAccount } from '../../../api/transaction';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  TransactionCode,
  TransactionIndicator,
} from '../../../enums/transaction';
import { PolicyTransactionCode } from '../../../types/policy';
import { useMerchantListQuery } from '../../../store/queries/transactionQueries';
import VegaAutocompleteSearchV4 from '../../common/v4/VegaAutocompleteSearchV4';
import SearchIcon from '../../icon/SearchIcon';

interface ITransactionFormProps {
  category: TransactionCode;
  amount: number;
  mcc_code?: number;
  merchant_location: string;
  merchant_name: string;
  date: string;
  mid_code?: string;
}

const TransactionDebitDrawer = (
  props: Omit<IVegaDrawerV4Props, 'children' | 'header'> & {
    accountId?: string;
    refetch: any;
  }
) => {
  const [loading, setLoading] = useState(false);
  const [merchant, setMerchant] = useState<string | undefined>(undefined);
  const [showAllInputs, setShowAllInputs] = useState(false);
  const { setSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
    getValues,
    watch,
    setValue,
  } = useForm<ITransactionFormProps>({
    mode: 'onTouched',
  });

  const { onClose, open, accountId, refetch, ...rest } = props;
  const watchMerchantName = watch('merchant_name');
  console.log('watchMerchantName', watchMerchantName, merchant);

  const { data: merchantData, isFetching: isMerchantDataFetching } =
    useMerchantListQuery({
      search: merchant || '',
      pageNumber: 0,
      pageSize: 10,
    });

  const merchantRecords = useMemo(
    () => merchantData?.records || [],
    [merchantData?.records]
  );

  const merchantMids = useMemo(
    () =>
      merchantRecords.find((item) => item.name === watchMerchantName)?.mids ||
      [],
    [merchantRecords, watchMerchantName]
  );
  const merchantMccCodes = useMemo(
    () =>
      merchantRecords.find((item) => item.name === watchMerchantName)
        ?.mccCodes || [],
    [merchantRecords, watchMerchantName]
  );

  const merchantOptions = useMemo(
    () =>
      merchantRecords.map((item) => {
        return {
          label: item.name,
          id: item.name,
        };
      }) || [],
    [merchantRecords]
  );

  const midOptions = useMemo(
    () =>
      merchantMids.map((rec) => {
        return {
          name: rec.mid,
          id: rec.mid,
        };
      }) || [],
    [merchantMids]
  );

  const mccOptions = useMemo(
    () =>
      merchantMccCodes.map((rec) => {
        return {
          name: rec.code,
          id: rec.code,
        };
      }) || [],
    [merchantMccCodes]
  );

  const onDrawerClose = (e: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    onClose?.(e, reason);
    reset();
  };

  const resetForm = () => {
    reset();
  };

  useEffect(() => {
    if (!open) {
      setMerchant(undefined);
    }
  }, [open]);

  useEffect(() => {
    setValue('mid_code', undefined);
    setValue('mcc_code', undefined);
  }, [merchant, setValue]);

  const createTransaction = () => {
    setLoading(true);
    const values = getValues();

    createTransactionForAccount({
      accountId: accountId || '',
      code: values.category as TransactionCode,
      amount: values.amount,
      time: values.date || undefined,
      mcc: values.mcc_code,
      mid: values.mid_code,
      merchantLocation: values.merchant_location,
      indicator: TransactionIndicator.DEBIT,
      merchantName: values.merchant_name,
    })
      .then((res) => {
        setSnackbar('Transaction created!');
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      })
      .finally(() => {
        setLoading(false);
        onClose?.({}, 'escapeKeyDown');
        refetch();
        reset();
      });
  };

  return (
    <VegaDrawerV4
      {...rest}
      open={Boolean(open && accountId)}
      onClose={onDrawerClose}
      header="Create Debit Transaction"
      submitBtn={{
        show: true,
        props: {
          value: 'Create Transaction',
          disabled: !isDirty || loading,
          loading: loading,
          onClick: handleSubmit(createTransaction),
        },
      }}
      secondaryBtn={{
        props: {
          value: 'Clear',
          onClick: resetForm,
          disabled: loading,
        },
      }}
    >
      <Stack gap={3}>
        <Controller
          name="category"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaSelectV4
              {...rest}
              error={Boolean(errors.category)}
              header="Category"
              labelText={errors.category?.message}
              value={rest.value || ''}
              standardWidth={400}
              options={Object.values(PolicyTransactionCode).map((el) => ({
                name: convertCamelCase(el),
                id: el,
              }))}
              wrapperProps={{
                sx: {
                  flex: 0,
                },
              }}
              required
              placeholder="Select Category"
            />
          )}
        />
        <Controller
          name="amount"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.amount)}
              header="Amount"
              type="number"
              labelText={errors.amount?.message}
              standardWidth={400}
              required
              placeholder="Enter Amount"
            />
          )}
        />
        <Controller
          name="merchant_name"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaAutocompleteSearchV4
              {...rest}
              textFieldProps={{
                ...TextFieldProps,
                error: Boolean(errors.merchant_name),
                labelText: errors.merchant_name?.message,
                onChange: (e) => {
                  setMerchant(e.target.value);
                  setShowAllInputs(false);
                },
                onBlur: () => {
                  setMerchant(watchMerchantName);
                  setShowAllInputs(Boolean(watchMerchantName));
                },
              }}
              options={merchantOptions}
              value={rest.value || ''}
              loading={isMerchantDataFetching}
              onChange={(e, val: any) => {
                setValue('merchant_name', val?.label);
                setMerchant(val?.label);
                setShowAllInputs(true);
              }}
              sx={{
                '& > .MuiStack-root': {
                  maxWidth: 'none',
                  width: '100%',
                  '& > .MuiFormControl-root': {
                    width: '100%',
                  },
                },
              }}
            />
          )}
        />
        {watchMerchantName && !isMerchantDataFetching && showAllInputs && (
          <>
            {Boolean(midOptions.length) && (
              <Controller
                name="mid_code"
                control={control}
                rules={{
                  required: 'Required field',
                }}
                render={({ field: { ref, ...rest } }) => (
                  <VegaSelectV4
                    {...rest}
                    error={Boolean(errors.mid_code)}
                    header="MID"
                    labelText={errors.mid_code?.message}
                    value={rest.value || ''}
                    standardWidth={400}
                    options={midOptions}
                    wrapperProps={{
                      sx: {
                        flex: 0,
                      },
                    }}
                    required
                    placeholder="Select MID"
                  />
                )}
              />
            )}
            {Boolean(mccOptions.length) && (
              <Controller
                name="mcc_code"
                control={control}
                rules={{
                  required: 'Required field',
                }}
                render={({ field: { ref, ...rest } }) => (
                  <VegaSelectV4
                    {...rest}
                    error={Boolean(errors.mcc_code)}
                    header="MCC"
                    labelText={errors.mcc_code?.message}
                    value={rest.value || ''}
                    standardWidth={400}
                    options={mccOptions}
                    wrapperProps={{
                      sx: {
                        flex: 0,
                      },
                    }}
                    required
                    placeholder="Select MCC"
                  />
                )}
              />
            )}
            <Controller
              name="merchant_location"
              control={control}
              rules={{
                required: 'Required field',
              }}
              render={({ field: { ref, ...rest } }) => (
                <VegaTextfieldV4
                  {...rest}
                  error={Boolean(errors.merchant_location)}
                  header="Merchant Location"
                  labelText={errors.merchant_location?.message}
                  standardWidth={400}
                  required
                  placeholder="Enter Merchant Location"
                />
              )}
            />
            <Controller
              name="date"
              control={control}
              rules={{
                required: 'Required field',
              }}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <VegaDateTimePickerV4
                    dateProps={{
                      ...rest,
                      views: ['year', 'month', 'day', 'hours', 'minutes'],
                    }}
                    textProps={{
                      header: 'Date',
                      required: true,
                      headerTypo: ListHead2Typo,
                      labelText: errors.date?.message,
                      error: Boolean(errors.date),
                      standardWidth: 400,
                      placeholder: 'Select Date and Time',
                    }}
                  />
                );
              }}
            />
          </>
        )}
        {watchMerchantName && isMerchantDataFetching && (
          <>
            <Stack gap={1}>
              <Skeleton variant="rounded" width={'30%'} height={20} />
              <Skeleton variant="rounded" width={'100%'} height={30} />
            </Stack>
            <Stack gap={1}>
              <Skeleton variant="rounded" width={'30%'} height={20} />
              <Skeleton variant="rounded" width={'100%'} height={30} />
            </Stack>
            <Stack gap={1}>
              <Skeleton variant="rounded" width={'30%'} height={20} />
              <Skeleton variant="rounded" width={'100%'} height={30} />
            </Stack>
          </>
        )}
      </Stack>
    </VegaDrawerV4>
  );
};

export const TextFieldProps = {
  header: 'Merchant Name',
  standardWidth: 300,
  InputProps: { endAdornment: <SearchIcon /> },
  placeholder: 'Search and Select',
};

export default TransactionDebitDrawer;
