import { useQuery } from 'react-query';
import { getAllReportConfigsForProgram } from '../../../api/reports';
import { IReportConfigReponse } from '../../../types/reports';

const getReportsConfig = async (programId: string | undefined) => {
  if (!programId) return null;
  const { data } = await getAllReportConfigsForProgram(programId);
  console.log('allReportConfigs', data);
  const hash = await (data as IReportConfigReponse[]).reduce((pre, curr) => {
    return {
      ...pre,
      [curr.reportConfigId]: curr,
    };
  }, {} as Record<string, IReportConfigReponse>);
  return hash;
};

export default function useReportsConfig(programId: string | undefined) {
  return useQuery(['reportsConfig', programId], () =>
    getReportsConfig(programId)
  );
}
