import { Box, Divider, Popover, Stack } from '@mui/material';
import { useClientAuth } from '../../providers/ClientProvider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ComponentBody1Typo, ComponentBody2Typo } from './Typography';
import { useState } from 'react';
import VegaButtonV4 from './v4/VegaButtonV4';
import { INDICATIVE, QUICK_ACCESS } from '../../constants/style';
import EmailIcon from '../icon/EmailIcon';
import PhoneIcon from '../icon/PhoneIcon';
import LogoutIcon from '../icon/LogoutIcon';
import LockIcon from '../icon/LockIcon';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import PersonIcon from '@mui/icons-material/Person';

interface IProfileDisplayProps {
  displayLogo?: boolean;
}

const ProfileDisplay = ({ displayLogo = true }: IProfileDisplayProps) => {
  const { client, loggedInUserDetails, userDetails, logout } = useClientAuth();
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetNavigator = () => {
    navigation(ROUTES.RESET_PASSWORD);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const email = loggedInUserDetails?.email;
  const mob = loggedInUserDetails?.mobileNumber || userDetails?.mobileNumber;

  return (
    <Box>
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        className="cursor-pointer"
        onClick={handleClick}
      >
        {displayLogo && (
          <Stack
            sx={{
              backgroundColor: 'rgba(66, 66, 66, 0.3)',
              borderRadius: 12,
              overflow: 'hidden',
              width: 24,
              height: 24,
            }}
            justifyContent="center"
            alignItems="center"
          >
            {client?.logo ? (
              <img
                src={client?.logo}
                alt={client?.clientName}
                width={'100%'}
                height={'100%'}
              />
            ) : (
              <PersonIcon fontSize="small" sx={{ color: 'gray' }} />
            )}
          </Stack>
        )}
        <ComponentBody1Typo>
          {loggedInUserDetails?.name || 'User'}
        </ComponentBody1Typo>
        <KeyboardArrowDownIcon
          fontSize="small"
          sx={{ color: QUICK_ACCESS.text[75] }}
        />
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack gap={0.5} minWidth={200} py={1}>
          <Stack gap={1} px={2} py={1} minWidth={30}>
            {email && (
              <Stack gap={1} direction="row" alignItems="center">
                <Stack width={20}>
                  <EmailIcon stroke={QUICK_ACCESS.text[100]} />
                </Stack>
                <ComponentBody2Typo sx={{ color: QUICK_ACCESS.text[100] }}>
                  {email}
                </ComponentBody2Typo>
              </Stack>
            )}
            {mob && (
              <Stack gap={1} direction="row" alignItems="center">
                <Stack width={20} pl={'2px'}>
                  <PhoneIcon />
                </Stack>
                <ComponentBody2Typo sx={{ color: QUICK_ACCESS.text[100] }}>
                  {mob}
                </ComponentBody2Typo>
              </Stack>
            )}
          </Stack>
          <Divider />
          <Stack gap={1} px={2} py={1}>
            <VegaButtonV4
              sx={{ pr: 0, pl: 0.5, py: 0.75, minWidth: 0 }}
              startIcon={<LockIcon />}
              onClick={resetNavigator}
            >
              Reset Password
            </VegaButtonV4>
            <VegaButtonV4
              sx={{
                pr: 0,
                pl: 0.6,
                py: 0.75,
                minWidth: 0,
                color: INDICATIVE.red[500],
              }}
              startIcon={<LogoutIcon />}
              onClick={logout}
            >
              Logout
            </VegaButtonV4>
          </Stack>
        </Stack>
      </Popover>
    </Box>
  );
};

export default ProfileDisplay;
