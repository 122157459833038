import Box from '@mui/material/Box';
import { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import DataTile from '../DataTile';
import {
  ChargePolicyInterface,
  ChargeRuleInterface,
  SlabChargesInterface,
} from '../../../types/policy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CHARGE_CODES_TO_RULE } from '../../../constants/policy';
import { convertCamelCase } from '../../../utils/stringUtils';
import { GridColDef, type GridValueFormatterParams } from '@mui/x-data-grid';
import ProgramDataGrid from './ProgramDataGrid';
import { QUICK_ACCESS } from '../../../constants/style';
import { SectionHeadingTypo } from '../../common/Typography';
import VegaAccordionV4, {
  VegaAccordionDetails,
  VegaAccordionSummary,
} from '../../common/v4/VegaAccordionV4';
import VegaBadgeStatusTagV4 from '../../common/v4/VegaBadgeStatusTagV4';

const getSlabChargeTableColumns = (): GridColDef<SlabChargesInterface>[] => [
  {
    field: 'start_amount',
    headerName: 'From Amount',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
  {
    field: 'end_amount',
    headerName: 'To Amount',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
  {
    field: 'value_type',
    headerName: 'Value Type',
    flex: 1,
    sortable: false,
    minWidth: 180,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      convertCamelCase(params.value),
  },
  {
    field: 'value',
    headerName: 'Value',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
];

const ChargesAccordion = ({
  title,
  data,
  isActive,
}: {
  title: string;
  data?: ChargeRuleInterface;
  isActive?: boolean;
}) => {
  const columnsRef = useMemo(() => getSlabChargeTableColumns(), []);

  const isSlabCharges = data?.charge?.charge_type === 'SLAB_CHARGES';

  const fixChargeContent = [
    {
      header: 'Value Type',
      value: isSlabCharges
        ? 'Slab'
        : `${
            data?.charge?.value_type
              ? convertCamelCase(data.charge.value_type)
              : '-'
          }`,
    },
    ...(!isSlabCharges
      ? [
          {
            header: 'Value',
            value: `${data?.charge?.value ?? '-'}`,
          },
        ]
      : []),
    {
      header: 'Taxation Type',
      value: `${
        data?.taxation_type ? convertCamelCase(data.taxation_type) : '-'
      }`,
    },

    ...(!isSlabCharges
      ? [
          {
            header: 'Charge Type',
            value: `${
              data?.charge?.charge_type
                ? convertCamelCase(data.charge.charge_type)
                : '-'
            }`,
          },
        ]
      : []),
  ];

  return (
    <VegaAccordionV4 disabled={!isActive}>
      <VegaAccordionSummary
        expandIcon={isActive && <ExpandMoreIcon fontSize="small" />}
        sx={{ flexDirection: 'row' }}
      >
        <SectionHeadingTypo
          sx={{
            color: QUICK_ACCESS.text[200],
          }}
        >
          {title}
        </SectionHeadingTypo>
        <VegaBadgeStatusTagV4
          text={isActive ? 'Active' : 'Inactive'}
          color={isActive ? 'green' : 'grey'}
          variant="stroked"
        />
      </VegaAccordionSummary>
      <VegaAccordionDetails>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 2,
          }}
        >
          <Grid container spacing={3}>
            {fixChargeContent.map(({ header, value }) => (
              <Grid item xs={6} sm={3} key={header}>
                <DataTile title={header} value={value} />
              </Grid>
            ))}
          </Grid>
          {isSlabCharges ? (
            <ProgramDataGrid
              data={data.charge.charges ?? []}
              columns={columnsRef}
              idColumn={'start_amount'}
            />
          ) : null}
        </Box>
      </VegaAccordionDetails>
    </VegaAccordionV4>
  );
};
type ViewChargesPolicyProps = {
  policy: ChargePolicyInterface;
};
function ViewChargesPolicy({ policy }: ViewChargesPolicyProps) {
  const chargesData = useMemo(
    () =>
      Object.keys(CHARGE_CODES_TO_RULE).map((code) => {
        const chargeRule = policy.charge_rules.find(
          (rule) => rule.charge_code === code
        );
        return {
          title: convertCamelCase(code),
          isActive: !!chargeRule,
          data: chargeRule,
        };
      }),
    [policy]
  );

  return (
    <Box
      sx={{
        py: 3,

        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      {chargesData.map((data) => (
        <ChargesAccordion
          key={data.title}
          title={data.title}
          isActive={data.isActive}
          data={data.data}
        />
      ))}
    </Box>
  );
}

export default ViewChargesPolicy;
