import { ISVGProps } from '../../mcc/types/Commons';

export const BackOfficeHomeIcon = (props: ISVGProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.55572 1.51625C7.80134 1.27063 8.19957 1.27063 8.4452 1.51625L10.2393 3.31032C10.4849 3.55595 10.4849 3.95418 10.2393 4.19981L8.4452 5.99388C8.19957 6.2395 7.80134 6.2395 7.55572 5.99388L5.76164 4.1998C5.51602 3.95418 5.51602 3.55595 5.76164 3.31032L7.55572 1.51625Z"
        stroke={props.stroke || '#EBEDEF'}
        strokeWidth="1.33339"
      />
      <path
        d="M11.7996 5.76018C12.0453 5.51455 12.4435 5.51455 12.6891 5.76018L14.4832 7.55425C14.7288 7.79987 14.7288 8.19811 14.4832 8.44373L12.6891 10.2378C12.4435 10.4834 12.0453 10.4834 11.7996 10.2378L10.0056 8.44373C9.75994 8.19811 9.75994 7.79987 10.0056 7.55425L11.7996 5.76018Z"
        stroke={props.stroke || '#EBEDEF'}
        strokeWidth="1.33339"
      />
      <path
        d="M3.31179 5.76018C3.55741 5.51455 3.95565 5.51455 4.20127 5.76018L5.99535 7.55425C6.24097 7.79987 6.24097 8.19811 5.99534 8.44373L4.20127 10.2378C3.95565 10.4834 3.55741 10.4834 3.31179 10.2378L1.51771 8.44373C1.27209 8.19811 1.27209 7.79987 1.51771 7.55425L3.31179 5.76018Z"
        stroke={props.stroke || '#EBEDEF'}
        strokeWidth="1.33339"
      />
      <path
        d="M7.55572 10.0041C7.80134 9.75848 8.19957 9.75848 8.4452 10.0041L10.2393 11.7982C10.4849 12.0438 10.4849 12.442 10.2393 12.6877L8.4452 14.4817C8.19957 14.7274 7.80134 14.7274 7.55572 14.4817L5.76164 12.6877C5.51602 12.442 5.51602 12.0438 5.76164 11.7982L7.55572 10.0041Z"
        stroke={props.stroke || '#EBEDEF'}
        strokeWidth="1.33339"
      />
    </svg>
  );
};
