import {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import InvoicePage from '../../../components/FinanceCenter/InvoicePage';
import ProfitLossPage from '../../../components/FinanceCenter/ProfitLossPage';

interface IFinanceCenterContextProps {
  currState?: FinancePageState;
  setCurrState?: Dispatch<React.SetStateAction<FinancePageState>>;
}

export type FinancePageState = 'invoice' | 'third-party' | 'profit-loss';

interface IFinanceCenterProps {
  state?: FinancePageState;
}

export const FinanceCenterContext = createContext<IFinanceCenterContextProps>(
  {}
);

export const useFinanceCenterContext = () => useContext(FinanceCenterContext);

const FinanceCenterWrap = ({ state }: IFinanceCenterProps) => {
  const [currState, setCurrState] = useState<FinancePageState>(
    state || 'invoice'
  );

  useEffect(() => {
    setCurrState(state || 'invoice');
  }, [state]);

  const display = useMemo(() => {
    switch (currState) {
      case 'invoice':
        return <InvoicePage renderState="vegapay" />;
      case 'third-party':
        return <InvoicePage renderState="third-party" />;
      case 'profit-loss':
        return <ProfitLossPage />;
    }
  }, [currState]);

  return (
    <FinanceCenterContext.Provider
      value={{
        currState,
        setCurrState,
      }}
    >
      {display}
    </FinanceCenterContext.Provider>
  );
};

export default FinanceCenterWrap;
