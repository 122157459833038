import {
  Stepper,
  Step,
  StepLabel,
  styled,
  StepIcon,
  StepConnector,
} from '@mui/material';
import { Label2Typo, ListHead1Typo } from '../Typography';
import { QUICK_ACCESS } from '../../../constants/style';
export interface StepperProps {
  totalSteps: string[];
  stepContent?: boolean;
  completedSteps: string[];
  updatedAt?: string;
}
interface ConnectorProps {
  active: boolean;
}
const CustomConnector = styled('div')<ConnectorProps>(
  ({ theme, active = false }) => ({
    left: '2.5px', // Adjust the value based on your design
    width: '1.5px',
    height: '40px',
    backgroundColor: !active ? '#ccc' : 'green', // Adjust the color as needed
    position: 'absolute',
    top: '11px',
  })
);
const CustomStepIcon = styled(StepIcon)(({ theme }) => ({
  '& .MuiStepIcon-root': {
    // Customize styles for the StepIcon container
    // For example, you can apply margins, padding, etc.
    marginTop: '12px', // Adjust as needed
    padding: '0', // Adjust as needed
  },
  color: '#999DA8',
  zIndex: 2, // Customize the color of the step circle
  '&.Mui-active': {
    color: 'green', // Customize the color of the active step circle
  },
  height: '6px',
  width: '6px',
  '& .MuiStepIcon-text': {
    display: 'none', // Hide the text inside the StepIcon
  },
  '& .MuiStepIcon-iconContainer': {
    display: 'none', // Hide the right icon inside the StepIcon
  },
}));
const VegaVerticalStepper = ({
  completedSteps,
  totalSteps,
  updatedAt,
}: StepperProps) => {
  return (
    <Stepper
      connector={<></>}
      orientation="vertical"
      sx={{
        gap: '30px',
      }}
    >
      {totalSteps.map((item, index) => (
        <Step
          sx={{
            position: 'relative',
          }}
          active={completedSteps.includes(item)}
          key={index}
        >
          <StepLabel
            sx={{
              '& .MuiStepLabel-label': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
              padding: 0,
            }}
            StepIconComponent={CustomStepIcon}
          >
            <ListHead1Typo
              sx={{
                color: completedSteps.includes(item) ? 'green' : '#999DA8',
              }}
            >
              {item}
            </ListHead1Typo>
            {completedSteps.includes(item) && (
              <Label2Typo sx={{ color: QUICK_ACCESS.text[100] }}>
                {updatedAt}
              </Label2Typo>
            )}
          </StepLabel>
          {index < totalSteps.length - 1 && (
            <CustomConnector active={completedSteps.includes(item)} />
          )}
        </Step>
      ))}
    </Stepper>
  );
};

export default VegaVerticalStepper;
