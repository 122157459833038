import { useQuery } from 'react-query';
import { QUERY_NAMES } from '../../enums/commons';
import {
  getBillFromLoanId,
  getBillsForAccount,
  getEMIsForBill,
  getPhysicalStatementList,
  getRepaymentsForBill,
  getTransactionsForBill,
} from '../../api/bill';
import { BillStatus, PhysicalStatementStatus } from '../../types/bill';
import { CommonQuery } from '../../types/commonType';
import useDebounce from '../../hooks/useDebounce';

export const useBackOfficeBillListQuery = ({
  accountId,
  pageNumber,
  pageSize,
  enabled,
  statusList,
  search,
  startDate,
  endDate,
}: {
  accountId: string;
  statusList?: BillStatus[];
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(search, 500);

  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.BILL_LIST_BO,
      accountId,
      statusList,
      { pageNumber, pageSize, debouncedSearch, startDate, endDate },
    ],
    queryFn: () =>
      getBillsForAccount({
        accountId: accountId,
        page: pageNumber || 0,
        pageSize: pageSize || 5,
        startDate,
        endDate,
        statusList,
        q: debouncedSearch,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};

export const useTransactionForBillListQuery = ({
  billId,
  pageNumber,
  pageSize,
  enabled,
}: {
  billId: string;
  pageNumber?: number;
  pageSize?: number;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.BILL_TRANSACTION_LIST_BO,
      billId,
      { pageNumber, pageSize },
    ],
    queryFn: () =>
      getTransactionsForBill(billId, pageNumber || 0, pageSize || 5),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(billId),
  });

  return { data: data?.data, ...rest };
};

export const useEMIsForBillListQuery = ({
  billId,
  enabled,
}: {
  billId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.BILL_EMIs_LIST_BO, billId],
    queryFn: () => getEMIsForBill(billId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(billId),
  });

  return { data: data?.data, ...rest };
};

export const useRepaymentsForBillListQuery = ({
  billId,
  enabled,
}: {
  billId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.BILL_REPAYMENTS_LIST_BO, billId],
    queryFn: () => getRepaymentsForBill(billId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(billId),
  });

  return { data: data?.data, ...rest };
};

export const usePhysicalStatementListQuery = ({
  accountId,
  programId,
  billId,
  pageNumber,
  pageSize,
  enabled,
  statusList,
  search,
  startDate,
  endDate,
}: {
  statusList?: PhysicalStatementStatus[];
  billId?: string;
  accountId?: string;
  programId?: string;
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(search, 500);

  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.PHYSICAL_STATEMENT_LIST,
      accountId,
      statusList,
      programId,
      billId,
      { pageNumber, pageSize, debouncedSearch, startDate, endDate },
    ],
    queryFn: () =>
      getPhysicalStatementList({
        accountId,
        programId,
        billId,
        page: pageNumber || 0,
        pageSize: pageSize || 5,
        startDate,
        endDate,
        statusList,
        q: debouncedSearch,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled,
  });

  return { data: data?.data, ...rest };
};

export const useBillFromLoanQuery = ({
  loanId,
  enabled,
}: {
  loanId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.BILL_LOAN, loanId],
    queryFn: () => getBillFromLoanId(loanId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(loanId),
  });

  return { data: data?.data, ...rest };
};
