import * as React from 'react';
const DeleteIconV4 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M1.6001 3.59995H14.4001M6.4001 11.6V6.79995M9.6001 11.6V6.79995M11.2001 14.8H4.8001C3.91644 14.8 3.2001 14.0836 3.2001 13.2V4.39995C3.2001 3.95812 3.55827 3.59995 4.0001 3.59995H12.0001C12.4419 3.59995 12.8001 3.95812 12.8001 4.39995V13.2C12.8001 14.0836 12.0838 14.8 11.2001 14.8ZM6.4001 3.59995H9.6001C10.0419 3.59995 10.4001 3.24178 10.4001 2.79995V1.99995C10.4001 1.55812 10.0419 1.19995 9.6001 1.19995H6.4001C5.95827 1.19995 5.6001 1.55812 5.6001 1.99995V2.79995C5.6001 3.24178 5.95827 3.59995 6.4001 3.59995Z"
      stroke={props.color || '#1047DC'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DeleteIconV4;
