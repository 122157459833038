import Stack, { StackProps } from '@mui/material/Stack';
import React from 'react';
import { SectionHeading2Typo } from './Typography';
import { INDICATIVE, VEGA_COMMON } from '../../constants/style';

type VegaWrapperType1Props = StackProps & {
  header: string;
};

function VegaWrapperType1({
  header,
  children,
  sx,
  ...rest
}: React.PropsWithChildren<VegaWrapperType1Props>) {
  return (
    <Stack
      sx={{
        borderRadius: 2,
        border: `1px solid ${VEGA_COMMON.border[50]}`,
        backgroundColor: VEGA_COMMON.background[50],
        ...sx,
      }}
      {...rest}
    >
      <Stack
        py={2}
        px={3}
        sx={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottom: `1px solid ${VEGA_COMMON.border[50]}`,
          bgcolor: INDICATIVE.blue[50],
        }}
      >
        <SectionHeading2Typo>{header}</SectionHeading2Typo>
      </Stack>
      <Stack p={3}>{children}</Stack>
    </Stack>
  );
}

export default VegaWrapperType1;
