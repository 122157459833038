import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { VegaProgramWithMetaDataType } from '../../types/program';
import type { AnyPolicyType } from '../../types/policy';
import { AppState } from '..';
import type { VegaKycBaseStep } from '../../components/ApplicationManagment/KYCflow/KYCFlowDataTypes';
import type { AddOnCardConfiguration, CardSettings } from '../../types/card';
import { CardConfigProperties } from '../../providers/CardConfigProvider';
import { STORE_SLICE } from '../../enums/commons';

type ProgramManagementState = {
  selectedProgram: VegaProgramWithMetaDataType | null;
  policies: AnyPolicyType[];
  onboardingKycFlow: VegaKycBaseStep[] | null;
  cardSettings: CardSettings | null;
  physicalCardConfig: CardConfigProperties | null;
  virtualCardConfig: CardConfigProperties | null;
  addonCardConfig: AddOnCardConfiguration | null;
};

const initialState: ProgramManagementState = {
  selectedProgram: null,
  policies: [],
  onboardingKycFlow: null,
  cardSettings: null,
  physicalCardConfig: null,
  virtualCardConfig: null,
  addonCardConfig: null,
};

const programManagementSlice = createSlice({
  name: STORE_SLICE.PROGRAM_MANAGEMENT,
  initialState,
  reducers: {
    setPolicies: (
      state: ProgramManagementState,
      action: PayloadAction<AnyPolicyType[]>
    ) => {
      state.policies = action.payload;
    },
    clearProgramManagement: (state: ProgramManagementState) => {
      state.policies = [];
      state.selectedProgram = null;
      state.onboardingKycFlow = null;
      state.cardSettings = null;
      state.physicalCardConfig = null;
      state.virtualCardConfig = null;
      state.addonCardConfig = null;
    },
    setSelectedProgram: (
      state: ProgramManagementState,
      action: PayloadAction<VegaProgramWithMetaDataType>
    ) => {
      state.selectedProgram = action.payload;
    },
    setOnboardingKycFlow: (
      state: ProgramManagementState,
      action: PayloadAction<VegaKycBaseStep[]>
    ) => {
      state.onboardingKycFlow = action.payload;
    },
    setCardSettings: (
      state: ProgramManagementState,
      action: PayloadAction<CardSettings>
    ) => {
      state.cardSettings = action.payload;
    },
    setPhysicalCardConfig: (
      state: ProgramManagementState,
      action: PayloadAction<CardConfigProperties>
    ) => {
      state.physicalCardConfig = action.payload;
    },
    setVirtualCardConfig: (
      state: ProgramManagementState,
      action: PayloadAction<CardConfigProperties>
    ) => {
      state.virtualCardConfig = action.payload;
    },
    setAddOnCardConfig: (
      state: ProgramManagementState,
      action: PayloadAction<AddOnCardConfiguration>
    ) => {
      state.addonCardConfig = action.payload;
    },
  },
});

export const {
  setPolicies,
  clearProgramManagement,
  setSelectedProgram,
  setOnboardingKycFlow,
  setCardSettings,
  setPhysicalCardConfig,
  setVirtualCardConfig,
  setAddOnCardConfig,
} = programManagementSlice.actions;
export const getProgramManagementState = (state: AppState) =>
  state.programManagement;
export default programManagementSlice;
