import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { GENERIC_BORDER, QUICK_ACCESS } from '../../../constants/style';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    TransitionProps={{ unmountOnExit: true }}
    {...props}
  />
))(({ theme }) => ({
  border: GENERIC_BORDER,
  borderRadius: '8px !important',
  '& .MuiPaper-root': {
    borderRadius: '8px',
  },
  '& .MuiAccordion-rounded': {
    borderRadius: '8px',
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-disabled': {
    borderColor: '#eee',
  },
}));

export const VegaAccordionSummary = styled(MuiAccordionSummary)(
  ({ theme }) => ({
    backgroundColor: QUICK_ACCESS.background[75],
    padding: 0,
    borderRadius: '8px',
    '& .MuiAccordionSummary-expandIconWrapper': {
      order: 1,
    },
    '&.MuiAccordionSummary-root': {
      minHeight: '40px',
      padding: '12px',
      gap: '12px',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      alignItems: 'center',
      gap: '12px',
    },
    '& .MuiAccordionSummary-content.Mui-expanded .expandIcon': {
      transition: 'transform 200ms',
      transform: 'rotate(180deg)',
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '&.Mui-disabled': {
      opacity: 1,
      backgroundColor: QUICK_ACCESS.background[75],
    },
    '&.Mui-expanded': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  })
);

export const VegaAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: QUICK_ACCESS.background[50],
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  })
);

type VegaAccordionV4Props = {
  disabled?: boolean;
  expandable?: boolean;
} & AccordionProps;

function VegaAccordionV4({
  children,
  disabled,
  expandable = true,
  ...rest
}: VegaAccordionV4Props) {
  return (
    <Accordion
      disabled={disabled}
      {...(!expandable && !disabled && { expanded: true })}
      {...rest}
    >
      {children}
    </Accordion>
  );
}

export default VegaAccordionV4;
