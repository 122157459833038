import React, { Dispatch, SetStateAction, useState } from 'react';
import VegaModal from '../../../components/common/VegaModal';
import { AddCircleOutline } from '@mui/icons-material';
import CreatePartner from '../components/PartnerMangement/CreatePartner';
import AddPartner from '../components/PartnerMangement/AddPartner';
import CreateUser from '../components/PartnerMangement/CreateUser';
import PartnerService from '../../../services/PartnerService';
import { ICreatePartnerRequest, IPartner } from '../../../types/partner';
import {
  IAddUserRequest,
  UserPlatformEnums,
  UserTeamType,
  addRole,
  addRoleForUser,
  addUser,
} from '../api/roles';
import { EntityStatusEnum } from '../../../enums/commons';
import { getAccessFeaturesForPartner } from '../components/PartnerMangement/utils/addUserForPartner';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { usePartnersList } from '../../../store/queries/partnerQueries';
import { useClientAuth } from '../../../providers/ClientProvider';

interface ICreatePartnersModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedPartner: IPartner | null;
}

const CreatePartnersModal = ({
  open,
  setOpen,
  selectedPartner,
}: ICreatePartnersModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();

  const onModalClose = () => {
    setOpen(false);
  };

  const partnersQuery = usePartnersList({
    clientId: clientId || '',
    page: 0,
    pageSize: 50,
  });

  const onSubmit = async (data: {
    partner: ICreatePartnerRequest;
    user: IAddUserRequest;
  }) => {
    setIsLoading(true);
    PartnerService.createPartner({
      ...data.partner,
      clientId: clientId || '',
      status: EntityStatusEnum.ACTIVE,
    })
      .then((res: IPartner) => {
        partnersQuery.refetch();
        setSnackbar('Successfully created partner');

        const { designation, ...user } = data.user;
        const userPromise = addUser({
          ...user,
          clientId: clientId || '',
          platformId: res.id,
          platformName: UserPlatformEnums.FOREX_HQ,
          mccUser: true,
          idType: 'MOBILE_NO',
          idNumber: data.user.mobileNumber,
          status: 'ACTIVE',
          team: UserTeamType.OPERATIONS,
        });

        const rolePromise = addRole({
          clientId,
          roleName: res.id + '_ADMIN',
          createdBy: user?.name || '',
          status: 'ACTIVE',
          accessFeatures: getAccessFeaturesForPartner(res.id),
        });

        Promise.all([userPromise, rolePromise]).then((res) => {
          const user = res[0].data;
          const role = res[1].data;

          const roleForUser = {
            userId: user.userId,
            roleId: role.id,
            roleName: role?.roleName,
            checkerMakerFlag: true,
          };
          addRoleForUser(roleForUser)
            .then(() => {
              setSnackbar('Success fully created user');
            })
            .catch((err) => {
              console.log('🚀 ~ file: PartnerManagement.tsx:236 ~ err:', err);
              setSnackbar('Error in adding user and role ', 'error');
            })
            .finally(() => {
              setIsLoading(false);
              onModalClose();
            });
        });
      })
      .catch((err) => {
        console.log('🚀 ~ file: PartnerManagement.tsx:243 ~ err:', err);
        setSnackbar('Error in adding user and role ', 'error');
      })
      .finally(() => {
        setIsLoading(false);
        onModalClose();
      });
  };

  return (
    <VegaModal
      header={'Create Partners'}
      open={open}
      handleClose={onModalClose}
      isButton={false}
      handleSubmit={() => {}}
      buttonIcon={<AddCircleOutline />}
      zIndex={1600}
    >
      <AddPartner
        components={[
          <CreatePartner
            initialValue={
              selectedPartner
                ? ({ ...selectedPartner, ...selectedPartner.address } as any)
                : undefined
            }
          />,
          <CreateUser />,
        ]}
        onSubmit={onSubmit}
        onCancel={onModalClose}
        steps={
          selectedPartner
            ? ['Entity Details']
            : ['Entity Details', 'Admin Details']
        }
        isLoading={isLoading}
      />
    </VegaModal>
  );
};

export default CreatePartnersModal;
