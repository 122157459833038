import { Box } from '@mui/material';
import { useMemo } from 'react';
import VegaTableV4, { ITableDataProps } from '../common/v4/VegaTableV4';

const year = new Date().getFullYear();
const records = [
  {
    month: 'Jan',
    secondSat: '03, 04, 11, 18,',
    fourthSat: '04, 11, 18, 25',
  },
  {
    month: 'Jan',
    secondSat: '03, 04, 11, 18,',
    fourthSat: '04, 11, 18, 25',
  },
];

const InstitutionStandardSaturdayHolidays = () => {
  const structuredData: ITableDataProps[] = useMemo(() => {
    return (
      records.map((rec, idx) => {
        return {
          rowData: [
            {
              value: rec.month,
              type: 'string',
              cellSx: {
                width: '200px',
              },
            },
            {
              value: rec.secondSat,
              type: 'string',
            },
            {
              value: rec.fourthSat,
              type: 'string',
            },
          ],
        };
      }) || []
    );
  }, []);

  return (
    <Box mt={2}>
      <VegaTableV4
        head={[`Month ${year}`, '2nd Saturday', '4th Saturday']}
        data={structuredData}
      />
    </Box>
  );
};

export default InstitutionStandardSaturdayHolidays;
