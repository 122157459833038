import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../../../../common/Typography';
import VegaTableV4, {
  ITableDataProps,
} from '../../../../../../common/v4/VegaTableV4';
import { IBillResponseData } from '../../../../../../../types/bill';
import { useState } from 'react';
import { useTransactionForBillListQuery } from '../../../../../../../store/queries/billQueries';
import { getTransactionForBillStructuredData } from '../../../../supportUtils';
import VegaNoResults from '../../../../../../common/VegaNoResults';

interface IBillDetailsTransactionsProps {
  data: IBillResponseData | undefined;
}

const BillDetailsTransactions = ({ data }: IBillDetailsTransactionsProps) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const { data: transactionData, isFetching } = useTransactionForBillListQuery({
    billId: data?.id || '',
    pageNumber: page,
    pageSize,
  });

  const records = transactionData?.records;

  const structuredTableData = getTransactionForBillStructuredData(records);

  return (
    <Stack gap={1.5}>
      <PageHeadingTypo>Transactions</PageHeadingTypo>
      {!Boolean(transactionData?.records.length) && !isFetching ? (
        <VegaNoResults errorText="No Transactions found" />
      ) : (
        <VegaTableV4
          head={[
            'Transaction Time',
            'Transaction ID',
            'Amount',
            'Over limit',
            'Transaction Category',
            'Merchant Name',
            'Indicator',
            'Type',
            'Status',
          ]}
          data={structuredTableData}
          isLoading={isFetching}
          pagination={{
            rowsPerPage: pageSize,
            setRowsPerPage: setPageSize,
            setPage,
            page,
            totalRecords: transactionData?.totalItems || 0,
          }}
        />
      )}
    </Stack>
  );
};

export default BillDetailsTransactions;
