export enum ANALYTICS_QUERY_NAME {
  CCMS_NEW_APPLICATION_CREATED_BY_DATE = 'CCMS_NEW_APPLICATION_CREATED_BY_DATE',
  CCMS_APPLICATION_CREATED_BY_MONTH = 'CCMS_APPLICATION_CREATED_BY_MONTH',
  CCMS_APPLICATION_CREATED_DATE_RANGE = 'CCMS_APPLICATION_CREATED_DATE_RANGE',

  CCMS_NEW_LOS_LIMIT_OFFER_CREATED_BY_DATE = 'CCMS_NEW_LOS_LIMIT_OFFER_CREATED_BY_DATE',
  CCMS_NEW_LOS_LIMIT_OFFER_BY_MONTH = 'CCMS_NEW_LOS_LIMIT_OFFER_BY_MONTH',
  CCMS_LOS_LIMIT_OFFER_DATE_RANGE = 'CCMS_LOS_LIMIT_OFFER_DATE_RANGE',

  CCMS_NEW_ACCOUNT_BY_DATE = 'CCMS_NEW_ACCOUNT_BY_DATE',
  CCMS_NEW_ACCOUNT_BY_MONTH = 'CCMS_NEW_ACCOUNT_BY_MONTH',
  CCMS_ACCOUNT_DATE_RANGE = 'CCMS_ACCOUNT_DATE_RANGE',

  CCMS_TRANSACTING_CUSTOMER_DATA_RANGE = 'CCMS_TRANSACTING_CUSTOMER_DATA_RANGE',

  CCMS_TRANSACTION_AMOUNT_BY_CATEGORY_FOR_DATE = 'CCMS_TRANSACTION_AMOUNT_BY_CATEGORY_FOR_DATE',
  CCMS_TRANSACTION_AMOUNT_MONTHLY_DATA = 'CCMS_TRANSACTION_AMOUNT_MONTHLY_DATA',

  CCMS_LEDGER_SUMMARY_DATE_RANGE = 'CCMS_LEDGER_SUMMARY_DATE_RANGE',

  CCMS_BILL_PAID_MONTH_RANGE = 'CCMS_BILL_PAID_MONTH_RANGE',
  CCMS_BILL_PAID_DATE_BY_DATE = 'CCMS_BILL_PAID_DATE_BY_DATE',
  CCMS_BILLING_DATA = 'CCMS_BILLING_DATA',

  CCMS_REPAYMENT_AMOUNT_MONTHLY_DATA = 'CCMS_REPAYMENT_AMOUNT_MONTHLY_DATA',
  CCMS_REPAYMENT_AMOUNT_DATE_BY_DATE = 'CCMS_REPAYMENT_AMOUNT_DATE_BY_DATE',
}
