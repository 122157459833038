import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import Typography from '@mui/material/Typography';
import ProgramStatus from './ProgramStatus';
import { VegaProgramWithMetaDataType } from '../../types/program';
import { AnyPolicyType, PolicyStatus } from '../../types/policy';
import dayjs from 'dayjs';
import { VPButton } from '../../ui-components/common';
import { VPPRIMARY } from '../../ui-components/theme';
import { getLogoForNetwork } from '../icon/Icons';
import { VegaUser } from '../../types/user';
import { convertCamelCase } from '../../utils/stringUtils';
import VegaButtonV4 from '../common/v4/VegaButtonV4';

type ProgramManagementDashboardColumnTypes =
  GridColDef<VegaProgramWithMetaDataType>[];

export const getProgramManagementDashboardColumns = (
  onClickAction: (program: VegaProgramWithMetaDataType) => void
): ProgramManagementDashboardColumnTypes => {
  return [
    {
      field: 'name',
      headerName: 'Program Name',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },

    {
      field: 'programId',
      headerName: 'Program ID',
      flex: 1.8,
      minWidth: 180,
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Program Type',
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (props) => props.row.type,
      valueFormatter: ({ value }) => (value ? convertCamelCase(value) : '-'),
    },
    {
      field: 'coBrandPartnerName',
      headerName: 'Co-branding Partner',
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (props) => props.row.coBrandPartnerName,
      renderCell: ({ value }) => {
        return (
          <Typography sx={{ fontSize: 14 }}>
            {value ? startCase(toLower(value)) : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'network',
      headerName: 'Network',
      sortable: false,
      flex: 0.8,
      valueGetter: (props) => props.row.network,
      renderCell: ({ value }) => {
        return value ? <Box>{getLogoForNetwork(value)}</Box> : '-';
      },
    },
    {
      field: 'binDetails',
      headerName: 'Bin Details',
      sortable: false,
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => props.row.bin?.binValue,
      renderCell: ({ value }) => {
        return <Typography sx={{ fontSize: 14 }}>{value ?? '-'}</Typography>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1,
      minWidth: 100,
      valueGetter: (props) => props.row.status,
      renderCell: ({ value }) => {
        return value ? <ProgramStatus status={value} /> : '-';
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      minWidth: 120,
      renderCell: ({ value, row }) => {
        return (
          <VegaButtonV4 variant="text" onClick={() => onClickAction(row)}>
            View Details
          </VegaButtonV4>
        );
      },
    },
  ];
};
