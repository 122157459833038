import { Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import { VegaTable } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaLabel from '../../../../components/common/VegaLabel';
import { getLogoForNetwork } from '../../../../components/icon/Icons';
import { toLowerCase } from '../../../../constants/commonFunction';
import { GREEN } from '../../../../constants/style';
import StatusModal from '../../../components/StatusModal';
import { IConsolidatedAccountData } from '../../../types/Commons';
import { STATUS_DATA } from '../../../variable';

interface IProps {
  data: any[];
  loading: boolean;
  onStatusChange: (data: any, status: string) => void;
  setRowMultiSelect: React.Dispatch<React.SetStateAction<string[]>>;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  rowsCount: number;
}

const ListOfCardChecker = ({
  data,
  loading,
  onStatusChange,
  setRowMultiSelect,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  rowsCount,
}: IProps) => {
  const [customerDetails, setCustomerDetails] = useState<any>({});
  const [status, setStatus] = useState<string>('');

  const [open, setOpen] = useState<boolean>(false);

  const COLUMN: GridColumns<IConsolidatedAccountData> = [
    {
      field: 'customerId',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Customer Name
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return (
          <Typography>
            {props.row.customer?.firstName || props.row.customer?.name || ''}
          </Typography>
        );
      },
    },
    // {
    //   field: 'customerType',
    //   renderHeader: () => (
    //     <Typography variant="body2">Customer Type</Typography>
    //   ),
    //   flex: 0.7,
    //   renderCell: (props) => {
    //     const customerType = props.row?.customer?.customerType;
    //     return (
    //       <VegaLabel
    //         text={customerType === 'RETAIL_CUSTOMER' ? 'Retail' : 'Corprate'}
    //         color={'primary'}
    //       />
    //     );
    //   },
    // },
    {
      field: 'programName',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Program Name
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props) => {
        const programName = props.row?.account?.programName;
        return <VegaLabel text={programName ?? '-'} color={'primary'} />;
      },
    },
    {
      field: 'binValue',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Bin Value
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props) => {
        const binValue = props.row?.card?.binId;
        return <VegaLabel text={binValue ?? '-'} color={'primary'} />;
      },
    },
    {
      field: 'cardNo',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Card No
        </Typography>
      ),
      flex: 1,
      renderCell: (props) => {
        return (
          <VegaLabel
            text={props?.row?.card?.cardNumber ?? '--'}
            color="primary"
          />
        );
      },
    },

    {
      field: 'expiryDate',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Card Expiry
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props) => {
        return (
          <VegaLabel
            text={props?.row?.card?.expiryDate ?? ''}
            color="primary"
          />
        );
      },
    },

    {
      field: 'cardNetwork',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Card Network
        </Typography>
      ),
      flex: 0.5,
      renderCell: (props) => {
        const networks = props.row.card?.cardNetwork || props.row.card?.network;
        if (!networks || networks.length <= 0) {
          return;
        }
        return (
          <Stack direction={'row'} alignItems="center" spacing={2}>
            <div>{getLogoForNetwork(networks)}</div>
          </Stack>
        );
      },
    },

    {
      field: 'status',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Status
        </Typography>
      ),
      flex: 0.5,

      renderCell: (props: any) => {
        const status = props.row.account?.checkerMakerStatus;
        return (
          <VegaLabel
            text={toLowerCase(status)}
            color={
              status === 'APPROVED'
                ? 'success'
                : status === 'REJECTED'
                ? 'danger'
                : 'primary'
            }
          />
        );
      },
    },
    {
      field: 'action2',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      renderCell: (props) => {
        return props.row.account?.status === 'DRAFT' ||
          (props.row.account?.status === 'UNDER_REVIEW' &&
            props.row.account?.checkerMakerStatus === 'CHECKER_PENDING') ? (
          <VegaContainedButton
            text="Edit"
            onClick={() => showDetails(props.row)}
            isPrimary
          />
        ) : (
          props.row.account?.checkerMakerStatus !== null && (
            <VegaContainedButton text="Sent" isPrimary disabled />
          )
        );
      },
      flex: 0.7,
    },
  ];

  const showDetails = (data: any) => {
    setCustomerDetails(data);

    setOpen(!open);
  };

  const decline = () => {
    setOpen(!open);
  };
  const approve = async () => {
    await onStatusChange(customerDetails, status);
    setOpen(!open);
    setStatus('');
  };

  const handleStatusChange = (e: any) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <>
        <VegaTable
          noTopRadius
          data={data}
          columnDef={COLUMN}
          idColumn="id"
          loading={loading}
          disableSelectionOnClick={true}
          checkboxSelection={true}
          onRowSelect={(e) => setRowMultiSelect(e)}
          paginationMode="server"
          page={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          rowCount={rowsCount}
          isSelected={(parm: any) => {
            if (parm.row.account.checkerMakerStatus === 'APPROVED') {
              return false;
            } else if (
              parm.row.account.status === 'ACTIVE' &&
              parm.row.account.checkerMakerStatus === 'CHECKER_PENDING'
            ) {
              return false;
            } else if (
              parm.row.account.status === 'UNDER_REVIEW' &&
              parm.row.account.checkerMakerStatus === 'CHANGES_REQUESTED'
            ) {
              return false;
            } else {
              return true;
            }
          }}
        />
      </>
      <StatusModal
        header="Status "
        onSubmit={approve}
        open={open}
        radioData={STATUS_DATA}
        name="status"
        value={status}
        onChange={handleStatusChange}
        labelPlacement={'right'}
        handleClose={() => {
          setOpen(!open);
          setStatus('');
        }}
        color={GREEN.dark}
      />
    </>
  );
};

export default ListOfCardChecker;
