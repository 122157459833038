import React from 'react';
import Box from '@mui/material/Box';
import DataTile from '../DataTile';
import { BillingPolicyInterface } from '../../../types/policy';
import { convertCamelCase } from '../../../utils/stringUtils';

type BillingPolicyProps = {
  policy: BillingPolicyInterface;
};

const containerSx = {
  width: 180,
};

function ViewBillingPolicy({ policy }: BillingPolicyProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        gap: 3,
        flex: 1,
        py: 3,
      }}
    >
      <DataTile
        title="Billing Cycle Type"
        value={convertCamelCase(policy.billing_cycle_type)}
        containerSx={containerSx}
      />
      <DataTile
        title="Cycle End Day"
        value={`${policy.cycle_end_date} day of the month`}
        containerSx={containerSx}
      />
      <DataTile
        title="Bill Generation"
        value={`${policy.days_between_end_date_and_bill_date} day(s) after cycle end date`}
        containerSx={containerSx}
      />
      <DataTile
        title="Due Date"
        value={`${policy.due_date} of every month`}
        containerSx={containerSx}
      />
    </Box>
  );
}

export default ViewBillingPolicy;
