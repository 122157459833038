import { useEffect, useMemo, useState } from 'react';
import { PolicyType, type AnyPolicyType } from '../../../types/policy';
import { fetchAllEffectivePolicesForProgram } from '../../../api/policy';
import { AxiosError } from 'axios';
import Box from '@mui/material/Box';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { setPolicies } from '../../../store/common/programManagementSlice';
import VegaNoResults from '../../common/VegaNoResults';
import PolicyView from '../containers/PolicyView';

type PoliciesProps = {
  programId: string;
};

const formatVisiblePolicies = (policies: AnyPolicyType[]) => {
  return policies.filter(
    (policy) =>
      ![
        PolicyType.TCS,
        PolicyType.PASSWORD_POLICY,
        PolicyType.FRM_POLICY,
      ].includes(policy?.policy_type)
  );
};

const fetchPolicies = (programId: string) => {
  return new Promise((resolve, reject) => {
    fetchAllEffectivePolicesForProgram(programId)
      .then((res) => {
        const allPolicies = res?.data;
        if (!allPolicies) {
          reject(new AxiosError('Failed to fetch policies of program'));
        }
        resolve(allPolicies);
      })
      .catch(reject);
  });
};

function Policies({ programId }: PoliciesProps) {
  const { setSnackbar } = useSnackbar();
  const { policies } = useAppSelector((state) => state.programManagement);
  const [isLoading, setIsLoading] = useState(true);
  const formattedPolicies = useMemo(
    () => formatVisiblePolicies(policies),
    [policies]
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!policies.length) {
      fetchPolicies(programId)
        .then((res) => {
          dispatch(setPolicies(res as AnyPolicyType[]));
        })
        .catch((err) => {
          setSnackbar('Failed to fetch policies!', 'error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  return formattedPolicies.length > 0 || isLoading ? (
    <PolicyView />
  ) : (
    <Box
      sx={{
        padding: {
          xs: 1,
          sm: 3.25,
        },
        flex: 1,
      }}
    >
      <VegaNoResults errorText="No Policies Found" />
    </Box>
  );
}

export default Policies;
