import axios, { AxiosResponse } from 'axios';
import {
  CardConfigProperties,
  CardType,
} from '../providers/CardConfigProvider';
import {
  AddCardPayload,
  AddOnCardConfiguration,
  BinSettings,
  CardBlockInfo,
  CardCategory,
  CardDto,
  CardSettings,
  CardStatus,
  CustomerPreferenceStatus,
  CustomerPreferenceType,
  PhysicalCardOrder,
  VegaCustomerCard,
} from '../types/card';
import { BASE_URL, CARD_PROCESSOR } from './endpoints';
import { PaginatedResponse } from '../utils/api';

const CARD_BASE_URL = BASE_URL + CARD_PROCESSOR.BASE;
const CARD_MANAGEMENT_BASE_URL =
  CARD_BASE_URL + CARD_PROCESSOR.CARD_MANAGEMENT_BASE;

export async function getPreSignURL(url: string) {
  try {
    const endpoint = CARD_MANAGEMENT_BASE_URL + '/presignUrl';
    const response = await axios.post(endpoint, { url });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCardById(data: {
  cardId: string;
}): Promise<VegaCustomerCard> {
  const endpoint = CARD_BASE_URL + CARD_PROCESSOR.GET_CARD_BY_ID;
  try {
    const response = await axios.get(endpoint, {
      params: { cardId: data.cardId },
    });
    return response.data as VegaCustomerCard;
  } catch (error) {
    throw error;
  }
}

export async function getActiveCardList(data: {
  accountId: string;
}): Promise<VegaCustomerCard[]> {
  const endpoint = CARD_BASE_URL + CARD_PROCESSOR.LIST_ACTIVE_CARD;
  try {
    const response = await axios.get(endpoint, {
      params: { accountId: data.accountId },
    });
    return response.data as VegaCustomerCard[];
  } catch (error) {
    throw error;
  }
}

export async function getCardListByCustomer({
  accountId,
  pageNumber = 0,
  pageSize = 10,
  cardStatusList,
}: {
  accountId: string;
  pageNumber: number;
  pageSize: number;
  cardStatusList?: CardStatus[];
}): Promise<AxiosResponse<PaginatedResponse<CardDto>>> {
  const endpoint = CARD_BASE_URL + CARD_PROCESSOR.LIST_ACTIVE_CARD;
  try {
    return await axios.post(endpoint, {
      accountId,
      pageNumber,
      pageSize,
      cardStatusList,
    });
  } catch (error) {
    throw error;
  }
}

export async function getCardDataByCardId(cardId: string): Promise<CardDto> {
  const endpoint = CARD_BASE_URL + '/getCard';
  try {
    const response = await axios.get(endpoint, {
      params: { cardId },
    });
    return response.data as CardDto;
  } catch (error) {
    throw error;
  }
}

export async function getCardsList(data: {
  accountId: string;
}): Promise<CardDto[]> {
  const endpoint = CARD_BASE_URL + CARD_PROCESSOR.LIST_CARD;
  try {
    const response = await axios.get(endpoint, {
      params: { accountId: data.accountId },
    });
    return response.data as CardDto[];
  } catch (error) {
    throw error;
  }
}

export async function updateCardStatus(data: {
  cardId: string;
  cardStatus: CardStatus;
  reason?: string;
}): Promise<CardDto[]> {
  const endpoint = `${BASE_URL}${CARD_PROCESSOR.BASE}/status`;
  try {
    const response = await axios.patch(endpoint, { ...data, crmUpdate: true });
    return response.data as CardDto[];
  } catch (error) {
    throw error;
  }
}

export async function updateCardControlPreference(data: {
  cardId: string;
  locationType: string;
  type: CustomerPreferenceType;
  customerPreferenceStatus: CustomerPreferenceStatus;
  limit: number;
}): Promise<any> {
  return axios.post(`${BASE_URL}${CARD_PROCESSOR.BASE}/control`, data);
}

export interface UpdateCardPreferanceBody {
  cardId: string;
  locationType: string;
  type: string;
  limit: number;
  customerPreferenceStatus: string;
}
export async function updateCustomerPreference(
  data: UpdateCardPreferanceBody
): Promise<any> {
  const endpoint = CARD_BASE_URL + CARD_PROCESSOR.UPDATE_CARD_PREFERANCE;
  try {
    const response = await axios.post(endpoint, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getBinSettings(data: {
  programId: string;
}): Promise<BinSettings> {
  try {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL +
      CARD_PROCESSOR.GET_BIN_SETTINGS.replace('{programId}', data.programId);
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCardSettings(data: {
  programId: string;
}): Promise<CardSettings> {
  try {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL +
      CARD_PROCESSOR.GET_CARD_SETTINGS.replace('{programId}', data.programId);
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAddOnCardConfiguration(data: {
  programId: string;
}): Promise<AddOnCardConfiguration> {
  try {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL +
      CARD_PROCESSOR.GET_ADD_ON_CARD_CONFIGURATION.replace(
        '{programId}',
        data.programId
      );
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function saveBinSettings(data: {
  binSettings: {
    programId: string;
    binId: number;
    cardAllocated?: number;
  };
}): Promise<BinSettings> {
  try {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL + CARD_PROCESSOR.SAVE_BIN_SETTINGS;
    const response = await axios.post(endpoint, data.binSettings);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function saveCardSettings(
  cardSettings: CardSettings
): Promise<CardSettings> {
  try {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL + CARD_PROCESSOR.SAVE_CARD_SETTINGS;
    const response = await axios.post(endpoint, cardSettings);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function saveAddOnCardConfiguration(
  addOnCardConfiguration: Partial<AddOnCardConfiguration>
): Promise<AddOnCardConfiguration> {
  try {
    if (!addOnCardConfiguration.programId) {
      throw Error('Program Id is required');
    }
    const endpoint =
      CARD_MANAGEMENT_BASE_URL + CARD_PROCESSOR.SAVE_ADD_ON_CARD_CONFIGURATION;
    const response = await axios.post(endpoint, addOnCardConfiguration, {
      params: { cardCategory: CardCategory.ADD_ON_CARD },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function saveSupplementaryCardConfiguration(
  supplmentaryCardConfig: Partial<AddOnCardConfiguration>
): Promise<AddOnCardConfiguration> {
  try {
    if (!supplmentaryCardConfig.programId) {
      throw Error('Program Id is required');
    }
    const endpoint =
      CARD_MANAGEMENT_BASE_URL + CARD_PROCESSOR.SAVE_ADD_ON_CARD_CONFIGURATION;
    const response = await axios.post(endpoint, supplmentaryCardConfig, {
      params: { cardCategory: CardCategory.SUPPLEMENT_CARD },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

type GetCardDesignConfigData = {
  programId: string;
  cardType: CardType;
};
export async function getCardDesignConfiguration(
  data: GetCardDesignConfigData
): Promise<CardConfigProperties> {
  try {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL + CARD_PROCESSOR.GET_CARD_DESIGN_CONFIG;
    const response = await axios.get(endpoint, {
      params: { programId: data.programId, cardType: data.cardType },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function saveCardDesignConfiguration(
  data: Partial<CardConfigProperties>
): Promise<CardConfigProperties> {
  try {
    const endpoint =
      CARD_MANAGEMENT_BASE_URL + CARD_PROCESSOR.SAVE_CARD_DESIGN_CONFIG;
    const response = await axios.post(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export interface PhysicalCardOrderBody {
  accountId: string;
  physicalCardOrderStatusList?: string[];
}

export async function getPhysicalCardOrders(
  data: PhysicalCardOrderBody
): Promise<PhysicalCardOrder[]> {
  try {
    const endpoint = CARD_BASE_URL + CARD_PROCESSOR.LIST_PHYSICAL_CARD_ORDERS;
    const response = await axios.post(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export interface IBulkCardBlockProps {
  id: string;
  clientId: string;
  totalCardsCount: number | null;
  successBlockedCount: number | null;
  alreadyBlockedCount: number | null;
  doesNotExistCount: number | null;
  blockType: string;
  status: string;
  reportFileName: string | null;
  reportFileUrl: string | null;
  embossingFileName: string;
  embossingFileUrl: string;
  createdAt: string;
  updatedAt: string;
}

export async function getBlockCardStatus(
  resquestId: string
): Promise<IBulkCardBlockProps> {
  try {
    const endpoint = `${CARD_MANAGEMENT_BASE_URL}/getOrder/block/${resquestId}`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postBuklCardBlock(
  formData: FormData
): Promise<IBulkCardBlockProps> {
  try {
    const endpoint = `${CARD_MANAGEMENT_BASE_URL}/bulk/card/tempBlock`;
    const response = await axios.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function replaceCard(
  cardId: string,
  reissueReason: string,
  clientId: string
): Promise<any> {
  return axios.post(
    BASE_URL + CARD_PROCESSOR.BASE + CARD_PROCESSOR.REPLACE_CARD,
    {
      cardId,
      replacementReason: reissueReason,
      entityLevel: 'CLIENT',
      entityId: clientId,
    }
  );
}

export interface IBulkCardBodyProps {
  clientId: string;
  page: number;
  size: number;
}

export async function getBulkCardBlockList(
  body: IBulkCardBodyProps
): Promise<AxiosResponse<PaginatedResponse<CardBlockInfo>, any>> {
  const endpoint = CARD_MANAGEMENT_BASE_URL + CARD_PROCESSOR.BULK_CARD_LIST;

  const response = await axios.post(endpoint, body);
  return response;
}

export async function getPresignedUrl(
  url: string
): Promise<AxiosResponse<string>> {
  const endpoint = `${CARD_MANAGEMENT_BASE_URL}/presignUrl`;

  return axios.post(endpoint, {
    url: url,
  });
}

export async function addCard(payload: AddCardPayload) {
  const { data } = await axios.post(
    CARD_BASE_URL + CARD_PROCESSOR.ADD_CARD,
    payload
  );

  return data;
}
