import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { ComponentBody1Typo, ListGroupHeadingTypo } from '../Typography';
import { QUICK_ACCESS } from '../../../constants/style';

type VegaDataTileV4Props = {
  title: string;
  value?: string;
  children?: React.ReactNode;
  hasSeparator?: boolean;
  sx?: SxProps<Theme>;
};

const Separator = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mx: 3,
      }}
    >
      <Box
        sx={{ width: '1px', height: 12, bgcolor: QUICK_ACCESS.border[50] }}
      />
    </Box>
  );
};

function VegaDataTileV4({
  title,
  value,
  children,
  hasSeparator,
  sx,
}: VegaDataTileV4Props) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          whiteSpace: 'nowrap',
          gap: 1,
          ...sx,
        }}
      >
        <ListGroupHeadingTypo
          sx={{
            color: QUICK_ACCESS.text[100],
          }}
        >
          {title}
        </ListGroupHeadingTypo>
        {value ? (
          <ComponentBody1Typo
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: 'inherit',
              color: QUICK_ACCESS.text[200],
            }}
          >
            {value}
          </ComponentBody1Typo>
        ) : null}
        {!value && children ? children : null}
      </Box>
      {hasSeparator ? <Separator /> : null}
    </>
  );
}

export default VegaDataTileV4;
