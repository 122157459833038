import axios from 'axios';
import { AuditLog } from '../types/audit';
import { AUDIT, BASE_URL } from './endpoints';
import { IAuditLogProps } from '../components/customers/ListOfCustomers';

const AUDIT_BASE_URL = BASE_URL + AUDIT.BASE;

interface GetAuditLogsParams {
  accountId?: string;
  customerId?: string;
  programId?: string;
  eventName?: string;
}
export async function fetchAuditLogs(
  params: GetAuditLogsParams
): Promise<AuditLog[]> {
  try {
    const endpoint = AUDIT_BASE_URL;
    const response = await axios.get(endpoint, { params: params });
    return response.data as AuditLog[];
  } catch (error) {
    throw error;
  }
}
export async function fetchAuditLogsForAccountId(
  accountId: string
): Promise<AuditLog[]> {
  try {
    const endpoint = AUDIT_BASE_URL + AUDIT.DATES;
    const response = await axios.post(endpoint, { accountId });
    return response.data as AuditLog[];
  } catch (error) {
    throw error;
  }
}

export const fetchAuditrByCustomerId = async (
  customerId: string
): Promise<IAuditLogProps[]> => {
  const { data } = await axios.get(
    `${AUDIT_BASE_URL}?customerId=${customerId}`
  );
  return data;
};
