import { IConsolidatedAccountData, ICorporateConsolidatedDto } from './Commons';

export enum CARD_NETWORK {
  RUPAY = 'RUPAY',
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  MAESTRO = 'MAESTRO',
  UNION_PAY = 'UNION_PAY',
}

export interface ICard {
  id: string;
  accountId: string;
  binId: number;
  vendorId: string;
  vendorCardNumber: string;
  cardNumber?: string;
  cardType: string;
  cardNetwork: string;
  cardStatus: string;
  lastFourDigits: string;
  expiryDate: string;
  pinStatus: string;
  replacedCardId: string | null;
  nameOnCard: string | null;
  countryCode: string;
  mobileNumber: string;
  createdAt: string;
  updatedAt: string;
  primary: boolean;
  network: string | null;
}

export interface IAccountProps {
  id: string;
  customerId: string;
  branchId: string;
  programId: string;
  primaryCardId: string | null;
  walletId: string;
  accountType?: string;
  status: string;
  corporateCustomerId: null | string;
  accountLimit: 0.0;
  billingCycle?: number;
  programName: null | string;
  branchName: null | string;
  checkerMakerId: null | string;
  checkerMakerStatus: null | string;
}
export interface IdentityProps {
  id: number | string;
  customerId: string;
  clientId: string;
  idType: string;
  idNumber: string;
  idVerified: string | boolean;
  url: null | string;
}
export interface ICustomerAddressProps {
  id: string | number;
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}

export interface IPermanentAddressProps {
  id: string | number;
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}

export interface ICustomerNewProps {
  id?: string;
  customerId: string;
  clientId: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  customerType: string;
  countryCode: string;
  mobileNumber: string;
  emailId: string;
  identity: IdentityProps[];
  dob: string;
  gender: string;
  currentAddress: ICustomerAddressProps;
  isCurrentAddressPermanent: string;
  permanentAddress: IPermanentAddressProps;
  nationality: string;
  status: string;
  kycStatus: string;
  creationVector: null | string;
  selfieUrl: null | string;
  createdAt: string;
  updatedAt: string;
  communicationPinCode: null | string;
  riskScore: null | string;
  bureauScore: null | string;
  branchId: string;
  corporateId: string;
  docsUrl: {};
  fullName: string;
}

export interface ICustomerPorps {
  customerId: string;
  countryCode: string;
  clientId: string;
  branchId: string;
  corporateId: null | string;
  status: string;
  customerType: string;
  checkerMakerId: null | string;
  checkerMakerStatus: null | string;
  address: null | string;
  pinCode: null | string;
  state: null | string;
  city: null | string;
  createdAt: string;
  updatedAt: string;
  mobileNumber: string;
  emailId: string;
  panNo: null | string;
  aadharNo: null | string;
  passportNo: string;
  firstName: string;
  middleName: null | string;
  lastName: null | string;
  dob: string;
  panUrl: null | string;
  passportUrl: null | string;
  kycUrl: null | string;
  ticketUrl: null | string;
  visaUrl: null | string;
  selected?: boolean;
}

export interface ICardDataProps {
  id: string;
  accountId: string;
  binId: 1000000008;
  binEntityType: null | string;
  entityId: null | string;
  vendorId: string;
  vendorCardNumber: string;
  cardType: string;
  cardNetwork: string;
  cardStatus: string;
  lastFourDigits: string;
  cardNumber: string;
  expiryDate: string;
  pinStatus: string;
  replacedCardId: null | string;
  reasonForReplacement: null | string;
  nameOnCard: null | string;
  countryCode: string;
  mobileNumber: string;
  createdAt: string;
  updatedAt: string;
  primary: true;
}

export interface ISubwalletDataProps {
  id?: string;
  walletId?: string;
  ledgerId?: string;
  type?: string;
  currency: string;
  status?: string;
  totalBalance: number;
  holdBalance: number;
  availableBalance: number;
  createdAt?: string | null;
  updatedAt?: string;
}

export interface ICardFundLoadProps {
  id?: string;
  accountId: string;
  sourceCurrency: string;
  destinationCurrency: string;
  amount: number;
  markupValueType: string;
  markupValue: number;
  transactionTime: string;
  makerCheckerId: string;
  userId: string;
  fundLoadOrderStatus: string;
  makerCheckerStatus: string;
  branchId: string;
  corporateId: null | string;
  accountConsolidatedDto: null | IConsolidatedAccountData;
  corporateTransactionId?: null | string;
  corporateConsolidatedDto: null | ICorporateConsolidatedDto;
  customerName: string;
  customerType: string;
  cardNetwork: string;

  createdAt: string;
  updatedAt: string;
  selected?: boolean;
  [key: string]: any;
}
