/** @format */

import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getDefaultFRMPolicy } from '../../api/frm';
import { addOrUpdatePolicy, fetchPolicy } from '../../api/policy';
import { useClientAuth } from '../../providers/ClientProvider';
import { useProgramData } from '../../providers/ProgramDataProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import {
  AcceptanceOptions,
  CriteriaType,
  FrmCriteriaCondition,
  getOperandType,
  getSelectedOperator,
  getUpdatedConfig,
} from '../../types/frm';
import {
  FRMCriteria,
  IdentityFRMPolicy,
  PolicyStatus,
  PolicyType,
} from '../../types/policy';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { VegaCard, VegaTable } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaText from '../common/VegaText';
import BooleanSelect from './BooleanSelect';
import { CriteriaConfigCell } from './CriteriaConfigCell';

function IdentityFrm() {
  const [frmPolicy, setFrmPolicy] = useState<IdentityFRMPolicy | null>(null);
  const [checks, setChecks] = useState<FrmCriteriaCondition[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedProgram } = useProgramData();
  const { setSnackbar } = useSnackbar();
  const { clientId } = useClientAuth();

  let columnDef = [
    {
      field: 'check',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Check
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const criteria = props.row as FrmCriteriaCondition;
        return _.startCase(_.toLower(criteria.check));
      },
    },
    {
      field: 'autoAcceptCriteria',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Auto Accept
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const criteria = props.row as FrmCriteriaCondition;
        const acceptanceCriteria = criteria.criteria.acceptance_criteria;
        return (
          <CriteriaConfigCell
            config={acceptanceCriteria}
            operatorOptions={getAutoAcceptOperators(criteria)}
            showSuffix={canShowPercentageSuffix(criteria)}
            onValueChange={(updatedValue: string) => {
              onCriteriaValueChange(
                updatedValue,
                criteria,
                CriteriaType.APPROVE
              );
            }}
            selectedOperator={getSelectedOperator(acceptanceCriteria)}
            onSelectValueChange={(updatedValue: string) => {
              onCriteriaOperatorChange(
                updatedValue,
                criteria,
                CriteriaType.APPROVE
              );
            }}
          />
        );
      },
    },
    {
      field: 'autoRejectCriteria',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Auto Reject
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const criteria = props.row as FrmCriteriaCondition;
        const rejectionCriteria = criteria.criteria.reject_criteria;
        return (
          <CriteriaConfigCell
            config={rejectionCriteria}
            operatorOptions={getAutoRejectOperators(criteria)}
            showSuffix={canShowPercentageSuffix(criteria)}
            selectedOperator={getSelectedOperator(rejectionCriteria)}
            onValueChange={(updatedValue: string) => {
              onCriteriaValueChange(
                updatedValue,
                criteria,
                CriteriaType.REJECT
              );
            }}
            onSelectValueChange={(updatedValue: string) => {
              onCriteriaOperatorChange(
                updatedValue,
                criteria,
                CriteriaType.REJECT
              );
            }}
          />
        );
      },
    },
    {
      field: 'failSafe',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Fail Safe
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const check = props.row as FrmCriteriaCondition;

        return (
          <BooleanSelect
            selectedValue={check.criteria.failsafe}
            onChange={function (value: number): void {
              onFailSafeValueChange(value == 0 ? false : true, check);
            }}
          />
        );
      },
    },
  ];

  function mapIdentityFrmCriteriaToArray(policy: IdentityFRMPolicy) {
    const frmCriteriaConditions: FrmCriteriaCondition[] = [];
    Object.entries(policy.identity_frm_criteria).forEach(
      ([check, criteria]) => {
        if (criteria) {
          frmCriteriaConditions.push({ check, criteria });
        }
      }
    );
    setChecks(frmCriteriaConditions);
  }

  function fetchDefaultFrmPolicy() {
    setLoading(true);
    getDefaultFRMPolicy()
      .then((res) => {
        const existingPolicy = res;
        setFrmPolicy(existingPolicy);
        mapIdentityFrmCriteriaToArray(existingPolicy);
      })
      .catch((err) => {
        console.error(err);
        setSnackbar('Failed to get default Policy', 'error');
      })
      .finally(() => setLoading(false));
  }

  function fetchFrmPolicy() {
    setFrmPolicy(null);
    const programId = selectedProgram?.programId;

    if (!programId) {
      return;
    }

    setLoading(true);
    fetchPolicy(programId, PolicyType.FRM_POLICY)
      .then((response) => {
        const existingPolicy = response.data;
        setFrmPolicy(existingPolicy as IdentityFRMPolicy);
        mapIdentityFrmCriteriaToArray(existingPolicy as IdentityFRMPolicy);
      })
      .catch((error) => {
        fetchDefaultFrmPolicy();
      })
      .finally(() => setLoading(false));
  }

  function onCriteriaOperatorChange(
    updatedValue: string,
    condition: FrmCriteriaCondition,
    criteriaType: CriteriaType
  ) {
    const criteria =
      criteriaType == CriteriaType.APPROVE
        ? condition.criteria.acceptance_criteria
        : condition.criteria.reject_criteria;

    const criterValue = criteria?.max || criteria?.min;

    const updatedChecks = checks.map((item) => {
      if (item.check == condition.check) {
        const updatedConfig = getUpdatedConfig(updatedValue, criterValue);
        if (criteriaType == CriteriaType.APPROVE) {
          return {
            ...item,
            criteria: {
              ...item.criteria,
              acceptance_criteria: updatedConfig,
            },
          };
        } else {
          return {
            ...item,
            criteria: {
              ...item.criteria,
              reject_criteria: updatedConfig,
            },
          };
        }
      }
      return item;
    });
    setChecks(updatedChecks);
  }

  function onFailSafeValueChange(
    updatedValue: boolean,
    condition: FrmCriteriaCondition
  ) {
    const updatedChecks = checks.map((item) => {
      if (item.check === condition.check) {
        const { criteria } = item;
        return {
          ...item,
          criteria: {
            ...criteria,
            failsafe: updatedValue,
          },
        };
      }
      return item;
    });
    setChecks(updatedChecks);
  }

  function onCriteriaValueChange(
    updatedValue: string,
    condition: FrmCriteriaCondition,
    criteriaType: CriteriaType
  ) {
    const numberValue = Number(updatedValue);

    const acceptanceOption = getSelectedOperator(
      criteriaType == CriteriaType.APPROVE
        ? condition.criteria.acceptance_criteria
        : condition.criteria.reject_criteria
    );
    const operandType = getOperandType(acceptanceOption);
    if (!operandType) return;
    const updatedChecks = checks.map((item) => {
      if (item.check === condition.check) {
        const { criteria } = item;
        if (criteriaType === CriteriaType.APPROVE) {
          return {
            ...item,
            criteria: {
              ...criteria,
              acceptance_criteria: {
                ...criteria.acceptance_criteria,
                [operandType]: numberValue,
              },
            },
          };
        } else {
          return {
            ...item,
            criteria: {
              ...criteria,
              reject_criteria: {
                ...criteria.reject_criteria,
                [operandType]: numberValue,
              },
            },
          };
        }
      }

      return item;
    });

    setChecks(updatedChecks);
  }

  function onSaveClick() {
    const updatedPolicy = frmPolicy;

    if (!updatedPolicy) {
      return;
    }

    const identityFrmCriteria: { [key: string]: FRMCriteria } = checks.reduce(
      (obj: { [key: string]: FRMCriteria }, condition) => {
        obj[condition.check] = condition.criteria;
        return obj;
      },
      {}
    );

    updatedPolicy.identity_frm_criteria = identityFrmCriteria;

    addPolicy(updatedPolicy);
  }

  function addPolicy(policy: IdentityFRMPolicy) {
    const programId = selectedProgram?.programId;
    if (!programId) {
      return;
    }
    setLoading(true);
    policy.client_id = clientId;
    addOrUpdatePolicy(programId, policy, PolicyStatus.ENABLED)
      .then((res) => {
        const updatedPolicy = res.data.policy;
        setFrmPolicy(updatedPolicy);
        setSnackbar('Policy added!');
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      })
      .finally(() => setLoading(false));
  }

  const getPolicyUpdatedAtText = () => {
    if (!frmPolicy) {
      return 'Policy not set';
    }

    if (!frmPolicy.policy_id) {
      return 'Policy not set';
    }

    const updatedAt = frmPolicy.updated_at;

    return `Last updated: ${dayjs(updatedAt).format('MMM D, YYYY')}`;
  };

  useEffect(() => {
    fetchFrmPolicy();
  }, [selectedProgram]);

  useEffect(() => {
    console.log('Checks change', checks);
  }, [checks]);

  return (
    <VegaCard fullHeight noPad>
      <div style={{ marginBottom: 20, marginTop: 20, marginLeft: '40px' }}>
        <VegaText
          fw={500}
          text={'Conditions/score for action on applications received'}
        />
      </div>
      <VegaTable
        noTopRadius
        columnDef={columnDef}
        idColumn={'check'}
        data={[...checks]}
        noFooter
        loading={loading}
      />
      <Stack
        direction={'row'}
        alignItems="flex-end"
        justifyContent={'space-between'}
        style={{
          margin: 14,
        }}
      >
        <Typography variant="caption">
          Rest cases will automatically send to Manual approval by ops agent
        </Typography>
        <VegaContainedButton
          text={'Save'}
          isPrimary
          onClick={onSaveClick}
          loading={loading}
          disabled={frmPolicy == null}
        />
      </Stack>
      <Stack
        direction={'row'}
        alignItems="flex-end"
        justifyContent={'end'}
        style={{
          margin: 14,
        }}
      >
        <Typography variant="caption">{getPolicyUpdatedAtText()}</Typography>
      </Stack>
    </VegaCard>
  );
}

export default IdentityFrm;

const canShowPercentageSuffix = (condition: FrmCriteriaCondition) => {
  const check = condition.check;
  return check != 'IP_UNIQUENESS' && check != 'DEVICE_UNIQUENESS';
};

const getAutoAcceptOperators = (condition: FrmCriteriaCondition) => {
  const check = condition.check;
  if (check == 'IP_UNIQUENESS' || check == 'DEVICE_UNIQUENESS') {
    return [AcceptanceOptions.LESS_THAN, AcceptanceOptions.LESS_THAN_EQUAL_TO];
  }
  return [
    AcceptanceOptions.GREATER_THAN,
    AcceptanceOptions.GREATER_THAN_EQUAL_TO,
  ];
};

const getAutoRejectOperators = (condition: FrmCriteriaCondition) => {
  const check = condition.check;
  if (check == 'IP_UNIQUENESS' || check == 'DEVICE_UNIQUENESS') {
    return [
      AcceptanceOptions.GREATER_THAN,
      AcceptanceOptions.GREATER_THAN_EQUAL_TO,
    ];
  }
  return [AcceptanceOptions.LESS_THAN, AcceptanceOptions.LESS_THAN_EQUAL_TO];
};
