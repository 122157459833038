import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { GENERIC_BORDER, QUICK_ACCESS } from '../../../constants/style';
import {
  ComponentBody1Typo,
  ListGroupHeadingTypo,
  SectionHeading2Typo,
} from '../../common/Typography';
const Separator = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mx: 3,
      }}
    >
      <Box
        sx={{ width: '1px', height: 12, bgcolor: QUICK_ACCESS.border[50] }}
      />
    </Box>
  );
};

const ActivityComponent = ({
  date,
  change,
  program,
  data,
}: {
  date: string;
  change: string;
  program: string;
  data?: Record<string, string | number>;
}) => {
  return (
    <Stack
      p={2}
      direction="row"
      border={GENERIC_BORDER}
      borderRadius={2}
      alignItems="center"
      bgcolor={QUICK_ACCESS.background[75]}
    >
      <Typography
        className="font-aspekta-600"
        sx={{
          fontSize: '13px',
          fontWeight: 600,
          lineHeight: '140%',
          color: QUICK_ACCESS.text[200],
        }}
      >
        {date}
      </Typography>
      <Separator />
      <ComponentBody1Typo
        sx={{
          color: QUICK_ACCESS.text[200],
        }}
      >
        {change}
      </ComponentBody1Typo>
      <Separator />
      <ComponentBody1Typo
        sx={{
          color: QUICK_ACCESS.text[200],
        }}
      >
        {program}
      </ComponentBody1Typo>
      {data &&
        Object.entries(data).map((entry) => (
          <Stack direction="row" alignItems="center">
            <Separator />
            <ListGroupHeadingTypo color={QUICK_ACCESS.text[100]}>
              {entry[0]}:
            </ListGroupHeadingTypo>
            &nbsp;
            <ComponentBody1Typo>{` ${entry[1] ?? '-'}`}</ComponentBody1Typo>
          </Stack>
        ))}
    </Stack>
  );
};

const ViewPastActivities = () => {
  return (
    <>
      <Accordion
        sx={{
          '&.MuiAccordion-root': {
            borderRadius: '10px !important',
            boxShadow: 'none !important',
            border: `1px solid ${QUICK_ACCESS.border[50]} !important`,
            ':before': {
              height: 0,
            },
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box>
            <SectionHeading2Typo
              sx={{
                color: QUICK_ACCESS.text[200],
              }}
            >
              View Past Activities
            </SectionHeading2Typo>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '&.MuiAccordionDetails-root': {
              borderTop: GENERIC_BORDER,
              height: '100%',
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            },
          }}
        >
          <ActivityComponent
            date="20 October, 2023"
            change="New Program Added"
            program="Vega Demo Program"
          />
          <ActivityComponent
            date="20 October, 2023"
            change="Card Range Increased"
            program="One Card Infinite Program"
            data={{
              'Updated Limit': 640000,
              'Earlier Limit': 640000,
            }}
          />
          <ActivityComponent
            date="20 October, 2023"
            change="Card Range Increased"
            program="Gomechanic Prepaid Program"
            data={{
              'Updated Limit': 640000,
              'Earlier Limit': 640000,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ViewPastActivities;
