import React, { ChangeEvent, useRef } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import {
  VEGA_COMMON,
  INDICATIVE,
  PRIMARY_COLOR,
  QUICK_ACCESS,
} from '../../../constants/style';
import { AcceptFileType } from '../../../types/acceptFileType';
import {
  FileValidator,
  FileValidatorResult,
} from '../../../utils/FileValidator';
import FileUploadIcon from '../../icon/FileUploadIcon';
import TrashIcon from '../../icon/TrashIcon';
import {
  ComponentBody2Typo,
  LabelTypo,
  ListHead2Typo,
  TextProps,
} from '../Typography';
import Box from '@mui/material/Box';
import ArrowRefreshIcon from '../../icon/ArrowRefreshIcon';

type VegaUploadFileInputV4Props = {
  acceptFileType?: AcceptFileType;
  buttonText?: string;
  currentFile?: File | null;
  onFileSelected: (files: File | null) => void;
  onFileSelectedError?: (error: string | null) => void;
  fileValidator?: FileValidator;
  error?: boolean;
  labelText?: string;
  header?: string;
  required?: boolean;
  wrapperProps?: StackProps;
  headerTypo?: (props: TextProps) => JSX.Element;
};

function VegaUploadFileInputV4({
  buttonText = 'Upload Document',
  currentFile,
  onFileSelected,
  onFileSelectedError,
  fileValidator,
  error,
  acceptFileType,
  labelText,
  required,
  header,
  wrapperProps,
  headerTypo,
}: VegaUploadFileInputV4Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const HEADER_TYPO = headerTypo || ListHead2Typo;

  const handleFileSelect = async (file: File) => {
    if (file) {
      if (!fileValidator) {
        onFileSelected(file);
        return;
      }

      const validationResult: FileValidatorResult =
        await fileValidator.validateFile(file);

      if (validationResult.isValid) {
        onFileSelected(file);
      } else {
        onFileSelectedError?.(validationResult.errorMessage);
      }
    } else {
      onFileSelected(null);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = getFile(event);
    event.target.value = ''; // this is required because if i delete a file and then reupload the same file after, it doesnt get uploaded again because it is cached, and the event is triigered only on change

    if (file) {
      handleFileSelect(file);
    } else {
      onFileSelected(null);
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  const onRemoveClick = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onFileSelected(null);
  };

  return (
    <Stack
      gap={1}
      flex={1}
      {...wrapperProps}
      sx={{ maxWidth: '328px', ...wrapperProps?.sx }}
    >
      {header ? (
        <Box
          sx={{
            display: 'inline-flex',
            gap: 0.5,
          }}
        >
          <HEADER_TYPO
            sx={{
              color: QUICK_ACCESS.text[100],
              display: 'inline-block',
            }}
          >
            {header}
          </HEADER_TYPO>{' '}
          {required ? (
            <HEADER_TYPO
              sx={{
                color: INDICATIVE.red[500],
                display: 'inline-block',
              }}
            >
              *
            </HEADER_TYPO>
          ) : null}
        </Box>
      ) : null}
      <Stack
        direction="row"
        gap={1}
        px={1.5}
        py={1}
        sx={{
          backgroundColor: currentFile
            ? PRIMARY_COLOR.blue[50]
            : VEGA_COMMON.background[50],
          border: `1px dashed ${
            error ? INDICATIVE.red[500] : VEGA_COMMON.primaryBlue
          }`,
          borderRadius: 1,
          cursor: 'pointer',
          display: 'inline-flex',
          justifyContent: 'space-between',
        }}
        onClick={handleClick}
      >
        <ComponentBody2Typo
          sx={{
            maxWidth: 240,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: error ? INDICATIVE.red[500] : VEGA_COMMON.primaryBlue,
          }}
        >
          {currentFile ? currentFile.name : buttonText}
        </ComponentBody2Typo>
        <input
          type="file"
          hidden
          ref={inputRef}
          onChange={handleFileChange}
          accept={acceptFileType}
        />
        {currentFile ? (
          error ? (
            <ArrowRefreshIcon onClick={onRemoveClick} />
          ) : (
            <TrashIcon onClick={onRemoveClick} />
          )
        ) : (
          <FileUploadIcon
            stroke={error ? INDICATIVE.red[500] : VEGA_COMMON.primaryBlue}
          />
        )}
      </Stack>
      {labelText && (
        <LabelTypo
          sx={{
            color: error ? INDICATIVE.red[500] : VEGA_COMMON.text100,
          }}
        >
          {labelText}
        </LabelTypo>
      )}
    </Stack>
  );
}

function getFile(event: ChangeEvent<HTMLInputElement>) {
  const file =
    event.target.files &&
    event.target.files.length > 0 &&
    event.target.files[0];

  return file;
}
export default VegaUploadFileInputV4;
