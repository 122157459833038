import { Stack } from '@mui/material';
import VegaContainerV4 from '../common/v4/VegaContainerV4';
import { Controller, UseFormReturn } from 'react-hook-form';
import VegaTextfieldV4 from '../common/v4/VegaTextfieldV4';
import VegaSelectV4 from '../common/v4/VegaSelectV4';
import VegaUploadFileInputV4 from '../common/v4/VegaUploadFileInputV4';
import { IPartnerManagementAddBrandFormValues } from './PartnerManagementAddBrandForm';
import { VALIDATE_REGEX } from '../../utils/validation.utils';
import { ListHead2Typo } from '../common/Typography';
import { CompanyTypeEnum, PartnerTypeEnum } from '../../enums/commons';
import { convertCamelCase } from '../../utils/stringUtils';
import { AcceptFileType } from '../../types/acceptFileType';
import { fetchStateOnPincode } from '../../api/resources';

interface IPartnerEntityDetailsFormProps {
  partnerFormHook: UseFormReturn<
    IPartnerManagementAddBrandFormValues,
    any,
    undefined
  >;
}

const PartnerEntityDetailsForm = ({
  partnerFormHook,
}: IPartnerEntityDetailsFormProps) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = partnerFormHook;

  const addFile = (val: any) => (file: File | null) => {
    setValue(val, file);
  };

  const onPincodeChange = (pincode: string) => {
    return new Promise((res) => {
      if (pincode?.length === 6) {
        fetchStateOnPincode(pincode)
          .then((result) => {
            if (result.data?.records?.length) {
              const stateList = result.data?.records[0]?.state;
              const cityList = result.data?.records[0]?.city;
              const counteryList = result.data?.records[0]?.country;
              setValue('entityDetails.city', cityList);
              setValue('entityDetails.state', stateList);
              setValue(
                'entityDetails.country',
                counteryList.toLocaleUpperCase()
              );
              res(true);
            } else {
              res(false);
            }
          })
          .catch((err) => {
            res(false);
          });
      } else {
        res(true);
      }
    });
  };

  return (
    <VegaContainerV4 title="Entity Details">
      <Stack gap={2} maxWidth="1016px">
        <Stack
          direction="row"
          gap={2}
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          <Controller
            name="entityDetails.name"
            control={control}
            rules={{
              required: 'Required field',
              pattern: {
                value: VALIDATE_REGEX.COMPANY_NAME,
                message: 'Not a valid company name',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.entityDetails?.name)}
                header="Company's Name"
                labelText={errors.entityDetails?.name?.message}
                required
                placeholder="Enter Company Name"
              />
            )}
          />
          <Controller
            name="entityDetails.panNo"
            control={control}
            rules={{
              required: 'Required field',
              pattern: {
                value: VALIDATE_REGEX.PAN,
                message: 'Not a valid Pan Number',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.entityDetails?.panNo)}
                header="PAN Number"
                labelText={errors.entityDetails?.panNo?.message}
                required
                placeholder="Enter PAN Number"
              />
            )}
          />
          <Controller
            name="entityDetails.gstNo"
            control={control}
            rules={{
              required: 'Required field',
              pattern: {
                value: VALIDATE_REGEX.GST_NO,
                message: 'Not a valid GST Number',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.entityDetails?.gstNo)}
                header="GST Number"
                labelText={errors.entityDetails?.gstNo?.message}
                required
                placeholder="Enter GST Number"
              />
            )}
          />
          <Controller
            name="entityDetails.cinNo"
            control={control}
            rules={{
              required: 'Required field',
              pattern: {
                value: VALIDATE_REGEX.CIN_NO,
                message: 'Not a valid CIN Number',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.entityDetails?.cinNo)}
                header="CIN Number"
                labelText={errors.entityDetails?.cinNo?.message}
                required
                placeholder="Enter CIN Number"
              />
            )}
          />
          <Controller
            name="entityDetails.companyType"
            control={control}
            rules={{
              required: 'Required field',
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaSelectV4
                {...rest}
                error={Boolean(errors.entityDetails?.companyType)}
                header="Company Type"
                labelText={errors.entityDetails?.companyType?.message}
                value={rest.value || ''}
                options={Object.values(CompanyTypeEnum).map((el) => ({
                  name: convertCamelCase(el),
                  id: el,
                }))}
                required
                placeholder="Select Company Type"
              />
            )}
          />
          <Controller
            name="entityDetails.partnerType"
            control={control}
            rules={{
              required: 'Required field',
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaSelectV4
                {...rest}
                error={Boolean(errors.entityDetails?.partnerType)}
                header="Partner Type"
                value={rest.value || ''}
                labelText={errors.entityDetails?.partnerType?.message}
                options={Object.values(PartnerTypeEnum).map((el) => ({
                  name: convertCamelCase(el),
                  id: el,
                }))}
                wrapperProps={{
                  sx: {
                    flex: 0,
                  },
                }}
                required
                placeholder="Select Partner Type"
              />
            )}
          />
        </Stack>
        <Stack
          direction="row"
          gap={2}
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          <Controller
            name="entityDetails.line1"
            control={control}
            rules={{
              required: 'Required field',
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.entityDetails?.line1)}
                header="Address 1"
                labelText={errors.entityDetails?.line1?.message}
                required
                placeholder="Enter Address 1"
              />
            )}
          />
          <Controller
            name="entityDetails.line2"
            control={control}
            rules={{
              required: 'Required field',
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.entityDetails?.line2)}
                header="Address 2"
                labelText={errors.entityDetails?.line2?.message}
                required
                placeholder="Enter Address 2"
              />
            )}
          />
          <Controller
            name="entityDetails.pincode"
            control={control}
            rules={{
              required: 'Required field',
              maxLength: {
                value: 6,
                message: 'Pincode should be 6 digits',
              },
              minLength: {
                value: 6,
                message: 'Pincode should be 6 digits',
              },
              validate: {
                require: async (e) => {
                  const res = await (onPincodeChange(e) as Promise<boolean>);
                  return res || 'Please provide valid Pincode';
                },
              },
            }}
            render={({ field: { ref, onChange, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.entityDetails?.pincode)}
                header="Pincode"
                labelText={errors.entityDetails?.pincode?.message}
                onChange={(e) => {
                  if (e.target.value.length !== 6) {
                    setValue('entityDetails.city', '');
                    setValue('entityDetails.state', '');
                    setValue('entityDetails.country', '');
                  }
                  onChange(e);
                }}
                required
                placeholder="Enter Pincode"
              />
            )}
          />
          <Controller
            name="entityDetails.city"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.entityDetails?.city)}
                header="City (Autofilled)"
                disabled
              />
            )}
          />
          <Controller
            name="entityDetails.state"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.entityDetails?.state)}
                header="State (Autofilled)"
                disabled
              />
            )}
          />
          <Controller
            name="entityDetails.country"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.entityDetails?.country)}
                header="Country (Autofilled)"
                disabled
              />
            )}
          />
        </Stack>
        <Stack direction="row" gap={2} justifyContent="flex-start">
          <Controller
            name="entityDetails.logo"
            control={control}
            render={({ field: { value, ref, ...rest } }) => (
              <VegaUploadFileInputV4
                {...rest}
                error={Boolean(errors.entityDetails?.logo)}
                header="Logo (Optional)"
                labelText={
                  errors.entityDetails?.logo?.message ||
                  'You can upload files in png/jpeg/svg format'
                }
                buttonText="Upload Logo"
                acceptFileType={AcceptFileType.Images}
                currentFile={value as File}
                onFileSelected={addFile('entityDetails.logo')}
              />
            )}
          />
          <Controller
            name="entityDetails.agreement"
            control={control}
            render={({ field: { value, ref, onChange, ...rest } }) => (
              <VegaUploadFileInputV4
                {...rest}
                error={Boolean(errors.entityDetails?.agreement)}
                header="Agreement (Optional)"
                buttonText="Upload Agreement"
                acceptFileType={AcceptFileType.PDF}
                labelText={
                  errors.entityDetails?.agreement?.message ||
                  'You can upload file in pdf format'
                }
                currentFile={value}
                onFileSelected={addFile('entityDetails.agreement')}
              />
            )}
          />
        </Stack>
      </Stack>
    </VegaContainerV4>
  );
};

export default PartnerEntityDetailsForm;
