/* eslint-disable react/jsx-pascal-case */
import { Box, useMediaQuery, useTheme, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import Vega_Text from '../../components/common/v3/Vega_Text';
import MainHeader from '../../components/header/MainHeader';
import BackOfficeRouteCard from './BackOfficeRouteCard';
import { SidebarListItemType } from '../../components/layouts/SidebarLayout';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { BACK_OFFICE_ROUTES } from '../../routes';
import FolderIcon from '../../components/icon/FolderIcon';
import ProfileIcon from '../../components/icon/ProfileIcon';
import FileType2Icon from '../../components/icon/FileType2Icon';
import SettingsIcon from '../../components/icon/SettingsIcon';
import GraphIcon from '../../components/icon/GraphIcon';
import SupportIcon from '../../components/icon/SupportIcon';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { setShowLendingPage } from '../../store/common/stateSlice';
import { useClientAuth } from '../../providers/ClientProvider';

const SELECTED_VIEW = 'view';

interface IProps {
  setSelectedItem: React.Dispatch<
    React.SetStateAction<SidebarListItemType | undefined>
  >;
}

const BackOfficeLendingPage = ({ setSelectedItem }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const route = searchParams.get('view');
  const { loggedInUserDetails, canAccessSubModule } = useClientAuth();
  const clientEmail = loggedInUserDetails?.email;
  const { restrictedUsers } = useAppSelector(({ header }) => ({
    restrictedUsers: header.restrictedUsers,
  }));

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleItemClick = (item: SidebarListItemType) => {
    setSelectedItem(item);
    setSearchParams(createSearchParams({ [SELECTED_VIEW]: item.id }));
  };

  useEffect(() => {
    const idx = NAV_OPTIONS.findIndex(
      (el) => el.id === route && canAccessSubModule(el.id.replace('/', ''))
    );
    if (idx !== -1) {
      setSelectedItem(NAV_OPTIONS[idx]);
      dispatch(setShowLendingPage(false));
    }
  }, [searchParams, route]);

  const isRestrictedUser =
    clientEmail && clientEmail.trim() !== ''
      ? restrictedUsers.includes(clientEmail)
      : true;

  return (
    <>
      <MainHeader
        setOpen={setOpen}
        isSmallScreen={isSmallScreen}
        isMobile={isMobile}
      />
      <Box height={'100%'} pt={'69px'}>
        <Box
          sx={{
            maxWidth: '1290px',
            mx: 'auto',
            px: 5,
            py: 4,
          }}
        >
          <Vega_Text
            text={'Welcome back, Rahul!'}
            color={'#1B1D22'}
            fontWeight={600}
            fontSize={24}
            sx={{
              pb: 2,
              borderBottom: '1px solid #C5C8D0',
            }}
          />
          <Box mt={5}>
            <Vega_Text
              text={'Access the Vegapay’s Backoffice!'}
              color={'#1B1D22'}
              fontWeight={600}
              fontSize={17}
            />
            <Box mt={2}>
              <Grid container spacing={2}>
                {NAV_OPTIONS.filter((el) =>
                  canAccessSubModule(el.id.replace('/', ''))
                ).map((route: SidebarListItemType) =>
                  !(isRestrictedUser && route.isRestrictedForUser) ? (
                    <Grid key={route.id} item xs={12} sm={6} md={4}>
                      <BackOfficeRouteCard
                        route={route}
                        onSelect={handleItemClick}
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const NAV_OPTIONS: SidebarListItemType[] = [
  {
    id: BACK_OFFICE_ROUTES.COLLECTIONS,
    icon: <FolderIcon stroke="#061F62" width={28} height={28} />,
    label: 'Collections',
    isRestrictedForUser: true,
  },
  {
    id: BACK_OFFICE_ROUTES.AGENT_VIEW,
    icon: <ProfileIcon stroke="#061F62" width={28} height={28} />,
    label: 'Agent View',
    isRestrictedForUser: true,
  },
  {
    id: BACK_OFFICE_ROUTES.REPORTS,
    icon: <FileType2Icon stroke="#061F62" width={28} height={28} />,
    label: 'Reports',
  },
  {
    id: BACK_OFFICE_ROUTES.OPERATIONS,
    icon: <SettingsIcon stroke="#061F62" width={28} height={28} />,
    label: 'Operations',
  },
  {
    id: BACK_OFFICE_ROUTES.ANALYTICS,
    icon: <GraphIcon stroke="#061F62" width={28} height={28} />,
    label: 'Analytics',
  },
  {
    id: BACK_OFFICE_ROUTES.SUPPORT,
    icon: <SupportIcon stroke="#061F62" width={28} height={28} />,
    label: 'Support Center',
  },
];

export default BackOfficeLendingPage;
