import { Typography } from '@mui/material';
import { VegaAgent } from '../../../types/collection';
import { VegaTable } from '../../common';

type Props = {
  data: VegaAgent[];
  loading: boolean;
};

const ListOfAgents = ({ data, loading }: Props) => {
  const columnDef = [
    {
      field: 'agentName',
      headerName: <Typography variant="body2">Name</Typography>,
      flex: 1,
    },
    {
      field: 'agency',
      headerName: <Typography variant="body2">Agency</Typography>,
      flex: 1,
    },
    {
      field: 'accounts',
      headerName: <Typography variant="body2">Total Count</Typography>,
      flex: 1,
    },
    {
      field: 'totalOb',
      headerName: <Typography variant="body2">Total O/B</Typography>,
      flex: 1,
    },
    {
      field: 'totalOd',
      headerName: <Typography variant="body2">Total O/D</Typography>,
      flex: 1,
    },
    {
      field: 'amountRecovered',
      headerName: <Typography variant="body2">Amount recovered</Typography>,
      flex: 1,
    },
    {
      field: 'performance',
      headerName: <Typography variant="body2">Performance</Typography>,
      flex: 1,
    },
  ];

  return (
    <VegaTable
      border
      data={data}
      columnDef={columnDef}
      idColumn={'agentId'}
      loading={loading}
    />
  );
};

export default ListOfAgents;
