import { SVGProps } from 'react';
import { INDICATIVE } from '../../constants/style';
const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
    stroke="none"
  >
    <path
      d="M1.59961 3.60001H14.3996M5.59961 1.20001H10.3996M6.39961 11.6V6.80001M9.59961 11.6V6.80001M10.7996 14.8H5.19961C4.31595 14.8 3.59961 14.0837 3.59961 13.2L3.23433 4.43332C3.21539 3.97882 3.57874 3.60001 4.03364 3.60001H11.9656C12.4205 3.60001 12.7838 3.97882 12.7649 4.43332L12.3996 13.2C12.3996 14.0837 11.6833 14.8 10.7996 14.8Z"
      stroke={props.stroke ?? INDICATIVE.red[500]}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TrashIcon;
