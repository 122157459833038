/** @format */

import { Box, Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';
import Vega_Text from '../../../../../../common/v3/Vega_Text';
import { NEW_PRIMARY } from '../../../../../../../constants/style';
import { useEffect, useState } from 'react';
import { getTcsSummary } from '../../../../../../../api/ledger';
import { useSupport } from '../../../../../../../providers/SupportProvider';

interface TransactionData {
  accountId: string;
  totalTxnAmtInEduAndMed: number;
  tcsCollectedInEduAndMed: number;
  totalTxnAmtOtherMcgs: number;
  tcsCollectedOtherMcgs: number;
}

export type CustomerDataGridRow = {
  key: string;
  value: string | number;
};

interface ITcsSummaryListStateProps {
  data: TransactionData | null;
  loading: boolean;
}

const ListOfTcsSummary = () => {
  const { accountData } = useSupport();
  const [tcsSummaryListState, setTcsSummaryListState] =
    useState<ITcsSummaryListStateProps>({
      data: null,
      loading: false,
    });

  const getTcsSummaryList = async (accountId: string) => {
    setTcsSummaryListState({ data: null, loading: true });
    try {
      const response = await getTcsSummary(accountId);
      setTcsSummaryListState({ data: response, loading: false });
    } catch (error) {
      setTcsSummaryListState({ data: null, loading: false });
    }
  };
  useEffect(() => {
    if (accountData?.accountId) {
      getTcsSummaryList(accountData.accountId);
    }
  }, []);

  const data = [
    {
      key: 'Transaction Amount - Med & Edu',
      value: tcsSummaryListState.data?.totalTxnAmtInEduAndMed ?? 0,
    },
    {
      key: 'TCS Collected',
      value: tcsSummaryListState.data?.tcsCollectedInEduAndMed ?? 0,
    },
    {
      key: 'Transaction Amount - Other MCGs',
      value: tcsSummaryListState.data?.totalTxnAmtOtherMcgs ?? 0,
    },
    {
      key: 'TCS Collected',
      value: tcsSummaryListState.data?.tcsCollectedOtherMcgs ?? 0,
    },
  ];
  return (
    <Box
      sx={{
        borderRadius: '20px',
        bgcolor: 'background.paper',
      }}
    >
      <Box sx={{ border: '1px solid #E4E4E4', borderRadius: '8px' }}>
        <Grid container>
          {data.map((val: CustomerDataGridRow, index: number) => {
            const isLastItem = index === data.length - 1;
            const isSecondLastItem = index === data.length - 2;
            return (
              <Grid item xs={6} sm={6} width={'50%'}>
                <Stack
                  sx={{
                    borderBottom:
                      isLastItem || isSecondLastItem
                        ? 'none'
                        : '1px solid #E4E4E4',
                  }}
                  direction={'row'}
                  alignItems={'center'}
                >
                  <Box
                    width={'50%'}
                    sx={{
                      px: 1,
                      py: 1,
                      bgcolor: '#F7F7F7',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      ...(index === 0 && { borderTopLeftRadius: '8px' }),
                      ...(index === 2 && { borderBottomLeftRadius: '8px' }),
                    }}
                  >
                    <Vega_Text
                      text={val.key}
                      fontSize={13}
                      fontWeight={700}
                      color={NEW_PRIMARY.GRAY}
                    />
                  </Box>
                  <Box width={'50%'} sx={{ px: 1, py: 1 }}>
                    <Vega_Text
                      text={val.value}
                      fontSize={12}
                      fontWeight={500}
                      color={NEW_PRIMARY.BLACK}
                    />
                  </Box>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default ListOfTcsSummary;
