import { useQuery } from 'react-query';
import { QUERY_NAMES } from '../../enums/commons';
import { getRewardsList } from '../../api/rewards';
import { CommonQuery } from '../../types/commonType';
import useDebounce from '../../hooks/useDebounce';

export const useRewardList = ({
  accountId,
  pageNumber,
  pageSize,
  enabled,
  endDate,
  startDate,
  search,
}: {
  accountId: string;
  pageNumber?: number;
  pageSize?: number;
  enabled?: boolean;
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(search, 500);

  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.REWARD_LIST,
      accountId,
      { pageSize, pageNumber, debouncedSearch, endDate, startDate },
    ],
    queryFn: () =>
      getRewardsList({
        accountId,
        page: pageNumber,
        pageSize,
        endDate,
        startDate,
        q: debouncedSearch,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};
