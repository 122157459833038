/** @format */

import _ from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import {
  ResourceType,
  useInstitutionalResources,
} from '../../hooks/useInstitutionalResources';
import {
  MccCode,
  Merchant,
  MerchantMid,
  MerchantTid,
} from '../../types/resources';
import VegaDataGridV4 from '../common/v4/VegaDataGridV4';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { useSearchContext } from './searchValueContext';
import Stack from '@mui/material/Stack';
import { ComponentBody1Typo } from '../common/Typography';
import VegaButtonV4 from '../common/v4/VegaButtonV4';
import EditIconV4 from '../iconography/EditIconV4';
import AddOrEditMerchant from './AddOrEditMerchant';
import { useResourcesQuery } from '../../store/queries/resourcesQueries';

function MerchantTable() {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [editMerchant, setEditMerchant] = useState<Merchant | null>(null);
  const { searchValue, searchBy } = useSearchContext();
  const { isLoading, data: paginatedData } = useResourcesQuery<Merchant>({
    type: ResourceType.MERCHANT,
    key: 'fetchMerchants',
    page,
    pageSize,
    searchBy: searchBy as string,
    searchQuery: searchValue,
  });
  const columnMerchant = useMemo(() => getColumns(setEditMerchant), []);

  return (
    <Fragment>
      <VegaDataGridV4
        data={paginatedData?.records ?? []}
        columns={columnMerchant}
        idColumn={'id'}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        page={page}
        pageSize={pageSize}
        rowCount={paginatedData?.totalItems}
        paginationMode="server"
        loading={isLoading}
      />
      {!!editMerchant && (
        <AddOrEditMerchant
          isOpen
          onClose={() => setEditMerchant(null)}
          merchant={editMerchant}
        />
      )}
    </Fragment>
  );
}

export default MerchantTable;

const getColumns = (
  onEditClick: (merchant: Merchant) => void
): GridColDef<Merchant>[] => [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: (props: any) => {
      const logoUrl = props.row.logo ?? '';
      const hasLogo = logoUrl.length > 0;

      return (
        <Stack direction="row" gap={1} alignItems="center">
          <Stack width={16} height={16}>
            {hasLogo && (
              <img
                height={'100%'}
                width={'100%'}
                style={{ objectFit: 'contain' }}
                src={logoUrl}
                alt="Logo"
              />
            )}
          </Stack>
          <ComponentBody1Typo>{props.row.name}</ComponentBody1Typo>
        </Stack>
      );
    },
  },
  {
    field: 'mids',
    headerName: 'MIDs',
    flex: 1,
    valueGetter: (params) => params.row.mids,
    valueFormatter: (
      params: GridValueFormatterParams<MerchantMid[] | undefined>
    ) => {
      const mids = params.value;
      const commaSeparatedIds = mids?.map((item) => item.mid).join(',');
      return commaSeparatedIds || '-';
    },
  },
  {
    field: 'tid',
    headerName: 'TIDs',
    flex: 1,
    valueGetter: (params) => params.row.tids,
    valueFormatter: (
      params: GridValueFormatterParams<MerchantTid[] | undefined>
    ) => {
      const tids = params.value;
      const commaSeparatedIds = tids?.map((item) => item.tid).join(',');
      return commaSeparatedIds || '-';
    },
  },
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,
    valueGetter: (params) => params.row.mccCodes,
    valueFormatter: (
      params: GridValueFormatterParams<MccCode[] | undefined>
    ) => {
      const categories = params.value ?? [];
      const categoriesNames = new Set();
      categories?.forEach((item) => {
        categoriesNames.add(_.startCase(_.toLower(item.category?.name)));
      });
      const commaSeparatedNames = Array.from(categoriesNames).join(', ');
      return commaSeparatedNames;
    },
  },

  {
    field: 'action',
    headerName: 'Action',
    flex: 0.5,
    renderCell: (props: any) => {
      return (
        <VegaButtonV4
          startIcon={<EditIconV4 />}
          variant="mellowed"
          size="small"
          onClick={() => onEditClick(props.row)}
        />
      );
    },
  },
];
