/** @format */

import axios from 'axios';
import { NewCardBin } from '../types/card';
import { VegaBin, VegaNewBin } from '../types/client';
import { BASE_URL, CARD_PROCESSOR } from './endpoints';

const CARD_BASE_URL = BASE_URL + CARD_PROCESSOR.BASE;
const CARD_BIN = CARD_BASE_URL + CARD_PROCESSOR.BIN;

export const listBinForClient = (
  clientId: string,
  page: number,
  size: number
) => {
  return axios.post(`${CARD_BIN}/listForClient`, { clientId, page, size });
};

export const listBinAllocations = (binId: string | number) => {
  return axios.post(`${CARD_BIN}/binProgram/list`, { binId });
};

export const listCardFormFactorForClient = (clientId: string) => {
  return axios.get(`${CARD_BIN}/cardProcessorConfig/list/${clientId}`);
};

export const addCardFormFactorForClient = (clientId: string) => {
  const data = {
    clientId: clientId,
    cardProcessorVendor: 'M2P',
    cardProcessorVendorId: '35edd4c3-1743-452e-aeea-f76e540240ee',
    productType: 'CREDIT',
    vendorMetadata: {
      appGuid: '123dase',
      business: 'LQVEGAPAY',
      channelName: 'MIN_KYC',
      entityType: 'CUSTOMER',
      cardType: 'VIRTUAL',
      cardCategory: 'PREPAID',
      cardRegStatus: 'ACTIVE',
    },
  };

  return axios.post(`${CARD_BIN}/cardProcessorConfig`, data);
};

export async function addBinForClient(
  bin: Partial<VegaBin | VegaNewBin>
): Promise<VegaBin | VegaNewBin> {
  try {
    const endpoint = `${CARD_BIN}/add`;
    const response = await axios.post(endpoint, bin);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const addProgramForBin = (
  binId: number,
  programId: any,
  binRangeRegex: string
) => {
  const data = {
    binId,
    programId,
    binRangeRegex,
  };
  return axios.post(`${CARD_BIN}/addProgram`, data);
};

export const allocateBin = (
  binId: number,
  programId: any,
  numberOfKits: number
) => {
  const data = {
    binId: binId,
    programId: programId,
    numberOfKits: numberOfKits,
  };

  return axios.post(`${CARD_BIN}/binProgram`, data);
};

export async function listBinsByIds(ids: number[]): Promise<NewCardBin[]> {
  try {
    const endpoint = CARD_BIN + '/listByIds';
    const response = await axios.post(endpoint, { ids: ids });
    return response.data.records;
  } catch (error) {
    throw error;
  }
}
