import { useMutation } from 'react-query';
import { UpdatePartnerRequest } from '../../types/partner';
import PartnerService from '../../services/PartnerService';

export const useUpdatePartner = () => {
  return useMutation({
    mutationFn: (request: UpdatePartnerRequest) => {
      return PartnerService.updatePartner(request);
    },
  });
};
