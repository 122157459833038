import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { addReportConfig, updateReportConfig } from '../../../api/reports';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  IReportConfig,
  IReportConfigReponse,
  tempReportConfig,
} from '../../../types/reports';
import VegaDrawer from '../../common/VegaDrawer';
import { REPORT_SAMPLE_LINKS } from '../constants';
import ScheduleModal from './ScheduleModal';

type Props = {
  openScheduleModal: any;
  setOpenScheduleModal: any;
  selectedReportName?: string;
  selectedReportId?: string;
  fetchReportsConfig?: any;
  initialReportConfig?: IReportConfigReponse | null;
};

function ReportsConfigModal({
  openScheduleModal,
  setOpenScheduleModal,
  selectedReportId = '',
  fetchReportsConfig,
  initialReportConfig = null,
  selectedReportName = '',
}: Props) {
  const [reportConfig, setReportConfig] = useState<IReportConfig>({
    ...tempReportConfig,
    reportId: selectedReportId,
  });

  const closeModalHandler = () => {
    setOpenScheduleModal(!openScheduleModal);
  };
  const { setSnackbar } = useSnackbar();

  const { client } = useClientAuth();

  const submitHandler = () => {
    // dayjs.extend(utc);
    // const ISTTime = dayjs()
    //   .hour(reportConfig.hours)
    //   .minute(reportConfig.minutes);

    // console.log('add__IST', {
    //   hrs: ISTTime.hour(),
    //   mins: ISTTime.minute(),
    // });

    // const UTCTime = ISTTime.utc();
    // console.log('add__UTC', {
    //   hrs: UTCTime.hour(),
    //   mins: UTCTime.minute(),
    // });

    if (reportConfig) {
      addReportConfig({
        reportId: reportConfig.reportId,
        sampleReportLink:
          reportConfig.sampleReportLink || selectedReportName
            ? REPORT_SAMPLE_LINKS[selectedReportName]
            : '',
        status: reportConfig.status,
        frequency: reportConfig.frequency,
        hours: reportConfig.hours,
        minutes: reportConfig.minutes,
        recipients: reportConfig.recipients,
        subject: reportConfig.subject,
        description: reportConfig.description,
        createdBy: reportConfig.createdBy || client?.clientName || 'Alpha User',
        format: reportConfig.format,
      })
        .then((res) => {
          setSnackbar('Added report config', 'success');
        })
        .catch((err) => {
          setSnackbar('Failed to add report config', 'error');
        });
    }
    setOpenScheduleModal(!openScheduleModal);
    fetchReportsConfig();
  };

  const updateHandler = () => {
    if (reportConfig && initialReportConfig) {
      updateReportConfig({
        reportConfigId: initialReportConfig?.reportConfigId,
        reportId: reportConfig.reportId,
        sampleReportLink:
          reportConfig.sampleReportLink || selectedReportName
            ? REPORT_SAMPLE_LINKS[selectedReportName]
            : '',
        status: reportConfig.status,
        frequency: reportConfig.frequency,
        hours: reportConfig.hours,
        minutes: reportConfig.minutes,
        recipients: reportConfig.recipients,
        subject: reportConfig.subject,
        description: reportConfig.description,
        createdBy: reportConfig.createdBy,
        format: reportConfig.format,
      })
        .then((res) => {
          setSnackbar('Report Config updated successfully!', 'success');
        })
        .catch((err) => {
          setSnackbar('Failed to update report config', 'error');
        });
    }

    setOpenScheduleModal(!openScheduleModal);
    fetchReportsConfig();
  };

  useEffect(() => {
    if (initialReportConfig) {
      setReportConfig({
        ...initialReportConfig,
      });
    }
  }, [initialReportConfig, selectedReportName]);

  return (
    <>
      {initialReportConfig ? (
        <VegaDrawer
          open={openScheduleModal}
          onClose={() => closeModalHandler()}
          anchor="right"
          isSubmit={true}
          isClose={true}
          onSubmit={() => updateHandler()}
          submitText="Update"
          closeText="Cancel"
          header={
            <div>
              Update this Report
              <IconButton sx={{ mx: 1 }}>
                <InfoIcon />
              </IconButton>
            </div>
          }
        >
          <ScheduleModal
            reportConfig={reportConfig}
            setReportConfig={setReportConfig}
          />
        </VegaDrawer>
      ) : (
        <VegaDrawer
          open={openScheduleModal}
          onClose={() => closeModalHandler()}
          anchor="right"
          isSubmit={true}
          isClose={true}
          onSubmit={() => submitHandler()}
          submitText="Schedule"
          closeText="Cancel"
          header={
            <div>
              Schedule this Report
              <IconButton sx={{ mx: 1 }}>
                <InfoIcon />
              </IconButton>
            </div>
          }
        >
          <ScheduleModal
            reportConfig={reportConfig}
            setReportConfig={setReportConfig}
          />
        </VegaDrawer>
      )}
    </>
  );
}

export default ReportsConfigModal;
