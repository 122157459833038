import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  IAccountCloseBody,
  IAccountClosureDetailsRequestBody,
  IAccountClosureRequestBody,
  ICancelAccountClosureRequestBody,
  IGetAllAccountClosureRequestBody,
} from '../types/accountClosure';
import { ACCOUNT, BASE_URL } from './endpoints';
import { IRequest } from '../types/commonType';
import { standard500ErrResponse } from '../utils/common.utils';

const ACC_BASE = BASE_URL + ACCOUNT.BASE;
const ACC_CLOSURE = ACC_BASE + ACCOUNT.CLOSURE;

export const closeAccountRequestAPI = createAsyncThunk(
  'accountClosure/account/closure',
  async (req: IRequest<IAccountClosureRequestBody>) => {
    try {
      const { data } = await axios.post(ACC_CLOSURE, req.payload);
      req?.setSnackbar?.('Account Closure request was initiated!', 'success');
      return { payload: data };
    } catch (err) {
      const errRes = standard500ErrResponse(err);
      req.setSnackbar?.(
        errRes.apierror.debugMessage ||
          errRes.apierror.message ||
          'Unknown Error',
        'error'
      );
      return errRes;
    }
  }
);

export const getAllCloseAccountRequestAPI = createAsyncThunk(
  'accountClosure/account/closure/list',
  async (req: IRequest<IGetAllAccountClosureRequestBody>) => {
    try {
      const { data } = await axios.get(`${ACC_CLOSURE}/list`, {
        params: req.payload,
      });
      return { payload: data };
    } catch (err) {
      const errRes = standard500ErrResponse(err);
      return errRes;
    }
  }
);

export const cancelCloseAccountRequestAPI = createAsyncThunk(
  'accountClosure/account/closure/cancel',
  async (req: IRequest<ICancelAccountClosureRequestBody>) => {
    try {
      const { data } = await axios.post(`${ACC_CLOSURE}/cancel`, req.payload);
      req?.setSnackbar?.('Account Closure request was cancelled!', 'success');
      req?.postSuccessFunc?.();
      return { payload: data };
    } catch (err) {
      const errRes = standard500ErrResponse(err);
      req.setSnackbar?.(
        errRes.apierror.debugMessage ||
          errRes.apierror.message ||
          'Unknown Error',
        'error'
      );
      return errRes;
    }
  }
);

export const closeAccountAPI = createAsyncThunk(
  'accountClosure/account/closure/close',
  async (req: IRequest<IAccountCloseBody>) => {
    try {
      const { data } = await axios.post(`${ACC_CLOSURE}/close`, req.payload);
      req?.setSnackbar?.(
        'The Account has been closed successfully!',
        'success'
      );
      req?.postSuccessFunc?.();
      return { payload: data };
    } catch (err) {
      const errRes = standard500ErrResponse(err);
      req.setSnackbar?.(
        errRes.apierror.debugMessage ||
          errRes.apierror.message ||
          'Unknown Error',
        'error'
      );
      return errRes;
    }
  }
);

export const closeAccountRequestDetailsAPI = createAsyncThunk(
  'accountClosure/account/closure/getdetail',
  async (req: IRequest<IAccountClosureDetailsRequestBody>) => {
    try {
      const { data } = await axios.get(
        `${ACC_CLOSURE}/getdetail/${req.payload?.id}`
      );
      return {
        payload: data,
      };
    } catch (err) {
      const errRes = standard500ErrResponse(err);
      return errRes;
    }
  }
);
