import axios, { AxiosResponse } from 'axios';
import {
  AddMerchantPayloadType,
  Mcg,
  Merchant,
  UpdateMerchantPayloadType,
} from '../types/resources';
import { BASE_URL, ONBOARDING, formContentType } from './endpoints';

const ONBOARDING_BASE_URL = BASE_URL + ONBOARDING.BASE;
const ONBOARDING_DATA_URL = ONBOARDING_BASE_URL + ONBOARDING.DATA;

export const fetchListOfCountries = (page: number, pageSize: number) => {
  return axios.get(
    `${ONBOARDING_DATA_URL}/country?page=${page}&pageSize=${pageSize}`
  );
};
export const fetchListOfTimeZone = (page: number, pageSize: number) => {
  return axios.get(
    `${ONBOARDING_DATA_URL}/timezone?page=${page}&pageSize=${pageSize}`
  );
};
export const fetchListOfCurrency = (page: number, pageSize: number) => {
  return axios.get(
    `${ONBOARDING_DATA_URL}/currency?page=${page}&pageSize=${pageSize}`
  );
};
export const fetchListOfPinCode = (page: number, pageSize: number) => {
  return axios.get(
    `${ONBOARDING_DATA_URL}/pincode?page=${page}&pageSize=${pageSize}`
  );
};

export type IPincodeRes = {
  records: Array<{
    id: number;
    pincode: string;
    state: string;
    city: string;
    country: string;
  }>;
  pageNumber: number;
  numberOfItems: number;
  totalPages: number;
  totalItems: number;
};

export const fetchStateOnPincode = (
  pincode: string
): Promise<AxiosResponse<IPincodeRes, any>> => {
  return axios.get(`${ONBOARDING_DATA_URL}/pincode?pincode=${pincode}`);
};
export const fetchListOfResources = (
  page: number,
  pageSize: number,
  URL: string,
  key?: string,
  value?: string
) => {
  return axios.get(`${ONBOARDING_DATA_URL}/${URL}`, {
    params: {
      page,
      pageSize,
      ...(key && value && { [key]: value }),
    },
  });
};

export async function updateMcgRisk(data: {
  id: string;
  riskProfile: string;
}): Promise<Mcg> {
  try {
    const endpoint = ONBOARDING_DATA_URL + '/mcg';
    const response = await axios.put(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createMerchant = (data: AddMerchantPayloadType) => {
  const endpoint = ONBOARDING_DATA_URL + '/merchant';
  return axios.post<Merchant>(endpoint, data);
};

export const updateMerchant = (data: UpdateMerchantPayloadType) => {
  const endpoint = ONBOARDING_DATA_URL + '/merchant';
  return axios.put<Merchant>(endpoint, data);
};

export const updateMerchantLogo = (merchantId: string, logo: File) => {
  const endpoint = ONBOARDING_DATA_URL + '/merchant';
  return axios.patch(
    endpoint,
    {
      logo: logo,
      id: merchantId,
    },
    {
      headers: {
        ...formContentType,
      },
    }
  );
};
