import { Box, Stack } from '@mui/material';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import VegaCardV4 from '../../common/v4/VegaCardV4';
import VegaAutocompleteSearchV4 from '../../common/v4/VegaAutocompleteSearchV4';
import { useEffect, useState } from 'react';
import VegaTableV4 from '../../common/v4/VegaTableV4';
import {
  getTransactionAccListStructuredData,
  getTransactionDetailsDialogData,
  searchByOptionsForTransactions,
} from '../simulatorUtils';
import VegaNoResults from '../../common/VegaNoResults';
import { TransactionDto } from '../../../types/transaction';
import VegaDetailsDialogV4 from '../../common/v4/VegaDetailsDialogV4';
import TransactionDebitDrawer from './TransactionDebitDrawer';
import TransactionRepaymentDrawer from './TransactionRepaymentDrawer';
import { useSimulatorContext } from '../SimulatorMainWrap';
import { TextFieldSearchProps } from '../../../utils/common.utils';
import { useTransactionListQuery } from '../../../store/queries/transactionQueries';
import VegaSelectV4 from '../../common/v4/VegaSelectV4';
import { ListGroupHeadingTypo } from '../../common/Typography';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';
import { VALIDATE_REGEX } from '../../../utils/validation.utils';

const TransactionListPage = () => {
  const [openDebitTransactionDrawer, setOpenDebitTransactionDrawer] =
    useState(false);
  const [openRepaymentDrawer, setOpenRepaymentDrawer] = useState(false);
  const [openTransactionDetailsDialog, setOpenTransactionDetailsDialog] =
    useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<
    TransactionDto | undefined
  >(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState('');

  const updateRepaymentDrawer = (val: boolean) => () => {
    setOpenRepaymentDrawer(val);
  };

  const updateDebitTransactionDrawer = (val: boolean) => () => {
    setOpenDebitTransactionDrawer(val);
  };

  const transactionDetailsDialogOpen = (rec: TransactionDto) => {
    setSelectedTransaction(rec);
    setOpenTransactionDetailsDialog(true);
  };

  const transactionDetailsDialogClose = () => {
    setOpenTransactionDetailsDialog(false);
  };

  const {
    searchValue: value,
    updateSearchValue,
    customerRecords,
    activeSearchBy,
    updateActiveSearchBy,
    onTextChange,
    selectedAccId,
    isCustDataFetching,
  } = useSimulatorContext();

  const {
    data: transactionData,
    isFetching,
    refetch,
  } = useTransactionListQuery({
    accountId: selectedAccId as string,
    pageNumber: page,
    pageSize,
    search,
  });

  const records = selectedAccId ? transactionData?.records : [];

  useEffect(() => {
    setPage(0);
  }, [value]);

  const updateSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const val = e.target.value;
    if (VALIDATE_REGEX.ALPHA_NUMERIC.test(val) || val === '') {
      setSearch(val);
      setPage(0);
    }
  };

  return (
    <Box>
      <VegaHeaderV4 title="Transactions" sx={{ marginTop: 4 }} />
      <Stack p={3} gap={5}>
        <VegaCardV4>
          <Stack direction="row" gap={2} alignItems={'center'}>
            <VegaSelectV4
              header="Search By"
              options={searchByOptionsForTransactions}
              value={activeSearchBy}
              onChange={updateActiveSearchBy}
              wrapperProps={{
                flex: 0.7,
              }}
              sx={{ height: 36.5 }}
              standardWidth={180}
              headerTypo={ListGroupHeadingTypo}
            />
            <VegaAutocompleteSearchV4
              textFieldProps={{
                ...TextFieldSearchProps,
                onChange: onTextChange,
              }}
              options={customerRecords || []}
              value={value || ''}
              onChange={updateSearchValue}
              freeSolo
              loading={isCustDataFetching}
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            <VegaButtonV4
              variant="outlined"
              onClick={updateDebitTransactionDrawer(true)}
              disabled={!Boolean(selectedAccId)}
            >
              Create Debit Transaction
            </VegaButtonV4>
            <VegaButtonV4
              variant="outlined"
              onClick={updateRepaymentDrawer(true)}
              disabled={!Boolean(selectedAccId)}
            >
              Create Repayment
            </VegaButtonV4>
          </Stack>
        </VegaCardV4>
        <Stack gap={2}>
          <Stack alignItems="flex-end">
            <VegaSearchfieldV4
              placeholder="Search by Txn Id..."
              onChange={updateSearch}
              value={search}
            />
          </Stack>
          {!value || (!records?.length && !isFetching) ? (
            <VegaNoResults
              errorText={
                value
                  ? 'No Transaction Found for the customer. Please select another customer'
                  : 'No Transaction Found. Please select the customer'
              }
            />
          ) : (
            <VegaTableV4
              head={[
                'Txn Id',
                'Txn Time',
                'Amount',
                'Currency',
                'Indicator',
                'Status',
                'Merchant Name',
                'Category',
                'Code',
                'Txn Details',
              ]}
              data={getTransactionAccListStructuredData(
                records,
                transactionDetailsDialogOpen
              )}
              isLoading={isFetching}
              pagination={{
                rowsPerPage: pageSize,
                setRowsPerPage: setPageSize,
                setPage,
                page,
                totalRecords: transactionData?.totalItems || 0,
              }}
            />
          )}
        </Stack>
      </Stack>
      <TransactionDebitDrawer
        open={openDebitTransactionDrawer}
        onClose={updateDebitTransactionDrawer(false)}
        accountId={selectedAccId}
        refetch={refetch}
      />
      <TransactionRepaymentDrawer
        open={openRepaymentDrawer}
        onClose={updateRepaymentDrawer(false)}
        accountId={selectedAccId}
        refetch={refetch}
      />
      <VegaDetailsDialogV4
        data={getTransactionDetailsDialogData(selectedTransaction)}
        dialogProps={{
          title: 'Transaction Details',
          open: openTransactionDetailsDialog,
          onClose: transactionDetailsDialogClose,
        }}
        keyMinWidth={170}
        dialogMinWidth={600}
      />
    </Box>
  );
};

export default TransactionListPage;
