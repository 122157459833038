/** @format */

import { CheckCircle } from '@mui/icons-material';
import { Dialog, Grid, IconButton, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { addProgramKey, getProgramKey } from '../../api/program';
import { BACKGROUND, BLUE, BORDER } from '../../constants/style';
import { INewProgramState } from '../../pages/ClientDashBoard';
import { useClientAuth } from '../../providers/ClientProvider';
import { useProgramData } from '../../providers/ProgramDataProvider';
import { VegaProgramType } from '../../types/program';
import EditProgram from '../ClientHome/EditProgram';
import { Loading, NoResults, VegaCard, VegaTable } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaDrawer from '../common/VegaDrawer';
import VegaLabel from '../common/VegaLabel';
import { CopyIcon, CreditCardIcon } from '../icon/Icons';
import { useSnackbar } from '../../providers/SnackbarProvider';

type Props = {};

const INITAIL_KEY = {
  clientName: '',
  clientId: '',
  clientSecret: '',
};

interface IProgramKeyProps {
  clientName: string;
  clientId: string;
  clientSecret: string;
}

type ProgramStatusToggleProps = {
  programId: string;
  status: string;
};

const ListOfPrograms = (props: Props) => {
  const { clientId } = useClientAuth();
  const {
    programs,
    fetchPrograms,
    loading,
    updateProgramStatusLocal,
    updateStatusLoading,
  } = useProgramData();
  const { isAdmin } = useClientAuth();
  const { setSnackbar } = useSnackbar();

  const [activeState, setActiveState] = useState<INewProgramState>({
    text: '',
    id: '',
    labelText: '',
    isLabel: true,
    bgColor: BACKGROUND.BLUE,
    image: <CreditCardIcon />,
  });
  const [openProgram, setOpenProgram] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [copyText, setCopyText] = useState<string>('');
  const [generated, setGenerated] = useState<any>({});
  const [loadingKeyMeta, setLoadingKeyMeta] = useState<boolean>(false);
  const [programKey, setProgramKey] = useState<IProgramKeyProps>({
    ...INITAIL_KEY,
  });

  React.useEffect(() => {
    fetchProgramKeyMeta();
  }, [programs]);

  const fetchProgramKeyMeta = () => {
    if (programs?.length > 0 && !loadingKeyMeta) {
      setLoadingKeyMeta(true);
      programs.forEach(async (program) => {
        if (program.status !== 'ACTIVE') {
          try {
            const programKeyMetaResp = await getProgramKey(program.programId);
            console.log('ProgramKey meta', programKeyMetaResp);
            setGenerated((prev: any) => ({
              ...prev,
              [program.programId]: programKeyMetaResp.data,
            }));
          } catch {
            console.log('Failed');
          }
        }
      });
      setLoadingKeyMeta(false);
    }
  };

  const onClickGenerate = (id: number) => {
    setOpen(true);
    setCopyText('');
    if (generated[id]) {
      console.log('Generated value');
      setProgramKey(generated[id]);
    } else {
      addProgramKey(clientId, id).then((res) => {
        setProgramKey(res.data);
        setGenerated({ ...generated, [id]: res.data });
      });
    }
  };

  const copyLink = (data: any) => {
    setCopyText(data);
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(data);
    } else {
      return Promise.reject('The Clipboard API is not available.');
    }
  };

  const isEventSubscribed = (id: number) => {
    // console.log('Checking..', generated);
    return !!generated[id];
  };

  const notAdminSnackbar = () => {
    setSnackbar(
      'You are not authorized to change status of any program',
      'error'
    );
  };

  const columnDef = [
    {
      field: 'status1',
      headerName: '  Program Status',
      headerClassName: 'headerClass',
      renderCell: (props: any) => {
        return (
          <Switch
            disabled={updateStatusLoading}
            onChange={(e) =>
              isAdmin
                ? updateProgramStatusLocal(
                    props.row.programId,
                    props.row.status === 'ACTIVE' ? 'DRAFT' : 'ACTIVE'
                  )
                : notAdminSnackbar()
            }
            checked={props.row.status === 'ACTIVE'}
            color="success"
          />
        );
      },
      flex: 1,
    },
    {
      field: 'name',
      headerName: '  Program Name',
      flex: 1,
    },
    {
      field: 'Details',
      headerName: '  Details',
      renderCell: (props: any) => {
        return (
          <VegaContainedButton
            text="View"
            isPrimary
            onClick={() => showDetails(props.row)}
          />
        );
      },
      flex: 0.7,
    },
    {
      field: 'type',
      headerName: '  Type',
      flex: 1,
      renderCell: (props: any) => {
        return <Typography>Credit Card</Typography>;
      },
    },
    {
      field: 'subType',
      headerName: '  Sub-Type',
      flex: 1,
      renderCell: (props: any) => {
        return <Typography>Retails Card</Typography>;
      },
    },
    {
      field: 'loremKey',
      headerName: '  Key',
      flex: 1,
      renderCell: (props: any) => {
        if (loadingKeyMeta) {
          return <Loading />;
        }
        if (props.row.status === 'ACTIVE') {
          return (
            <VegaContainedButton
              text="Lock"
              px={3}
              // onClick={() => showDetails(props.row)}
            />
          );
        }
        const isSubscribed = isEventSubscribed(props.row.programId);
        console.log(isSubscribed);
        return isSubscribed ? (
          <Box>
            <VegaContainedButton
              text="View"
              px={3}
              isPrimary
              onClick={() => onClickGenerate(props.row.programId)}
            />
          </Box>
        ) : (
          <VegaContainedButton
            text="Generate"
            px={1.2}
            isSuccess
            onClick={() => onClickGenerate(props.row.programId)}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: '  Status',
      renderCell: (props: any) => {
        return (
          <VegaLabel
            text={props.row.status === 'ACTIVE' ? 'prod' : 'Dev'}
            color={props.row.status === 'ACTIVE' ? 'success' : 'primary'}
          />
        );
      },
      flex: 0.5,
    },
  ];

  const showDetails = (data: VegaProgramType) => {
    var createdAtDate = undefined;

    if (data.createdAt) {
      createdAtDate = new Date(data.createdAt).getTime();
    }

    setActiveState({
      text: data.name,
      id: data.programId,
      labelText: data.status ?? '',
      isLabel: true,
      bgColor: BACKGROUND.BLUE,
      image: <CreditCardIcon />,
      date: createdAtDate,
    });
    setOpenProgram(!openProgram);
  };

  React.useEffect(() => {
    if (!loading && !programs) fetchPrograms();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!programs || programs.length === 0) {
    return (
      <NoResults text="You have no program" subtext="Create new program?" />
    );
  }
  return (
    <Box sx={{ position: 'relative' }}>
      <VegaCard fullHeight noPad>
        <VegaTable
          data={programs}
          columnDef={columnDef}
          idColumn={'programId'}
        />
      </VegaCard>
      <VegaDrawer
        open={openProgram}
        onClose={() => setOpenProgram(!openProgram)}
        anchor="right"
        isSubmit={true}
        isClose={false}
        submitText="Got it"
        onSubmit={() => setOpenProgram(!openProgram)}
        header={`Program name - ${activeState?.text}`}
      >
        <EditProgram
          state={activeState}
          handleChange={() => console.log('first')}
        />
      </VegaDrawer>

      <Dialog
        sx={{
          '.MuiBackdrop-root': {
            backgroundColor: 'rgba(12,12,12,0.10)',
          },
          '.MuiPaper-root': {
            borderRadius: '10px',
            borderLeft: '14px solid #4785FF',
            position: 'absolute',
            right: 0,
            bottom: 0,
          },
        }}
        onClose={() => setOpen(!open)}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            px: 2,
            py: 3,
          }}
        >
          <Grid container alignItems={'center'} my={1}>
            <Grid item sm={2}>
              <Typography sx={{ color: BLUE.lighter, fontSize: 14 }}>
                API Key
              </Typography>
            </Grid>
            <Grid item sm={10}>
              <Box sx={{ display: 'flex', flexGrow: 1, mx: 2 }}>
                <Typography
                  sx={{
                    flexGrow: 1,
                    maxWidth: '360px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    p: 1,
                    borderRadius: '5px',
                    border: BORDER,
                    mx: 1,
                    fontSize: 12,
                  }}
                >
                  {programKey?.clientId}
                </Typography>
                {copyText === programKey?.clientId ? (
                  <IconButton>
                    <CheckCircle fontSize="small" color="success" />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => copyLink(programKey?.clientId)}>
                    <CopyIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} my={1}>
            <Grid item sm={2}>
              <Typography sx={{ color: BLUE.lighter, fontSize: 14 }}>
                API Secret
              </Typography>
            </Grid>
            <Grid item sm={10}>
              <Box sx={{ display: 'flex', flexGrow: 1, mx: 2 }}>
                <Typography
                  sx={{
                    flexGrow: 1,
                    maxWidth: '360px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    p: 1,
                    borderRadius: '5px',
                    border: BORDER,
                    mx: 1,
                    fontSize: 12,
                  }}
                >
                  {programKey?.clientSecret}
                </Typography>
                {copyText === programKey?.clientSecret ? (
                  <IconButton>
                    <CheckCircle fontSize="small" color="success" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => copyLink(programKey?.clientSecret)}
                  >
                    <CopyIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ListOfPrograms;
