import { Box, Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../../../common/Typography';
import VegaSearchfieldV4 from '../../../../../common/v4/VegaSearchfieldV4';
import VegaTableV4 from '../../../../../common/v4/VegaTableV4';
import VegaNoResults from '../../../../../common/VegaNoResults';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { useState } from 'react';
import { useKeyValHook } from '../../../../../../hooks/useKeyValHook';
import { usePhysicalStatementListQuery } from '../../../../../../store/queries/billQueries';
import { getPhysicalStatementStructuredData } from '../../../supportUtils';
import { PhysicalStatementStatus } from '../../../../../../types/bill';

interface IPSFilter {
  date?: Date;
  status?: PhysicalStatementStatus | 'All';
  search?: string;
  startDate?: string;
  endDate?: string;
}

type Keys = 'search';

const PhysicalStatement = () => {
  const { accountData } = useSupport();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const updatePage2Zero = () => {
    setPage(0);
  };

  const { updateValue, values } = useKeyValHook<IPSFilter, Keys>(
    {
      status: 'All',
      search: undefined,
    },
    updatePage2Zero
  );

  const { data, isFetching } = usePhysicalStatementListQuery({
    accountId: accountData?.accountId || '',
    pageNumber: page,
    pageSize,
    search: values.search,
    enabled: Boolean(accountData?.accountId),
  });

  const updateRecord = (key: Keys) => (e: any) => {
    updateValue(
      key,
      key === 'search' && e.target.value === ''
        ? undefined
        : (e.target.value as string)
    );
  };

  const clearSearch = () => {
    updateValue('search', undefined);
  };

  const structuredData = getPhysicalStatementStructuredData(data?.records);

  return (
    <Box>
      <Stack gap={2}>
        <PageHeadingTypo>Physical Statement</PageHeadingTypo>
        <Stack alignItems="flex-end">
          <VegaSearchfieldV4
            value={values.search}
            onChange={updateRecord('search')}
            onClear={clearSearch}
            placeholder="Search by request Id..."
          />
        </Stack>
        {!Boolean(data?.records.length) && !isFetching ? (
          <VegaNoResults errorText="Physical Statement list is Empty" />
        ) : (
          <VegaTableV4
            head={[
              'Request ID',
              'Bill ID',
              'Bill Generation Date',
              'Bill Cycle Start Date',
              'Bill Cycle End Date',
              'Last Modified on',
              'Request Status',
            ]}
            data={structuredData}
            isLoading={isFetching}
            pagination={{
              rowsPerPage: pageSize,
              setRowsPerPage: setPageSize,
              setPage,
              page,
              totalRecords: data?.totalItems || 0,
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

export default PhysicalStatement;
