export const FOREX_SUB_MODULE_MAP: Record<string, any> = {
  FOREX_HQ: {
    'CREATE-PROGRAM': 'FOREX_HQ_PROGRAM',
    'CREATE-PARTNER': 'FOREX_HQ_PARTNER',
    'CREATE-BRANCH': 'FOREX_HQ_BRANCH',
    'CREATE-ROLE': 'FOREX_HQ_ROLE',
    'TEAM-MANAGEMENT-CHECKER': 'FOREX_HQ_TEAM_MANAGEMENT_CHECKER',
    'TEAM-MANAGEMENT-MAKER': 'FOREX_HQ_TEAM_MANAGEMENT_MAKER',
    REPORT: 'FOREX_HQ_REPORT',
    ANALYTICS: 'FOREX_HQ_ANALYTICS',
    POLICIES: 'FOREX_HQ_POLICIES',
    'PROGRAM-ALLOCATION': 'FOREX_HQ_PROGRAM_ALLOCATION',
    'INSTITUTIONAL-RESOURCES': 'FOREX_HQ_INSTITUTIONAL_RESOURCES',
    'CARD-CHECKER': 'FOREX_HQ_CARD_SALE_CHECKER',
    'APPLICATION-MANAGEMENT': 'FOREX_HQ_APPLICATION_MANAGEMENT',
    'CARD-TRANSACTION': 'FOREX_HQ_CARD_TRANSACTION',
    'CORPORATE-TRANSACTION-CHECKER': 'FOREX_HQ_CORPORATE_TRANSACTION_CHECKER',
    'INVENTORY-MANAGEMENT': 'FOREX_HQ_INVENTORY_MANAGEMENT',
  },
  FOREX_BRANCH: {
    'TEAM-MANAGEMENT': 'FOREX_BRANCH_TEAM_MANAGEMENT',
    'APPLICATION-MANAGEMENT': 'FOREX_BRANCH_APPLICATION_MANAGEMENT',
    'USER-MANAGEMENT': 'FOREX_BRANCH_USER_MANAGEMENT',
    'CARD-MAKER': 'FOREX_BRANCH_CARD_SALE_MAKER',
    'CARD-TRANSACTION': 'FOREX_BRANCH_CARD_TRANSACTION',
    'INVENTORY-MANAGEMENT': 'FOREX_BRANCH_INVENTORY_MANAGEMENT',
    'RELEASE-TRANSACTION': 'FOREX_BRANCH_RELEASE_TRANSACTION',
    SERVICES: 'FOREX_BRANCH_SERVICES',
    'CORPORATE-MANAGEMENT': 'FOREX_BRANCH_CORPORATE_MANAGEMENT',
    'CORPORATE-TRANSACTION': 'FOREX_BRANCH_CORPORATE_TRANSACTION',
    REPORT: 'FOREX_BRANCH_REPORT',
    ANALYTICS: 'FOREX_BRANCH_ANALYTICS',
  },
  FOREX_CORPORATE: {
    'CARD-ANALYTICS': 'FOREX_CORPORATE_CARD_ANALYTICS',
    'CARD-TRANSACTION': 'FOREX_CORPORATE_CARD_TRANSACTION',
    INVOICES: 'FOREX_CORPORATE_INVOICES',
    'APPLICATION-MANAGEMENT': 'FOREX_CORPORATE_APPLICATION_MANAGEMENT',
    'CARD-SALE': 'FOREX_CORPORATE_CARD_SALE',
    REPORT: 'FOREX_CORPORATE_REPORT',
    ANALYTICS: 'FOREX_CORPORATE_ANALYTICS',
  },
  FOREX_SIMULATOR: {
    TRANSACTIONS: 'FOREX_SIMULATOR_TRANSACTIONS',
  },
};

export const ACCESS_FEATURES = {
  BRANCH_ADMIN_ACCESS_FEATURES: [
    'FOREX_BRANCH_TEAM_MANAGEMENT',
    'FOREX_BRANCH_APPLICATION_MANAGEMENT',
    'FOREX_BRANCH_USER_MANAGEMENT',
    'FOREX_BRANCH_CARD_SALE_MAKER',
    'FOREX_BRANCH_CARD_TRANSACTION',
    'FOREX_BRANCH_INVENTORY_MANAGEMENT',
    'FOREX_BRANCH_RELEASE_TRANSACTION',
    'FOREX_BRANCH_SERVICES',
    'FOREX_BRANCH_CORPORATE_MANAGEMENT',
    'FOREX_BRANCH_CORPORATE_TRANSACTION',
    'FOREX_BRANCH_REPORT',
    'FOREX_BRANCH_ANALYTICS',
  ],
  BRANCH_USER_ACCESS_FEATURES: [
    'FOREX_BRANCH_APPLICATION_MANAGEMENT',
    'FOREX_BRANCH_USER_MANAGEMENT',
    'FOREX_BRANCH_CARD_SALE_MAKER',
    'FOREX_BRANCH_CARD_TRANSACTION',
    'FOREX_BRANCH_INVENTORY_MANAGEMENT',
    'FOREX_BRANCH_RELEASE_TRANSACTION',
    'FOREX_BRANCH_SERVICES',
    'FOREX_BRANCH_CORPORATE_MANAGEMENT',
    'FOREX_BRANCH_CORPORATE_TRANSACTION',
    'FOREX_BRANCH_REPORT',
    'FOREX_BRANCH_ANALYTICS',
  ],
  HQ_USER_ACCESS_FEATURES: [
    'FOREX_HQ_PROGRAM',
    'FOREX_HQ_PARTNER',
    'FOREX_HQ_BRANCH',
    'FOREX_HQ_ROLE',
    'FOREX_HQ_TEAM_MANAGEMENT_CHECKER',
    'FOREX_HQ_REPORT',
    'FOREX_HQ_ANALYTICS',
    'FOREX_HQ_POLICIES',
    'FOREX_HQ_PROGRAM_ALLOCATION',
    'FOREX_HQ_INSTITUTIONAL_RESOURCES',
    'FOREX_HQ_CARD_SALE_CHECKER',
    'FOREX_HQ_APPLICATION_MANAGEMENT',
    'FOREX_HQ_CARD_TRANSACTION',
    'FOREX_HQ_CORPORATE_TRANSACTION_CHECKER',
    'FOREX_HQ_INVENTORY_MANAGEMENT',
  ],
  PARTNER_HQ: [
    // 'FOREX_HQ_PROGRAM',
    // 'FOREX_HQ_PARTNER',
    'FOREX_HQ_BRANCH',
    'FOREX_HQ_ROLE',
    'FOREX_HQ_TEAM_MANAGEMENT_CHECKER',
    'FOREX_HQ_REPORT',
    'FOREX_HQ_ANALYTICS',
    'FOREX_HQ_POLICIES',
    'FOREX_HQ_PROGRAM_ALLOCATION',
    'FOREX_HQ_INSTITUTIONAL_RESOURCES',
    'FOREX_HQ_CARD_SALE_CHECKER',
    'FOREX_HQ_APPLICATION_MANAGEMENT',
    'FOREX_HQ_CARD_TRANSACTION',
    // 'FOREX_HQ_CORPORATE_TRANSACTION_CHECKER',
    'FOREX_HQ_INVENTORY_MANAGEMENT',
  ],
  CORPORATE_ADMIN_ACCESS_FEATURES: [
    'FOREX_CORPORATE_CARD_ANALYTICS',
    'FOREX_CORPORATE_CARD_TRANSACTION',
    'FOREX_CORPORATE_INVOICES',
    'FOREX_CORPORATE_APPLICATION_MANAGEMENT',
    'FOREX_CORPORATE_CARD_SALE',
    'FOREX_CORPORATE_REPORT',
    'FOREX_CORPORATE_ANALYTICS',
  ],
};
