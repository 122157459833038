import { Stack } from '@mui/material';
import {
  ComponentBody1Typo,
  PageHeadingTypo,
  SectionHeadingTypo,
} from '../../../../../../common/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TransactionDto } from '../../../../../../../types/transaction';
import { useCallback, useState } from 'react';
import TransactionOverview from './TransactionOverview';
import TransactionChargeback from './TransactionChargeback';
import TransactionCharges from './TransactionCharges';
import TransactionRefund from './TransactionRefund';
import { QUICK_ACCESS } from '../../../../../../../constants/style';
import SideNavigationMenuV1 from '../../../../../../common/SideNavigationMenuV1';

interface ITransactionDetailsProps {
  data: TransactionDto | undefined;
  goBack: () => void;
}

type MenuItemType = 'overview' | 'charges' | 'refund' | 'chargeback';

const TransactionDetails = ({ data, goBack }: ITransactionDetailsProps) => {
  const [menu, setMenu] = useState<MenuItemType>('overview');

  const menuItem = useCallback(() => {
    switch (menu) {
      case 'overview':
        return <TransactionOverview data={data} />;
      case 'chargeback':
        return <TransactionChargeback data={data} />;
      case 'charges':
        return <TransactionCharges data={data} />;
      default:
        return <TransactionRefund data={data} />;
    }
  }, [menu, data]);

  return (
    <SideNavigationMenuV1
      header={
        <Stack
          py={2}
          px={2.5}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          borderBottom={`1px solid ${QUICK_ACCESS.border[50]}`}
        >
          <Stack direction="row" gap={1.5} alignItems="center">
            <ArrowBackIcon
              fontSize="small"
              onClick={goBack}
              className="cursor-pointer"
            />
            <Stack direction="row" gap={1} alignItems="center">
              <PageHeadingTypo>Transaction ID -</PageHeadingTypo>
              <SectionHeadingTypo>{data?.txnProxyId}</SectionHeadingTypo>
            </Stack>
          </Stack>
          {data?.parentTransactionId && (
            <ComponentBody1Typo>
              Parent ID - {data?.parentTransactionId}
            </ComponentBody1Typo>
          )}
        </Stack>
      }
      menuItem={menuItem()}
      menu={menu}
      setMenu={setMenu}
      menuValues={MenuValues}
    />
  );
};

const MenuValues = [
  {
    value: 'Overview',
    key: 'overview',
  },
  { value: 'Charges & GST', key: 'charges' },
  { value: 'Refund', key: 'refund' },
  { value: 'Chargeback', key: 'chargeback' },
];

export default TransactionDetails;
