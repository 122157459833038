/** @format */

import { Slide, Snackbar, Stack } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import _ from 'lodash';
import React, { createContext, ReactNode, useContext } from 'react';
import { INDICATIVE, NEUTRALS } from '../constants/style';
import { getErrorMessageFromErrorObj } from '../utils/api';
import CloseIcon from '@mui/icons-material/Close';
import {
  ComponentBody1Typo,
  ComponentHeadingTypo,
} from '../components/common/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type Props = {
  children: ReactNode;
};

export type SnackbarContextType = {
  onClose?: () => void;
  setSnackbar: (content: string, type?: AlertColor) => void;
  setSnackbarError: (error: any) => void;
};

type SnackbarStateInterface = {
  isOpen: boolean;
  type: AlertColor;
  content: string;
  header?: string;
  addIcon?: boolean;
};

const SnackbarContext = createContext<SnackbarContextType | null>(null);
export const useSnackbar = () => {
  return useContext(SnackbarContext) as SnackbarContextType;
};

const getColors = (type: AlertColor) => {
  switch (type) {
    case 'success':
      return { text: INDICATIVE.green[600], bg: INDICATIVE.green[50] };
    case 'warning':
      return { text: INDICATIVE.yellow[600], bg: INDICATIVE.yellow[50] };
    case 'error':
      return { text: INDICATIVE.red[500], bg: INDICATIVE.red[50] };
    default:
      return { text: NEUTRALS.grey[400], bg: NEUTRALS.grey[50] };
  }
};

const SnackbarProvider = ({ children }: Props) => {
  const [snackbarState, setSnackbarState] =
    React.useState<SnackbarStateInterface>({
      isOpen: false,
      type: 'success',
      content: '',
      header: undefined,
      addIcon: false,
    });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const setSnackbar = (
    snackbarContent: string,
    snackbarType?: AlertColor,
    header?: string,
    addIcon?: boolean
  ) => {
    setSnackbarState({
      isOpen: true,
      type: snackbarType || 'success',
      content: snackbarContent,
      header,
      addIcon,
    });
  };

  //  Need to make VegaError Type
  const _setSnackbarError = (error: any) => {
    setSnackbarState({
      isOpen: true,
      type: 'error',
      content: _.startCase(_.toLower(getErrorMessageFromErrorObj(error))),
      header: undefined,
      addIcon: false,
    });
  };

  const { isOpen, type, content, header, addIcon } = snackbarState;
  const { text, bg } = getColors(type);
  return (
    <>
      <SnackbarContext.Provider
        value={{ setSnackbar, setSnackbarError: _setSnackbarError }}
      >
        {children}
      </SnackbarContext.Provider>

      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={Slide}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          zIndex: 100000,
        }}
      >
        <Stack
          px={3}
          py={1.5}
          direction="row"
          alignItems="center"
          gap={5}
          sx={{ background: bg, border: `1px solid ${text}` }}
          borderRadius={'8px'}
        >
          <Stack gap={1.5} direction="row" alignItems="center">
            {addIcon && <ErrorOutlineIcon sx={{ color: text }} />}
            <Stack gap={0.5}>
              {header && (
                <ComponentHeadingTypo sx={{ color: text }}>
                  {header}
                </ComponentHeadingTypo>
              )}
              <ComponentBody1Typo sx={{ color: text }}>
                {content}
              </ComponentBody1Typo>
            </Stack>
          </Stack>
          <CloseIcon
            sx={{ color: text }}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </Stack>
      </Snackbar>
    </>
  );
};

export default SnackbarProvider;
