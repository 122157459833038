import { useQuery } from 'react-query';
import { QUERY_NAMES } from '../../enums/commons';
import {
  fetchLoanFromLoanId,
  listInstallmentsForAccount,
} from '../../api/installment';
import { CommonQuery } from '../../types/commonType';
import { LoanStatus } from '../../types/transaction';
import useDebounce from '../../hooks/useDebounce';

export const useInstallmentListQuery = ({
  accountId,
  pageNumber,
  pageSize,
  enabled,
  statusList,
  endDate,
  startDate,
  search,
}: {
  accountId: string;
  statusList?: LoanStatus[];
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(search, 500);

  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.INSTALLMENT_LIST,
      accountId,
      statusList,
      { pageSize, pageNumber, debouncedSearch, endDate, startDate },
    ],
    queryFn: () => {
      return listInstallmentsForAccount({
        accountId,
        page: pageNumber || 0,
        pageSize: pageSize || 5,
        startDate,
        endDate,
        q: debouncedSearch,
        statusList,
      });
    },
    enabled: enabled && Boolean(accountId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
  });

  return { data: data?.data, ...rest };
};

export const useLoanQuery = ({
  loanId,
  enabled,
}: {
  loanId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.LOAN_FROM_LOAN_ID, loanId],
    queryFn: () => {
      return fetchLoanFromLoanId({
        loanId,
      });
    },
    enabled: enabled && Boolean(loanId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
  });

  return { data: data?.data, ...rest };
};
