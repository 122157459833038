import { Box, BoxProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { SectionHeading2Typo } from '../Typography';
import { INDICATIVE, QUICK_ACCESS } from '../../../constants/style';

interface IVegaContainerV4Props extends PropsWithChildren, BoxProps {
  title: string;
}

const VegaContainerV4 = ({
  title,
  children,
  sx,
  ...rest
}: IVegaContainerV4Props) => {
  return (
    <Box
      sx={{
        border: `1px solid ${QUICK_ACCESS.border[50]}`,
        backgroundColor: QUICK_ACCESS.background[50],
        borderRadius: '8px',
        overflow: 'hidden',
        ...sx,
      }}
      {...rest}
    >
      <Box
        py={2}
        px={3}
        sx={{
          backgroundColor: INDICATIVE.blue[50],
          borderBottom: `1px solid ${QUICK_ACCESS.border[50]}`,
        }}
      >
        <SectionHeading2Typo>{title}</SectionHeading2Typo>
      </Box>
      <Box p={3}>{children}</Box>
    </Box>
  );
};

export default VegaContainerV4;
