import { Box, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import * as React from 'react';
import { PRIMARY } from '../../constants/style';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: PRIMARY.light,
    },
  }));


export const VegaToolTipAndCopy = ({value , title = "Customer Id"} : {
    value : string ,
    title? : string
} ) => {
  const [copied, setCopied] = React.useState(false);

  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        console.log("copied-in", copied);
        setTimeout(()=>{
            setCopied(false)
        } ,1000)
      }}
    >
      <LightTooltip title={!copied ? "Copy " + title  : "Copied : " + value }
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      >
        <Box sx={{
            "::selection" : {
                // color : 'red',
                // bgcolor : 'blue'
            }

        }}
        style={{
            MozUserSelect : 'all',
            WebkitUserSelect: "all",
            msUserSelect: 'text'
        }}
        >{value}</Box>
      </LightTooltip>
    </Box>
  );
};