import Stack from '@mui/material/Stack';
import VegaWrapperType1 from '../common/VegaWrapperType1';
import VegaDataTileV4 from '../common/v4/VegaDataTileV4';
import { VegaClientInterface } from '../../types/client';

type ViewCompanyProfileProps = {
  state: VegaClientInterface | null;
};

function ViewCompanyProfile({ state }: ViewCompanyProfileProps) {
  return (
    <VegaWrapperType1 header="Company Details">
      <Stack direction="row" flexWrap="wrap" rowGap={3} gap={3}>
        <VegaDataTileV4
          title="Name"
          value={state?.clientName ?? '-'}
          sx={{
            width: 180,
          }}
        />
        <VegaDataTileV4
          title="Email"
          value={state?.clientEmail ?? '-'}
          sx={{
            width: 180,
          }}
        />
        <VegaDataTileV4
          title="GST Number"
          value={state?.gstNumber ?? '-'}
          sx={{
            width: 180,
          }}
        />
        <VegaDataTileV4
          title="PAN Number"
          value={state?.panNumber ?? '-'}
          sx={{
            width: 180,
          }}
        />
        <VegaDataTileV4
          title="Address"
          value={state?.address ?? '-'}
          sx={{
            width: '100%',
          }}
        />
      </Stack>
    </VegaWrapperType1>
  );
}

export default ViewCompanyProfile;
