import { useQuery } from 'react-query';
import { getApplicationListData } from '../../api/applications';
import useDebounce from '../../hooks/useDebounce';
import { QUERY_NAMES } from '../../enums/commons';

export const useApplicationListDataQuery = ({
  searchParameter,
  page,
  pageSize,
  enabled,
  debDelay,
}: {
  searchParameter: string;
  page?: number;
  pageSize?: number;
  enabled?: boolean;
  debDelay?: number;
}) => {
  const debouncedSearch = useDebounce<string>(searchParameter, debDelay || 500);

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_NAMES.APPLICATIONS, { page, pageSize, debouncedSearch }],
    queryFn: () =>
      getApplicationListData({
        searchParameter: debouncedSearch,
        page,
        pageSize,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled,
  });

  return { data: data?.data, ...rest };
};
