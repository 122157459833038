import { Box, Stack } from '@mui/material';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { useEffect, useMemo, useState } from 'react';
import VegaTableV4, { ITableDataProps } from '../../common/v4/VegaTableV4';
import { VegaCreateCustomersType } from '../../../types/customers';
import { useCustomerContext } from './CustomerWrap';
import CustomerViewAuditDialog from './CustomerViewAuditDialog';
import CustomerAccountCreationDrawer from './CustomerAccountCreationDrawer';
import VegaNoResults from '../../common/VegaNoResults';
import { useCustomerListQuery } from '../../../store/queries/customerQueries';
import VegaDetailsDialogV4 from '../../common/v4/VegaDetailsDialogV4';
import {
  SearchOptions,
  getCustomerDetailsDialogData,
  searchByOptionsForCustomer,
} from '../simulatorUtils';
import AddSquare02Icon from '../../icon/AddSquare02Icon';
import VegaSelectV4 from '../../common/v4/VegaSelectV4';

const CustomerListPage = () => {
  const { setCurrState } = useCustomerContext();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [selectedCustomer, setSelectedCustomer] = useState<
    VegaCreateCustomersType | undefined
  >(undefined);
  const [openAuditDialog, setOpenAuditDialog] = useState(false);
  const [openCustomerDetailsDialog, setOpenCustomerDetailsDialog] =
    useState(false);
  const [openCreateAccountDrawer, setOpenCreateAccountDrawer] = useState(false);
  const { selectedProgram } = useProgramData();
  const [activeSearchBy, setActiveSearchBy] = useState<SearchOptions>(
    searchByOptionsForCustomer[0].id
  );
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const { data, isFetching } = useCustomerListQuery({
    programId: selectedProgram?.programId as string,
    clientId: selectedProgram?.clientId as string,
    pageNumber: page,
    pageSize,
    allAccounts: false,
    searchBy: searchValue ? activeSearchBy : undefined,
    search: searchValue,
  });

  const records = data?.records;

  const onAuditOpen = (rec: VegaCreateCustomersType) => {
    setSelectedCustomer(rec);
    setOpenAuditDialog(true);
  };

  const onAuditClose = () => {
    setOpenAuditDialog(false);
  };

  const onCustomerDetailsOpen = (rec: VegaCreateCustomersType) => {
    setSelectedCustomer(rec);
    setOpenCustomerDetailsDialog(true);
  };

  const onCustomerDetailsClose = () => {
    setOpenCustomerDetailsDialog(false);
  };

  const onCreateActionsOpen = (rec: VegaCreateCustomersType) => {
    setSelectedCustomer(rec);
    setOpenCreateAccountDrawer(true);
  };

  const onCreateActionsClose = () => {
    setOpenCreateAccountDrawer(false);
  };

  const go2CreateCustomerPage = () => {
    setCurrState?.('create-customer');
  };

  const structuredData: ITableDataProps[] = useMemo(() => {
    return getStructuredData(
      records,
      onAuditOpen,
      onCustomerDetailsOpen,
      onCreateActionsOpen
    );
  }, [records]);

  useEffect(() => {
    setPage(0);
  }, [searchValue, activeSearchBy]);

  return (
    <Box>
      <Box paddingTop={4}>
        <VegaHeaderV4
          title="Customers"
          actionBtnProps={{
            variant: 'contained',
            startIcon: <AddSquare02Icon />,
            value: 'Create Customer',
            onClick: go2CreateCustomerPage,
          }}
        />
      </Box>
      <Stack p={3} gap={2}>
        <Stack justifyContent="flex-end" direction="row">
          <Stack direction={'row'} gap={1}>
            <VegaSelectV4
              options={searchByOptionsForCustomer}
              value={activeSearchBy}
              onChange={(e) => {
                setActiveSearchBy(e.target.value as SearchOptions);
                setSearchValue('');
              }}
              wrapperProps={{
                flex: 0.7,
              }}
              standardWidth={140}
            />
            <VegaSearchfieldV4
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value as string)}
            />
          </Stack>
        </Stack>
        {Boolean(records?.length) || isFetching ? (
          <VegaTableV4
            head={[
              'Customer Name',
              'Mobile Number',
              'Email ID',
              'ID Number',
              'Audit Log',
              'Customer Details',
              'Actions',
            ]}
            data={structuredData}
            isLoading={isFetching}
            pagination={{
              page,
              setPage,
              rowsPerPage: pageSize,
              setRowsPerPage: setPageSize,
              totalRecords: data?.totalItems || 0,
            }}
          />
        ) : (
          <VegaNoResults errorText="No Customers Found" />
        )}
      </Stack>
      <VegaDetailsDialogV4
        data={getCustomerDetailsDialogData(selectedCustomer)}
        dialogProps={{
          title: 'Customer Details',
          open: openCustomerDetailsDialog,
          onClose: onCustomerDetailsClose,
        }}
      />
      <CustomerViewAuditDialog
        open={openAuditDialog}
        onClose={onAuditClose}
        customerId={selectedCustomer?.customerId}
      />
      <CustomerAccountCreationDrawer
        open={openCreateAccountDrawer}
        onClose={onCreateActionsClose}
        selectedCustomer={selectedCustomer}
      />
    </Box>
  );
};

function getStructuredData(
  records: VegaCreateCustomersType[] | undefined,
  onAuditClick: Function,
  onCustomerDetailsClick: Function,
  onCreateActionsClick: Function
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: (rec.firstName || '') + ' ' + (rec.lastName || ''),
            type: 'string',
          },
          {
            value: rec.mobileNumber,
            type: 'string',
          },
          {
            value: rec.emailId,
            type: 'string',
          },
          {
            value: rec.customerId,
            type: 'string',
          },
          {
            value: 'View',
            type: 'link',
            linkOnClick: onAuditClick,
          },
          {
            value: 'View',
            type: 'link',
            linkOnClick: onCustomerDetailsClick,
          },
          {
            value: rec.account ? 'Account Created' : 'Create Account',
            type: rec.account ? 'status' : 'link',
            statusColor: 'green',
            linkOnClick: rec.account ? undefined : onCreateActionsClick,
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export default CustomerListPage;
