import axios, { AxiosResponse } from 'axios';
import { LOS, BASE_URL, PROGRAM } from './endpoints';
import { ApplicationsResponse } from '../types/applications';
import { PaginatedResponse } from '../utils/api';
import { VegaLosLimitOffer } from '../types/los';
import { CUSTOMER } from '../mcc/Back-office/api/endpoints';
export interface DownloadPdf {
  applicationId: string;
  consentType: string;
  documentUrl: string;
}
export function getApplicationListData({
  searchParameter,
  page,
  pageSize,
}: {
  searchParameter: string | undefined;
  page?: number;
  pageSize?: number;
}): Promise<AxiosResponse<PaginatedResponse<ApplicationsResponse>>> {
  const url =
    BASE_URL +
    LOS.BASE_V2 +
    LOS.APPLICATION +
    PROGRAM.LIST +
    CUSTOMER.BASE +
    LOS.UNMASKED;
  return axios.get(url, {
    params: {
      searchParameter,
      page,
      pageSize,
    },
  });
}
export async function getOfferByApplicationId(
  applicationID: string
): Promise<AxiosResponse<VegaLosLimitOffer>> {
  const url = BASE_URL + LOS.BASE + LOS.LIMIT_OFFER;
  const data = await axios.get(url, {
    params: {
      applicationId: applicationID,
    },
  });
  return data;
}

export async function downloadPdf(
  consentType: string,
  applicationId: string
): Promise<AxiosResponse<DownloadPdf>> {
  const url =
    BASE_URL + LOS.BASE_V2 + LOS.APPLICATION + LOS.CONSENT + LOS.DOWNLOAD;
  const data = axios.get(url, {
    params: {
      applicationId,
      consentType,
    },
  });
  return data;
}
