import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { VegaTextField } from '../../../../components/common';
import BranchSelector from '../../../components/BranchSelector';
import { TransferType } from '../../../../enums/commons';
import { BinAllocation } from '../../../../types/card';
import {
  IPaginationProps,
  fetchBinAlloctionListForClient,
} from '../../api/inventory';
import { useClientAuth } from '../../../../providers/ClientProvider';

interface IProps {
  showData: any;
  handleChange: (name: string, value: any) => void;
  isShow: boolean;
  setBranchName: React.Dispatch<React.SetStateAction<string>>;
  transferType?: TransferType;
}

const StockTransfer = ({
  showData,
  handleChange,
  isShow,
  setBranchName,
  transferType = TransferType.OUTBOUND,
}: IProps) => {
  const { clientId } = useClientAuth();

  const [availableKits, setAvailableKits] = useState(0);
  const [allocatedBinsForClient, setAllocatedBinsForClient] = useState<
    BinAllocation[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  const onPageChange = (page: number) => {
    setPage(page);
  };
  const onPageSizeChange = (pageSize: number) => {
    setSize(pageSize);
  };

  const getBinsForClient = async () => {
    const data = {
      clientId,
      page,
      size,
    } as IPaginationProps;
    fetchBinAlloctionListForClient(data).then(async (res) => {
      const allocatedBins: BinAllocation[] = res.data;

      setAllocatedBinsForClient(allocatedBins);
    });
  };

  const handleBranch = (value: any) => {
    console.log('vhandleBranchalue', value);
    setBranchName(value.branchName);
    handleChange('destinationEntityId', value.branchId);

    allocatedBinsForClient.some((bin) => {
      if (bin.entityId === value.branchId && bin.binId === showData.binId) {
        setAvailableKits(bin.unallocatedKits);
        return true;
      }
    });
  };

  useEffect(() => {
    clientId && getBinsForClient();
  }, [clientId, page, size]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} mt={2}>
          <VegaTextField
            value={showData.numberOfKits}
            onChange={(e) =>
              handleChange(
                'numberOfKits',
                e.target.value && parseFloat(e.target.value)
              )
            }
            key="numberOfKits"
            label="Number Of Kit"
            type={'number'}
            placeholder="Number Of Kit"
            disabled={isShow}
          />
        </Grid>
        <Grid item xs={12} sm={12} mt={2}>
          <BranchSelector
            label="Destination Branch"
            name="destinationEntityId"
            selected={showData.destinationEntityId ?? ''}
            handleChange={(value) => handleBranch(value)}
            disabled={isShow}
          />
        </Grid>
      </Grid>
      {transferType === TransferType.INBOUND &&
        showData.destinationEntityId && (
          <Box mt={1}>
            <Typography variant="caption">
              {`${availableKits} kit${
                availableKits > 1 ? 's are' : ' is'
              } available`}
            </Typography>
          </Box>
        )}
    </Box>
  );
};

export default StockTransfer;
