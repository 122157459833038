import { useEffect, useState } from 'react';

export const useKeyValHook = <T, H>(keyVals: T, onUpdate: () => void) => {
  const [obj, setObj] = useState<T>(keyVals);

  const updateValue = (key: H, value: any) => {
    setObj((prev) => ({
      ...prev,
      [key as string]: value,
    }));
  };

  useEffect(() => {
    onUpdate();
  }, [obj]);

  return { updateValue, values: obj };
};
