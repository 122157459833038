/** @format */

import { ArrowDropDownCircleOutlined } from '@mui/icons-material';
import {
  Box,
  MenuItem,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { toLowerCase } from '../../constants/commonFunction';
import { COMMON, PRIMARY } from '../../constants/style';
import VegaHeader from './VegaHeader';

export type VegaSelectSelectOptionType = {
  id: string;
  name: string;
  icon?: JSX.Element;
};

type Props = {
  options: string[] | VegaSelectSelectOptionType[];
  selected: string | VegaSelectSelectOptionType;
  onChange: (selected: string) => void;
  label?: string;
  className?: string;
  placeholder?: string;
  textFieldWidth?: number;
  disabled?: boolean;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
  variant?: 'standard' | 'filled' | 'outlined';
  maxPopperHeight?: number;
  fontSize?: number;
  darkLabel?: boolean;
  onScroll?: any;
  focused?: boolean;
  isCurrency?: boolean;
  startAdornment?: any;
  error?: boolean;
  isFixedHeight?: boolean;
  errorHelperText?: string;
  headerText?: string;
  name?: any;
  errorText?: any;
};

const VegaSelect = ({
  options,
  selected,
  onChange,
  className,
  label,
  placeholder,
  textFieldWidth,
  disabled,
  size,
  sx,
  variant,
  maxPopperHeight,
  darkLabel,
  isCurrency,
  onScroll,
  focused = false,
  startAdornment,
  error = false,
  errorHelperText,
  isFixedHeight,
  headerText,
  fontSize,
  errorText,
  name,
}: Props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      {headerText?.length && <VegaHeader text={headerText} />}
      {/* <div id="select-menu-container" /> */}

      <TextField
        error={error}
        helperText={errorHelperText}
        select
        focused={focused}
        label={label}
        value={
          typeof selected === 'string' || typeof selected === 'number'
            ? selected
            : selected?.id
        }
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        defaultValue={label}
        fullWidth={!textFieldWidth}
        disabled={disabled}
        SelectProps={{
          IconComponent: ArrowDropDownCircleOutlined,
          MenuProps: {
            PaperProps: {
              sx: { maxHeight: maxPopperHeight || 300 },
              onScroll: onScroll ?? '',
            },
            container: document.querySelector('#select-menu-container'),
          },
          sx: {
            '& .MuiSvgIcon-root': {
              fontSize: '1.2rem',
              color: '#343434',
            },
          },
        }}
        // InputLabelProps={{
        //   style: {
        //     color: darkLabel ? PRIMARY.main : '#AFAFAF',
        //     textAlign: 'center',
        //     transform: focused ? '' : 'translateY(55%)',
        //     marginLeft: focused ? 0 : 12,
        //   },
        // }}
        InputProps={
          isFixedHeight
            ? {
                style: {
                  borderRadius: '9px',
                  height: '35px',
                },
                startAdornment: startAdornment,
              }
            : {
                style: {
                  borderRadius: '9px',
                  height: '50px',
                },
                startAdornment: startAdornment,
              }
        }
        size={size || 'small'}
        sx={{
          '& .MuiInputBase-input, .MuiInputLabel-root': {
            fontFamily: `var(--font-Aspekta-450) !important`,
            fontSize: '13px',
            lineHeight: '120%',
            // color: '#1B1D22',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '6px',
            '&.Mui-focused fieldset': {
              borderColor: '#C5C8D0',
              borderWidth: '1px',
              borderRadius: '6px',
            },
            ':hover fieldset': {
              borderColor: '#C5C8D0',
            },
          },
          '& .MuiInputLabel-root': {
            transform: 'translate(14px, 13px) scale(1)',
            // fontFamily: 'Aspekta-450 !important',
            // fontSize: 13,
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
          ...(textFieldWidth && { width: textFieldWidth }),
          '& .MuiFormLabel-root': {
            '&.Mui-focused': {
              color: `${PRIMARY.main} !important`,
            },
          },

          ...sx,
        }}
        variant={variant}
      >
        {options.map((option, index) => {
          return (
            <MenuItem
              className={'font-aspekta-500'}
              key={index}
              value={typeof option === 'string' ? option : option.id}
              sx={{
                ...(textFieldWidth && { width: textFieldWidth }),
                whiteSpace: 'normal',
                textAlign: 'center',
              }}
            >
              <Stack
                className={'font-aspekta-500'}
                sx={{
                  fontSize: 12,
                }}
                direction={'row'}
                gap={2}
                fontSize={fontSize}
                color={COMMON.gray}
                alignItems="center"
              >
                {typeof option !== 'string' && option.icon && option.icon}
                {isCurrency
                  ? typeof option === 'string'
                    ? option
                    : option.name
                  : typeof option === 'string'
                  ? toLowerCase(option)
                  : toLowerCase(option.name)}
              </Stack>
            </MenuItem>
          );
        })}
      </TextField>
      {errorText && (
        <Typography
          className="font-aspekta-500"
          color={'red'}
          fontSize={13}
          sx={{ position: 'absolute', right: 0 }}
        >
          {errorText[name]}
        </Typography>
      )}
    </Box>
  );
};

export default VegaSelect;
