export interface UserBasicInfo {
  name: string;
  mobileNumber: string;
  emailId: string;
  designation: string | null;
}
export interface UsersData {
  userBasicInfo: UserBasicInfo;
  panNumber: string;
  aadharNumber: string;
  companyId: string;
  userKycStatus: string;
  kycCompletionTimestamp: string;
  promoter: string;
}
export interface ApplicationsResponse {
  id: string;
  programId: string;
  programName: string;
  programCategoryType: string;
  customerId: string;
  status: ApplicationStatus;
  users: UsersData[];
  completedStates: ApplicationCompletedStates[];
  applicationPreDispersed: string;
  sanctionLetterAccepted: string;
  phone: string;
  updatedAt: string;
}

export enum ApplicationStatus {
  UNDER_REVIEW = 'UNDER_REVIEW',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  PRE_DISPERSED = 'PRE_DISPERSED',
  READY_FOR_DISPERSAL = 'READY_FOR_DISPERSAL',
  DISPERSED = 'DISPERSED',
  COMPLETED = 'COMPLETED',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export enum ApplicationCompletedStates {
  AADHAAR_VALIDATION = 'AADHAAR_VALIDATION',
  PAN_VALIDATION = 'PAN_VALIDATION',
  SELFIE_VERIFICATION = 'SELFIE_VERIFICATION',
  VIDEO_KYC = 'VIDEO_KYC',
  APPLICATION_FORM = 'APPLICATION_FORM',
  AGREEMENT = 'AGREEMENT',
  CARD_FORM = 'CARD_FORM',
}
