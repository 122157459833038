import { Box } from '@mui/material';
import { NEW_PRIMARY } from '../../../constants/style';
import { useSupport } from '../../../providers/SupportProvider';
import Vega_Tab from '../../common/v3/Vega_Tab';
import NoDataFound from './NoUserFound';
import SupportAccountDetails from './components/SupportAccountDetails';
import SupportUserAuditDetails from './components/SupportUserAuditDetails';
import SupportApplcaitionKycContainer from './components/SupportApplcaitionKycContainer/SupportApplcaitionKycContainer';
import ApplicationsProvider from '../../../providers/ApplicationsProvider';
import { SupportStep, supportStepOptions } from '../Support/SupportSearch';

const SupportRightContainer = () => {
  const { filteredCustomer, selected, setSelected, loading } = useSupport();
  return (
    <>
      <Vega_Tab
        tabData={supportStepOptions}
        selected={selected}
        onSelect={(value) => setSelected(value)}
        color={NEW_PRIMARY.BLUE}
      />

      <Box mt={1} height={'calc(100% - 36px)'}>
        {selected === SupportStep.APPLICATIONS ||
        selected === SupportStep.KYC ? (
          <ApplicationsProvider>
            <SupportApplcaitionKycContainer selected={selected} />
          </ApplicationsProvider>
        ) : selected === SupportStep.USER_AUDIT ? (
          <SupportUserAuditDetails />
        ) : !filteredCustomer?.account ? (
          <NoDataFound text="No Account exist" />
        ) : (
          <SupportAccountDetails />
        )}
      </Box>
    </>
  );
};

export default SupportRightContainer;
