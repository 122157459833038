import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import VegaDialogV4 from '../../../common/v4/VegaDialogV4';
import { ListGroupHeadingTypo } from '../../../common/Typography';
import { TransactionIndicator } from '../../../../enums/transaction';

interface IBlockByActionDialogProps {
  open: boolean;
  setOpenFalse: () => void;
}

const BlockByActionDialog = ({
  open,
  setOpenFalse,
}: IBlockByActionDialogProps) => {
  return (
    <VegaDialogV4
      open={open}
      title="Block By Category"
      onClose={setOpenFalse}
      actionBtnSecondaryText={'Submit'}
      closeBtnRequired
      actionBtnSecondaryProps={{
        onClick: setOpenFalse,
      }}
    >
      <Stack sx={{ padding: '24px 40px' }} gap={1}>
        <ListGroupHeadingTypo>Block By -</ListGroupHeadingTypo>
        <FormGroup sx={{ width: 'fit-content' }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                value={TransactionIndicator.CREDIT}
                sx={{
                  padding: '4px 9px',
                  '&:not(.Mui-checked)': {
                    color: 'darkgray',
                  },
                }}
              />
            }
            label={'Credit'}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                value={TransactionIndicator.DEBIT}
                sx={{
                  padding: '4px 9px',
                  '&:not(.Mui-checked)': {
                    color: 'darkgray',
                  },
                }}
              />
            }
            label={'Debit'}
          />
        </FormGroup>
      </Stack>
    </VegaDialogV4>
  );
};

export default BlockByActionDialog;
