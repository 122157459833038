import React from 'react';
import VegaWrapperType1 from '../common/VegaWrapperType1';
import Stack from '@mui/material/Stack';
import UploadMediaLogoContainer, {
  UploadLogoType,
} from './UploadMediaLogoContainer';
import { VegaClientInterface } from '../../types/client';

type MoreDetailsProps = {
  client: VegaClientInterface | null;
  onUpload: (logoType: UploadLogoType, file: File) => void;
};

function MediaDetails({ client, onUpload }: MoreDetailsProps) {
  return (
    <VegaWrapperType1 header="Media Details">
      <Stack direction="row" gap={3} flexWrap="wrap">
        <UploadMediaLogoContainer logotype="Favicon" onUpload={onUpload} />
        <UploadMediaLogoContainer
          logotype="LargeLogo"
          onUpload={onUpload}
          fileUrl={client?.logo}
        />
        <UploadMediaLogoContainer logotype="SmallLogo" onUpload={onUpload} />
        <UploadMediaLogoContainer
          logotype="IOSLogo"
          fileUrl={client?.mobileAppLogo}
          onUpload={onUpload}
        />
      </Stack>
    </VegaWrapperType1>
  );
}

export default MediaDetails;
