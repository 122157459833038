import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Grid from '@mui/material/Grid';
import DueDateCalendar from '../DueDateCalendar';
import { useAppSelector } from '../../../store/hook';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getSettlementCalendar } from '../../../api/program';
import { VegaSettlementCalendarDate } from '../../../types/program';
import { DateUtility } from '../../../utils/dateTime';
import VegaSelectV2, { VegaSelectOptionV2 } from '../../common/v2/VegaSelectV2';
import VegaTextV2 from '../../common/v2/VegaTextV2';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import {
  ComponentBody1Typo,
  ListHead2Typo,
  SectionHeadingTypo,
} from '../../common/Typography';
import VegaAccordionV4, {
  VegaAccordionSummary,
  VegaAccordionDetails,
} from '../../common/v4/VegaAccordionV4';
import VegaSelectV4, {
  VegaSelectOptionType,
} from '../../common/v4/VegaSelectV4';
import { QUICK_ACCESS } from '../../../constants/style';

type Props = {};

const ChangedDateListItem = (data: {
  key: string;
  description: string;
  fromDate: string;
  toDate: string;
}) => {
  return (
    <Box
      key={data.key}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <ListHead2Typo
          sx={{
            color: QUICK_ACCESS.text[200],
          }}
        >
          {data.fromDate}
        </ListHead2Typo>
        <ArrowForwardRoundedIcon color="disabled" fontSize="small" />
        <ListHead2Typo
          sx={{
            color: QUICK_ACCESS.text[200],
          }}
        >
          {data.toDate}
        </ListHead2Typo>
      </Box>
      <ListHead2Typo sx={{ color: QUICK_ACCESS.text[100] }}>
        {data.description}
      </ListHead2Typo>
    </Box>
  );
};

function mapSettlementCalendarToEffectiveDates(
  settlementCalendarDate: VegaSettlementCalendarDate[]
) {
  const selectedDatesMap = new Map<number, number[]>();
  const changedDatesMap = new Map<number, VegaSettlementCalendarDate[]>();
  settlementCalendarDate.forEach((item) => {
    const currentMonth = DateUtility.monthNameToNumber(item.effectiveMonth);
    const actualDate = item.actualDay;
    const actualMonth = item.actualMonth;
    const effectiveDate = item.effectiveDay;
    const effectiveMonth = item.effectiveMonth;
    const existingDates = selectedDatesMap.get(currentMonth) ?? [];
    existingDates.push(item.effectiveDay);
    selectedDatesMap.set(currentMonth, existingDates);

    if (actualDate !== effectiveDate || actualMonth !== effectiveMonth) {
      const existingChangedDates = changedDatesMap.get(currentMonth) ?? [];
      existingChangedDates.push(item);
      changedDatesMap.set(currentMonth, existingChangedDates);
    }
  });

  return { selectedDates: selectedDatesMap, changedDates: changedDatesMap };
}

const selectOptions = [...Array(12)].map((_, index) => {
  const item = `${index + 1}`;
  const option: VegaSelectOptionType = {
    id: item.toString(),
    name: item,
  };
  return option;
});

function FinalSettlementCalendar({}: Props) {
  const { setSnackbar } = useSnackbar();
  const { selectedProgram } = useAppSelector(
    (state) => state.programManagement
  );
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedDates, setSelectedDates] = useState(
    new Map<number, number[]>()
  );
  const [changedDates, setChangedDates] = useState(
    new Map<number, VegaSettlementCalendarDate[]>()
  );

  useEffect(() => {
    if (!selectedProgram) return;
    getSettlementCalendar({
      programId: selectedProgram.programId,
      financialYear: new Date().getFullYear(),
    })
      .then((res) => {
        const { selectedDates, changedDates } =
          mapSettlementCalendarToEffectiveDates(res);
        setSelectedDates(selectedDates);
        setChangedDates(changedDates);
      })
      .catch((err) => {
        setSnackbar('Failed to fetch settlement calendar', 'error');
      });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 3,
        pb: 2,
      }}
    >
      <Typography className="font-aspekta-600" fontSize={17}>
        Payment Due Date
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <VegaAccordionV4 expandable={false}>
            <VegaAccordionSummary
              sx={{
                '& .MuiAccordionSummary-content': {
                  justifyContent: 'space-between',
                },
              }}
            >
              <SectionHeadingTypo>Due Dates</SectionHeadingTypo>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                }}
              >
                <VegaSelectV4
                  options={selectOptions}
                  value={selectedMonth.toString()}
                  onChange={(e) => {
                    setSelectedMonth(Number(e.target.value));
                  }}
                  wrapperProps={{
                    sx: { width: 'initial' },
                  }}
                />
                <ComponentBody1Typo
                  sx={{
                    fontWeight: 450,
                    fontSize: 13,
                  }}
                >
                  {new Date().getFullYear()}
                </ComponentBody1Typo>
              </Box>
            </VegaAccordionSummary>
            <VegaAccordionDetails>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <DueDateCalendar
                  selectDate={selectedDates.get(selectedMonth) ?? []}
                  month={selectedMonth}
                />
              </Box>
            </VegaAccordionDetails>
          </VegaAccordionV4>
        </Grid>
        <Grid item xs={12} lg={4}>
          <VegaAccordionV4
            expandable={false}
            sx={{
              height: '100%',
            }}
          >
            <VegaAccordionSummary>
              <SectionHeadingTypo>Due Dates</SectionHeadingTypo>
            </VegaAccordionSummary>
            <VegaAccordionDetails sx={{ height: '100%' }}>
              {changedDates.get(selectedMonth) ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <ul
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      paddingInlineStart: 16,
                    }}
                  >
                    {changedDates.get(selectedMonth)?.map((item, index) => (
                      <li key={index}>
                        <ChangedDateListItem
                          key={item.id}
                          fromDate={`${item.actualDay} ${startCase(
                            toLower(item.actualMonth)
                          )}`}
                          toDate={`${item.effectiveDay} ${startCase(
                            toLower(item.effectiveMonth)
                          )}`}
                          description={item.reason ? `(${item.reason})` : '-'}
                        />
                      </li>
                    ))}
                  </ul>
                </Box>
              ) : (
                <Box>
                  <ListHead2Typo
                    sx={{
                      color: QUICK_ACCESS.text[75],
                    }}
                  >
                    No Due Dates Present
                  </ListHead2Typo>
                </Box>
              )}
            </VegaAccordionDetails>
          </VegaAccordionV4>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FinalSettlementCalendar;
