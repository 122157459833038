import { useState } from 'react';
import VegaDrawerV4 from '../common/v4/VegaDrawerV4';
import Stack from '@mui/material/Stack';
import { ListGroupHeadingTypo } from '../common/Typography';
import { QUICK_ACCESS } from '../../constants/style';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import VegaDatePickerV4 from '../common/v4/VegaDatePickerV4';
import VegaRadio from '../common/VegaRadio';
dayjs.extend(utc);

type LoginHistoryFilterProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (value?: string, startDate?: Dayjs, endDate?: Dayjs) => void;
};

const options = [
  { value: 'past7', label: 'Past 7 days' },
  { value: 'past14', label: 'Past 14 days' },
  { value: 'custom', label: 'Custom' },
];

function LoginHistoryFilter({
  open,
  onClose,
  onSubmit,
}: LoginHistoryFilterProps) {
  const [selectedValue, setSelectedValue] = useState(options[0].value);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const isCustom = selectedValue === 'custom';

  const onSubmitClick = () => {
    if (isCustom && startDate && endDate) {
      onSubmit('custom', startDate, endDate);
    } else {
      onSubmit(selectedValue);
    }
  };

  return (
    <VegaDrawerV4
      open={open}
      PaperProps={{
        sx: {
          width: '480px',
        },
      }}
      header="Filter by"
      onClose={onClose}
      submitBtn={{
        show: true,
        props: {
          variant: 'contained',
          value: 'Submit',
          onClick: onSubmitClick,
          disabled: isCustom && (!startDate || !endDate),
        },
      }}
      secondaryBtn={{
        props: {
          value: 'Clear All',
        },
      }}
    >
      <Stack gap={3}>
        <Stack>
          <ListGroupHeadingTypo
            sx={{
              color: QUICK_ACCESS.text[75],
            }}
          >
            Day
          </ListGroupHeadingTypo>
          <Stack>
            <VegaRadio
              name="filter-date"
              value={selectedValue}
              radioData={options}
              onChange={(e: any) => setSelectedValue(e.target.value)}
              labelPlacement="end"
              isRow={false}
              color={QUICK_ACCESS.primary.blue}
            />
          </Stack>
        </Stack>
        {isCustom && (
          <Stack gap={2}>
            <ListGroupHeadingTypo
              sx={{
                color: QUICK_ACCESS.text[75],
              }}
            >
              Choose Date Range
            </ListGroupHeadingTypo>
            <Stack direction="row" gap={1.5}>
              <VegaDatePickerV4
                dateProps={{
                  maxDate: endDate,
                  value: startDate,
                  onChange: (e) => {
                    setStartDate(e as Dayjs);
                  },
                }}
                textProps={{
                  error: false,
                  placeholder: 'Start Date',
                  sx: {
                    '& .MuiInputBase-input': {
                      fontFamily: `Aspekta !important`,
                      fontWeight: 450,
                      fontSize: '12px',
                      lineHeight: '120%',
                      color: QUICK_ACCESS.text[200],
                      padding: 1.5,
                      paddingRight: 0,
                    },
                  },
                  standardWidth: 'auto',
                }}
              />
              <VegaDatePickerV4
                dateProps={{
                  value: endDate,
                  onChange: (e) => {
                    setEndDate(e as Dayjs);
                  },
                  minDate: startDate,
                }}
                textProps={{
                  error: false,
                  placeholder: 'End Date',
                  sx: {
                    '& .MuiInputBase-input': {
                      fontFamily: `Aspekta !important`,
                      fontWeight: 450,
                      fontSize: '12px',
                      lineHeight: '120%',
                      color: QUICK_ACCESS.text[200],
                      padding: 1.5,
                      paddingRight: 0,
                    },
                  },
                  standardWidth: 'auto',
                }}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </VegaDrawerV4>
  );
}

export default LoginHistoryFilter;
