import * as React from 'react';
import { SVGProps } from 'react';

const FileCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M6.86653 14.4001H3.66652C2.78286 14.4001 2.06652 13.6837 2.06653 12.8001L2.06659 3.20009C2.0666 2.31644 2.78294 1.6001 3.66659 1.6001H10.8668C11.7504 1.6001 12.4668 2.31644 12.4668 3.2001V7.6001M9.26678 12.1334L10.7334 13.6001L13.9334 10.4M4.86678 4.8001H9.66678M4.86678 7.2001H9.66678M4.86678 9.6001H7.26678"
      stroke={props.stroke || '#1047DC'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default FileCheckIcon;
