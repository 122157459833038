/* eslint-disable react/jsx-pascal-case */
import { Close } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { updateCardStatus } from '../../../../api/card';
import { toLowerCase } from '../../../../constants/commonFunction';
import { NEW_PRIMARY } from '../../../../constants/style';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useSupport } from '../../../../providers/SupportProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import Vega_Button from '../../../common/v3/Vega_Button';
import Vega_Dialog from '../../../common/v3/Vega_Dialog';
import Vega_FormWrapper from '../../../common/v3/Vega_FormWrapper';
import Vega_Select, { ISelectOptionType } from '../../../common/v3/Vega_Select';
import Vega_Text from '../../../common/v3/Vega_Text';
import { CardStatus } from '../../../../types/card';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const BlockCardModal = ({ open, handleClose }: IProps) => {
  const { cardsList } = useSupport();
  const [selectedStatus, setSelectedStatus] = useState<CardStatus>();
  const { setSnackbar } = useSnackbar();

  const primaryCard = useMemo(() => {
    return cardsList.data.find((item) => item.cardCategory === 'PRIMARY_CARD');
  }, [cardsList]);

  const onSubmit = () => {
    selectedStatus &&
      primaryCard &&
      updateCardStatus({
        cardId: primaryCard?.id,
        cardStatus: selectedStatus,
      })
        .then((res) => {
          setSnackbar(
            'Card Blocked Successfully for - ' + cardsList.data[0].id,
            'success'
          );
          handleClose();
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(err, 'Error while blocking card'),
            'error'
          );
        });
  };
  return (
    <Vega_Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      handleClose={handleClose}
      headerComponent={() => (
        <>
          <Stack
            p={3}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'start'}
          >
            <Stack gap={1}>
              <Vega_Text
                text={'Block Card'}
                color={'#3A4A5F'}
                fontSize={14}
                fontWeight={500}
              />
            </Stack>
            <Close
              sx={{ color: '#3A4A5F', cursor: 'pointer' }}
              onClick={handleClose}
            />
          </Stack>
        </>
      )}
      buttonComponent={() => (
        <Stack direction={'row'} gap={1} px={2} py={1} justifyContent={'end'}>
          <Vega_Button
            variant="text"
            textColor={NEW_PRIMARY.BLUE}
            bgColor="transparant"
            text="Cancel"
            onClick={handleClose}
          />
          <Vega_Button
            text="Block"
            onClick={onSubmit}
            disabled={!selectedStatus}
          />
        </Stack>
      )}
    >
      <Stack px={3} pb={3}>
        {primaryCard ? (
          <Vega_FormWrapper header="Status">
            <Vega_Select
              fullWidth
              sx={{ width: '100%' }}
              options={blockOption()}
              onChange={(event) =>
                setSelectedStatus(event.target.value as CardStatus)
              }
            />
          </Vega_FormWrapper>
        ) : (
          <Typography>No Primary Card Found</Typography>
        )}
      </Stack>
    </Vega_Dialog>
  );
};

export default BlockCardModal;

const blockOption = () =>
  [CardStatus.TEMP_BLOCKED, CardStatus.PERM_BLOCKED].map((item) => {
    const option: ISelectOptionType = {
      id: item,
      name: toLowerCase(item),
    };
    return option;
  });
