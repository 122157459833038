import axios, { AxiosResponse } from 'axios';
import { COLLECTION } from '../api/endpoints';

const COLLECTION_BASE = COLLECTION.BASE;
const COLLECTION_BASE_DELINQUENCY_STATS =
  COLLECTION_BASE + COLLECTION.DELINQUENCY_STATS;
const COLLECTION_BASE_DELINQUENCY_CURRENT =
  COLLECTION_BASE + COLLECTION.DELINQUENCY_CURRENT;
const COLLECTION_BASE_DELINQUENCY_HISTORY =
  COLLECTION_BASE + COLLECTION.DELINQUENCY_HISTORY;

export class DelinquencyServices {
  static async getDelinquencyHistoryByAccountId(
    accountIds: string[]
  ): Promise<AxiosResponse<IDelinquencyListProps[]>> {
    return await axios.post(COLLECTION_BASE_DELINQUENCY_HISTORY, {
      accountIds,
    });
  }
  static async getDelinquencyCurrentByAccountId(
    accountId: string
  ): Promise<AxiosResponse<IDelinquencyListProps>> {
    return await axios.get(
      COLLECTION_BASE_DELINQUENCY_CURRENT + `/${accountId}`
    );
  }
  static async getDelinquencyStatsByAccountId(
    accountId: string
  ): Promise<AxiosResponse<IDelinQuencyStatProps>> {
    return await axios.get(COLLECTION_BASE_DELINQUENCY_STATS + `/${accountId}`);
  }
}

export interface IDelinquencyListProps {
  id: number;
  customerId: string;
  accountId: string;
  delinquencyAccountType: DelinquencyAccountType;
  totalLimit: number;
  principalUsed: number;
  interest: number;
  charges: number;
  dueDate: string;
  dpdCalculationDate: string;
  dpd: number;
  outstandingMad: number;
  outstandingTad: number;
  outstandingPrincipal: number;
  outstandingInterest: number;
  outstandingCharges: number;
  paidPrincipal: number;
  paidInterest: number;
  paidCharges: number;
  lastPaymentDate: string;
  createdAt: string;
  agentName: string;
}

export interface IDelinQuencyStatProps {
  accountId: string;
  maxDpd: number;
  DpdCount: number;
  pastNinetyMaxDpd: number;
  pastOneEightyMaxDpd: number;
}

export enum DelinquencyAccountType {
  ACCOUNT = 'ACCOUNT',
  LOAN = 'LOAN',
}
