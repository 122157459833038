import VegaBadgeStatusTagV4 from '../common/v4/VegaBadgeStatusTagV4';

const ProgramStatus = ({ status }: { status: string }) => {
  const statusText = status === 'ACTIVE' ? 'Live' : status;
  const statusColor = status === 'ACTIVE' ? 'green' : 'yellow';

  return (
    <VegaBadgeStatusTagV4
      text={statusText}
      variant="stroked"
      color={statusColor}
    />
  );
};

export default ProgramStatus;
