import { Stack } from '@mui/material';
import { QUICK_ACCESS } from '../../../../constants/style';
import { PageHeadingTypo } from '../../../common/Typography';
import VegaFilterButtonsV4 from '../../../common/v4/VegaFilterBtnsV4';
import { getFilterOptions } from '../../backOfficeUtils';
import { useEffect, useState } from 'react';
import { useProgramData } from '../../../../providers/ProgramDataProvider';
import dayjs from 'dayjs';
import { getDateRange } from '../../../../utils/common.utils';
import { DATE_FILTERS } from '../../../../enums/commons';
import {
  DATE_FORMAT_MONTH_US,
  DATE_FORMAT_US,
} from '../../../../constants/url';
import { BillingGraphDataProps } from '../../../../types/analytics';
import { ANALYTICS_QUERY_NAME } from '../../../../enums/analytics.enum';
import { useAnalyticsDataQuery } from '../../../../store/queries/analyticsQueries';
import VegaLineChartV4 from '../../../common/Charts/VegaLineChartV4';
import {
  getDatesBetween,
  getMonthsBetween,
} from '../../../../utils/stringUtils';

interface IGraphDataProps {
  date: string;
  value: number;
}

const BillingAnalytics = () => {
  const [activeIdx, setActiveIdx] = useState(4);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: '',
    endDate: '',
  });
  const [billAmountArray, setBillAmountArray] = useState<IGraphDataProps[]>([]);
  const [billCountArray, setBillCountArray] = useState<IGraphDataProps[]>([]);
  const [tickMax, setTickMax] = useState(10);
  const { selectedProgram } = useProgramData();
  const programId = selectedProgram?.programId;

  const onFilterCellClick = (idx: number) => {
    if (idx !== 0) {
      setActiveIdx(idx);
      setSelectedDateRange({ startDate: '', endDate: '' });
    }
  };

  const submitDateRange = (startDate: string, endDate: string) => {
    setSelectedDateRange({
      startDate: dayjs(startDate).format('MMM D, YYYY'),
      endDate: dayjs(endDate).format('MMM D, YYYY'),
    });
    setActiveIdx(0);
  };

  const onCloseCustomRangeClick = () => {
    setSelectedDateRange({ startDate: '', endDate: '' });
    setActiveIdx(4);
  };

  const filterOptions = getFilterOptions(selectedDateRange, 'longer');
  const dateRange =
    activeIdx === 0
      ? {
          startDate: dayjs(selectedDateRange.startDate).format(DATE_FORMAT_US),
          endDate: dayjs(selectedDateRange.endDate).format(DATE_FORMAT_US),
        }
      : getDateRange(
          filterOptions[activeIdx]?.text as DATE_FILTERS,
          DATE_FORMAT_US
        );
  const isDataMonthly = activeIdx >= 3;

  const { data: billingGraphData } =
    useAnalyticsDataQuery<BillingGraphDataProps>({
      queryName: isDataMonthly
        ? ANALYTICS_QUERY_NAME.CCMS_BILL_PAID_MONTH_RANGE
        : ANALYTICS_QUERY_NAME.CCMS_BILL_PAID_DATE_BY_DATE,
      programId: programId as string,
      ...dateRange,
    });

  const graphDataRecords = billingGraphData?.records || [];

  useEffect(() => {
    const billAmountArrayTemp: IGraphDataProps[] = [];
    const billCountArrayTemp: IGraphDataProps[] = [];

    const temp: {
      [key: string]: { total_pay_amount: string; total_pay_count: string };
    } = {};
    graphDataRecords.forEach((el) => {
      temp[(isDataMonthly ? el.month : el.created_at) as string] = {
        total_pay_amount: el.total_pay_amount,
        total_pay_count: el.total_pay_count,
      };
    });
    let dates = isDataMonthly
      ? getMonthsBetween(
          new Date(dateRange.startDate),
          new Date(dateRange.endDate)
        )
      : getDatesBetween(
          new Date(dateRange.startDate),
          new Date(dateRange.endDate)
        );
    dates = dates.slice(1);

    dates.forEach((date) => {
      const dateFormated = dayjs(date).format(
        isDataMonthly ? DATE_FORMAT_MONTH_US : DATE_FORMAT_US
      );
      const dateFormated2 = dayjs(date).format(
        isDataMonthly ? "MMM, 'YY" : "MMM D, 'YY"
      );

      billAmountArrayTemp.push({
        date: dateFormated2,
        value: Number(temp[dateFormated]?.total_pay_amount || 0),
      });
      billCountArrayTemp.push({
        date: dateFormated2,
        value: Number(temp[dateFormated]?.total_pay_count || 0),
      });
    });
    setBillAmountArray(billAmountArrayTemp);
    setBillCountArray(billCountArrayTemp);
  }, [graphDataRecords, isDataMonthly, dateRange.endDate, dateRange.startDate]);

  useEffect(() => {
    const maxNum = Math.max(...billCountArray.map((el) => el.value));
    if (maxNum > 10) {
      const mod = maxNum % 25;
      const rem = 25 - mod;
      let val = maxNum + rem;
      if ((val / 5) * 4 < maxNum) {
        val += 25;
      }
      setTickMax(val);
    } else {
      setTickMax(10);
    }
  }, [billCountArray, activeIdx]);

  return (
    <Stack
      px={2}
      py={3}
      gap={4}
      sx={{
        backgroundColor: QUICK_ACCESS.background[50],
        border: `1px solid ${QUICK_ACCESS.border[50]}`,
        borderRadius: '16px',
      }}
    >
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="space-between"
        flex={1}
      >
        <PageHeadingTypo>Bill paid</PageHeadingTypo>
        <VegaFilterButtonsV4
          data={getFilterOptions(selectedDateRange, 'longer')}
          activeIdx={activeIdx}
          onCellClick={onFilterCellClick}
          submitDateRange={submitDateRange}
          customIdx={0}
          onCloseCustomRangeClick={onCloseCustomRangeClick}
        />
      </Stack>
      <Stack flex={1}>
        {billAmountArray.length && (
          <VegaLineChartV4
            YAxisLabels={{
              left: {
                text: 'Amount of Bill paid',
                sx: { color: QUICK_ACCESS.primary.blue },
              },
              right: {
                text: 'Count of Bill paid',
                sx: { color: QUICK_ACCESS.logo.orange },
              },
            }}
            linePropsArray={[
              {
                stroke: QUICK_ACCESS.primary.blue,
                dataKey: 'value',
                name: 'Amount of Bill paid',
                data: billAmountArray,
                yAxisId: 'left',
              },
              {
                stroke: QUICK_ACCESS.logo.orange,
                dataKey: 'value',
                name: 'Count of Bill paid',
                data: billCountArray,
                yAxisId: 'right',
              },
            ]}
            XAxisData={{
              dataKey: 'date',
              type: 'category',
              allowDuplicatedCategory: false,
            }}
            YAxisData={{
              dataKey: 'value',
              yAxisId: 'left',
              tick: { fill: QUICK_ACCESS.primary.blue, fontWeight: 600 },
            }}
            YAxisData2={{
              dataKey: 'value',
              yAxisId: 'right',
              orientation: 'right',
              tick: { fill: QUICK_ACCESS.logo.orange, fontWeight: 600 },
              ticks: [
                0,
                tickMax / 5,
                (tickMax / 5) * 2,
                (tickMax / 5) * 3,
                (tickMax / 5) * 4,
              ],
            }}
            wrapperSx={{
              p: 2,
            }}
            respContainerData={{ height: 432 }}
            legendRequired
          />
        )}
      </Stack>
    </Stack>
  );
};

export default BillingAnalytics;
