import { Stack } from '@mui/material';
import { TransactionDto } from '../../../../../../../types/transaction';
import { PageHeadingTypo } from '../../../../../../common/Typography';
import VegaTableV4 from '../../../../../../common/v4/VegaTableV4';
import { useTransactionChargesListQuery } from '../../../../../../../store/queries/transactionQueries';
import { getTransactionChargesStructuredData } from '../../../../supportUtils';
import VegaNoResults from '../../../../../../common/VegaNoResults';

interface ITransactionChargesProps {
  data: TransactionDto | undefined;
}

const TransactionCharges = ({ data }: ITransactionChargesProps) => {
  const { data: chargesData, isFetching } = useTransactionChargesListQuery({
    transactionId: data?.id || '',
  });

  const structuredTableData = getTransactionChargesStructuredData(chargesData);

  return (
    <Stack gap={1.5}>
      <PageHeadingTypo>Charges & GST</PageHeadingTypo>
      {chargesData?.length || isFetching ? (
        <VegaTableV4
          head={['Transaction ID', 'Amount', 'GST Amount', 'Charge Type']}
          data={structuredTableData}
          isLoading={isFetching}
        />
      ) : (
        <VegaNoResults errorText="No Charges List to display" />
      )}
    </Stack>
  );
};

export default TransactionCharges;
