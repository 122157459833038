import { Box, Stack } from '@mui/material';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { VegaCreateCustomersType } from '../../../types/customers';
import VegaTableV4, { ITableDataProps } from '../../common/v4/VegaTableV4';
import VegaNoResults from '../../common/VegaNoResults';
import VegaDetailsDialogV4 from '../../common/v4/VegaDetailsDialogV4';
import {
  SearchOptions,
  getAccDetailsDialogData,
  getAccListStructuredData,
  getCustomerDetailsDialogData,
  searchByOptionsForTransactions,
} from '../simulatorUtils';
import { useAccountListQuery } from '../../../store/queries/customerQueries';
import VegaDialogV4 from '../../common/v4/VegaDialogV4';
import { AccountStatus } from '../../../enums/account.enum';
import { SectionHeadingTypo } from '../../common/Typography';
import { updateStatusForAccount } from '../../../mcc/Back-office/api/account';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useQueryClient } from 'react-query';
import { QUERY_NAMES } from '../../../enums/commons';
import VegaSelectV4 from '../../common/v4/VegaSelectV4';

const AccountListPage = () => {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { selectedProgram } = useProgramData();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [activeSearchBy, setActiveSearchBy] = useState<SearchOptions>(
    searchByOptionsForTransactions[0].id
  );
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [openCustomerDetailsDialog, setOpenCustomerDetailsDialog] =
    useState(false);
  const [openAccDetailsDialog, setOpenAccDetailsDialog] = useState(false);
  const [openAccUpdateDialog, setOpenAccUpdateDialog] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<
    VegaCreateCustomersType | undefined
  >(undefined);

  const account = selectedCustomer?.account;
  const accountStatus = account?.status;
  const isStatusActive = accountStatus === AccountStatus.ACTIVE;

  const onAccountStatusChange = useCallback((rec: VegaCreateCustomersType) => {
    onAccUpdateOpen(rec);
  }, []);

  const onCustomerDetailsOpen = (rec: VegaCreateCustomersType) => {
    setSelectedCustomer(rec);
    setOpenCustomerDetailsDialog(true);
  };

  const onCustomerDetailsClose = () => {
    setOpenCustomerDetailsDialog(false);
  };

  const onAccDetailsOpen = (rec: VegaCreateCustomersType) => {
    setSelectedCustomer(rec);
    setOpenAccDetailsDialog(true);
  };

  const onAccDetailsClose = () => {
    setOpenAccDetailsDialog(false);
  };

  const onAccUpdateOpen = (rec: VegaCreateCustomersType) => {
    setSelectedCustomer(rec);
    setOpenAccUpdateDialog(true);
  };

  const onAccUpdateClose = () => {
    setOpenAccUpdateDialog(false);
  };

  const getNextAccountLifecycleStatus = useCallback(() => {
    if (accountStatus == AccountStatus.CREATED) return AccountStatus.NEW;
    else if (accountStatus == AccountStatus.INACTIVE)
      return AccountStatus.ACTIVE;
    return AccountStatus.INACTIVE;
  }, [accountStatus]);

  const updateAccountStatus = () => {
    const updatedStatus = getNextAccountLifecycleStatus();
    setUpdateLoading(true);
    updateStatusForAccount({
      accountId: account?.accountId as string,
      status: updatedStatus,
    })
      .then(() => {
        queryClient.invalidateQueries([
          QUERY_NAMES.CUSTOMER_ACCOUNT_LIST,
          selectedProgram?.programId,
          clientId,
        ]);
        setSnackbar('Account Status Updated');
        onAccUpdateClose();
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  };

  const { data, isFetching } = useAccountListQuery({
    clientId: clientId as string,
    programId: selectedProgram?.programId as string,
    pageNumber: page,
    pageSize,
    searchBy: searchValue ? activeSearchBy : undefined,
    search: searchValue,
  });

  const records = data?.records;

  const structuredData: ITableDataProps[] = useMemo(() => {
    return getAccListStructuredData(
      records,
      onCustomerDetailsOpen,
      onAccDetailsOpen,
      onAccountStatusChange
    );
  }, [records, onAccountStatusChange]);

  useEffect(() => {
    setPage(0);
  }, [searchValue, activeSearchBy]);

  return (
    <Box>
      <VegaHeaderV4 title="Accounts" sx={{ marginTop: 4 }} />
      <Stack p={3} gap={2}>
        <Stack justifyContent="flex-end" direction="row">
          <Stack direction={'row'} gap={1}>
            <VegaSelectV4
              options={searchByOptionsForTransactions}
              value={activeSearchBy}
              onChange={(e) => {
                setActiveSearchBy(e.target.value as SearchOptions);
                setSearchValue('');
              }}
              wrapperProps={{
                flex: 0.7,
              }}
              standardWidth={140}
            />
            <VegaSearchfieldV4
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value as string)}
            />
          </Stack>
        </Stack>
        {Boolean(records?.length) || isFetching ? (
          <VegaTableV4
            head={[
              'Customer Name',
              'Account Number',
              'Account Limit',
              'Cash Limit',
              'Over Limit',
              'Grace Day',
              'Created At',
              'Customer Details',
              'Account Details',
              'Account Status',
            ]}
            data={structuredData}
            isLoading={isFetching}
            pagination={{
              page,
              setPage,
              rowsPerPage: pageSize,
              setRowsPerPage: setPageSize,
              totalRecords: data?.totalItems || 0,
            }}
          />
        ) : (
          <VegaNoResults errorText="No Accounts Found" />
        )}
      </Stack>
      <VegaDetailsDialogV4
        data={getCustomerDetailsDialogData(selectedCustomer)}
        dialogProps={{
          title: 'Customer Details',
          open: openCustomerDetailsDialog,
          onClose: onCustomerDetailsClose,
        }}
      />
      <VegaDetailsDialogV4
        data={getAccDetailsDialogData(selectedCustomer)}
        dialogProps={{
          title: 'Account Details',
          open: openAccDetailsDialog,
          onClose: onAccDetailsClose,
        }}
        keyMinWidth={170}
        dialogMinWidth={550}
      />
      <VegaDialogV4
        title="Update Account Status"
        open={openAccUpdateDialog}
        onClose={onAccUpdateClose}
        closeBtnRequired
        actionBtnPrimaryText="No"
        actionBtnSecondaryText="Yes"
        actionBtnPrimaryProps={{
          onClick: onAccUpdateClose,
          disabled: updateLoading,
        }}
        actionBtnSecondaryProps={{
          onClick: updateAccountStatus,
          disabled: updateLoading,
          loading: updateLoading,
        }}
      >
        <Box py={4} px={5}>
          <SectionHeadingTypo>
            Are you sure you want to change the account status from{' '}
            {accountStatus} to{' '}
            {isStatusActive ? 'INACTIVE' : `${getNextAccountLifecycleStatus()}`}
            ?
          </SectionHeadingTypo>
        </Box>
      </VegaDialogV4>
    </Box>
  );
};

export default AccountListPage;
