import dayjs from 'dayjs';
import { IReportConfigReponse, IReportRun } from '../../../types/reports';
import { convertToDateTime } from '../../../utils/dateTime';

export const getGeneratedOn = (
  key: string,
  reportRuns: Record<string, IReportRun>
) => {
  console.log('getgenertedOn', key, reportRuns);

  if (reportRuns && reportRuns[key])
    convertToDateTime(reportRuns[key].generatedOn);
  return '';
};

export const getDate = (date: Date) => {
  return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
};
export const getScheduledTime = (currDateTime: Date, scheduledTime: number) => {
  const currTime = currDateTime.getHours() + currDateTime.getMinutes() / 60;

  if (scheduledTime >= currTime) {
    return `${currDateTime.getDate()}/${currDateTime.getMonth()}/${currDateTime.getFullYear()} ${scheduledTime}`;
  } else {
    const tomorrow = new Date(currDateTime.getTime() + 60 * 60 * 24 * 1000);
    return `${tomorrow.getDate()}/${tomorrow.getMonth()}/${tomorrow.getFullYear()} ${scheduledTime}`;
  }
};

export const getFinalTime = (date: Date, config: IReportConfigReponse) => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${
    config.hours + ':' + config.minutes
  }`;
};

export const nextMonth = (currDate: Date, dayOfMonth: number) => {
  let month = currDate.getMonth();
  let yr = currDate.getFullYear();
  month++;
  month = month % 12;
  if (month === 0) {
    yr = yr++;
  }

  return new Date(yr, month, dayOfMonth);
};

export const DT_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const getNextSchedule = (config: IReportConfigReponse) => {
  let scheduled = dayjs().hour(config.hours).minute(config.minutes).second(0);
  const now = dayjs();

  switch (config.frequency) {
    case 'DAILY':
      if (scheduled.isBefore(now)) {
        scheduled = scheduled.add(1, 'day');
      }
      break;
    case 'MONTHLY':
      scheduled = scheduled.date(config.dayOfMonth);
      if (scheduled.isBefore(now)) {
        scheduled = scheduled.add(1, 'month');
      }
      break;
    case 'WEEKLY':
      scheduled = scheduled.day(config.dayOfWeek);
      if (scheduled.isBefore(now)) {
        scheduled = scheduled.add(1, 'week');
      }
      break;
  }
  return scheduled;
};
