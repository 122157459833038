import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import DataTile from '../DataTile';
import {
  AccountPolicyInterface,
  AnyPolicyType,
  BillingPolicyInterface,
  ChargePolicyInterface,
  InterestPolicyInterface,
  MadPolicyInterface,
  PolicyStatus,
  RepaymentPolicyInterface,
  TaxPolicyInterface,
  TransactionPolicy,
  TxnToEmiPolicyInterface,
} from '../../../types/policy';
import ViewBillingPolicy from './ViewBillingPolicy';
import ViewMadTadPolicy from './ViewMadTadPolicy';
import ViewAccountPolicy from './ViewAccountPolicy';
import ViewInterestPolicy from './ViewInterestPolicy';
import ViewTaxPolicy from './ViewTaxPolicy';
import ViewRepaymentPolicy from './ViewRepaymentPolicy';
import ViewTransactionToEmiPolicy from './ViewTransactionToEmiPolicy';
import ViewTransactionLimitPolicy from './ViewTransactionLimitPolicy';
import ViewChargesPolicy from './ViewChargesPolicy';
import { useAppSelector } from '../../../store/hook';
import VPMenuDrivenContent from '../../../ui-components/common/VPMenuDrivenContent';
import VegaNoResults from '../../common/VegaNoResults';
import Stack from '@mui/material/Stack';
import { PageHeadingTypo, SectionHeadingTypo } from '../../common/Typography';
import { GENERIC_BORDER } from '../../../constants/style';
import Box from '@mui/material/Box';

type PolicyViewProps = {};

const policyMapping = [
  { id: 'BILLING', title: 'Billing Policy' },
  { id: 'MAD', title: 'Mad Tad Policy' },
  { id: 'ACCOUNT', title: 'Account Policy' },
  { id: 'INTEREST', title: 'Interest Policy' },
  { id: 'CHARGES', title: 'Charges Policy' },
  { id: 'TAX', title: 'Tax Policy' },
  { id: 'REPAYMENT_WATERFALL', title: 'Repayment Policy' },
  { id: 'TRANSACTION_TO_INSTALLMENT', title: 'Transaction to Emi Policy' },
  { id: 'TRANSACTION', title: 'Transaction Limit Policy' },
];

const getTitle = (policy_type: string | null): string => {
  return (
    policyMapping.find((policy) => policy.id === policy_type)?.title || 'Policy'
  );
};

function PolicyView({}: PolicyViewProps) {
  // const title = getTitle(policy.policy_type);
  const { policies, selectedProgram } = useAppSelector(
    (state) => state.programManagement
  );
  const filteredPolicyMappings = useMemo(
    () =>
      policyMapping.filter((map) =>
        policies.some((policy) => policy.policy_type === map.id)
      ),
    [policies]
  );
  const [selectedListItem, setSelectedListItem] = useState(
    filteredPolicyMappings.at(0)?.id
  );
  const [activePolicy, setActivePolicyState] = useState<
    AnyPolicyType | undefined
  >(policies.find((policy) => policy.policy_type === selectedListItem));

  const handleListItemClick = (id: string) => {
    setSelectedListItem(id);
    const policy = policies.find((policy) => policy.policy_type === id);
    setActivePolicyState(policy);
  };

  const isApproved = useMemo(
    () =>
      activePolicy &&
      [PolicyStatus.APPROVED, PolicyStatus.ENABLED].includes(
        activePolicy?.status
      ) &&
      !!selectedProgram?.checker,
    [selectedProgram, activePolicy]
  );

  return selectedListItem && activePolicy ? (
    <VPMenuDrivenContent
      menuItems={filteredPolicyMappings}
      selectedMenuItem={selectedListItem}
      onMenuItemChange={handleListItemClick}
    >
      <Stack borderBottom={GENERIC_BORDER} pb={3}>
        <Stack direction="row">
          <PageHeadingTypo>{getTitle(selectedListItem)}</PageHeadingTypo>
        </Stack>
        <Stack direction="row" gap={3} mt="30px" flexWrap="wrap" maxWidth={700}>
          <DataTile
            title="Policy ID"
            value={activePolicy.policy_id || '-'}
            containerSx={{
              width: 180,
            }}
          />
          <DataTile
            title="Approved On"
            value={
              isApproved && activePolicy.updated_at
                ? dayjs(activePolicy.updated_at).format('DD/MM/YYYY')
                : '-'
            }
            containerSx={{
              width: 180,
            }}
          />
          <DataTile
            title="Approved By"
            value={isApproved ? selectedProgram?.checker?.email : '-'}
            containerSx={{
              width: 180,
            }}
          />
          <DataTile
            title="Start Date"
            value={
              activePolicy.start_date
                ? dayjs(activePolicy.start_date).format('DD/MM/YYYY')
                : '-'
            }
            containerSx={{
              width: 180,
            }}
          />
        </Stack>
      </Stack>
      <Stack py={3}>
        <SectionHeadingTypo>Ongoing Policies</SectionHeadingTypo>
        <ActivePolicyTab policy={activePolicy} />
      </Stack>
    </VPMenuDrivenContent>
  ) : (
    <Box
      sx={{
        padding: {
          xs: 1,
          sm: 3.25,
        },
        flex: 1,
      }}
    >
      <VegaNoResults errorText="No Policies Found" />
    </Box>
  );
}

function ActivePolicyTab({ policy }: { policy: AnyPolicyType }) {
  switch (policy.policy_type) {
    case 'BILLING':
      return <ViewBillingPolicy policy={policy as BillingPolicyInterface} />;
    case 'MAD':
      return <ViewMadTadPolicy policy={policy as MadPolicyInterface} />;
    case 'ACCOUNT':
      return <ViewAccountPolicy policy={policy as AccountPolicyInterface} />;
    case 'INTEREST':
      return <ViewInterestPolicy policy={policy as InterestPolicyInterface} />;
    case 'TAX':
      return <ViewTaxPolicy policy={policy as TaxPolicyInterface} />;
    case 'REPAYMENT_WATERFALL':
      return (
        <ViewRepaymentPolicy policy={policy as RepaymentPolicyInterface} />
      );
    case 'TRANSACTION_TO_INSTALLMENT':
      return (
        <ViewTransactionToEmiPolicy
          policy={policy as TxnToEmiPolicyInterface}
        />
      );
    case 'TRANSACTION':
      return (
        <ViewTransactionLimitPolicy policy={policy as TransactionPolicy} />
      );
    case 'CHARGES':
      return <ViewChargesPolicy policy={policy as ChargePolicyInterface} />;
    default:
      break;
  }
  return null;
}
export default PolicyView;
