import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { fetchListOfVendor } from '../api/vendorService';
import { VegaPage } from '../components/common';
import ListOfServiceSetup from '../components/Service Setup/ListOfServiceSetup';
import { useSnackbar } from '../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../utils/api';
import VPHeader from '../ui-components/common/VPHeader';
import Stack from '@mui/material/Stack';
import VegaSelectV4, {
  VegaSelectOptionType,
} from '../components/common/v4/VegaSelectV4';
import VegaSearchfieldV4 from '../components/common/v4/VegaSearchfieldV4';
import { VendorInfo } from '../types/vendor';
import { CredentialService, VendorCredential } from '../api/credintial';

export interface IVendorProps {
  serviceType: string;
  services: string[];
  vendorName: string;
}

enum FilterBy {
  PARTNER = 'vendorName',
  SERVICES = 'services',
}

const filterOptions: VegaSelectOptionType[] = [
  {
    id: FilterBy.PARTNER,
    name: 'Partner',
  },
  { id: FilterBy.SERVICES, name: 'Services' },
];

const DEFAULT_FILTER = {
  search: '',
  searchBy: filterOptions[0].id,
};

type VendorInfoWithActivationData = VendorInfo & {
  devCredentials?: VendorCredential[];
  prodCredentials?: VendorCredential[];
};

// TODO: Need a better way to do this
const getListOfVendorWithActivationData = async (clientId: string) => {
  return new Promise((resolve, reject) =>
    fetchListOfVendor().then((res) => {
      const vendors = res.data as VendorInfo[];
      const vendorsWithActivationData = vendors.map(async (vendor) => {
        let devCreds: VendorCredential[] = [];
        let prodCreds: VendorCredential[] = [];
        try {
          devCreds = await CredentialService.getVendorCredentials({
            clientId: clientId,
            accountType: 'SANDBOX',
            vendorId: vendor.id,
          });
        } catch (error) {}
        try {
          prodCreds = await CredentialService.getVendorCredentials({
            clientId: clientId,
            accountType: 'PROD',
            vendorId: vendor.id,
          });
        } catch (error) {}

        return {
          ...vendor,
          devCredentials: devCreds,
          prodCredentials: prodCreds,
        };
      });
      resolve(vendorsWithActivationData);
    })
  );
};
const ServicesSetup = () => {
  const { setSnackbar } = useSnackbar();
  const [vendors, setVendors] = useState<VendorInfo[]>([]);
  const [filter, setFilter] = useState<any>({ ...DEFAULT_FILTER });

  const getListOfVendor = async () => {
    await fetchListOfVendor()
      .then((res) => {
        setVendors(res.data);
      })
      .catch((err) => setSnackbar(getErrorMessageFromErrorObj(err), 'error'));
  };

  const onChange = (name: string, value: string) => {
    setFilter((preVal: any) => ({
      ...preVal,
      [name]: value,
    }));
  };

  const filterData = () => {
    const query = filter.search.toLowerCase();
    if (query === '') {
      return vendors;
    }

    const filterBy = filter.searchBy;
    if (filterBy == FilterBy.PARTNER) {
      return vendors.filter((item) =>
        item.vendorName.toLowerCase().includes(query)
      );
    } else if (filterBy == FilterBy.SERVICES) {
      return vendors.filter((item) => {
        const services = item.services.filter((subItem) =>
          subItem.toLowerCase().includes(query)
        );
        return services.length > 0;
      });
    }

    return vendors;
  };

  useEffect(() => {
    getListOfVendor();
  }, []);

  return (
    <VegaPage>
      <VPHeader text="Service Setup" />
      <Box
        sx={{
          px: 3.25,
        }}
      >
        <Stack direction="row" gap={3} pb={2} justifyContent="flex-start">
          <VegaSelectV4
            options={filterOptions}
            value={filter.searchBy}
            onChange={(e) => onChange('searchBy', e.target.value as string)}
            standardWidth={200}
          />
          <VegaSearchfieldV4
            placeholder="Search Partner"
            value={filter.search}
            onChange={(e) => onChange('search', e.target.value)}
          />
        </Stack>
        <VegaPage>
          <ListOfServiceSetup data={filterData()} />
        </VegaPage>
      </Box>
    </VegaPage>
  );
};

export default ServicesSetup;
