import * as React from 'react';
import { SVGProps } from 'react';
import { VEGA_COMMON } from '../../constants/style';
const FileUploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
    stroke="none"
  >
    <path
      d="M7.20039 14.3999H4.00038C3.11673 14.3999 2.40038 13.6836 2.40039 12.7999L2.40045 3.19997C2.40046 2.31631 3.1168 1.59998 4.00045 1.59998H11.2006C12.0843 1.59998 12.8006 2.31632 12.8006 3.19998V7.59997M10.4006 12.0284L12.0368 10.4M12.0368 10.4L13.6006 11.9547M12.0368 10.4V14.4M5.20064 4.79997H10.0006M5.20064 7.19997H10.0006M5.20064 9.59997H7.60064"
      stroke={props.stroke ?? VEGA_COMMON.primaryBlue}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default FileUploadIcon;
