import { Box, Stack } from '@mui/material';
import {
  Cell,
  Legend,
  LegendProps,
  Pie,
  PieChart,
  PieLabelRenderProps,
  PieProps,
  ResponsiveContainer,
  ResponsiveContainerProps,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import {
  ACCENT,
  INDICATIVE,
  PRIMARY_COLOR,
  QUICK_ACCESS,
} from '../../../constants/style';
import { ComponentBody1Typo, ComponentBody2Typo } from '../Typography';
import { CSSProperties } from 'react';

interface IVegaDoughnutChartV4Props {
  mainData: any[];
  toolTipData?: TooltipProps<ValueType, NameType>;
  respContainerData?: Omit<ResponsiveContainerProps, 'children'>;
  pieData?: PieProps;
  colors?: CSSProperties['backgroundColor'][];
}

const VegaDoughnutChartV4 = ({
  mainData,
  toolTipData,
  respContainerData,
  pieData,
  colors = [
    PRIMARY_COLOR.orange[400],
    ACCENT.violet[300],
    INDICATIVE.blue[400],
    ACCENT.seaGreen[200],
    PRIMARY_COLOR.blue[100],
  ],
}: IVegaDoughnutChartV4Props) => {
  return (
    <ResponsiveContainer
      height={400}
      style={{ flex: 1 }}
      {...respContainerData}
    >
      <PieChart>
        <Pie
          dataKey="value"
          data={mainData}
          innerRadius={90}
          outerRadius={150}
          label={renderCustomizedLabel}
          labelLine={false}
          {...(pieData as any)}
        >
          {mainData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} {...toolTipData} />
        <Legend
          iconType="circle"
          margin={{ top: 20 }}
          align="center"
          content={<CustomLegend />}
          verticalAlign="top"
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx = 0,
  cy = 0,
  midAngle = 0,
  innerRadius = 0,
  outerRadius = 0,
  percent = 0,
  index,
}: PieLabelRenderProps) => {
  const radius =
    Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.5;
  const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
  const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={'middle'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomLegend = (props: LegendProps) => {
  const { payload } = props;

  return (
    <Stack
      style={{ listStyleType: 'none', padding: 0 }}
      direction="row"
      gap={2}
      alignItems="center"
      justifyContent="center"
      flexWrap={'wrap'}
    >
      {payload?.map((entry, index) => (
        <Stack
          key={`item-${index}`}
          direction="row"
          gap={1}
          alignItems="center"
        >
          <Box
            width={'16px'}
            height={'16px'}
            borderRadius={'8px'}
            sx={{ backgroundColor: entry.color }}
          />
          <ComponentBody1Typo>{entry.value}</ComponentBody1Typo>
        </Stack>
      ))}
    </Stack>
  );
};

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: QUICK_ACCESS.background[50],
          borderRadius: '6px',
          border: `1px solid ${QUICK_ACCESS.border[50]}`,
          overflow: 'hidden',
        }}
      >
        {payload.map((item, idx) => (
          <Box>
            <Box
              p={'8px'}
              sx={{ backgroundColor: QUICK_ACCESS.background[100] }}
            >
              <ComponentBody1Typo>{item.name}</ComponentBody1Typo>
            </Box>
            <Box p={'8px'} gap={'12px'}>
              <Stack key={idx} direction="row" gap={'12px'}>
                <Stack direction="row" gap={'12px'} flex={1}>
                  <Box
                    width={'16px'}
                    height={'16px'}
                    borderRadius={'8px'}
                    sx={{ backgroundColor: item.payload.fill || 'gray' }}
                  />
                  <ComponentBody2Typo color={QUICK_ACCESS.text[100]}>
                    {item.dataKey}
                  </ComponentBody2Typo>
                </Stack>

                <ComponentBody1Typo>{item.value}</ComponentBody1Typo>
              </Stack>
            </Box>
          </Box>
        ))}
      </Box>
    );
  }
  return null;
};

export default VegaDoughnutChartV4;
