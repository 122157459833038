/** @format */

import { VegaPage } from '../components/common';
import { useClientAuth } from '../providers/ClientProvider';
import { VegaClientLogoType } from '../types/client';
import VegaHeaderV4 from '../components/common/v4/VegaHeaderV4';
import Stack from '@mui/material/Stack';
import ViewCompanyProfile from '../components/morePage/ViewCompanyProfile';
import ViewUserProfile from '../components/morePage/ViewUserProfile';
import MediaDetails from '../components/morePage/MediaDetails';
import { UploadLogoType } from '../components/morePage/UploadMediaLogoContainer';
import { useState } from 'react';
import ViewLoginHistory from '../components/morePage/ViewLoginHistory';

const MoreClientOptions = () => {
  const { client, clientId, updateClientLogo } = useClientAuth();
  const [isViewHistoryOpen, setIsViewHistoryOpen] = useState(false);

  const handleUpload = (logoType: UploadLogoType, file: File) => {
    if (!clientId) return;
    updateClientLogo({
      clientId,
      logo: file,
      logoType: VegaClientLogoType.COMPANY, // TODO : Need to revisit this
    });
  };

  if (isViewHistoryOpen) {
    return <ViewLoginHistory onGoBack={() => setIsViewHistoryOpen(false)} />;
  }

  return (
    <>
      <VegaPage>
        <VegaHeaderV4 title="More" />
        <Stack px={3.25} gap={3} pb={10}>
          <ViewUserProfile
            onViewLoginHistoryClick={() => setIsViewHistoryOpen(true)}
          />
          <ViewCompanyProfile state={client} />
          <MediaDetails client={client} onUpload={handleUpload} />
        </Stack>
      </VegaPage>
    </>
  );
};

export default MoreClientOptions;
