/** @format */

import { Menu } from '@mui/icons-material';
import { Box, Divider, Stack } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppBar } from '../../providers/AppBarProvider';
import {
  VegaFullScreenDialogState,
  useVegaFullScreenDialog,
} from '../../providers/VegaFullScreenDialogProvider';
import { DASHBOARD_ROUTES } from '../../routes';
import { useAppSelector } from '../../store/hook';
import { getState } from '../../store/common/stateSlice';
import VegapayLogo from '../icon/VegapayLogo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SectionHeadingTypo } from '../common/Typography';
import ProfileDisplay from '../common/ProfileDisplay';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: any;
  isSmallScreen: any;
  pageTitle?: string;
  backButtonRequired?: boolean;
};

const MainHeader = (props: Props) => {
  const { pathname } = useLocation();
  const { setAppBarHeight } = useAppBar();
  const { showLendingPage } = useAppSelector(getState);
  const { visiblityState: dialogVisiblityState } = useVegaFullScreenDialog();
  const { setOpen, isSmallScreen, isMobile, pageTitle, backButtonRequired } =
    props;
  const navigation = useNavigate();
  const headerRef = useRef<HTMLDivElement>(null);

  const getHeaderZIndex = () => {
    switch (dialogVisiblityState) {
      case VegaFullScreenDialogState.Visible:
        return 0;
    }
    return 1500;
  };

  const goBack = () => {
    navigation(-1);
  };

  useEffect(() => {
    if (headerRef.current) {
      const updatedHeight = headerRef.current.offsetHeight;
      setAppBarHeight(updatedHeight);
    }
  }, []);

  return (
    <Stack
      sx={{
        width: '-webkit-fill-available',
        bgcolor: 'background.paper',
        py: 2,
        px: 5,
        zIndex: getHeaderZIndex(),
        borderBottom: '4px solid #EBEBEB',
      }}
    >
      <Box
        ref={headerRef}
        sx={{
          display: 'flex',
          ...(isSmallScreen || isMobile
            ? { justifyContent: 'space-between' }
            : {
                justifyContent:
                  showLendingPage && pathname === DASHBOARD_ROUTES.BACK_OFFICE
                    ? 'space-between'
                    : 'flex-end',
              }),
        }}
      >
        <Stack
          direction="row"
          justifyContent={
            isSmallScreen || isMobile || showLendingPage
              ? 'space-between'
              : 'flex-end'
          }
          alignItems="center"
          gap={1}
        >
          {(isSmallScreen || isMobile) && !showLendingPage && (
            <Menu
              onClick={() => setOpen((preVal: boolean) => !preVal)}
              className="cursor-pointer"
            />
          )}
          {showLendingPage && pathname === DASHBOARD_ROUTES.BACK_OFFICE && (
            <Box width={82} height={28}>
              <VegapayLogo width={82} height={23} />
            </Box>
          )}
          <ProfileDisplay
            displayLogo={
              !(showLendingPage && pathname === DASHBOARD_ROUTES.BACK_OFFICE)
            }
          />
        </Stack>
      </Box>
      {(pageTitle || backButtonRequired) && (
        <>
          <Divider
            sx={{
              height: '1px',
              width: 'calc(100% + 80px)',
              margin: '12px -40px',
            }}
          />
          <Stack gap={1.5} direction="row" alignItems={'center'}>
            {backButtonRequired && (
              <Box
                width={24}
                height={24}
                borderRadius={12}
                sx={{
                  backgroundColor: '#F7F7F7',
                  display: 'grid',
                  placeContent: 'center',
                }}
                className="cursor-pointer"
                onClick={goBack}
              >
                <ArrowBackIcon sx={{ fontSize: '16px', color: '#7B8694' }} />
              </Box>
            )}
            {Boolean(pageTitle) && (
              <SectionHeadingTypo>{pageTitle}</SectionHeadingTypo>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default MainHeader;
