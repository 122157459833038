/** @format */

export const formContentType = {
  'content-type': 'multipart/form-data',
};

export const BASE_URL = '';

export const ONBOARDING = {
  BASE: '/onboarding/v1',
  CLIENT: '/client',
  CUSTOMER: '/customer',
  ENTITY_PARTNER: '/client/entity/partner',
  DATA: '/data',
  MID: '/mid',
  MCC: '/mcc',
  MERCHANT: '/merchant',
};

export const CREDENTIAL_MANAGER = {
  BASE: '/credential/v1/credential',
  PROGRAM_KEY: '/program-key',
  ROLE_MANAGEMENT: {
    BASE: '/role-management',
    GET_BY_EMAIL: '/user/{email}',
    ADD_COGNITO_USER: '/addCognitoUser',
    GET_USER_BY_EMAIL: '/user/{email}',
    UPDATE_ROLE_FOR_USER: '/role',
    REVOKE_REFRESH_TOKEN: '/revokeToken',
  },
  VENDOR_SERVICE: {
    BASE: '/vendor-credential',
    GET_VENDOR_SPECS: '/getVendorCredentialSpec/{vendorId}',
  },
};

export const PROGRAM = {
  BASE: '/program/v1/program',
  CREATE: '/create',
  LIST: '/list',
  LIST_WITH_META_DATA: '/listWithMetaData',
  UPDATE_STATUS: '/status',
  SANCTION_LETTER: '/sanction-letter',
  AGREEMENT_TWO: '/agreement-two',
  LOAN_EMI_AGREEMENT: '/loan-emi-agreement',
  DELETE_SANCTION_LETTER: '/{programId}/sanction-letter',
  DELETE_AGREEMENT_TWO: '/{programId}/agreement-two',
  DELETE_LOAN_EMI_AGREEMENT: '/{programId}/loan-emi-agreement',
  DOCUMENT_URL: '/{programId}/documentFormat',
  DEFAULT_DOCUMENT_URL: '/defaultDocumentFormat',
  PROGRAM_MITC: '/{programId}/mitc',
  UPDATE_BIN: '/bin',
  UPDATE_MAX_PDD_ALLOWED: '/max-ppd-allowed',
  GENERATE_SETTLEMENT_CALENDAR: '/settlementCalendar',
  GET_SETTLEMENT_CALENDAR: '/settlementCalendar',
  PDD: '/{programId}/paymentDueDates',
  POLICY: {
    BASE: '/policy',
    POLICIES: '/client/policy',
    EFFECTIVE_PROGRAM: '/effective',
    SUBMIT_TO_CHECKER: '/policy-mapping/submit', // api does not take program id so had to define complete endpoint
    DISABLE_POLICY: '/disable/{policyId}',
    DEFAULT_POLICY: '/defaultPolicy/{policyType}',
    PROGRAM_MAPPING: '/mapping',
    ALL_POLICIES: '/mapping/default',
  },
};

export const ACCOUNT = {
  BASE: '/account/v1/account',
  LIMIT_OFFER: '/limitoffer',
  LIST: '/list/program/{programId}',
  CLOSURE: '/closure',
  FILTER: '/filter',
};

export const TRANSACTION = {
  BASE: '/transaction/v1/transaction',
  PAYMENT: '/payment',
  SURPLUS: '/listSurplusTransactions',
  WAIVER: '/waiver',
  LIST: 'list',
  DISPUTE: '/dispute',
  JOURNEY: '/transactionJourney',
  BILL: '/bill',
  BILL_REPAYMENTS: '/bill/repayments',
};

export const SUPPORT = {
  BASE: '/support/v1/support',
  FRESHDESK: '/freshdesk',
};

export const BILL = {
  BASE: '/bill/v1/bill',
  LIST: '/list',
  LOAN: '/loan',
  BILL_LATEST_BY_ACCOUNT_ID: '/latest/accountId',
  STATEMENT: '/statement',
  PHYSICAL_STATEMENT_LIST: '/statement/physical/list',
  PHYSICAL_STATEMENT_BULK_STATUS_UPDATE: '/statement/physical/status/bulk',
};

export const REWARDS = {
  BASE: '/rewards/v1',
  LIST_FOR_ACCOUNT:
    '/list/between?accountId={accountId}&startTime=2021-08-08T00:00:00Z&endTime=2025-11-21T00:00:00Z',
  CAMPAIGN: '/campaign',
  RULE: '/rule',
  INVENTORY: '/inventory',
};

export const REPORT = {
  BASE: '/reporting',
  CATEGORY: '/category',
  CATALOG: '/catalog',
  REPORT: '/report',
  ANALYTICS: '/execute/athena/report',
};

export const COLLECTION = {
  BASE: '/collection',
  DELINQUENCY_STATS: '/dpd/dpdStats',
  DELINQUENCY_HISTORY: '/dpd/get',
  DELINQUENCY_CURRENT: '/dpd/top/account',
  QUERY_COLLECTIONS: '/query/get',
  GET_PROGRAM_DELINQUENCY: '/dpd/top/{programId}',
  GET_SUMMARY_BY_DPD: '/allocation/dpd/summary',
  GET_AGENTS: '/allocation/summary',
  GET_BUCKETS: '/buckets',
  ACCOUNT_DELINQUENCY_SUMMARY: '/delinquency/history/{accountId}',
};

export const DISPUTE = {
  BASE: '/dispute/v1/dispute',
  FILTER: '/filter',
  STATUS: '/status',
  ACQUIRE_DISPUTE: '/acquireDispute',
  CHARGE_BACK: {
    BASE: '/chargeback',
    GET_CHARGEBACK: '/{chargebackId}',
    CREATE_NETWORK_CHARGEBACK: '/network',
    UPDATE_CHARGEBACK_STATUS: '/status',
    FILE_ARBITRATION: '/arbitration',
    REJECT_BY_ACQUIRER: '/acquirerRejection',
    REOPEN_CHARGEBACK: '/reopenChargeback',
    LIST_CHARGEBACK_AUDIT: '/audit/list',
    ADD_NETWORK_CHARGEBACK_DISPUTE_REASON: '/networkChargebackDisputeReason',
    GET_NETWORK_CHARGEBACK_DISPUTE_REASON:
      '/networkChargebackDisputeReason/{networkChargebackDisputeReasonId}',
    LIST_NETWORK_CHARGEBACK_DISPUTE_REASON:
      '/networkChargebackDisputeReason/list',
    ADD_CHARGEBACK_REJECTION_REASON: '/rejectionReason',
    GET_CHARGEBACK_REJECTION_REASON:
      '/rejectionReason/{chargebackRejectionReasonId}',
    LIST_CHARGEBACK_REJECTION_REASON: '/rejectionReason/list',
  },
};

export const LOS = {
  BASE: '/los/v1',
  BASE_V2: '/los/v2',
  APPLICATION: '/application',
  APPLICATION_BY_ID: '/application/{applicationId}',
  KYC_FLOW: '/kyc_flow',
  GET_KYC_FLOW: '/{programId}/kyc_flow',
  LIMIT_OFFER: '/limitOffer',
  GET_BY_PROGRAM: '/program/{programId}',
  GET_APPLICATION_BY_FILTER: '/application/customer/list',
  PASSPOSRT: '/passposrt',
  FETCH_PAN_BY_PAN_NUMBER: '/pan/fetch/{panNumber}',
  FETCH_PAN_BY_PAN_ID: '/pan/fetch/panId/{panId}',
  FETCH_MASK_PAN_BY_PAN_ID: '/pan/fetch/masked/panId/{panId}',
  FETCH_AADHAAR_BY_ADHAR_INFO_ID: '/aadhaar/fetch/aadhaarId/{aadhaarInfoId}',
  UNMASKED: '/unmasked',
  CONSENT: '/consent',
  DOWNLOAD: '/download',
};

export const ENTITY_AUTH = {
  BASE: '/authorization/v1/authorization',
  GET_STATUS: '/entityId?entityId={entityId}',
  UPDATE_STATUS: '/status',
  ADD_COMMENT: '/comments',
  UPDATE_COMMENT: '/comments/{commentId}',
  SUBMIT_TO_CHECKER: '/submitToChecker',
  SUBMIT_TO_MAKER: '/submitToMaker',
  LIST_BY_ENTITY_IDS: '/listByEntityIds',
};

export const INSTALLMENT = {
  BASE: '/installment/v1/installment',
  SCHEDULE_LIST: '/schedule/list',
  PREVIEW: '/preview/transaction',
  CONVERT_TO_EMI: '/convert/transaction',
  LIST_FOR_ACCOUNT: '/list/account/loans',
  LOAN: '/detail/loan',
  BILL_EMI: '/bill/ongoing',
};

export const WEBHOOK = {
  BASE: '/webhook/v1/webhook',
  LIST_BY_PROGRAM_ID: '/program/{programId}',
  UNSUBSCRIBE: '/unsubscribe/{webhookId}',
  CREDENTIALS: '/credentials',
  SUBSCRIBE: '/subscribe/{webhookId}',
};

export const CARD_PROCESSOR = {
  BASE: '/card/v1/card',
  REPLACE_CARD: '/replaceCard',
  GET_CARD_BY_ID: '/getCard',
  CARD_MANAGEMENT_BASE: '/card-management',
  LIST_CARD: '/listCard',
  UPDATE_CARD_STATUS: '/updateCardStatus',
  LIST_ACTIVE_CARD: '/list',
  GET_BIN_SETTINGS: '/program/{programId}/binSettings',
  GET_ADD_ON_CARD_CONFIGURATION: '/program/{programId}/addOnCardConfiguration',
  GET_CARD_SETTINGS: '/program/{programId}/cardSettings',
  SAVE_BIN_SETTINGS: '/binSettings',
  SAVE_ADD_ON_CARD_CONFIGURATION: '/addOnCardConfiguration',
  SAVE_CARD_SETTINGS: '/cardSettings',
  SAVE_CARD_DESIGN_CONFIG: '/cardSettings/cardDesign',
  GET_CARD_DESIGN_CONFIG: '/cardSettings/cardDesign',
  LIST_PHYSICAL_CARD_ORDERS: '/listPhysicalCardOrders',
  UPLOAD_BULK_CARD: '/bulk/card/tempBlock',
  BULK_CARD_LIST: '/getOrder/block/list',
  UPDATE_CARD_PREFERANCE: '/updateCustomerPreference',
  ADD_CARD: '/addCard',
  BIN: '/bin',
};

export const AUDIT = {
  BASE: '/audit/v1/audit',
  DATES: '/dates',
};

export const LEDGER = {
  BASE: '/ledger/v1/ledger',
  TCS: '/tcs/{accountId}',
  TCS_SUMMARY: '/tcs/summary/{accountId}',
};

export const FRM = {
  BASE: '/frm/v1',
  GET_DEFAULT_POLICY: '/default',
  TRANSACTIONAL: 'transactional',
};

export const INTEREST = {
  BASE: '/interest/v1/interest',
  REPORTS: '/reports',
  DPD: '/dpd',
};

export const POSTPE = {
  REWARD: '/reward/v1/list',
};
