import Grid from '@mui/material/Grid';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CredentialService,
  VendorCredential,
  VendorCredRequest,
  VendorCredSpecs,
} from '../../api/credintial';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { VegaTextField } from '../common';
import VegaDrawer from '../common/VegaDrawer';
import VegaSelectItem from '../common/VegaSelectItem';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaDrawerV4 from '../common/v4/VegaDrawerV4';
import VegaTextfieldV4 from '../common/v4/VegaTextfieldV4';
import { convertCamelCase } from '../../utils/stringUtils';
import Stack from '@mui/material/Stack';

type Props = {
  open: boolean;
  onClose: () => void;
  vendorId?: string;
  accountType: AccountType;
};

export enum AccountType {
  SANDBOX = 'SANDBOX',
  PRE_PROD = 'PRE_PROD',
  PROD = 'PROD',
}

function AccountLinkDrawer({ open, onClose, vendorId, accountType }: Props) {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [vendorSpecs, setVendorSpecs] = useState<VendorCredSpecs[]>([]);
  const [vendorCreds, setVendorCreds] = useState<VendorCredential[]>([]);
  const [updateVendorCred, setUpdatedVendorCreds] = useState<
    VendorCredRequest[]
  >([]);

  function cleanCreds() {
    setVendorCreds([]);
    setVendorSpecs([]);
    setUpdatedVendorCreds([]);
  }

  function handleClose() {
    onClose();
    cleanCreds();
  }

  async function _fetchVendorCredentials(data: {
    clientId: string;
    accountType: string;
    vendorId: string;
  }) {
    try {
      cleanCreds();
      setLoading(true);
      const response = await CredentialService.getVendorCredentials({
        clientId: data.clientId,
        accountType: data.accountType,
        vendorId: data.vendorId,
      });
      setVendorCreds(response);

      if (!response || response.length === 0) {
        _fetchVendorSpecs(data.vendorId);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function _fetchVendorSpecs(vendorId: string) {
    try {
      cleanCreds();
      setLoading(true);
      const response = await CredentialService.getVendorCredSpecs(vendorId);
      setVendorSpecs(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function _addVendorCred() {
    try {
      if (!clientId || !vendorId) return;
      setLoading(true);
      await CredentialService.addVendorCredentials({
        clientId: clientId,
        vendorId: vendorId,
        status: 'ACTIVE',
        accountType: accountType,
        vendorCredentialRequestList: updateVendorCred,
      });
      setSnackbar('Credentials added successfully', 'success');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
      handleClose();
    }
  }

  function onCredValueChange(valueType: string, value: string, index: number) {
    const updatedValue: VendorCredRequest = {
      credentialName: valueType,
      credentialValue: value,
    };

    const presentInArray = updateVendorCred.filter(
      (item) => item.credentialName == valueType
    );

    if (presentInArray.length === 0) {
      setUpdatedVendorCreds((prev) => [...prev, updatedValue]);
    } else {
      const updatedValues = updateVendorCred.map((item) => {
        if (item.credentialName == valueType) return updatedValue;
        return item;
      });

      setUpdatedVendorCreds(updatedValues);
    }
  }

  useEffect(() => {
    if (open && clientId && vendorId) {
      _fetchVendorCredentials({
        clientId,
        vendorId,
        accountType: accountType,
      });
    }
  }, [accountType, open]);

  return (
    <VegaDrawerV4
      open={open}
      onClose={handleClose}
      anchor="right"
      // submitButton={
      //   vendorCreds.length > 0 ? (
      //     <VegaContainedButton
      //       text={'Got It'}
      //       onClick={handleClose}
      //       isPrimary
      //       loading={loading}
      //     />
      //   ) : undefined
      // }
      // onSubmit={() => {
      //   _addVendorCred();
      // }}
      // loading={loading}
      // submitText={'Add'}
      submitBtn={{
        show: true,
        props: {
          value: vendorCreds.length > 0 ? 'Got It' : 'Add',
          onSubmit: vendorCreds.length > 0 ? handleClose : _addVendorCred,
          loading: loading,
        },
      }}
      header="Account Link Details"
    >
      <Stack gap={3}>
        {/* <Grid item xs={12}>
          <VegaSelectItem
            placeholder="Account Type"
            label="Account Type"
            data={Object.keys(AccountType).map((item) => {
              return {
                value: item,
                text: _.startCase(_.toLower(item)),
              };
            })}
            value={accountType}
            onChange={(e: any) => {
              setAccountType(e.target.value as string);
            }}
          />
        </Grid> */}

        {/* {vendorCreds.map((item, index) => {
          return (
            <Grid item xs={12} key={item.id}>
              <VegaTextField
                label={_.startCase(_.toLower(item.credentialName))}
                disabled
                value={item.credentialValue}
              />
            </Grid>
          );
        })} */}
        {vendorCreds.map((item, index) => {
          return (
            <VegaTextfieldV4
              header={convertCamelCase(item.credentialName)}
              key={item.id}
              disabled
              value={item.credentialValue}
              standardWidth={400}
            />
          );
        })}

        {/* {vendorSpecs.map((item, index) => {
          return (
            <Grid item xs={12} key={item.id}>
              <VegaTextField
                label={_.startCase(_.toLower(item.credentialName))}
                onChange={(e) => {
                  onCredValueChange(item.credentialName, e.target.value, index);
                }}
              />
            </Grid>
          );
        })} */}

        {vendorSpecs.map((item, index) => {
          return (
            <VegaTextfieldV4
              header={convertCamelCase(item.credentialName)}
              key={item.id}
              onChange={(e) => {
                onCredValueChange(item.credentialName, e.target.value, index);
              }}
            />
          );
        })}
      </Stack>
    </VegaDrawerV4>
  );
}

export default AccountLinkDrawer;
