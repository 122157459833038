import SupportApplicationsDetails from '../SupportApplicationDetails/SupportApplicationsDetails';
import SupportKycDetails from '../SupportKycDetails/SupportKycDetails';
import { SupportStep } from '../../../Support/SupportSearch';
import { Height } from '@mui/icons-material';

interface IProps {
  selected: string;
}

const SupportApplcaitionKycContainer = ({ selected }: IProps) => {
  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      {selected === SupportStep.APPLICATIONS ? (
        <SupportApplicationsDetails />
      ) : (
        <SupportKycDetails />
      )}
    </div>
  );
};

export default SupportApplcaitionKycContainer;
