/* eslint-disable react/jsx-pascal-case */
import { MouseEvent, useMemo, useState } from 'react';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { useBackOfficeBillListQuery } from '../../../../../../store/queries/billQueries';
import VegaTableV4 from '../../../../../common/v4/VegaTableV4';
import { getBackOfficeBillListStructuredData } from '../../../supportUtils';
import { BillStatus, IBillResponseData } from '../../../../../../types/bill';
import BillDetailMain from './BillDetails.tsx/BillDetailMain';
import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../../../common/Typography';
import VegaSearchfieldV4 from '../../../../../common/v4/VegaSearchfieldV4';
import VegaNoResults from '../../../../../common/VegaNoResults';
import { useSnackbar } from '../../../../../../providers/SnackbarProvider';
import VegaSelectV4 from '../../../../../common/v4/VegaSelectV4';
import { useKeyValHook } from '../../../../../../hooks/useKeyValHook';
import { pascalCaseFromCamelCase } from '../../../../../../utils/stringUtils';
import VegaTextfieldV4 from '../../../../../common/v4/VegaTextfieldV4';
import dayjs from 'dayjs';
import { DATE_FORMATE_SHORT } from '../../../../../../constants/url';
import VegaDateRangePickerPopoverV4 from '../../../../../common/v4/VegaDateRangePickerPopoverV4';
import { getDateInputIcons } from '../../../../../../utils/common.utils';
import useDateRangeStandardHook from '../../../../../../hooks/useDateRangeStandardHook';

interface IBillFilter {
  date?: Date;
  status?: BillStatus | 'All';
  search?: string;
  startDate?: string;
  endDate?: string;
}

type Keys = 'date' | 'status' | 'search' | 'startDate' | 'endDate';

const Bills = () => {
  const { accountData } = useSupport();
  const { setSnackbar } = useSnackbar();
  const [selectedBill, setSelectedBill] = useState<
    IBillResponseData | undefined
  >();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const updatePage2Zero = () => {
    setPage(0);
  };

  const { updateValue, values } = useKeyValHook<IBillFilter, Keys>(
    {
      status: 'All',
      search: undefined,
    },
    updatePage2Zero
  );

  const statusList =
    values.status && values.status !== 'All' ? [values.status] : undefined;

  const { startDate2Pass, endDate2Pass } = useDateRangeStandardHook(
    values.startDate,
    values.endDate
  );

  const { data, isFetching } = useBackOfficeBillListQuery({
    accountId: accountData?.accountId || '',
    pageNumber: page,
    pageSize,
    search: values.search,
    statusList,
    startDate: startDate2Pass,
    endDate: endDate2Pass,
  });

  const structuredData = getBackOfficeBillListStructuredData(
    data?.records,
    setSelectedBill,
    setSnackbar
  );

  const goBack = () => {
    setSelectedBill(undefined);
  };

  const updateRecord = (key: Keys) => (e: any) => {
    updateValue(
      key,
      key === 'search' && e.target.value === ''
        ? undefined
        : (e.target.value as string)
    );
  };

  const clearSearch = () => {
    updateValue('search', undefined);
  };

  const clearDate = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    updateValue('startDate', undefined);
    updateValue('endDate', undefined);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitAndClose = (startDate: string, endDate: string) => {
    updateValue('startDate', startDate);
    updateValue('endDate', endDate);
    handleClose();
  };

  const openRange = Boolean(anchorEl);
  const id = openRange ? 'simple-popover' : undefined;

  return selectedBill ? (
    <BillDetailMain goBack={goBack} data={selectedBill} />
  ) : (
    <Stack gap={2}>
      <PageHeadingTypo>Bills</PageHeadingTypo>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={1}>
          <VegaTextfieldV4
            onClick={handleClick}
            value={
              Boolean(values.endDate && values.endDate)
                ? `${dayjs(values.startDate).format(
                    DATE_FORMATE_SHORT
                  )} - ${dayjs(values.endDate).format(DATE_FORMATE_SHORT)}`
                : undefined
            }
            standardWidth={Boolean(values.endDate && values.endDate) ? 190 : 95}
            placeholder="By Date"
            sx={{ caretColor: 'transparent' }}
            InputProps={getDateInputIcons(Boolean(values.startDate), clearDate)}
          />
          <VegaSelectV4
            onChange={updateRecord('status')}
            value={values.status}
            options={['All', ...Object.values(BillStatus)].map((el) => ({
              name: el === 'All' ? 'All status' : pascalCaseFromCamelCase(el),
              id: el,
            }))}
            standardWidth={130}
          />
        </Stack>
        <VegaSearchfieldV4
          value={values.search}
          onChange={updateRecord('search')}
          onClear={clearSearch}
          placeholder="Search by bill Id..."
        />
      </Stack>
      {!Boolean(data?.records.length) && !isFetching ? (
        <VegaNoResults errorText="Bill list is Empty" />
      ) : (
        <VegaTableV4
          head={[
            'Bill ID',
            'Generation Date',
            'Start Date',
            'End Date',
            'Due Amount',
            'Amount Paid',
            'Actions',
            'Bill Status',
          ]}
          data={structuredData}
          pagination={{
            rowsPerPage: pageSize,
            setRowsPerPage: setPageSize,
            setPage,
            page,
            totalRecords: data?.totalItems || 0,
          }}
          isLoading={isFetching}
        />
      )}
      <VegaDateRangePickerPopoverV4
        open={openRange}
        onClose={handleClose}
        submitDateRange={submitAndClose}
        popoverProps={{
          anchorEl,
          onClose: handleClose,
          open: openRange,
          id,
        }}
        initialValues={{
          startDate: values.startDate || '',
          endDate: values.endDate || '',
        }}
      />
    </Stack>
  );
};

export default Bills;
