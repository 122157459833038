import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import { QUICK_ACCESS } from '../../constants/style';

export interface TextProps extends TypographyProps {
  component?: React.ElementType;
}

export const PageHeadingTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-600 ' + (className ?? '')}
      sx={{
        fontSize: '17px',
        lineHeight: '20.4px',
        ...sx,
      }}
    />
  );
};

export const PageSubtitleTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-400 ' + (className ?? '')}
      sx={{
        fontSize: '12px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const ActionLargeTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'cursor-pointer font-aspekta-700 ' + (className ?? '')}
      sx={{
        fontSize: '14px',
        lineHeight: '19.6px',
        color: QUICK_ACCESS.primary.blue,
        ...sx,
      }}
    />
  );
};

export const ActionMediumTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'cursor-pointer font-aspekta-700 ' + (className ?? '')}
      sx={{
        fontSize: '12px',
        lineHeight: '15.6px',
        color: QUICK_ACCESS.primary.blue,
        ...sx,
      }}
    />
  );
};

export const ActionSmallTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'cursor-pointer font-aspekta-700 ' + (className ?? '')}
      sx={{
        fontSize: '12px',
        lineHeight: '12px',
        color: QUICK_ACCESS.primary.blue,
        ...sx,
      }}
    />
  );
};

export const ListTableHead = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-700 ' + (className ?? '')}
      sx={{
        fontSize: '13px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const ListHead1Typo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-500 ' + (className ?? '')}
      sx={{
        fontSize: '13px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const ListHead2Typo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-500 ' + (className ?? '')}
      sx={{
        fontSize: '12px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const ComponentSubtitleTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-600 ' + (className ?? '')}
      sx={{
        fontSize: '10px',
        lineHeight: '12px',
        ...sx,
      }}
    />
  );
};

export const SectionHeadingTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-500 ' + (className ?? '')}
      sx={{
        fontSize: '14px',
        lineHeight: '19.6px',
        ...sx,
      }}
    />
  );
};

export const SectionHeading2Typo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-600 ' + (className ?? '')}
      sx={{
        fontSize: '14px',
        lineHeight: '19.6px',
        ...sx,
      }}
    />
  );
};

export const ListGroupHeadingTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-700 ' + (className ?? '')}
      sx={{
        fontSize: '10px',
        lineHeight: '12.4px',
        letterSpacing: '0.07em',
        textTransform: 'uppercase',
        ...sx,
      }}
    />
  );
};

export const ComponentBody1Typo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-450 ' + (className ?? '')}
      sx={{
        fontSize: '13px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const ComponentBody2Typo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-400 ' + (className ?? '')}
      sx={{
        fontSize: '12px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const LabelTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-600 ' + (className ?? '')}
      sx={{
        fontFamily: 'Aspekta',
        fontSize: '10px',
        fontStyle: 'normal',
        lineHeight: '120%' /* 12px */,
        ...sx,
      }}
    />
  );
};

export const Label2Typo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-500 ' + (className ?? '')}
      sx={{
        fontFamily: 'Aspekta',
        fontSize: '10px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.1px',
        ...sx,
      }}
    />
  );
};

export const ComponentHeadingTypo = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-700 ' + (className ?? '')}
      sx={{
        fontSize: '13px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const GenericTypo1 = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-600 ' + (className ?? '')}
      sx={{
        fontSize: '24px',
        lineHeight: '31.68px',
        ...sx,
      }}
    />
  );
};

export const GenericTypo2 = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-500 ' + (className ?? '')}
      sx={{
        fontSize: '24px',
        lineHeight: '36px',
        ...sx,
      }}
    />
  );
};

export const GenericTypo3 = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-600 ' + (className ?? '')}
      sx={{
        fontSize: '13px',
        lineHeight: '15.6px',
        ...sx,
      }}
    />
  );
};

export const GenericTypo4 = (props: TextProps) => {
  const { sx, className, ...rest } = props;
  return (
    <Typography
      {...rest}
      display="block"
      className={'font-aspekta-500 ' + (className ?? '')}
      sx={{
        fontSize: '10px',
        lineHeight: '12px',
        ...sx,
      }}
    />
  );
};
