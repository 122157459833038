import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import DataTile from '../DataTile';
import Grid from '@mui/material/Grid';
import VegaSelectV2, { VegaSelectOptionV2 } from '../../common/v2/VegaSelectV2';

type Props = {};
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthsOptions = months.map((item) => {
  const option: VegaSelectOptionV2 = {
    id: item,
    value: item,
    label: item,
  };
  return option;
});
// TODO: DATA IN THIS PAGE IS HARD CODED, NEED TO INTEGRATE WITH API
function Analytics({}: Props) {
  const [selectedMonth, setSelectedMonth] = useState(months[0]);
  return (
    <Box
      sx={{
        padding: {
          xs: 1,
          sm: 3.25,
        },
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography className="font-aspekta-600" fontSize={17}>
          Payment Due Date
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <DataTile title="GMV TD" value="12432" />
          </Grid>
          <Grid item xs={6} md={4}>
            <DataTile title="Card TD" value="78934" />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
          }}
        >
          <Typography className="font-aspekta-600" fontSize={17}>
            Key Data Points
          </Typography>
          <VegaSelectV2
            options={monthsOptions}
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value as string)}
          />
        </Box>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item xs={6} lg={3}>
            <DataTile
              title="New Users"
              value={'-'}
              containerSx={{
                minWidth: 180,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8} lg={3}>
            <DataTile
              title="New Accounts"
              value={'-'}
              containerSx={{
                minWidth: 180,
              }}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <DataTile
              title="Transactions"
              value={'-'}
              containerSx={{
                minWidth: 180,
              }}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <DataTile
              title="Card Issued"
              value={'-'}
              containerSx={{
                minWidth: 180,
              }}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <DataTile
              title="Active Customers"
              value={'-'}
              containerSx={{
                minWidth: 180,
              }}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <DataTile
              title="Spent"
              value={'-'}
              containerSx={{
                minWidth: 180,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Analytics;
