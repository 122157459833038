import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { VegaCard, VegaTable } from '../../../../components/common';
import { fetchMCCProgramList } from '../../api/Program';
import { useClientAuth } from '../../../../providers/ClientProvider';

const ListOfAllocatePolicies = ({ data, mapProgram }: any) => {
  const { clientId } = useClientAuth();
  const [programsList, setProgram] = useState<any[]>([]);

  const getProgramList = async () => {
    fetchMCCProgramList(clientId as string, 0, 1000).then((res) => {
      setProgram(res.data.records);
    });
  };

  useEffect(() => {
    getProgramList();
  }, []);

  const getProgramName = (id: any) => {
    const findProgramName = programsList.find(
      (val: any) => val.programId === id
    );
    return findProgramName?.name;
  };
  const columns: GridColDef[] = [
    {
      field: 'programId',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Program Name{' '}
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {getProgramName(props.row?.programId)}
          </Typography>
        );
      },

      flex: 1,
    },
    {
      field: 'markupFeePlan',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Markup Fee Plan
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {
              props?.row?.planDtoList?.find(
                (p: { policy_type: string }) =>
                  p?.policy_type === 'MARKUP_FEE_PLAN'
              )?.policy_name
            }
          </Typography>
        );
      },
    },
    {
      field: 'transactionLimitPlan',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Transaction Limit Plan
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        console.log(props.row);

        return (
          <Typography className="font-aspekta-500" variant="body2">
            {
              props?.row?.planDtoList?.find(
                (p: { policy_type: string }) =>
                  p?.policy_type === 'TRANSACTION_LIMIT_PLAN'
              )?.policy_name
            }
          </Typography>
        );
      },
    },
    {
      field: 'walletConfigurationPlan',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Wallet Configuration Plan{' '}
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {
              props?.row?.planDtoList?.find(
                (p: { policy_type: string }) =>
                  p?.policy_type === 'WALLET_CONFIGURATION_PLAN'
              )?.policy_name
            }
          </Typography>
        );
      },
    },
    {
      field: 'charges',
      renderHeader: () => (
        <Typography className="font-aspekta-500" variant="body2">
          Charges Plan{' '}
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <Typography className="font-aspekta-500" variant="body2">
            {
              props?.row?.planDtoList?.find(
                (p: { policy_type: string }) =>
                  p?.policy_type === 'CHARGES_PLAN'
              )?.policy_name
            }
          </Typography>
        );
      },
    },
  ];
  return (
    <VegaCard fullHeight noPad>
      <VegaTable columnDef={columns} idColumn="programId" data={data} />
    </VegaCard>
  );
};

export default ListOfAllocatePolicies;
