import axios, { AxiosResponse } from 'axios';
import { PaginatedResponse } from '../utils/api';
import { BASE_URL, INSTALLMENT } from './endpoints';
import { EMIData, EMIStatus } from '../types/emis';
import { CommonRequestQuery } from '../types/commonType';

const EMI_BASE_URL = BASE_URL + INSTALLMENT.BASE;
const EMI_LIST = EMI_BASE_URL + INSTALLMENT.SCHEDULE_LIST;

export const getEMIScheduleList = ({
  accountId,
  page,
  pageSize,
  statusList,
  q,
  startDate,
  endDate,
}: {
  accountId: string;
  statusList?: EMIStatus[];
} & CommonRequestQuery): Promise<AxiosResponse<PaginatedResponse<EMIData>>> => {
  return axios.get(EMI_LIST, {
    params: {
      accountId,
      pageNo: page ?? 0,
      pageSize: pageSize ?? 100,
      statusList,
      q,
      startDate,
      endDate,
    },
  });
};

export const getEMISchedulesFromLoanId = ({
  loanId,
}: {
  loanId: string;
}): Promise<AxiosResponse<EMIData[]>> => {
  return axios.get(`${EMI_BASE_URL}/${loanId}/schedule`);
};
