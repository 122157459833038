import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { VegaTextField } from '../../../../components/common';
import VegaSelect, {
  VegaSelectSelectOptionType,
} from '../../../../components/common/VegaSelect';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { fetchListForInventory } from '../../../Back-office/api/inventory';
import { fetchMCCProgramList } from '../../../Program-Dashboard/api/Program';
import { IProgram } from '../../../types/Program';
import { ICommonProps } from '../../../../types/commonType';
import BinSelector from '../../../components/BinSelector';

interface IProps extends ICommonProps {
  checkValue: string;
}

function CorporateCardSaleDrawerContent({
  handleChange,
  state,
  checkValue,
}: IProps) {
  const { clientId } = useClientAuth();

  const [binData, setBinData] = useState<VegaSelectSelectOptionType[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [programsList, setProgram] = useState<IProgram[]>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== programsList.length) {
        setPage(page + 1);
      }
    }
  };

  const getProgramList = async () => {
    fetchMCCProgramList(clientId as string, page, 1000).then((res) => {
      const corporatePrograms = res.data.records.filter(
        (program: any) => program.programCustomerType === 'CORPORATE'
      );

      corporatePrograms.forEach((obj: any, index: any) => {
        obj.id = obj.programId;
      });

      setProgram([...programsList, ...corporatePrograms]);
      setTotalItem(res.data.totalItems);
    });
  };

  useEffect(() => {
    getProgramList();
  }, [page]);

  // const getInventoryList = () => {
  //   fetchListForInventory(clientId as string)
  //     .then((res) => {
  //       const binArr = res.data?.map((val: any) => ({
  //         id: val.id,
  //         name: val.binName,
  //       }));
  //       setBinData(binArr);
  //     })
  //     .catch((err) => {});
  // };

  // useEffect(() => {
  //   getInventoryList();
  // }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        {checkValue === 'CREATE_ACCOUNT' ? (
          <>
            <Grid item xs={12} sm={12} mt={2}>
              <VegaSelect
                placeholder="Program Name"
                label="Program Name"
                size="small"
                key={'programId'}
                selected={state.programId ?? ''}
                onChange={(value) => handleChange('programId', value)}
                options={programsList}
                onScroll={loadMoreItems}
              />
            </Grid>
          </>
        ) : checkValue === 'GENERATE_OTP' ? (
          <Grid item xs={12} sm={12} mt={2}>
            <BinSelector
              selected={state.binId}
              name="binId"
              handleChange={(value) => handleChange('binId', value)}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12} mt={2}>
              <VegaTextField
                value={state?.id}
                onChange={(e) => handleChange('customerId', e.target.value)}
                key="customerId"
                label="Account ID"
                type={'text'}
                placeholder="Account ID"
                disabled
              />
            </Grid>
            {checkValue === 'VIEW' && (
              <Grid item xs={12} sm={12} mt={2}>
                <VegaTextField
                  value={state.card?.vendorCardNumber ?? ''}
                  onChange={(e) =>
                    handleChange('vendorCardNumber', e.target.value)
                  }
                  key="vendorCardNumber"
                  label="Kit ID"
                  type={'text'}
                  placeholder="Kit ID"
                  disabled={checkValue === 'VIEW'}
                />
              </Grid>
            )}
            {checkValue !== 'VIEW' && (
              <>
                <Grid item xs={12} sm={6} mt={2}>
                  <VegaTextField
                    value={state.otp ?? ''}
                    onChange={(e) => handleChange('otp', e.target.value)}
                    key="otp"
                    label="Enter OTP"
                    type={'text'}
                    placeholder="Enter OTP"
                  />
                </Grid>
                <Grid item xs={12} sm={6} mt={2}>
                  {/* <VegaSelect
                    options={binData}
                    key="binId"
                    selected={state.binId}
                    label="Select Bin"
                    onChange={(value) => handleChange('binId', value)}
                    disabled
                  /> */}
                  <BinSelector
                    selected={state.binId}
                    name="binId"
                    handleChange={(value) => handleChange('binId', value)}
                    disabled
                  />
                </Grid>
              </>
            )}
            {checkValue === 'VIEW' && (
              <>
                <Grid item xs={12} sm={6} mt={2}>
                  <VegaTextField
                    value={state?.card?.cardNumber}
                    onChange={(e) => handleChange('cardNo', e.target.value)}
                    key="cardNo"
                    label="Card No"
                    type={'text'}
                    placeholder="Card No"
                    disabled={checkValue === 'VIEW'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} mt={2}>
                  <VegaTextField
                    value={state?.card?.expiryDate ?? ''}
                    disabled
                    key="expiryDate"
                    label="Expiry Date"
                    placeholder="Expiry Date"
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}

export default CorporateCardSaleDrawerContent;
