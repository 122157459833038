import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../../../../common/Typography';
import {
  TransactionDto,
  TransactionSource,
} from '../../../../../../../types/transaction';
import TransactionAndMerchantDetails from './TransactionAndMerchantDetails';
import TransactionJourney from './TransactionJourney';
import VegaKeyValCardV4 from '../../../../../../common/v4/VegaKeyValCardV4';
import { pascalCaseFromCamelCase } from '../../../../../../../utils/stringUtils';

interface ITransactionOverviewProps {
  data: TransactionDto | undefined;
}

const TransactionOverview = ({ data }: ITransactionOverviewProps) => {
  const displayData = [
    [
      {
        key: 'Type',
        value: pascalCaseFromCamelCase(data?.transactionCategory || '--'),
      },
      {
        key: 'Category',
        value: pascalCaseFromCamelCase(data?.transactionCode || '--'),
      },
    ],
    [
      {
        key: 'Indicator',
        value: pascalCaseFromCamelCase(data?.transactionIndicator || '--'),
      },
      {
        key: 'Added by',
        value:
          (data?.source === TransactionSource.MANUAL
            ? `${data?.addedBy} ${data.source}`
            : data?.source) || '--',
      },
    ],
  ];

  return (
    <Stack gap={1.5}>
      <PageHeadingTypo>Overview</PageHeadingTypo>
      <Stack gap={2}>
        <TransactionAndMerchantDetails data={data} />
        <VegaKeyValCardV4 displayData={displayData} />
        <TransactionJourney data={data} />
      </Stack>
    </Stack>
  );
};

export default TransactionOverview;
