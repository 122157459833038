import { Box, Grid, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { NEW_PRIMARY } from '../../../../constants/style';
import { useProgramData } from '../../../../providers/ProgramDataProvider';
import { useSupport } from '../../../../providers/SupportProvider';
import { AccountDataDto } from '../../../../types/account';
import { VegaProgramType } from '../../../../types/program';
import Vega_Text from '../../../common/v3/Vega_Text';
import AccountCardContainer from './AccountCardContainer';

const SupportAccountDetails = () => {
  const { filteredCustomer } = useSupport();
  const { programs } = useProgramData();
  const [cardsList, setCardsList] = useState<AccountDataDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAccountDto = async (accounts: AccountDataDto[]) => {
    const accountDto = accounts?.map((account: AccountDataDto) => {
      const program = programs.find(
        (program: VegaProgramType) => program.programId === account.programId
      );
      return {
        ...account,
        accountStatus: account.status,
        programName: program?.name,
        productType: program?.type,
      };
    });

    setCardsList(accountDto);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (!filteredCustomer) return;
    if (!filteredCustomer.account) return;
    const getListAll = setTimeout(() => {
      if (filteredCustomer.account) {
        getAccountDto(filteredCustomer.account);
      }
    }, 1000);
    return () => clearTimeout(getListAll);
  }, [filteredCustomer]);
  return (
    <Box
      sx={{
        border: `1px solid #E1E4EB`,
        borderRadius: '12px',
        bgcolor: 'white',
        py: 2,
        px: 2.5,
        mt: 1,
        overflow: 'scroll',
        height: '100%',
      }}
    >
      <Vega_Text
        text={'Account'}
        fontWeight={600}
        color={NEW_PRIMARY.BLACK}
        fontSize={17}
      />
      <Grid container spacing={2} mt={1}>
        {isLoading ? (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Skeleton variant="rounded" width={'100%'} height={170} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Skeleton variant="rounded" width={'100%'} height={170} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Skeleton variant="rounded" width={'100%'} height={170} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Skeleton variant="rounded" width={'100%'} height={170} />
            </Grid>
          </>
        ) : cardsList.length ? (
          cardsList.map((card: AccountDataDto, idx: number) => {
            return <AccountCardContainer card={card} key={idx} />;
          })
        ) : (
          <Grid
            sx={{
              cursor: 'pointer',
              transition: 'all .2s ease-in-out',
              ':hover': {
                transform: 'scale(1.02)',
              },
            }}
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={4}
          >
            <Box
              sx={{
                border: `1px solid #EEEEEE`,
                borderRadius: '12px',
              }}
              height={170}
            >
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Vega_Text text={'No Card for this customer'} />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SupportAccountDetails;
