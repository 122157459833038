import { ISVGProps } from '../../mcc/types/Commons';

const LogoutIcon = (props: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M5.85354 2.40002H3.38296C3.00853 2.40002 2.64944 2.54752 2.38469 2.81007C2.11993 3.07263 1.97119 3.42872 1.97119 3.80002V12.2C1.97119 12.5713 2.11993 12.9274 2.38469 13.19C2.64944 13.4525 3.00853 13.6 3.38296 13.6H5.85354M6.029 8.00002H14.029M14.029 8.00002L10.9722 4.80002M14.029 8.00002L10.9722 11.2"
        stroke={props.stroke || '#BA1C1C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
