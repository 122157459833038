import axios from 'axios';
import {
  ICreatePartnerRequest,
  IPartner,
  ListPartnerRequest,
  UpdatePartnerRequest,
} from '../types/partner';
import { PaginatedResponse } from '../utils/api';
import { getFormData } from '../utils/common.utils';
import { BASE_URL, ONBOARDING } from '../api/endpoints';

const ONBOARDING_BASE_URL = BASE_URL + ONBOARDING.BASE;
const ONBOARDING_ENTITY_PARTNER_URL =
  ONBOARDING_BASE_URL + ONBOARDING.ENTITY_PARTNER;

class PartnerService {
  static async createPartner(
    request: ICreatePartnerRequest
  ): Promise<IPartner> {
    const response = await axios.post(
      ONBOARDING_ENTITY_PARTNER_URL,
      getFormData(request)
    );
    return response.data;
  }

  static async getPartner(id: string): Promise<IPartner> {
    const response = await axios.get(`${ONBOARDING_ENTITY_PARTNER_URL}/${id}`);
    return response.data;
  }

  static async listPartners(
    request: Partial<ListPartnerRequest>
  ): Promise<PaginatedResponse<IPartner>> {
    const response = await axios.post(
      `${ONBOARDING_ENTITY_PARTNER_URL}/list`,
      request
    );
    return response.data;
  }

  static async updatePartner(request: UpdatePartnerRequest): Promise<IPartner> {
    const response = await axios.put(
      ONBOARDING_ENTITY_PARTNER_URL,
      getFormData(request)
    );
    return response.data;
  }

  static async deletePartner(id: string) {
    const response = await axios.delete(
      `${ONBOARDING_ENTITY_PARTNER_URL}/${id}`
    );
    return response.data;
  }
}

export default PartnerService;
