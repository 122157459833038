import { CSSProperties } from 'react';
import { ICustomer } from '../../types/customers';
import { IAccount } from './Account';
import { ICard } from './Card';
import { ICreateCorporateCustomer } from './CorporateCustomer';
import { IProgram } from './Program';
import { ISubwallets } from './Wallets';

export interface IConsolidatedAccountData {
  id: string;
  customer: ICustomer | null;
  account: IAccount | null;
  subWallets: ISubwallets[] | null;
  program: IProgram | null;
  card: ICard | null;
}

export interface ICorporateConsolidatedDto {
  account: IAccount | null;
  corporate: ICreateCorporateCustomer | null;
  suballets: ISubwallets[] | null;
}

export enum CHECKER_MAKER_STATUS {
  CHECKER_PENDING = 'CHECKER_PENDING',
  REVIEWER_PENDING = 'REVIEWER_PENDING',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ENTITY_STATUS {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REJECTED = 'REJECTED',
}

export interface PaginatedResponse<T> {
  records: Array<T>;
  pageNumber: number;
  numberOfItems: number;
  totalPages: number;
  totalItems: number;
}

export interface ISVGProps {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  fill?: CSSProperties['color'];
  fillOpacity?: CSSProperties['fillOpacity'];
  stroke?: CSSProperties['stroke'];
  className?: string;
  onClick?: () => void;
}

export type ValueType =
  | 'number'
  | 'string'
  | 'jsx'
  | 'switch'
  | 'status'
  | 'link';
