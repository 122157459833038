import { ISVGProps } from '../../mcc/types/Commons';

const FolderIcon = (props: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M3.59983 3.55298V2.35298C3.59983 1.91115 3.958 1.55298 4.39983 1.55298H11.9998C12.4417 1.55298 12.7998 1.91115 12.7998 2.35298V5.55298M1.60045 12.847L1.60051 6.7641C1.60052 6.15482 1.60029 5.287 1.6001 4.65863C1.59996 4.21669 1.95818 3.85884 2.40013 3.85884H6.21227L8.05562 5.82793H13.5998C14.0416 5.82793 14.3998 6.18612 14.3998 6.62795L14.3996 12.8471C14.3996 13.7307 13.6833 14.447 12.7996 14.447L3.20045 14.447C2.31679 14.447 1.60045 13.7306 1.60045 12.847Z"
        stroke={props.stroke || '#EBEDEF'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FolderIcon;
