import { useCallback } from 'react';
import { useCustomerContext } from './CustomerWrap';
import CustomerListPage from './CustomerListPage';
import CustomerCreateCustomerFormPage from './AddCustomer/CreateCustomerFormPage';

const CustomerMainPage = () => {
  const { currState } = useCustomerContext();

  const getDisplay = useCallback(() => {
    switch (currState) {
      case 'customer-list':
        return <CustomerListPage />;
      default:
        return <CustomerCreateCustomerFormPage />;
    }
  }, [currState]);

  return getDisplay();
};

export default CustomerMainPage;
