import { Box } from '@mui/material';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { BLUE, BORDER_GRAY } from '../../constants/style';
import { usePolicy } from '../../providers/PolicyProvider';
import { DEFAULT_TRANSACTION_POLICY } from '../../types/defaultPolicy';
import { EntityAuthStatus, UserMakerCheckerRole } from '../../types/entityAuth';
import {
  PolicyComponentType,
  PolicyType,
  PolicyTransactionCode,
  TransactionLimit,
  TransactionPolicy,
} from '../../types/policy';
import { VegaTextField } from '../common';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import VegaOutLineTextField from '../common/VegaOutLineTextField';
import VegaText from '../common/VegaText';
import PolicyCard from './PolicyCard';
import PolicyCommentCard from './PolicyCommentCard';
import { PolicyWrapper } from './PolicyWrapper';

enum LimitType {
  PER_TRANSACTION_LIMIT = 'per_transaction_limit',
  DAILY_LIMIT = 'daily_limit',
  MONTHLY_LIMIT = 'monthly_limit',
  YEARLY_LIMIT = 'yearly_limit',
}

const getInitialValueForCode = (
  code: PolicyTransactionCode,
  policy?: TransactionPolicy
) => {
  const initialValue = {
    transaction_code: code,
    per_transaction_limit: 0,
    daily_limit: 0,
    monthly_limit: 0,
    yearly_limit: 0,
  };
  if (!policy || (policy?.transaction_limits ?? []).length <= 0) {
    return initialValue;
  }
  const filteredValue = policy.transaction_limits.filter(
    (item) => item.transaction_code == code
  );
  if (!filteredValue) return initialValue;
  return filteredValue[0];
};

const TRANSACTION_CODES: PolicyTransactionCode[] = Object.values(
  PolicyTransactionCode
);

const TransactionLimitView = ({
  initialValue,
  onSubmit,
  isLoading,
  onApprove,
  onReject,
  onPastCommentsClick,
}: PolicyComponentType) => {
  const {
    checkPolicyEditable,
    checkPolicyStatusUpdatable,
    entityAuthStatuses,
    loggedInMakerCheckerUser,
    updateCommentOnPolicy,
    addCommentOnPolicy,
    areAllPoliciesApproved,
    getDefaultPolicy,
  } = usePolicy();
  const [transactionPolicy, setTransactionPolicy] =
    useState<TransactionPolicy | null>(
      DEFAULT_TRANSACTION_POLICY('Default Transaction Policy')
    );
  const [eComTransactionLimit, setEComTransactionLimit] =
    useState<TransactionLimit>(
      getInitialValueForCode(PolicyTransactionCode.E_COM, initialValue)
    );
  const [posTransactionLimit, setPosTransactionLimit] =
    useState<TransactionLimit>(
      getInitialValueForCode(PolicyTransactionCode.POS, initialValue)
    );
  const [contactLessTransactionLimit, setContactLessTransactionLimit] =
    useState<TransactionLimit>(
      getInitialValueForCode(PolicyTransactionCode.CARD_NFC, initialValue)
    );
  const [atmWithdrawalTransactionLimit, setAtmWithdrawalTransactionLimit] =
    useState<TransactionLimit>(
      getInitialValueForCode(
        PolicyTransactionCode.CARD_CASH_WITHDRAW,
        initialValue
      )
    );
  const [canEditPolicy, setCanEditPolicy] = useState<boolean>(false);
  const [canUpdatePolicyStatus, setCanUpdatePolicyStatus] =
    useState<boolean>(false);
  const [entityAuthStatus, setEntityStatus] = useState<EntityAuthStatus | null>(
    null
  );

  function updateTransactionLimit(
    code: PolicyTransactionCode,
    limitType: LimitType,
    updatedValue: string
  ) {
    switch (code) {
      case PolicyTransactionCode.E_COM:
        setEComTransactionLimit((prevTransactionLimit) => ({
          ...prevTransactionLimit,
          [limitType]: Number(updatedValue),
        }));
        break;
      case PolicyTransactionCode.POS:
        setPosTransactionLimit((prevTransactionLimit) => ({
          ...prevTransactionLimit,
          [limitType]: Number(updatedValue),
        }));
        break;
      case PolicyTransactionCode.CARD_NFC:
        setContactLessTransactionLimit((prevTransactionLimit) => ({
          ...prevTransactionLimit,
          [limitType]: Number(updatedValue),
        }));
        break;
      case PolicyTransactionCode.CARD_CASH_WITHDRAW:
        setAtmWithdrawalTransactionLimit((prevTransactionLimit) => ({
          ...prevTransactionLimit,
          [limitType]: Number(updatedValue),
        }));
        break;
    }
  }

  const getTransactionLimitForCode = (code: PolicyTransactionCode) => {
    switch (code) {
      case PolicyTransactionCode.E_COM:
        return eComTransactionLimit;
      case PolicyTransactionCode.POS:
        return posTransactionLimit;
      case PolicyTransactionCode.CARD_NFC:
        return contactLessTransactionLimit;
      case PolicyTransactionCode.CARD_CASH_WITHDRAW:
        return atmWithdrawalTransactionLimit;
    }
  };

  function getValueForCode(code: PolicyTransactionCode, type: LimitType) {
    const value = getLimitTypeValue(getTransactionLimitForCode(code), type);
    return value == 0 ? '' : value;
  }

  const getLimitTypeValue = (limit: TransactionLimit, type: LimitType) => {
    switch (type) {
      case LimitType.DAILY_LIMIT:
        return limit.daily_limit;
      case LimitType.MONTHLY_LIMIT:
        return limit.monthly_limit;
      case LimitType.PER_TRANSACTION_LIMIT:
        return limit.per_transaction_limit;
      case LimitType.YEARLY_LIMIT:
        return limit.yearly_limit;
    }
  };

  const TableHeaderCell = (data: { heading: string }) => {
    return (
      <Box
        sx={{
          px: 2,
          py: 1,
          flex: 1,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {data.heading}
      </Box>
    );
  };

  function didClickSubmit() {
    if (!transactionPolicy) return;
    const updatedPolicy: TransactionPolicy = {
      ...transactionPolicy,
      transaction_limits: [
        eComTransactionLimit,
        posTransactionLimit,
        atmWithdrawalTransactionLimit,
        contactLessTransactionLimit,
      ],
    };

    onSubmit(updatedPolicy);
  }

  async function getEntityStatusAndCheckIfCanEdit() {
    const policyId = transactionPolicy?.policy_id;
    if (!policyId) {
      setCanEditPolicy(false);
      setCanUpdatePolicyStatus(false);
      setEntityStatus(null);
      return;
    }
    const response = await checkPolicyEditable(transactionPolicy);
    const canUpdateStatus = await checkPolicyStatusUpdatable(transactionPolicy);
    const entityAuthStatus = entityAuthStatuses.get(policyId);
    setCanUpdatePolicyStatus(canUpdateStatus);
    setCanEditPolicy(response);
    setEntityStatus(entityAuthStatus ?? null);
  }

  function didClickApprove() {
    if (transactionPolicy) onApprove(transactionPolicy);
  }

  function addCommentOnEntity(comment: string) {
    const policyId = transactionPolicy?.policy_id;
    if (!policyId) {
      return;
    }
    addCommentOnPolicy({
      policyId: policyId,
      comment: comment,
    });
  }

  function updateEntityComment(id: string, comment: string) {
    const policyId = transactionPolicy?.policy_id;
    if (!policyId) {
      return;
    }
    updateCommentOnPolicy({
      policyId: policyId,
      commentId: id,
      comment: comment,
    });
  }

  const canShowCommentsCard = () => {
    if (areAllPoliciesApproved) return false;
    const isMaker =
      loggedInMakerCheckerUser?.role == UserMakerCheckerRole.MAKER;
    const areCommentsPresent =
      (entityAuthStatus?.commentsList ?? []).length > 0;
    return isMaker && areCommentsPresent;
  };

  function fetchDefaultPolicy() {
    getDefaultPolicy(PolicyType.TRANSACTION)
      .then((defaultPolicy) => {
        if (defaultPolicy) {
          const transactionPolicy = defaultPolicy as TransactionPolicy;
          setTransactionPolicy(transactionPolicy);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (initialValue) setTransactionPolicy(initialValue as TransactionPolicy);
    else {
      fetchDefaultPolicy();
    }
  }, [initialValue]);

  useEffect(() => {
    if (!transactionPolicy) return;
    setEComTransactionLimit(
      getInitialValueForCode(PolicyTransactionCode.E_COM, transactionPolicy)
    );
    setPosTransactionLimit(
      getInitialValueForCode(PolicyTransactionCode.POS, transactionPolicy)
    );
    setContactLessTransactionLimit(
      getInitialValueForCode(PolicyTransactionCode.CARD_NFC, transactionPolicy)
    );
    setAtmWithdrawalTransactionLimit(
      getInitialValueForCode(
        PolicyTransactionCode.CARD_CASH_WITHDRAW,
        transactionPolicy
      )
    );
    getEntityStatusAndCheckIfCanEdit();
  }, [transactionPolicy, loggedInMakerCheckerUser]);

  return (
    <PolicyWrapper
      user={loggedInMakerCheckerUser}
      policyId={transactionPolicy?.policy_id}
      policyView={
        <Box>
          <VegaConditionalWrapper
            condition={canShowCommentsCard()}
            ifTrue={
              <PolicyCommentCard
                onPastCommentClick={() => {
                  onPastCommentsClick(transactionPolicy?.policy_id);
                }}
                comments={entityAuthStatus?.commentsList}
              />
            }
            ifFalse={undefined}
          />

          <PolicyCard
            policy={initialValue}
            onSaveClick={didClickSubmit}
            isLoading={isLoading}
            isSaveDisabled={canUpdatePolicyStatus == false}
            onApproveClick={didClickApprove}
            viewFor={loggedInMakerCheckerUser?.role}
            onCommentAddClick={addCommentOnEntity}
            policyEntityAuth={entityAuthStatus}
            onPastCommentsClick={onPastCommentsClick}
            onCommentEdit={updateEntityComment}
          >
            <Box sx={{ border: BORDER_GRAY, borderRadius: '20px' }}>
              <Box
                sx={{
                  px: 3,
                  py: 2,
                  bgcolor: BLUE.lightest,
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                }}
              >
                <VegaText text="Program level Limits" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  bgcolor: BLUE.light,
                }}
              >
                <TableHeaderCell heading="Mode" />
                <TableHeaderCell heading="Transation Limit" />
                <TableHeaderCell heading="Daily Limit" />
                <TableHeaderCell heading="Monthly Limit" />
                <TableHeaderCell heading="Annual Limit" />
              </Box>
              {TRANSACTION_CODES.map((code, index) => {
                return (
                  <Box sx={{ display: 'flex' }} key={index}>
                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        flex: 1,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {_.startCase(_.toLower(code))}
                    </Box>
                    <Box sx={{ px: 2, py: 1, flex: 1 }}>
                      <Box sx={{ display: 'flex', flex: 1 }}>
                        <Box flex={0.7} mx={0.5}>
                          <VegaTextField
                            type={'number'}
                            key={`${LimitType.PER_TRANSACTION_LIMIT} - ${code}`}
                            value={getValueForCode(
                              code,
                              LimitType.PER_TRANSACTION_LIMIT
                            )}
                            onChange={(e) => {
                              updateTransactionLimit(
                                code,
                                LimitType.PER_TRANSACTION_LIMIT,
                                e.target.value
                              );
                            }}
                            disabled={canEditPolicy == false}
                          />
                        </Box>
                        <Box flex={0.4} mx={0.5}>
                          <VegaOutLineTextField
                            key="inr"
                            value={'INR'}
                            readOnly
                            disabled
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ px: 2, py: 1, flex: 1 }}>
                      <Box sx={{ display: 'flex', flex: 1 }}>
                        <Box flex={0.7} mx={0.5}>
                          <VegaTextField
                            key={`${LimitType.DAILY_LIMIT} - ${code}`}
                            type={'number'}
                            value={getValueForCode(code, LimitType.DAILY_LIMIT)}
                            onChange={(e) => {
                              updateTransactionLimit(
                                code,
                                LimitType.DAILY_LIMIT,
                                e.target.value
                              );
                            }}
                            disabled={canEditPolicy == false}
                          />
                        </Box>
                        <Box flex={0.4} mx={0.5}>
                          <VegaOutLineTextField
                            key="inr"
                            value={'INR'}
                            readOnly
                            disabled
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ px: 2, py: 1, flex: 1 }}>
                      <Box sx={{ display: 'flex', flex: 1 }}>
                        <Box flex={0.7} mx={0.5}>
                          <VegaTextField
                            key={`${LimitType.MONTHLY_LIMIT} - ${code}`}
                            type={'number'}
                            value={getValueForCode(
                              code,
                              LimitType.MONTHLY_LIMIT
                            )}
                            onChange={(e) => {
                              updateTransactionLimit(
                                code,
                                LimitType.MONTHLY_LIMIT,
                                e.target.value
                              );
                            }}
                            disabled={canEditPolicy == false}
                          />
                        </Box>
                        <Box flex={0.4} mx={0.5}>
                          <VegaOutLineTextField
                            key="inr"
                            value={'INR'}
                            readOnly
                            disabled
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ px: 2, py: 1, flex: 1 }}>
                      <Box sx={{ display: 'flex', flex: 1 }}>
                        <Box flex={0.7} mx={0.5}>
                          <VegaTextField
                            key={`${LimitType.YEARLY_LIMIT} - ${code}`}
                            type={'number'}
                            value={getValueForCode(
                              code,
                              LimitType.YEARLY_LIMIT
                            )}
                            onChange={(e) => {
                              updateTransactionLimit(
                                code,
                                LimitType.YEARLY_LIMIT,
                                e.target.value
                              );
                            }}
                            disabled={canEditPolicy == false}
                          />
                        </Box>
                        <Box flex={0.4} mx={0.5}>
                          <VegaOutLineTextField
                            key="inr"
                            value={'INR'}
                            readOnly
                            disabled
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </PolicyCard>
        </Box>
      }
    />
  );
};

export default TransactionLimitView;
