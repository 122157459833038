import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { listBinsByIds } from '../../../api/bin';
import { QUICK_ACCESS } from '../../../constants/style';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { BinCharterRoute } from '../../../routes';
import { NewCardBin } from '../../../types/card';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import BinDetailsComponent from './BinDetailsComponent';
import ViewPastActivities from './ViewPastActivities';
import { VegaPage } from '../../common';
import VegaBreadcrumbs from '../../common/v2/VegaBreadcrumbs';
import { ComponentBody2Typo } from '../../common/Typography';
import BinDetailsTable from './BinDetailsTable';

export interface IBinProps {
  key: string;
  value: string | any;
}

const BinDetails = () => {
  const { selecteddBinId, navigateTo } = useBinCharter();
  const { setSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [binData, setBinData] = useState<NewCardBin[]>([]);

  const getBinData = async (binId: number) => {
    try {
      const bin = await listBinsByIds([binId]);

      setBinData(bin);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selecteddBinId) {
      setLoading(true);
      getBinData(selecteddBinId);
    }
  }, [selecteddBinId]);

  return (
    <VegaPage>
      <Box
        sx={{
          px: 3.25,
          bgcolor: 'white',
          pt: 3,
          pb: 1.5,
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          borderBottom: '1px solid #EEE',
        }}
      >
        <VegaBreadcrumbs
          separator="›"
          sx={{
            '& .MuiBreadcrumbs-separator': {
              color: QUICK_ACCESS.text[100],
            },
          }}
        >
          <ComponentBody2Typo
            onClick={() => navigateTo(BinCharterRoute.BIN_CONFIGURATION)}
            color={QUICK_ACCESS.text[100]}
            fontSize={12}
            sx={{
              cursor: 'pointer',
            }}
          >
            Bin Charter
          </ComponentBody2Typo>

          <ComponentBody2Typo fontSize={12} color={QUICK_ACCESS.primary.blue}>
            Bin Details
          </ComponentBody2Typo>
        </VegaBreadcrumbs>
        <BinDetailsComponent loading={loading} binData={binData} />
      </Box>

      <Stack px={3.25} gap={3}>
        <BinDetailsTable />
        <ViewPastActivities />
      </Stack>
    </VegaPage>
  );
};

export default BinDetails;
