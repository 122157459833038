/** @format */

import { createTheme } from '@mui/material';
import { PRIMARY } from '../constants/style';

const factor = 0.9;
export const appTheme = createTheme({
  palette: {
    primary: {
      main: '#131432',
      light: '#4785FF',
    },
    background: {
      paper: '#FFFFFF',
      default: '#`EFF2F7`',
    },
    text: {
      secondary: '#FFFFFF',
    },
    secondary: {
      main: '#1047DC',
    },
  },
  typography: {
    fontFamily: 'var(--font-Aspekta-500)',
    body1: {
      fontSize: 18 * factor,
      fontWeight: 500,
    },
    body2: {
      fontSize: 16 * factor,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 24 * factor,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 20 * factor,
      fontWeight: 500,
    },
    caption: {
      color: '#656565',
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          fontSize: [20 * factor, '!important'],
          fontWeight: [500, '!important'],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingLeft: 20 * factor,
          paddingRight: 20,
          borderRadius: 8,
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            '&.Mui-focused': {
              color: `${PRIMARY.main} !important`,
            },
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: PRIMARY.main,
            },
            ':hover fieldset': { borderColor: PRIMARY.main },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: '7px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#AFAFAF',
        },
      },
    },
  },
});
