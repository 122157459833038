import { Stack } from '@mui/material';
import { QUICK_ACCESS } from '../../../../constants/style';
import VegaLineChartV4 from '../../../common/Charts/VegaLineChartV4';
import { PageHeadingTypo } from '../../../common/Typography';

const CollectionCustDPDBucket = () => {
  const tickMax = 450;
  return (
    <Stack
      flex={1}
      p={2}
      gap={3}
      sx={{
        backgroundColor: QUICK_ACCESS.background[50],
        border: `1px solid ${QUICK_ACCESS.border[50]}`,
        borderRadius: '16px',
      }}
    >
      <PageHeadingTypo>
        Customer Count/ Overdue Amount by DPD Bucket
      </PageHeadingTypo>
      <VegaLineChartV4
        YAxisLabels={{
          left: {
            text: 'Amount of Overdue',
            sx: { color: QUICK_ACCESS.primary.blue },
          },
          right: {
            text: 'Count of Customer',
            sx: { color: QUICK_ACCESS.logo.orange },
          },
        }}
        linePropsArray={[
          {
            stroke: QUICK_ACCESS.primary.blue,
            dataKey: 'value',
            name: 'Amount of Overdue',
            data: line1Data,
            yAxisId: 'left',
          },
          {
            stroke: QUICK_ACCESS.logo.orange,
            dataKey: 'value',
            name: 'Count of Customer',
            data: line2Data,
            yAxisId: 'right',
          },
        ]}
        XAxisData={{
          dataKey: 'date',
          type: 'category',
          allowDuplicatedCategory: false,
        }}
        YAxisData={{
          dataKey: 'value',
          yAxisId: 'left',
          tick: { fill: QUICK_ACCESS.primary.blue, fontWeight: 600 },
        }}
        YAxisData2={{
          dataKey: 'value',
          yAxisId: 'right',
          orientation: 'right',
          tick: { fill: QUICK_ACCESS.logo.orange, fontWeight: 600 },
          ticks: [
            0,
            tickMax / 5,
            (tickMax / 5) * 2,
            (tickMax / 5) * 3,
            (tickMax / 5) * 4,
          ],
        }}
        wrapperSx={{
          p: 2,
        }}
        respContainerData={{ height: 432 }}
        legendRequired
      />
    </Stack>
  );
};

const line1Data = [
  { date: 'x', value: 3000 },
  { date: '1-30', value: 4200 },
  { date: '31-60', value: 1237 },
  { date: '61-90', value: 688 },
  { date: 'NPA', value: 3232 },
];

const line2Data = [
  { date: 'x', value: 200 },
  { date: '1-30', value: 322 },
  { date: '31-60', value: 100 },
  { date: '61-90', value: 50 },
  { date: 'NPA', value: 82 },
];

export default CollectionCustDPDBucket;
