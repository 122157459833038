import { Stack } from '@mui/material';
import VegaDrawerV4 from '../common/v4/VegaDrawerV4';
import { Controller, useForm } from 'react-hook-form';
import VegaTextfieldV4 from '../common/v4/VegaTextfieldV4';
import { VALIDATE_REGEX } from '../../utils/validation.utils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import VegaDatePickerV4 from '../common/v4/VegaDatePickerV4';
import VegaSelectV4 from '../common/v4/VegaSelectV4';
import { INDIAN_STATES } from '../../enums/commons';

interface IInstitutionStandardAddHolidayFormProps {
  open: boolean;
  setOpen: (val: boolean) => () => void;
}

interface IHolidayFormValues {
  name: string;
  date: Date;
  state: string;
}

const InstitutionStandardAddHolidayForm = ({
  open,
  setOpen,
}: IInstitutionStandardAddHolidayFormProps) => {
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<IHolidayFormValues>({
    mode: 'onTouched',
  });

  const submitForm = () => {
    setOpen(false)();
    reset();
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <VegaDrawerV4
      header="Add Bank Holiday"
      onClose={setOpen(false)}
      open={open}
      submitBtn={{
        show: true,
        props: {
          value: 'Add Bank Holiday',
          onClick: handleSubmit(submitForm),
        },
      }}
    >
      <Stack gap={3}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.COMPANY_NAME,
              message: 'Not a valid Holiday name',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.name)}
              header="Holiday Name"
              labelText={errors.name?.message}
              standardWidth={400}
              required
              placeholder="Enter Holiday Name"
            />
          )}
        />
        <Controller
          name="date"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => {
            return (
              <VegaDatePickerV4
                dateProps={{ ...rest, views: ['month', 'day'] }}
                textProps={{
                  header: 'Date',
                  required: true,
                  labelText: errors.date?.message,
                  error: Boolean(errors.date),
                  standardWidth: 400,
                }}
              />
            );
          }}
        />
        <Controller
          name="state"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaSelectV4
              {...rest}
              error={Boolean(errors.state)}
              header="States"
              labelText={errors.state?.message}
              value={rest.value || ([] as any)}
              multiple
              standardWidth={400}
              options={Object.values(INDIAN_STATES).map((el) => ({
                name: el,
                id: el,
              }))}
              required
              placeholder="Select States"
            />
          )}
        />
      </Stack>
    </VegaDrawerV4>
  );
};

export default InstitutionStandardAddHolidayForm;
