import { useEffect, useState } from 'react';
import { VegaPage } from '../../../components/common';
import { CreditCardIcon, UserIcon } from '../../../components/icon/Icons';
import { BACKGROUND } from '../../../constants/style';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  allocateCard,
  createAccount,
  createForexAccount,
  fetchAccountList,
  generateOTPForCard,
  initiateCheckerMakerForAccount,
} from '../../Back-office/api/account';
import MCCFilterSection from '../../Back-office/components/MCCUserManagement/MCCFilterSection';
import { CHECKER_MAKER_STATUS } from '../../types/Commons';
import ListOfCardSaleMakerCorporate from '../components/CorporateCardSale/ListOfCardSaleMakerCorporate';

let tileData = [
  {
    id: 'KIT',
    text: 'Kit',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'PERSONALISED_SALE',
    text: 'Personalised Sale',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'REISSUE',
    text: 'Reissue',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
];

const CorporateCardSale = () => {
  const { setSnackbar } = useSnackbar();
  const { userId, mccPlatformId: corporateId } = useClientAuth();
  const [accountData, setAccountData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>({});
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [isMapCardShow, setIsMapCardShow] = useState<boolean>(false);
  const [hide, setHide] = useState<boolean>(false);
  const [activeState, setActiveState] = useState<any>(tileData[0]);

  const handleChange = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const onCreate = (data: any) => {
    const obj = {
      customerId: data.customer.customerId,
      entityId: data?.customer?.branchId ?? data?.customer?.entityId,
      branchId: data?.customer?.branchId ?? data?.customer?.entityId,
      programId: data.programId,
      corporateCustomerId: corporateId,
    };

    createForexAccount(obj)
      .then((res) => {
        setSnackbar('Account Created!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to create account'),
          'error'
        );
      });
  };
  const onGenerateOTP = (data: any) => {
    setHide(true);
    const obj = {
      binId: data.binId,
      accountId: data.id,
    };

    generateOTPForCard(obj)
      .then((res) => {
        setSnackbar('OTP generated successfully for card!!');
        getCustomerList();
        setIsMapCardShow(true);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to generate the OTP'),
          'error'
        );
      });
    setTimeout(() => {
      setIsMapCardShow(false);
      setHide(false);
    }, 30000);
  };

  const onAllocateCard = (data: any) => {
    const obj = {
      binId: data.binId,
      accountId: data.id,
      otp: data.otp,
    };

    allocateCard(obj)
      .then((res) => {
        setSnackbar('Card allocate successfully !!');
        getCustomerList();
        setIsMapCardShow(true);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to allocate card'),
          'error'
        );
      });
  };

  const onCheckerStatus = (data: any) => {
    const obj = {
      userId,
      entityId: data.id,
      initialStatus: CHECKER_MAKER_STATUS.REVIEWER_PENDING,
    };

    initiateCheckerMakerForAccount(obj)
      .then((res) => {
        setSnackbar('Send Checker successfully!!');
        getCustomerList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send checker'),
          'error'
        );
      });
  };

  const getCustomerList = async () => {
    setListLoading(true);
    fetchAccountList({
      corporateId: corporateId,
      value: filterData.mobile,
      page: 0,
      pageSize: 50,
    })
      .then((res) => {
        const getData = res.data.records?.map((value: any, index: number) => ({
          ...value,
          id: value?.account?.id || value?.customer?.id || index,
        }));
        setAccountData(getData);

        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };
  useEffect(() => {
    getCustomerList();
  }, []);

  return (
    <VegaPage>
      <MCCFilterSection
        state={filterData}
        handleChange={handleChange}
        onSearch={getCustomerList}
        isSearch
      />
      <ListOfCardSaleMakerCorporate
        onCheckerStatus={onCheckerStatus}
        data={accountData}
        active={activeState}
        loading={listLoading}
        onCreate={onCreate}
        onGenerateOTP={onGenerateOTP}
        onAllocateCard={onAllocateCard}
        isMapCardShow={isMapCardShow}
        hide={hide}
      />
    </VegaPage>
  );
};

export default CorporateCardSale;
