import VegaLineChartV4 from '../../../common/Charts/VegaLineChartV4';
import { RepaymentGraphDataProps } from '../../../../types/analytics';
import { ANALYTICS_QUERY_NAME } from '../../../../enums/analytics.enum';
import { QUICK_ACCESS } from '../../../../constants/style';
import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../common/Typography';
import VegaFilterButtonsV4 from '../../../common/v4/VegaFilterBtnsV4';
import { useAnalyticsHook } from '../../../../hooks/useAnalyticsHook';

const RepaymentAnalytics = () => {
  const {
    onFilterCellClick,
    submitDateRange,
    onCloseCustomRangeClick,
    structuredGraphData,
    activeIdx,
    filterOptions,
  } = useAnalyticsHook<RepaymentGraphDataProps, undefined, undefined>({
    graphProps: ['month', 'created_at', 'total_repayment_amount'],
    graphLabel: 'Repayment Amount',
    graphQueryNames: {
      monthly: ANALYTICS_QUERY_NAME.CCMS_REPAYMENT_AMOUNT_MONTHLY_DATA,
      daily: ANALYTICS_QUERY_NAME.CCMS_REPAYMENT_AMOUNT_DATE_BY_DATE,
    },
    filterOptionsType: 'longer',
  });

  return (
    <Stack
      px={2}
      py={3}
      gap={4}
      sx={{
        backgroundColor: QUICK_ACCESS.background[50],
        border: `1px solid ${QUICK_ACCESS.border[50]}`,
        borderRadius: '16px',
      }}
    >
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="space-between"
        flex={1}
      >
        <PageHeadingTypo>Repayment</PageHeadingTypo>
        <VegaFilterButtonsV4
          data={filterOptions}
          activeIdx={activeIdx}
          onCellClick={onFilterCellClick}
          submitDateRange={submitDateRange}
          customIdx={0}
          onCloseCustomRangeClick={onCloseCustomRangeClick}
        />
      </Stack>
      <Stack flex={1}>
        <VegaLineChartV4
          YAxisLabels={{
            left: { text: 'Repayment Amount' },
          }}
          mainData={structuredGraphData}
          linePropsArray={[
            { stroke: QUICK_ACCESS.primary.blue, dataKey: 'Repayment Amount' },
          ]}
          XAxisData={{ dataKey: 'date' }}
          wrapperSx={{
            p: 2,
          }}
          respContainerData={{ height: 432 }}
        />
      </Stack>
    </Stack>
  );
};

export default RepaymentAnalytics;
