import { Box, Stack } from '@mui/material';
import React from 'react';
import { LINEAR_GRADIANT_COLOR } from '../../../constants/style';
import VegapayLogo from '../../icon/VegapayLogo';

const CustomerLoadingPage = () => {
  return (
    <Box>
      <Stack
        gap={3}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          height: 'calc(100vh - 69px)',
          background: LINEAR_GRADIANT_COLOR.BACKGROUND,
          width: '100%',
        }}
      >
        <Box>
          <VegapayLogo />
        </Box>
        <div className="snippet" data-title="dot-pulse">
          <div className="stage">
            <div className="dot-pulse"></div>
          </div>
        </div>
      </Stack>
    </Box>
  );
};

export default CustomerLoadingPage;
