import { Stack } from '@mui/material';
import VegaTableV4 from '../../../common/v4/VegaTableV4';
import { PageHeadingTypo } from '../../../common/Typography';
import VegaLineChartV4 from '../../../common/Charts/VegaLineChartV4';
import { INDICATIVE, QUICK_ACCESS } from '../../../../constants/style';
import VegaFilterButtonsV4 from '../../../common/v4/VegaFilterBtnsV4';
import { ANALYTICS_QUERY_NAME } from '../../../../enums/analytics.enum';
import { ITransactionSummary } from '../../../../types/analytics';
import { getTransactionSummaryData } from '../analyticsUtils';
import { useAnalyticsHook } from '../../../../hooks/useAnalyticsHook';

const TransactionSummaryAnalytics = () => {
  const {
    onFilterCellClick,
    submitDateRange,
    onCloseCustomRangeClick,
    structuredGraphData,
    structuredTableData,
    activeIdx,
    filterOptions,
    tableData,
    isTableDataFetching,
    page,
    pageSize,
    setPage,
    setPageSize,
  } = useAnalyticsHook<ITransactionSummary, undefined, ITransactionSummary>({
    graphProps: ['month', 'transaction_date', 'total_transaction_amount'],
    graphLabel: 'Balance',
    graphQueryNames: {
      monthly: ANALYTICS_QUERY_NAME.CCMS_TRANSACTION_AMOUNT_MONTHLY_DATA,
      daily: ANALYTICS_QUERY_NAME.CCMS_TRANSACTION_AMOUNT_BY_CATEGORY_FOR_DATE,
    },
    tableQueryName:
      ANALYTICS_QUERY_NAME.CCMS_TRANSACTION_AMOUNT_BY_CATEGORY_FOR_DATE,
    filterOptionsType: 'longer',
    tableDataFunction: getTransactionSummaryData,
  });

  return (
    <Stack gap={3}>
      <Stack gap={2}>
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <PageHeadingTypo>Transaction Summary</PageHeadingTypo>
        </Stack>
        <VegaTableV4
          head={[
            'Date',
            'Total Txn Count',
            'Total Txn Volume',
            'Ecom Txn Count',
            'Ecom Txn Volume',
            'POS Txn Count',
            'POS Txn Volume',
            'Cash Withdrawal Count',
            'Cash Volume',
            'NFC Txn Count',
            'NFC Txn Volume',
          ]}
          data={structuredTableData}
          isLoading={isTableDataFetching}
          pagination={{
            rowsPerPage: pageSize,
            setRowsPerPage: setPageSize,
            setPage,
            page,
            totalRecords: tableData?.totalItems || 0,
          }}
        />
      </Stack>
      <Stack
        px={2}
        py={3}
        gap={4}
        sx={{
          backgroundColor: QUICK_ACCESS.background[50],
          border: `1px solid ${QUICK_ACCESS.border[50]}`,
          borderRadius: '16px',
        }}
      >
        <Stack
          direction="row"
          gap={2}
          flexWrap="wrap"
          justifyContent="space-between"
          flex={1}
        >
          <PageHeadingTypo>Transaction Amount</PageHeadingTypo>
          <VegaFilterButtonsV4
            data={filterOptions}
            activeIdx={activeIdx}
            onCellClick={onFilterCellClick}
            submitDateRange={submitDateRange}
            customIdx={0}
            onCloseCustomRangeClick={onCloseCustomRangeClick}
          />
        </Stack>
        <Stack flex={1}>
          <VegaLineChartV4
            YAxisLabels={{
              left: { text: 'Balance' },
            }}
            mainData={structuredGraphData}
            linePropsArray={[
              { stroke: INDICATIVE.green[500], dataKey: 'Balance' },
            ]}
            XAxisData={{ dataKey: 'date' }}
            wrapperSx={{
              p: 2,
            }}
            respContainerData={{ height: 432 }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TransactionSummaryAnalytics;
