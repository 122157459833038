import { AddCircleOutline } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { GREY, PRIMARY } from '../../../constants/style';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import { useClientAuth } from '../../../providers/ClientProvider';
import { BinCharterRoute } from '../../../routes';
import { IProgramDtoProps } from '../../../types/binType';
import { VegaNewBin } from '../../../types/client';
import { VegaProgramType } from '../../../types/program';
import VegaTextButton from '../../common/VegaTextButton';
import VegaDataGridV2 from '../../common/v2/VegaDataGridV2';
import { getLogoForNetwork } from '../../icon/Icons';
import { convertCamelCase } from '../../../utils/stringUtils';
import VegaDataGridV4 from '../../common/v4/VegaDataGridV4';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import { ComponentBody1Typo } from '../../common/Typography';

type Props = {
  bins: VegaNewBin[];
  programs: VegaProgramType[];
  onAddClick: (id: string) => void;
  page: number;
  size: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
};

const ListOfBinCharter = () => {
  const {
    page,
    size,
    rowCount,
    binList,
    listLoading,
    navigateTo,
    openAddProgram,
    onPageChange,
    onPageSizeChange,
    getListBinForClient,
    setIsBinDetails,
    setSelectedBinId,
    getFormFactorIdForClient,
  } = useBinCharter();
  const { clientId } = useClientAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (clientId) {
      getFormFactorIdForClient(clientId);
    }
  }, [clientId]);

  const getBinList = async (clientId: string) => {
    setLoading(true);
    await getListBinForClient(clientId);
    setLoading(false);
  };
  useEffect(() => {
    if (clientId) {
      getBinList(clientId);
    }
  }, [clientId, page, size]);

  const onViewDetails = (id: any) => {
    setSelectedBinId(id);
    // setIsBinDetails(true);
    navigateTo(BinCharterRoute.BIN_DETAILS);
  };

  const columnRef: GridColumns = [
    {
      field: 'binName',
      headerName: 'Bin Name',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'binValue',
      headerName: 'Bin value',
      flex: 0.5,
      minWidth: 80,
    },
    {
      field: 'binType',
      headerName: 'Bin type',
      flex: 0.6,
      minWidth: 100,
      valueFormatter: ({ value }) => (value ? convertCamelCase(value) : '-'),
    },
    {
      field: 'network',
      headerName: 'Network',
      flex: 0.5,
      minWidth: 80,

      renderCell: (props: any) => {
        const network = props.row.network;
        if (network) {
          return <div>{getLogoForNetwork(network)}</div>;
        }
        return <>-</>;
      },
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      flex: 1,
      minWidth: 100,

      renderCell: (props: any) => {
        const programList = props.row.programName as IProgramDtoProps[];
        if (!programList?.length) return '-';
        return (
          <Stack direction={'row'} gap={0.5} sx={{ flexWrap: 'wrap' }}>
            <RenderProgramList programDto={programList} />
          </Stack>
        );
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 100,
      cellClassName: 'autoHeight',
      renderCell: (props: any) => {
        return props.row.binType === 'SHARED' ? (
          <VegaButtonV4
            value="Add Program"
            onClick={() => openAddProgram(props.row)}
            variant="text"
          />
        ) : (
          ''
        );
      },
    },
  ];

  return (
    <VegaDataGridV4
      columns={columnRef}
      data={binList}
      idColumn={'id'}
      paginationMode={'server'}
      page={page}
      pageSize={size}
      classes={{ row: 'customRow' }}
      rowCount={rowCount}
      loading={listLoading}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onCellClick={(params: GridCellParams<any>) => {
        if (params.field !== 'action') {
          onViewDetails(params.id);
        }
      }}
    />
  );
};

export default ListOfBinCharter;

const RenderProgramList = ({ programDto }: { programDto: any[] }) => {
  return (
    <Tooltip
      title={
        <>
          <ul style={{ paddingInlineStart: '16px' }}>
            {programDto.map((program, index) => (
              <li>
                <ComponentBody1Typo key={index}>
                  {program.programDto?.name}
                </ComponentBody1Typo>
              </li>
            ))}
          </ul>
        </>
      }
      arrow
    >
      <Stack>
        {programDto.slice(0, 2).map((program, index) => (
          <ComponentBody1Typo key={index}>
            {program.programDto?.name}
            {index === 1 && programDto.length > 2
              ? ` +${programDto.length - 2}`
              : ','}
          </ComponentBody1Typo>
        ))}
      </Stack>
    </Tooltip>
  );
};
