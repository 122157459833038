import { useState, useEffect } from 'react';
import { VegaPage } from '../../../components/common';

import CreateProgram from '../components/MCCProgram/CreateProgram';
import {
  createProgramForMCC,
  fetchMCCProgramList,
  updateProgramForMCC,
} from '../api/Program';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import ListOfMCCProgram from '../components/MCCProgram/ListOfMCCProgram';
import { GridCallbackDetails } from '@mui/x-data-grid';
import { NETWORKS } from '../../../components/ClientHome/AddProgram';
import { useClientAuth } from '../../../providers/ClientProvider';
import { VegaUpdateProgramType } from '../../../types/program';
import { updateExistingProgram } from '../../../api/program';

export interface IProgramProps {
  name: string;
  programCustomerType: string;
  type?: string;
  network: any;
  tnc: any;
  agreement: any;
  status: string;
}

const DEFAULT_PROGRAM = {
  name: '',
  type: 'FOREX',
  category: 'FOREX_CARD',
  issuanceType: 'SELF',
  programCustomerType: 'CORPORATE',
  network: NETWORKS[0],
  tnc: null,
  agreement: null,
  status: 'PROD',
};

function MCCProgram() {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [programDetails, setProgramDetails] = useState<IProgramProps>({
    ...DEFAULT_PROGRAM,
  });
  const [data, setData] = useState({
    loading: true,
    rows: [],
    totalRows: 0,
    rowCount: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 10,
    page: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (name: string, value: any) => {
    console.log('name,value', name, value);
    setProgramDetails({ ...programDetails, [name]: value });
  };

  const createProgram = () => {
    setIsLoading(true);
    const formData = new FormData();
    const newObj: any = {
      ...programDetails,
      clientId,
      network: programDetails.network.name
        ? programDetails.network.name
        : programDetails.network,
    };
    console.log('programDetails', newObj);
    Object.keys(newObj)
      .filter((key) => newObj[key] != null)
      .map((value: any) => {
        formData.append(value, newObj[value]);
      });
    createProgramForMCC(formData)
      .then((res) => {
        setSnackbar('Program added!');
        setIsLoading(false);
        getProgramList();
        clearProgram();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add program'),
          'error'
        );
        setIsLoading(false);
        clearProgram();
      });
  };
  const clearProgram = () => {
    setProgramDetails({ ...DEFAULT_PROGRAM });
  };

  const updateProgram = async (data: VegaUpdateProgramType) => {
    await updateExistingProgram(data)
      .then((res) => {
        setSnackbar('Program Updated!');
        setIsLoading(false);
        getProgramList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to update program'),
          'error'
        );
        setIsLoading(false);
        clearProgram();
      });
  };

  const viewProgram = () => {
    //
  };

  const onPageChange = (pageNumber: number) => {
    updateData('page', pageNumber);
  };

  const onPageSizeChange = (pageSizeNumber: number) => {
    updateData('pageSize', pageSizeNumber);
  };

  const updateData = (k: any, v: any) =>
    setData((prev) => ({ ...prev, [k]: v }));

  const getProgramList = () => {
    fetchMCCProgramList(clientId as string, data.page, data.pageSize)
      .then((res) => {
        console.log('fetchListOfResources', res);
        updateData('loading', false);
        updateData('rows', res.data.records);
        updateData('rowCount', res.data.totalItems);
      })
      .catch((err) => {
        updateData('loading', false);
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      });
  };

  useEffect(() => {
    const getListAll = setTimeout(() => {
      updateData('loading', true);
      getProgramList();
    }, 1000);
    return () => clearTimeout(getListAll);
  }, [data.page, data.pageSize]);

  return (
    <VegaPage>
      <CreateProgram
        state={programDetails}
        handleChange={handleChange}
        onClear={clearProgram}
        onSubmit={createProgram}
        loading={isLoading}
      />
      {/* ------------------------------------PROGRAM LIST------------------------------------ */}

      <ListOfMCCProgram
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        page={data.page}
        pageSize={data.pageSize}
        data={data.rows}
        rowCount={data.rowCount}
        loading={data.loading}
        updateProgram={updateProgram}
      />
    </VegaPage>
  );
}

export default MCCProgram;

const ProgramsList = [
  {
    programId: 'programId1',
    programStatus: 'PRE_PROD',
    programName: 'Ebix Cash1',
    network: 'Mastercard',
    agreement: 'Agreement.pdf',
    program_type: 'RETAIL',
  },
  {
    programId: 'programId',
    programStatus: 'PROD',
    programName: 'Ebix Cash2',
    network: 'Visa',
    agreement: 'Agreement.pdf',
    program_type: 'CORPORATE',
  },
];
