import { Box, Stack } from '@mui/material';
import VegapayLogo from '../../icon/VegapayLogo';
import { LINEAR_GRADIANT_COLOR } from '../../../constants/style';
import Vega_OutlineTextField from '../../common/v3/Vega_OutlineTextField';
import { ChangeEvent } from 'react';
import { Search } from '@mui/icons-material';
import Vega_Button from '../../common/v3/Vega_Button';
import { useSupport } from '../../../providers/SupportProvider';
import CustomerLoadingPage from './CustomerLoadingPage';

const FilterPage = () => {
  const { throttledGetFilterCustomer, filter, setFilter, loading } =
    useSupport();
  return loading ? (
    <CustomerLoadingPage />
  ) : (
    <Stack
      gap={3}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{
        height: 'calc(100vh - 69px)',
        background: LINEAR_GRADIANT_COLOR.BACKGROUND,
        width: '100%',
      }}
    >
      <Box>
        <VegapayLogo />
      </Box>
      <Stack direction={'row'} sx={{ width: '70%', maxWidth: 625 }}>
        <Vega_OutlineTextField
          fullWidth
          fixWidth={'100%'}
          value={filter}
          startAdornment={<Search sx={{ color: '#999DA8' }} />}
          placeholder="Search by  Email ID/ Mobile Number/ CIF"
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setFilter(e.target.value)
          }
        />
      </Stack>
      <Stack direction={'row'} sx={{ width: '50%', maxWidth: 352 }}>
        <Vega_Button
          text="Search"
          onClick={() => {
            throttledGetFilterCustomer();
          }}
          disabled={!filter.length}
          loading={loading}
          sx={{
            width: '100%',
          }}
        />
      </Stack>
    </Stack>
  );
};

export default FilterPage;
