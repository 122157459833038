import React from 'react';
import NoResultsIcon from '../icon/NoResultsIcon';
import { PageHeadingTypo } from './Typography';
import Stack, { StackProps } from '@mui/material/Stack';

type VegaNoResultsProps = {
  errorText?: string;
} & StackProps;

function VegaNoResults({
  errorText = 'No Results Found',
  sx,
  ...rest
}: VegaNoResultsProps) {
  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        ...sx,
      }}
      {...rest}
    >
      <Stack
        sx={{
          width: '241px',
          height: '197px',
        }}
      >
        <NoResultsIcon />
      </Stack>
      <PageHeadingTypo>{errorText}</PageHeadingTypo>
    </Stack>
  );
}

export default VegaNoResults;
