import { IconButton, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { VendorInfo } from '../../types/vendor';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import AccountLinkDrawer, { AccountType } from './AccountLinkDrawer';
import VegaDataGridV4 from '../common/v4/VegaDataGridV4';
import { GridColDef } from '@mui/x-data-grid';
import { convertCamelCase } from '../../utils/stringUtils';
import { VegaSwitchV4 } from '../common/v4/VegaSwitchV4';
import { ComponentBody1Typo } from '../common/Typography';
import VegaButtonV4 from '../common/v4/VegaButtonV4';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { QUICK_ACCESS } from '../../constants/style';
import {
  VendorCredSpecs,
  VendorCredential,
  VendorCredRequest,
  CredentialService,
} from '../../api/credintial';
import EyeOpenIcon from '../icon/EyeOpenIcon';
// Comment

type AccountLinkDrawerData = {
  open: boolean;
  vendorId?: string;
  accountType: AccountType;
};
const ListOfServiceSetup = ({ data }: any) => {
  const [showDrawer, setShowDrawer] = useState<AccountLinkDrawerData>({
    open: false,
    accountType: AccountType.SANDBOX,
  });
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [accountType, setAccountType] = useState<string>(AccountType.SANDBOX);
  const [vendorSpecs, setVendorSpecs] = useState<VendorCredSpecs[]>([]);
  const [vendorCreds, setVendorCreds] = useState<VendorCredential[]>([]);
  const [updateVendorCred, setUpdatedVendorCreds] = useState<
    VendorCredRequest[]
  >([]);

  function cleanCreds() {
    setVendorCreds([]);
    setVendorSpecs([]);
    setUpdatedVendorCreds([]);
  }

  async function _fetchVendorCredentials(data: {
    clientId: string;
    accountType: string;
    vendorId: string;
  }) {
    try {
      cleanCreds();
      setLoading(true);
      const response = await CredentialService.getVendorCredentials({
        clientId: data.clientId,
        accountType: data.accountType,
        vendorId: data.vendorId,
      });
      setVendorCreds(response);

      if (!response || response.length === 0) {
        _fetchVendorSpecs(data.vendorId);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function _fetchVendorSpecs(vendorId: string) {
    try {
      cleanCreds();
      setLoading(true);
      const response = await CredentialService.getVendorCredSpecs(vendorId);
      setVendorSpecs(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function _addVendorCred(vendorId: string) {
    try {
      if (!clientId || !vendorId) return;
      setLoading(true);
      await CredentialService.addVendorCredentials({
        clientId: clientId,
        vendorId: vendorId,
        status: 'ACTIVE',
        accountType: accountType,
        vendorCredentialRequestList: updateVendorCred,
      });
      setSnackbar('Credentials added successfully', 'success');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function onCredValueChange(valueType: string, value: string, index: number) {
    const updatedValue: VendorCredRequest = {
      credentialName: valueType,
      credentialValue: value,
    };

    const presentInArray = updateVendorCred.filter(
      (item) => item.credentialName == valueType
    );

    if (presentInArray.length === 0) {
      setUpdatedVendorCreds((prev) => [...prev, updatedValue]);
    } else {
      const updatedValues = updateVendorCred.map((item) => {
        if (item.credentialName == valueType) return updatedValue;
        return item;
      });

      setUpdatedVendorCreds(updatedValues);
    }
  }
  const columnRef = useMemo(() => getColumnRef(setShowDrawer), []);
  return (
    <>
      <VegaDataGridV4
        data={data}
        columns={columnRef}
        idColumn={'id'}
        bgColor="transparent"
        paginationMode="client"
        rowsPerPageOptions={[5, 10, 15]}
        pageSize={10}
      />
      <AccountLinkDrawer
        open={showDrawer.open}
        vendorId={showDrawer.vendorId}
        accountType={showDrawer.accountType}
        onClose={function (): void {
          setShowDrawer({
            open: false,
            vendorId: undefined,
            accountType: AccountType.SANDBOX,
          });
        }}
      />
    </>
  );
};

const getColumnRef = (
  setShowDrawer: (data: AccountLinkDrawerData) => void
): GridColDef<VendorInfo>[] => [
  {
    field: 'vendorName',
    headerName: 'Partner',

    flex: 1,
  },

  {
    field: 'services',
    headerName: 'Services',
    flex: 1,
    valueGetter: (params) => params.row.services,
    valueFormatter: (params) =>
      params.value.map((value: string) => convertCamelCase(value)),
  },
  {
    field: 'serviceType',
    headerName: 'Services Type',
    valueFormatter: ({ value }) => convertCamelCase(value),
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.75,
    renderCell: (props) => (
      <>
        <Stack direction="row" gap={1}>
          <VegaSwitchV4 checked />
          <ComponentBody1Typo>Active</ComponentBody1Typo>
        </Stack>
      </>
    ),
  },
  {
    field: 'devActivation',
    headerName: 'Dev Activation',
    flex: 0.8,
    renderCell: (props) => (
      <>
        <Stack direction="row" gap={1}>
          <VegaButtonV4
            variant="text"
            value="Link Account"
            disabled
            onClick={() =>
              setShowDrawer({
                open: true,
                vendorId: props.row.id,
                accountType: AccountType.SANDBOX,
              })
            }
          />
        </Stack>
      </>
    ),
  },
  {
    field: 'prodActivation',
    headerName: 'Prod Activation',
    flex: 0.8,

    renderCell: (props) => (
      <>
        {/* <Stack direction="row" gap={3}>
          <VegaButtonV4 variant="text" value="Account Linked" disabled />
          <IconButton>
            <EyeOpenIcon />
          </IconButton>
        </Stack> */}
        <Stack direction="row" gap={1}>
          <VegaButtonV4
            variant="text"
            value="Link Account"
            disabled
            onClick={() =>
              setShowDrawer({
                open: true,
                vendorId: props.row.id,
                accountType: AccountType.PROD,
              })
            }
          />
        </Stack>
      </>
    ),
  },
  // {
  //   field: 'activation',
  //   headerName: 'Activation',
  //   renderCell: (props: any) => {
  //     return (
  //       <Box
  //         onClick={() => {
  //           setShowDrawer({ open: true, vendorId: props.row.id });
  //         }}
  //       >
  //         <div
  //           style={{
  //             color: theme.palette.primary.light,
  //             paddingTop: 20,
  //             paddingBottom: 20,
  //             cursor: 'pointer',
  //           }}
  //         >
  //           Link Account
  //         </div>
  //       </Box>
  //     );
  //   },
  //   flex: 1,
  // },
];

export default ListOfServiceSetup;
