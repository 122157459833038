import { Stack } from '@mui/material';
import {
  INDICATIVE,
  NEUTRALS,
  QUICK_ACCESS,
} from '../../../../../../../constants/style';
import {
  ActionLargeTypo,
  ComponentBody1Typo,
} from '../../../../../../common/Typography';
import { DATE_FORMATE_SHORT } from '../../../../../../../constants/url';
import {
  convert2IndianNumberSystem,
  standardizeStatusText,
} from '../../../../../../../utils/stringUtils';
import dayjs from 'dayjs';
import { IBillResponseData } from '../../../../../../../types/bill';
import VegaBadgeStatusTagV4 from '../../../../../../common/v4/VegaBadgeStatusTagV4';
import { getBillStatusColor } from '../../../../supportUtils';

interface IBillStatusAndPaymentStatusProps {
  data: IBillResponseData | undefined;
}

const BillStatusAndPaymentStatus = ({
  data,
}: IBillStatusAndPaymentStatusProps) => {
  const billDetailInfo = [
    { key: 'Due amount', value: convert2IndianNumberSystem(data?.dueAmount) },
    {
      key: 'Start-End date',
      value: `${
        data?.billStartDate
          ? dayjs(data?.billStartDate).format(DATE_FORMATE_SHORT)
          : '--'
      } - ${
        data?.billEndDate
          ? dayjs(data?.billEndDate).format(DATE_FORMATE_SHORT)
          : '--'
      }`,
    },
    {
      key: 'Generation date',
      value: data?.createdAt
        ? dayjs(data?.createdAt).format(DATE_FORMATE_SHORT)
        : '--',
    },
  ];

  const billPaymentDetails = [
    { key: 'Amount paid', value: convert2IndianNumberSystem(data?.paidAmount) },
    {
      key: 'Due date',
      value: data?.billDueDate
        ? dayjs(data?.billDueDate).format(DATE_FORMATE_SHORT)
        : '--',
    },
    {
      key: 'Repayment date',
      value: data?.billPaidDate
        ? dayjs(data?.billPaidDate).format(DATE_FORMATE_SHORT)
        : '--',
    },
  ];

  return (
    <Stack
      direction={'row'}
      gap={2}
      justifyContent="space-evenly"
      width={'100%'}
    >
      <Stack
        sx={{ backgroundColor: NEUTRALS.white[100], borderRadius: '8px' }}
        px={2.5}
        py={2}
        gap={2}
        flex={1}
      >
        <Stack direction="row" gap={5} alignItems="center">
          <ActionLargeTypo sx={{ color: QUICK_ACCESS.text[200] }} width={160}>
            Bill Status
          </ActionLargeTypo>
          <VegaBadgeStatusTagV4
            text={standardizeStatusText(data?.status || '')}
            color={getBillStatusColor(data?.status)}
            variant="stroked"
          />
        </Stack>
        {billDetailInfo.map((item) => (
          <Stack direction="row" gap={5} alignItems="center" key={item.key}>
            <ComponentBody1Typo
              sx={{ color: QUICK_ACCESS.text[100] }}
              width={160}
            >
              {item.key}
            </ComponentBody1Typo>
            <ComponentBody1Typo>{item.value}</ComponentBody1Typo>
          </Stack>
        ))}
      </Stack>
      <Stack
        sx={{ backgroundColor: NEUTRALS.white[100], borderRadius: '8px' }}
        px={2.5}
        py={2}
        gap={2}
        flex={1}
      >
        <Stack direction="row" gap={5} alignItems="center">
          <ActionLargeTypo sx={{ color: QUICK_ACCESS.text[200] }} width={160}>
            Bill payment status
          </ActionLargeTypo>
          <VegaBadgeStatusTagV4
            text={standardizeStatusText(data?.status || '')}
            color={getBillStatusColor(data?.status)}
            variant="text"
          />
        </Stack>
        {billPaymentDetails.map((item, idx) => (
          <Stack direction="row" gap={5} alignItems="center" key={item.key}>
            <ComponentBody1Typo
              sx={{
                color: QUICK_ACCESS.text[100],
              }}
              width={160}
            >
              {item.key}
            </ComponentBody1Typo>
            <ComponentBody1Typo
              sx={{
                color:
                  idx === 0 && item.value !== '₹--'
                    ? INDICATIVE.green[500]
                    : undefined,
              }}
            >
              {item.value}
            </ComponentBody1Typo>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default BillStatusAndPaymentStatus;
