import { useSupport } from '../../../../../../../providers/SupportProvider';
import { useDelinquencyCurrentListQuery } from '../../../../../../../store/queries/transactionQueries';
import {
  ActionLargeTypo,
  ComponentBody1Typo,
} from '../../../../../../common/Typography';
import { Box, Skeleton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { DATE_FORMATE_SHORT } from '../../../../../../../constants/url';
import VegaCardV4_2 from '../../../../../../common/v4/VegaCardV4_2';
import { convert2IndianNumberSystem } from '../../../../../../../utils/stringUtils';

const ListOfCurrentDelinquncy = () => {
  const { accountData } = useSupport();
  const accountId = accountData?.accountId;

  const { data, isFetching } = useDelinquencyCurrentListQuery({
    accountId: accountId || '',
    enabled: Boolean(accountId),
  });

  return (
    <VegaCardV4_2>
      <ActionLargeTypo mb={2}>Current Delinquency</ActionLargeTypo>
      <Stack gap={1}>
        <Stack gap={1} direction="row">
          <Box width={150}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>Current DPD</ComponentBody1Typo>
            )}
          </Box>
          <Box flex={1}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>{data?.dpd || '--'}</ComponentBody1Typo>
            )}
          </Box>
        </Stack>
        <Stack gap={1} direction="row">
          <Box width={150}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>Amount Due</ComponentBody1Typo>
            )}
          </Box>
          <Box flex={1}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>
                {convert2IndianNumberSystem(data?.outstandingTad)}
              </ComponentBody1Typo>
            )}
          </Box>
        </Stack>
        <Stack gap={1} direction="row">
          <Box width={150}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>Entry Date</ComponentBody1Typo>
            )}
          </Box>
          <Box flex={1}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>
                {data?.dpdCalculationDate
                  ? dayjs(data?.dpdCalculationDate).format(DATE_FORMATE_SHORT)
                  : '--'}
              </ComponentBody1Typo>
            )}
          </Box>
        </Stack>
        <Stack gap={1} direction="row">
          <Box width={150}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>Bill Date</ComponentBody1Typo>
            )}
          </Box>
          <Box flex={1}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>
                {data?.dueDate
                  ? dayjs(data?.dueDate).format(DATE_FORMATE_SHORT)
                  : '--'}
              </ComponentBody1Typo>
            )}
          </Box>
        </Stack>
      </Stack>
    </VegaCardV4_2>
  );
};

export default ListOfCurrentDelinquncy;
