import { Stack, TextField } from '@mui/material';
import VegaDialogV4 from '../../../common/v4/VegaDialogV4';
import { ListGroupHeadingTypo } from '../../../common/Typography';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { AccountClosureType } from '../../../../enums/account.enum';
import { useSupport } from '../../../../providers/SupportProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { closeAccountRequestAPI } from '../../../../api/accountClosureAPIs';

interface ICloseAccountReqDialogProps {
  open: boolean;
  setOpenFalse: () => void;
}

enum CloseAccReqFormData {
  REASON = 'reason',
  REMARKS = 'remarks',
}

type FormValues = {
  remarks: string;
  reason: string;
};

const CloseAccountReqDialog = ({
  open,
  setOpenFalse,
}: ICloseAccountReqDialogProps) => {
  const dispatch = useAppDispatch();
  const { accountData, loading } = useSupport();
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const store = useAppSelector((state) => ({
    isFormSubmitLoading:
      state.accountClosure.accountClosureRequestData?.apiStatus?.isLoading,
  }));
  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues,
  } = useForm<FormValues>();

  const onSubmit = () => {
    const { reason, remarks } = getValues();
    if (!accountData?.accountId || !reason || !user?.email) return;

    dispatch(
      closeAccountRequestAPI({
        payload: {
          createdBy: user.email,
          accountId: accountData.accountId,
          type: AccountClosureType.MANUAL,
          reason,
          remarks,
        },
        setSnackbar,
      })
    ).then((res) => {
      if (!(res?.payload as any)?.apierror) {
        setOpenFalse();
      }
    });
  };

  return (
    <VegaDialogV4
      open={open}
      title="Account Closure Request"
      onClose={setOpenFalse}
      actionBtnSecondaryText={'Submit'}
      closeBtnRequired
      actionBtnSecondaryProps={{
        onClick: handleSubmit(onSubmit),
        disabled: !isValid || loading || store.isFormSubmitLoading,
      }}
    >
      <Stack sx={{ padding: '24px 40px 40px' }} gap={3}>
        <Stack gap={1}>
          <ListGroupHeadingTypo>Reason</ListGroupHeadingTypo>
          <TextField
            {...register(CloseAccReqFormData.REASON, { required: true })}
            id="outlined-textarea"
            placeholder="Add Reason"
            multiline
            sx={{
              width: '550px',
              '.MuiInputBase-input': {
                '&::placeholder': {
                  fontSize: '13px',
                  fontWeight: 450,
                  fontFamily: 'Aspekta',
                },
              },
            }}
          />
        </Stack>
        <Stack gap={1}>
          <ListGroupHeadingTypo>Remarks (Optional)</ListGroupHeadingTypo>
          <TextField
            {...register(CloseAccReqFormData.REMARKS)}
            id="outlined-textarea"
            placeholder="Add Remarks"
            multiline
            rows={3}
            sx={{
              width: '550px',
              '.MuiInputBase-input': {
                '&::placeholder': {
                  fontSize: '13px',
                  fontWeight: 450,
                  fontFamily: 'Aspekta',
                },
              },
            }}
          />
        </Stack>
      </Stack>
    </VegaDialogV4>
  );
};

export default CloseAccountReqDialog;
