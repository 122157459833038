// Example usage:
// const fileValidator = new FileValidatorBuilder()
//   .withAllowedExtensions(['jpg', 'png', 'pdf'])
//   .withMaxSize(5)
//   .withMaxNameLength(50)
//   .build();

export enum FileExtension {
  PNG = 'png',
  SVG = 'svg',
  PDF = 'pdf',
  EXCEL = 'xlsx',
  JPEG = 'jpeg',
  JPG = 'jpg',
  CSV = 'csv',
}

export const ImageFileExtensions = [
  FileExtension.PNG,
  FileExtension.JPEG,
  FileExtension.JPG,
];

export enum FileValidationCheck {
  MAX_SIZE,
  NAME_LENGTH,
  EXTENSION,
  MULTIPLE_FILE_EXTENSIONS,
  SVG_FILE_SCAN,
  MAX_SIZE_IN_KB,
  DIMENSION,
}

export interface FileValidatorResult {
  isValid: boolean;
  errorMessage: string | null;
}

export class FileValidatorBuilder {
  allowedExtensions: string[] = [];
  maxSizeInBytes: number = 0;
  maxNameLength: number = 0;
  validationChecks: Set<FileValidationCheck> = new Set();
  dimension = { width: 0, height: 0 };

  withAllowedExtensions(extensions: FileExtension[]): FileValidatorBuilder {
    this.allowedExtensions = extensions;
    this.validationChecks.add(FileValidationCheck.EXTENSION);
    return this;
  }

  withMultipleExtensionsCheck(): FileValidatorBuilder {
    this.validationChecks.add(FileValidationCheck.MULTIPLE_FILE_EXTENSIONS);
    return this;
  }

  withMaxSizeInMB(maxSizeInMegabytes: number): FileValidatorBuilder {
    this.maxSizeInBytes = maxSizeInMegabytes * 1024 * 1024;
    this.validationChecks.add(FileValidationCheck.MAX_SIZE);
    return this;
  }

  withMaxSizeInKB(maxSizeInKilobytes: number): FileValidatorBuilder {
    this.maxSizeInBytes = maxSizeInKilobytes * 1024;
    this.validationChecks.add(FileValidationCheck.MAX_SIZE_IN_KB);
    return this;
  }

  withMaxNameLength(maxLength: number): FileValidatorBuilder {
    this.maxNameLength = maxLength;
    this.validationChecks.add(FileValidationCheck.NAME_LENGTH);
    return this;
  }

  withSvgFileScan(): FileValidatorBuilder {
    this.validationChecks.add(FileValidationCheck.SVG_FILE_SCAN);
    return this;
  }

  withSetDimensions(width: number, height: number): FileValidatorBuilder {
    this.dimension = { width, height };
    this.validationChecks.add(FileValidationCheck.DIMENSION);
    return this;
  }

  build(): FileValidator {
    return new FileValidator(this);
  }

  // Optional method to check if a specific validation is enabled in the builder
  hasValidationCheck(check: FileValidationCheck): boolean {
    return this.validationChecks.has(check);
  }
}

export class FileValidator {
  private allowedExtensions: string[];
  private maxSizeInBytes: number;
  private maxNameLength: number;
  private validationChecks: Set<FileValidationCheck>;
  private dimension = { width: 0, height: 0 };

  constructor(builder: FileValidatorBuilder) {
    this.allowedExtensions = builder.allowedExtensions;
    this.maxSizeInBytes = builder.maxSizeInBytes;
    this.maxNameLength = builder.maxNameLength;
    this.validationChecks = builder.validationChecks;
    this.dimension = builder.dimension;
  }

  private getFileExtension(fileName: string): string {
    return fileName.split('.').pop() || '';
  }

  private hasMultipleExtensions(fileName: string): boolean {
    const dotCount = fileName.split('.').length - 1;
    return dotCount > 1;
  }

  private isFileExtensionValid(fileName: string): boolean {
    const fileExtension = this.getFileExtension(fileName);
    return this.allowedExtensions.includes(fileExtension.toLowerCase());
  }

  private isFileSizeValid(fileSize: number): boolean {
    return fileSize <= this.maxSizeInBytes;
  }

  private isFileNameLengthValid(fileName: string): boolean {
    return fileName.length <= this.maxNameLength;
  }

  private isMaliciousSVG(file: File): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const svgContent = event.target?.result as string;

        try {
          // Parse the SVG content using DOMParser
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(svgContent, 'image/svg+xml');

          const onClickAttribute = xmlDoc.querySelector('[onclick]');
          const scriptTags = xmlDoc.getElementsByTagName('script');
          const hasScriptTags = scriptTags.length > 0;
          const isMalicious = hasScriptTags || !!onClickAttribute;

          resolve(isMalicious);
        } catch (error) {
          resolve(true);
        }
      };

      reader.onerror = () => {
        // An error occurred while reading the file
        // Treat it as potentially malicious or handle the error as per your requirement
        console.error('Error reading SVG file.');
        resolve(true);
      };

      reader.readAsText(file);
    });
  }

  private hasValidDimensions(selectedFile: File) {
    return new Promise<boolean>((resolve, reject) => {
      const objectUrl = URL.createObjectURL(selectedFile);
      console.log(objectUrl);

      // Create a new Image object
      const img = new Image();

      // Set CORS policy if necessary
      img.crossOrigin = 'anonymous';

      // Set the source of the image
      img.src = objectUrl;

      // Handle image loading
      img.onload = () => {
        // Get the dimensions
        const width = img.width;
        const height = img.height;

        if (
          width !== this.dimension.width ||
          height !== this.dimension.height
        ) {
          resolve(false);
        }
        URL.revokeObjectURL(objectUrl);
        resolve(true);
      };
      img.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        resolve(false);
      };
    });
  }

  async validateFile(file: File): Promise<FileValidatorResult> {
    const validationResults: string[] = [];

    if (
      this.validationChecks.has(FileValidationCheck.MULTIPLE_FILE_EXTENSIONS) &&
      this.hasMultipleExtensions(file.name) == true
    ) {
      validationResults.push(`Invalid File`);
    }

    if (
      this.validationChecks.has(FileValidationCheck.MAX_SIZE) &&
      !this.isFileSizeValid(file.size)
    ) {
      validationResults.push(
        `File size should be less than ${
          this.maxSizeInBytes / (1024 * 1024)
        } MB.`
      );
    }

    if (
      this.validationChecks.has(FileValidationCheck.MAX_SIZE_IN_KB) &&
      !this.isFileSizeValid(file.size)
    ) {
      validationResults.push(
        `File size exceeds ${this.maxSizeInBytes / 1024} KB.`
      );
    }

    if (
      this.validationChecks.has(FileValidationCheck.DIMENSION) &&
      ImageFileExtensions.includes(
        this.getFileExtension(file.name) as FileExtension
      )
    ) {
      const isValidDimension = await this.hasValidDimensions(file);
      if (!isValidDimension) {
        validationResults.push(
          `Image size doesn't matches ${this.dimension.width}X${this.dimension.height}px KB.`
        );
      }
    }

    if (
      this.validationChecks.has(FileValidationCheck.EXTENSION) &&
      !this.isFileExtensionValid(file.name)
    ) {
      validationResults.push(
        `Invalid file extension. Allowed extensions are: ${this.allowedExtensions.join(
          ', '
        )}`
      );
    }

    if (
      this.validationChecks.has(FileValidationCheck.NAME_LENGTH) &&
      !this.isFileNameLengthValid(file.name)
    ) {
      validationResults.push(
        `File name length should be less than or equal to ${this.maxNameLength}.`
      );
    }

    if (this.validationChecks.has(FileValidationCheck.SVG_FILE_SCAN)) {
      const isSvgFile = this.getFileExtension(file.name) == FileExtension.SVG;

      if (isSvgFile == true) {
        const isSvgFileInValid = await this.isMaliciousSVG(file);
        if (isSvgFileInValid == true) {
          validationResults.push(`Svg File is not valid.`);
        }
      }
    }

    const isValid = validationResults.length === 0;
    const errorMessage = isValid ? null : validationResults.join(' ');

    return {
      isValid,
      errorMessage,
    };
  }
}
