import { PopoverProps, Stack } from '@mui/material';
import { ListGroupHeadingTypo } from '../Typography';
import { Controller, useForm } from 'react-hook-form';
import VegaDatePickerV4 from './VegaDatePickerV4';
import { QUICK_ACCESS } from '../../../constants/style';
import VegaButtonV4 from './VegaButtonV4';
import { useEffect } from 'react';
import VegaPopoverV4 from './VegaPopoverV4';

interface IVegaDateRangePickerPopoverV4Props {
  open: boolean;
  onClose: () => void;
  submitDateRange: (startDate: string, endDate: string) => void;
  popoverProps: PopoverProps;
  initialValues?: {
    startDate: string;
    endDate: string;
  };
  isEndDateMinNextDay?: boolean;
}

interface IDateRangePickerFormValues {
  startDate?: string;
  endDate?: string;
}

const VegaDateRangePickerPopoverV4 = ({
  open,
  onClose,
  submitDateRange,
  popoverProps,
  initialValues,
  isEndDateMinNextDay,
}: IVegaDateRangePickerPopoverV4Props) => {
  const {
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    control,
    watch,
    resetField,
    reset,
    setValue,
  } = useForm<IDateRangePickerFormValues>({ mode: 'onTouched' });

  const submitRange = () => {
    const { startDate, endDate } = getValues();
    if (startDate && endDate) {
      submitDateRange(startDate, endDate);
    }
  };

  const startDateWatch = watch('startDate');
  const endDateWatch = watch('endDate');
  const startDateProper = startDateWatch ? new Date(startDateWatch) : undefined;
  if (isEndDateMinNextDay) {
    startDateProper?.setDate(startDateProper.getDate() + 1);
  }

  useEffect(() => {
    if (endDateWatch && startDateWatch) {
      const endDate = new Date(endDateWatch).getTime();
      const startDate = new Date(startDateWatch).getTime();
      if (startDate >= endDate) {
        resetField('endDate');
      }
    }
    // only start date dependency is required here
  }, [startDateWatch]);

  useEffect(() => {
    reset();
  }, [open, reset]);

  useEffect(() => {
    if (open) {
      setValue('endDate', initialValues?.endDate);
      setValue('startDate', initialValues?.startDate);
    }
  }, [open, initialValues?.endDate, initialValues?.startDate, setValue]);

  return (
    <VegaPopoverV4 {...popoverProps}>
      <Stack px={5} py={3} gap={2}>
        <ListGroupHeadingTypo sx={{ color: QUICK_ACCESS.text[75] }}>
          Choose Date Range
        </ListGroupHeadingTypo>
        <Stack direction="row" gap={1.5} pb={1}>
          <Controller
            name="startDate"
            control={control}
            rules={{
              required: 'Required field',
            }}
            render={({ field: { ref, ...rest } }) => {
              return (
                <VegaDatePickerV4
                  dateProps={{ ...rest, views: ['year', 'month', 'day'] }}
                  textProps={{
                    required: true,
                    labelText: errors.startDate?.message,
                    error: Boolean(errors.startDate),
                    standardWidth: 194,
                    placeholder: 'Start Date',
                  }}
                />
              );
            }}
          />
          <Controller
            name="endDate"
            control={control}
            rules={{
              required: 'Required field',
            }}
            render={({ field: { ref, ...rest } }) => {
              return (
                <VegaDatePickerV4
                  disabled={!Boolean(startDateWatch)}
                  dateProps={{
                    ...rest,
                    views: ['year', 'month', 'day'],
                    minDate: startDateProper?.getTime(),
                  }}
                  textProps={{
                    required: true,
                    labelText: errors.endDate?.message,
                    error: Boolean(errors.endDate),
                    standardWidth: 194,
                    placeholder: 'End Date',
                  }}
                />
              );
            }}
          />
        </Stack>
        <Stack direction="row" gap={1} justifyContent="flex-end">
          <VegaButtonV4 variant="text" onClick={onClose}>
            Cancel
          </VegaButtonV4>
          <VegaButtonV4
            variant="contained"
            onClick={handleSubmit(submitRange)}
            disabled={!isDirty}
          >
            Apply Date Range
          </VegaButtonV4>
        </Stack>
      </Stack>
    </VegaPopoverV4>
  );
};

export default VegaDateRangePickerPopoverV4;
