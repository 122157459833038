import axios from 'axios';
import { BASE_URL, ONBOARDING, REWARDS } from './endpoints';
import {
  ICreateCampaignProps,
  ICreateInventoryProps,
  IPointBaseRulesProps,
} from '../types/RewardsLoyalty';
import { Merchant } from '../types/resources';
import { PaginatedResponse } from '../utils/api';

const REWARDS_BASE_URL = BASE_URL + REWARDS.BASE;
const ONBOARDING_BASE_URL = BASE_URL + ONBOARDING.BASE;
const RULE_URL = REWARDS_BASE_URL + REWARDS.RULE;
const CAMPAIGN_URL = REWARDS_BASE_URL + REWARDS.CAMPAIGN;
const INVENTORY_URL = REWARDS_BASE_URL + REWARDS.INVENTORY;
const MERCHANT = ONBOARDING.DATA + ONBOARDING.MERCHANT;

interface IRenewProps {
  id: string;
  renew?: boolean;
}

export const fetchAllMerchantList = (page: number, pageSize: number) => {
  return axios.get(`${MERCHANT}?page=${page}&pageSize=${pageSize}`);
};

export async function getAllMerchants(): Promise<PaginatedResponse<Merchant>> {
  try {
    const endpoint = ONBOARDING_BASE_URL + MERCHANT;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchMerchantListByMid = (mid: number) => {
  return axios.get(`${MERCHANT}/filter?mid=${mid}`);
};

export const postRule = (rule: IPointBaseRulesProps) => {
  return axios.post(RULE_URL, rule);
};

export const updateRule = (rule: IPointBaseRulesProps) => {
  return axios.put(RULE_URL, rule);
};

export const fetchCampaignByProgramId = (programId: any) => {
  return axios.get(`${CAMPAIGN_URL}/program/${programId}`);
};

export const fetchCampaignById = (id: any) => {
  return axios.get(`${CAMPAIGN_URL}/${id}`);
};

export const createCampaign = (
  rule: IPointBaseRulesProps | ICreateCampaignProps
) => {
  return axios.post(CAMPAIGN_URL, rule);
};

export const updateCampaign = (rule: any) => {
  return axios.put(CAMPAIGN_URL, rule);
};

export const updateCampaignImage = (data: any) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.put(`${CAMPAIGN_URL}/${data.id}/image`, data.formData, config);
};

/////////////// INVENTORY \\\\\\\\\\\\\\\\\\\\\\\\\
export const fetchInventoryByProgramId = (id: any) => {
  return axios.get(`${INVENTORY_URL}/program/${id}`);
};
export const fetchInventoryByProgramIdAndGiftType = (
  id: any,
  giftType: string
) => {
  return axios.get(
    `${INVENTORY_URL}/program/${id}/giftType?giftType=${giftType}`
  );
};

export const createInventory = (inventory: ICreateInventoryProps) => {
  return axios.post(INVENTORY_URL, inventory);
};

export const updateInventoryRenewStatus = ({ id, renew }: IRenewProps) => {
  return axios.put(`${INVENTORY_URL}/${id}/renew?renew=${renew}`);
};
