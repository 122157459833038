import { Controller, UseFormReturn } from 'react-hook-form';
import { IAddCustomerFormProps } from './CreateCustomerFormPage';
import VegaContainerV4 from '../../../common/v4/VegaContainerV4';
import { Stack } from '@mui/material';
import VegaTextfieldV4 from '../../../common/v4/VegaTextfieldV4';
import { ListHead2Typo } from '../../../common/Typography';
import { fetchStateOnPincode } from '../../../../api/resources';

interface IAddCustomerAddressDetailsProps {
  customerFormHook: UseFormReturn<IAddCustomerFormProps, any>;
}
const AddCustomerAddressDetails = ({
  customerFormHook,
}: IAddCustomerAddressDetailsProps) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = customerFormHook;

  const onPincodeChange = (pincode: string) => {
    return new Promise((res) => {
      if (pincode?.length === 6) {
        fetchStateOnPincode(pincode)
          .then((result) => {
            if (result.data?.records?.length) {
              const stateList = result.data?.records[0]?.state;
              const cityList = result.data?.records[0]?.city;
              const counteryList = result.data?.records[0]?.country;
              setValue('address.city', cityList);
              setValue('address.state', stateList);
              setValue('address.country', counteryList.toLocaleUpperCase());
              res(true);
            } else {
              res(false);
            }
          })
          .catch((err) => {
            res(false);
          });
      } else {
        res(true);
      }
    });
  };

  return (
    <VegaContainerV4 title="Address">
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        <Controller
          name="address.line1"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.address?.line1)}
              header="Address 1"
              labelText={errors.address?.line1?.message}
              required
              placeholder="Enter Address 1"
            />
          )}
        />
        <Controller
          name="address.line2"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.address?.line2)}
              header="Address 2"
              labelText={errors.address?.line2?.message}
              placeholder="Enter Address 2"
            />
          )}
        />
        <Controller
          name="address.pincode"
          control={control}
          rules={{
            required: 'Required field',
            maxLength: {
              value: 6,
              message: 'Pincode should be 6 digits',
            },
            minLength: {
              value: 6,
              message: 'Pincode should be 6 digits',
            },
            validate: {
              require: async (e) => {
                const res = await (onPincodeChange(e) as Promise<boolean>);
                return res || 'Please provide valid Pincode';
              },
            },
          }}
          render={({ field: { ref, onChange, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.address?.pincode)}
              header="Pincode"
              labelText={errors.address?.pincode?.message}
              onChange={(e) => {
                if (e.target.value.length !== 6) {
                  setValue('address.city', '');
                  setValue('address.state', '');
                  setValue('address.country', '');
                }
                onChange(e);
              }}
              required
              placeholder="Enter Pincode"
            />
          )}
        />
        <Controller
          name="address.city"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.address?.city)}
              header="City (Autofilled)"
              disabled
            />
          )}
        />
        <Controller
          name="address.state"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.address?.state)}
              header="State (Autofilled)"
              disabled
            />
          )}
        />
        <Controller
          name="address.country"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.address?.country)}
              header="Country (Autofilled)"
              disabled
            />
          )}
        />
        {/* <Controller
          name="isCurrentAddressPermanent"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <VegaCheckboxV4
              label="Is Current Address Permanent?"
              checkboxProps={{
                ...rest,
                checked: Boolean(rest.value),
                onChange: (e) => rest.onChange(e.target.checked),
              }}
              helper={errors.isCurrentAddressPermanent?.message}
              error={Boolean(errors.isCurrentAddressPermanent)}
            />
          )}
        /> */}
      </Stack>
    </VegaContainerV4>
  );
};

export default AddCustomerAddressDetails;
