import { SxProps, Typography } from '@mui/material';
import React from 'react';
import { GREY } from '../../constants/style';

interface IProps {
  text: string;
  mb?: number;
  sx?: SxProps;
}

const VegaHeader = ({ text, mb = 0.5, sx }: IProps) => {
  return (
    <Typography
      className="font-aspekta-700"
      sx={{
        textTransform: 'uppercase',
        fontSize: 10,
        letterSpacing: '0.07em',

        mb: mb,
        color: GREY.lightGray,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};

export default VegaHeader;
