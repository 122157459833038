import {
  Box,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import AddBin from './AddBin';
import ListOfBinCharter from './ListOfBinCharter';
import VegaPage from '../../common/VegaPage';
import AddSquare02Icon from '../../icon/AddSquare02Icon';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import { VPHeader } from '../../../ui-components/common';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';
import VegaDrawerV4 from '../../common/v4/VegaDrawerV4';
import VegaTextfieldV4 from '../../common/v4/VegaTextfieldV4';
import { useClientAuth } from '../../../providers/ClientProvider';
import VegaSelectV4 from '../../common/v4/VegaSelectV4';

const BinConfiguration = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    bin,
    binRange,
    isProgramOpen,
    isAddBinOpen,
    programOptions,
    loading,
    binData,
    setBinRange,
    setIsBinDetails,
    setIsProgramOpen,
    toggleIsAddBinOpen,
    onChange,
    addProgram,
    addBin,
    navigateTo,
  } = useBinCharter();
  const { clientId } = useClientAuth();

  let isNameValid = !!bin.binName && bin.binName.length > 0;
  let isValueValid = !!bin.binValue && bin.binValue.length == 6;
  let isTypeValid = !!bin.binType && bin.binType.length > 0;
  const isNetworkValid = !!bin.network && bin.network.length > 0;

  const disabled = isNameValid && isValueValid && isTypeValid && isNetworkValid;

  const onSelectedProgramChange = (
    event: SelectChangeEvent<string | number>
  ) => {
    onChange('programId', event.target.value);
  };
  return (
    <>
      <VegaPage sx={{ gap: 3 }}>
        <VPHeader
          text="Bin Charter"
          component={
            <Stack direction="row" justifyContent="flex-end">
              <VegaButtonV4
                startIcon={<AddSquare02Icon />}
                onClick={toggleIsAddBinOpen}
                variant="contained"
              >
                Add Bin
              </VegaButtonV4>
            </Stack>
          }
        />
        <Box px={3.25}>
          <Stack direction="row" justifyContent="flex-end" pb={2}>
            <VegaSearchfieldV4 />
          </Stack>
          <ListOfBinCharter />
        </Box>
      </VegaPage>
      <VegaDrawerV4
        open={isProgramOpen}
        onClose={() => {
          setBinRange({
            programId: '',
            numberOfKits: 0,
          });
          setIsProgramOpen(!isProgramOpen);
        }}
        header="Add program"
        description={`${binData?.binName}`}
        footer={
          <>
            <Stack
              direction={'row'}
              gap={1}
              justifyContent="end"
              alignItems={'center'}
            >
              <VegaButtonV4
                value="Cancel"
                onClick={toggleIsAddBinOpen}
                variant="text"
              />
              <VegaButtonV4
                value="Add Program"
                loading={loading}
                onClick={addProgram}
                variant="contained"
                // disabled={disabled == false}
              />
            </Stack>
          </>
        }
      >
        <Stack gap={3}>
          <VegaSelectV4
            header="Program Name"
            name="programId"
            options={programOptions}
            value={binRange.programId}
            onChange={onSelectedProgramChange}
            standardWidth={400}
          />

          <VegaTextfieldV4
            header="No. Of Cards"
            type={'number'}
            key="numberOfKits "
            placeholder="Enter Number Of Cards"
            value={binRange?.numberOfKits ?? 0}
            onChange={(e: any) =>
              onChange(
                'numberOfKits',
                e.target.value && parseInt(e.target.value)
              )
            }
            standardWidth={400}
          />
        </Stack>
      </VegaDrawerV4>
      <VegaDrawerV4
        header="Add New Bin"
        open={isAddBinOpen}
        onClose={toggleIsAddBinOpen}
        footer={
          <>
            <Stack
              direction={'row'}
              gap={1}
              justifyContent="end"
              alignItems={'center'}
            >
              <VegaButtonV4
                value="Cancel"
                variant="text"
                onClick={toggleIsAddBinOpen}
              />
              <VegaButtonV4
                value="Add bin"
                variant="contained"
                loading={loading}
                onClick={() => {
                  addBin();
                  toggleIsAddBinOpen();
                }}
                disabled={!disabled}
              />
            </Stack>
          </>
        }
      >
        <AddBin />
      </VegaDrawerV4>
    </>
  );
};

export default BinConfiguration;
