/** @format */

import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { BASE_URL, BILL, INSTALLMENT, TRANSACTION } from './endpoints';
import { PaginatedResponse } from '../utils/api';
import {
  BillStatus,
  IBillResponseData,
  ITransactionForBillResponse,
  PhysicalStatementOrderDto,
  PhysicalStatementStatus,
} from '../types/bill';
import { EMIData } from '../types/emis';
import { TransactionDto } from '../types/transaction';
import { CommonRequestQuery } from '../types/commonType';

const BILL_BASE_URL = BASE_URL + BILL.BASE;
const TRANSACTION_BASE_URL = BASE_URL + TRANSACTION.BASE;
const INSTALLMENT_BASE_URL = BASE_URL + INSTALLMENT.BASE;

const BILL_LOAN_URL = BILL_BASE_URL + BILL.LOAN;
const BILL_FOR_TRANSACTION = TRANSACTION_BASE_URL + TRANSACTION.BILL;
const BILL_FOR_EMIs = INSTALLMENT_BASE_URL + INSTALLMENT.BILL_EMI;
const BILL_FOR_REPAYMENTS = TRANSACTION_BASE_URL + TRANSACTION.BILL_REPAYMENTS;
const BILL_STATEMENT = BILL_BASE_URL + BILL.STATEMENT;
const BILL_LATEST_BY_ACCOUNT_ID =
  BILL_BASE_URL + BILL.BILL_LATEST_BY_ACCOUNT_ID;

export const generateBillForAccount = (accountId: string, date: string) => {
  const data = {
    accountId,
    billDateStr: dayjs(date).format('YYYY-MM-DD'),
  };
  return axios.post(BILL_BASE_URL, data);
};

export const getBillsForAccount = (
  data: {
    accountId: string;
    statusList?: BillStatus[];
  } & CommonRequestQuery
): Promise<AxiosResponse<PaginatedResponse<IBillResponseData>>> => {
  return axios.get(BILL_BASE_URL + '/list', {
    params: {
      accountId: data.accountId,
      page: data.page ?? 0,
      pageSize: data.pageSize ?? 100,
      statusList: data.statusList,
      q: data.q,
      startDate: data.startDate,
      endDate: data.endDate,
    },
  });
};

export const getPhysicalStatementList = (
  data: {
    accountId?: string;
    programId?: string;
    billId?: string;
    statusList?: PhysicalStatementStatus[];
  } & CommonRequestQuery
): Promise<AxiosResponse<PaginatedResponse<PhysicalStatementOrderDto>>> => {
  const endpoint = BILL_BASE_URL + BILL.PHYSICAL_STATEMENT_LIST;
  return axios.get(endpoint, {
    params: {
      accountId: data.accountId,
      programId: data.programId,
      billId: data.billId,
      page: data.page ?? 0,
      pageSize: data.pageSize ?? 100,
      statusList: data.statusList,
      q: data.q,
      startDate: data.startDate,
      endDate: data.endDate,
    },
  });
};

export const updateBulkPhysicalStatementStatus = (data: {
  requestIdList: string[];
  newStatus: PhysicalStatementStatus;
}) => {
  const endpoint = BILL_BASE_URL + BILL.PHYSICAL_STATEMENT_BULK_STATUS_UPDATE;
  return axios.patch(endpoint, data);
};

export const getTransactionsForBill = (
  billId: string,
  page?: number,
  pageSize?: number
): Promise<AxiosResponse<PaginatedResponse<ITransactionForBillResponse>>> => {
  return axios.get(BILL_FOR_TRANSACTION, {
    params: {
      billId,
      pageNo: page ?? 0,
      pageSize: pageSize ?? 100,
    },
  });
};

export const getEMIsForBill = (
  billId: string,
  includeRewardsDetails?: boolean
): Promise<AxiosResponse<EMIData[]>> => {
  return axios.get(BILL_FOR_EMIs, {
    params: {
      billId,
      includeRewardsDetails: includeRewardsDetails || false,
    },
  });
};

export const getRepaymentsForBill = (
  billId: string
): Promise<AxiosResponse<PaginatedResponse<TransactionDto>>> => {
  return axios.get(BILL_FOR_REPAYMENTS, {
    params: {
      billId,
    },
  });
};

export const getBillPdf = (
  billId: string
): Promise<AxiosResponse<{ url: string }>> => {
  return axios.get(BILL_BASE_URL + `/${billId}/pdf`);
};

export const getDigitalBillPdf = (
  billId: string
): Promise<AxiosResponse<{ url: string }>> => {
  return axios.post(BILL_STATEMENT + `/${billId}/digital`);
};

export const generatePhysicalStatement = (
  billId: string
): Promise<AxiosResponse<{ url: string }>> => {
  return axios.post(BILL_STATEMENT + `/physical/trigger/${billId}`);
};

export const getLatestBill = (accountId: string) => {
  return axios.get(BILL_LATEST_BY_ACCOUNT_ID.replace('accountId', accountId));
};

export const getBillFromLoanId = (
  loanId: string
): Promise<AxiosResponse<IBillResponseData>> => {
  return axios.get(`${BILL_LOAN_URL}/${loanId}`);
};
