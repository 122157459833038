import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../common/Typography';
import VegaFilterButtonsV4 from '../../../common/v4/VegaFilterBtnsV4';
import { INDICATIVE, QUICK_ACCESS } from '../../../../constants/style';
import VegaMetricCardV4 from '../../../common/v4/VegaMetricCardV4';
import VegaLineChartV4 from '../../../common/Charts/VegaLineChartV4';
import {
  AccAnalyticsGraphData,
  AccAnalyticsMetaData,
} from '../../../../types/analytics';
import { ANALYTICS_QUERY_NAME } from '../../../../enums/analytics.enum';
import { useAnalyticsHook } from '../../../../hooks/useAnalyticsHook';

const AccHolderAccLimitData = () => {
  const {
    onFilterCellClick,
    submitDateRange,
    onCloseCustomRangeClick,
    structuredGraphData,
    structuredMetaData,
    activeIdx,
    filterOptions,
  } = useAnalyticsHook<AccAnalyticsGraphData, AccAnalyticsMetaData, undefined>({
    graphProps: ['month', 'created_at', 'total_accounts'],
    graphLabel: 'New Accounts Created',
    graphQueryNames: {
      monthly: ANALYTICS_QUERY_NAME.CCMS_NEW_ACCOUNT_BY_MONTH,
      daily: ANALYTICS_QUERY_NAME.CCMS_NEW_ACCOUNT_BY_DATE,
    },
    metaDataQueryName: ANALYTICS_QUERY_NAME.CCMS_ACCOUNT_DATE_RANGE,
    filterOptionsType: 'shorter',
  });

  return (
    <Stack
      px={2}
      py={3}
      gap={4}
      sx={{
        backgroundColor: QUICK_ACCESS.background[50],
        border: `1px solid ${QUICK_ACCESS.border[50]}`,
        borderRadius: '16px',
      }}
    >
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="space-between"
        flex={1}
      >
        <PageHeadingTypo>Account and Limit Data</PageHeadingTypo>
        <VegaFilterButtonsV4
          data={filterOptions}
          activeIdx={activeIdx}
          onCellClick={onFilterCellClick}
          submitDateRange={submitDateRange}
          customIdx={0}
          onCloseCustomRangeClick={onCloseCustomRangeClick}
        />
      </Stack>
      <Stack direction="row">
        <Stack gap={2} flex={1} maxWidth={610}>
          <VegaMetricCardV4
            mainInfo={{
              text: structuredMetaData?.total_accounts,
              label: 'Account Created',
              sx: {
                color: INDICATIVE.blue[600],
              },
              labelSx: {
                color: INDICATIVE.blue[600],
              },
            }}
            paperSx={{
              backgroundColor: INDICATIVE.blue[50],
            }}
            badge={{
              props: {
                text: 30,
              },
              helperText: 'from last day',
            }}
          />
          <Stack direction="row" gap={2} flexWrap={'wrap'}>
            <VegaMetricCardV4
              mainInfo={{
                text: structuredMetaData?.active_accounts,
                label: 'Active Accounts',
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: 30,
                },
                helperText: 'from last day',
              }}
            />
            <VegaMetricCardV4
              mainInfo={{
                text: structuredMetaData?.inactive_accounts,
                label: 'Inactive accounts',
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: 0,
                },
                helperText: 'from last day',
              }}
            />
          </Stack>
          <Stack direction="row" gap={2} flexWrap={'wrap'}>
            <VegaMetricCardV4
              mainInfo={{
                text: structuredMetaData?.revolving_accounts,
                label: 'Revolver customer',
                sx: {
                  color: QUICK_ACCESS.text[100],
                },
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: -30,
                },
                helperText: 'from last day',
              }}
            />
            <VegaMetricCardV4
              mainInfo={{
                text: 0 + '',
                label: 'Transactor customer',
                sx: {
                  color: QUICK_ACCESS.text[100],
                },
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: 30,
                },
                helperText: 'from last day',
              }}
            />
          </Stack>
        </Stack>
        <Stack flex={1}>
          <VegaLineChartV4
            YAxisLabels={{
              left: { text: 'New Accounts Created' },
            }}
            mainData={structuredGraphData}
            linePropsArray={[
              {
                stroke: INDICATIVE.blue[600],
                dataKey: 'New Accounts Created',
              },
            ]}
            XAxisData={{ dataKey: 'date' }}
            wrapperSx={{
              p: 2,
            }}
            respContainerData={{ height: 432 }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AccHolderAccLimitData;
