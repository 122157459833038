import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { VegaProgramCategoryType } from '../../../types/program';
import VegaTabV4 from '../../common/v4/VegaTabV4';
import VegaSearchfieldV4 from '../../common/v4/VegaSearchfieldV4';

type FilterProgramManagementProps = {
  activeTable: VegaProgramCategoryType;
  onTableChange: React.Dispatch<React.SetStateAction<VegaProgramCategoryType>>;
  searchValue: string;
  handlesSearchValueChange: (value: string) => void;
};

const filterProgramTabOptions = [
  { value: VegaProgramCategoryType.CREDIT_CARD, label: 'Credit Card' },
  { value: VegaProgramCategoryType.PREPAID_CARD, label: 'Prepaid Card' },
  { value: VegaProgramCategoryType.FOREX_CARD, label: 'Forex Card' },
  { value: VegaProgramCategoryType.LOANS, label: 'LMS' },
];

function FilterProgramManagement({
  activeTable,
  onTableChange,
  searchValue,
  handlesSearchValueChange,
}: FilterProgramManagementProps) {
  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handlesSearchValueChange(e.target.value);
  };
  const onSelectedTabChanged = (
    event: React.SyntheticEvent,
    newValue: VegaProgramCategoryType
  ) => {
    onTableChange(newValue);
  };

  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent={'space-between'}
        mb={2}
        gap={1}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <VegaTabV4
          tabData={filterProgramTabOptions}
          value={activeTable}
          onChange={onSelectedTabChanged}
        />
        <Stack
          direction={'row'}
          className="dataGrid"
          alignItems={'center'}
          justifyContent={'flex-end'}
          spacing={2}
        >
          <VegaSearchfieldV4
            value={searchValue}
            onChange={onSearchTextChange}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default FilterProgramManagement;
