import React from 'react';
import { useClientAuth } from '../../providers/ClientProvider';
import VegaWrapperType1 from '../common/VegaWrapperType1';
import Stack from '@mui/material/Stack';
import VegaDataTileV4 from '../common/v4/VegaDataTileV4';
import VegaButtonV4 from '../common/v4/VegaButtonV4';

type ViewUserProfileProps = {
  onViewLoginHistoryClick: () => void;
};

function ViewUserProfile({ onViewLoginHistoryClick }: ViewUserProfileProps) {
  const { loggedInUserDetails } = useClientAuth();

  return (
    <VegaWrapperType1 header="User Details">
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <Stack direction="row" flexWrap="wrap" rowGap={3} gap={3}>
          <VegaDataTileV4
            title="Name"
            value={loggedInUserDetails?.name ?? '-'}
            sx={{
              width: 180,
            }}
          />
          <VegaDataTileV4
            title="Email"
            value={loggedInUserDetails?.email ?? '-'}
            sx={{
              width: 180,
            }}
          />
          <VegaDataTileV4
            title="Mobile"
            value={loggedInUserDetails?.mobileNumber ?? '-'}
            sx={{
              width: 180,
            }}
          />
          <VegaDataTileV4
            title="Position"
            value={loggedInUserDetails?.user?.team ?? '-'}
            sx={{
              width: 180,
            }}
          />
          <VegaDataTileV4
            title="Address"
            value={loggedInUserDetails?.user?.address ?? '-'}
            sx={{
              width: '100%',
            }}
          />
        </Stack>
        <Stack minWidth={180} alignItems="flex-end">
          <VegaButtonV4
            value="View Log In History"
            variant="outlined"
            onClick={onViewLoginHistoryClick}
          />
        </Stack>
      </Stack>
    </VegaWrapperType1>
  );
}

export default ViewUserProfile;
