export enum AccountClosureType {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export enum AccountClosureStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export enum AccountClosureChecklist {
  NO_DUES = 'NO_DUES',
  ACCOUNT_BALANCE = 'ACCOUNT_BALANCE',
  DISPUTE_TRANSACTION = 'DISPUTE_TRANSACTION',
  AUTOMATIC_BILL_PAYMENT = 'AUTOMATIC_BILL_PAYMENT',
  PENDING_REWARDS = 'PENDING_REWARDS',
}

export enum AccountStatus {
  CREATED = 'CREATED',
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELINQUENT = 'DELINQUENT',
  WRITE_OFF = 'WRITE_OFF',
  CLOSED = 'CLOSED',
  CLOSED_REQUESTED = 'CLOSED_REQUESTED',
  PRODUCT_TRANSFER = 'PRODUCT_TRANSFER',
  FRAUD_TRANSFER = 'FRAUD_TRANSFER',
  CHARGED_OFF = 'CHARGED_OFF',
  BALANCE_TRANSFER = 'BALANCE_TRANSFER',
}
