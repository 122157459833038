import axios, { AxiosResponse } from 'axios';
import {
  IConsolidatedAccountData,
  PaginatedResponse,
} from '../../types/Commons';
import { ACCOUNT as MCC_ACCOUNT, BASE_URL, GETWAY } from './endpoints';
import { AccountStatus } from '../../../enums/account.enum';
import { ACCOUNT } from '../../../api/endpoints';

const FOREX_ACCOUNT_BASE_URL = `${BASE_URL}${GETWAY}${MCC_ACCOUNT.BASE}`;
const ACCOUNT_BASE_URL = `${BASE_URL}${ACCOUNT.BASE}`;
const ACCOUNT_ACCOUNT_DETAILS_URL = `${BASE_URL}${GETWAY}${MCC_ACCOUNT.PLAN}`;
const ACCOUNT_SEARCH_URL = `${BASE_URL}${GETWAY}${MCC_ACCOUNT.SEARCH}`;
const ACCOUNT_FILTER_URL = `${BASE_URL}${GETWAY}${MCC_ACCOUNT.FILTER}`;
const ACCOUNT_CONSOLIDATED_FILTER_URL = `${BASE_URL}${GETWAY}${MCC_ACCOUNT.CONSOLIDATED_FILTER}`;

export const createAccount = (data: any) => {
  return axios.post(`${ACCOUNT_BASE_URL}`, data);
};
export const createForexAccount = (data: any) => {
  return axios.post(`${FOREX_ACCOUNT_BASE_URL}`, data);
};
export const generateOTPForCard = (data: any) => {
  return axios.post(`${ACCOUNT_BASE_URL}/generateOtpForCard`, data);
};

export const initiateCheckerMakerForAccount = (data: any) => {
  return axios.post(FOREX_ACCOUNT_BASE_URL + '/checkermaker', data);
};
export const updateCheckerMakerStatusForAccount = (data: any) => {
  return axios.patch(`${FOREX_ACCOUNT_BASE_URL}/checkermaker/status`, data);
};
export const updateStatusForAccount = (data: {
  accountId: string;
  status: AccountStatus;
}) => {
  return axios.patch(`${ACCOUNT_BASE_URL}/status`, data);
};
export const allocateCard = (data: any) => {
  return axios.post(`${FOREX_ACCOUNT_BASE_URL}/allocateCard`, data);
};
export const fetchAccountList = (
  data: any
): Promise<AxiosResponse<PaginatedResponse<IConsolidatedAccountData>, any>> => {
  return axios.post(ACCOUNT_SEARCH_URL, data);
};
export const fetchfilterAccountList = (data: any) => {
  return axios.post(ACCOUNT_FILTER_URL, data);
};
export const fetchfilterConsolidatedAccountList = (data: any) => {
  return axios.post(ACCOUNT_CONSOLIDATED_FILTER_URL, data);
};
export const fetchAccountDetailsList = (data: any) => {
  return axios.post(
    `${ACCOUNT_ACCOUNT_DETAILS_URL}/allocation/active-plans`,
    // `http://ec2-13-235-248-72.ap-south-1.compute.amazonaws.com:8511/forex/plan/allocation/active-plans`,
    data
  );
};
