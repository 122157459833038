import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getDpdBuckets, getProgramDelinquency } from '../../../api/collections';
import { GREY } from '../../../constants/style';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { DelinquencyReport } from '../../../types/collection';
import { FileUtils } from '../../../utils/file';
import { VegaCard, VegaTable } from '../../common';
import VegaText from '../../common/VegaText';
import DPDbucketSelector from './DPDbucketSelector';

type DpdType = {
  dpd: string;
};

export const getSelected = (value: string, arr: any[]) => {
  return arr.some((item: any) => value === item);
};

type Props = {};

const LeadsByDPDbucket = ({}: Props) => {
  const { selectedProgram } = useProgramData();
  const [deliquencyData, setDeliquencyData] = useState<DelinquencyReport[]>([]);
  const [filteredDeliquencyData, setFilteredDeliquencyData] = useState<
    DelinquencyReport[]
  >([]);
  const [dpdBuckets, setDpdBuckets] = useState<DpdType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectDPDbucket, setSelectDPDbucket] = useState<string[]>([]);

  const columnDef = [
    {
      field: 'customerId',
      headerName: <Typography variant="body2">Customer ID</Typography>,
      flex: 0.7,
    },
    {
      field: 'outstandingTad',
      headerName: <Typography variant="body2">O/B Balance</Typography>,
      flex: 0.7,
    },
    {
      field: 'outstandingTad1',
      headerName: <Typography variant="body2">O/D Balance</Typography>,
      flex: 0.7,
      renderCell: (props: any) => {
        const outstandingTad = props.row.outstandingTad;
        return <Typography>{outstandingTad}</Typography>;
      },
    },
    {
      field: 'dpd1',
      headerName: <Typography variant="body2">DPD Bucket</Typography>,
      flex: 0.7,
      renderCell: (props: any) => {
        const dpd = props.row.dpd;
        return (
          <Typography>
            {getBucketFromDpd(dpd)
              .replace('BUCKET_', '')
              .split('_')
              .join(' to ')}
          </Typography>
        );
      },
    },
    {
      field: 'dpd',
      headerName: <Typography variant="body2">DPD</Typography>,
      flex: 0.5,
    },
    {
      field: 'agentName',
      headerName: <Typography variant="body2">Agent</Typography>,
      flex: 1,
    },
  ];

  const onSelectDPDbucket = (value: any, index: number) => {
    const isSelected = getSelected(value, selectDPDbucket);

    if (!isSelected) {
      setSelectDPDbucket([...selectDPDbucket, value]);
    } else {
      const filterDPDbucket = selectDPDbucket.filter(
        (item: any) => item !== value
      );

      setSelectDPDbucket(filterDPDbucket);
    }
  };

  function fetchDeliquencyReports() {
    setDeliquencyData([]);
    setFilteredDeliquencyData([]);
    setDpdBuckets([]);
    const programId = selectedProgram?.programId;
    if (!programId) return;
    setLoading(true);
    getProgramDelinquency({ programId })
      .then((res) => {
        setDeliquencyData(res);
        setFilteredDeliquencyData(res);
        fetchDpdBuckets();
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  function fetchDpdBuckets() {
    setLoading(true);
    getDpdBuckets()
      .then((res) => {
        const buckets = res.map((item) => {
          const bucket: DpdType = {
            dpd: item,
          };
          return bucket;
        });
        setDpdBuckets(buckets);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  }

  function filterDeliquencyData() {
    if (selectDPDbucket.length <= 0) {
      setFilteredDeliquencyData(deliquencyData);
      return;
    }
    const filteredData = deliquencyData.filter((item) => {
      const includes = selectDPDbucket.includes(getBucketFromDpd(item.dpd));
      return includes;
    });
    setFilteredDeliquencyData(filteredData);
  }

  function onDownloadClick() {
    FileUtils.downloadJsonFile({
      data: filteredDeliquencyData,
      fileName: 'deliquency_data',
    });
  }

  useEffect(() => {
    fetchDeliquencyReports();
  }, [selectedProgram]);

  useEffect(() => {
    filterDeliquencyData();
  }, [selectDPDbucket]);

  return (
    <VegaCard fullHeight noPad>
      <Box
        sx={{
          px: 3,
          py: 2,
          bgcolor: GREY.light,
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        }}
      >
        <VegaText text="Collection Leads by DPD buckets" />
      </Box>

      <Box sx={{ p: 3 }}>
        <DPDbucketSelector
          data={dpdBuckets}
          onSelect={onSelectDPDbucket}
          state={selectDPDbucket}
          onDownloadClick={onDownloadClick}
          disableDownloadButton={filteredDeliquencyData.length <= 0}
        />
      </Box>
      <Box sx={{ p: 3 }}>
        <VegaTable
          border
          loading={loading}
          data={filteredDeliquencyData}
          columnDef={columnDef}
          idColumn={'id'}
        />
      </Box>
    </VegaCard>
  );
};

function getBucketFromDpd(dpd: number) {
  if (dpd >= 0 && dpd <= 29) {
    return 'BUCKET_0_29';
  } else if (dpd >= 30 && dpd <= 59) {
    return 'BUCKET_30_59';
  } else if (dpd >= 60 && dpd <= 89) {
    return 'BUCKET_60_89';
  } else if (dpd >= 90 && dpd <= 119) {
    return 'BUCKET_90_119';
  } else if (dpd >= 120 && dpd <= 149) {
    return 'BUCKET_120_149';
  } else if (dpd >= 150 && dpd <= 179) {
    return 'BUCKET_150_179';
  } else if (dpd >= 180 && dpd <= 209) {
    return 'BUCKET_180_209';
  } else if (dpd >= 210) {
    return 'BUCKET_above_209';
  } else {
    return 'NOT_DELINQUENT';
  }
}

export default LeadsByDPDbucket;
