import { useEffect, useState } from 'react';
import { VegaPage } from '../../../components/common';
import VegaSelector from '../../../components/common/VegaSelector';
import {
  CorporateCardIcon,
  CreditCardIcon,
  UserIcon,
} from '../../../components/icon/Icons';
import { BACKGROUND } from '../../../constants/style';
import { useClientAuth } from '../../../providers/ClientProvider';
import { fetchAccountList } from '../api/account';
import ListOfUserMenagement from '../components/MCCUserManagement/ListOfUserMenagement';
import MCCFilterSection from '../components/MCCUserManagement/MCCFilterSection';

export interface ISectionProps {
  id: string;
  text: string;
  image: JSX.Element;
  bgColor: any;
}

const PROGRAM = [
  {
    id: 'CUSTOMER_PROFILE',
    text: 'Customer Profile',
    image: <CorporateCardIcon />,
    bgColor: BACKGROUND.BLUE,
    color: 'white',
  },
  {
    id: 'CARD_SUMMARY',
    text: 'Card Summary',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
  },
  {
    id: 'WALLET_SUMMARY',
    text: 'Wallet Summary',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
  },
];

const MCCUserManagement = () => {
  const { clientId, userId, mccPlatformId: branchId } = useClientAuth();
  const [customerData, setCustomerData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>({
    mobile: '',
  });
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [activeState, setActiveState] = useState<ISectionProps>(PROGRAM[0]);

  const handleChange = (name: string, value: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const selectedTab = (data: any) => {
    setActiveState(data);
  };

  const getCustomerList = async () => {
    setListLoading(true);
    fetchAccountList({
      value: filterData.mobile ? filterData.mobile : branchId,
      page: 0,
      pageSize: 100,
    })
      .then((res) => {
        const getData = res.data.records
          ?.map((value) => ({
            ...value,
            id: value?.account?.id ?? value?.customer?.customerId,
          }))
          .filter((val) => val.customer?.status !== 'DRAFT')
          .filter((val) => val.customer?.status !== 'UNDER_REVIEW');
        console.log('getData', getData);
        setCustomerData(getData);
        setFilterData({
          mobile: '',
        });
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };
  useEffect(() => {
    getCustomerList();
  }, []);

  return (
    <VegaPage>
      <VegaSelector
        data={PROGRAM}
        setActiveState={selectedTab}
        active={activeState}
        header="User Management"
        subHeader="All Live programs till date."
      />
      <MCCFilterSection
        state={filterData}
        handleChange={handleChange}
        onSearch={getCustomerList}
        isSearch
      />
      <ListOfUserMenagement
        customerData={customerData}
        active={activeState}
        getCustomerList={getCustomerList}
        loading={listLoading}
      />
    </VegaPage>
  );
};

export default MCCUserManagement;
