import { useState } from 'react';
import { VegaPage } from '../common';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import { PRIMARY_COLOR, QUICK_ACCESS } from '../../constants/style';
import VegaBreadcrumbs from '../common/v2/VegaBreadcrumbs';
import {
  ActionMediumTypo,
  ComponentBody2Typo,
  PageHeadingTypo,
} from '../common/Typography';
import Stack from '@mui/material/Stack';
import FilterList from '@mui/icons-material/FilterList';
import VegaSearchfieldV4 from '../common/v4/VegaSearchfieldV4';
import VegaDataGridV4 from '../common/v4/VegaDataGridV4';
import LoginHistoryFilter from './LoginHistoryFilter';
import dayjs, { Dayjs } from 'dayjs';
import { useSnackbar } from '../../providers/SnackbarProvider';
import IconButton from '@mui/material/IconButton';

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    valueFormatter: ({ value }: { value: any }) =>
      value ? dayjs(value).format('MMM DD, YYYY') : '-',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
  {
    field: 'time',
    headerName: 'Time',
    valueFormatter: ({ value }: { value: any }) =>
      value ? dayjs(value).format('hh:mm:ss') : '-',
    flex: 1,
    sortable: false,
    minWidth: 150,
  },

  {
    field: 'dashboard',
    headerName: 'Dashboard',
    flex: 1,
    sortable: false,
    minWidth: 150,
  },
  {
    field: 'deviceId',
    headerName: 'Device ID',
    flex: 1,
    sortable: false,
    minWidth: 150,
  },
  {
    field: 'ip',
    headerName: 'IP Address',
    flex: 1,
    sortable: false,
    minWidth: 150,
  },
  {
    field: 'activity',
    headerName: 'Activity',
    flex: 1,
    sortable: false,
    minWidth: 150,
  },
];

const mockData = [
  {
    id: '1',
    date: dayjs(),
    time: dayjs(),
    dashboard: 'Client',
    deviceId: 'AAAAAAA-BBBBB-CCCC',
    ip: '192.168.123.132',
    activity: 'Login',
  },
];

type ViewLoginHistoryProps = {
  onGoBack: () => void;
};

// ! Hardcoded
function ViewLoginHistory({ onGoBack }: ViewLoginHistoryProps) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { setSnackbar } = useSnackbar();
  const [activeFilterStatus, setActiveFilterStatus] = useState<string | null>(
    null
  );

  const onFilterChange = (
    value?: string,
    startDate?: Dayjs,
    endDate?: Dayjs
  ) => {
    if (!value) {
      setActiveFilterStatus(null);
      setSnackbar('Filter removed');
      return;
    }

    let startD = dayjs();
    let endD = dayjs();
    if (value === 'custom' && startDate && endDate) {
      startD = startDate;
      endD = endDate;
    } else if (value === 'past7') {
      startD = endD.subtract(7, 'day');
    } else if (value === 'past14') {
      startD = endD.subtract(14, 'day');
    }

    setActiveFilterStatus(
      `${startD.format('MMM DD, YYYY')} - ${endD.format('MMM DD, YYYY')}`
    );

    setIsFilterOpen(false);
  };

  return (
    <VegaPage
      sx={{
        gap: 2,
      }}
    >
      <Box
        sx={{
          px: 3.25,
          bgcolor: QUICK_ACCESS.background[50],
          pt: 3,
          pb: 1.5,
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          borderBottom: `1px solid ${QUICK_ACCESS.border[50]}`,
        }}
      >
        <VegaBreadcrumbs
          separator="›"
          sx={{
            '& .MuiBreadcrumbs-separator': {
              color: QUICK_ACCESS.primary.blue,
            },
          }}
        >
          <ComponentBody2Typo
            color={QUICK_ACCESS.text[200]}
            sx={{
              cursor: 'pointer',
            }}
            onClick={onGoBack}
          >
            More
          </ComponentBody2Typo>
          <ComponentBody2Typo color={QUICK_ACCESS.primary.blue}>
            User Login History
          </ComponentBody2Typo>
        </VegaBreadcrumbs>
      </Box>
      <Stack px={3.25} gap={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <PageHeadingTypo>User Login History</PageHeadingTypo>
          <Stack direction="row" gap={1.5} alignItems="center">
            {activeFilterStatus && (
              <Stack
                direction="row"
                gap={0.5}
                alignItems="center"
                px={2}
                py={0.5}
                bgcolor={PRIMARY_COLOR.blue[50]}
                borderRadius={4}
              >
                <ActionMediumTypo
                  sx={{
                    color: QUICK_ACCESS.primary.blue,
                  }}
                >
                  {activeFilterStatus}
                </ActionMediumTypo>
                <IconButton
                  sx={{
                    padding: 0,
                  }}
                  onClick={() => onFilterChange()}
                >
                  <CloseRoundedIcon
                    sx={{
                      color: QUICK_ACCESS.primary.blue,
                      fontSize: 16,
                    }}
                  />
                </IconButton>
              </Stack>
            )}
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={1}
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsFilterOpen(true)}
            >
              <FilterList
                sx={{
                  color: activeFilterStatus
                    ? QUICK_ACCESS.primary.blue
                    : QUICK_ACCESS.text[100],
                  fontSize: 16,
                }}
              />
              <ActionMediumTypo
                sx={{
                  color: activeFilterStatus
                    ? QUICK_ACCESS.primary.blue
                    : QUICK_ACCESS.text[100],
                }}
              >
                Filters
              </ActionMediumTypo>
            </Stack>
            <VegaSearchfieldV4 />
          </Stack>
        </Stack>
        <VegaDataGridV4 data={mockData} columns={columns} idColumn="id" />
      </Stack>
      <LoginHistoryFilter
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onSubmit={onFilterChange}
      />
    </VegaPage>
  );
}

export default ViewLoginHistory;
