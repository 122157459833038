export enum TransactionIndicator {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum TransactionStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  INIT = 'INIT',
}

export enum TransactionCode {
  CARD_ECOM = 'CARD_ECOM',
  CARD_POS = 'CARD_POS',
  CARD_NFC = 'CARD_NFC',
  DAIL_A_DRAFT = 'DAIL_A_DRAFT',
  EMI_PRINCIPAL = 'EMI_PRINCIPAL',
  CARD_CASH_WITHDRAW = 'CARD_CASH_WITHDRAW',
  CASH_FEE = 'CASH_FEE',
  EMI_FEE = 'EMI_FEE',
  FEE = 'FEE',
  EMI_INTEREST = 'EMI_INTEREST',
  CASH_INTEREST = 'CASH_INTEREST',
  RETAIL_INTEREST = 'RETAIL_INTEREST',
  PAYMENT = 'PAYMENT',
  CASH_PAYMENT = 'CASH_PAYMENT',
  PAYMENT_REWARDS = 'PAYMENT_REWARDS',
  CASH_TO_EMI = 'CASH_TO_EMI',
  RETAIL_TO_EMI = 'RETAIL_TO_EMI',
  STATEMENT_TO_EMI = 'STATEMENT_TO_EMI',
  REFUND = 'REFUND',
  SURPLUS_TRANSFER = 'SURPLUS_TRANSFER',
  TAX = 'TAX',
  RETAIL_FEE_TAX = 'RETAIL_FEE_TAX',
  CASH_FEE_TAX = 'CASH_FEE_TAX',
  EMI_FEE_TAX = 'EMI_FEE_TAX',
  SETTLE_CHARGEBACK = 'SETTLE_CHARGEBACK',
  CHARGEBACK_TO_SURPLUS = 'CHARGEBACK_TO_SURPLUS',
  SURPLUS = 'SURPLUS',
  CASH_AT_POS = 'CASH_AT_POS',
  WALLET_TO_WALLET_WATERFALL = 'WALLET_TO_WALLET_WATERFALL',
  WALLET_TO_WALLET = 'WALLET_TO_WALLET',
  RELOAD_CARD = 'RELOAD_CARD',
  CASH_WITHDRAWAL = 'CASH_WITHDRAWAL',
  POS = 'POS',
  ECOM = 'ECOM',
  CONTACTLESS = 'CONTACTLESS',
  MANUAL_ADJUSTMENT = 'MANUAL_ADJUSTMENT',
  CARD_REFUND = 'CARD_REFUND',
  MARKUP_FEE = 'MARKUP_FEE',
  CORPORATE_FUND_LOAD = 'CORPORATE_FUND_LOAD',
}
