import { useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { AccountPolicyInterface } from '../../../types/policy';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DataTile from '../DataTile';
import ProgramDataGrid from './ProgramDataGrid';

type AccountPolicyTable = {
  category: string;
  value_type: string;
  value: string | number;
};

const getAccountPolicyColumns = (): GridColDef<AccountPolicyTable>[] => [
  {
    field: 'category',
    headerName: 'Transaction Category',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
  {
    field: 'value_type',
    headerName: 'Value Type',
    flex: 1,
    sortable: false,
    minWidth: 180,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      capitalize(params.value),
  },
  {
    field: 'value',
    headerName: 'Value',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
];

type ViewAccountPolicyProps = {
  policy: AccountPolicyInterface;
};
function ViewAccountPolicy({ policy }: ViewAccountPolicyProps) {
  const columnsRef = useMemo(() => getAccountPolicyColumns(), []);

  const tableData: AccountPolicyTable[] = useMemo(() => {
    if (!policy) {
      return [];
    }

    const data: AccountPolicyTable[] = [
      {
        category: 'Overlimit',
        value_type: policy.overlimit?.value_type,
        value: policy.overlimit?.value,
      },
      {
        category: 'Payment Deviation',
        value_type: policy.payment_deviation.value_type,
        value: policy.payment_deviation.value,
      },
      {
        category: 'Cash Limit',
        value_type: policy.cash_limit.value_type,
        value: policy.cash_limit.value,
      },
      {
        category: 'Charge Off',
        value_type: 'Days',
        value: policy.days_to_mark_account_charge_off,
      },
      {
        category: 'Write Off',
        value_type: 'Days',
        value: policy.days_to_mark_account_write_off,
      },
    ];
    return data;
  }, [policy]);

  if (!policy) {
    return null;
  }

  return (
    <Box
      sx={{
        py: 3,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6} sm={4}>
          <DataTile
            title="Grace Period"
            value={`${policy.grace_period ?? '-'}`}
          />
        </Grid>
      </Grid>
      <ProgramDataGrid
        data={tableData}
        columns={columnsRef}
        idColumn={'category'}
      />
    </Box>
  );
}

export default ViewAccountPolicy;
