import { useQuery } from 'react-query';
import { fetchInvoice } from '../../api/setting';

export const useFinanceInvoiceList = ({
  clientId,
  year,
  month,
}: {
  clientId: string;
  year: number | string;
  month: number;
}) => {
  const res = useQuery({
    queryKey: ['finance-invoice-list', clientId, year, month],
    queryFn: () => fetchInvoice(clientId, year, month),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
  });

  return res;
};
