import * as React from 'react';
import { SVGProps } from 'react';
import { ACCENT } from '../../constants/style';

const CashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3001 3.24585C9.01117 2.8014 7.59406 2.77801 6.28605 3.18268C4.85379 3.6258 3.30636 3.6258 1.8741 3.18268L1.70006 3.12884L1.69996 8.67208L2.16953 8.81736C3.40926 9.20091 4.75064 9.20091 5.99037 8.81736C7.39156 8.38385 8.89963 8.37537 10.3 8.78649L10.3001 3.24585ZM5.99049 2.22736C7.55593 1.74304 9.25476 1.78922 10.7877 2.35835L10.9741 2.42754C11.1701 2.50029 11.3001 2.68726 11.3001 2.89629L11.3 9.28749C11.2999 9.73223 10.8565 10.0406 10.4396 9.88583C9.1139 9.39366 7.64134 9.35334 6.28593 9.77268C4.85366 10.2158 3.30624 10.2158 1.87397 9.77268L1.05217 9.51843C0.842744 9.45364 0.699947 9.25998 0.699951 9.04076L0.700062 3.12882C0.700074 2.45538 1.35227 1.97447 1.99562 2.17351L2.16966 2.22736C3.40939 2.61091 4.75076 2.61091 5.99049 2.22736Z"
      fill={props.color || ACCENT.violet[500]}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.75 4.50002C4.75 4.36195 4.86193 4.25002 5 4.25002H7.35714C7.49522 4.25002 7.60714 4.36195 7.60714 4.50002C7.60714 4.63809 7.49522 4.75002 7.35714 4.75002H6.56868C6.64027 4.85918 6.69196 4.98012 6.72141 5.10716H7.35714C7.49522 5.10716 7.60714 5.21909 7.60714 5.35716C7.60714 5.49523 7.49522 5.60716 7.35714 5.60716H6.72141C6.67501 5.80734 6.57337 5.99239 6.42573 6.14003C6.2181 6.34766 5.93649 6.4643 5.64286 6.4643H5.60355L6.46249 7.32324C6.56012 7.42087 6.56012 7.57916 6.46249 7.6768C6.36486 7.77443 6.20657 7.77443 6.10894 7.6768L4.82322 6.39108C4.75173 6.31958 4.73034 6.21205 4.76903 6.11863C4.80773 6.02522 4.89889 5.9643 5 5.9643H5.64286C5.80388 5.9643 5.95831 5.90034 6.07217 5.78648C6.12448 5.73417 6.16626 5.6733 6.19614 5.60716H5C4.86193 5.60716 4.75 5.49523 4.75 5.35716C4.75 5.21909 4.86193 5.10716 5 5.10716H6.19614C6.16626 5.04102 6.12448 4.98015 6.07217 4.92785C5.95831 4.81399 5.80388 4.75002 5.64286 4.75002H5C4.86193 4.75002 4.75 4.63809 4.75 4.50002Z"
      fill={props.color || '#5029DB'}
    />
  </svg>
);
export default CashIcon;
