import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../common/Typography';
import VegaFilterButtonsV4 from '../../../common/v4/VegaFilterBtnsV4';
import {
  ACCENT,
  INDICATIVE,
  PRIMARY_COLOR,
  QUICK_ACCESS,
} from '../../../../constants/style';
import { useState } from 'react';
import dayjs from 'dayjs';
import { getFilterOptions } from '../../backOfficeUtils';
import VegaMetricCardV4 from '../../../common/v4/VegaMetricCardV4';
import VegaDoughnutChartV4 from '../../../common/Charts/VegaDoughnutChartV4';
import { useProgramData } from '../../../../providers/ProgramDataProvider';
import { DATE_FORMAT_US } from '../../../../constants/url';
import { getDateRange } from '../../../../utils/common.utils';
import { DATE_FILTERS } from '../../../../enums/commons';
import { useAnalyticsDataQuery } from '../../../../store/queries/analyticsQueries';
import { TransactingCustomerAnalyticsMetaData } from '../../../../types/analytics';
import { ANALYTICS_QUERY_NAME } from '../../../../enums/analytics.enum';

const AccHolderTransactingCust = () => {
  const [activeIdx, setActiveIdx] = useState(4);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: '',
    endDate: '',
  });
  const { selectedProgram } = useProgramData();
  const programId = selectedProgram?.programId;

  const onFilterCellClick = (idx: number) => {
    if (idx !== 0) {
      setActiveIdx(idx);
      setSelectedDateRange({ startDate: '', endDate: '' });
    }
  };

  const submitDateRange = (startDate: string, endDate: string) => {
    setSelectedDateRange({
      startDate: dayjs(startDate).format('MMM D, YYYY'),
      endDate: dayjs(endDate).format('MMM D, YYYY'),
    });
    setActiveIdx(0);
  };

  const onCloseCustomRangeClick = () => {
    setSelectedDateRange({ startDate: '', endDate: '' });
    setActiveIdx(4);
  };

  const filterOptions = getFilterOptions(selectedDateRange, 'shorter');
  const dateRange =
    activeIdx === 0
      ? {
          startDate: dayjs(selectedDateRange.startDate).format(DATE_FORMAT_US),
          endDate: dayjs(selectedDateRange.endDate).format(DATE_FORMAT_US),
        }
      : getDateRange(
          filterOptions[activeIdx]?.text as DATE_FILTERS,
          DATE_FORMAT_US
        );

  const { data: metaData } =
    useAnalyticsDataQuery<TransactingCustomerAnalyticsMetaData>({
      queryName: ANALYTICS_QUERY_NAME.CCMS_TRANSACTING_CUSTOMER_DATA_RANGE,
      programId: programId as string,
      ...dateRange,
    });

  const structuredMetaData = (metaData?.records || [])[0];

  return (
    <Stack
      px={2}
      py={3}
      gap={4}
      sx={{
        backgroundColor: QUICK_ACCESS.background[50],
        border: `1px solid ${QUICK_ACCESS.border[50]}`,
        borderRadius: '16px',
      }}
    >
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="space-between"
        flex={1}
      >
        <PageHeadingTypo>Transacting Customer</PageHeadingTypo>
        <VegaFilterButtonsV4
          data={getFilterOptions(selectedDateRange, 'shorter')}
          activeIdx={activeIdx}
          onCellClick={onFilterCellClick}
          submitDateRange={submitDateRange}
          customIdx={0}
          onCloseCustomRangeClick={onCloseCustomRangeClick}
        />
      </Stack>
      <Stack direction="row" gap={3}>
        <Stack gap={2} flex={1} maxWidth={610}>
          <VegaMetricCardV4
            mainInfo={{
              text: structuredMetaData?.total_transacting_customer,
              label: 'Total accounts',
              sx: {
                color: QUICK_ACCESS.primary.blue,
              },
              labelSx: {
                color: QUICK_ACCESS.primary.blue,
              },
            }}
            paperSx={{
              backgroundColor: PRIMARY_COLOR.blue[50],
            }}
            badge={{
              props: {
                text: 30,
              },
              helperText: 'from last day',
            }}
          />
          <Stack direction="row" gap={2} flexWrap={'wrap'}>
            <VegaMetricCardV4
              mainInfo={{
                text: structuredMetaData?.accounts_with_more_than_1_transaction,
                label: 'Total accounts with > 1 transaction ever',
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: 30,
                },
                helperText: 'from last day',
              }}
            />
            <VegaMetricCardV4
              mainInfo={{
                text: structuredMetaData?.accounts_with_more_than_5_transactions,
                label: 'Total accounts with > 5 transaction ever',
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: 0,
                },
                helperText: 'from last day',
              }}
            />
          </Stack>
          <Stack direction="row" gap={2} flexWrap={'wrap'}>
            <VegaMetricCardV4
              mainInfo={{
                text: '--',
                label: 'Total accounts with active EMIs',
                sx: {
                  color: QUICK_ACCESS.text[100],
                },
              }}
              paperSx={{
                flex: 1,
              }}
              badge={{
                props: {
                  text: -30,
                },
                helperText: 'from last day',
              }}
            />
          </Stack>
        </Stack>
        <Stack flex={1}>
          <VegaDoughnutChartV4
            mainData={data}
            colors={[
              PRIMARY_COLOR.blue[100],
              ACCENT.seaGreen[200],
              INDICATIVE.blue[400],
              ACCENT.violet[300],
              PRIMARY_COLOR.orange[400],
            ]}
            respContainerData={{
              height: 436,
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const data = [
  { name: '< 21 yrs', value: 200 },
  { name: '21 - 28 yrs', value: 400 },
  { name: '28 - 35 yrs', value: 200 },
  { name: '35 - 45 yrs', value: 200 },
  { name: '> 45 yrs', value: 1000 },
];

export default AccHolderTransactingCust;
