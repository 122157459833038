import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {};

const normalSx = { fontSize: 12, color: '#354356' };
const boldSx = { fontSize: 12, color: '#181F28' };
const linkSx = { fontSize: 12, color: '#1047DC' };

function TransactionFrm({}: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography color="#1B1D22" fontSize={17} className="font-aspekta-700">
        Velocity Checks
      </Typography>
      <Box>
        <ul
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <li>
            <Typography sx={normalSx}>
              Customer cannot consume more than
              <Typography component="span" sx={boldSx}>
                {' 10% '}
              </Typography>
              of the limit in the first
              <Typography component="span" sx={boldSx}>
                {' 24 '}
              </Typography>
              hours of activation in a single transaction.
            </Typography>
          </li>
          <li>
            <Typography sx={normalSx}>
              The customer cannot consume more than
              <Typography component="span" sx={linkSx}>
                {' 80% '}
              </Typography>
              of the cumulative limit in the first
              <Typography component="span" sx={linkSx}>
                {' 24 hours '}
              </Typography>
              of activation.
            </Typography>
          </li>
          <li>
            <Typography sx={normalSx}>
              The customer cannot make more than
              <Typography component="span" sx={linkSx}>
                {' 10 '}
              </Typography>
              [this should not include charges, taxes etc] transactions in the
              first
              <Typography component="span" sx={linkSx}>
                {' 24 hours '}
              </Typography>
              of activation.
            </Typography>
          </li>
          <li>
            <Typography sx={normalSx}>
              The customer cannot consume in the MCC code from
              <Typography component="span" sx={linkSx}>
                {' 3300 '}
              </Typography>
              -
              <Typography component="span" sx={linkSx}>
                {' 3499 '}
              </Typography>
              - more than
              <Typography component="span" sx={linkSx}>
                {' 50% '}
              </Typography>
              of the limits or
              <Typography component="span" sx={linkSx}>
                {' 50k'}
              </Typography>
              whichever is higher in a single transaction.
            </Typography>
          </li>
          <li>
            <Typography sx={normalSx}>
              The customer cannot consume in a high risk MCG [from institution
              resources] more than
              <Typography component="span" sx={linkSx}>
                {' 20% '}
              </Typography>
              of the limit in a single transaction.
            </Typography>
          </li>
          <li>
            <Typography sx={normalSx}>
              The customer cannot consume Overlimit on MCC code from
              <Typography component="span" sx={linkSx}>
                {' 3300 '}
              </Typography>
              -
              <Typography component="span" sx={linkSx}>
                {' 3499 '}
              </Typography>
            </Typography>
          </li>
        </ul>
      </Box>
    </Box>
  );
}

export default TransactionFrm;
