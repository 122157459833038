import { AccountStatus } from '../enums/account.enum';
import { Currency } from './cardRefund';

export interface AccountDataDto {
  accountId: string;
  accountType: AccountType;
  parentAccountId: string;
  programId: string;
  programName?: string;
  programCategoryType: ProgramCategoryType;
  customerId: string;
  applicationId: string;
  clientId: string;
  gracePeriod: number;
  paymentDeviation: number;
  paymentDueDate: number;
  cycleEndDate: number;
  billingCycleFrequency: string;
  accountOriginationMode: string;
  cashBalance: number;
  totalAmountDue: number;
  delinquencyLevel: number;
  currency: Currency;
  activationDate?: string;
  agreement: string;
  lenderId: string;
  status: AccountStatus;
  activityStatus: AccountActivityStatus;
  balanceType: AccountBalanceType;
  interestStatus: InterestStatus;
  lastAccrualDate?: string;
  lastLiquidationDate?: string;
  lastAnnualMembershipChargeDate?: string;
  limitUpdateDate?: string;
  physicalStatementOpted: boolean;
  overlimitOpted: boolean;
  physicalStatementAvailed: number;
  timezone: string;
  createdAt: string;
  updatedAt: string;
  accountLimits?: AccountLimitProps;
}

export interface AccountLimitProps {
  id: string;
  accountLimit: number;
  perTransactionLimit: number;
  surplus: number;
  charge: number;
  availableLimit: number;
  lastLimit: number;
  cashLimit: number;
  cashAvailableLimit: number;
  creditLimitUsed: number;
  cashLimitUsed: number;
  overlimit: number;
  overlimitUsed: number;
  limitHold: number;
  cashLimitHold: number;
  overlimitHold: number;
  retailEmiHold: number;
  cashEmiHold: number;
  unbilledOutstanding: number;
  unbilledCharges: number;
  unbilledInterest: number;
  unbilledEmi: number;
  unbilledRepayment: number;
  limitUsedInCurrentCycle: number;
  createdAt: string;
  updatedAt: string;
}

export enum AccountType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}
export enum ProgramCategoryType {
  CREDIT_CARD = 'CREDIT_CARD',
  PREPAID_CARD = 'PREPAID_CARD',
  FOREX_CARD = 'FOREX_CARD',
  LOANS = 'LOANS',
}

export enum AccountActivityStatus {
  NORMAL = 'NORMAL',
  SUPERVISION = 'SUPERVISION',
  CHARGED_OFF = 'CHARGED_OFF',
  CLOSED = 'CLOSED',
}
export enum AccountBalanceType {
  ZERO_BALANCE = 'ZERO_BALANCE',
  CREDIT_BALANCE = 'CREDIT_BALANCE',
  DEBIT_BALANCE = 'DEBIT_BALANCE',
  APPROVED_OVERLIMIT = 'APPROVED_OVERLIMIT',
  CRITICALLY_OVERLIMIT = 'CRITICALLY_OVERLIMIT',
}

export enum InterestStatus {
  NORMAL = 'NORMAL',
  REVOLVING = 'REVOLVING',
  DELINQUENT = 'DELINQUENT',
}
