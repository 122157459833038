import { Currency } from './cardRefund';

export enum EMIStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  PARTIAL_PAID = 'PARTIAL_PAID',
  CLOSED = 'CLOSED',
  BILLED = 'BILLED',
  UNBILLED = 'UNBILLED',
  REAMORTIZED = 'REAMORTIZED',
  MOVED_TO_LEDGER = 'MOVED_TO_LEDGER',
}

export interface EMIData {
  id: string;
  loan_id: string;
  program_id: string;
  account_id: string;
  installment_amount: number;
  principle: number;
  interest: number;
  gap_interest: number;
  paid_amount: number;
  fee: number;
  paid_principal: number;
  paid_interest: number;
  paid_fee: number;
  currency: Currency;
  paid_date: string;
  due_date: string;
  status: EMIStatus;
  billing_status: EMIStatus;
  parent_installment_id: string;
  rewards: string;
  created_at: string;
  updated_at: string;
}
