import { Stack } from '@mui/material';
import VegaDrawerV4 from '../../../../../common/v4/VegaDrawerV4';
import { Controller, useForm } from 'react-hook-form';
import VegaAutocompleteSearchV4 from '../../../../../common/v4/VegaAutocompleteSearchV4';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useUnwaivedChargesListFromAccountQuery } from '../../../../../../store/queries/transactionQueries';
import { useSupport } from '../../../../../../providers/SupportProvider';
import VegaRadioV4 from '../../../../../common/v4/VegaRadioV4';
import VegaSelectV4 from '../../../../../common/v4/VegaSelectV4';
import VegaTextfieldV4 from '../../../../../common/v4/VegaTextfieldV4';
import { WaiverType } from '../../../../../../types/transaction';
import { waiveAmount } from '../../../../../../api/transaction';
import { Currency } from '../../../../../../types/cardRefund';
import { QUERY_NAMES } from '../../../../../../enums/commons';
import { useQueryClient } from 'react-query';

interface IWaiveAmountDrawerProps {
  open: boolean;
  onClose: () => void;
}

interface IForm {
  gst: 'true' | 'false';
  type: string;
  amt?: number;
  reason: string;
}

const WaiveAmountDrawer = ({ open, onClose }: IWaiveAmountDrawerProps) => {
  const { accountData } = useSupport();
  const [loading, setLoaing] = useState(false);
  const queryClient = useQueryClient();
  const [selectedTransaction, setSelectedTransaction] = useState<
    undefined | { label: string; id: string; idx: number }
  >(undefined);

  const { data, isFetching } = useUnwaivedChargesListFromAccountQuery({
    accountId: accountData?.accountId || '',
  });

  const cleanedData = useMemo(
    () =>
      data?.map((el, idx) => ({
        label: el.transactionId,
        id: el.transactionId,
        idx,
      })) || [],
    [data]
  );

  const updateSearchValue = (
    e: SyntheticEvent<Element, Event>,
    newVal: any
  ) => {
    setSelectedTransaction(newVal);
  };

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    getValues,
    watch,
    setValue,
    clearErrors,
  } = useForm<IForm>({
    mode: 'onTouched',
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const onClear = () => {
    reset();
    setSelectedTransaction(undefined);
  };

  const gstWatch = watch('gst');
  const amtWatch = watch('amt');
  const isGstTrue = gstWatch === 'true';
  const selectedCharges =
    selectedTransaction && data
      ? data[selectedTransaction.idx].chargeDto.baseCharge
      : 0;

  const submitWaiveAmount = async () => {
    if (!accountData?.accountId || !selectedTransaction?.id) {
      return;
    }
    setLoaing(true);
    const values = getValues();

    await waiveAmount({
      accountId: accountData.accountId,
      chargeTxnId: selectedTransaction.id,
      currency: data
        ? data[selectedTransaction.idx].chargeDto.currency
        : Currency.INR,
      waiverAmount: Number(values.amt),
      waiverType: isGstTrue
        ? WaiverType.FULL_WITH_GST
        : WaiverType.PARTIAL_WITHOUT_GST,
      remarks: values.reason,
    })
      .then(() => {
        queryClient.invalidateQueries({
          predicate: (query: any) => {
            return [
              QUERY_NAMES.UNWAIVED_TRANSACTION_CHARGES_LIST_BY_ACC,
              QUERY_NAMES.WAIVER_LIST,
            ].includes(query.queryKey[0]);
          },
        });
        onClose();
        onClear();
      })
      .finally(() => {
        setLoaing(false);
      });
  };

  useEffect(() => {
    if (isGstTrue) {
      setValue('amt', selectedCharges);
    } else {
      setValue('amt', amtWatch ? selectedCharges : undefined);
    }
  }, [isGstTrue, selectedCharges, setValue]);

  return (
    <VegaDrawerV4
      open={open}
      onClose={onClose}
      header="Waive Amount"
      submitBtn={{
        show: true,
        props: {
          value: 'Waive Amount',
          variant: 'contained',
          onClick: handleSubmit(submitWaiveAmount),
          disabled: !gstWatch || !selectedTransaction || loading,
          loading: loading,
        },
      }}
      secondaryBtn={{
        props: {
          value: 'Clear',
          onClick: onClear,
          disabled: loading,
        },
      }}
    >
      <Stack width={400} gap={1.5}>
        <VegaAutocompleteSearchV4
          textFieldProps={{ standardWidth: '100%' }}
          options={cleanedData}
          value={selectedTransaction?.label || ''}
          onChange={updateSearchValue}
        />
        {selectedTransaction && (
          <Controller
            name="gst"
            control={control}
            render={({ field: { ref, onChange, value, ...rest } }) => {
              return (
                <VegaRadioV4
                  componentProps={{
                    radioGroup: {
                      ...rest,
                      value,
                      sx: {
                        display: 'flex',
                        gap: '20px',
                        mb: 2,
                        flexDirection: 'row',
                      },
                    },
                  }}
                  formControlLabels={[
                    {
                      label: 'With GST',
                      value: 'true',
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(e.target.checked ? 'true' : undefined);
                        clearErrors();
                      },
                    },
                    {
                      label: 'Without GST',
                      value: 'false',
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(e.target.checked ? 'false' : undefined);
                        clearErrors();
                      },
                    },
                  ]}
                  standardWidth="100%"
                />
              );
            }}
          />
        )}
        {gstWatch && (
          <Stack gap={3}>
            <Controller
              name="type"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <VegaSelectV4
                  {...rest}
                  error={Boolean(errors.type)}
                  disabled
                  header="Type"
                  labelText={errors.type?.message}
                  value={
                    gstWatch ? (isGstTrue ? 'Full' : 'Partial') : undefined
                  }
                  standardWidth={400}
                  options={['Full', 'Partial'].map((el) => ({
                    name: el,
                    id: el,
                  }))}
                  wrapperProps={{
                    sx: {
                      flex: 0,
                    },
                  }}
                />
              )}
            />
            <Controller
              name="amt"
              control={control}
              rules={{
                required: 'Required field',
                max: !isGstTrue
                  ? {
                      value: selectedCharges,
                      message: `Max Amount allowd to waive off is ₹${selectedCharges}`,
                    }
                  : undefined,
              }}
              render={({ field: { ref, value, ...rest } }) => (
                <VegaTextfieldV4
                  {...rest}
                  error={Boolean(errors.amt)}
                  header="Amount"
                  value={isGstTrue ? selectedCharges : value}
                  disabled={isGstTrue}
                  labelText={errors.amt?.message}
                  required
                  type="number"
                  placeholder="Enter Amount"
                  standardWidth={'100%'}
                />
              )}
            />
            <Controller
              name="reason"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <VegaTextfieldV4
                  {...rest}
                  error={Boolean(errors.reason)}
                  header="Reason"
                  multiline
                  rows={5}
                  placeholder="Enter Reason"
                  standardWidth={'100%'}
                />
              )}
            />
          </Stack>
        )}
      </Stack>
    </VegaDrawerV4>
  );
};

export default WaiveAmountDrawer;
