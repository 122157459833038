import { VegaCard, VegaPage } from '../../../components/common';
import { useEffect, useMemo, useState } from 'react';
import { VegaSelectSelectOptionType } from '../../../components/common/VegaSelect';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import AddUser from '../../Back-office/components/MCCTeamManagement/AddUser';
import MCCFilterSection from '../../Back-office/components/MCCUserManagement/MCCFilterSection';
import { IFilterProps } from '../../Back-office/pages/MCCAplicationManagement';

import { IAddUserState } from '../../Back-office/pages/MCCTeamManagement';
import { STATUS } from '../../variable';
import {
  UserPlatformEnums,
  UserRoleEnums,
  UserTeamType,
  addRole,
  addRoleForUser,
  addUser,
  fetchRolesForClient,
  fetchUserWithStatusForClient,
  updateStatusForUser,
} from '../api/roles';
import ListOfTeamManagementHQ from '../components/MCCTeamManagementChecker/ListOfTeamManagementHQ';
import SubmitFormWrapper from '../../Back-office/components/SubmitFormWrapper';
import { ACCESS_FEATURES } from '../../role';

const INITIAL_USER_DATA: IAddUserState = {
  clientId: '',
  platformId: '',
  platformName: UserPlatformEnums.FOREX_HQ,
  mccUser: true,
  name: '',
  mobileNumber: '',
  email: '',
  address: '',
  officialEmailId: '',
  idType: 'PAN',
  idNumber: '',
  status: 'ACTIVE',
  team: UserTeamType.OPERATIONS,
  roleType: UserRoleEnums.HQ_USER,
};

const MCCTeamManagementForHQ = () => {
  const { clientId, userId, mccPlatformId: branchId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [branchUser, setBranchUsers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEmptyKey, setIsEmptyKey] = useState<boolean>(false);

  const [branchCode, setBranchCode] = useState<string>('');
  const [filterData, setFilterData] = useState<IFilterProps>({
    mobile: '',
    status: 'ALL',
  });
  const [rolesList, setRolesList] = useState<VegaSelectSelectOptionType[]>([]);
  const [userProfile, setUserProfile] = useState<IAddUserState>({
    ...INITIAL_USER_DATA,
    clientId: clientId || '',
    platformId: branchId || '',
  });

  const BRANCH_ADMIN_ACCESS = ACCESS_FEATURES.BRANCH_ADMIN_ACCESS_FEATURES.map(
    (access: string) => ({
      entityRoles: [
        {
          entityId: branchId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    })
  );
  const BRANCH_USER_ACCESS = ACCESS_FEATURES.BRANCH_USER_ACCESS_FEATURES.map(
    (access: string) => ({
      entityRoles: [
        {
          entityId: branchId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    })
  );
  const HQ_USER_ACCESS = ACCESS_FEATURES.HQ_USER_ACCESS_FEATURES.map((access: string) => ({
    entityRoles: [
      {
        entityId: clientId,
        roles: ['EDIT'],
      },
    ],
    feature: access,
  }));
  const CORPORATE_ADMIN_ACCESS = ACCESS_FEATURES.CORPORATE_ADMIN_ACCESS_FEATURES.map(
    (access: string) => ({
      entityRoles: [
        {
          entityId: clientId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    })
  );

  const DEFUALT_BRANCH_ADMIN = {
    clientId,
    roleName: `FOREX_BRANCH_ADMIN`,
    accessFeatures: BRANCH_ADMIN_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };

  const DEFAULT_BRANCH_USERS = {
    clientId,
    roleName: 'FOREX_BRANCH_USER',
    accessFeatures: BRANCH_USER_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };

  const DEFAUL_HQ_USERS = {
    clientId,
    roleName: 'FOREX_HQ_USER',
    accessFeatures: HQ_USER_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };
  const DEFAUL_CORPORATE_ADMIN = {
    clientId,
    roleName: 'FOREX_CORPORATE_ADMIN',
    accessFeatures: CORPORATE_ADMIN_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };

  const [roleDetails, setRoleDetails] = useState<any>({
    ...DEFAUL_HQ_USERS,
  });

  const isValidInput = useMemo(() => {
    const flag =
      userProfile.email &&
      userProfile.mobileNumber &&
      userProfile.name &&
      userProfile.address &&
      userProfile.idNumber;

    if (!roleDetails.roleName || !flag) {
      return false;
    }

    return true;
  }, [userProfile]);

  const handleFilter = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const toggleUser = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (
    key: keyof IAddUserState,
    value: string | File | null
  ) => {
    setUserProfile((pre) => {
      let platformName = pre.platformName;
      if (key === 'roleType') {
        switch (value) {
          case UserRoleEnums.HQ_USER:
            platformName = UserPlatformEnums.FOREX_HQ;
            setRoleDetails({ ...DEFAUL_HQ_USERS });
            break;
          case UserRoleEnums.CORPORATE_ADMIN:
            platformName = UserPlatformEnums.FOREX_CORPORATE;
            setRoleDetails({ ...DEFAUL_CORPORATE_ADMIN });

            break;
          case UserRoleEnums.BRANCH_ADMIN:
            platformName = UserPlatformEnums.FOREX_BRANCH;
            setRoleDetails({ ...DEFUALT_BRANCH_ADMIN });
            break;
          default:
            // default branch user
            platformName = UserPlatformEnums.FOREX_BRANCH;
            setRoleDetails({ ...DEFAULT_BRANCH_USERS });
        }
      }
      return {
        ...pre,
        [key]: value,
        platformName,
      };
    });
  };
  const onClear = () => {
    setUserProfile({
      ...INITIAL_USER_DATA,
      clientId: clientId || '',
      platformId: branchId || '',
    });
  };

  const submitRole = () => {
    setIsLoading(true);
    const roleName = (roleDetails.roleName + userProfile.platformId).replaceAll(
      ' ',
      '_'
    );
    const entityId = userProfile.platformId;
    if (!roleName || !entityId) {
      setSnackbar('Role is not defined', 'error');
      setIsLoading(false);
      return;
    }
    const finalObj: any = {};
    const newArr = Object.keys(roleDetails).map((value: any) => {
      return {
        [value]: roleDetails[value],
      };
    });
    for (let i = 0; i < newArr.length; i++) {
      Object.assign(finalObj, newArr[i]);
    }

    const newObj = {
      ...finalObj,
      roleName: roleName,
      accessFeatures: finalObj?.accessFeatures?.map((item: any) => ({
        ...item,
        entityRoles: item?.entityRoles?.map((val: any) => ({
          ...val,
          entityId: entityId,
        })),
      })),
    };
    addRole(newObj)
      .then((res) => {
        setSnackbar('Role added!');
        setIsLoading(false);
        addUsers(res.data.id);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add role'),
          'error'
        );
        setIsLoading(false);
      });
  };

  const addUsers = (roleId: string) => {
    setIsLoading(true);
    if (!clientId) {
      setSnackbar('Client Id is not defined');
      return;
    }

    const { roleType, ...addUserData } = userProfile;

    addUser({
      ...addUserData,
      clientId,
    })
      .then(async (res) => {
        const roleForUser = {
          userId: res.data?.userId,
          roleId,
          roleName: roleDetails?.roleName,
          checkerMakerFlag: true,
        };
        addRoleForUser(roleForUser).then((res) => {
          console.log('res.data', res.data);
          getBranchUserList();
        });
        setSnackbar('User added!');
        setIsLoading(false);
        onClear();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add user'),
          'error'
        );
        setIsLoading(false);
        // onClear();
      });
  };
  const onAddUser = async () => {
    await submitRole();
  };

  const getBranchUserList = async () => {
    setListLoading(true);
    fetchUserWithStatusForClient({
      clientId,
    })
      .then((res) => {
        const copy = res.data.filter((val: any) => val.userRoles.length && val);
        console.log('copy', copy);
        setBranchUsers(copy);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  const getRoles = async () => {
    fetchRolesForClient(clientId as string)
      .then((res) => {
        const newArr = res.data?.map((val: any) => ({
          name: val.roleName,
          id: val.id,
        }));
        setRolesList(newArr);
      })
      .catch((err) => {});
  };

  const getFilterData = (arr: any[]) => {
    const newArr = arr.filter((val: any) => {
      if (filterData.mobile === '') {
        return val;
      } else if (
        val.mobileNumber.toLowerCase().includes(filterData.mobile.toLowerCase())
      ) {
        return val;
      } else if (
        val.email.toLowerCase().includes(filterData.mobile.toLowerCase())
      ) {
        return val;
      } else if (
        val.name.toLowerCase().includes(filterData.mobile.toLowerCase())
      ) {
        return val;
      }
      return false;
    });

    return newArr;
  };

  const filteredBranchUser = branchUser.filter((val: any) => {
    if (filterData.status === 'ALL') {
      return val;
    } else if (val?.userRoles[0]?.checkerMakerStatus === filterData.status) {
      return val;
    }
  });

  const onStatusChange = async (id: string, status: string) => {
    const obj = { status, userId, entityId: id };

    updateStatusForUser(obj)
      .then((res) => {
        setSnackbar('Status change!');
        getBranchUserList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to change status'),
          'error'
        );
      });
  };

  useEffect(() => {
    // getBranches();
    getRoles();
  }, []);

  useEffect(() => {
    getBranchUserList();
  }, []);
  return (
    <VegaPage>
      <VegaCard noPad>
        <SubmitFormWrapper
          titleText="Add New Profile"
          submitText="Add User"
          cancelText="Clear"
          onSubmit={onAddUser}
          onClear={onClear}
          toggle={toggleUser}
          isOpen={isOpen}
          loading={isLoading}
          disabled={isEmptyKey}
        >
          <AddUser
            setBranchCode={setBranchCode}
            state={userProfile}
            rolesList={[
              'HQ_USER',
              'BRANCH_ADMIN',
              'BRANCH_USER',
              'CORPORATE_ADMIN',
            ]}
            handleChange={handleChange}
            setIsEmptyKey={setIsEmptyKey}
          />
        </SubmitFormWrapper>
      </VegaCard>
      <MCCFilterSection
        isStatusFilter
        statusOption={STATUS}
        state={filterData}
        handleChange={handleFilter}

        // onSearch={onSearch}
      />
      <ListOfTeamManagementHQ
        state={getFilterData(filteredBranchUser)}
        onStatusChange={onStatusChange}
      />
    </VegaPage>
  );
};

export default MCCTeamManagementForHQ;
