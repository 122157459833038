import { Box, Stack } from '@mui/material';
import {
  ComponentBody1Typo,
  ListGroupHeadingTypo,
} from '../../../common/Typography';
import { QUICK_ACCESS } from '../../../../constants/style';
import CollectionSummaryAnalytics from './CollectionSummaryAnalytics';
import CustomerCountAndOverdueAnalytics from './CustomerCountAndOverdueAnalytics';
import CollectionCustDPDBucket from './CollectionCustDPDBucket';

const CollectionAnalytics = () => {
  return (
    <Stack gap={3} mb={10}>
      <Stack direction="row" gap={4} mb={2}>
        <Stack gap={1}>
          <ListGroupHeadingTypo sx={{ color: QUICK_ACCESS.text[100] }}>
            tOTAL AMOUNT OF OVERDUE CUSTOMER TD
          </ListGroupHeadingTypo>
          <ComponentBody1Typo>23,456</ComponentBody1Typo>
        </Stack>
        <Stack gap={1}>
          <ListGroupHeadingTypo sx={{ color: QUICK_ACCESS.text[100] }}>
            TOTAL OVERDUE AMOUNT TD
          </ListGroupHeadingTypo>
          <ComponentBody1Typo>₹ 27,34,11,250</ComponentBody1Typo>
        </Stack>
      </Stack>
      <CollectionSummaryAnalytics />
      <CustomerCountAndOverdueAnalytics />
      <CollectionCustDPDBucket />
    </Stack>
  );
};

export default CollectionAnalytics;
