import { ButtonProps, SxProps } from '@mui/material';
import { NEUTRALS, PRIMARY_COLOR, QUICK_ACCESS } from '../constants/style';
import {
  ActionLargeTypo,
  ActionMediumTypo,
  ActionSmallTypo,
} from '../components/common/Typography';
import { useCallback } from 'react';
import { ButtonVariants } from '../components/common/v4/VegaButtonV4';

export const useButtonUtils = (
  variant: ButtonVariants | undefined,
  size: ButtonProps['size'],
  isDisabled?: boolean,
  sx?: any
) => {
  const getBGColor = useCallback(
    (isHover: boolean) => {
      if (sx?.backgroundColor) return sx?.backgroundColor;
      switch (variant) {
        case 'contained':
          if (isDisabled) {
            return NEUTRALS.grey[100];
          }
          if (isHover) {
            return PRIMARY_COLOR.blue[400];
          }
          return QUICK_ACCESS.primary.blue;
        case 'outlined':
          if (isDisabled) {
            return NEUTRALS.grey[50];
          }
          return 'transparent';
        case 'mellowed':
          if (isDisabled) {
            return NEUTRALS.grey[50];
          }
          return PRIMARY_COLOR.blue[50];
        default:
          return 'transparent';
      }
    },
    [variant, isDisabled, sx?.backgroundColor]
  );

  const getOpacity = useCallback(() => {
    return isDisabled ? 0.5 : 1;
  }, [isDisabled]);

  const getTextColor = useCallback(
    (isHover: boolean) => {
      if (sx?.color) return sx?.color;
      switch (variant) {
        case 'contained':
          if (isDisabled) {
            return NEUTRALS.grey[600];
          }
          return QUICK_ACCESS.text[50];
        default:
          if (isDisabled) {
            return NEUTRALS.grey[600];
          }
          if (isHover) {
            return PRIMARY_COLOR.blue[400];
          }
          return QUICK_ACCESS.primary.blue;
      }
    },
    [variant, isDisabled, sx?.color]
  );

  const getTextComponent = useCallback(() => {
    switch (size) {
      case 'large':
        return ActionLargeTypo;
      case 'small':
        return ActionSmallTypo;
      default:
        return ActionMediumTypo;
    }
  }, [size]);

  const getBorderColor = useCallback(
    (isHover: boolean) => {
      if (sx?.borderColor) return sx?.borderColor;
      switch (variant) {
        case 'contained':
          if (isDisabled) {
            return NEUTRALS.grey[100];
          }
          if (isHover) {
            return PRIMARY_COLOR.blue[400];
          }
          return QUICK_ACCESS.primary.blue;
        case 'outlined':
          if (isDisabled) {
            return NEUTRALS.grey[600];
          }
          if (isHover) {
            return PRIMARY_COLOR.blue[400];
          }
          return QUICK_ACCESS.primary.blue;
        default:
          return 'transparent';
      }
    },
    [variant, isDisabled, sx?.borderColor]
  );

  const getPadding = useCallback(() => {
    if (sx?.padding) return sx?.padding;
    switch (size) {
      case 'large':
        return '10px 16px';
      case 'small':
        return '6px 16px';
      default:
        return '8px 16px';
    }
  }, [size, sx?.padding]);

  return {
    backgroundColor: getBGColor(false),
    color: getTextColor(false),
    borderColor: getBorderColor(false),

    backgroundColorHover: getBGColor(true),
    colorHover: getTextColor(true),
    borderColorHover: getBorderColor(true),

    opacity: getOpacity(),
    padding: getPadding(),
    TEXT_COMPONENT: getTextComponent(),
  };
};
