import { Address } from '../types/card';

export class AddressUtils {
  static formatToReadableString(address?: Partial<Address>) {
    if (!address) return '-';
    var readableAddress = '';

    if (address.line1) {
      readableAddress += address.line1;
    }

    if (address.line2) {
      readableAddress += `, ${address.line2}`;
    }

    if (address.city) {
      readableAddress += `, ${address.city}`;
    }

    if (address.state) {
      readableAddress += `, ${address.state}`;
    }

    if (address.pincode) {
      readableAddress += `, ${address.pincode}`;
    }

    if (address.country) {
      readableAddress += `, ${address.country}`;
    }

    return readableAddress;
  }
}
