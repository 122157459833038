import {
  SyntheticEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useClientAuth } from '../../providers/ClientProvider';
import { useProgramData } from '../../providers/ProgramDataProvider';
import { useAccountListQuery } from '../../store/queries/customerQueries';
import AccountListPage from './Accounts/AccountListPage';
import BillListPage from './Bills/BillListPage';
import CustomerWrap from './Customers/CustomerWrap';
import LedgerListPage from './Transactions/LedgerListPage';
import TransactionListPage from './Transactions/TransactionListPage';
import { PaginatedResponse } from '../../utils/api';
import { VegaCreateCustomersType } from '../../types/customers';
import { UseQueryResult } from 'react-query';
import {
  SearchOptions,
  searchByOptionsForTransactions,
} from './simulatorUtils';
import { AccountStatus } from '../../enums/account.enum';

interface ISimulatorMainWrapProps {
  route: 'customers' | 'accounts' | 'transactions' | 'ledger' | 'bills';
}

interface ISimulatorContextProps {
  customerListQuery?: UseQueryResult<
    PaginatedResponse<VegaCreateCustomersType>,
    unknown
  >;
  searchValue?: string;
  selectedAccId?: string;
  onTextChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  updateSearchValue?: (e: SyntheticEvent<Element, Event>, newVal: any) => void;
  updateActiveSearchBy?: (e: any) => void;
  activeSearchBy?: SearchOptions;
  customerRecords?: {
    label: string;
    id: string;
  }[];
  isCustDataFetching?: boolean;
}

export const SimulatorContext = createContext<ISimulatorContextProps>({});
export const useSimulatorContext = () => useContext(SimulatorContext);

const SimulatorMainWrap = ({ route }: ISimulatorMainWrapProps) => {
  const { clientId } = useClientAuth();
  const { selectedProgram } = useProgramData();
  const [activeSearchBy, setActiveSearchBy] = useState<SearchOptions>(
    searchByOptionsForTransactions[0].id
  );
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [selectedAccId, setSelectedAccId] = useState<string | undefined>(
    undefined
  );

  const customerListQuery = useAccountListQuery({
    clientId: clientId as string,
    programId: selectedProgram?.programId as string,
    pageNumber: 0,
    pageSize: 20,
    searchBy: activeSearchBy,
    search: searchValue,
    statusList: [AccountStatus.ACTIVE, AccountStatus.NEW],
  });

  const { data: customerData, isFetching: isCustDataFetching } =
    customerListQuery;

  const extraDetail = useCallback(
    (item: VegaCreateCustomersType) => {
      switch (activeSearchBy) {
        case SearchOptions.ACCOUNT_ID:
          return ' - ' + item.account?.accountId;
        case SearchOptions.CUSTOMER_ID:
          return ' - ' + item.customerId;
        case SearchOptions.MOBILE_NUMBER:
          return ' - ' + item.mobileNumber;
        default:
          return '';
      }
    },
    [activeSearchBy]
  );

  const customerRecords = useMemo(() => {
    return (
      customerData?.records
        .filter((el) => Boolean(el?.account?.accountId))
        .map((el) => ({
          label: el.firstName + ' ' + el.lastName + extraDetail(el),
          id: el.account?.accountId as string,
        })) || []
    );
  }, [customerData, extraDetail]);

  useEffect(() => {
    setSearchValue(undefined);
    setSelectedAccId(undefined);
    setActiveSearchBy(SearchOptions.CUSTOMER_NAME);
  }, [route, selectedProgram?.programId]);

  const updateSearchValue = (e: any, newVal: any) => {
    setSelectedAccId(newVal?.id);
    setSearchValue(newVal?.label);
    e.type === 'click' && setActiveSearchBy(SearchOptions.CUSTOMER_NAME);
  };

  const onTextChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(e.target.value);
  };

  const updateActiveSearchBy = (e: any) => {
    setActiveSearchBy(e.target.value);
    setSelectedAccId(undefined);
    setSearchValue(undefined);
  };

  const getDisplay = () => {
    switch (route) {
      case 'customers':
        return <CustomerWrap />;
      case 'accounts':
        return <AccountListPage />;
      case 'transactions':
        return <TransactionListPage />;
      case 'ledger':
        return <LedgerListPage />;
      default:
        return <BillListPage />;
    }
  };

  return (
    <SimulatorContext.Provider
      value={{
        updateSearchValue,
        customerRecords,
        customerListQuery,
        updateActiveSearchBy,
        activeSearchBy,
        searchValue,
        onTextChange,
        selectedAccId,
        isCustDataFetching,
      }}
    >
      {getDisplay()};
    </SimulatorContext.Provider>
  );
};

export default SimulatorMainWrap;
