import { alpha, styled } from '@mui/material';
import Switch from '@mui/material/Switch';
import { NEUTRALS, PRIMARY_COLOR, VEGA_COMMON } from '../../../constants/style';

export const VegaSwitchV4 = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'disabled',
})(({ theme, size = 'large', disabled }) => ({
  width: size === 'small' ? 32 : 36,
  height: size === 'small' ? 16 : 18,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 16,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  ':hover': {
    '& .MuiSwitch-track': {
      backgroundColor: disabled ? NEUTRALS.grey[100] : NEUTRALS.grey[200],
    },
  },

  '& .MuiSwitch-switchBase': {
    padding: 2,

    transition: theme.transitions.create(['color'], {
      duration: 200,
    }),

    '&.Mui-checked': {
      transform: size === 'small' ? 'translateX(16px)' : 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: disabled
          ? NEUTRALS.grey[100]
          : VEGA_COMMON.primaryBlue,
      },

      ':hover': {
        '& + .MuiSwitch-track': {
          backgroundColor: disabled
            ? NEUTRALS.grey[100]
            : PRIMARY_COLOR.blue[400],
        },
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: size === 'small' ? 12 : 14,
    height: size === 'small' ? 12 : 14,
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: disabled ? alpha(NEUTRALS.grey[600], 0.5) : '#FFFFFF',
  },
  '& .MuiSwitch-track': {
    borderRadius: size === 'small' ? 16 : 18,
    opacity: 1,
    boxSizing: 'border-box',
    backgroundColor: NEUTRALS.grey[100],
    ':hover': {
      backgroundColor: PRIMARY_COLOR.blue[400],
    },
  },
}));
