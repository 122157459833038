import { Dispatch, createContext, useContext, useState } from 'react';
import CustomerMainPage from './CustomerMainPage';

interface ICustomerContextProps {
  currState: CustomerState;
  setCurrState?: Dispatch<React.SetStateAction<CustomerState>>;
}

export type CustomerState = 'customer-list' | 'create-customer';

export const CustomerContext = createContext<ICustomerContextProps>({
  currState: 'customer-list',
});

export const useCustomerContext = () => useContext(CustomerContext);

const CustomerWrap = () => {
  const [currState, setCurrState] = useState<CustomerState>('customer-list');

  return (
    <CustomerContext.Provider
      value={{
        currState,
        setCurrState,
      }}
    >
      <CustomerMainPage />
    </CustomerContext.Provider>
  );
};

export default CustomerWrap;
