export interface VegaUser {
  userId: string;
  clientId: string;
  name: string;
  mobileNumber: string;
  email: string;
  address: string;
  officialEmailId: string;
  idType: string;
  idNumber: string;
  isAdmin: boolean;
  designation: string;
  status: VegaUserStatus;
  userRoles: VegaUserRole[];
  createdAt: Date;
  updatedAt: Date;
  corporateId?: string | null;
}

export enum VegaUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum VegaUserRoleStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface VegaUserRole {
  id: string;
  userId: string;
  roleId: string;
  roleName: string;
  status: VegaUserRoleStatus;
  createdAt: Date;
  updatedAt: Date;
}
