import React from 'react';
import Vega_Dialog from '../../../common/v3/Vega_Dialog';
import { Box, Stack } from '@mui/material';
import Vega_Text from '../../../common/v3/Vega_Text';
import { Close } from '@mui/icons-material';
import Vega_Button from '../../../common/v3/Vega_Button';
import { NEW_PRIMARY } from '../../../../constants/style';
import SupportUserAuditDetails from '../components/SupportUserAuditDetails';
import ListOfUserAuditLog from '../components/ListOfUserAuditLog';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const ViewPastActivityModal = ({ open, handleClose }: IProps) => {
  return (
    <Vega_Dialog
      fullWidth
      maxWidth="md"
      open={open}
      handleClose={handleClose}
      headerComponent={() => (
        <>
          <Stack
            p={3}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'start'}
          >
            <Stack gap={1}>
              <Vega_Text
                text={'View Past Activities'}
                color={'#3A4A5F'}
                fontSize={14}
                fontWeight={500}
              />
            </Stack>
            <Close
              sx={{ color: '#3A4A5F', cursor: 'pointer' }}
              onClick={handleClose}
            />
          </Stack>
        </>
      )}
      buttonComponent={() => <></>}
    >
      <Box px={1}>
        <ListOfUserAuditLog />
      </Box>
    </Vega_Dialog>
  );
};

export default ViewPastActivityModal;
