import { useCallback } from 'react';
import PartnerManagementBrandView from './PartnerManagementBrandView';
import PartnerManagementAddBrandForm from './PartnerManagementAddBrandForm';
import { usePartnerMgmtContext } from '../../mcc/Program-Dashboard/pages/PartnerManagementWrap';
import PartnerDetailsView from './PartnerDetailsView';

const PartnerManagementRevamp = () => {
  const { currState } = usePartnerMgmtContext();

  const viewDisplay = useCallback(() => {
    switch (currState) {
      case 'brand':
        return <PartnerManagementBrandView />;
      case 'partner':
        return <PartnerDetailsView />;
      case 'brand-form-add':
        return <PartnerManagementAddBrandForm />;
      default:
        return <PartnerManagementAddBrandForm isEdit />;
    }
  }, [currState]);

  const view = viewDisplay();
  return view;
};

export default PartnerManagementRevamp;
