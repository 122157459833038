import axios from 'axios';
import { PaginatedResponse } from '../mcc/types/Commons';
import {
  AcquirerRejectionRequest,
  AddChargebackRejectionReasonRequest,
  AddNetworkChargebackDisputeReasonRequest,
  ChargebackAuditDto,
  ChargebackDto,
  ChargebackRejectionReasonDto,
  CreateChargebackRequest,
  CreateNetworkChargebackRequest,
  FileArbitrationRequest,
  ListChargebackAuditRequest,
  ListChargebackRejectionReasonRequest,
  ListChargebackRequest,
  ListNetworkChargebackDisputeReasonRequest,
  NetworkChargebackDisputeReasonDto,
  ReopenChargebackRequest,
  UpdateChargebackStatusRequest,
} from '../types/chargeBack';
import { getFormData } from '../utils/common.utils';
import { DISPUTE } from '../api/endpoints';

const DISPUTE_BASE = DISPUTE.BASE;
const CHARGE_BACK_ENDPOINTS = DISPUTE.CHARGE_BACK;
const CHARGEBACK_BASE = DISPUTE_BASE + CHARGE_BACK_ENDPOINTS.BASE;

const DISPUTE_CHARGEBACK_LIST = CHARGEBACK_BASE + '/list';

export class ChargebackService {
  private static readonly baseUrl = CHARGEBACK_BASE;

  static async getChargeback(chargebackId: string) {
    const response = await axios.get<ChargebackDto>(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.GET_CHARGEBACK.replace(
        '{chargebackId}',
        chargebackId
      )}`
    );
    return response.data;
  }

  static async listChargeback(
    listChargebackRequest: Partial<ListChargebackRequest>
  ) {
    const response = await axios.post<PaginatedResponse<ChargebackDto>>(
      DISPUTE_CHARGEBACK_LIST,
      listChargebackRequest
    );
    return response.data;
  }

  static async createChargeback(
    createChargebackRequest: CreateChargebackRequest
  ) {
    const response = await axios.post<ChargebackDto>(
      `${this.baseUrl}`,
      getFormData(createChargebackRequest)
    );
    return response.data;
  }

  static async createNetworkChargeback(
    createNetworkChargebackRequest: CreateNetworkChargebackRequest
  ) {
    const response = await axios.post<ChargebackDto>(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.CREATE_NETWORK_CHARGEBACK}`,
      createNetworkChargebackRequest
    );
    return response.data;
  }

  static async updateChargebackStatus(
    updateChargebackStatusRequest: Partial<UpdateChargebackStatusRequest>
  ) {
    const response = await axios.patch<ChargebackDto>(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.UPDATE_CHARGEBACK_STATUS}`,
      updateChargebackStatusRequest
    );
    return response.data;
  }

  static async fileArbitration(fileArbitrationRequest: FileArbitrationRequest) {
    const response = await axios.post<ChargebackDto>(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.FILE_ARBITRATION}`,
      getFormData(fileArbitrationRequest)
    );
    return response.data;
  }

  static async rejectByAcquirer(
    acquirerRejectionRequest: AcquirerRejectionRequest
  ) {
    const response = await axios.post<ChargebackDto>(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.REJECT_BY_ACQUIRER}`,
      getFormData(acquirerRejectionRequest)
    );
    return response.data;
  }

  static async reopenChargeback(
    reopenChargebackRequest: ReopenChargebackRequest
  ) {
    const response = await axios.post<ChargebackDto>(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.REOPEN_CHARGEBACK}`,
      getFormData(reopenChargebackRequest)
    );
    return response.data;
  }

  static async listChargebackAudit(
    listChargebackAuditRequest: ListChargebackAuditRequest
  ) {
    const response = await axios.post<PaginatedResponse<ChargebackAuditDto>>(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.LIST_CHARGEBACK_AUDIT}`,
      listChargebackAuditRequest
    );
    return response.data;
  }

  static async addNetworkChargebackDisputeReason(
    addNetworkChargebackDisputeReasonRequest: AddNetworkChargebackDisputeReasonRequest
  ) {
    const response = await axios.post<NetworkChargebackDisputeReasonDto>(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.ADD_NETWORK_CHARGEBACK_DISPUTE_REASON}`,
      addNetworkChargebackDisputeReasonRequest
    );
    return response.data;
  }

  static async getNetworkChargebackDisputeReason(
    networkChargebackDisputeReasonId: string
  ) {
    const response = await axios.get<NetworkChargebackDisputeReasonDto>(
      `${
        this.baseUrl
      }${CHARGE_BACK_ENDPOINTS.GET_NETWORK_CHARGEBACK_DISPUTE_REASON.replace(
        '{networkChargebackDisputeReasonId}',
        networkChargebackDisputeReasonId
      )}`
    );
    return response.data;
  }

  static async listNetworkChargebackDisputeReason(
    listNetworkChargebackDisputeReasonRequest: ListNetworkChargebackDisputeReasonRequest
  ) {
    const response = await axios.post<
      PaginatedResponse<NetworkChargebackDisputeReasonDto>
    >(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.LIST_NETWORK_CHARGEBACK_DISPUTE_REASON}`,
      listNetworkChargebackDisputeReasonRequest
    );
    return response.data;
  }

  static async addChargebackRejectionReason(
    addChargebackRejectionReasonRequest: AddChargebackRejectionReasonRequest
  ) {
    const response = await axios.post<ChargebackRejectionReasonDto>(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.ADD_CHARGEBACK_REJECTION_REASON}`,
      addChargebackRejectionReasonRequest
    );
    return response.data;
  }

  static async getChargebackRejectionReason(
    chargebackRejectionReasonId: string
  ) {
    const response = await axios.get<ChargebackRejectionReasonDto>(
      `${
        this.baseUrl
      }${CHARGE_BACK_ENDPOINTS.GET_CHARGEBACK_REJECTION_REASON.replace(
        '{chargebackRejectionReasonId}',
        chargebackRejectionReasonId
      )}`
    );
    return response.data;
  }

  static async listChargebackRejectionReason(
    listChargebackRejectionReasonRequest: ListChargebackRejectionReasonRequest
  ) {
    const response = await axios.post<
      PaginatedResponse<ChargebackRejectionReasonDto>
    >(
      `${this.baseUrl}${CHARGE_BACK_ENDPOINTS.LIST_CHARGEBACK_REJECTION_REASON}`,
      listChargebackRejectionReasonRequest
    );
    return response.data;
  }
}
