import * as React from 'react';
import { SVGProps } from 'react';

const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <rect width="16" height="16" rx="8" fill="#78B57E" />
    <path
      d="M11.2 5.59961L6.42698 10.3996L4.79999 8.76342"
      stroke={props.stroke || 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CheckIcon;
