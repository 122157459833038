import { useMemo, useState } from 'react';
import { useProgramData } from '../providers/ProgramDataProvider';
import dayjs from 'dayjs';
import { getFilterOptions } from '../components/BackOffice/backOfficeUtils';
import { DATE_FORMAT_MONTH_US, DATE_FORMAT_US } from '../constants/url';
import { getDateRange } from '../utils/common.utils';
import { DATE_FILTERS } from '../enums/commons';
import { useAnalyticsDataQuery } from '../store/queries/analyticsQueries';
import { ANALYTICS_QUERY_NAME } from '../enums/analytics.enum';
import { getDatesBetween, getMonthsBetween } from '../utils/stringUtils';
import { ITableDataProps } from '../components/common/v4/VegaTableV4';

interface IAnalyticsHookProps<T> {
  graphProps?: string[];
  graphLabel?: string;
  graphQueryNames?: {
    monthly: ANALYTICS_QUERY_NAME;
    daily: ANALYTICS_QUERY_NAME;
  };
  metaDataQueryName?: ANALYTICS_QUERY_NAME;
  tableQueryName?: ANALYTICS_QUERY_NAME;
  filterOptionsType?: 'shorter' | 'longer';
  tableDataFunction?: (param: T[] | undefined) => ITableDataProps[];
}

export const useAnalyticsHook = <T, H, L>({
  graphProps = [],
  graphLabel,
  graphQueryNames,
  metaDataQueryName,
  tableQueryName,
  filterOptionsType,
  tableDataFunction,
}: IAnalyticsHookProps<L>) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [activeIdx, setActiveIdx] = useState(4);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: '',
    endDate: '',
  });
  const { selectedProgram } = useProgramData();
  const programId = selectedProgram?.programId;

  const onFilterCellClick = (idx: number) => {
    if (idx !== 0) {
      setActiveIdx(idx);
      setSelectedDateRange({ startDate: '', endDate: '' });
    }
  };

  const submitDateRange = (startDate: string, endDate: string) => {
    setSelectedDateRange({
      startDate: dayjs(startDate).format('MMM D, YYYY'),
      endDate: dayjs(endDate).format('MMM D, YYYY'),
    });
    setActiveIdx(0);
  };

  const onCloseCustomRangeClick = () => {
    setSelectedDateRange({ startDate: '', endDate: '' });
    setActiveIdx(4);
  };

  const filterOptions = filterOptionsType
    ? getFilterOptions(selectedDateRange, filterOptionsType)
    : [];

  const dateRange =
    activeIdx === 0
      ? {
          startDate: dayjs(selectedDateRange.startDate).format(DATE_FORMAT_US),
          endDate: dayjs(selectedDateRange.endDate).format(DATE_FORMAT_US),
        }
      : getDateRange(
          filterOptions[activeIdx]?.text as DATE_FILTERS,
          DATE_FORMAT_US
        );
  const isDataMonthly = activeIdx >= 3;

  const { data: graphData } = useAnalyticsDataQuery<T>({
    queryName: isDataMonthly
      ? graphQueryNames?.monthly
      : graphQueryNames?.daily,
    programId: programId as string,
    ...dateRange,
  });

  const graphDataRecords = graphData?.records || [];

  const { data: metaData } = useAnalyticsDataQuery<H>({
    queryName: metaDataQueryName,
    programId: programId as string,
    ...dateRange,
  });

  const { data: tableData, isFetching: isTableDataFetching } =
    useAnalyticsDataQuery<L>({
      queryName: tableQueryName,
      programId: programId as string,
      page,
      pageSize,
      ...{
        startDate: '2020-01-01',
        endDate: dayjs(Date.now()).format(DATE_FORMAT_US),
      },
    });

  const structuredGraphData = useMemo(() => {
    if (!graphLabel) return [];

    const temp: {
      [key: string]: string;
    } = {};
    graphDataRecords.forEach((el: any) => {
      temp[(isDataMonthly ? el[graphProps[0]] : el[graphProps[1]]) as string] =
        el[graphProps[2]] as string;
    });

    let dates = isDataMonthly
      ? getMonthsBetween(
          new Date(dateRange.startDate),
          new Date(dateRange.endDate)
        )
      : getDatesBetween(
          new Date(dateRange.startDate),
          new Date(dateRange.endDate)
        );
    // dates = dates.slice(1);

    const updatedData = dates.map((date) => {
      const dateFormated = dayjs(date).format(
        isDataMonthly ? DATE_FORMAT_MONTH_US : DATE_FORMAT_US
      );
      const dateFormated2 = dayjs(date).format(
        isDataMonthly ? "MMM, 'YY" : "MMM D, 'YY"
      );
      return {
        date: dateFormated2,
        [graphLabel]: Number(temp[dateFormated] || 0),
      };
    });
    return updatedData;
  }, [
    graphDataRecords,
    isDataMonthly,
    dateRange.endDate,
    dateRange.startDate,
    graphLabel,
    graphProps,
  ]);

  const structuredMetaData = (metaData?.records || [])[0];
  const structuredTableData = tableDataFunction?.(tableData?.records) || [];

  return {
    onFilterCellClick,
    submitDateRange,
    onCloseCustomRangeClick,
    structuredGraphData,
    structuredMetaData,
    structuredTableData,
    activeIdx,
    filterOptions,
    tableData,
    isTableDataFetching,
    page,
    pageSize,
    setPage,
    setPageSize,
  };
};
