import React, { useState } from 'react';
import {
  VegaCard,
  VegaTable,
  VegaTextField,
} from '../../../../components/common';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import { Box, Typography, Grid } from '@mui/material';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import { ISectionProps } from '../../pages/MCCUserManagement';
import {
  IBinAllocationOrderProps,
  IBinProps,
  IStockTranseferListProps,
  IStockTransferProps,
} from '../../../types/Bin';
import OrderInventory from './OrderInventory';
import { useClientAuth } from '../../../../providers/ClientProvider';
import VegaLabel from '../../../../components/common/VegaLabel';
import { toLowerCase } from '../../../../constants/commonFunction';
import VegaSelect from '../../../../components/common/VegaSelect';
import BranchSelector from '../../../components/BranchSelector';
import StockTransfer from './StockTransfer';

interface IProps {
  data: IStockTranseferListProps[];
  loading: boolean;
  updateStockTransfer: (data: IStockTransferProps) => void;
  updateToCheckerForStockTransfer: (data: IStockTranseferListProps) => void;
  sendToCheckerForStockTransfer: (data: IStockTranseferListProps) => void;
}

const DEFAUT_DATA = {
  binId: null,
  sourceBinEntityType: 'BRANCH',
  sourceEntityId: '',
  numberOfKits: '',
  destinationBinEntityType: '',
  destinationEntityId: '',
};

const ListOfStockTransfer = ({
  data,
  loading,
  sendToCheckerForStockTransfer,
  updateToCheckerForStockTransfer,
  updateStockTransfer,
}: IProps) => {
  const { mccPlatformId: branchId } = useClientAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [branchName, setBranchName] = useState<string>('');
  const [isShow, setIsShow] = useState<boolean>(false);
  const [showData, setShowData] = useState<IStockTransferProps>({
    ...DEFAUT_DATA,
  });

  const getAction = (data: IStockTranseferListProps) => {
    if (data.binAllocationTransferStatus === 'DRAFT') {
      return {
        action: (
          <VegaContainedButton
            text="Send to Checker"
            isPrimary
            onClick={() => sendToCheckerForStockTransfer(data)}
          />
        ),
        viewEdit: (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(data, false)}
            isPrimary
          />
        ),
      };
    } else if (
      data.binAllocationTransferStatus === 'UNDER_REVIEW' &&
      data.makerCheckerStatus === 'CHECKER_PENDING'
    ) {
      return {
        action: (
          <VegaContainedButton text="Sent to Checker" isPrimary disabled />
        ),
        viewEdit: (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(data, true)}
            isPrimary
          />
        ),
      };
    } else if (
      data.binAllocationTransferStatus === 'UNDER_REVIEW' &&
      data.makerCheckerStatus === 'CHANGES_REQUESTED'
    ) {
      return {
        action: (
          <VegaContainedButton
            text="Sent to Checker"
            isPrimary
            onClick={() => updateToCheckerForStockTransfer(data)}
          />
        ),
        viewEdit: (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(data, false)}
            isPrimary
          />
        ),
      };
    } else {
      return {
        action: (
          <VegaContainedButton
            text={toLowerCase(data.binAllocationTransferStatus)}
            isSuccess
            disabled
            onClick={() => updateToCheckerForStockTransfer(data)}
          />
        ),
        viewEdit: (
          <VegaContainedButton
            text="View/Edit"
            onClick={() => showDetails(data, true)}
            isPrimary
          />
        ),
      };
    }
  };

  const STOCK_TRANSFER = [
    {
      field: 'binId',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Bin No
        </Typography>
      ),
      flex: 1,
    },

    {
      field: 'numberOfKits',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          No Of Kit
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return <VegaLabel text={props.row.numberOfKits} color="primary" />;
      },
    },
    {
      field: 'sourceBinEntityType',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Source Branch
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return <VegaLabel text={props.row.sourceEntityId} color="primary" />;
      },
    },
    {
      field: 'destinationBinEntityType',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Destination Branch
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return (
          <VegaLabel text={props.row.destinationEntityId} color="primary" />
        );
      },
    },
    {
      field: 'action',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Action
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return getAction(props.row)?.action;
      },
    },
    {
      field: 'view',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          View/Edit
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        return getAction(props.row)?.viewEdit;
      },
    },
  ];

  const showDetails = (data: IStockTransferProps, show: boolean) => {
    const newObj = {
      binAllocationTransferId: data.id,
      binId: data.binId,
      sourceBinEntityType: 'BRANCH',
      sourceEntityId: branchId,
      numberOfKits: data.numberOfKits,
      // destinationBinEntityType: branchName,
      destinationBinEntityType: 'BRANCH',
      destinationEntityId: data.destinationEntityId,
    };
    setIsShow(show);
    setOpen(!open);
    setShowData(newObj);
  };

  const handleChange = (name: string, value: any) => {
    setShowData({ ...showData, [name]: value });
  };

  const onClose = () => {
    setOpen(!open);
  };
  console.log('showData', showData);
  const onSubmit = async () => {
    const obg = {
      ...showData,
      sourceEntityId: branchId,
      sourceBinEntityType: 'BRANCH',
      // destinationBinEntityType: branchName,
      destinationBinEntityType: 'BRANCH',
    };
    await updateStockTransfer(obg);

    onClose();
  };
  return (
    <VegaCard fullHeight noPad>
      <VegaTable data={data} columnDef={STOCK_TRANSFER} idColumn={'id'} />
      <VegaDrawer
        open={open}
        isClose
        isSubmit={!isShow}
        header={'Stock Transfer'}
        closeText={isShow ? 'Got It!!' : 'Cancel'}
        submitText={'Submit'}
        onClose={onClose}
        onSubmit={onSubmit}
      >
        <StockTransfer
          handleChange={handleChange}
          isShow={isShow}
          showData={showData}
          setBranchName={setBranchName}
        />
      </VegaDrawer>
    </VegaCard>
  );
};

export default ListOfStockTransfer;
