import React, { CSSProperties } from 'react';
import TextField, {
  type TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import {
  INDICATIVE,
  NEUTRALS,
  QUICK_ACCESS,
  VEGA_COMMON,
} from '../../../constants/style';
import { LabelTypo, ListHead2Typo, TextProps } from '../Typography';
import Stack, { StackProps } from '@mui/material/Stack';
import Box from '@mui/material/Box';

export type VegaTextfieldV4Props = {
  header?: string;
  headerTypo?: (props: TextProps) => JSX.Element;
  labelText?: string;
  wrapperProps?: StackProps;
  isRequired?: boolean;
  standardWidth?: CSSProperties['width'];
} & MuiTextFieldProps;

const VegaTextfieldV4 = ({
  sx,
  type,
  header,
  labelText,
  wrapperProps,
  isRequired,
  headerTypo,
  disabled,
  standardWidth,
  ...rest
}: VegaTextfieldV4Props) => {
  const HEADER_TYPO = headerTypo || ListHead2Typo;

  return (
    <Stack
      gap={1}
      sx={{ width: standardWidth ?? 328, maxWidth: standardWidth ?? 328 }}
      {...wrapperProps}
    >
      {header ? (
        <Box
          sx={{
            display: 'inline-flex',
            gap: 0.5,
            opacity: disabled ? 0.3 : 1,
          }}
        >
          <HEADER_TYPO
            sx={{
              color: QUICK_ACCESS.text[100],
              display: 'inline-block',
            }}
          >
            {header}
          </HEADER_TYPO>{' '}
          {rest.required || isRequired ? (
            <HEADER_TYPO
              sx={{
                color: INDICATIVE.red[500],
                display: 'inline-block',
              }}
            >
              *
            </HEADER_TYPO>
          ) : null}
        </Box>
      ) : null}
      <TextField
        onWheel={(e: any) => e.target.blur()}
        autoComplete={'off'}
        type={type}
        disabled={disabled}
        sx={{
          '& .MuiInputBase-input': {
            fontFamily: `Aspekta !important`,
            fontWeight: 450,
            fontSize: '12px',
            lineHeight: '120%',
            color: VEGA_COMMON.text200,
            borderRadius: '4px',
            ':disabled': {
              background: NEUTRALS.grey[50],
              outline: `1px solid ${NEUTRALS.grey[100]}`,
            },
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            borderColor: VEGA_COMMON.border[50],

            '&.Mui-focused fieldset': {
              borderColor: VEGA_COMMON.border[50],
              borderWidth: '1px',
              borderRadius: '4px',
            },
            '&.Mui-disabled fieldset': {
              borderColor: VEGA_COMMON.border[50],
            },
            ':hover fieldset': {
              borderColor: VEGA_COMMON.border[50],
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: VEGA_COMMON.border[50],
          },
          width: standardWidth ?? 328,
          '.MuiInputAdornment-root': {
            display: disabled ? 'none' : undefined,
          },
          ...sx,
        }}
        {...rest}
        value={type === 'number' ? rest.value : rest?.value || ''}
      />
      {labelText && (
        <LabelTypo
          sx={{
            color: rest.error ? INDICATIVE.red[500] : VEGA_COMMON.text100,
          }}
        >
          {labelText}
        </LabelTypo>
      )}
    </Stack>
  );
};

export default VegaTextfieldV4;
