/** @format */

import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import VegaTableV4, { ITableDataProps } from '../../common/v4/VegaTableV4';
import { PageHeadingTypo } from '../../common/Typography';
import { getApplicationStructuredData } from '../../BackOffice/Support_New/supportUtils';
import { useApplicationListDataQuery } from '../../../store/queries/applicationQueries';
import UserApplicationDetail from './UserApplicationDetail';
import { ApplicationsResponse } from '../../../types/applications';
import VegaNoResults from '../../common/VegaNoResults';
import { useSupport } from '../../../providers/SupportProvider';

function ApplicationManagement() {
  const [selected, setSelected] = useState(false);
  const [applicationData, setApplicationData] = useState<
    ApplicationsResponse | undefined
  >(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const { filter: searchParameter } = useSupport();

  const {
    data: applicationsData,
    isFetching,
    refetch,
  } = useApplicationListDataQuery({
    searchParameter,
    page,
    pageSize,
    enabled: false,
    debDelay: 0,
  });

  const onBtnClick = () => {
    setSelected(!selected);
  };
  const structuredData = getApplicationStructuredData(
    applicationsData?.records ?? [],
    onBtnClick,
    setApplicationData
  ) as ITableDataProps[];

  useEffect(() => {
    refetch();
  }, [refetch]);

  return !selected ? (
    <>
      {!Boolean(applicationsData?.records.length) && !isFetching ? (
        <VegaNoResults errorText="Applications list not found" />
      ) : (
        <Stack gap={2}>
          <PageHeadingTypo>Applications</PageHeadingTypo>

          <VegaTableV4
            data={structuredData}
            head={[
              'Customer Id',
              'Customer Name',
              'Mobile Number',
              'View Details',
              'Application Status',
              'Application stage',
              'Updated At',
              'Program Name',
            ]}
            pagination={{
              page,
              rowsPerPage: pageSize,
              setPage,
              setRowsPerPage: setPageSize,
              totalRecords: applicationsData?.totalItems || 0,
            }}
            isLoading={isFetching}
          />
        </Stack>
      )}
    </>
  ) : (
    <UserApplicationDetail
      applicationData={applicationData}
      onBtnClick={onBtnClick}
    />
  );
}

export default ApplicationManagement;
