import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import Vega_Text from '../../../common/v3/Vega_Text';
import { PersonOutlineOutlined } from '@mui/icons-material';
import { NEW_PRIMARY } from '../../../../constants/style';
import { UserIcon } from '../icons/SupportIcons';
import { useSupport } from '../../../../providers/SupportProvider';

const SupportCustomerDetails = () => {
  const { loading, filteredCustomer } = useSupport();
  return (
    <Box>
      <Stack direction={'row'} alignItems={'center'} gap={1} mb={1.5}>
        <UserIcon />
        <Vega_Text
          text={'Customer Details'}
          fontSize={13}
          fontWeight={500}
          color={NEW_PRIMARY.BLACK}
        />
      </Stack>
      <Stack gap={1}>
        <Text
          name="customer ID"
          value={filteredCustomer?.customerId}
          loading={loading}
        />
        <Text
          name="customer Name"
          value={filteredCustomer?.firstName}
          loading={loading}
        />
        <Text
          name="mobile number"
          value={filteredCustomer?.mobileNumber}
          loading={loading}
        />
        <Text
          name="email address"
          value={filteredCustomer?.emailId}
          loading={loading}
        />
      </Stack>
    </Box>
  );
};

export default SupportCustomerDetails;

interface IProps {
  name: string;
  value: string | undefined;
  loading: boolean;
}

const Text = ({ name, value, loading }: IProps) => {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={1}>
      <Vega_Text
        text={name}
        sx={{ width: '50%' }}
        fontWeight={700}
        fontSize={10}
        textTransform={'uppercase'}
        color={'#7B8694'}
        letterSpacing={'0.5px'}
      />
      {loading ? (
        <Skeleton width={'50%'} />
      ) : (
        <Vega_Text
          text={value ?? '--'}
          sx={{
            width: '50%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          fontWeight={450}
          fontSize={13}
          color={'#181F28'}
        />
      )}
    </Stack>
  );
};
