/** @format */

import {
  AccountBalanceOutlined,
  CurrencyExchangeOutlined,
  LegendToggleSharp,
  ReceiptLongOutlined,
  SupervisorAccountOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import Accounts from '../pages/Accounts';
import Bills from '../pages/Bills';
import { GeneralLedger } from '../pages/GeneralLedger';
import Transactions from '../pages/Transactions';
import { ROUTES, SUB_ROUTES } from '../routes';
import CustomerWrap from '../components/Simulator/Customers/CustomerWrap';
import AccountListPage from '../components/Simulator/Accounts/AccountListPage';
import TransactionListPage from '../components/Simulator/Transactions/TransactionListPage';
import LedgerListPage from '../components/Simulator/Transactions/LedgerListPage';
import BillListPage from '../components/Simulator/Bills/BillListPage';
import { useAppSelector } from '../store/hook';
import SimulatorHome from '../components/Simulator/Home/SimulatorHome';
import SimulatorMainWrap from '../components/Simulator/SimulatorMainWrap';

type Props = {};

const NAV_OPTIONS: SidebarListItemType[] = [
  {
    id: ROUTES.CUSTOMERS,
    icon: <SupervisorAccountOutlined sx={{ fontSize: 18 }} />,
    label: 'Customers',
    component: <SimulatorMainWrap route="customers" />,
    isProgramSelector: true,
    isAccountSelector: false,
    isSimulatorRoutes: true,
  },
  // {
  //   id: ROUTES.CUSTOMERS + '1',
  //   icon: <SupervisorAccountOutlined sx={{ fontSize: 18 }} />,
  //   label: 'Customers 1',
  //   component: <Customers />,
  //   isProgramSelector: true,
  //   isAccountSelector: false,
  //   isSimulatorRoutes: true,
  // },
  {
    id: ROUTES.ACCOUNTS,
    icon: <AccountBalanceOutlined sx={{ fontSize: 18 }} />,
    label: 'Accounts',
    component: <SimulatorMainWrap route="accounts" />,
    isProgramSelector: true,
    isAccountSelector: false,
    isSimulatorRoutes: true,
  },
  // {
  //   id: ROUTES.ACCOUNTS + '1',
  //   icon: <AccountBalanceOutlined sx={{ fontSize: 18 }} />,
  //   label: 'Accounts + 1',
  //   component: <Accounts />,
  //   isProgramSelector: true,
  //   isAccountSelector: false,
  //   isSimulatorRoutes: true,
  // },
  {
    id: ROUTES.TRANSACTIONS,
    icon: <CurrencyExchangeOutlined sx={{ fontSize: 18 }} />,
    label: 'Transactions',
    isProgramSelector: true,
    isAccountSelector: false,
    isSimulatorRoutes: true,
    children: [
      {
        id: SUB_ROUTES.TRANSACTIONS.TRANSACTIONS,
        label: 'Transactions',
        component: <SimulatorMainWrap route="transactions" />,
        sx: {
          padding: 0,
        },
      },
      {
        id: SUB_ROUTES.TRANSACTIONS.LEDGER,
        label: 'Ledger',
        component: <SimulatorMainWrap route="ledger" />,
        sx: {
          padding: 0,
        },
      },
    ],
  },
  // {
  //   id: ROUTES.TRANSACTIONS + '1',
  //   icon: <CurrencyExchangeOutlined sx={{ fontSize: 18 }} />,
  //   label: 'Transactions 1',
  //   component: <Transactions />,
  //   isProgramSelector: true,
  //   isAccountSelector: true,
  //   isSimulatorRoutes: true,
  // },
  // {
  //   id: ROUTES.GENERAL_LEDGER + 1,
  //   icon: <LegendToggleSharp sx={{ fontSize: 18 }} />,
  //   label: 'Ledger 1',
  //   component: <GeneralLedger />,
  //   isProgramSelector: true,
  //   isAccountSelector: true,
  //   isSimulatorRoutes: true,
  // },
  {
    id: ROUTES.BILLS,
    icon: <ReceiptLongOutlined sx={{ fontSize: 18 }} />,
    label: 'Bills',
    component: <SimulatorMainWrap route="bills" />,
    isProgramSelector: true,
    isAccountSelector: false,
    isSimulatorRoutes: true,
  },
  // {
  //   id: ROUTES.BILLS + '1',
  //   icon: <ReceiptLongOutlined sx={{ fontSize: 18 }} />,
  //   label: 'Bills 1',
  //   component: <Bills />,
  //   isProgramSelector: true,
  //   isAccountSelector: true,
  //   isSimulatorRoutes: true,
  // },
];

const SimulatorContainer = (props: Props) => {
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    NAV_OPTIONS[0]
  );

  const { selectedProgram } = useAppSelector(({ simulator }) => ({
    selectedProgram: simulator.selectedProgram,
  }));

  return !selectedProgram ? (
    <SimulatorHome />
  ) : (
    <Box sx={{ height: '100%' }}>
      <SidebarLayout
        sidebarListItems={NAV_OPTIONS}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default SimulatorContainer;
