import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import {
  ListTransactionsFilterRequestDto,
  MccInfoResponse,
  TransactionCategory,
  TransactionChargeResponse,
  TransactionDto,
  TransactionJourneyResponse,
  WaiverDto,
  WaiverType,
  MidCodeDto,
  IMccCodesProps,
  MerchantDto,
} from '../types/transaction';
import {
  BASE_URL,
  DISPUTE,
  ONBOARDING,
  SUPPORT,
  TRANSACTION,
} from './endpoints';
import { PaginatedResponse } from '../utils/api';
import {
  TransactionCode,
  TransactionIndicator,
  TransactionStatus,
} from '../enums/transaction';
import { Currency } from '../types/cardRefund';
import { ChargebackDto, ChargebackStatus } from '../types/chargeBack';
import { CommonRequestQuery } from '../types/commonType';
import { KeyValuePairs } from '../store/queries/customerQueries';

const TRANSACTION_BASE_URL = BASE_URL + TRANSACTION.BASE;
const SUPPORT_BASE = SUPPORT.BASE;
const DISPUTE_BASE = DISPUTE.BASE;
const ONBOARDING_BASE = ONBOARDING.BASE;

const FRESHDESK_URL = SUPPORT_BASE + SUPPORT.FRESHDESK;

const TRANSACTION_DISPUTE_URL = TRANSACTION_BASE_URL + TRANSACTION.DISPUTE;
const TRANSACTION_CHARGEBACK_URL = DISPUTE_BASE + DISPUTE.CHARGE_BACK.BASE;
const TRANSACTION_SURPLUS = TRANSACTION_BASE_URL + TRANSACTION.SURPLUS;
const TRANSACTION_WAIVER = TRANSACTION_BASE_URL + TRANSACTION.WAIVER;
const TRANSACTION_JOUNEY = TRANSACTION_BASE_URL + TRANSACTION.JOURNEY;

const MID_CODE = ONBOARDING_BASE + ONBOARDING.DATA + ONBOARDING.MID;
const MERCHANT_NAME = ONBOARDING_BASE + ONBOARDING.DATA + ONBOARDING.MERCHANT;
const MCC_CODE =
  ONBOARDING_BASE + ONBOARDING.DATA + ONBOARDING.MCC + ONBOARDING.MID;

interface CreateTransactionRequestBody {
  accountId: string;
  code: TransactionCode;
  amount: number;
  indicator: TransactionIndicator;
  time?: string;
  mcc?: number;
  merchantLocation?: string;
  merchantName?: string;
  reason?: string;
  mid?: string;
}

export const createTransactionForAccount = (
  dataVals: CreateTransactionRequestBody
) => {
  const {
    accountId,
    code,
    amount,
    indicator,
    time,
    mcc,
    merchantLocation,
    merchantName,
    reason,
    mid,
  } = dataVals;

  const data = {
    accountId,
    parentTransactionId: null,
    amount,
    currency: Currency.INR,
    transactionIndicator: indicator,
    transactionCategory: TransactionCategory.CASH,
    transactionCode: code,
    mid,
    mcc: mcc,
    lng: 12.0,
    lat: 10.0,
    description:
      reason ||
      (code === TransactionCode.ECOM
        ? 'electronic commerce services'
        : 'ATM cash withdrawal'),
    merchantName,
    providerAttributesJson: '{}',
    merchantLocation: merchantLocation ?? '',
    transactionTime: time ? dayjs(time).toISOString() : undefined,
  } as Partial<TransactionDto & { providerAttributesJson: string }>;

  return axios.post(TRANSACTION_BASE_URL, data);
};

export const createRepaymentForAccount = (
  accountId: string,
  amount: number,
  time?: string
) => {
  const data = {
    accountId,
    currency: 'INR',
    amount,
    paymentMode: 'Repayment - OfflineCollection',
    transactionCode: 'PAYMENT',
    description: 'System_repayment',
    transactionTime: time ? dayjs(time).toISOString() : undefined,
  };
  return axios.post(`${TRANSACTION_BASE_URL}/payment`, data);
};

export const updateTransactionStatus = (
  transactionId: string,
  status: string
) => {
  const data = {
    transactionId,
    status,
  };
  return axios.patch(`${TRANSACTION_BASE_URL}/payment/status`, data);
};

export const getTransactionById = (
  transactionId: string
): Promise<AxiosResponse<TransactionDto, any>> => {
  return axios.get(TRANSACTION_BASE_URL + `/${transactionId}`);
};
export const submitDisputeReason = (transactionId: string, reason?: string) => {
  const data = {
    transactionId,
    reason,
  };
  return axios.post(TRANSACTION_DISPUTE_URL, data);
};

export const listTransactionsForAccount = ({
  accountId,
  statusList,
  transactionCodeList,
  transactionIndicatorList,
  proxyId,
  startDate,
  endDate,
  page,
  pageSize,
  requestObject,
}: {
  proxyId?: string;
  accountId: string;
  statusList?: TransactionStatus[];
  transactionCodeList?: TransactionCode[];
  transactionIndicatorList?: TransactionIndicator[];
  requestObject?: KeyValuePairs | {};
} & CommonRequestQuery): Promise<
  AxiosResponse<PaginatedResponse<TransactionDto>, any>
> => {
  return axios.get(`${TRANSACTION_BASE_URL}/list`, {
    params: {
      accountId,
      statusList,
      transactionCodeList,
      transactionIndicatorList,
      proxyId,
      startDate,
      endDate,
      page,
      pageSize,
      ...requestObject,
    },
  });
};

export const listTransactionCharges = (
  transactionId: string
): Promise<AxiosResponse<TransactionChargeResponse[]>> => {
  return axios.get(`${TRANSACTION_BASE_URL}/${transactionId}/charges`);
};

export const listTransactionChargesByAccountId = (
  accountId: string
): Promise<AxiosResponse<TransactionChargeResponse[]>> => {
  return axios.get(`${TRANSACTION_BASE_URL}/account/${accountId}/charges`);
};

export const listUnwaivedTransactionChargesByAccountId = (
  accountId: string
): Promise<AxiosResponse<TransactionChargeResponse[]>> => {
  return axios.get(
    `${TRANSACTION_BASE_URL}/account/${accountId}/charges/unwaived`
  );
};

export const listTransactionRefund = (
  transactionId: string
): Promise<AxiosResponse<TransactionDto[]>> => {
  return axios.get(`${TRANSACTION_BASE_URL}/${transactionId}/refunds`);
};

export const listTransactionChargeback = (
  data: {
    transactionId?: string;
    accountId?: string;
    statusList?: ChargebackStatus[];
  } & CommonRequestQuery
): Promise<AxiosResponse<PaginatedResponse<ChargebackDto>, any>> => {
  const { page, ...rest } = data;

  return axios.post(TRANSACTION_CHARGEBACK_URL + '/list', {
    ...rest,
    pageNumber: page,
  });
};

export const getSurplusList = (
  data: {
    accountId: string;
    page?: number;
    pageSize?: number;
  } & CommonRequestQuery
): Promise<AxiosResponse<PaginatedResponse<TransactionDto>, any>> => {
  return axios.get(TRANSACTION_SURPLUS, {
    params: {
      ...data,
      page: data.page ?? 0,
      pageSize: data.pageSize ?? 100,
    },
  });
};

export const getWaiverList = (
  data: {
    accountId: string;
    page?: number;
    pageSize?: number;
  } & CommonRequestQuery
): Promise<AxiosResponse<PaginatedResponse<WaiverDto>, any>> => {
  return axios.get(TRANSACTION_WAIVER + '/list', {
    params: {
      ...data,
      pageNo: data.page ?? 0,
      pageSize: data.pageSize ?? 100,
    },
  });
};

export const waiveAmount = (data: {
  accountId: string;
  chargeTxnId: string;
  currency?: Currency;
  waiverAmount: number;
  waiverType: WaiverType;
  remarks: string;
}): Promise<any> => {
  return axios.post(`${TRANSACTION_BASE_URL}/waiver`, data);
};

export const fetchTransactionJourney = (
  transactionId: string
): Promise<AxiosResponse<TransactionJourneyResponse[]>> => {
  return axios.get(`${TRANSACTION_JOUNEY}/${transactionId}`);
};

export const getMccCodeInfo = (
  mcc: number
): Promise<AxiosResponse<MccInfoResponse>> => {
  return axios.get(`/data/mccCodes/${mcc}/risk`);
};

export const listTransactionsForProgram = (
  programId: string
): Promise<AxiosResponse<PaginatedResponse<TransactionDto>, any>> => {
  return axios.get(`${TRANSACTION_BASE_URL}/list`, { params: { programId } });
};

export const listOfTicketsForAccount = (accountId: string) => {
  return axios.get(`${FRESHDESK_URL}/account/${accountId}`);
};

export const listOfTicketCountForAccount = (accountId: string) => {
  return axios.get(`${FRESHDESK_URL}/account/ticketStatusCount/${accountId}`);
};

export const listTransactions = (
  data: ListTransactionsFilterRequestDto
): Promise<AxiosResponse<PaginatedResponse<TransactionDto>, any>> => {
  return axios.get(`${TRANSACTION_BASE_URL}/list`, { params: data });
};

export const getMidCodes = (
  page: string
): Promise<AxiosResponse<PaginatedResponse<MidCodeDto>>> => {
  return axios.get(MID_CODE, { params: { page, pageSize: 10 } });
};

export const getMccCodes = (
  page: string | number,
  pageSize: string | number,
  mid: string | number | undefined
): Promise<AxiosResponse<PaginatedResponse<IMccCodesProps>>> => {
  const urlWithPath = `${MCC_CODE}/${mid}`;
  return axios.get(urlWithPath, { params: { page, pageSize } });
};

export const getMerchantNames = (
  data: { name?: string } & CommonRequestQuery
): Promise<AxiosResponse<PaginatedResponse<MerchantDto>>> => {
  return axios.get(MERCHANT_NAME, {
    params: { page: data.page, pageSize: data.pageSize, name: data.name },
  });
};
