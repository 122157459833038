import React, { useMemo, useState } from 'react';
import { listBinAllocations } from '../../../api/bin';
import { IBinProgramListProps } from '../../../types/binType';
import { useQuery } from 'react-query';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import VegaTableV4, { ITableDataProps } from '../../common/v4/VegaTableV4';
import Stack from '@mui/material/Stack';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import Typography from '@mui/material/Typography';
import { QUICK_ACCESS } from '../../../constants/style';
import VegaDataTileV4 from '../../common/v4/VegaDataTileV4';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import VegaDrawerV4 from '../../common/v4/VegaDrawerV4';
import IncreaseCardRange from './IncreaseCardRange';
import OrderPrintedCard from './OrderPrintedCard';

type BinListProps = {
  id: number;
  programName: string;
  totalCardRange: number;
  totalCardIssued: number;
  availableCardRange: number;
  status: string;
};
const getBinProgramData = async (binId: number) => {
  const response = await listBinAllocations(binId);
  const data = response.data.records;
  if (data.length) {
    const newData = data.map((bin: IBinProgramListProps, index: number) => ({
      id: index + 1,
      programName: bin.programDto ? bin.programDto.name : '--',
      totalCardRange: bin.maxNoOfKits,
      totalCardIssued: bin.noOfKitsSold,
      availableCardRange: bin.noOfKitsAvailable,
      status: bin.programDto ? bin.programDto.status : '--',
    })) as BinListProps[];

    return newData;
  }

  return [];
};

const Separator = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mx: 3,
      }}
    >
      <Box
        sx={{ width: '2px', height: 84, bgcolor: QUICK_ACCESS.border[50] }}
      />
    </Box>
  );
};

const getRows = (
  binData: BinListProps[],
  openIncreaseCardRange: (id: string | number) => void,
  openPrintCardModal: (id: string | number) => void
): ITableDataProps[] =>
  binData.map((bin) => ({
    rowData: [
      {
        type: 'string',
        value: bin.programName,
        cellSx: {
          minWidth: 200,
        },
      },
      {
        type: 'status',
        value: bin.status,
        statusColor: bin.status === 'ACTIVE' ? 'green' : 'yellow',
      },
      { type: 'number', value: bin.totalCardRange },
      { type: 'number', value: bin.totalCardIssued },
      { type: 'number', value: bin.availableCardRange },
      {
        type: 'jsx',
        children: (
          <>
            <VegaButtonV4
              value="Increase Card Range"
              onClick={() => openIncreaseCardRange(bin.id)}
            />
          </>
        ),
      },
    ],
    collapseData: {
      children: (
        <Stack bgcolor="#F3F7FF" direction="row">
          <Stack p={2} minWidth={'20%'}>
            <Typography
              display="block"
              className={'font-aspekta-500 '}
              sx={{
                fontFamily: 'Aspekta',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '120%' /* 12px */,
                color: QUICK_ACCESS.text[100],
                pb: 2,
              }}
            >
              Total Cards Issued
            </Typography>
            <Stack pt={2} direction="row">
              <VegaDataTileV4
                title="Virtual Card"
                value={`${2500}`}
                hasSeparator
              />
              <VegaDataTileV4 title="Physical Card" value={`${2500}`} />
            </Stack>
          </Stack>
          <Separator />
          <Stack p={2} minWidth={'50%'}>
            <Typography
              display="block"
              className={'font-aspekta-500 '}
              sx={{
                fontFamily: 'Aspekta',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '120%' /* 12px */,
                color: QUICK_ACCESS.text[100],
                pb: 2,
              }}
            >
              Physical Cards Inventory
            </Typography>
            <Stack pt={2} direction="row">
              <VegaDataTileV4
                title="Printed Card TD"
                value={`${2500}`}
                hasSeparator
              />
              <VegaDataTileV4
                title="Card issued"
                value={`${2500}`}
                hasSeparator
              />
              <VegaDataTileV4 title="Card in Stock" value={`${2500}`} />
            </Stack>
          </Stack>
          <Stack direction="row" p={2}>
            <VegaButtonV4
              value="Order Printed Cards"
              onClick={() => openPrintCardModal(bin.id)}
            />
          </Stack>
        </Stack>
      ),
    },
  }));

function BinDetailsTable() {
  const { selecteddBinId: selectedBinId } = useBinCharter();
  const [isIncreaseCardsModalOpen, setIsIncreaseCardsModalOpen] =
    useState(false);
  const [isOrderPrintedCardsModalOpen, setIsOrderPrintedCardsModalOpen] =
    useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { data: binProgramData } = useQuery({
    queryKey: ['binProgramData', selectedBinId],
    queryFn: () => (selectedBinId ? getBinProgramData(selectedBinId) : []),
    enabled: !!selectedBinId,
  });

  const toggleCardRangeModal = () => {
    setIsIncreaseCardsModalOpen((prevValue) => !prevValue);
  };

  const openIncreaseCardRange = (id: string | number) => {
    toggleCardRangeModal();
  };

  const togglePrintCardsModal = () => {
    setIsOrderPrintedCardsModalOpen((prevValue) => !prevValue);
  };

  const openPrintCardModal = (id: string | number) => {
    togglePrintCardsModal();
  };

  const rows = useMemo(
    () =>
      binProgramData
        ? getRows(binProgramData, openIncreaseCardRange, openPrintCardModal)
        : [],
    [binProgramData]
  );
  return (
    <>
      <VegaTableV4
        data={rows}
        head={[
          'Program Name',
          'Status',
          'Total Card Range',
          'Total Card Issue',
          'Available Card Range',
          'Action',
        ]}
        pagination={{
          rowsPerPage,
          setRowsPerPage,
          page,
          setPage,
          totalRecords: rows.length,
        }}
      />
      <VegaDrawerV4
        open={isIncreaseCardsModalOpen}
        submitBtn={{ show: true, props: { value: 'Increase Card Range' } }}
        header="Increase Card Range"
        description="Vegapay Demo Program"
        onClose={toggleCardRangeModal}
      >
        <IncreaseCardRange />
      </VegaDrawerV4>
      <VegaDrawerV4
        open={isOrderPrintedCardsModalOpen}
        submitBtn={{ show: true, props: { value: 'Order Printed Cards' } }}
        header="Order Printed Cards"
        onClose={togglePrintCardsModal}
      >
        <OrderPrintedCard />
      </VegaDrawerV4>
    </>
  );
}

export default BinDetailsTable;
