/** @format */

import { AccessForProgram } from '../pages/RolesAndAccess';
import { IAccessProps, IProgramRoles } from '../types/userAccess';

export const getAccessFeaturesForProgramObjFromResponse = (
  accessFeatureArray: IAccessProps[]
) => {
  const featuresForProgram: any = {};
  accessFeatureArray.forEach((accessFeature) => {
    const programRoles = accessFeature.programRoles;
    const feature = accessFeature.feature;
    programRoles?.forEach((programRole) => {
      const programId = programRole.programId as string;
      const exists = !!featuresForProgram[programId];
      if (!exists) {
        featuresForProgram[programId] = [feature];
      } else {
        featuresForProgram[programId] = [
          ...featuresForProgram[programId],
          feature,
        ];
      }
      if (programId == 'ALL') {
        return;
      }
    });
  });
  return featuresForProgram;
};

const defaultProgramRoleObj = {
  programId: 'ALL',
  roles: ['EDIT'],
} as IProgramRoles;

export const getRequestAccessFeatureObjFromProgramFeatues = (
  featuresForProgram: AccessForProgram
) => {
  const accessFeaturesToProgram: Record<string, string[]> = {};
  const allProgramsAccessibleFeatues: string[] = [];
  Object.keys(featuresForProgram).forEach((programId) => {
    if (programId == 'ALL') {
      featuresForProgram[programId].forEach((feature) => {
        allProgramsAccessibleFeatues.push(feature);
        accessFeaturesToProgram[feature] = ['ALL'];
      });
    } else {
      featuresForProgram[programId].forEach((feature) => {
        if (!allProgramsAccessibleFeatues.includes(feature)) {
          accessFeaturesToProgram[feature] = !!accessFeaturesToProgram[feature]
            ? [...accessFeaturesToProgram[feature], programId]
            : [programId];
        }
      });
    }
  });
  const accessFeatures: IAccessProps[] = [];
  Object.keys(accessFeaturesToProgram).forEach((feature) => {
    let programRoles = [{ ...defaultProgramRoleObj }];
    if (
      !(
        accessFeaturesToProgram[feature].length == 1 &&
        accessFeaturesToProgram[feature][0] == 'ALL'
      )
    ) {
      accessFeaturesToProgram[feature].forEach((programId, idx) => {
        if (idx === 0) {
          programRoles = [{ ...defaultProgramRoleObj, programId }];
        } else {
          programRoles = [
            ...programRoles,
            { ...defaultProgramRoleObj, programId },
          ];
        }
      });
    }
    const accessFeatureObj = { programRoles, feature };
    accessFeatures.push(accessFeatureObj);
  });
  return accessFeatures;
};
