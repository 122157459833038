import axios from 'axios';
import { VoucherInventory } from '../types/RewardsLoyalty';
import { BASE_URL, REWARDS } from './endpoints';

const REWARDS_BASE = BASE_URL + REWARDS.BASE;
const INVENTORY_URL = REWARDS_BASE + REWARDS.INVENTORY;

export class VoucherInventoryService {
  static async getForProgram(programId: string): Promise<VoucherInventory[]> {
    try {
      const endpoint =
        INVENTORY_URL +
        '/program/{programId}'.replace('{programId}', programId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
