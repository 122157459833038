import { Box, Divider, Stack } from '@mui/material';
import React from 'react';
import { QUICK_ACCESS } from '../../../constants/style';
import { ComponentBody1Typo } from '../Typography';
import { IKeyValueProps } from '../../../types/commonType';

interface IVegaKeyValCardV4Props {
  displayData: IKeyValueProps[][];
}

const VegaKeyValCardV4 = ({ displayData }: IVegaKeyValCardV4Props) => {
  return (
    <Box
      p={2}
      borderRadius={'8px'}
      sx={{ border: `1px solid ${QUICK_ACCESS.border[50]}` }}
    >
      <Stack direction="row" gap={2} alignItems="center">
        {displayData.map((item, idx) => (
          <React.Fragment key={idx}>
            <Stack gap={2} minWidth={240}>
              {item.map((keyval) => (
                <Stack gap={1.5} direction="row" key={keyval.key}>
                  <ComponentBody1Typo
                    sx={{ color: QUICK_ACCESS.text[100] }}
                    width={140}
                  >
                    {keyval.key}
                  </ComponentBody1Typo>
                  <ComponentBody1Typo>{keyval.value}</ComponentBody1Typo>
                </Stack>
              ))}
            </Stack>
            {displayData.length - 1 !== idx && (
              <Divider
                variant="middle"
                orientation="vertical"
                sx={{ height: 48, borderStyle: 'dashed', marginRight: 2 }}
              />
            )}
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
};

export default VegaKeyValCardV4;
