import { Box, CircularProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { getTransactionById } from '../../../../../api/transaction';
import { BLUE } from '../../../../../constants/style';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../../../services/ChargeBackService';
import { ChargebackDto } from '../../../../../types/chargeBack';
import { TransactionDto } from '../../../../../types/transaction';
import { VPDrawer } from '../../../../../ui-components/common';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import { StatusChangeTimeLine } from '../../../../ChargeBack';
import { useChargeBack } from '../../../../ChargeBack/hooks';
import VegaTabBar from '../../../../common/v2/VegaTabBar';
import VegaTabBarItem from '../../../../common/v2/VegaTabBarItem';
import { ChargeBackDetails } from './ChargeBackDetails';

interface IProps {
  triggerLabel: string;
  transactionDto?: TransactionDto;
  chargeBackDto?: ChargebackDto;
  open: boolean;
  handleClose: () => void;
}

enum TAB_DATA {
  CHARGE_BACK_DETAILS = 'CHARGE_BACK_DETAILS',
  TIMELINE = 'TIMELINE',
}
export function ViewDisputeDetailsModal({
  transactionDto,
  triggerLabel,
  chargeBackDto,
  open,
  handleClose,
}: IProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const { setSelectedChargeBack } = useChargeBack();
  const { setSnackbar } = useSnackbar();

  const [currentTransactionDto, setCurrentTransactionDto] = useState<
    TransactionDto | undefined
  >(transactionDto);

  useEffect(() => {
    // fetch chargebackdto from transactionDto
    if (transactionDto?.latestChargebackId) {
      ChargebackService.getChargeback(transactionDto.latestChargebackId)
        .then((res) => {
          setSelectedChargeBack(res);
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(
              err,
              'Error in fetching chargeback for transaction id - ' +
                transactionDto.id
            ),
            'error'
          );
        });
    }

    // fetch transactionDto from chargebackdto
    if (chargeBackDto?.transactionId) {
      setSelectedChargeBack(chargeBackDto);
      getTransactionById(chargeBackDto?.transactionId)
        .then((res) => {
          setCurrentTransactionDto(res.data);
        })
        .catch((err) => {
          setSnackbar(
            getErrorMessageFromErrorObj(
              err,
              'Error in fetching transaction for transaction id - ' +
                chargeBackDto?.transactionId
            ),
            'error'
          );
        });
    }
  }, []);

  return (
    <VPDrawer open={open} onClose={handleClose} header={triggerLabel}>
      <Stack spacing={2}>
        <Box
          style={
            {
              // height: '100%',
            }
          }
        >
          <VegaTabBar
            value={selectedTab}
            color={BLUE.lighter}
            onChange={(e, selected) => {
              setSelectedTab(selected);
            }}
          >
            {Object.keys(TAB_DATA).map((key: string) => {
              return (
                <VegaTabBarItem
                  color={BLUE.lighter}
                  label={key}
                ></VegaTabBarItem>
              );
            })}
          </VegaTabBar>

          <Box py={1} overflow={'scroll'}>
            {selectedTab === 0 ? (
              currentTransactionDto ? (
                <ChargeBackDetails transactionDto={currentTransactionDto} />
              ) : (
                <CircularProgress />
              )
            ) : (
              <StatusChangeTimeLine />
            )}
          </Box>
        </Box>
      </Stack>
    </VPDrawer>
  );
}
