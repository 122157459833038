import React from 'react';
import { Box, LinearProgress, type SxProps, type Theme } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridCallbackDetails,
  GridColumns,
  GridFeatureMode,
} from '@mui/x-data-grid';
import { QUICK_ACCESS, VEGA_COMMON } from '../../../constants/style';

export type VegaDataGridV4Props = {
  data: readonly any[];
  columns: GridColumns<any>;
  page?: number;
  pageSize?: number;
  rowCount?: number;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
  rowsPerPageOptions?: number[];
  loading?: boolean;
  idColumn: string;
  bgColor?: string;
  paginationMode?: GridFeatureMode;
  wrapperSx?: SxProps<Theme>;
} & Partial<DataGridProps>;

function VegaDataGridV4({
  data,
  columns,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  rowsPerPageOptions,
  loading,
  rowCount,
  paginationMode,
  idColumn,
  bgColor,
  sx,
  wrapperSx,
  components,
  ...rest
}: VegaDataGridV4Props) {
  const [_pageSize, setPageSize] = React.useState<number>(5);
  const _onPageSizeChange = (newPageSize: number) => setPageSize(newPageSize);
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          '& .vegatable--white': {
            bgcolor: QUICK_ACCESS.background[50],
            '&:hover': {
              bgcolor: QUICK_ACCESS.background[50],
            },
          },
          ...wrapperSx,
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            overflowX: 'scroll',
            border: 'none',
            '& ::-webkit-scrollbar': {
              width: '2px !important',
              height: '2px !important',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: 'transparent !important',
            },
            '& .MuiDataGrid-main': {
              border: `1px solid ${QUICK_ACCESS.border[50]}`,
              borderRadius: '0.75rem',
            },
            '& .MuiDataGrid-row:hover': {
              bgcolor: 'white',
            },
            '& .MuiDataGrid-cellContent': {
              color: QUICK_ACCESS.text[200],
              fontSize: '13px',
              fontFamily: `Aspekta !important`,
              fontWeight: 450,
              textOverflow: 'ellipsis',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderTop: 'none !important',
              borderBottom: `1px solid ${QUICK_ACCESS.border[50]}`,
              backgroundColor: QUICK_ACCESS.background[100],
              maxHeight: '2.75rem !important',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontFamily: `Aspekta !important`,
              fontWeight: 700,
              fontSize: '13px',
              textTransform: 'capitalize',

              lineHeight: '124%',
              color: QUICK_ACCESS.text[100],
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-iconSeparator': {
              display: 'none !important',
            },
            '& .MuiDataGrid-sortIcon': {
              display: 'none !important',
            },
            '& .MuiTablePagination-toolbar': {
              fontFamily: `Aspekta !important`,
              fontWeight: 600,
              fontSize: '12px',
              color: QUICK_ACCESS.text[100],
              '& .MuiTablePagination-select': {
                marginTop: '2px',
                paddingBottom: '0px',
              },
            },

            '& .MuiTablePagination-selectLabel': {
              fontFamily: `Aspekta !important`,
              fontWeight: 700,
              fontSize: '10px',
            },
            '& .MuiTablePagination-displayedRows': {
              fontFamily: `Aspekta !important`,
              fontWeight: 700,
              fontSize: '10px',
            },
            '& .MuiDataGrid-footerContainer': {
              border: 'none !important',
              backgroundColor: bgColor ?? 'transparent',
              margin: '0px !important',
              padding: '0px !important',
            },
            '.MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
              borderRight: `1px solid ${QUICK_ACCESS.border[50]}`,
              px: 2,
            },
            ...sx,
          }}
          //   components={{
          //     Pagination: CustomPagination,
          //   }}
          showCellRightBorder={false}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          rowHeight={48}
          classes={{ withBorder: 'no-border' }}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          rowSpacingType="margin"
          onRowClick={(params) => {}}
          page={page}
          onPageChange={onPageChange}
          pageSize={pageSize ?? _pageSize}
          onPageSizeChange={onPageSizeChange ?? _onPageSizeChange}
          loading={loading}
          rowCount={rowCount}
          paginationMode={paginationMode ?? 'client'}
          rowsPerPageOptions={rowsPerPageOptions ?? [5, 10, 15]}
          pagination={true}
          getRowId={(row: any) => {
            return row[idColumn];
          }}
          getRowClassName={(params) => 'vegatable--white'}
          components={{
            LoadingOverlay: LinearProgress,
            ...components,
          }}
          {...rest}
        />
      </Box>
    </div>
  );
}

export default VegaDataGridV4;
