import { useEffect } from 'react';
import { Add, BorderBottom } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { VegaCard, VegaTextField } from '../../../../components/common';
import VegaContainedButton from '../../../../components/common/VegaContainedButton';
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton';
import VegaSelect from '../../../../components/common/VegaSelect';
import VegaText from '../../../../components/common/VegaText';
import { useClientAuth } from '../../../../providers/ClientProvider';
import {
  IAddUserRequest,
  UserRoleEnums,
} from '../../../Program-Dashboard/api/roles';
import BranchSelector from '../../../components/BranchSelector';
import CorporateSelector from '../../../components/CorporateSelector';
import { ICorporateEntity } from '../../../types/CorporateCustomer';
import { IAddUserState } from '../../pages/MCCTeamManagement';
import { COMMON, GREY } from '../../../../constants/style';
import VegaAspektaText from '../../../../components/common/VegaAspektaText';

interface IApplicationProps {
  state: IAddUserState;
  rolesList: string[];
  handleChange: (key: keyof IAddUserState, value: string | File | null) => void;
  setBranchCode?: any;
  setIsEmptyKey: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUser = ({
  state,
  handleChange,
  rolesList,
  setBranchCode,
  setIsEmptyKey,
}: IApplicationProps) => {
  const { mccPlatformId, clientId } = useClientAuth();

  const handleRoleChange = (value: UserRoleEnums) => {
    handleChange('roleType', value);

    if (value === UserRoleEnums.HQ_USER) {
      handleChange('platformId', mccPlatformId);
    }
  };

  const handleBranch = (name: keyof IAddUserRequest, value: any) => {
    setBranchCode(value.branchCode);
    handleChange(name, value.branchId || '');
  };
  const handleCorporate = (value: ICorporateEntity | null) => {
    handleChange('platformId', value?.id || '');
  };

  return (
    <>
      <Box sx={{ px: 3 }} mb={4}>
        <Box mb={1}>
          <VegaAspektaText
            text={'Personal Information'}
            fontSize={14}
            fontWeight={'600'}
            color={COMMON.gray}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <VegaTextField
              value={state.name}
              onChange={(e) => handleChange('name', e.target.value)}
              key="name"
              label="Enter Name"
              type={'text'}
              placeholder="Enter Name"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <VegaTextField
              value={state.mobileNumber}
              onChange={(e) => handleChange('mobileNumber', e.target.value)}
              key="mobileNumber"
              label="Enter Mobile No"
              type={'number'}
              placeholder="Enter Mobile No"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VegaTextField
              value={state.email}
              onChange={(e) => handleChange('email', e.target.value)}
              key="email"
              label="Enter Email"
              type={'email'}
              placeholder="Enter Email"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <VegaTextField
              value={state.address}
              onChange={(e) => handleChange('address', e.target.value)}
              key="address"
              label="Enter address"
              type={'text'}
              placeholder="Enter address"
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ px: 3, borderBottom: `1px solid ${GREY.grey}` }} pb={2}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6}>
            <VegaSelect
              options={rolesList}
              key="roleType"
              selected={state.roleType}
              label="Select Role"
              onChange={(value) => handleRoleChange(value as UserRoleEnums)}
            />
          </Grid>
          {/* {state.roleType === UserRoleEnums.BRANCH_USER && !isHQ && (
            <Grid item xs={12} sm={6}>
              <VegaTextField
                value={state.platformId}
                onChange={(e) => handleChange('platformId', e.target.value)}
                key="branchId"
                label="Enter branch"
                type={'text'}
                placeholder="Enter branch"
                disabled
              />
            </Grid>
          )} */}
          {(state.roleType === UserRoleEnums.BRANCH_ADMIN ||
            state.roleType === UserRoleEnums.BRANCH_USER) && (
            <Grid item xs={12} sm={6}>
              <BranchSelector
                name="branchId"
                selected={state.platformId ?? ''}
                handleChange={(value) => handleBranch('platformId', value)}
              />
            </Grid>
          )}
          {state.roleType === UserRoleEnums.CORPORATE_ADMIN && (
            <Grid item xs={12} sm={6}>
              <CorporateSelector
                name="corporateId"
                selected={state.platformId}
                handleChange={(value) => handleCorporate(value)}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={{ px: 3 }} mt={2}>
        <Box mb={1}>
          <VegaAspektaText
            text={'Goverment ID Proof'}
            fontSize={14}
            fontWeight={'600'}
            color={COMMON.gray}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <VegaSelect
              options={['PAN', 'AADHAR', 'LICENCE']}
              key="idType"
              selected={state.idType}
              label="Select ID"
              onChange={(value) => handleChange('idType', value)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <VegaTextField
              value={state.idNumber}
              onChange={(e) => handleChange('idNumber', e.target.value)}
              key="idNumber"
              label="ID Number"
              type={'text'}
              placeholder="ID Number ( Optional )"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddUser;
