import { Box, Stack, SxProps } from '@mui/material';
import { IKeyValueProps } from '../../types/commonType';
import { QUICK_ACCESS } from '../../constants/style';
import { ListHead2Typo } from './Typography';

interface ISideNavigationMenuV1Props {
  menuValues: IKeyValueProps[];
  setMenu: React.Dispatch<React.SetStateAction<any>>;
  menu: string;
  menuSx?: SxProps;
  header: React.ReactNode;
  menuItem: React.ReactNode;
}

const SideNavigationMenuV1 = ({
  menuValues,
  setMenu,
  menu,
  header,
  menuItem,
  menuSx,
}: ISideNavigationMenuV1Props) => {
  return (
    <Box mx={-2.5} my={-2} height={'100%'}>
      {header}
      <Stack sx={{ height: `calc(100% - 22px)`, ...menuSx }} direction="row">
        <Box borderRight={`1px solid ${QUICK_ACCESS.border[50]}`}>
          {menuValues.map((item) => (
            <Box
              key={item.key}
              onClick={() => setMenu(item.key)}
              width={197}
              px={3}
              py={2}
              sx={{
                color:
                  menu === item.key
                    ? QUICK_ACCESS.primary.blue
                    : QUICK_ACCESS.text[100],
                backgroundColor:
                  menu === item.key
                    ? QUICK_ACCESS.background[75]
                    : QUICK_ACCESS.background[50],
                borderBottom: `1px solid ${
                  menu === item.key
                    ? QUICK_ACCESS.primary.blue
                    : QUICK_ACCESS.border[50]
                }`,
              }}
              className="cursor-pointer"
            >
              <ListHead2Typo>{item.value}</ListHead2Typo>
            </Box>
          ))}
        </Box>
        <Box p={3} sx={{ overflow: 'auto' }} width={'100%'}>
          {menuItem}
        </Box>
      </Stack>
    </Box>
  );
};

export default SideNavigationMenuV1;
