import SupportSearch from '../components/BackOffice/Support/SupportSearch';
import { useSupport } from '../providers/SupportProvider';
import AccountDetails from '../components/BackOffice/Support/AccountDetails';

const SupportWrap = () => {
  const { display } = useSupport();

  return display === 'search' ? <SupportSearch /> : <AccountDetails />;
};

export default SupportWrap;
