/* eslint-disable react/jsx-pascal-case */
import { useState } from 'react';
import { Stack } from '@mui/material';
import Vega_Text from '../../../common/v3/Vega_Text';
import Vega_Button from '../../../common/v3/Vega_Button';
import { NEW_PRIMARY } from '../../../../constants/style';
import BlockCardModal from './BlockCardModal';
import ViewPastActivityModal from './ViewPastActivityModal';
import ReissueCardReqDialog from './ReissueCardReqDialog';
import CloseAccountReqDialog from './CloseAccountReqDialog';
import BlockByActionDialog from './BlockByActionDialog';
import AdjustTransactionDrawer from './AccountDetailsStep/Transactions/AdjustTransactionDrawer';

const AccountDetailsActionContainer = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);

  const [openCloseAccountReqDialog, setOpenCloseAccountReqDialog] =
    useState(false);
  const [openReissueCardReqDialog, setOpenReissueCardReqDialog] =
    useState(false);
  const [openClientAdjustmentReqDialog, setOpenClientAdjustmentReqDialog] =
    useState(false);
  const [openBlockByActionDialog, setOpenBlockByActionDialog] = useState(false);

  const setCloseAccountReqDialogView = (open: boolean) => () => {
    setOpenCloseAccountReqDialog(open);
  };
  const setReissueCardReqDialogView = (open: boolean) => () => {
    setOpenReissueCardReqDialog(open);
  };
  const setClientAdjustmentReqDialogView = (open: boolean) => () => {
    setOpenClientAdjustmentReqDialog(open);
  };
  const setBlockByActionDialogView = (open: boolean) => () => {
    setOpenBlockByActionDialog(open);
  };

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} gap={2} sx={{ mt: 2 }}>
        <Vega_Text
          text={'Quick Actions'}
          fontWeight={700}
          fontSize={10}
          textTransform={'uppercase'}
          color={'#7B8694'}
          letterSpacing={'0.5px'}
        />
        <Stack direction={'row'} gap={1}>
          {/* <Vega_Button
            text="Block by Actions"
            variant="outlined"
            bgColor="transparant"
            textColor={NEW_PRIMARY.BLUE}
            sx={{ py: 0.2 }}
            onClick={setBlockByActionDialogView(true)}
          /> */}
          {/* <Vega_Button
            text="Block Card"
            variant="outlined"
            bgColor="transparant"
            textColor={NEW_PRIMARY.BLUE}
            sx={{ py: 0.2 }}
            onClick={() => setOpen(!open)}
          /> */}
          <Vega_Button
            text="Client Adjustments"
            variant="outlined"
            bgColor="transparant"
            textColor={NEW_PRIMARY.BLUE}
            sx={{ py: 0.2 }}
            onClick={setClientAdjustmentReqDialogView(true)}
          />
          <Vega_Button
            text="View Past Activities"
            variant="outlined"
            bgColor="transparant"
            textColor={NEW_PRIMARY.BLUE}
            sx={{ py: 0.2 }}
            onClick={() => setIsView(!isView)}
          />
          {/* <Vega_Button
            text="Reissue Card"
            variant="outlined"
            bgColor="transparant"
            textColor={NEW_PRIMARY.BLUE}
            sx={{ py: 0.2 }}
            onClick={setReissueCardReqDialogView(true)}
          /> */}
          <Vega_Button
            text="Close Account"
            variant="outlined"
            bgColor="transparant"
            textColor={NEW_PRIMARY.BLUE}
            sx={{ py: 0.2 }}
            onClick={setCloseAccountReqDialogView(true)}
          />
        </Stack>
      </Stack>
      <BlockCardModal open={open} handleClose={() => setOpen(!open)} />

      {/* modals and dialogs */}
      <ViewPastActivityModal
        open={isView}
        handleClose={() => setIsView(!isView)}
      />

      <CloseAccountReqDialog
        open={openCloseAccountReqDialog}
        setOpenFalse={setCloseAccountReqDialogView(false)}
      />

      <ReissueCardReqDialog
        open={openReissueCardReqDialog}
        setOpenFalse={setReissueCardReqDialogView(false)}
      />

      <AdjustTransactionDrawer
        open={openClientAdjustmentReqDialog}
        onClose={setClientAdjustmentReqDialogView(false)}
      />

      <BlockByActionDialog
        open={openBlockByActionDialog}
        setOpenFalse={setBlockByActionDialogView(false)}
      />
    </>
  );
};

export default AccountDetailsActionContainer;
