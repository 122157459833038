import { Box, Stack } from '@mui/material';
import { PageHeadingTypo } from '../common/Typography';
import VegaNoResults from '../common/VegaNoResults';
import VegaSearchfieldV4 from '../common/v4/VegaSearchfieldV4';
import { useState } from 'react';
import VegaTableV4, { ITableDataProps } from '../common/v4/VegaTableV4';
import { useClientAuth } from '../../providers/ClientProvider';
import { usePartnersList } from '../../store/queries/partnerQueries';
import { capitalizeString } from '../../utils/stringUtils';
import { usePartnerMgmtContext } from '../../mcc/Program-Dashboard/pages/PartnerManagementWrap';
import { EntityDto } from '../../types/entity';

const PartnerManagementBrandList = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const { setCurrState, setPartner } = usePartnerMgmtContext();

  const onSearchUpdate = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const { clientId } = useClientAuth();

  const { data, isFetching } = usePartnersList({
    clientId: clientId || '',
    page,
    pageSize,
    name: search ? search : undefined,
  });

  const brandViewDetailOnClick = (data: EntityDto) => {
    setCurrState?.('partner');
    setPartner?.(data);
  };

  const structuredData: ITableDataProps[] =
    data?.records.map((rec) => {
      return {
        rowData: [
          {
            value: rec.name,
            type: 'string',
          },
          {
            value: capitalizeString(rec.panNo),
            type: 'string',
          },
          {
            value: rec.companyType,
            type: 'string',
          },
          {
            value: rec.status,
            type: 'status',
            statusColor: rec.status === 'ACTIVE' ? 'green' : 'yellow',
          },
          {
            value: 'View Details',
            type: 'link',
            linkOnClick: brandViewDetailOnClick,
          },
        ],
        id: rec,
      };
    }) || [];

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <PageHeadingTypo>Co Brand</PageHeadingTypo>
        <VegaSearchfieldV4 value={search} onChange={onSearchUpdate} />
      </Stack>
      {!Boolean(data?.records.length) && !isFetching ? (
        <VegaNoResults errorText="No Co-Brand Found" />
      ) : (
        <VegaTableV4
          head={['Partner', 'PAN Number', 'Company Type', 'Status', 'Action']}
          data={structuredData}
          pagination={{
            rowsPerPage: pageSize,
            setRowsPerPage: setPageSize,
            setPage,
            page,
            totalRecords: data?.totalItems || 0,
          }}
          isLoading={isFetching}
        />
      )}
    </Box>
  );
};

export default PartnerManagementBrandList;
