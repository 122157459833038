import * as React from 'react';
import { SVGProps } from 'react';
import { QUICK_ACCESS } from '../../constants/style';
const FileType2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M5.60005 4.80156H10.4001M5.60005 7.20156H10.4001M5.60005 9.60156H8.00005M4.39987 1.60156H11.6C12.4837 1.60156 13.2 2.31793 13.2 3.20159L13.1998 12.8016C13.1998 13.6852 12.4835 14.4016 11.5998 14.4016L4.3998 14.4015C3.51614 14.4015 2.7998 13.6852 2.7998 12.8015L2.79987 3.20155C2.79987 2.3179 3.51621 1.60156 4.39987 1.60156Z"
      stroke={props.stroke || QUICK_ACCESS.primary.blue}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default FileType2Icon;
