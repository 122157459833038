import React, { PropsWithChildren } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import MuiList, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { VEGA_COMMON } from '../../constants/style';

const defaultDrawerWidth = '198px';

interface StyledListProps extends ListProps {
  activeColor?: string;
  inactiveColor?: string;
}

const List = styled(MuiList, {
  shouldForwardProp: (prop) =>
    prop !== 'activeColor' && prop !== 'inactiveColor',
})<StyledListProps>(({ theme, activeColor, inactiveColor }) => ({
  '& .MuiListItemButton-root': {
    padding: '8px 24px',
    gap: 8,
    color: inactiveColor,
  },
  '& .MuiListItemButton-root.Mui-selected': {
    background: '#FAFAFA',
    color: activeColor,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
  },
  '& .MuiListItem-root': {
    borderBottom: '1px solid #EEE',
    minWidth: '150px',
    ':has(.Mui-selected)': {
      borderBottom: `1px solid ${activeColor}`,
    },
  },
  '&.MuiList-root': {
    display: 'flex',
    flex: '1 1 auto',
    overflowX: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    '&.MuiList-root': {
      display: 'block',
    },
    '& .MuiListItem-root': {
      minWidth: 0,
    },
  },
}));

type MenuDrivenContentListItem = {
  id: string;
  title: string;
  Icon?: ({ color }: { color: string }) => JSX.Element;
};

type VPMenuDrivenContentProps = {
  menuItems: MenuDrivenContentListItem[];
  selectedMenuItem: string;
  drawerWidth?: string | number;
  onMenuItemChange: (id: string) => void;
  activeColor?: string;
  inactiveColor?: string;
};

function VPMenuDrivenContent({
  menuItems,
  selectedMenuItem,
  drawerWidth = defaultDrawerWidth,
  onMenuItemChange,
  activeColor = VEGA_COMMON.primaryBlue,
  inactiveColor = VEGA_COMMON.text100,
  children,
}: PropsWithChildren<VPMenuDrivenContentProps>) {
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => {
    onMenuItemChange(id);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        flex: 1,
        overflowX: {
          xs: 'hidden',
          sm: 'visible',
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            sm: drawerWidth,
          },
          borderRight: '1px solid #EEE',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <List
          disablePadding
          inactiveColor={inactiveColor}
          activeColor={activeColor}
        >
          {menuItems.map(({ id, title, Icon }) => (
            <ListItem key={id} disablePadding>
              <ListItemButton
                onClick={(e) => handleListItemClick(e, id)}
                selected={selectedMenuItem === id}
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon
                      color={
                        selectedMenuItem === id ? activeColor : inactiveColor
                      }
                    />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    className: 'font-aspekta-500',
                    fontSize: 12,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'stretch',
          pl: {
            xs: 2,
            sm: 3.25,
          },
          pt: 3,
          pr: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default VPMenuDrivenContent;
