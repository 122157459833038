import axios, { AxiosResponse } from 'axios';
import { BASE_URL, REPORT } from './endpoints';
import { ANALYTICS_QUERY_NAME } from '../enums/analytics.enum';
import { PaginatedResponse } from '../utils/api';

const REPORT_BASE = BASE_URL + REPORT.BASE;
const ANALYTICS_BASE_URL = REPORT_BASE + REPORT.ANALYTICS;

export const getAnalyticsDate = <T>(
  queryName: ANALYTICS_QUERY_NAME | undefined,
  startDate: string,
  endDate: string,
  programId: string,
  page: number,
  pageSize: number
): Promise<AxiosResponse<PaginatedResponse<T>, any>> => {
  if (queryName) {
    return axios.post(`${ANALYTICS_BASE_URL}/${queryName}`, {
      fixedParams: {
        START_DATE: startDate,
        END_DATE: endDate,
        PROGRAM_ID: programId,
      },
      page,
      pageSize,
    });
  } else {
    throw new Error('Query Name not provided');
  }
};
