import { createContext, useContext, useEffect, useState } from 'react';
import { getAllMerchants } from '../../../api/rewardsLoyalty';
import { CampaignRuleService } from '../../../api/rule';
import { Merchant } from '../../../types/resources';
import {
  CampaignRuleType,
  Contribution,
  ContributionEntityType,
  FinancialTenureData,
  InclusionExclusionConditionType,
  InlcusionExclusionData,
  MilestoneEventType,
  NonTransactionalEventType,
  PointBasedRewardsData,
  ContributionData,
  RuleFinancialData,
  TransactionalEventType,
  CampaignRule,
  RuleMonetizationType,
  RuleRewardData,
  IterationType,
  PointBasedMilestone,
  Campaign,
  GiftVoucher,
  GiftVoucherType,
  NonPointBasedMilestone,
  CampaignType,
} from '../../../types/RewardsLoyalty';
import { useCampaign } from './CampaignProvider';
import { CampaignRuleBuilder } from './CampaignRuleBuilder';
import { CampaignRuleValidator } from './CampaignRuleValidator';

export enum RewardsInputType {
  REWARD_POINTS = 'rewardPoints',
  PER_AMOUNT = 'perAmount',
  ITERATION = 'iteration',
  MAXIMUM_POINTS = 'maximumPoints',
}

export enum InclusionExclusionInputType {
  MERCHANT,
  CATEGORY,
  TRANSACTION_TYPE,
}

interface CampaignRuleContextType {
  campaign: Campaign | null;
  selectedRuleType: string | null;
  isRulePaid: boolean;
  ruleName: string;
  selectedEvent: string;
  rewardsData: Partial<PointBasedRewardsData>;
  financialData: Partial<RuleFinancialData>;
  contributionData: Partial<ContributionData>;
  inclusionExclusionData: Partial<InlcusionExclusionData>;
  merchants: Merchant[];
  milestoneData: Partial<PointBasedMilestoneRewardsData>;
  nonPointBasedMilestoneData: Partial<NonPointBasedMilestoneRewardsData>;
  giftVouchers: Partial<GiftVoucher>[];
  discountVouchers: Partial<GiftVoucher>[];
  subscriptionVouchers: Partial<GiftVoucher>[];

  // methods
  getEventsForRuleType(ruleType: CampaignRuleType): string[];

  // inclusion exclusion
  onInclusionExclusionConditionTypeChange: (data: {
    type: InclusionExclusionInputType;
    condition: string;
  }) => void;

  onInclusionExclusionSelectValuesChange: (data: {
    type: InclusionExclusionInputType;
    values: string[];
  }) => void;
  onInclusionExclusionTransactionAmountChange: (value: number) => void;

  // contributions
  addContribution: (contribution: Contribution) => void;
  deleteContribution: (contributionType: ContributionEntityType) => void;
  updateContribution: (
    contributionType: ContributionEntityType,
    value?: number | string
  ) => void;

  // financials
  addFinancialTenure: (financialTenure: FinancialTenureData) => void;
  deleteFinancialTenure: (financialTenure: FinancialTenureData) => void;

  // rewards
  onRuleRewardsDataChange: (data: {
    inputType: RewardsInputType;
    value: string | number;
  }) => void;

  // point based milestones
  addMilestone: (data: Partial<PointBasedMilestone>) => void;
  updateMilestone: (data: {
    index: number;
    updatedValue: Partial<PointBasedMilestone>;
  }) => void;
  deleteMilestone: (index: number) => void;
  updateMilestoneIteration: (iteration: string) => void;

  // non point based milestones
  addNonPointBasedMilestone: (data: Partial<NonPointBasedMilestone>) => void;
  deleteNonPointBasedMilestone: (index: number) => void;
  updateNonPointBasedMilestoneIteration: (iteration: string) => void;
  updateNonPointBasedMilestone: (data: {
    index: number;
    updatedValue: Partial<NonPointBasedMilestone>;
  }) => void;

  // rule
  onRuleNameChange: (value: string) => void;
  onRuleTypeChange: (campaignType: string) => void;
  onRuleEventChange: (event: string) => void;
  onRulePaidStatusChange: (status: boolean) => void;

  // gift Voucher
  addVoucher: (data: {
    voucher: Partial<GiftVoucher>;
    type: GiftVoucherType;
  }) => void;
  deleteLastVoucher: (data: { type: GiftVoucherType }) => void;
  updateVoucher: (data: {
    voucher: Partial<GiftVoucher>;
    index: number;
    type: GiftVoucherType;
  }) => void;

  createRule: () => void;
  updateRule: () => void;
  setRule: (rule: CampaignRule) => void;
  reset: () => void;

  validationErrors: RuleValidationErrors;
}

const CampaignRuleContext = createContext<CampaignRuleContextType | null>(null);

export interface PointBasedMilestoneRewardsData {
  iteration: string;
  milestones: Partial<PointBasedMilestone>[];
}

export interface NonPointBasedMilestoneRewardsData {
  iteration: string;
  milestones: Partial<NonPointBasedMilestone>[];
}

type RuleValidationErrors = {
  isInputValid: boolean;
  commonErrors: string[];
  rewardDataErrors: string[];
  financialDataErrors: string[];
  contributionDataErrors: string[];
  inclusionExclusionDataErrors: string[];
};

export const useCampaignRule = () =>
  useContext(CampaignRuleContext) as CampaignRuleContextType;

export const CampaignRuleProvider = ({ children }: any) => {
  const {
    selectedCampaign,
    updateRuleOfSelectedCampaign,
    addRuleToSelectedCampaign,
  } = useCampaign();
  const [ruleType, setRuleType] = useState<string | null>(null);
  const [isRulePaid, setIsRulePaid] = useState<boolean>(false);
  const [ruleName, setRuleName] = useState<string>('');
  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [segment, setSegment] = useState<string>('ALL');
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [rewardsData, setRewardsData] = useState<
    Partial<PointBasedRewardsData>
  >({});
  const [financialData, setFinancialData] = useState<
    Partial<RuleFinancialData>
  >({});
  const [contributionData, setContributionData] = useState<
    Partial<ContributionData>
  >({});
  const [inclusionExclusionData, setInclusionExclusionData] = useState<
    Partial<InlcusionExclusionData>
  >({
    isMerchantInclusive: true,
    isCategoriesInclusive: false,
    merchants: [],
    categories: [],
  });
  const [pointBasedMilestoneData, setPointBasedMilestoneData] = useState<
    Partial<PointBasedMilestoneRewardsData>
  >({
    iteration: IterationType.ANNUALLY,
    milestones: [],
  });
  const [nonPointBasedMilestoneData, setNonPointBasedMilestoneData] = useState<
    Partial<NonPointBasedMilestoneRewardsData>
  >({
    iteration: IterationType.ANNUALLY,
    milestones: [],
  });
  const [inputValidationErrors, setInputValidationErrors] =
    useState<RuleValidationErrors>({
      isInputValid: false,
      commonErrors: [],
      rewardDataErrors: [],
      financialDataErrors: [],
      contributionDataErrors: [],
      inclusionExclusionDataErrors: [],
    });
  const [giftVouchers, setGiftVouchers] = useState<Partial<GiftVoucher>[]>([]);
  const [subscriptionVouchers, setSubscriptionVouchers] = useState<
    Partial<GiftVoucher>[]
  >([]);
  const [discountVouchers, setDiscountVouchers] = useState<
    Partial<GiftVoucher>[]
  >([]);

  // rule as from api
  const [rule, setRule] = useState<CampaignRule | null>(null);

  function _onRuleTypeChange(campaignRule: string) {
    setRuleType(campaignRule);
    const firstOption = getFirstOptionForRuleType(campaignRule);
    _onRuleEventChange(firstOption);
  }
  function _onRuleEventChange(event: string) {
    if (ruleType == CampaignRuleType.MILESTONE) {
      setPointBasedMilestoneData({
        milestones: [],
      });
      setNonPointBasedMilestoneData({
        milestones: [],
      });
    }
    setSelectedEvent(event);
  }
  function _onRulePaidStatusChange(value: boolean) {
    setIsRulePaid(value);
  }
  function _onRuleNameChange(value: string) {
    setRuleName(value);
  }

  function _addContribution(contribution: Contribution) {
    const existingContribution = contributionData.contributionEntities ?? [];
    existingContribution.push(contribution);
    const updatedContributionData = { ...contributionData };
    updatedContributionData.contributionEntities = existingContribution;
    setContributionData(updatedContributionData);
  }

  function _deleteContribution(contributionType: ContributionEntityType) {
    const updatedContributions = contributionData.contributionEntities?.filter(
      (item) => {
        if (item.contributorType == contributionType) {
          return false;
        }
        return true;
      }
    );
    const updatedContributionData = { ...contributionData };
    updatedContributionData.contributionEntities = updatedContributions;

    setContributionData(updatedContributionData);
  }

  function _updateContribution(
    contributionType: ContributionEntityType,
    value?: number | string
  ) {
    const updatedContributions = contributionData.contributionEntities?.map(
      (item) => {
        if (item.contributorType == contributionType) {
          if (typeof value === 'number') {
            return {
              ...item,
              percentage: value,
            };
          } else if (typeof value === 'string') {
            return {
              ...item,
              name: value,
            };
          }
        }
        return item;
      }
    );
    const updatedContributionData = { ...contributionData };
    updatedContributionData.contributionEntities = updatedContributions;
    setContributionData(updatedContributionData);
  }

  function _addFinancialTenure(tenure: FinancialTenureData) {
    const existingTenures = financialData.tenures ?? [];
    existingTenures.push(tenure);
    const updatedFinancialData = { ...financialData };
    updatedFinancialData.tenures = existingTenures;
    setFinancialData(updatedFinancialData);
  }

  function _deleteFinancialTenure(tenure: FinancialTenureData) {
    const updatedTenures = financialData.tenures?.filter((item) => {
      if (item.tenureType == tenure.tenureType) {
        return false;
      }
      return true;
    });
    const updatedFinancialData = { ...financialData };
    updatedFinancialData.tenures = updatedTenures;
    setFinancialData(updatedFinancialData);
  }

  function _onTransactionalRewardsDataChange(data: {
    inputType: RewardsInputType;
    value: string | number;
  }) {
    setRewardsData((prev) => {
      return {
        ...prev,
        [data.inputType]: data.value,
      };
    });
  }

  function _onInclusionExclusionConditionTypeChange(data: {
    type: InclusionExclusionInputType;
    condition: string;
  }) {
    const updatedData = { ...inclusionExclusionData };
    switch (data.type) {
      case InclusionExclusionInputType.CATEGORY:
        const isInclusive =
          data.condition == InclusionExclusionConditionType.INCLUSION;
        updatedData.isCategoriesInclusive = isInclusive;
        updatedData.isMerchantInclusive = isInclusive ? false : true;
        updatedData.merchants = [];
        updatedData.categories = [];
        break;
      case InclusionExclusionInputType.MERCHANT:
        const isMerchantInclusive =
          data.condition == InclusionExclusionConditionType.INCLUSION;
        updatedData.isMerchantInclusive = isMerchantInclusive;
        updatedData.isCategoriesInclusive = isMerchantInclusive ? false : true;
        updatedData.merchants = [];
        updatedData.categories = [];
        break;
      case InclusionExclusionInputType.TRANSACTION_TYPE:
        updatedData.isTransactionInclusive =
          data.condition == InclusionExclusionConditionType.INCLUSION;
        break;
    }

    setInclusionExclusionData(updatedData);
  }

  function _onInclusionExclusionSelectValuesChange(data: {
    type: InclusionExclusionInputType;
    values: string[];
  }) {
    const updatedData = { ...inclusionExclusionData };
    switch (data.type) {
      case InclusionExclusionInputType.CATEGORY:
        updatedData.categories = data.values;
        break;
      case InclusionExclusionInputType.MERCHANT:
        updatedData.merchants = data.values;
        // hacky code need to improve this
        if (updatedData.isMerchantInclusive) {
          const merchantIds: string[] = updatedData.merchants.filter(
            (item) => item.length > 0
          );
          const availableCategories = getCategoriesFor(merchantIds);
          const actualCategories = updatedData.categories ?? [];
          const validCategories = actualCategories.filter((category) =>
            availableCategories.some(
              (availableCategory) => availableCategory === category
            )
          );
          updatedData.categories = validCategories;
        }
        break;
      case InclusionExclusionInputType.TRANSACTION_TYPE:
        updatedData.transactionTypes = data.values;
        break;
    }
    setInclusionExclusionData(updatedData);
  }

  const getCategoriesFor = (selectedMerchantIds: string[]) => {
    const selectedMerchants = merchants.filter((item) =>
      selectedMerchantIds.includes(item.id)
    );

    const categorySet = new Set();

    selectedMerchants.forEach((item) => {
      item.mccCodes?.forEach((category) => {
        categorySet.add(category.category.id);
      });
    });

    return Array.from(categorySet.values());
  };

  function _onInclusionExclusionTransactionAmountChange(value: number) {
    const updatedData = { ...inclusionExclusionData };
    updatedData.transactionAmount = value;
    setInclusionExclusionData(updatedData);
  }

  // Point based milestones
  function _addMilestone(data: Partial<PointBasedMilestone>) {
    setPointBasedMilestoneData((prev) => {
      const existingMilestones = prev.milestones ?? [];
      existingMilestones.push(data);
      return {
        ...prev,
        milestones: existingMilestones,
      };
    });
  }

  function _deleteMilestone(index: number) {
    setPointBasedMilestoneData((prev) => {
      const updatedMilestones = prev.milestones?.filter((item, itemIndex) => {
        if (index == itemIndex) return false;
        return true;
      });
      return {
        ...prev,
        milestones: updatedMilestones,
      };
    });
  }
  function _updateMilestone(data: {
    index: number;
    updatedValue: Partial<PointBasedMilestone>;
  }) {
    setPointBasedMilestoneData((prev) => {
      const updatedMilestones = prev.milestones?.map((item, itemIndex) => {
        if (data.index == itemIndex) return data.updatedValue;
        return item;
      });
      return {
        ...prev,
        milestones: updatedMilestones,
      };
    });
  }

  function _updateMilestoneIteration(type: string) {
    setPointBasedMilestoneData((prev) => {
      return {
        ...prev,
        iteration: type,
      };
    });
  }

  // non point based milestones
  function _addNonPointBasedMilestone(data: Partial<NonPointBasedMilestone>) {
    setNonPointBasedMilestoneData((prev) => {
      const existingMilestones = prev.milestones ?? [];
      existingMilestones.push(data);
      return {
        ...prev,
        milestones: existingMilestones,
      };
    });
  }

  function _deleteNonPointBasedMilestone(index: number) {
    setNonPointBasedMilestoneData((prev) => {
      const updatedMilestones = prev.milestones?.filter((item, itemIndex) => {
        if (index == itemIndex) return false;
        return true;
      });
      return {
        ...prev,
        milestones: updatedMilestones,
      };
    });
  }
  function _updateNonPointBasedMilestoneIteration(type: string) {
    setNonPointBasedMilestoneData((prev) => {
      return {
        ...prev,
        iteration: type,
      };
    });
  }

  function _updateNonPointBasedMilestone(data: {
    index: number;
    updatedValue: Partial<NonPointBasedMilestone>;
  }) {
    setNonPointBasedMilestoneData((prev) => {
      const updatedMilestones = prev.milestones?.map((item, itemIndex) => {
        if (data.index == itemIndex) return data.updatedValue;
        return item;
      });
      return {
        ...prev,
        milestones: updatedMilestones,
      };
    });
  }

  function _addVoucher(data: {
    voucher: Partial<GiftVoucher>;
    type: GiftVoucherType;
  }) {
    switch (data.type) {
      case GiftVoucherType.GIFT_VOUCHER:
        setGiftVouchers((prev) => [...prev, data.voucher]);
        break;
      case GiftVoucherType.DISCOUNT_VOUCHER:
        setDiscountVouchers((prev) => [...prev, data.voucher]);
        break;
      case GiftVoucherType.SUBSCRIPTION:
        setSubscriptionVouchers((prev) => [...prev, data.voucher]);
        break;
    }
  }
  function _deleteLastVoucher(data: { type: GiftVoucherType }) {
    switch (data.type) {
      case GiftVoucherType.GIFT_VOUCHER:
        setGiftVouchers((prev) => {
          return prev.slice(0, prev.length - 1);
        });
        break;
      case GiftVoucherType.DISCOUNT_VOUCHER:
        setDiscountVouchers((prev) => {
          return prev.slice(0, prev.length - 1);
        });
        break;
      case GiftVoucherType.SUBSCRIPTION:
        setSubscriptionVouchers((prev) => {
          return prev.slice(0, prev.length - 1);
        });
        break;
    }
  }
  function _updateVoucher(data: {
    voucher: Partial<GiftVoucher>;
    index: number;
    type: GiftVoucherType;
  }) {
    switch (data.type) {
      case GiftVoucherType.GIFT_VOUCHER:
        setGiftVouchers((prev) => {
          return prev.map((item, currentIndex) => {
            if (data.index == currentIndex) {
              return data.voucher;
            }
            return item;
          });
        });
        break;
      case GiftVoucherType.DISCOUNT_VOUCHER:
        setDiscountVouchers((prev) => {
          return prev.map((item, currentIndex) => {
            if (data.index == currentIndex) {
              return data.voucher;
            }
            return item;
          });
        });
        break;
      case GiftVoucherType.SUBSCRIPTION:
        setSubscriptionVouchers((prev) => {
          return prev.map((item, currentIndex) => {
            if (data.index == currentIndex) {
              return data.voucher;
            }
            return item;
          });
        });
        break;
    }
  }

  const constructCampaignRuleObject = () => {
    const ruleRewardsData: RuleRewardData =
      CampaignRuleBuilder.constructCampaignRewardsData({
        ruleType: ruleType,
        rewardsData: rewardsData,
      });
    const ruleFilters = CampaignRuleBuilder.constructRuleFilters(
      inclusionExclusionData
    );
    const ruleContribution: ContributionData =
      CampaignRuleBuilder.constructContribution(contributionData);
    const milestoneData = CampaignRuleBuilder.constructMilestoneList({
      ruleType: ruleType,
      campaign: selectedCampaign,
      event: selectedEvent,
      pointBasedMilestoneData: pointBasedMilestoneData,
      nonPointBasedMilestoneData: nonPointBasedMilestoneData,
    });
    const giftsData = CampaignRuleBuilder.constructGiftsList({
      giftVouchers: giftVouchers,
      discountVouchers: discountVouchers,
      subscriptionVouchers: subscriptionVouchers,
    });

    const financialList: FinancialTenureData[] = [];
    if (isRulePaid) {
      financialData.tenures?.forEach((item) => financialList.push(item));
    }

    const campaignRule: CampaignRule = {
      ruleName: ruleName,
      campaignId: selectedCampaign!.id,
      ruleType: ruleType!,
      paymentType: isRulePaid
        ? RuleMonetizationType.PAID
        : RuleMonetizationType.FREE,
      mileStoneList: milestoneData ?? [],
      giftsLists: giftsData,
      filters: ruleFilters,
      financialList: financialList,
      contribution: ruleContribution,
      values: ruleRewardsData,
    };

    if (rule) {
      campaignRule.id = rule.id;
      campaignRule.createdAt = rule.createdAt;
      campaignRule.updatedAt = rule.updatedAt;
    }

    if ((milestoneData ?? []).length <= 0) {
      campaignRule.event = selectedEvent;
    }

    return campaignRule;
  };

  function _setRule(rule: CampaignRule) {
    console.log('==============_setRule======================');
    console.log(rule);
    console.log('====================================');
    const filters = rule.filters;
    const inclusionExclusionData: Partial<InlcusionExclusionData> = {
      transactionAmount: filters.minimumAmount,
      isTransactionInclusive:
        filters.transactionCode.filterType ==
        InclusionExclusionConditionType.INCLUSION,
      isMerchantInclusive:
        filters.merchantId.filterType ==
        InclusionExclusionConditionType.INCLUSION,
      isCategoriesInclusive:
        filters.merchantCategory.filterType ==
        InclusionExclusionConditionType.INCLUSION,
      transactionTypes: filters.transactionCode.transactionCodeList,
      categories: filters.merchantCategory.merchantCategoryIdList,
      merchants: filters.merchantId.merchantIdList,
    };

    const contribution: Partial<ContributionData> = {
      contributionEntities: rule.contribution.contributionEntities,
    };

    const rewardsData: Partial<PointBasedRewardsData> = {
      rewardPoints: rule.values.points,
      perAmount: rule.values.onSpends,
      maximumPoints: rule.values.maximumPoint,
      iteration: rule.values.timeframe,
    };

    const financialData: RuleFinancialData = {
      tenures: rule.financialList,
    };

    const giftVouchers: Partial<GiftVoucher>[] = filterGiftListByType(
      rule.giftsLists,
      GiftVoucherType.GIFT_VOUCHER
    );
    const discountVouchers: Partial<GiftVoucher>[] = filterGiftListByType(
      rule.giftsLists,
      GiftVoucherType.DISCOUNT_VOUCHER
    );
    const subscriptionVouchers: Partial<GiftVoucher>[] = filterGiftListByType(
      rule.giftsLists,
      GiftVoucherType.SUBSCRIPTION
    );

    setIsRulePaid(rule.paymentType == RuleMonetizationType.PAID);
    setRuleName(rule.ruleName);
    setRuleType(rule.ruleType);
    setSelectedEvent(rule.event ?? '');
    setRewardsData(rewardsData);
    setInclusionExclusionData(inclusionExclusionData);
    setContributionData(contribution);
    setFinancialData(financialData);
    setGiftVouchers(giftVouchers);
    setDiscountVouchers(discountVouchers);
    setSubscriptionVouchers(subscriptionVouchers);
    const milestoneList = rule.mileStoneList;
    if (milestoneList.length > 0) {
      const firstMilestone = milestoneList[0];
      setSelectedEvent(firstMilestone.mileStoneType);

      if (selectedCampaign?.campaignType == CampaignType.NON_POINT_BASED) {
        const nonPointBasedMilestoneData: NonPointBasedMilestoneRewardsData = {
          iteration: firstMilestone.values.timeframe!,
          milestones: milestoneList.map((item) => {
            const valueData = item.values;
            const data: Partial<NonPointBasedMilestone> = {
              count: valueData.onSpends,
              volume: valueData.volume,
              giftVouchers: filterGiftListByType(
                item.giftsLists ?? [],
                GiftVoucherType.GIFT_VOUCHER
              ),
              subscriptionVouchers: filterGiftListByType(
                item.giftsLists ?? [],
                GiftVoucherType.SUBSCRIPTION
              ),
              discountVouchers: filterGiftListByType(
                item.giftsLists ?? [],
                GiftVoucherType.DISCOUNT_VOUCHER
              ),
            };
            return data;
          }),
        };

        setNonPointBasedMilestoneData(nonPointBasedMilestoneData);
      } else if (selectedCampaign?.campaignType == CampaignType.POINT_BASED) {
        const pointBasedMilestoneData: PointBasedMilestoneRewardsData = {
          iteration: firstMilestone.values.timeframe!,
          milestones: milestoneList.map((item) => {
            const valueData = item.values;
            const data: Partial<PointBasedMilestone> = {
              count: valueData.onSpends,
              volume: valueData.volume,
              rewardPoints: valueData.points,
            };
            return data;
          }),
        };
        setPointBasedMilestoneData(pointBasedMilestoneData);
      }
    }
    setRule(rule);
  }

  function _reset() {
    setRuleType(null);
    setRule(null);
    setIsRulePaid(false);
    setRuleName('');
    setSelectedEvent('');
    setRewardsData({});
    setFinancialData({});
    setContributionData({});
    setInclusionExclusionData({
      isMerchantInclusive: true,
      isCategoriesInclusive: false,
    });
    setGiftVouchers([]);
    setDiscountVouchers([]);
    setSubscriptionVouchers([]);
    setNonPointBasedMilestoneData({ iteration: IterationType.ANNUALLY });
    setPointBasedMilestoneData({ iteration: IterationType.ANNUALLY });
  }

  function updateValidationErrors(data: RuleValidationErrors) {
    const hasFinancialErrors = data.financialDataErrors.length > 0;
    const hasContributionErrors = data.contributionDataErrors.length > 0;
    const hasInclusionExclusionErrors =
      data.inclusionExclusionDataErrors.length > 0;
    const hasCommonErrors = data.commonErrors.length > 0;
    const hasRewardErrors = data.rewardDataErrors.length > 0;
    setInputValidationErrors((prev) => {
      return {
        ...prev,
        isInputValid:
          hasFinancialErrors == false &&
          hasContributionErrors == false &&
          hasInclusionExclusionErrors == false &&
          hasCommonErrors == false &&
          hasRewardErrors == false,
      };
    });
  }

  //////////// API CALLS ////////////////////

  async function _fetchMerchants() {
    try {
      const response = await getAllMerchants();
      setMerchants(response.records);
    } catch (error) {
      console.error(error);
    }
  }

  async function _createRule() {
    try {
      const ruleToBeCreated = constructCampaignRuleObject();
      console.log('====================================');
      console.log(ruleToBeCreated);
      console.log('====================================');
      const response = await CampaignRuleService.createRule(ruleToBeCreated);
      addRuleToSelectedCampaign(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function _updateRule() {
    try {
      const ruleToBeCreated = constructCampaignRuleObject();
      const response = await CampaignRuleService.updateRule(ruleToBeCreated);
      updateRuleOfSelectedCampaign(response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    _fetchMerchants();
  }, []);

  // validations

  useEffect(() => {
    const errors = CampaignRuleValidator.validateRewardsData({
      rewardsData: rewardsData,
      milestoneData: pointBasedMilestoneData,
      nonPointBasedMilestoneData: nonPointBasedMilestoneData,
      giftsList: [
        ...giftVouchers,
        ...subscriptionVouchers,
        ...discountVouchers,
      ],
      ruleType: ruleType,
      campaignType: selectedCampaign?.campaignType,
      eventType: selectedEvent,
    });
    setInputValidationErrors((prev) => {
      const updatedData = {
        ...prev,
        financialDataErrors: errors,
      };
      updateValidationErrors(updatedData);
      return updatedData;
    });
  }, [
    rewardsData,
    pointBasedMilestoneData,
    giftVouchers,
    subscriptionVouchers,
    discountVouchers,
    ruleType,
    selectedCampaign,
    selectedEvent,
    nonPointBasedMilestoneData,
  ]);

  useEffect(() => {
    const errors = CampaignRuleValidator.validateCommonData({
      ruleName: ruleName,
      ruleType: ruleType,
      event: selectedEvent,
    });
    setInputValidationErrors((prev) => {
      const updatedData = {
        ...prev,
        commonErrors: errors,
      };
      updateValidationErrors(updatedData);
      return updatedData;
    });
  }, [ruleName, ruleType, selectedEvent]);

  useEffect(() => {
    const errors = CampaignRuleValidator.validateFincancialData(
      isRulePaid,
      financialData
    );
    setInputValidationErrors((prev) => {
      const updatedData = {
        ...prev,
        financialDataErrors: errors,
      };
      updateValidationErrors(updatedData);
      return updatedData;
    });
  }, [isRulePaid, financialData]);

  useEffect(() => {
    const errors =
      CampaignRuleValidator.validateContributionData(contributionData);

    setInputValidationErrors((prev) => {
      const updatedData = {
        ...prev,
        contributionDataErrors: errors,
      };
      updateValidationErrors(updatedData);
      return updatedData;
    });
  }, [contributionData]);

  // useEffect(() => {
  //   const errors = CampaignRuleValidator.validateInclusionExclusionData(
  //     inclusionExclusionData
  //   );
  //   setInputValidationErrors((prev) => {
  //     const updatedData = {
  //       ...prev,
  //       inclusionExclusionDataErrors: errors,
  //     };
  //     updateValidationErrors(updatedData);
  //     return updatedData;
  //   });
  // }, [inclusionExclusionData]);

  return (
    <CampaignRuleContext.Provider
      value={{
        // variables
        ruleName: ruleName,
        campaign: selectedCampaign,
        selectedRuleType: ruleType,
        isRulePaid: isRulePaid,
        rewardsData: rewardsData,
        financialData: financialData,
        contributionData: contributionData,
        merchants: merchants,
        inclusionExclusionData: inclusionExclusionData,
        selectedEvent: selectedEvent,
        milestoneData: pointBasedMilestoneData,
        nonPointBasedMilestoneData: nonPointBasedMilestoneData,
        giftVouchers: giftVouchers,
        subscriptionVouchers: subscriptionVouchers,
        discountVouchers: discountVouchers,

        // methods
        onRuleRewardsDataChange: _onTransactionalRewardsDataChange,
        onRuleNameChange: _onRuleNameChange,
        onRulePaidStatusChange: _onRulePaidStatusChange,
        onRuleTypeChange: _onRuleTypeChange,
        onRuleEventChange: _onRuleEventChange,
        createRule: _createRule,
        updateRule: _updateRule,
        setRule: _setRule,

        // point based milestone
        addMilestone: _addMilestone,
        deleteMilestone: _deleteMilestone,
        updateMilestone: _updateMilestone,
        updateMilestoneIteration: _updateMilestoneIteration,
        // non point based milestone
        addNonPointBasedMilestone: _addNonPointBasedMilestone,
        deleteNonPointBasedMilestone: _deleteNonPointBasedMilestone,
        updateNonPointBasedMilestone: _updateNonPointBasedMilestone,
        updateNonPointBasedMilestoneIteration:
          _updateNonPointBasedMilestoneIteration,

        getEventsForRuleType: filterEventForRuleType,

        // Financial data
        addFinancialTenure: _addFinancialTenure,
        deleteFinancialTenure: _deleteFinancialTenure,

        // contribution
        addContribution: _addContribution,
        deleteContribution: _deleteContribution,
        updateContribution: _updateContribution,

        // inclusion exclusion
        onInclusionExclusionConditionTypeChange:
          _onInclusionExclusionConditionTypeChange,
        onInclusionExclusionSelectValuesChange:
          _onInclusionExclusionSelectValuesChange,
        onInclusionExclusionTransactionAmountChange:
          _onInclusionExclusionTransactionAmountChange,

        // gift vouchers
        addVoucher: _addVoucher,
        updateVoucher: _updateVoucher,
        deleteLastVoucher: _deleteLastVoucher,

        reset: _reset,
        validationErrors: inputValidationErrors,
      }}
    >
      {children}
    </CampaignRuleContext.Provider>
  );
};

const filterEventForRuleType = (ruleType: CampaignRuleType) => {
  switch (ruleType) {
    case CampaignRuleType.TRANSACTIONAL:
      return Object.values(TransactionalEventType);
    case CampaignRuleType.NON_TRANSACTIONAL:
      return Object.values(NonTransactionalEventType);
    case CampaignRuleType.MILESTONE:
      return Object.values(MilestoneEventType);
  }
};

const getFirstOptionForRuleType = (type: string) => {
  switch (type) {
    case CampaignRuleType.TRANSACTIONAL:
      return TransactionalEventType.SPENDS;
    case CampaignRuleType.NON_TRANSACTIONAL:
      return NonTransactionalEventType.ACCOUNT_CREATION;
    case CampaignRuleType.MILESTONE:
      return MilestoneEventType.BY_COUNT;
  }
  return '';
};

const filterGiftListByType = (
  gitftList: GiftVoucher[],
  type: GiftVoucherType
) => {
  return gitftList.filter((item) => item.giftType == type);
};
