/** @format */

import axios, { AxiosResponse } from 'axios';
import { EntityAuthStatus } from '../types/entityAuth';
import {
  AllProgramPolicyResponse,
  AnyPolicyType,
  PolicyEntityType,
  PolicyType,
} from '../types/policy';
import { PROGRAM, BASE_URL } from './endpoints';

const PROGRAM_BASE = BASE_URL + PROGRAM.BASE;
const POLICY_BASE = PROGRAM_BASE + PROGRAM.POLICY.BASE;

const POLICY_PDD_BASE_URL = PROGRAM_BASE + PROGRAM.PDD;

const POLICY_BASE_ALL_EFFECTIVE_PROGRAM =
  POLICY_BASE + PROGRAM.POLICY.EFFECTIVE_PROGRAM + '/all';
const POLICY_BASE_ALL_PROGRAM = POLICY_BASE + PROGRAM.POLICY.ALL_POLICIES;
const EFFECTIVE_PROGRAM = POLICY_BASE + PROGRAM.POLICY.EFFECTIVE_PROGRAM;
const PROGRAM_MAPPING = POLICY_BASE + PROGRAM.POLICY.PROGRAM_MAPPING;
const DEFAULT_POLICY_URL = POLICY_BASE + PROGRAM.POLICY.DEFAULT_POLICY;
const SUBMIT_TO_CHECKER_URL = POLICY_BASE + PROGRAM.POLICY.SUBMIT_TO_CHECKER;

// done
export const addOrUpdatePolicy = (
  programId: string,
  policy: any,
  entityPolicyMappingStatus = 'DRAFT'
) => {
  return axios.post(PROGRAM_MAPPING, {
    entityId: programId,
    entityType: PolicyEntityType.PROGRAM,
    entityPolicyMappingStatus: entityPolicyMappingStatus,
    policy,
  });
};

// done
export const fetchPolicy = (
  programId: string,
  policyType: PolicyType
): Promise<AxiosResponse<AnyPolicyType>> => {
  return axios.get(EFFECTIVE_PROGRAM, {
    params: {
      entityId: programId,
      entityType: PolicyEntityType.PROGRAM,
      policyType,
    },
  });
};

//done
export const fetchAllEffectivePolicesForProgram = (
  programId: string
): Promise<AxiosResponse<AnyPolicyType[]>> => {
  return axios.get(POLICY_BASE_ALL_EFFECTIVE_PROGRAM, {
    params: {
      entityId: programId,
      entityType: PolicyEntityType.PROGRAM,
    },
  });
};

export const fetchAllPolicesForProgram = (
  programId: string
): Promise<AxiosResponse<AllProgramPolicyResponse>> => {
  return axios.get(POLICY_BASE_ALL_PROGRAM, {
    params: {
      entityId: programId,
      entityType: PolicyEntityType.PROGRAM,
    },
  });
};

// same as old - no change
export const fetchAllPDDList = (
  programId: string
): Promise<AxiosResponse<number[]>> => {
  return axios.get(POLICY_PDD_BASE_URL.replace('{programId}', programId));
};

// currently BE folks are contemplating whether to use this method or not
export async function submitAllPoliciesToChecker(data: {
  programId: string;
  userId: string;
}): Promise<EntityAuthStatus> {
  const endpoint = SUBMIT_TO_CHECKER_URL;

  try {
    const response = await axios.post(endpoint, null, {
      params: {
        entityId: data.programId,
        userId: data.userId,
        entityType: PolicyEntityType.PROGRAM,
      },
    });
    return response.data as EntityAuthStatus;
  } catch (error) {
    throw error;
  }
}

// done
export function disablePolicy(data: { policyId: string }) {
  return axios.patch(POLICY_BASE + `/${data.policyId}/status`, null, {
    params: {
      policyStatus: 'INACTIVE',
    },
  });
}

// currently BE folks are contemplating whether to use this method or not
export async function getDefaultPolicy(data: {
  policyType: PolicyType;
}): Promise<AnyPolicyType> {
  try {
    const endpoint = DEFAULT_POLICY_URL.replace(
      '{policyType}',
      data.policyType
    );
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}
