import axios from 'axios';
import { CREDENTIAL_MANAGER } from './endpoints';

const CREDENTIAL_MANAGER_BASE = CREDENTIAL_MANAGER.BASE;
const VENDOR_BASE =
  CREDENTIAL_MANAGER_BASE + CREDENTIAL_MANAGER.VENDOR_SERVICE.BASE;

export class CredentialService {
  static async getVendorCredSpecs(
    vendorId: string
  ): Promise<VendorCredSpecs[]> {
    const endpoint =
      VENDOR_BASE +
      CREDENTIAL_MANAGER.VENDOR_SERVICE.GET_VENDOR_SPECS.replace(
        '{vendorId}',
        vendorId
      );
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async addVendorCredentials(request: Partial<AddVendorCredRequest>) {
    const endpoint = VENDOR_BASE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getVendorCredentials(
    request: Partial<GetVendorCredential>
  ): Promise<VendorCredential[]> {
    const endpoint = VENDOR_BASE;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
}

export type VendorCredential = {
  id: string;
  createdAt: string;
  updatedAt: string;
  clientId: string;
  vendorId: string;
  accountType: string;
  credentialName: string;
  credentialValue: string;
  status: string;
};

export type VendorCredSpecs = {
  id: string;
  createdAt: string;
  updatedAt: string;
  vendorId: string;
  credentialName: string;
  secret: boolean;
  mandatory: boolean;
};

export type AddVendorCredRequest = {
  clientId: string;
  vendorId: string;
  status: string;
  accountType: string;
  vendorCredentialRequestList: VendorCredRequest[];
};

export type VendorCredRequest = {
  credentialName: string;
  credentialValue: string;
};

export type GetVendorCredential = {
  clientId: string;
  accountType: string;
  vendorId: string;
};
