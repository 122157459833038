import React from 'react';
import Vega_Text from '../../../../../common/v3/Vega_Text';
import { NEW_PRIMARY } from '../../../../../../constants/style';
import Vega_FormWrapper from '../../../../../common/v3/Vega_FormWrapper';
import { VerifyIcon } from '../../../icons/SupportIcons';
import { Box, Grid, Stack } from '@mui/material';
import { TextWrapper } from '../../common/TextWrapper';
import KycDetailsContainer from './KycDetailsContainer';
import { useSupport } from '../../../../../../providers/SupportProvider';

const KycIdentificationDetails = () => {
  const { loading, filteredCustomer } = useSupport();
  return (
    <>
      <KycDetailsContainer
        header="Identification"
        rightText="Verified by Signzy on 23/06/23 at 11:23 am"
      >
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name="birth country"
              value={filteredCustomer?.currentAddress.country ?? '--'}
              isBorder
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name="birth place"
              value={filteredCustomer?.currentAddress.city ?? '--'}
              isBorder
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name={`gender`}
              value={
                filteredCustomer?.gender
                  ? filteredCustomer?.gender === 'M'
                    ? 'Male'
                    : 'Female'
                  : '--'
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name={`gross annual income`}
              value="60,00,000"
              isBorder
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name="marital status"
              value="Unmarried"
              isBorder
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name="mobile number"
              value={filteredCustomer?.mobileNumber ?? '--'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name={`nationality`}
              value={filteredCustomer?.nationality ?? '--'}
              isBorder
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name="occupation"
              value="Product Manager"
              isBorder
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name="wealth source"
              value="Income"
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name={`address type`}
              value="Home"
              isBorder
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name="tax residence outside india"
              value="-"
              isBorder
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TextWrapper
              name="email address"
              value="sanchit.vegapay@gmail.com"
              loading={loading}
            />
          </Grid>

          <Grid item xs={6} sm={12}>
            <Vega_FormWrapper
              header={'Document'}
              sx={{
                position: 'relative',
              }}
            >
              <Stack direction={'row'} gap={1}>
                <Box
                  sx={{
                    border: '1px solid #E1E4EB',
                    borderRadius: '4px',
                    height: '200px',
                    width: '140px',
                  }}
                >
                  <Box
                    sx={{
                      height: '160px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      px: 1,
                    }}
                  >
                    <img
                      src={require('../../../../../../assets/images/pancard.avif')}
                      alt=""
                      width={'100%'}
                      height={'40%'}
                    />
                  </Box>
                  <Box sx={{ height: '40px', p: 1.5 }}>
                    <Vega_Text
                      text={'PAN 1'}
                      fontWeight={500}
                      fontSize={12}
                      color={NEW_PRIMARY.BLACK}
                    />
                  </Box>
                </Box>
              </Stack>
            </Vega_FormWrapper>
          </Grid>
        </Grid>
      </KycDetailsContainer>
    </>
  );
};

export default KycIdentificationDetails;
