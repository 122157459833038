import { Stack } from '@mui/material';
import AccHolderAppData from './AccHolderAppData';
import AccHolderOfferData from './AccHolderOfferData';
import AccHolderTransactingCust from './AccHolderTransactingCust';
import AccHolderAccLimitData from './AccHolderAccLimitData';

const AccountHolderAnalytics = () => {
  return (
    <Stack gap={3} mb={10}>
      <AccHolderAppData />
      <AccHolderOfferData />
      <AccHolderAccLimitData />
      <AccHolderTransactingCust />
    </Stack>
  );
};

export default AccountHolderAnalytics;
