import { Currency } from './cardRefund';

export interface VegaCustomerCard {
  id: string;
  accountId?: string;
  cardProcessorVendor: string;
  kitId: string;
  cardType: string;
  network: string;
  cardStatus: string;
  cardNumber?: string;
  lastFourDigits: string;
  pinStatus: string;
  replacedCardId?: string;
  nameOnCard: string;
  countryCode: string;
  mobileNumber: string;
  createdAt: string;
  updatedAt: string;
  cardCategory: string;
}

export interface CardBin {
  id: number;
  clientId: string;
  binName: string;
  binValue: string;
  binType: BinType;
  cardFormFactorId?: string;
  startKitNumber?: number;
  endKitNumber?: number;
  lastAllocatedKitNumber?: number;
  totalKits?: number;
  allocatedKits?: number;
  unallocatedKits?: number;
  onHoldKits?: number;
  availableKits?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface NewCardBin {
  id: number;
  clientId: string;
  binName: string;
  binValue: string;
  network: string;
  productType: string;
  binType: BinType;
  cardProcessorConfigId?: string;
  totalKits?: number;
  noOfKitsSold?: number;
  maxNoOfKits?: number;
  noOfKitsDamaged?: number;
  noOfKitsAllocated?: number;
  noOfKitsAvailable?: number;

  createdAt?: Date;
  updatedAt?: Date;
}
export interface BinSettings {
  programId: string;
  currency: Currency;
  binId: number;
  cardAllocated?: number;
}

export interface CardSettings {
  programId: string;
  cardDigit: number;
  expiryMonth: number;
  cvvType: string;
  cvvLength: number;
  physicalCardActivationProcess: string;
  virtualCardActivationProcess: string;
}
export interface AddOnCardConfiguration {
  programId: string;
  addOnCardSchemeName: string;
  supplementaryCardSchemeName: string;
  firstName: boolean;
  lastName: boolean;
  relationShip: boolean;
  address: boolean;
  phoneNumber: boolean;
  email: boolean;
  gender: boolean;
  panNo: boolean;
  aadhaarNo: boolean;
  addOnCardEcommerceStatus: boolean;
  addOnCardPosStatus: boolean;
  addOnCardCashWithdrawalStatus: boolean;
  addOnCardCount: number;
  supplementaryCardCount: number;
}

export interface BinAllocation {
  id: string;
  binId: number;
  binEntityType: any;
  entityId: string;
  kitStart: number;
  kitEnd: number;
  totalKits: number;
  allocatedKits: number;
  unallocatedKits: number;
  onHoldKits: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface PhysicalCardOrder {
  id: string;
  accountId: string;
  cardId: string;
  binId: number;
  clientId: string;
  trackingId: string;
  nameOnCard: string;
  nameOnLetter: string;
  nameOnEnvelop: string;
  customerAddress?: Partial<Address>;
  vendorAddress?: Partial<Address>;
  deliveryPartner: string;
  rtoAddress?: Partial<Address>;
  otpDelivery: boolean;
  physicalCardOrderStatus: string;
  eta: string;
  createdAt: string;
  updatedAt: string;
}

export interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
}

export interface CardBlockInfo {
  id: string;
  clientId: string;
  totalCardsCount: number;
  successBlockedCount: number | null;
  alreadyBlockedCount: number | null;
  doesNotExistCount: number;
  blockType: string;
  status: string;
  reportFileName: string;
  reportFileUrl: string;
  embossingFileName: string;
  embossingFileUrl: string;
  createdAt: string;
  updatedAt: string;
}

export type AddCardPayload = {
  binId: number;
  accountId: string;
  entityLevel: string;
  entityId: string;
  cardName: string;
  cardType: string;
  nameOnCard: string;
};

export interface CardDto {
  id: string;
  binId: number;
  productType: string;
  network: string | null;
  cardCategory: CardCategory | null;
  cardType: CustomerPreferenceType;
  cardStatus: CardStatus;
  cardNumber: string;
  kitId: string | null;
  cardProcessorVendor: string | null;
  lastFourDigits: string;
  expiryDate: string;
  customerId: string | null;
  accountId: string;
  programId: string | null;
  externalCustomerId: string | null;
  externalAccountId: string | null;
  pinStatus: string;
  replacedCardId: string | null;
  replacementReason: string | null;
  discardReasonId: string | null;
  cardAllotmentOrderId: string | null;
  cardDeliveryTrackingId: string | null;
  nameOnCard: string;
  atm: string;
  pos: string;
  ecom: string;
  international: string;
  dcc: string;
  contactless: string;
  dualOrSingleMessage: string | null;
  levelEntityDto: any | null;
  programDto: any | null;
  entityCode: string | null;
  createdAt: string;
  updatedAt: string;
  accountStatus?: string;
  programName?: string;

  domesticAtm: CustomerPreferenceStatus;
  domesticPos: CustomerPreferenceStatus;
  domesticEcom: CustomerPreferenceStatus;
  domesticContactless: CustomerPreferenceStatus;
  internationalAtm: CustomerPreferenceStatus;
  internationalPos: CustomerPreferenceStatus;
  internationalEcom: CustomerPreferenceStatus;
  internationalContactless: CustomerPreferenceStatus;

  domesticAtmLimit: number | null;
  domesticPosLimit: number | null;
  domesticEcomLimit: number | null;
  domesticContactlessLimit: number | null;
  internationalAtmLimit: number | null;
  internationalPosLimit: number | null;
  internationalEcomLimit: number | null;
  internationalContactlessLimit: number | null;
}

export interface ICardState {
  data: CardDto[];
  isLoading: boolean;
}

export enum CustomerPreferenceStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum CardLocationType {
  DOMESTIC = 'DOMESTIC',
  INTERNATIONAL = 'INTERNATIONAL',
}

export enum BinType {
  SHARED = 'SHARED',
  DEDICATED = 'DEDICATED',
}

export enum CvvType {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export enum CardCategory {
  PRIMARY_CARD = 'PRIMARY_CARD',
  SUPPLEMENT_CARD = 'SUPPLEMENT_CARD',
  ADD_ON_CARD = 'ADD_ON_CARD',
}

export enum CardActivationOptions {
  GREEN_PIN = 'GREEN_PIN',
  PIN_THROUGH_SMS = 'PIN_THROUGH_SMS',
  OTP_ON_MOBILE = 'OTP_ON_MOBILE',
  OTP_ON_EMAIL = 'OTP_ON_EMAIL',
  OTP_ON_MOBILE_AND_EMAIL = 'OTP_ON_MOBILE_AND_EMAIL',
}

export enum PhysicalCardOrderStatusType {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  PLACED = 'PLACED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PRINTING_STARTED = 'PRINTING_STARTED',
  PACKED = 'PACKED',
  DISPATCHED = 'DISPATCHED',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  DELIVERED = 'DELIVERED',
  RTO = 'RTO',
}

export enum CardStatus {
  ISSUED = 'ISSUED',
  REJECTED = 'REJECTED',
  ACTIVATED = 'ACTIVATED',
  TEMP_BLOCKED = 'TEMP_BLOCKED',
  PERM_BLOCKED = 'PERM_BLOCKED',
  ASSIGNED = 'ASSIGNED',
  IN_TRANSIT = 'IN_TRANSIT',
  IN_STOCK = 'IN_STOCK',
  REPLACED = 'REPLACED',
  BINDING = 'BINDING',
  BOOKED = 'BOOKED',
}

export enum CustomerPreferenceType {
  ATM = 'ATM',
  POS = 'POS',
  ECOM = 'ECOM',
  CONTACTLESS = 'CONTACTLESS',
}
