import axios from 'axios';
import { BASE_URL, OPEN_CREDIT } from './endpoints';

const OPEN_CRDIT_BASE = BASE_URL + OPEN_CREDIT.BASE;
const OPEN_CRDIT_INVOICE = OPEN_CRDIT_BASE + OPEN_CREDIT.INVOICE;
const OPEN_CRDIT_REPAYMENT = OPEN_CRDIT_BASE + OPEN_CREDIT.REPAYMENT;

export const fetchInvoiceListForPostpaidCard = (
  data: Record<string, string> | Object
) => {
  return axios.post(`${OPEN_CRDIT_INVOICE}/list`, data);
};
export const submitRePayment = (data: Record<string, string> | Object) => {
  return axios.post(OPEN_CRDIT_REPAYMENT, data);
};
export const fetchOpenCreditList = (data: Record<string, string> | Object) => {
  return axios.post(`${OPEN_CRDIT_BASE}/list`, data);
};
