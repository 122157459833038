import axios from 'axios';
import { CampaignRule } from '../types/RewardsLoyalty';
import { BASE_URL, REWARDS } from './endpoints';

const REWARDS_BASE = BASE_URL + REWARDS.BASE;
const RULE_URL = REWARDS_BASE + REWARDS.RULE;
export class CampaignRuleService {
  static async createRule(rule: CampaignRule): Promise<CampaignRule> {
    try {
      const endpoint = RULE_URL;
      const response = await axios.post(endpoint, rule);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateRule(rule: CampaignRule): Promise<CampaignRule> {
    try {
      const endpoint = RULE_URL;
      const response = await axios.put(endpoint, rule);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
