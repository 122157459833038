import { Stack } from '@mui/material';
import VegaLineChartV4 from '../../../common/Charts/VegaLineChartV4';
import { ACCENT, QUICK_ACCESS } from '../../../../constants/style';
import { PageHeadingTypo } from '../../../common/Typography';

const CustomerCountAndOverdueAnalytics = () => {
  return (
    <Stack direction="row" gap={2.5}>
      <Stack
        flex={1}
        p={2}
        gap={3}
        sx={{
          backgroundColor: QUICK_ACCESS.background[50],
          border: `1px solid ${QUICK_ACCESS.border[50]}`,
          borderRadius: '16px',
        }}
      >
        <PageHeadingTypo>Customer Count</PageHeadingTypo>
        <VegaLineChartV4
          YAxisLabels={{
            left: { text: 'Customer Count' },
          }}
          mainData={line1Data}
          linePropsArray={[{ stroke: ACCENT.violet[500], dataKey: 'amt' }]}
          XAxisData={{ dataKey: 'name' }}
          wrapperSx={{
            p: 2,
          }}
          respContainerData={{ height: 432 }}
        />
      </Stack>
      <Stack
        flex={1}
        p={2}
        gap={3}
        sx={{
          backgroundColor: QUICK_ACCESS.background[50],
          border: `1px solid ${QUICK_ACCESS.border[50]}`,
          borderRadius: '16px',
        }}
      >
        <PageHeadingTypo>Overdue Amount</PageHeadingTypo>
        <VegaLineChartV4
          YAxisLabels={{
            left: { text: 'Overdue Amount' },
          }}
          mainData={line2Data}
          linePropsArray={[{ stroke: ACCENT.pink[500], dataKey: 'amt' }]}
          XAxisData={{ dataKey: 'name' }}
          wrapperSx={{
            p: 2,
          }}
          respContainerData={{ height: 432 }}
        />
      </Stack>
    </Stack>
  );
};

const line1Data = [
  {
    name: 'X',
    amt: 1200,
  },
  {
    name: '1-30',
    amt: 900,
  },
  {
    name: '31-60',
    amt: 1100,
  },
  {
    name: '61-90',
    amt: 850,
  },
  {
    name: 'NPA',
    amt: 3400,
  },
];

const line2Data = [
  {
    name: 'X',
    amt: 1200,
  },
  {
    name: '1-30',
    amt: 900,
  },
  {
    name: '31-60',
    amt: 1100,
  },
  {
    name: '61-90',
    amt: 850,
  },
  {
    name: 'NPA',
    amt: 3400,
  },
];

export default CustomerCountAndOverdueAnalytics;
