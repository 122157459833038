import { SVGProps } from 'react';

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.81006 13.6155C3.07261 13.8803 3.42871 14.029 3.80001 14.029H12.2C12.5713 14.029 12.9274 13.8803 13.19 13.6155M8.00079 1.97119V9.97119M8.00079 9.97119L11.2008 6.91442M8.00079 9.97119L4.80079 6.91442"
      stroke={props.stroke ?? '#1047DC'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DownloadIcon;
