import { Divider, Switch, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/system';
import React from 'react';
import { PRIMARY } from '../../../constants/style';
import { IReportConfig } from '../../../types/reports';
import { Loading, VegaTextField } from '../../common';
import VegaSelect from '../../common/VegaSelect';
import Recipients from '../catalog/Recipients';
import ScheduleReportTime from '../catalog/ScheduleFrequency';
import ScheduleInputItem from '../catalog/ScheduleInputItem';
import { reportingMailOptions } from '../constants';

export type Props = {
  reportConfig: IReportConfig;
  setReportConfig: React.Dispatch<React.SetStateAction<IReportConfig>> | null;
};

function ScheduleModal({ reportConfig, setReportConfig }: Props) {

  const onChange = (key: keyof IReportConfig, value: any) => {
    console.log('onchange handler', key, value);
    if (setReportConfig !== null) {
      setReportConfig((pre) => {
        if (!pre) return pre;
        return {
          ...pre,
          [key]: value,
        };
      });
    }
  };

  return (
    <>
      {reportConfig ? (
        <Stack px={2}>
          <Grid container spacing={2}>
            <ScheduleInputItem name="Active">
              <Switch
                checked={reportConfig.status === 'ACTIVE'}
                size="small"
                color="success"
                onChange={(e) =>
                  onChange('status', e.target.checked ? 'ACTIVE' : 'INACTIVE')
                }
              />
            </ScheduleInputItem>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={3} alignItems="center" display={'flex'}>
                <Typography sx={{ mr: 2, my: 1 }}>Send Reports</Typography>
              </Grid>

              <Grid item xs={9} alignItems="center" display={'flex'}>
                <ScheduleReportTime
                  reportConfig={reportConfig}
                  setReportConfig={setReportConfig}
                />
              </Grid>
            </Grid>{' '}
            <Divider
              variant="fullWidth"
              sx={{ border: '.5px solid gray', width: '100%', my: 3 }}
            ></Divider>
            <Grid container spacing={2}>
              <Grid item xs={3} alignItems="center" display={'flex'}>
                <Typography sx={{ mr: 2, my: 1 }}>Send To</Typography>
              </Grid>
              <Grid item xs={9} alignItems="center" display={'flex'}>
                <Recipients
                  emails={reportConfig.recipients}
                  emailOptions={reportingMailOptions}
                  onChange={onChange}
                  selectedEmails={reportConfig.recipients}
                />
              </Grid>
            </Grid>
            <ScheduleInputItem name="Subject">
              <VegaTextField
                key="subject"
                type="text"
                onChange={(e) => onChange('subject', e.target.value)}
                value={reportConfig.subject}
                placeholder="Subject"
              ></VegaTextField>
            </ScheduleInputItem>
            <ScheduleInputItem name="Description">
              <TextField
                fullWidth
                multiline
                maxRows={4}
                focused
                type="text"
                key="description"
                onChange={(e) => onChange('description', e.target.value)}
                value={reportConfig.description}
                placeholder="Write Description...."
                sx={{
                  '& .MuiFormLabel-root': {
                    '&.Mui-focused': {
                      color: `${PRIMARY.main} !important`,
                    },
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#AFAFAF',
                      borderWidth: '1px',
                      borderRadius: '9px',
                    },
                    ':hover fieldset': {
                      borderColor: PRIMARY.main,
                    },
                  },
                }}
              />
            </ScheduleInputItem>
            <Divider
              variant="fullWidth"
              sx={{ border: '.5px solid gray', width: '100%', my: 3 }}
            ></Divider>
            <ScheduleInputItem name="Format">
              <VegaSelect
                size="small"
                key={'format'}
                selected={reportConfig.format}
                onChange={(selected) => onChange('format', selected)}
                options={['CSV', 'PDF']}
              ></VegaSelect>
            </ScheduleInputItem>{' '}
          </Grid>
        </Stack>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default ScheduleModal;
