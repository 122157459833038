import { createContext, useContext, useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import {
  addBinForClient,
  addCardFormFactorForClient,
  allocateBin,
  listBinAllocations,
  listBinForClient,
  listCardFormFactorForClient,
} from '../api/bin';
import { updateProgramBinId } from '../api/program';
import { BinCharterRoute, NESTED_VIEW } from '../routes';
import { IBinProgramListProps } from '../types/binType';
import { VegaBin, VegaNewBin } from '../types/client';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useClientAuth } from './ClientProvider';
import { useProgramData } from './ProgramDataProvider';
import { useSnackbar } from './SnackbarProvider';
import { VegaSelectOptionType } from '../components/common/v4/VegaSelectV4';

export interface BinCharterContextProps {
  currentRoute: string;
  bin: Partial<VegaBin>;
  binList: VegaNewBin[];
  binRange: IBinRangeProps;
  programOptions: VegaSelectOptionType[];
  binDetails: IBinProgramListProps[];
  page: number;
  rowCount: number;
  size: number;
  selecteddBinId: number | null;
  isProgramOpen: boolean;
  isAddBinOpen: boolean;
  isBinDetails: boolean;
  loading: boolean;
  listLoading: boolean;
  binData: any;
  selecteRoute: BinCharterRoute;
  navigateTo: (route: BinCharterRoute) => void;
  setBin: React.Dispatch<React.SetStateAction<Partial<VegaBin>>>;
  setBinRange: React.Dispatch<React.SetStateAction<IBinRangeProps>>;
  setIsProgramOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddBinOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBinDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBinId: React.Dispatch<React.SetStateAction<number | null>>;
  onHandleChange: (name: string, value: string | number) => void;
  onChange: (name: keyof IBinRangeProps, value: string | number) => void;
  getFormFactorIdForClient: (clientId: string) => void;
  getListBinForClient: (clientId: string) => void;
  getBinDetails: (binId: number) => void;
  openAddProgram: (id: string) => void;
  addProgram: () => void;
  addBin: () => void;
  isInputValid: () => void;
  toggleIsAddBinOpen: () => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

interface IBinRangeProps {
  programId: string;
  numberOfKits: number;
}

const BinCharterContext = createContext<BinCharterContextProps | null>(null);

export const useBinCharter = () =>
  useContext(BinCharterContext) as BinCharterContextProps;

export const BinCharterProvider = ({ children }: any) => {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentRoute, setCurrentRoute] = useState<string>(
    BinCharterRoute.BIN_CONFIGURATION
  );
  const [bin, setBin] = useState<Partial<VegaBin>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddBinOpen, setIsAddBinOpen] = useState<boolean>(false);
  const [isBinDetails, setIsBinDetails] = useState<boolean>(false);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const { clientId } = useClientAuth();
  const { setSelectedProgram, programs } = useProgramData();
  const [binList, setBinList] = useState<any>([]);
  const [binId, setBinId] = useState<any>('');
  const [binData, setBinData] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [size, setSize] = useState<number>(5);
  const [selecteddBinId, setSelectedBinId] = useState<number | null>(null);
  const [binRange, setBinRange] = useState<IBinRangeProps>({
    programId: '',
    numberOfKits: 0,
  });

  const [isProgramOpen, setIsProgramOpen] = useState<boolean>(false);
  const [cardFormFactorId, setCardFormFactorId] = useState<string | null>(null);
  const [binDetails, setBinDetails] = useState<IBinProgramListProps[]>([]);
  const [selecteRoute, setSelectRoute] = useState<BinCharterRoute>(
    BinCharterRoute.BIN_CONFIGURATION
  );

  const isInputValid = () => {
    let isNameValid = !!bin.binName && bin.binName.length > 0;
    let isValueValid = !!bin.binValue && bin.binValue.length == 6;
    let isTypeValid = !!bin.binType && bin.binType.length > 0;
    const isNetworkValid = !!bin.network && bin.network.length > 0;
    const isBinUsageValid = !!bin.productType && bin.productType.length > 0;

    return (
      isNameValid &&
      isValueValid &&
      isTypeValid &&
      isNetworkValid &&
      isBinUsageValid
    );
  };

  const toggleIsAddBinOpen = () => {
    setIsAddBinOpen(!isAddBinOpen);
  };

  const onHandleChange = (name: string, value: any) => {
    setBin((preVal: any) => ({
      ...preVal,
      [name]: value,
    }));
  };

  const addBin = async () => {
    if (isInputValid() == false) {
      return;
    }
    if (cardFormFactorId) {
      setLoading(true);
      await addBinForClient({
        clientId: clientId as string,
        binName: bin.binName,
        binType: bin.binType,
        binValue: bin.binValue,
        cardProcessorConfigId: cardFormFactorId,
        productType: bin.productType,
        network: bin.network,
        numberOfKits: 10000,
      })
        .then(async (res) => {
          setBin({
            binName: '',
            binValue: '',
          });
          setSnackbar('Add bin successfull');
          await getListBinForClient(clientId as string);
          setLoading(false);
        })
        .catch((err) => {
          setSnackbar(getErrorMessageFromErrorObj(err), 'error');
          setLoading(false);
        });
    }
  };

  const getListBinForClient = async (id: string) => {
    setListLoading(true);
    try {
      const response = await listBinForClient(id, page, size);
      setRowCount(response.data.totalItems);
      const programData = await getProgramData(response.data.records);
      if (programData) {
        setBinList(programData);

        setListLoading(false);
      } else {
        setListLoading(false);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setListLoading(false);
    }
  };
  console.log('listLoading', listLoading);
  const onPageChange = (page: number) => {
    setPage(page);
  };
  const onPageSizeChange = (pageSize: number) => {
    setSize(pageSize);
  };

  const programOptions: VegaSelectOptionType[] = programs?.map((program) => ({
    name: program.name,
    id: program.programId,
  }));

  const onChange = (name: keyof IBinRangeProps, value: any) => {
    setBinRange((preVal: IBinRangeProps) => ({
      ...preVal,
      [name]: value,
    }));
  };

  const addProgram = async () => {
    await allocateBin(binId, binRange?.programId, binRange.numberOfKits)
      .then(async (res: any) => {
        await getListBinForClient(clientId as string);
        setIsProgramOpen(!isProgramOpen);
        setSelectedProgram(programs[0]);
        updateBinIdInProgram(binId, binRange?.programId);
        setBinRange({
          programId: '',
          numberOfKits: 0,
        });
      })
      .catch((err: any) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        setIsProgramOpen(!isProgramOpen);
      });
  };

  function updateBinIdInProgram(binId: number, programId?: string) {
    if (!programId) return;
    updateProgramBinId({ binId: binId, programId: programId })
      .then((res) => {})
      .catch((err) => console.error(err));
  }

  const openAddProgram = (data: any) => {
    setBinId(data.id);
    setBinData(data);
    setIsProgramOpen(!isProgramOpen);
  };

  const getBinDetails = async (binId: number) => {
    try {
      const response = await listBinAllocations(binId);
      setBinDetails(response.data.records);
      return response.data.records;
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getProgramData = async (data: any) => {
    try {
      const promises = data.map(async (val: any) => {
        let dats = await getBinDetails(val.id);
        return { ...val, programName: dats };
      });
      const promiseData = Promise.all(promises);
      return promiseData;
    } catch (error) {}
  };

  const getFormFactorIdForClient = (clientId: string) => {
    listCardFormFactorForClient(clientId as string).then((res) => {
      if (!res.data || res?.data?.length == 0) {
        addCardFormFactorForClient(clientId as string).then((res) => {
          setCardFormFactorId(res.data.id);
        });
      } else {
        setCardFormFactorId(res.data[0].id);
      }
    });
  };
  const SELECTED_VIEW = 'view';
  const navigateTo = (route: BinCharterRoute) => {
    // setSearchParams(createSearchParams({ [SELECTED_VIEW]: route }));
    setSelectRoute(route);
  };

  // useEffect(() => {
  //   const params = new URLSearchParams(search);
  //   const queryParams = Object.fromEntries(params.entries());
  //   const view = queryParams.view;
  //   setCurrentRoute(view);
  // }, [searchParams]);

  // useEffect(() => {
  //   navigateTo(BinCharterRoute.BIN_CONFIGURATION);
  // }, []);
  return (
    <BinCharterContext.Provider
      value={{
        currentRoute: currentRoute,
        bin: bin,
        binList: binList,
        binData: binData,
        binRange: binRange,
        binDetails: binDetails,
        page: page,
        size: size,
        rowCount: rowCount,
        selecteddBinId: selecteddBinId,
        isProgramOpen: isProgramOpen,
        loading: loading,
        listLoading: listLoading,
        isAddBinOpen: isAddBinOpen,
        isBinDetails: isBinDetails,
        programOptions: programOptions,
        selecteRoute,
        navigateTo: navigateTo,
        setBin: setBin,
        setBinRange: setBinRange,
        setIsProgramOpen: setIsProgramOpen,
        setIsAddBinOpen: setIsAddBinOpen,
        setIsBinDetails: setIsBinDetails,
        setSelectedBinId: setSelectedBinId,
        onHandleChange: onHandleChange,
        onChange: onChange,
        openAddProgram: openAddProgram,
        addProgram: addProgram,
        addBin: addBin,
        isInputValid: isInputValid,
        toggleIsAddBinOpen: toggleIsAddBinOpen,
        getFormFactorIdForClient: getFormFactorIdForClient,
        getListBinForClient: getListBinForClient,
        getBinDetails: getBinDetails,
        onPageChange: onPageChange,
        onPageSizeChange: onPageSizeChange,
      }}
    >
      {children}
    </BinCharterContext.Provider>
  );
};
