/** @format */

import { Box, Typography } from '@mui/material';
import { VegaCard } from '../common';
import VegaHorizontalSelector, {
  VegaHorizontalSelectorDataType,
} from '../common/VegaHorizontalSelector';
import { PolicyMakerCheckerActions } from './PolicyMakerCheckerActions';
import Vega_HeaderWrapper from '../common/v3/Vega_HeaderWrapper';

type Props = {
  selected: VegaHorizontalSelectorDataType;
  setSelected: (policy: VegaHorizontalSelectorDataType) => void;
  policies: VegaHorizontalSelectorDataType[];
  onAllPolicyClick: () => void;
  onSubmitToMakerClick: () => void;
  onSubmitToCheckerClick: () => void;
};

const PolicySelection = ({
  selected,
  setSelected,
  policies,
  onAllPolicyClick,
  onSubmitToCheckerClick,
  onSubmitToMakerClick,
}: Props) => {
  return (
    <VegaCard>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Vega_HeaderWrapper
          text="Policy Configurations"
          subText="Easily configure program policies"
        />

        <PolicyMakerCheckerActions
          onAllPolicyClick={onAllPolicyClick}
          onSubmitToCheckerClick={onSubmitToCheckerClick}
          onSubmitToMakerClick={onSubmitToMakerClick}
        />
      </Box>
      <VegaHorizontalSelector
        data={policies}
        selected={selected}
        onChange={setSelected}
      />
    </VegaCard>
  );
};

export default PolicySelection;
