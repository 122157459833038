import { Dispatch, createContext, useContext, useState } from 'react';
import PartnerManagementRevamp from '../../../components/PartnerManagement/PartnerManagementRevamp';
import { EntityDto } from '../../../types/entity';

interface IPartnerManagementContextProps {
  currState: PartnerPageState;
  setCurrState?: Dispatch<React.SetStateAction<PartnerPageState>>;
  partner?: EntityDto;
  setPartner?: Dispatch<React.SetStateAction<EntityDto | undefined>>;
}

export type PartnerPageState =
  | 'brand'
  | 'brand-form-add'
  | 'brand-form-edit'
  | 'partner';

export const PartnerManagementContext =
  createContext<IPartnerManagementContextProps>({
    currState: 'brand',
  });

export const usePartnerMgmtContext = () => useContext(PartnerManagementContext);

const PartnerManagementWrap = () => {
  const [currState, setCurrState] = useState<PartnerPageState>('brand');
  const [partner, setPartner] = useState<EntityDto | undefined>();

  return (
    <PartnerManagementContext.Provider
      value={{
        currState,
        setCurrState,
        partner,
        setPartner,
      }}
    >
      <PartnerManagementRevamp />
    </PartnerManagementContext.Provider>
  );
};

export default PartnerManagementWrap;
