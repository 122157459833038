import { Stack } from '@mui/material';
import { NEW_PRIMARY } from '../../../../../../../constants/style';
import Vega_Text from '../../../../../../common/v3/Vega_Text';

interface IProps {
  header: string;
  children: JSX.Element;
}

const DelinquncyHistoryContainer = ({ header, children }: IProps) => {
  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Vega_Text
            text={header}
            fontWeight={600}
            color={NEW_PRIMARY.BLACK}
            fontSize={17}
          />
        </Stack>
      </Stack>
      {children}
    </>
  );
};

export default DelinquncyHistoryContainer;
