/** @format */

import axios from 'axios';
import {
  AccountDelinquencySummary,
  DelinquencyReport,
  DelinquencySummary,
  VegaAgent,
} from '../types/collection';
import { BASE_URL, COLLECTION } from './endpoints';

const COLLECTION_BASE = BASE_URL + COLLECTION.BASE;

export const getCollection = (data: any) => {
  console.log('collection req', {
    ...data,
    isDownload: false,
    page: 0,
    pageSize: 10000,
  });
  return axios.post(COLLECTION_BASE + COLLECTION.QUERY_COLLECTIONS, {
    ...data,
    isDownload: false,
    page: 0,
    pageSize: 10000,
  });
};

export const modifyResponse = (response: any) => {
  if (response.status === 200) {
    return { success: true, result: response.data };
  } else {
    return { success: false, error: 'message', result: null };
  }
};

export async function getProgramDelinquency(data: {
  programId: string;
}): Promise<DelinquencyReport[]> {
  try {
    const endpoint =
      COLLECTION_BASE +
      COLLECTION.GET_PROGRAM_DELINQUENCY.replace('{programId}', data.programId);
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getDelinquencySummaryByBucket(data: {
  dpd: string;
}): Promise<DelinquencySummary> {
  try {
    const endpoint = COLLECTION_BASE + COLLECTION.GET_SUMMARY_BY_DPD;
    const response = await axios.post(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAgents(): Promise<VegaAgent[]> {
  try {
    const endpoint = COLLECTION_BASE + COLLECTION.GET_AGENTS;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getDpdBuckets(): Promise<string[]> {
  try {
    const endpoint = COLLECTION_BASE + COLLECTION.GET_BUCKETS;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAccountDelinquencySummary(data: {
  accountId: string;
}): Promise<AccountDelinquencySummary> {
  try {
    const endpoint = COLLECTION.ACCOUNT_DELINQUENCY_SUMMARY.replace(
      '{accountId}',
      data.accountId
    );
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}
