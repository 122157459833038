import axios from 'axios';
import { BASE_URL, LOS } from '../api/endpoints';

const BASE_URL_V1 = BASE_URL + LOS.BASE;
const BASE_URL_V2 = BASE_URL + LOS.BASE_V2;

export class ApplicationServices {
  static async getApplicationForCustomer(
    data: Partial<GetAllApplicationsForAgentApiData>
  ): Promise<PaginatedResponse<CustomerApplication>> {
    try {
      const endpoint = BASE_URL_V2 + LOS.GET_APPLICATION_BY_FILTER;
      const response = await axios.get(endpoint, { params: data });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async fetchPanDetails(panId: string): Promise<CustomerPanData> {
    try {
      const endpoint =
        BASE_URL_V1 + LOS.FETCH_PAN_BY_PAN_ID.replace('{panId}', panId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async fetchMaskPanDetails(panId: string): Promise<CustomerPanData> {
    try {
      const endpoint =
        BASE_URL_V1 + LOS.FETCH_MASK_PAN_BY_PAN_ID.replace('{panId}', panId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getAadhaarDetailsForInfoId(
    infoId: string
  ): Promise<CustomerAadharData> {
    try {
      const endpoint =
        BASE_URL_V1 +
        LOS.FETCH_AADHAAR_BY_ADHAR_INFO_ID.replace('{aadhaarInfoId}', infoId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export interface CustomerPanData {
  id: string;
  applicationId: string;
  customerId: string;
  name: string;
  number: string;
  typeOfHolder: string;
  status: string;
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  panStatus: string;
  panStatusCode: string;
  aadhaarSeedingStatus: string;
  aadhaarSeedingStatusCode: string;
  lastUpdatedOn: string;
  createdAt: string;
  updatedAt: string;
  valid: boolean;
  individual: boolean;
}

export interface PaginatedResponse<T> {
  records: T[];
  pageNumber: number;
  numberOfItems: number;
  totalPages: number;
  totalItems: number;
}
export interface CustomerApplication {
  id: string;
  programId: string;
  customerId: string;
  customerName: string;
  status: string;
  applicationState: string;
  programApplicationFlowId: string;
  losEntityType: string;
  basicInfo: CustomerApplicationBasicInfo;
  currentMemberIndex: number;
  members: ApplicationMember[];
  companyPanId: any;
  companyDetails: CompanyDetailsData;
  collateralDetails: CollateralDetails;
  financialDetails: FinancialDetails;
  documentationDetails: ElectricityDetails;
  agreement: any;
  bankDetails: any;
  agentId: string;
  createdAt: any;
  updatedAt: any;
  panAccepted: boolean;
  phone: string;
}
export interface CustomerApplicationBasicInfo {
  userType: string;
  subType?: string;
  email?: string;
  loanType: string;
  amount: number;
  tenure: number;
  id?: null | string;
}

export interface ApplicationMember {
  id: string;
  basicInfo: ApplicationMemberBasicInfo;
  panInfoId: string;
  aadhaarInfoId: string;
  selfieInfoId: string;
  designation: string;
  acceptAadhaarTimeStamp: string;
  acceptPanTimeStamp: string;
  acceptBureauPanTimeStamp: string;
}

export interface ApplicationMemberBasicInfo {
  name: string;
  designation: string;
  mobileNumber: string;
  email: string;
}

export type CompanyDetailsData = {
  id: string;
  applicationId: string;
  companyName: string;
  gstNo: string;
  documents: ApplicationDocument[];
};

export type CompanyPreviewData = {
  companyName: string;
  gstNumber: string;
  gstCertification: ApplicationDocument[];
  certificateOfIncorporation: ApplicationDocument[];
  moa: ApplicationDocument[];
  aoa: ApplicationDocument[];
  partnershipFirmDeed: ApplicationDocument[];
};

export type ElectricityDetails = {
  id: string;
  applicationId: string;
  electricityBoard: string;
  electricityBillNo: string;
  documents: ApplicationDocument[];
};

export interface ElectricityBoard {
  id: string;
  state: string;
  serviceProvider: string;
  code: string;
}
export interface Gurantor {
  name: string;
  relationShip: string;
  email: string;
  panCard: string;
  guaranteeType: string;
  valueOfGuarantee: string;
}

export interface CollateralTypeData {
  type: string;
  amount: string;
}

export type CollateralDetails = {
  loanType: string;
  collateralDetails: CollateralTypeData[];
  guaranteeDetails: Gurantor[];
};

export type ApplicationDocument = {
  id: string;
  documentCategory: string;
  documentType: string;
  documentName: string;
};

export type LosDocument = {
  url: string;
  name: string;
  requestedAt: string;
  expiryAt: string;
  validityInMinutes: number;
  metaData: Record<string, any>;
};

export type FinancialDetails = {
  id: string;
  applicationId: string;
  employerDetail: string;
  documents: ApplicationDocument[];
};

export type GetAllApplicationsForHQApiData = {
  status: string;
  page: number;
  pageSize: number;
  sortBy: string;
  sortDir: string;
  programId: string;
};

export type GetAllApplicationsForAgentApiData = {
  agentId: string;
  status: string;
  page: number;
  pageSize: number;
  sortBy: string;
  customerId: string;
  sortDir: string;
};

export interface CustomerAadharData {
  id: string;
  createdAt: string;
  updatedAt: string;
  customerId: string;
  applicationId: string;
  name: string;
  uid: string;
  dob: string;
  gender: string;
  address: string;
  photo: string;
  splitAddress: string;
  eaadhaar: true;
  status: 'APPROVED';
}

export type FetchPanDetailsRequestData = {
  panNumber: string;
};
export type FetchGSTDetailsRequestData = {
  gstNumber: string;
};

const base64ToBlob = async (base64Url: string): Promise<Blob> => {
  const response = await fetch(base64Url);
  const data = await response.blob();
  return data;
};
