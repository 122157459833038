import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { updateEntityStatus } from '../api/entityAuth';
import {
  addOrUpdatePolicy,
  fetchAllPDDList,
  fetchAllEffectivePolicesForProgram,
  fetchPolicy,
  getDefaultPolicy,
  submitAllPoliciesToChecker,
  fetchAllPolicesForProgram,
} from '../api/policy';
import { DRAFT_POLICY_ID } from '../types/defaultPolicy';
import {
  EntityAuthStatus,
  EntityComment,
  EntityCommentStatus,
  EntityStatus,
  UserMakerCheckerRole,
} from '../types/entityAuth';
import {
  AnyPolicyType,
  PolicySelections,
  PolicyStatus,
  PolicyType,
} from '../types/policy';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useProgramData } from './ProgramDataProvider';
import {
  MakerCheckerUserProps,
  useMakerCheckerEntity,
} from './MakerCheckerEntityProvider';
import { useSnackbar } from './SnackbarProvider';
import { useClientAuth } from './ClientProvider';

export type PolicyContextType = {
  policySelections: PolicySelections;
  fetchedPolicies: any;
  fetchAllPolicies: (user: MakerCheckerUserProps) => Promise<void>;
  getDefaultPolicy: (policyType: PolicyType) => Promise<AnyPolicyType | null>;
  fetchPolicyOfType: (type: PolicyType) => Promise<AnyPolicyType>;
  fetchPddList: () => Promise<void>;
  addOrUpdatePolicy: (
    policy: AnyPolicyType,
    policyType: string
  ) => Promise<AnyPolicyType>;
  pddList: any;
  areAllPoliciesApproved: boolean;
  canEnableMakerActions: () => boolean;
  canEnableCheckerActions: () => boolean;
  loading: boolean;
  loggedInMakerCheckerUser?: MakerCheckerUserProps | null;
  controlRole: UserMakerCheckerRole;
  //   Entity
  updatePolicyEntityStatus: (data: {
    updatedStatus: EntityStatus;
    policyId: string;
  }) => Promise<EntityAuthStatus>;
  submitPoliciesToMaker: () => Promise<any>;
  submitPoliciesToChecker: () => Promise<any>;
  entityAuthStatuses: Map<string, EntityAuthStatus>;
  addCommentOnPolicy: (data: {
    policyId: string;
    comment: string;
  }) => Promise<EntityComment>;
  updateCommentOnPolicy: (data: {
    policyId: string;
    commentId: string;
    comment: string;
  }) => Promise<EntityComment>;
  checkPolicyEditable: (policy?: AnyPolicyType) => Promise<boolean>;
  checkPolicyStatusUpdatable: (policy?: AnyPolicyType) => Promise<boolean>;
};

const INITIAL_POLICY_SELECTION: PolicySelections = {
  BILLING: null,
  MAD: null,
  ACCOUNT: null,
  TAX: null,
  CHARGES: null,
  INTEREST: null,
  REPAYMENT_WATERFALL: null,
  TRANSACTION_TO_INSTALLMENT: null,
  TRANSACTION: null,
};

const PolicyContext = createContext<PolicyContextType>({
  fetchedPolicies: null,
  loading: false,
  policySelections: INITIAL_POLICY_SELECTION,
  fetchAllPolicies: () => Promise.reject(),
  fetchPolicyOfType: () => Promise.reject(),
  fetchPddList: () => Promise.reject(),
  addOrUpdatePolicy: () => Promise.reject(),
  pddList: [],
  canEnableCheckerActions: () => false,
  canEnableMakerActions: () => false,
  areAllPoliciesApproved: false,
  updatePolicyEntityStatus: () => Promise.reject(),
  submitPoliciesToMaker: () => Promise.reject(),
  submitPoliciesToChecker: () => Promise.reject(),
  entityAuthStatuses: new Map(),
  checkPolicyEditable: () => Promise.resolve(false),
  checkPolicyStatusUpdatable: () => Promise.resolve(false),
  addCommentOnPolicy: () => Promise.reject(),
  updateCommentOnPolicy: () => Promise.reject(),
  controlRole: UserMakerCheckerRole.NOT_DEFINED,
  getDefaultPolicy: () => Promise.reject(),
});

export const usePolicy = () => {
  return useContext(PolicyContext) as PolicyContextType;
};

const BE_POLICY_TYPE = 'policy_type';

export const PolicyProvider = (props: { children: ReactNode }) => {
  const [policySelections, setPolicySelections] = useState<PolicySelections>(
    INITIAL_POLICY_SELECTION
  );
  const [areAllPoliciesApproved, setAreAllPoliciesApproved] =
    useState<boolean>(true);
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchedPolicies, setFetchedPolicies] = useState<any>({});
  const [pddList, setPDDList] = useState<any>([]);
  const { selectedProgram } = useProgramData();
  const { clientId } = useClientAuth();
  const {
    user,
    updateEntityStatusInCache,
    submitEntitiesToMaker,
    submitEntitiesToChecker,
    fetchStatusForEntities,
    entityAuthStatuses,
    clearEntityStatusCache,
    controlRole,
    areChangesToEntityAllowed,
    canChangeStatusOfEntity,
    getEntityAuthStatus,
    addComment,
    updateComment,
  } = useMakerCheckerEntity();

  const updateInFetchedPolicies = (
    policy: AnyPolicyType,
    policyType: string
  ) => {
    setFetchedPolicies({ ...fetchedPolicies, [policyType]: policy });
    setPolicySelections((prevState) => {
      return {
        ...prevState,
        [policyType]: policy,
      };
    });
  };

  async function _fetchPolicyOfType(type: PolicyType): Promise<AnyPolicyType> {
    const programId = selectedProgram?.programId;
    if (!programId) {
      throw Error('Program Id Not Found');
    }
    try {
      setLoading(true);
      const response = await fetchPolicy(programId, type);
      const createdPolicy = response.data;
      updateInFetchedPolicies(createdPolicy, type);
      return createdPolicy;
    } catch (error) {
      // console.error('Failed to get policy ', error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function _fetchAllPolicies(user: MakerCheckerUserProps) {
    const programId = selectedProgram?.programId;
    if (!programId) {
      throw Error('Program id Not Found');
    }
    try {
      setLoading(true);
      clearEntityStatusCache();
      const response = await fetchAllPolicesForProgram(
        selectedProgram.programId
      );
      const policiesForProgram = response.data.all_policies as AnyPolicyType[];
      const areAllPoliciesApproved = response.data.is_all_policies_approved;
      setAreAllPoliciesApproved(areAllPoliciesApproved);
      const allPolicies: any = {};
      policiesForProgram.forEach((policy) => {
        allPolicies[policy[BE_POLICY_TYPE]] = policy;
      });
      const defaultPolicySelections = {
        BILLING: allPolicies[PolicyType.BILLING],
        MAD: allPolicies[PolicyType.MAD],
        ACCOUNT: allPolicies[PolicyType.ACCOUNT],
        TAX: allPolicies[PolicyType.TAX],
        CHARGES: allPolicies[PolicyType.CHARGES],
        INTEREST: allPolicies[PolicyType.INTEREST],
        REPAYMENT_WATERFALL: allPolicies[PolicyType.REPAYMENT_WATERFALL],
        TRANSACTION_TO_INSTALLMENT:
          allPolicies[PolicyType.TRANSACTION_TO_INSTALLMENT],
        TRANSACTION: allPolicies[PolicyType.TRANSACTION],
      };
      await fetchAllPolicyStatus(defaultPolicySelections, user);
      await _fetchPDDList();
      setFetchedPolicies(allPolicies);
      setPolicySelections(defaultPolicySelections);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function _addOrUpdatePolicy(
    policy: AnyPolicyType,
    policyType: string
  ): Promise<AnyPolicyType> {
    try {
      const isUpdatingPolicy =
        !!policy.policy_id && policy.policy_id !== DRAFT_POLICY_ID;
      const updatedPolicy = {
        ...policy,
        policy_id: isUpdatingPolicy ? policy.policy_id : undefined,
      };
      setLoading(true);
      updatedPolicy.client_id = clientId;
      const res = await addOrUpdatePolicy(
        selectedProgram?.programId as string,
        updatedPolicy
      );
      const responsePolicy = res.data.policy as AnyPolicyType;
      setPolicySelections((prevState) => ({
        ...prevState,
        [policyType]: responsePolicy,
      }));
      setSnackbar(isUpdatingPolicy ? 'Policy Updated' : 'Policy Added!');
      return responsePolicy;
    } catch (err) {
      setSnackbar(
        getErrorMessageFromErrorObj(err, 'Failed to add policy'),
        'error'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function _fetchPDDList() {
    const programId = selectedProgram?.programId;
    if (!programId) {
      throw new Error('Program id Not found');
    }
    try {
      const response = await fetchAllPDDList(programId);
      setPDDList(response.data);
      setLoading(false);
    } catch (error) {
      throw error;
    }
  }

  async function _updatePolicyEntityStatus(data: {
    updatedStatus: EntityStatus;
    policyId: string;
  }): Promise<EntityAuthStatus> {
    const policyId = data.policyId;
    if (!selectedProgram || !policyId || !user) {
      console.error(
        `Selected Program ${selectedProgram} <> Policy Id ${policyId} <> user ${user}`
      );
      throw Error('Failed to update policy entity status');
    }
    setLoading(true);
    try {
      const response = await updateEntityStatus({
        userId: user.userId,
        programId: selectedProgram.programId,
        entityId: policyId,
        status: data.updatedStatus,
      });
      updateEntityStatusInCache({ entityId: policyId, authStatus: response });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function _submitEntitiesToChecker(): Promise<any> {
    const programId = selectedProgram?.programId;
    const userId = user?.userId;
    if (!programId || !userId) {
      console.error('Cannot find ProgramId ' + programId + ' userId ' + userId);
      return;
    }
    setLoading(true);

    const entitiesExists = entityAuthStatuses.size > 0;

    if (entitiesExists) {
      const entityIds: string[] = Array.from(entityAuthStatuses.values()).map(
        (item) => item.id
      );
      const response = await submitEntitiesToChecker(entityIds);
      if (user) {
        _fetchAllPolicies(user);
      }
      setSnackbar('Submitted to Checker', 'success');
      setLoading(false);
      return;
    }

    return _submitDraftPoliciesToChecker(programId, userId);
  }

  async function _submitDraftPoliciesToChecker(
    programId: string,
    userId: string
  ): Promise<any> {
    try {
      const response = await submitAllPoliciesToChecker({
        programId: programId,
        userId: userId,
      });
      if (user) {
        _fetchAllPolicies(user);
      }
      setSnackbar('Submitted to checker', 'success');
      return response;
    } catch (error) {
      setSnackbar('Failed to submit to checker', 'error');
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function _submitEntitiesToMaker(): Promise<any> {
    const entityIds: string[] = Array.from(entityAuthStatuses.values()).map(
      (item) => item.id
    );
    try {
      setLoading(true);
      const response = await submitEntitiesToMaker(entityIds);
      if (user) {
        _fetchAllPolicies(user);
      }

      setSnackbar('Submitted to Maker', 'success');
      return response;
    } catch (error) {
      setSnackbar('Failed to submit to Maker', 'error');
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function _addCommentOnPolicy(data: {
    policyId: string;
    comment: string;
  }): Promise<EntityComment> {
    try {
      const authStatus = entityAuthStatuses.get(data.policyId);
      if (!authStatus) {
        return Promise.reject('Auth request not found for policy');
      }

      var commentResponse = await _addOrUpdateComment({
        authStatus: authStatus,
        comment: data.comment,
        policyId: data.policyId,
      });

      const updateResponse = await _updatePolicyEntityStatus({
        policyId: data.policyId,
        updatedStatus: EntityStatus.DRAFT_REJECTED,
      });

      const updatedAuthStatus = { ...updateResponse };
      updatedAuthStatus.status = EntityStatus.DRAFT_REJECTED;
      const commentsList = updatedAuthStatus.commentsList ?? [];
      commentsList.unshift(commentResponse);
      updatedAuthStatus.commentsList = commentsList;
      updateEntityStatusInCache({
        entityId: data.policyId,
        authStatus: updatedAuthStatus,
      });
      return commentResponse;
    } catch (error) {
      setSnackbar('Failed to add comment', 'error');
      console.error(error);
      throw error;
    }
  }

  async function _addOrUpdateComment(data: {
    authStatus: EntityAuthStatus;
    comment: string;
    policyId: string;
  }) {
    const existingComments = data.authStatus.commentsList;
    if (existingComments.length > 0) {
      const latestComment = existingComments[0];
      if (latestComment.status == EntityCommentStatus.DRAFT) {
        return await _updateCommentOnPolicy({
          comment: data.comment,
          policyId: data.policyId,
          commentId: latestComment.id,
        });
      }
    }
    return await addComment({
      comment: data.comment,
      authorizationRequestId: data.authStatus.id,
    });
  }

  async function _updateCommentOnPolicy(data: {
    policyId: string;
    commentId: string;
    comment: string;
  }): Promise<EntityComment> {
    try {
      const authStatus = entityAuthStatuses.get(data.policyId);
      if (!authStatus) {
        return Promise.reject('Auth request not found for policy');
      }

      const response = await updateComment({
        comment: data.comment,
        commentId: data.commentId,
      });

      const updatedCommentList = authStatus.commentsList.map((item) => {
        if (item.id == data.commentId) {
          return response;
        }
        return item;
      });
      const updatedAuthStatus = { ...authStatus };
      updatedAuthStatus.commentsList = updatedCommentList;
      updateEntityStatusInCache({
        entityId: data.policyId,
        authStatus: updatedAuthStatus,
      });
      return response;
    } catch (error) {
      setSnackbar('Failed to add comment', 'error');
      console.error(error);
      throw error;
    }
  }

  const fetchAllPolicyStatus = async (
    policies: PolicySelections,
    user?: MakerCheckerUserProps | null
  ) => {
    const items = Object.values(policies);
    const ids: string[] = [];
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      const policyId = element?.policy_id;
      if (policyId) {
        ids.push(element.policy_id);
      }
    }
    try {
      await fetchStatusForEntities(ids, user);
    } catch (error) {
      console.log(error);
    }
  };

  async function _getDefaultPolicy(
    policyType: PolicyType
  ): Promise<AnyPolicyType | null> {
    try {
      const response = await getDefaultPolicy({ policyType: policyType });
      response.policy_id = DRAFT_POLICY_ID;
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async function _canEditPolicy(policy?: AnyPolicyType): Promise<boolean> {
    var entityStatus = EntityStatus.DRAFT;
    const policyId = policy?.policy_id;
    const entityMappingStatus = policy?.entity_policy_mapping_status;
    if (entityMappingStatus == PolicyStatus.DRAFT) {
      const isMaker = user?.role == UserMakerCheckerRole.MAKER;
      return isMaker;
    }
    if (!policyId) return false;
    if (policyId == DRAFT_POLICY_ID) {
      const isMaker = user?.role == UserMakerCheckerRole.MAKER;
      return isMaker;
    }
    try {
      const entityAuth = await getEntityAuthStatus({
        entityId: policyId,
        cachedValue: true,
      });
      updateEntityStatusInCache({ entityId: policyId, authStatus: entityAuth });
      entityStatus = entityAuth.status;
      if (areAllPoliciesApproved) {
        return false;
      } else {
        const canEditEntity = areChangesToEntityAllowed(entityStatus);
        return canEditEntity;
      }
    } catch (error) {
      throw error;
    }
  }

  async function _canUpdateStatusOfPolicy(
    policy?: AnyPolicyType
  ): Promise<boolean> {
    var entityStatus = EntityStatus.DRAFT;
    const policyId = policy?.policy_id;
    const entityMappingStatus = policy?.entity_policy_mapping_status;
    if (entityMappingStatus == PolicyStatus.DRAFT) {
      const isMaker = user?.role == UserMakerCheckerRole.MAKER;
      return isMaker;
    }
    if (!policyId) return false;
    if (policyId == DRAFT_POLICY_ID) {
      const isMaker = user?.role == UserMakerCheckerRole.MAKER;
      return isMaker;
    }

    try {
      const entityAuth = await getEntityAuthStatus({
        entityId: policyId,
        cachedValue: true,
      });

      updateEntityStatusInCache({ entityId: policyId, authStatus: entityAuth });
      entityStatus = entityAuth.status;
      if (areAllPoliciesApproved) {
        return false;
      } else {
        const canUpdateStatus = canChangeStatusOfEntity(entityStatus);
        return canUpdateStatus;
      }
    } catch (error) {
      throw error;
    }
  }

  function doesAllPoliciesHaveEitherEntityStatus(
    allowedStatuses: EntityStatus[]
  ): boolean {
    for (const policy in policySelections) {
      const policyKey = policy as keyof PolicySelections;
      const currentPolicy = policySelections[policyKey];
      const policyId = currentPolicy?.policy_id;
      if (!currentPolicy || !policyId) return false;
      const currentPolicyStatus = entityAuthStatuses.get(policyId);
      if (!currentPolicyStatus) {
        return false;
      }
      if (!allowedStatuses.includes(currentPolicyStatus.status)) return false;
    }
    return true;
  }

  function doesAllPoliciesHaveEitherStatus(
    allowedStatuses: PolicyStatus[]
  ): boolean {
    for (const policy in policySelections) {
      const policyKey = policy as keyof PolicySelections;
      const currentPolicy = policySelections[policyKey];
      if (!currentPolicy) return false;
      // const currentPolicyStatus = currentPolicy.status;
      const currentPolicyStatus = currentPolicy.entity_policy_mapping_status;
      if (!currentPolicyStatus) {
        return false;
      }
      if (!allowedStatuses.includes(currentPolicyStatus)) return false;
    }
    return true;
  }

  const _canEnableMakerActions = () => {
    if (entityAuthStatuses.size == 0) {
      var allPoliciesSet = true;
      Object.values(policySelections).forEach((item) => {
        if (!item) {
          allPoliciesSet = false;
        }
      });
      return allPoliciesSet;
    }

    if (user && controlRole == UserMakerCheckerRole.MAKER) {
      const role = user.role;
      if (role != UserMakerCheckerRole.MAKER) return false;

      const canEnable = doesAllPoliciesHaveEitherStatus([
        PolicyStatus.APPROVED,
        PolicyStatus.DRAFT,
      ]);
      return canEnable;
    }
    return false;
  };

  const _canEnableCheckerActions = () => {
    if (user && controlRole != UserMakerCheckerRole.MAKER) {
      const role = user.role;
      if (role != UserMakerCheckerRole.CHECKER) return false;
      let canEnable = doesAllPoliciesHaveEitherEntityStatus([
        EntityStatus.APPROVED,
        EntityStatus.DRAFT_APPROVED,
        EntityStatus.DRAFT_REJECTED,
        EntityStatus.REJECTED,
      ]);
      return canEnable;
    }
    return false;
  };

  useEffect(() => {
    if (user) {
      _fetchAllPolicies(user);
    }
  }, [user]);

  return (
    <PolicyContext.Provider
      value={{
        loading: loading,
        policySelections: policySelections,
        fetchAllPolicies: _fetchAllPolicies,
        fetchPolicyOfType: _fetchPolicyOfType,
        fetchPddList: _fetchPDDList,
        pddList: pddList,
        canEnableCheckerActions: _canEnableCheckerActions,
        canEnableMakerActions: _canEnableMakerActions,
        addOrUpdatePolicy: _addOrUpdatePolicy,
        areAllPoliciesApproved: areAllPoliciesApproved,
        updatePolicyEntityStatus: _updatePolicyEntityStatus,
        submitPoliciesToMaker: _submitEntitiesToMaker,
        submitPoliciesToChecker: _submitEntitiesToChecker,
        fetchedPolicies: fetchedPolicies,
        loggedInMakerCheckerUser: user,
        entityAuthStatuses: entityAuthStatuses,
        checkPolicyEditable: _canEditPolicy,
        checkPolicyStatusUpdatable: _canUpdateStatusOfPolicy,
        addCommentOnPolicy: _addCommentOnPolicy,
        updateCommentOnPolicy: _updateCommentOnPolicy,
        controlRole: controlRole,
        getDefaultPolicy: _getDefaultPolicy,
      }}
    >
      {props.children}
    </PolicyContext.Provider>
  );
};
