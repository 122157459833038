import { Box, Stack } from '@mui/material';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import VegaPaperV4 from '../../common/v4/VegaPaperV4';
import {
  GenericTypo2,
  LabelTypo,
  ListHead2Typo,
} from '../../common/Typography';
import { ACCENT, PRIMARY_COLOR } from '../../../constants/style';
import EastIcon from '@mui/icons-material/East';
import VegaBadgeStatusTagV4 from '../../common/v4/VegaBadgeStatusTagV4';
import CashIcon from '../../icon/CashIcon';
import {
  VegaProgramCategoryType,
  VegaProgramType,
} from '../../../types/program';
import CardIcon from '../../icon/CardIcon';
import ForexIcon from '../../icon/ForexIcon';
import { useAppDispatch } from '../../../store/hook';
import { updateProgramOnSimulator } from '../../../store/common/simulatorSlice';
import { useMemo } from 'react';
import VegaNoResults from '../../common/VegaNoResults';

interface ISimulatorHomeCardContainerProps {
  category: VegaProgramCategoryType | 'all';
  search: string;
}

const SimulatorHomeCardContainer = ({
  category,
  search,
}: ISimulatorHomeCardContainerProps) => {
  const { allowedPrograms, setSelectedProgram } = useProgramData();
  const dispatch = useAppDispatch();

  const getItems = (category?: VegaProgramCategoryType) => {
    switch (category) {
      case VegaProgramCategoryType.CREDIT_CARD:
        return {
          icon: <CardIcon />,
          text: 'Credit Card',
          colors: {
            dark: PRIMARY_COLOR.orange[600],
            light: PRIMARY_COLOR.orange[50],
          },
        };
      case VegaProgramCategoryType.PREPAID_CARD:
        return {
          icon: <CardIcon color={ACCENT.seaGreen[600]} />,
          text: 'Debit Card',
          colors: {
            dark: ACCENT.seaGreen[600],
            light: ACCENT.seaGreen[50],
          },
        };
      case VegaProgramCategoryType.FOREX_CARD:
        return {
          icon: <ForexIcon color={ACCENT.pink[600]} />,
          text: 'Multicurrency Card',
          colors: {
            dark: ACCENT.pink[600],
            light: ACCENT.pink[50],
          },
        };
      default:
        return {
          icon: <CashIcon />,
          text: 'Loan',
          colors: {
            dark: ACCENT.violet[500],
            light: ACCENT.violet[50],
          },
        };
    }
  };

  const selectProgram = (program: VegaProgramType) => () => {
    setSelectedProgram(program);
    dispatch(updateProgramOnSimulator(program));
  };

  const searchLowerCased = search.trim().toLowerCase();

  // the reason i am using 2 layer filter is because since i am using useMemo, i do not have to go through the first filter
  // each time there is a change in search, and i only iterate over first layer filter and not the entire program

  const filteredProgramsLayer1 = useMemo(() => {
    return allowedPrograms.filter(
      (program) => category === 'all' || program.category === category
    );
  }, [category, allowedPrograms]);

  const filteredProgramsLayer2 = useMemo(() => {
    return filteredProgramsLayer1.filter((program) => {
      const str2Compare = (
        program.programId +
        program.name +
        program.programCode
      )
        .trim()
        .toLowerCase();
      const isSearchStringPresent = searchLowerCased
        ? str2Compare.includes(searchLowerCased)
        : true;
      return isSearchStringPresent;
    });
  }, [searchLowerCased, filteredProgramsLayer1]);

  const isProgramsAvailable = Boolean(filteredProgramsLayer2.length);

  return (
    <Box height={'calc(100vh - 346px)'} overflow="auto" p={2}>
      <Stack
        gap={'1rem'}
        className="position-relative"
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(330px, 1fr))"
      >
        {isProgramsAvailable ? (
          filteredProgramsLayer2.map((program, idx) => {
            const { icon, text, colors } = getItems(program.category);

            return (
              <VegaPaperV4
                key={program.programId || idx}
                sx={{ height: 148 }}
                className="cursor-pointer"
                onClick={selectProgram(program)}
              >
                <Stack justifyContent="space-between" height="100%">
                  <Stack direction="row" gap={1}>
                    <GenericTypo2
                      sx={{
                        color: PRIMARY_COLOR.blue[500],
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      flex={1}
                    >
                      {program.name}
                    </GenericTypo2>
                    <Box>
                      <EastIcon />
                    </Box>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {program.category && (
                      <VegaBadgeStatusTagV4
                        variant="light-filled"
                        text={text}
                        headerTypo={LabelTypo}
                        icon={icon}
                        overridecolors={colors}
                      />
                    )}
                    <ListHead2Typo>{program.coBrandPartnerName}</ListHead2Typo>
                  </Stack>
                </Stack>
              </VegaPaperV4>
            );
          })
        ) : (
          <VegaNoResults
            errorText={
              category === 'all'
                ? 'No Programs Found'
                : 'No Programs Found in this category. Select another Category.'
            }
          />
        )}
      </Stack>
    </Box>
  );
};

export default SimulatorHomeCardContainer;
