import { Grid, Link, LinkProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import DataTile from '../DataTile';
import {
  VegaProgramIssuanceType,
  VegaProgramWithMetaDataType,
} from '../../../types/program';
import dayjs from 'dayjs';
import { FilePdfIcon } from '../../../ui-components/icon/VPIcons';
import { getLogoForNetwork } from '../../icon/Icons';
import { convertCamelCase } from '../../../utils/stringUtils';

type DetailsSectionProps = {
  program: VegaProgramWithMetaDataType;
};

const getIssuanceType = (issuanceType?: VegaProgramIssuanceType) => {
  switch (issuanceType) {
    case VegaProgramIssuanceType.SELF:
      return 'Self';
    case VegaProgramIssuanceType.CO_BRAND:
      return 'Co-brand';

    default:
      return '-';
  }
};

const FileButton = (props: LinkProps) => {
  return (
    <Link
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
      }}
      {...props}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FilePdfIcon />
      <Typography
        sx={{
          fontSize: 12,
          color: '#1047DC',
          textDecorationLine: 'underline',
          textTransform: 'lowercase',
        }}
      >
        {props.title}
      </Typography>
    </Link>
  );
};

function DetailsSection({ program }: DetailsSectionProps) {
  const {
    updatedAt,
    createdAt,
    network,
    issuanceType: unFormattedIssuanceType,
    coBrandPartnerName,
    bin,
    tnc,
    agreement,
    status,
  } = program;
  const issuanceType = getIssuanceType(unFormattedIssuanceType);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 3,
        pb: 2,
      }}
    >
      <Typography className="font-aspekta-600" fontSize={17}>
        Details
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={6} sm={4} lg={2}>
          <DataTile
            title="Bin Number"
            value={bin?.binValue ? `${bin.binValue}` : '-'}
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <DataTile
            title="Bin Type"
            value={bin?.binType ? `${convertCamelCase(bin.binType)}` : '-'}
          />
        </Grid>
        <Grid item xs={6} sm={8}>
          <DataTile title="Network">
            {network ? getLogoForNetwork(network) : '-'}
          </DataTile>
        </Grid>
        {status !== 'DRAFT' && (
          <Grid item xs={6} sm={4} lg={2}>
            <DataTile
              title="Activation Date"
              value={updatedAt ? dayjs(updatedAt).format('DD/MM/YYYY') : '-'}
            />
          </Grid>
        )}
        <Grid item xs={6} sm={10}>
          <DataTile
            title="Creation Date"
            value={createdAt ? dayjs(createdAt).format('DD/MM/YYYY') : '-'}
          />
        </Grid>

        {status === 'DRAFT' && <Grid item xs={6} sm={4} lg={2} />}
        <Grid item xs={6} sm={4} lg={2}>
          <DataTile title="Issuance Type" value={issuanceType} />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <DataTile
            title="Co-Brand Partner"
            value={coBrandPartnerName || '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} lg={2}>
          <DataTile title="Agreements">
            {agreement ? (
              <FileButton title="agreements.pdf" href={agreement} />
            ) : (
              '-'
            )}
          </DataTile>
        </Grid>
        <Grid item>
          <DataTile title="Terms and conditions">
            {tnc ? <FileButton title="termsndc.pdf" href={tnc} /> : '-'}
          </DataTile>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DetailsSection;
