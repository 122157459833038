import { useEffect, useMemo, useState, ChangeEvent } from 'react';
import {
  addRoleForUser,
  bulkProcessForUser,
  bulkSaveForUser,
  fetchPastUploadForUser,
} from '../../../api/roleManagement';
import { VegaCard, VegaPage } from '../../../components/common';
import { VegaSelectSelectOptionType } from '../../../components/common/VegaSelect';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  IAddUserRequest,
  UserPlatformEnums,
  UserRoleEnums,
  UserTeamType,
  addRole,
  addUser,
  fetchRolesForClient,
  fetchUserForClientWithPagination,
  makerCheckerForUser,
  updateStatusForUser,
} from '../../Program-Dashboard/api/roles';

import { sorting } from '../../../constants/commonFunction';
import { STATUS } from '../../variable';
import AddUser from '../components/MCCTeamManagement/AddUser';
import ListOfTeamManagement from '../components/MCCTeamManagement/ListOfTeamManagement';
import MCCFilterSection from '../components/MCCUserManagement/MCCFilterSection';
import SubmitFormWrapper from '../components/SubmitFormWrapper';
import { IFilterProps } from './MCCAplicationManagement';
import BulkUploadWrapper from '../components/BulkUploadWrapper';
import {
  bulkSaveCustomers,
  bulkUploadCustomers,
  fetchPastUploadForCustomers,
} from '../api/cutomer';
import VegaModal from '../../../components/common/VegaModal';
import { AddCircleOutline } from '@mui/icons-material';
import BulkUpload from '../components/MCCAplicationManagement/BulkUpload';
import ReviewEntity from '../components/MCCAplicationManagement/ReviewEntity';
import PastUpload from '../components/MCCAplicationManagement/PastUpload';
import {
  IBulkUploadProps,
  IPastUploadFilesProps,
} from '../../types/applicationManagement';
import { ACCESS_FEATURES } from '../../role';
import { BULK_UPLOAD_TEMPLATE } from '../../constants';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../utils/FileValidator';

export interface IAddUserState extends IAddUserRequest {
  roleType: UserRoleEnums;
}
export interface IRolesprops {
  entityId: string | null;
  roles: string[];
}
export interface IEntityRolesProps {
  entityRoles: IRolesprops[];
  feature: string;
}
export interface IUserRoleProps {
  clientId: string | null;
  roleName: string;
  accessFeatures: IEntityRolesProps[];
  teamName: string;
  createdBy: string;
  status: string;
}
const INITIAL_USER_DATA: IAddUserState = {
  clientId: '',
  platformId: '',
  platformName: UserPlatformEnums.FOREX_BRANCH,
  mccUser: true,
  name: '',
  mobileNumber: '',
  email: '',
  address: '',
  officialEmailId: '',
  idType: 'PAN',
  idNumber: '',
  status: 'ACTIVE',
  team: UserTeamType.OPERATIONS,
  roleType: UserRoleEnums.HQ_USER,
};
export interface ITeamsProps {
  name: string;
  userId: string;
  mobileNumber: string | number;
  email: string;
  role: any;
  activationDate: any;
  status: any;
  userRoles: any;
}

export const INITIAL_DATA = {
  name: '',
  userId: '',
  mobileNumber: '',
  email: '',
  role: '',
  activationDate: '',
  status: '',
  userRoles: [],
};

export const CUSTOM_DATA = [
  {
    userId: 'Forexa1b2',
    name: 'Virat',
    mobileNumber: '9766155331',
    email: 'virat@vegaPay.tech',
    role: ['admin', 'sub admin'],
    activationDate: '01/01/2023',
    status: 'pending',
  },
  {
    userId: 'Forexa1b4',
    name: 'Ronit',
    mobileNumber: '9766155331',
    email: 'ronit@vegaPay.tech',
    role: ['admin'],
    activationDate: '01/01/2023',
    status: 'pending',
  },
] as ITeamsProps[];

const bulkUploadProps: IBulkUploadProps = {
  filename: '',
  invalidEntriesCount: null,
  invalidEntriesFileName: '',
  invalidEntriesFileUrl: '',
  totalEntries: null,
  uploadDate: '',
  validEntriesCount: null,
  validEntriesFileName: '',
  validEntriesFileUrl: '',
};

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([FileExtension.EXCEL, FileExtension.CSV])
  .withMultipleExtensionsCheck()
  .withSvgFileScan()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const MCCTeamManagement = () => {
  const { clientId, userId, mccPlatformId: branchId } = useClientAuth();

  const { setSnackbar } = useSnackbar();
  const [filterData, setFilterData] = useState<IFilterProps>({
    mobile: '',
    status: 'ALL',
  });
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEmptyKey, setIsEmptyKey] = useState<boolean>(false);
  const [branchList, setBranchList] = useState<VegaSelectSelectOptionType[]>(
    []
  );

  const [rolesList, setRolesList] = useState<VegaSelectSelectOptionType[]>([]);
  const [branchUser, setBranchUsers] = useState<any[]>([]);
  const [branchCode, setBranchCode] = useState<string>('');

  const [userProfile, setUserProfile] =
    useState<IAddUserState>(INITIAL_USER_DATA);

  ///////////// bulkUpload state \\\\\\\\\\\\\\\\\\\\\\\
  const [fileName, setFileName] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openPastUpload, setOpenPastUpload] = useState<boolean>(false);
  const [openEntities, setOpenEntities] = useState<boolean>(false);
  const [toggleButton, setToggleButton] = useState<boolean>(false);

  const [pastUploadFiles, setPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [passedPastUploadFiles, setPassedPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [failedPastUploadFiles, setFailedPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [bulkUploadData, setBulkUploadData] = useState<IBulkUploadProps>({
    ...bulkUploadProps,
  });
  const [error, setError] = useState<string>('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowsCount, setRowsCount] = useState(0);
  ///////////// bulkUpload state \\\\\\\\\\\\\\\\\\\\\\\

  ////////////////// Allocation Role \\\\\\\\\\\\\\\\\\\\\\
  ////////////////// Allocation Role \\\\\\\\\\\\\\\\\\\\\\

  const BRANCH_ADMIN_ACCESS = ACCESS_FEATURES.BRANCH_ADMIN_ACCESS_FEATURES.map(
    (access: string) => ({
      entityRoles: [
        {
          entityId: '',
          roles: ['EDIT'],
        },
      ],
      feature: access,
    })
  );
  const BRANCH_USER_ACCESS = ACCESS_FEATURES.BRANCH_USER_ACCESS_FEATURES.map(
    (access: string) => ({
      entityRoles: [
        {
          entityId: branchId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    })
  );
  const HQ_USER_ACCESS = ACCESS_FEATURES.HQ_USER_ACCESS_FEATURES.map(
    (access: string) => ({
      entityRoles: [
        {
          entityId: clientId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    })
  );
  const CORPORATE_ADMIN_ACCESS =
    ACCESS_FEATURES.CORPORATE_ADMIN_ACCESS_FEATURES.map((access: string) => ({
      entityRoles: [
        {
          entityId: clientId,
          roles: ['EDIT'],
        },
      ],
      feature: access,
    }));

  const DEFUALT_BRANCH_ADMIN = {
    clientId,
    roleName: `FOREX_BRANCH_ADMIN`,
    accessFeatures: BRANCH_ADMIN_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };

  const DEFAULT_BRANCH_USERS = {
    clientId,
    roleName: 'FOREX_BRANCH_USER',
    accessFeatures: BRANCH_USER_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };

  const DEFAUL_HQ_USERS = {
    clientId,
    roleName: 'FOREX_HQ_USER',
    accessFeatures: HQ_USER_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };
  const DEFAUL_CORPORATE_ADMIN = {
    clientId,
    roleName: 'FOREX_CORPORATE_ADMIN',
    accessFeatures: CORPORATE_ADMIN_ACCESS,
    teamName: 'Supports',
    createdBy: 'test',
    status: 'ACTIVE',
  };

  const [roleDetails, setRoleDetails] = useState<any>({
    ...DEFAUL_HQ_USERS,
  });

  const isValidInput = useMemo(() => {
    const flag =
      userProfile.email &&
      userProfile.mobileNumber &&
      userProfile.name &&
      userProfile.address &&
      userProfile.idNumber;

    if (!roleDetails.roleName || !userProfile.platformId || !flag) {
      return false;
    }
    return true;
  }, [userProfile]);

  const handleFilter = (name: string, value: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleChange = (
    key: keyof IAddUserState,
    value: string | File | null
  ) => {
    // apply filter on platformName -> UserPlatformEnums
    setUserProfile((pre) => {
      let platformName = pre.platformName;
      if (key === 'roleType') {
        switch (value) {
          // case UserRoleEnums.HQ_USER:
          //   platformName = UserPlatformEnums.FOREX_HQ;
          //   setRoleDetails({ ...DEFAUL_HQ_USERS });
          //   break;
          case UserRoleEnums.CORPORATE_ADMIN:
            platformName = UserPlatformEnums.FOREX_CORPORATE;
            setRoleDetails({ ...DEFAUL_CORPORATE_ADMIN });
            break;
          // case UserRoleEnums.BRANCH_ADMIN:
          //   setRoleDetails({ ...DEFUALT_BRANCH_ADMIN });
          //   break;
          default:
            // default branch user
            platformName = UserPlatformEnums.FOREX_BRANCH;
            setRoleDetails({ ...DEFAULT_BRANCH_USERS });
        }
      }

      return {
        ...pre,
        [key]: value,
        platformName,
      };
    });
  };

  const onOpen = () => {
    setOpen(!open);
    setSelectedFile(null);
  };

  const onEntitiesOpen = () => {
    setOpenEntities(!openEntities);
    setSelectedFile(null);
  };
  const onPastOpen = () => {
    setOpenPastUpload(!openPastUpload);
    setSelectedFile(null);
  };

  const onFetchPastUpload = () => {
    fetchPastUploadForUser(branchId).then((res) => {
      const singleArray = [
        ...res.data.completed,
        ...res.data.unknown,
        ...res.data.failed,
      ];
      setPastUploadFiles(singleArray);

      setPassedPastUploadFiles(res.data.completed);

      setFailedPastUploadFiles([...res.data.unknown, ...res.data.failed]);
    });
    onPastOpen();
  };

  const onClear = () => {
    setUserProfile({ ...INITIAL_USER_DATA });
  };
  const toggleUser = () => {
    setIsOpen(!isOpen);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert to MB
      if (
        fileType === 'text/csv' ||
        fileType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        if (fileSize <= 2) {
          setSelectedFile(file || null);
        } else {
          setError('File size exceeds 2MB. Please upload a smaller file.');
        }
      } else {
        setError('Please upload only CSV or XLSX files.');
      }
    }
  };

  const handleOnError = (message: string | null) => {
    setSelectedFile(null);
    setError(message ?? 'Something went wrong');
  };

  const onFileProceed = () => {
    if (!selectedFile) {
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    console.log('onFileProceed', selectedFile);

    bulkProcessForUser(formData).then(async (res) => {
      setBulkUploadData(res.data);
      // const invalidFileURL = res.data.invalidEntriesFileUrl;
      setFileName(res.data.validEntriesFileName);
      setToggleButton(true);
    });
  };
  const onFileSave = (url: string) => {
    console.log('onFileProceed', selectedFile);
    if (!url) {
      return;
    }

    const obj = {
      branchId,
      fileName,
      url,
    };

    bulkSaveForUser(obj).then(async (res) => {
      console.log('bulkSaveCustomers', res.data);
      // const invalidFileURL = res.data.invalidEntriesFileUrl;
      // setToggleButton(true);
      onEntitiesOpen();
      setBulkUploadData({ ...bulkUploadProps });
      getBranchUserList();
    });
  };

  const newObjForRole = (platformId: string) => {
    const roleName = (roleDetails.roleName + platformId).replaceAll(' ', '_');
    const entityId = platformId;
    if (!roleName || !entityId) {
      setSnackbar('Role is not defined', 'error');
      setIsLoading(false);
      return;
    }
    const finalObj: any = {};
    const newArr = Object.keys(roleDetails).map((value: any) => {
      return {
        [value]: roleDetails[value],
      };
    });
    for (let i = 0; i < newArr.length; i++) {
      Object.assign(finalObj, newArr[i]);
    }

    const newObj = {
      ...finalObj,
      roleName: roleName,
      accessFeatures: finalObj?.accessFeatures?.map((item: any) => ({
        ...item,
        entityRoles: item?.entityRoles?.map((val: any) => ({
          ...val,
          entityId: entityId,
        })),
      })),
    };

    return newObj;
  };

  const submitRole = async () => {
    setIsLoading(true);
    const newObj = await newObjForRole(userProfile.platformId);

    addRole(newObj)
      .then((res) => {
        setSnackbar('Role added!');
        setIsLoading(false);
        addUsers(res.data.id);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add role'),
          'error'
        );
        setIsLoading(false);
      });
  };

  const allocateRoleForUser = async (userId: string, platformId: string) => {
    const newObj = await newObjForRole(platformId);

    addRole(newObj)
      .then((res) => {
        setSnackbar('Role added!');
        setIsLoading(false);
        const roleForUser = {
          userId: userId,
          roleId: res.data.id,
          roleName: roleDetails?.roleName,
          checkerMakerFlag: true,
        };
        addRoleForUser(roleForUser).then((res) => {
          getBranchUserList();
        });
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add role'),
          'error'
        );
        setIsLoading(false);
      });
  };

  const addUsers = (roleId: string) => {
    setIsLoading(true);
    if (!clientId) {
      setSnackbar('Client Id is not defined');
      return;
    }
    addUser({
      ...userProfile,
      clientId,
    })
      .then(async (res) => {
        const roleForUser = {
          userId: res.data?.userId,
          roleId,
          roleName: roleDetails?.roleName,
          checkerMakerFlag: true,
        };
        addRoleForUser(roleForUser).then((res) => {
          getBranchUserList();
        });
        setSnackbar('User added!');
        setIsLoading(false);
        onClear();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add user'),
          'error'
        );
        setIsLoading(false);
        // onClear();
      });
  };

  const onAddUser = async () => {
    await submitRole();
  };

  const onSendChecker = async (data: any) => {
    makerCheckerForUser({ userId, entityId: data.id })
      .then((res) => {
        setSnackbar('Send Checker!');
        getBranchUserList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send checker'),
          'error'
        );
      });
  };
  const onUpdateSendChecker = async (data: any) => {
    updateStatusForUser({
      userId,
      status: 'CHECKER_PENDING',
      entityId: data.id,
    })
      .then((res) => {
        setSnackbar('Send Checker!');
        getBranchUserList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to send checker'),
          'error'
        );
      });
  };

  const onSearch = (arr: any) => {
    const newArr = arr.filter((val: any) => {
      if (filterData.mobile === '') {
        return val;
      } else if (
        val.mobileNumber.toLowerCase().includes(filterData.mobile.toLowerCase())
      ) {
        return val;
      } else if (
        val.email.toLowerCase().includes(filterData.mobile.toLowerCase())
      ) {
        return val;
      }
    });
    return newArr;
    // setFilterUsers([...newArr]);
  };

  const filteredBranchUser = branchUser.filter((val: any) => {
    if (filterData.status === 'ALL') {
      return val;
    } else if (val?.userRoles[0]?.checkerMakerStatus === filterData.status) {
      return val;
    }
  });

  const getBranchUserList = async () => {
    setListLoading(true);
    fetchUserForClientWithPagination(clientId as string, {
      page,
      size: pageSize,
    })
      .then((res) => {
        // const copy = [...branchUser];
        // res.data.map((val: any) => {
        //   const arr = val.userRoles;

        //   if (!arr.length) {
        //     copy.push(val);
        //   } else {
        //     arr.map((value: any) => {
        //       copy.push({
        //         ...value,
        //         name: val.name,
        //         userId: val.userId,
        //         email: val.email,
        //       });
        //     });
        //   }

        //   setBranchUsers([...copy]);
        // });
        // const sorted = sorting(res.data);

        setBranchUsers(res.data?.records);
        setRowsCount(res.data?.totalItems);
        setListLoading(false);
      })

      .catch((err) => {
        setListLoading(false);
      });
  };

  const getRoles = async () => {
    await fetchRolesForClient(clientId as string)
      .then((res) => {
        const newArr = res.data?.map((val: any) => ({
          name: val.roleName,
          id: val.id,
        }));
        setRolesList(newArr);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    // getBranches();
    getRoles();
  }, []);
  useEffect(() => {
    getBranchUserList();
  }, [page, pageSize]);

  return (
    <>
      <VegaPage>
        <VegaCard noPad>
          <SubmitFormWrapper
            titleText="Add New Profile"
            submitText="Add User"
            cancelText="Clear"
            onSubmit={onAddUser}
            onClear={onClear}
            toggle={toggleUser}
            isOpen={isOpen}
            loading={isLoading}
            disabled={!isValidInput}
          >
            <AddUser
              setBranchCode={setBranchCode}
              state={userProfile}
              rolesList={['BRANCH_USER', 'CORPORATE_ADMIN']}
              handleChange={handleChange}
              setIsEmptyKey={setIsEmptyKey}
            />
          </SubmitFormWrapper>
        </VegaCard>
        <VegaCard>
          <BulkUploadWrapper
            onFetchPastUpload={onFetchPastUpload}
            onOpen={onOpen}
            templateUrl={BULK_UPLOAD_TEMPLATE.BRANCH_TEAM_MANAGEMENT}
          />
        </VegaCard>

        <MCCFilterSection
          isStatusFilter
          statusOption={STATUS}
          state={filterData}
          handleChange={handleFilter}
          // onSearch={onSearch}
          // isSearch
        />
        <ListOfTeamManagement
          state={onSearch(filteredBranchUser)}
          onSendChecker={onSendChecker}
          onUpdateSendChecker={onUpdateSendChecker}
          loading={listLoading}
          setRoleDetails={setRoleDetails}
          defaultBranchUser={DEFAULT_BRANCH_USERS}
          defaultCorporateAdmin={DEFAUL_CORPORATE_ADMIN}
          allocateRoleForUser={allocateRoleForUser}
          page={page}
          pageSize={pageSize}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          rowsCount={rowsCount}
        />
      </VegaPage>
      <VegaModal
        header={'Bulk Upload'}
        open={open}
        handleClose={onOpen}
        submitText={toggleButton ? 'Review Entity' : 'Process'}
        // disabled={!selectedFile || error.length > 0}
        isButton={true}
        handleSubmit={
          toggleButton
            ? () => {
                onOpen();
                onEntitiesOpen();
                setToggleButton(false);
              }
            : onFileProceed
        }
        buttonIcon={<AddCircleOutline />}
      >
        <BulkUpload
          handleFileChange={handleFileChange}
          selectedFile={selectedFile}
          error={error}
          bulkUploadData={bulkUploadData}
          onError={(message) => {
            handleOnError(message);
          }}
          fileValidator={fileValidatorConfig}
        />
      </VegaModal>
      <VegaModal
        header={'Review Entiry'}
        open={openEntities}
        handleClose={() => {
          onEntitiesOpen();
          setBulkUploadData({ ...bulkUploadProps });
        }}
        // submitText={getComponents(status).submitText}
        width={900}
        isButton={false}
        // disabled={!selectedFile || error.length > 0}
        handleSubmit={() => {}}
        buttonIcon={<AddCircleOutline />}
      >
        <ReviewEntity
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          bulkUploadData={bulkUploadData}
          onSaveFile={onFileSave}
        />
      </VegaModal>
      <VegaModal
        header={'Past Upload'}
        open={openPastUpload}
        handleClose={onPastOpen}
        // submitText={getComponents(status).submitText}
        width={900}
        isButton={false}
        // disabled={!selectedFile || error.length > 0}
        handleSubmit={() => {}}
        buttonIcon={<AddCircleOutline />}
      >
        <PastUpload
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          pastUploadFiles={pastUploadFiles}
          passedPastUploadFiles={passedPastUploadFiles}
          failedPastUploadFiles={failedPastUploadFiles}
          // bulkUploadData={bulkUploadData}
        />
      </VegaModal>
    </>
  );
};

export default MCCTeamManagement;
