import { useEffect, useState } from 'react';
import KycIdentificationDetails from './components/KycIdentificationDetails';
import KycPanDetails from './components/KycPanDetails';
import { Box } from '@mui/material';
import { useApplications } from '../../../../../providers/ApplicationsProvider';
import {
  ApplicationMember,
  ApplicationServices,
  CustomerAadharData,
  CustomerPanData,
} from '../../../../../services/ApplicationServices';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import { GetAllApplicationsForHQApiData } from '../../../../../types/support';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useSupport } from '../../../../../providers/SupportProvider';

export interface IPanState {
  data: CustomerPanData | null;
  loading: boolean;
}
export interface IAadharState {
  data: CustomerAadharData | null;
  loading: boolean;
}

const SupportKycDetails = () => {
  const { filteredCustomer } = useSupport();
  const { setSnackbar } = useSnackbar();
  const [panState, setPanState] = useState<IPanState>({
    data: null,
    loading: false,
  });
  const [aadharState, setAadharState] = useState<IAadharState>({
    data: null,
    loading: false,
  });

  const getPanData = async (member: ApplicationMember) => {
    try {
      if (member.panInfoId) {
        const response = await ApplicationServices.fetchMaskPanDetails(
          member.panInfoId
        );
        return response;
      } else {
        setPanState({ data: null, loading: false });
        // setSnackbar('Pan Info Id not found', 'error');
      }
    } catch (error) {
      setPanState({ data: null, loading: false });
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };
  const getAadharData = async (member: ApplicationMember) => {
    try {
      if (member.aadhaarInfoId) {
        const response = await ApplicationServices.getAadhaarDetailsForInfoId(
          member.aadhaarInfoId
        );
        return response;
      } else {
        setPanState({ data: null, loading: false });
        // setSnackbar('Aadhaar Info Id not found', 'error');
      }
    } catch (error) {
      setAadharState({ data: null, loading: false });
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getPanDetails = async (members: ApplicationMember[]) => {
    setPanState({ data: null, loading: true });
    try {
      const panResponse = await getPanData(members[0]);
      panResponse && setPanState({ data: panResponse, loading: false });
    } catch (error) {
      setPanState({ data: null, loading: false });
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };
  const getAadharDetails = async (members: ApplicationMember[]) => {
    setAadharState({ data: null, loading: true });
    try {
      const aadharResponse = await getAadharData(members[0]);
      aadharResponse &&
        setAadharState({ data: aadharResponse, loading: false });
    } catch (error) {
      setAadharState({ data: null, loading: false });
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getApplications = async () => {
    try {
      var params: Partial<GetAllApplicationsForHQApiData> = {
        page: 0,
        pageSize: 100,
        sortBy: 'createdAt',
        sortDir: 'desc',
        customerId: filteredCustomer?.customerId,
      };

      const response = await ApplicationServices.getApplicationForCustomer(
        params
      );
      if (response.records.length) {
        getPanDetails(response.records[0].members);
        getAadharDetails(response.records[0].members);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
    }
  };

  useEffect(() => {
    getApplications();
  }, []);

  return (
    <Box
      sx={{
        border: `1px solid #E1E4EB`,
        borderRadius: '12px',
        bgcolor: 'white',
        py: 2,
        px: 2.5,
        overflow: 'scroll',
        height: '100%',
      }}
    >
      <KycPanDetails panState={panState} aadharState={aadharState} />
      <hr
        style={{
          border: '1px solid #EBEDEF',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      />
      <KycIdentificationDetails />
    </Box>
  );
};

export default SupportKycDetails;
