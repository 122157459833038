import { Stack } from '@mui/material';
import RepaymentAnalytics from './RepaymentAnalytics';
import BillingAnalytics from './BillingAnalytics';
import BillingAndRepaymentSummaryTable from './BillingAndRepaymentSummaryTable';

const BillingAndRepaymentAnalytics = () => {
  return (
    <Stack gap={3} mb={10}>
      <BillingAndRepaymentSummaryTable />
      <BillingAnalytics />
      <RepaymentAnalytics />
    </Stack>
  );
};

export default BillingAndRepaymentAnalytics;
