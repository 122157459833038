import { Box, Grid, Stack, Skeleton } from '@mui/material';
import Vega_Text from '../../../common/v3/Vega_Text';
import { useSupport } from '../../../../providers/SupportProvider';
import { useProgramData } from '../../../../providers/ProgramDataProvider';
import { VegaProgramType } from '../../../../types/program';
import VegaBadgeStatusTagV4 from '../../../common/v4/VegaBadgeStatusTagV4';
import { getAccountStatusColor } from '../supportUtils';
import { AccountStatus } from '../../../../enums/account.enum';

const AccountDetailsTopContainer = () => {
  const { accountData, filteredCustomer } = useSupport();
  const { programs } = useProgramData();

  const programDto = programs.find(
    (program: VegaProgramType) => program.programId === accountData?.programId
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
          <Text
            name="Account Id"
            value={accountData?.accountId ?? '-'}
            isBorder
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
          <Text
            name="VERIFIED name"
            value={filteredCustomer?.firstName ?? '--'}
            isBorder
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
          <Text
            name="Program name"
            value={programDto ? programDto.name : '-'}
            isBorder
            width="40%"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
          <Text
            name="mobile number"
            value={filteredCustomer?.mobileNumber}
            isBorder
            width="40%"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
          <Text name="email address" value={filteredCustomer?.emailId} />
        </Grid>
      </Grid>
      <Box
        sx={{
          position: 'absolute',
          right: '15px',
          top: '16px',
        }}
      >
        <VegaBadgeStatusTagV4
          text={accountData?.status || ''}
          color={getAccountStatusColor(accountData?.status as AccountStatus)}
          variant="stroked"
        />
      </Box>
    </>
  );
};

export default AccountDetailsTopContainer;

interface ITextProps {
  name: string;
  value: string | undefined | null;
  width?: string;
  isBorder?: boolean;
  loading?: boolean;
}

const Text = ({
  name,
  value,
  isBorder,
  width = '50%',
  loading,
}: ITextProps) => {
  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{ ...(isBorder && { borderRight: '1px solid #E1E4EB' }) }}
        gap={1}
      >
        <Vega_Text
          text={name}
          fontWeight={700}
          fontSize={10}
          textTransform={'uppercase'}
          color={'#7B8694'}
          letterSpacing={'0.5px'}
        />
        {loading ? (
          <Skeleton />
        ) : (
          <Vega_Text
            text={value ?? '-'}
            sx={{
              width: width,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            fontWeight={450}
            fontSize={13}
            color={'#181F28'}
          />
        )}
      </Stack>
    </>
  );
};
