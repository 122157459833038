import { UseQueryResult, useQuery } from 'react-query';
import {
  ResourceType,
  useInstitutionalResources,
} from '../../hooks/useInstitutionalResources';
import { PaginatedResponse } from '../../utils/api';

type ResourceQueryType = {
  type: ResourceType;
  key: string;
  page: number;
  pageSize: number;
  searchBy?: string;
  searchQuery?: string;
};

export function useResourcesQuery<T>({
  type,
  key,
  page,
  pageSize,
  searchBy,
  searchQuery,
}: ResourceQueryType): UseQueryResult<PaginatedResponse<T>, unknown> {
  const { fetchResource } = useInstitutionalResources();
  const query = useQuery({
    queryKey: [key, page, pageSize, searchQuery, searchBy],
    queryFn: () =>
      fetchResource<T>({
        type: type,
        page,
        pageSize,
        ...(searchQuery
          ? {
              searchBy: searchBy as string,
              searchQuery: searchQuery,
            }
          : {}),
      }),
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  });
  return query;
}
