import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Mcg, RiskProfiles } from '../../types/resources';
import VegaDrawerV4 from '../common/v4/VegaDrawerV4';
import Stack from '@mui/material/Stack';
import VegaTextfieldV4 from '../common/v4/VegaTextfieldV4';
import VegaSelectV4, { VegaSelectOptionType } from '../common/v4/VegaSelectV4';
import { convertCamelCase } from '../../utils/stringUtils';

type Props = {
  open: boolean;
  onClose: () => void;
  category?: Mcg | null;
  onSubmit: (riskProfile: string) => void;
};

function EditCategoryRiskModal({ category, onSubmit, open, onClose }: Props) {
  const [selectedRisk, setSelectedRisk] = useState<string>(
    category?.riskProfile ?? ''
  );

  function _onSubmit() {
    onSubmit(selectedRisk);
    setSelectedRisk('');
  }

  const categoryDisplayName = category?.name
    ? convertCamelCase(category.name)
    : '';

  useEffect(() => {
    setSelectedRisk(category?.riskProfile ?? '');
  }, [category]);

  return (
    <VegaDrawerV4
      onClose={onClose}
      open={open}
      header="Edit Risk Profile"
      submitBtn={{
        show: true,
        props: {
          value: 'Edit Risk Profile',
          onClick: _onSubmit,
        },
      }}
    >
      <Stack spacing={2}>
        <VegaTextfieldV4
          header="Category"
          value={categoryDisplayName}
          disabled
          standardWidth={400}
        />

        <VegaSelectV4
          header="Risk Profile"
          placeholder="Select Risk Profile"
          options={riskProfileOptions}
          value={selectedRisk}
          standardWidth={400}
          onChange={(e) => setSelectedRisk(e.target.value as string)}
        />
      </Stack>
    </VegaDrawerV4>
  );
}

export default EditCategoryRiskModal;

const riskProfileOptions = Object.values(RiskProfiles).map((value) => {
  const option: VegaSelectOptionType = {
    id: value,
    name: convertCamelCase(value),
  };
  return option;
});
