import { useDelinquencyHistoryListQuery } from '../../../../../../../store/queries/transactionQueries';
import { useSupport } from '../../../../../../../providers/SupportProvider';
import VegaTableV4 from '../../../../../../common/v4/VegaTableV4';
import { getDelinquncyHistoryListStructuredData } from '../../../../supportUtils';
import VegaNoResults from '../../../../../../common/VegaNoResults';

const ListOfDelinquncyHistory = () => {
  const { accountData } = useSupport();
  const accountId = accountData?.accountId;

  const { data, isFetching } = useDelinquencyHistoryListQuery({
    accountIds: [accountId || ''],
    enabled: Boolean(accountId),
  });

  const structuredData = getDelinquncyHistoryListStructuredData(data);

  return !Boolean(data?.length) && !isFetching ? (
    <VegaNoResults errorText="No EMIs found" />
  ) : (
    <VegaTableV4
      head={['ID', 'DPD', 'DPD Entry Date', 'Amount Due', 'Due Date']}
      data={structuredData}
      isLoading={isFetching}
    />
  );
};

export default ListOfDelinquncyHistory;
