import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { VPAccordion, VPButton, VPModal } from '../../../ui-components/common';
import DataTile from '../DataTile';
import Grid from '@mui/material/Grid';
import VegaHeader from '../../common/VegaHeader';
import {
  ProgramCardBack,
  ProgramCardFront,
} from '../../common/ProgramCardView';
import { VegaProgramWithMetaDataType } from '../../../types/program';
import { CardType } from '../../../providers/CardConfigProvider';
import { CardSizeVariant } from '../../common/ProgramCardView/CardComponents';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { getCardDesignConfiguration, getCardSettings } from '../../../api/card';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  setCardSettings,
  setPhysicalCardConfig,
  setVirtualCardConfig,
} from '../../../store/common/programManagementSlice';
import { convertCamelCase } from '../../../utils/stringUtils';
import { PageHeadingTypo, SectionHeadingTypo } from '../../common/Typography';
import { PRIMARY_COLOR, QUICK_ACCESS } from '../../../constants/style';
import { Stack } from '@mui/material';
import VegaAccordionV4, {
  VegaAccordionDetails,
  VegaAccordionSummary,
} from '../../common/v4/VegaAccordionV4';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import DownloadIcon from '../../icon/DownloadIcon';
import CardType2Icon from '../../icon/CardType2Icon';

type CardSettingProps = {
  program: VegaProgramWithMetaDataType;
};

const NoCardFound = () => {
  return (
    <Stack gap={1}>
      <Stack alignItems="center" justifyContent="center">
        <Stack
          borderRadius={'50%'}
          bgcolor={PRIMARY_COLOR.blue[50]}
          alignItems="center"
          justifyContent="center"
          p={1.5}
        >
          <CardType2Icon width={41} height={40} viewBox="0 0 41 40" />
        </Stack>
      </Stack>
      <SectionHeadingTypo>No Card Design Found</SectionHeadingTypo>
    </Stack>
  );
};

function CardSetting({ program }: CardSettingProps) {
  const [showDownloadModal, setShowDownloadModal] = useState<
    'physical' | 'virtual' | null
  >(null);
  const { setSnackbar } = useSnackbar();

  const {
    selectedProgram,
    cardSettings,
    physicalCardConfig,
    virtualCardConfig,
  } = useAppSelector((state) => state.programManagement);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    setShowDownloadModal(null);
  };

  useEffect(() => {
    if (!selectedProgram) return;

    if (!cardSettings) {
      getCardSettings({ programId: selectedProgram.programId })
        .then((res) => {
          dispatch(setCardSettings(res));
        })
        .catch((err) => {
          const debugMessage = err.response?.data?.apierror?.debugMessage;
          if (
            !debugMessage ||
            debugMessage !== 'No Card Setting Found For ProgramId'
          ) {
            setSnackbar('Failed to fetch card settings', 'error');
          }
        });
    }

    if (!physicalCardConfig) {
      getCardDesignConfiguration({
        programId: selectedProgram.programId,
        cardType: CardType.PHYSICAL,
      })
        .then((res) => {
          if (res) {
            dispatch(setPhysicalCardConfig(res));
          }
        })
        .catch((err) => {
          setSnackbar('Failed to fetch card config', 'error');
        });
    }
    if (!virtualCardConfig) {
      getCardDesignConfiguration({
        programId: selectedProgram.programId,
        cardType: CardType.VIRTUAL,
      })
        .then((res) => {
          if (res) {
            dispatch(setVirtualCardConfig(res));
          }
        })
        .catch((err) => {
          setSnackbar('Failed to fetch card config', 'error');
        });
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: 3,
          pb: 2,
        }}
      >
        <PageHeadingTypo
          sx={{
            color: QUICK_ACCESS.text[200],
          }}
        >
          Card Setting
        </PageHeadingTypo>

        <Stack direction="column" gap={2}>
          <SectionHeadingTypo>Card Details</SectionHeadingTypo>
          <Stack direction="row" flexWrap="wrap" gap={5}>
            <DataTile
              title="Card Digit"
              value={`${cardSettings?.cardDigit ?? '-'}`}
              containerSx={{
                minWidth: 180,
              }}
            />
            <DataTile
              title="Expiry month"
              value={`${cardSettings?.expiryMonth ?? '-'}`}
              containerSx={{
                minWidth: 180,
              }}
            />
            <DataTile
              title="CVV Type"
              value={`${
                cardSettings?.cvvType
                  ? convertCamelCase(cardSettings.cvvType)
                  : '-'
              }`}
              containerSx={{
                minWidth: 180,
              }}
            />
            <DataTile
              title="CVV Digit"
              value={`${cardSettings?.cvvLength ?? '-'}`}
            />
          </Stack>
        </Stack>

        <Stack direction="column" gap={2}>
          <SectionHeadingTypo>Card Activation Process</SectionHeadingTypo>
          <Stack direction="row" flexWrap="wrap" gap={5}>
            <DataTile
              title="Physical Card"
              value={`${
                cardSettings?.physicalCardActivationProcess
                  ? convertCamelCase(
                      cardSettings?.physicalCardActivationProcess
                    )
                  : '-'
              }`}
              containerSx={{
                minWidth: 180,
              }}
            />
            <DataTile
              title="virtual card"
              value={`${
                cardSettings?.virtualCardActivationProcess
                  ? convertCamelCase(cardSettings?.virtualCardActivationProcess)
                  : '-'
              }`}
              containerSx={{
                minWidth: 180,
              }}
            />
          </Stack>
        </Stack>
        <Stack gap={2}>
          <SectionHeadingTypo>Card Design</SectionHeadingTypo>

          <Stack direction="row" gap={2}>
            <VegaAccordionV4
              sx={{
                flex: 1,
              }}
              expanded
            >
              <VegaAccordionSummary
                sx={{
                  '& .MuiAccordionSummary-content': {
                    justifyContent: 'space-between',
                  },
                }}
              >
                <SectionHeadingTypo>Physical Card</SectionHeadingTypo>
                <VegaButtonV4
                  value="Download"
                  startIcon={<DownloadIcon />}
                  onClick={() => setShowDownloadModal('physical')}
                  disabled={!physicalCardConfig}
                />
              </VegaAccordionSummary>
              <VegaAccordionDetails>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      md: 'row',
                    },
                    justifyContent: {
                      xs: 'center',
                    },
                    alignItems: {
                      xs: 'center',
                      md: 'flex-start',
                    },
                    p: {
                      xs: 1,
                      md: 3,
                    },
                    gap: 1,
                  }}
                >
                  {physicalCardConfig ? (
                    <>
                      <ProgramCardFront
                        program={program}
                        cardConfig={physicalCardConfig ?? {}}
                        cardType={CardType.PHYSICAL}
                        variant={CardSizeVariant.SMALL}
                      />
                      <ProgramCardBack
                        cardConfig={physicalCardConfig ?? {}}
                        variant={CardSizeVariant.SMALL}
                      />
                    </>
                  ) : (
                    <NoCardFound />
                  )}
                </Box>
              </VegaAccordionDetails>
            </VegaAccordionV4>
            <VegaAccordionV4
              sx={{
                flex: 1,
              }}
              expanded
            >
              <VegaAccordionSummary
                sx={{
                  '& .MuiAccordionSummary-content': {
                    justifyContent: 'space-between',
                  },
                }}
              >
                <SectionHeadingTypo>Virtual Card</SectionHeadingTypo>
                <VegaButtonV4
                  value="Download"
                  startIcon={<DownloadIcon />}
                  onClick={() => setShowDownloadModal('virtual')}
                  disabled={!virtualCardConfig}
                />
              </VegaAccordionSummary>
              <VegaAccordionDetails>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      md: 'row',
                    },
                    justifyContent: {
                      xs: 'center',
                    },
                    alignItems: {
                      xs: 'center',
                      md: 'flex-start',
                    },
                    p: {
                      xs: 1,
                      md: 3,
                    },
                    gap: 1,
                  }}
                >
                  {virtualCardConfig ? (
                    <ProgramCardFront
                      program={program}
                      cardConfig={virtualCardConfig ?? {}}
                      cardType={CardType.VIRTUAL}
                      variant={CardSizeVariant.SMALL}
                    />
                  ) : (
                    <NoCardFound />
                  )}
                </Box>
              </VegaAccordionDetails>
            </VegaAccordionV4>
          </Stack>
        </Stack>
      </Box>
      <VPModal
        maxWidth="sm"
        open={!!showDownloadModal}
        header={
          showDownloadModal === 'physical'
            ? 'View Physical Card'
            : 'View Virtual Card'
        }
        handleClose={handleCloseModal}
        // footer={
        //   <Box>
        //     <VPButton
        //       title="Download"
        //       variant="contained"
        //       startIcon={<ArrowDownwardRoundedIcon />}
        //       sx={{
        //         bgcolor: '#1047DC',
        //       }}
        //     >
        //       Download Design
        //     </VPButton>
        //   </Box>
        // }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            justifyContent: {
              xs: 'center',
            },
            p: {
              xs: 1,
              md: 5,
            },
            gap: 1,
          }}
        >
          <ProgramCardFront
            program={program}
            cardConfig={
              showDownloadModal === 'physical'
                ? physicalCardConfig ?? {}
                : virtualCardConfig ?? {}
            }
            cardType={
              showDownloadModal === 'physical'
                ? CardType.PHYSICAL
                : CardType.VIRTUAL
            }
            variant={CardSizeVariant.SMALL}
          />
          {showDownloadModal === 'physical' && (
            <ProgramCardBack
              cardConfig={physicalCardConfig ?? {}}
              variant={CardSizeVariant.SMALL}
            />
          )}
        </Box>
      </VPModal>
    </>
  );
}

export default CardSetting;
