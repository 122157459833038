import dayjs from 'dayjs';
import { DATE_FORMATE, DATE_TIME_FORMAT } from '../../constants/url';
import { VegaCreateCustomersType } from '../../types/customers';
import { IDialogDetailsData } from '../common/v4/VegaDetailsDialogV4';
import {
  convert2IndianNumberSystem,
  convertCamelCase,
  stringifyAddress,
} from '../../utils/stringUtils';
import { ITableDataProps } from '../common/v4/VegaTableV4';
import { TransactionDto } from '../../types/transaction';
import { TransactionStatus } from '../../enums/transaction';
import { CustomerStatus } from '../../enums/commons';
import { LedgerData } from '../../types/ledger';
import { BillStatus, IBillResponseData } from '../../types/bill';
import { INDICATIVE } from '../../constants/style';
import { AccountStatus } from '../../enums/account.enum';
import { AccountActivityStatus } from '../../types/account';

export function getAccDetailsDialogData(
  selectedCustomer?: VegaCreateCustomersType
): IDialogDetailsData[] {
  const account = selectedCustomer?.account;

  return [
    {
      key: 'Account ID',
      value: account?.accountId,
    },
    {
      key: 'Program ID',
      value: selectedCustomer?.programId,
    },
    {
      key: 'Customer ID',
      value: selectedCustomer?.customerId,
    },
    {
      key: 'Program Name',
      value: account?.programName,
    },
    {
      key: 'Program Category',
      value: account?.programCategoryType,
    },
    {
      key: 'Account Limit',
      value: account?.accountLimit,
    },
    {
      key: 'Account Type',
      value: account?.accountType,
    },
    {
      key: 'Last Limit',
      value: account?.lastLimit,
    },
    {
      key: 'Cash Limit',
      value: account?.cashLimit,
    },
    {
      key: 'Cash Available Limit',
      value: account?.cashAvailableLimit,
    },
    {
      key: 'Credit Limit Used',
      value: account?.creditLimitUsed,
    },
    {
      key: 'Cash Limit Used',
      value: account?.cashLimitUsed,
    },
    {
      key: 'Total Amount Due',
      value: account?.totalAmountDue,
    },
    {
      key: 'Delinquency Level',
      value: account?.delinquencyLevel,
    },
    {
      key: 'Payment Due Date',
      value: account?.paymentDueDate,
    },
    {
      key: 'Cycle End Date',
      value: account?.cycleEndDate,
    },
    {
      key: 'Billing Cycle Frequency',
      value: account?.billingCycleFrequency,
    },
    {
      key: 'Account Origination Mode',
      value: account?.accountOriginationMode,
    },
    {
      key: 'Per Transaction Limit',
      value: account?.perTransactionLimit,
    },
    {
      key: 'Available Limit',
      value: account?.availableLimit,
    },
    {
      key: 'Surplus',
      value: account?.surplus,
    },
    {
      key: 'Charge',
      value: account?.charge,
    },
    {
      key: 'Currency',
      value: account?.currency,
    },
    {
      key: 'Overlimit',
      value: account?.overlimit,
    },
    {
      key: 'Overlimit Used',
      value: account?.overlimitUsed,
    },
    {
      key: 'Limit Hold',
      value: account?.limitHold,
    },
    {
      key: 'Cash Limit Hold',
      value: account?.cashLimitHold,
    },
    {
      key: 'Overlimit Hold',
      value: account?.overlimitHold,
    },
    {
      key: 'Grace Period',
      value: account?.gracePeriod,
    },
    {
      key: 'Payment Deviation',
      value: account?.paymentDeviation,
    },
    {
      key: 'Activation Date',
      value: account?.activationDate
        ? dayjs(account?.activationDate).format(DATE_FORMATE)
        : undefined,
    },
    {
      key: 'Lender ID',
      value: account?.lenderId,
    },
    {
      key: 'Status',
      value: account?.status,
      valueSx: {
        color:
          account?.status === AccountStatus.ACTIVE ||
          account?.status === AccountStatus.NEW
            ? INDICATIVE.green[600]
            : INDICATIVE.red[500],
      },
    },
    {
      key: 'Activity Status',
      value: account?.activityStatus,
      valueSx: {
        color:
          account?.activityStatus === AccountActivityStatus.NORMAL
            ? INDICATIVE.green[600]
            : account?.activityStatus === AccountActivityStatus.SUPERVISION
            ? INDICATIVE.yellow['600']
            : INDICATIVE.red[500],
      },
    },
    {
      key: 'Balance Type',
      value: account?.balanceType,
    },
    {
      key: 'Interest Status',
      value: account?.interestStatus,
    },
    {
      key: 'Last Accrual Date',
      value: account?.lastAccrualDate
        ? dayjs(account?.lastAccrualDate).format(DATE_FORMATE)
        : undefined,
    },
    {
      key: 'Last Liquidation Date',
      value: account?.lastLiquidationDate
        ? dayjs(account?.lastLiquidationDate).format(DATE_FORMATE)
        : undefined,
    },
    {
      key: 'Physical Statement Opted',
      value: account?.physicalStatementOpted ? 'True' : 'False',
    },
    {
      key: 'Physical Statement Availed',
      value: account?.physicalStatementAvailed,
    },
    {
      key: 'Overlimit Opted',
      value: account?.overlimitOpted ? 'True' : 'False',
    },
    {
      key: 'Timezone',
      value: account?.timezone,
    },
    {
      key: 'Created At',
      value: account?.createdAt
        ? dayjs(account?.createdAt).format(DATE_FORMATE)
        : undefined,
    },
    {
      key: 'Updated At',
      value: account?.updatedAt
        ? dayjs(account?.updatedAt).format(DATE_FORMATE)
        : undefined,
    },
  ];
}

export function getCustomerDetailsDialogData(
  selectedCustomer?: VegaCreateCustomersType
): IDialogDetailsData[] {
  return [
    {
      key: 'Customer ID',
      value: selectedCustomer?.customerId,
    },
    {
      key: 'Program ID',
      value: selectedCustomer?.programId,
    },
    {
      key: 'Client ID',
      value: selectedCustomer?.clientId,
    },
    {
      key: 'Title',
      value: selectedCustomer?.title,
    },
    {
      key: 'First Name',
      value: selectedCustomer?.firstName,
    },
    {
      key: 'Middle Name',
      value: selectedCustomer?.middleName,
    },
    {
      key: 'Last Name',
      value: selectedCustomer?.lastName,
    },
    {
      key: 'Country Code',
      value: selectedCustomer?.countryCode,
    },
    {
      key: 'Mobile Number',
      value: selectedCustomer?.mobileNumber,
    },
    {
      key: 'Email Id',
      value: selectedCustomer?.emailId,
    },
    {
      key: 'Identity',
      value: selectedCustomer?.identity.find((el) => Boolean(el.idNumber))
        ?.idNumber,
    },
    {
      key: 'DOB',
      value: selectedCustomer?.dob
        ? dayjs(selectedCustomer?.dob).format(DATE_FORMATE)
        : undefined,
    },
    {
      key: 'Customer Type',
      value: selectedCustomer?.customerType,
    },
    {
      key: 'Gender',
      value: selectedCustomer?.gender,
    },
    {
      key: 'Nationality',
      value: selectedCustomer?.nationality,
    },
    {
      key: 'Status',
      value: selectedCustomer?.status,
      valueSx: {
        color:
          selectedCustomer?.status === CustomerStatus.ACTIVE
            ? INDICATIVE.green[600]
            : INDICATIVE.red[500],
      },
    },
    {
      key: 'KYC Status',
      value: selectedCustomer?.kycStatus,
      valueSx: {
        color:
          selectedCustomer?.kycStatus === 'VERIFIED'
            ? INDICATIVE.green[600]
            : INDICATIVE.red[500],
      },
    },
    {
      key: 'Current Address',
      value: stringifyAddress(selectedCustomer?.currentAddress),
    },
  ];
}

export function getTransactionDetailsDialogData(
  transaction?: TransactionDto
): IDialogDetailsData[] {
  return [
    {
      key: 'ID',
      value: transaction?.txnProxyId,
    },
    {
      key: 'Account ID',
      value: transaction?.accountId,
    },
    {
      key: 'Amount',
      value: transaction?.amount,
    },
    {
      key: 'Currency',
      value: transaction?.currency,
    },
    {
      key: 'Transaction Indicator',
      value: transaction?.transactionIndicator,
    },
    {
      key: 'Transaction Category',
      value: transaction?.transactionCategory,
    },
    {
      key: 'Merchant Name',
      value: transaction?.merchantName,
    },
    {
      key: 'Narration',
      value: transaction?.narration,
    },
    {
      key: 'Status',
      value: transaction?.status,
      valueSx: {
        color:
          transaction?.status === TransactionStatus.SUCCESS
            ? INDICATIVE.green[600]
            : INDICATIVE.red[500],
      },
    },
    {
      key: 'Transaction Time',
      value: transaction?.transactionTime
        ? dayjs(transaction?.transactionTime).format(DATE_TIME_FORMAT)
        : undefined,
    },
    {
      key: 'Created At',
      value: transaction?.createdAt
        ? dayjs(transaction?.createdAt).format(DATE_TIME_FORMAT)
        : undefined,
    },
    {
      key: 'Updated At',
      value: transaction?.updatedAt
        ? dayjs(transaction?.updatedAt).format(DATE_TIME_FORMAT)
        : undefined,
    },
    {
      key: 'After Transaction Balance',
      value: transaction?.afterTransactionBalance,
    },
  ];
}

export function getAccListStructuredData(
  records: VegaCreateCustomersType[] | undefined,
  onCustomerDetailsClick: Function,
  onAccDetailsOpen: Function,
  onAccountStatusChange: Function
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      const account = rec.account;

      return {
        rowData: [
          {
            value: (rec.firstName || '') + ' ' + (rec.lastName || ''),
            type: 'string',
          },
          {
            value: account?.accountId,
            type: 'string',
          },
          {
            value: account?.cashLimit,
            type: 'string',
          },
          {
            value: account?.overlimit,
            type: 'string',
          },
          {
            value: account?.gracePeriod,
            type: 'string',
          },
          {
            value: account?.paymentDeviation,
            type: 'string',
          },
          {
            value: dayjs(account?.createdAt).format(DATE_FORMATE),
            type: 'string',
            cellSx: {
              minWidth: 120,
            },
          },
          {
            value: 'View',
            type: 'link',
            linkOnClick: onCustomerDetailsClick,
          },
          {
            value: 'View',
            type: 'link',
            linkOnClick: onAccDetailsOpen,
          },
          {
            value: account?.status,
            type: [
              AccountStatus.ACTIVE,
              AccountStatus.INACTIVE,
              AccountStatus.CREATED,
            ].includes(account?.status as AccountStatus)
              ? 'switch'
              : 'string',
            isSwitchOn: account?.status === AccountStatus.ACTIVE,
            onSwitchChange: onAccountStatusChange,
            cellSx: {
              width: 150,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getTransactionAccListStructuredData(
  records: TransactionDto[] | undefined,
  onViewMoreClick: Function
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec?.txnProxyId,
            type: 'string',
          },
          {
            value: dayjs(rec.transactionTime).format(DATE_TIME_FORMAT),
            type: 'string',
          },
          {
            value: convert2IndianNumberSystem(rec.amount),
            type: 'string',
          },
          {
            value: rec.currency?.toUpperCase(),
            type: 'string',
          },
          {
            value: rec.transactionIndicator,
            type: 'string',
          },
          {
            value: rec.status,
            type: 'status',
            statusColor:
              rec.status === TransactionStatus.SUCCESS ? 'green' : 'red',
          },
          {
            value: rec.merchantName,
            type: 'string',
          },
          {
            value: rec.transactionCategory
              ? convertCamelCase(rec.transactionCategory)
              : '--',
            type: 'string',
          },
          {
            value: convertCamelCase(rec.transactionCode),
            type: 'string',
          },
          {
            value: 'View More',
            type: 'link',
            linkOnClick: onViewMoreClick,
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getLedgerListStructuredData(
  records: LedgerData[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      const { ledgerBreakup } = rec;
      return {
        rowData: [
          {
            value: rec.id,
            type: 'string',
            typoSx: {
              width: '300px',
            },
          },
          {
            value: rec.amount,
            type: 'string',
          },
          {
            value: rec.currency?.toUpperCase(),
            type: 'string',
          },
          {
            value: dayjs(rec.transactionDate).format(DATE_TIME_FORMAT),
            type: 'string',
            typoSx: {
              width: '150px',
            },
          },
          {
            value: ledgerBreakup?.retailPrincipal ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.retailInterest ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.retailFee ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.cashPrincipal ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.cashInterest ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.cashFee ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.emiPrincipal ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.emiInterest ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.emiFee ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.retailPrincipalBilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.retailInterestBilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.retailFeeBilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.retailPrincipalUnbilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.retailInterestUnbilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.retailFeeUnbilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.cashPrincipalBilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.cashInterestBilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.cashFeeBilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.cashPrincipalUnbilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.cashInterestUnbilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.cashFeeUnbilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.emiPrincipalBilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.emiInterestBilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.emiFeeBilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.emiPrincipalUnbilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.emiInterestUnbilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.emiFeeUnbilled ?? '--',
            type: 'string',
          },
          {
            value: ledgerBreakup?.overlimitUnbilled ?? '--',
            type: 'string',
          },
        ],
      };
    }) || []
  );
}

export function getBillListStructuredData(
  records: IBillResponseData[] | undefined,
  onViewBillClick: Function
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.accountId,
            type: 'string',
          },
          {
            value: dayjs(rec.billStartDate).format(DATE_FORMATE),
            type: 'string',
          },
          {
            value: rec.id,
            type: 'string',
          },
          {
            value: dayjs(rec.billDueDate).format(DATE_FORMATE),
            type: 'string',
            typoSx: {
              width: '150px',
            },
          },
          {
            value: rec.dueAmount,
            type: 'string',
          },
          {
            value: rec.mad,
            type: 'string',
          },
          {
            value: rec.status,
            type: 'status',
            statusColor: rec.status === BillStatus.PAID ? 'green' : 'red',
          },
          {
            value: rec.billPdfLink ? 'View Bill' : '--',
            type: rec.billPdfLink ? 'link' : 'string',
            linkOnClick: onViewBillClick,
          },
        ],
        id: rec.id,
      };
    }) || []
  );
}
export enum SearchOptions {
  MOBILE_NUMBER = 'mobileNumber',
  CUSTOMER_NAME = 'name',
  CUSTOMER_ID = 'customerId',
  ACCOUNT_ID = 'accountId',
}
export const searchByOptionsForCustomer = [
  {
    name: 'Mobile Number',
    id: SearchOptions.MOBILE_NUMBER,
  },
  {
    name: 'Customer Id',
    id: SearchOptions.CUSTOMER_ID,
  },
  {
    name: 'Customer Name',
    id: SearchOptions.CUSTOMER_NAME,
  },
];

export const searchByOptionsForTransactions = [
  ...searchByOptionsForCustomer,
  { name: 'Account Id ', id: SearchOptions.ACCOUNT_ID },
];

export function helper(key: string | number, value: string | undefined) {
  return {
    [key as string]: value,
  };
}

export function getRequestObject(
  searchBy: string | number,
  searchQuery: string | undefined
) {
  return helper(searchBy, searchQuery);
}
