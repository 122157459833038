import { Stack } from '@mui/material';
import { IBillResponseData } from '../../../../../../../types/bill';
import { PageHeadingTypo } from '../../../../../../common/Typography';
import BillStatusAndPaymentStatus from './BillStatusAndPaymentStatus';
import VegaKeyValCardV4 from '../../../../../../common/v4/VegaKeyValCardV4';
import { convert2IndianNumberSystem } from '../../../../../../../utils/stringUtils';

interface IBillDetailsOverviewProps {
  data: IBillResponseData | undefined;
}

const BillDetailsOverview = ({ data }: IBillDetailsOverviewProps) => {
  const displayData = [
    [
      {
        key: 'Opening balance',
        value: convert2IndianNumberSystem(data?.openingBalance),
      },
      {
        key: 'Closing balance',
        value: convert2IndianNumberSystem(data?.closingBalance),
      },
    ],
    [
      {
        key: 'Total limit used',
        value: convert2IndianNumberSystem(data?.limitUsed),
      },
      {
        key: 'Total available limit',
        value: convert2IndianNumberSystem(data?.limitAvailable),
      },
    ],
    [
      {
        key: 'Outstanding principal',
        value: convert2IndianNumberSystem(data?.dueRetail),
      },
      {
        key: 'Outstanding interest',
        value: convert2IndianNumberSystem(data?.dueInterest),
      },
    ],
  ];

  return (
    <Stack gap={1.5}>
      <PageHeadingTypo>Overview</PageHeadingTypo>
      <Stack gap={2}>
        <BillStatusAndPaymentStatus data={data} />
        <VegaKeyValCardV4 displayData={displayData} />
      </Stack>
    </Stack>
  );
};

export default BillDetailsOverview;
