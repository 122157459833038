import { useSupport } from '../../../../../../../providers/SupportProvider';
import { useDelinquencyStatQuery } from '../../../../../../../store/queries/transactionQueries';
import VegaCardV4_2 from '../../../../../../common/v4/VegaCardV4_2';
import {
  ActionLargeTypo,
  ComponentBody1Typo,
} from '../../../../../../common/Typography';
import { Box, Skeleton, Stack } from '@mui/material';

const ListOfDPDStatus = () => {
  const { accountData } = useSupport();
  const accountId = accountData?.accountId;

  const { data, isFetching } = useDelinquencyStatQuery({
    accountId: accountId || '',
    enabled: Boolean(accountId),
  });

  return (
    <VegaCardV4_2>
      <ActionLargeTypo mb={2}>Delinquency Stats</ActionLargeTypo>
      <Stack gap={1}>
        <Stack gap={1} direction="row">
          <Box width={150}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>Max Lifetime DPD</ComponentBody1Typo>
            )}
          </Box>
          <Box flex={1}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>{data?.maxDpd || '--'}</ComponentBody1Typo>
            )}
          </Box>
        </Stack>
        <Stack gap={1} direction="row">
          <Box width={150}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>No. Of Time In DPD TD</ComponentBody1Typo>
            )}
          </Box>
          <Box flex={1}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>{data?.DpdCount || '--'}</ComponentBody1Typo>
            )}
          </Box>
        </Stack>
        <Stack gap={1} direction="row">
          <Box width={150}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>Last 6 Months Max DPD</ComponentBody1Typo>
            )}
          </Box>
          <Box flex={1}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>
                {data?.pastOneEightyMaxDpd || '--'}
              </ComponentBody1Typo>
            )}
          </Box>
        </Stack>
        <Stack gap={1} direction="row">
          <Box width={150}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>Last 3 Months Max DPD</ComponentBody1Typo>
            )}
          </Box>
          <Box flex={1}>
            {isFetching ? (
              <Skeleton height={15} />
            ) : (
              <ComponentBody1Typo>
                {data?.pastNinetyMaxDpd || '--'}
              </ComponentBody1Typo>
            )}
          </Box>
        </Stack>
      </Stack>
    </VegaCardV4_2>
  );
};

export default ListOfDPDStatus;
