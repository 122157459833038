/** @format */
import { useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ForgotPassword from '../containers/ClientLogin/ForgotPassword';
import LoginContainer from '../containers/ClientLogin/LoginContainer';
import NewPassword from '../containers/ClientLogin/NewPassword';
import {
  isCurrentPathProgram,
  isCurrentPathBackOffice,
  isCurrentPathSimulator,
  isCurrentPathForexBranch,
  isCurrentPathForexCorporate,
} from '../constants/url';

function Login() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState<string>('');
  const [stateRoute, setStateRoute] = useState('LOGIN');
  const title = isCurrentPathProgram
    ? 'Program Dashboard'
    : isCurrentPathBackOffice
    ? 'Back Office'
    : isCurrentPathSimulator
    ? 'Simulator'
    : isCurrentPathForexBranch
    ? 'Branch Dashboard'
    : isCurrentPathForexCorporate
    ? 'Corporate Dashboard'
    : 'Client Dashboard';

  const changePageHandler = (route: string) => {
    setStateRoute(route);
  };

  return (
    <div>
      <Grid
        container
        style={{
          maxHeight: '100vh',
          height: '100vh',
        }}
      >
        <Grid
          sx={{
            bgcolor: '#05162a',
            ...(isMobile && { display: 'none' }),
            position: 'relative',
          }}
          item
          xs={0}
          sm={6}
          lg={6}
          md={6}
        >
          <Box
            px={7.5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              position: 'relative',
            }}
          >
            <Box
              p={4.5}
              sx={{
                bgcolor: '#05162a',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            ></Box>
            <Box>
              <Typography
                fontSize={48}
                className="font-aspekta-300"
                color={'white'}
              >
                Welcome to
              </Typography>
              <Typography
                fontSize={48}
                className="font-aspekta-700"
                color={'#FF912C'}
              >
                {title}
              </Typography>
              <Box
                sx={{
                  width: '52px',
                  height: '3px',
                  bgcolor: 'white',
                  my: 3,
                }}
              ></Box>
              <Typography
                fontSize={14}
                className="font-aspekta-500"
                color={'white'}
              >
                Your own Admin Ecosystem
              </Typography>
            </Box>

            <Stack
              direction={'row'}
              gap={3}
              p={7.5}
              sx={{
                bgcolor: '#05162a',
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            >
              <Typography
                fontSize={8}
                className="font-aspekta-600"
                color={'white'}
                letterSpacing={'1px'}
              >
                &copy; Vegapay Technology Pvt. Ltd. {new Date().getFullYear()}
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          style={{
            background: '#FFFFFF',
            position: 'relative',
          }}
        >
          {stateRoute === 'FORGOT' ? (
            <ForgotPassword
              changePageHandler={changePageHandler}
              setActiveUserEmail={setEmail}
            />
          ) : stateRoute === 'NEW_PASSWORD' ? (
            <NewPassword changePageHandler={changePageHandler} email={email} />
          ) : (
            <LoginContainer changePageHandler={changePageHandler} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;

/* 
* Deprecated UI, can be removed later
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { VegaTextField } from '../components/common';
import { BLUE, BORDER } from '../constants/style';
import { isDev } from '../constants/url';
import { useClientAuth } from '../providers/ClientProvider';
import { ROUTES } from '../routes';
import { GetLoginImage } from '../utils/LoginPageImage';
import { isCurrentPathClient, isLocalPath } from '../constants/url';
import ClientLoginPage from '../containers/ClientLogin/ClientLoginPage';
type Props = {};

const Login = (props: Props) => {
  const { login } = useClientAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reCaptchaValue, setReCaptchaValue] = useState<string | null>(null);

  const navigation = useNavigate();

  const forgotPasswordHandler = () => {
    navigation(ROUTES.FORGOT_PASSWORD);
  };

  const isClientPath = isCurrentPathClient || isLocalPath;

  if (isClientPath) {
    return <ClientLoginPage />;
  }

  return (
    <Box
      sx={{
        height: '100vh',
        p: 5,
        bgcolor: '#EBEFF4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '20px',
          py: 8,
          px: 5,
          maxWidth: '1100px',
          marginX: 'auto',
          // display: 'flex',
          // justifyContent: 'space-between',
          // alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            ...(isMobile ? { borderBottom: BORDER } : { borderRight: BORDER }),
          }}
          justifyContent="center"
        >
          <GetLoginImage />
          
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            pl: 5,

            // height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="subtitle2" mb={5} textAlign={'center'}>
            Let's get you started
          </Typography>
          <Box component={'form'} onSubmit={(e) => login(e, email, password)}>
            <Box mb={2}>
              <Typography>Email</Typography>
              <VegaTextField
                type={'email'}
                key={'login-email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Box mb={3}>
              <Typography>Password</Typography>
              <VegaTextField
                type={'password'}
                key={'login-pass'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            <Box mb={3}>
              <ReCAPTCHA
                sitekey="6LeLhHUnAAAAACftMS55hwpQuSTw1yBgN0ccMB7f"
                onChange={(props) => {
                  setReCaptchaValue(props);
                }}
              />
            </Box>
            <Stack spacing={2}>
              <Button
                type="submit"
                disabled={!isDev && !reCaptchaValue}
                variant="contained"
                sx={{ bgcolor: '#7081D8', width: '100%' }}
              >
                Login
              </Button>
              <Typography
                sx={{
                  color: BLUE.lighter,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                component={'span'}
                onClick={forgotPasswordHandler}
                textAlign={'center'}
              >
                Forgot Password ?
              </Typography>
            </Stack>
          </Box>
          <Box mt={2} textAlign="center">
            <Typography
              component={'div'}
              style={{ fontSize: '18px', fontWeight: 500 }}
            >
              <Typography
                sx={{ color: BLUE.lighter, cursor: 'pointer' }}
                component={'span'}
                onClick={() =>
                  window.open('https://www.vegapay.tech/privacy-policy')
                }
              >
                Privacy Policy
              </Typography>
              &{' '}
              <Typography
                sx={{ color: BLUE.lighter, cursor: 'pointer' }}
                component={'span'}
                onClick={() =>
                  window.open('https://www.vegapay.tech/term&condition')
                }
              >
                Terms of Service
              </Typography>{' '}
              apply.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
 */
