import * as React from 'react';
import { SVGProps } from 'react';
import { INDICATIVE } from '../../constants/style';

const ArrowRefreshIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M13.5377 4.79998C12.4257 2.88701 10.3474 1.59998 7.96702 1.59998C5.26809 1.59998 2.95748 3.25451 2.00345 5.59998M11.5845 5.59998H14.8V2.39998M2.46228 11.2C3.57424 13.1129 5.65257 14.4 8.03296 14.4C10.7319 14.4 13.0425 12.7454 13.9965 10.4M4.4155 10.4H1.19999V13.6"
      stroke={props.stroke ?? INDICATIVE.red[500]}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ArrowRefreshIcon;
