import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { VegaTextfieldV4Props } from './VegaTextfieldV4';
import { Stack } from '@mui/material';
import VegaSearchfieldV4 from './VegaSearchfieldV4';
import SearchIcon from '../../icon/SearchIcon';
import { PartialBy } from '../../../types/commonType';
import { ComponentBody1Typo } from '../Typography';
import { QUICK_ACCESS } from '../../../constants/style';

interface IVegaAutocompleteSearchV4props
  extends AutocompleteProps<
    AutoCompleteOptions,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  > {
  textFieldProps: VegaTextfieldV4Props;
  onTextChange?: any;
}

export type AutoCompleteOptions = {
  label: string;
  id: string | number;
};

const VegaAutocompleteSearchV4 = ({
  textFieldProps,
  id,
  loading,
  value,
  ...rest
}: PartialBy<IVegaAutocompleteSearchV4props, 'renderInput'>) => {
  const renderObj = (params: AutocompleteRenderInputParams) => (
    <VegaSearchfieldV4
      {...params}
      {...textFieldProps}
      InputProps={{
        ...params.InputProps,
        sx: {
          input: {
            fontSize: '12px !important',
            '&::placeholder': {
              fontSize: 12,
            },
          },
          ...(params as any).InputProps?.sx,
        },
        startAdornment: (
          <Stack pl={1} justifyContent="center" alignItems="center">
            <SearchIcon />
          </Stack>
        ),
      }}
    />
  );

  return (
    <Autocomplete
      value={value}
      openOnFocus
      id={id || 'combo-box-demo'}
      renderInput={renderObj}
      loading={loading}
      renderOption={(props, option, state) => {
        if (loading) {
          // Display loading indicator as the first option when loading
          return state.index === 0 ? (
            <ComponentBody1Typo p={1} className="cursor-pointer">
              Loading...
            </ComponentBody1Typo>
          ) : null;
        }
        return (
          <ComponentBody1Typo
            {...props}
            p={1}
            sx={{
              backgroundColor:
                option.label === state.inputValue
                  ? QUICK_ACCESS.background[75]
                  : undefined,
              '&:hover': {
                backgroundColor: QUICK_ACCESS.background[100],
              },
            }}
            className="cursor-pointer"
          >
            {option.label}
          </ComponentBody1Typo>
        );
      }}
      {...rest}
    />
  );
};

export default VegaAutocompleteSearchV4;
