import { Stack } from '@mui/material';
import { MouseEvent, useState } from 'react';
import {
  ComponentBody1Typo,
  PageHeadingTypo,
} from '../../../../../common/Typography';
import VegaSearchfieldV4 from '../../../../../common/v4/VegaSearchfieldV4';
import VegaTableV4 from '../../../../../common/v4/VegaTableV4';
import VegaButtonV4 from '../../../../../common/v4/VegaButtonV4';
import SurplusPayoutDrawer from './SurplusPayoutDrawer';
import { QUICK_ACCESS } from '../../../../../../constants/style';
import { useSurplusListQuery } from '../../../../../../store/queries/transactionQueries';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { getSurplusListStructuredData } from '../../../supportUtils';
import VegaNoResults from '../../../../../common/VegaNoResults';
import { convert2IndianNumberSystem } from '../../../../../../utils/stringUtils';
import { useKeyValHook } from '../../../../../../hooks/useKeyValHook';
import VegaTextfieldV4 from '../../../../../common/v4/VegaTextfieldV4';
import dayjs from 'dayjs';
import { DATE_FORMATE_SHORT } from '../../../../../../constants/url';
import VegaDateRangePickerPopoverV4 from '../../../../../common/v4/VegaDateRangePickerPopoverV4';
import { getDateInputIcons } from '../../../../../../utils/common.utils';
import useDateRangeStandardHook from '../../../../../../hooks/useDateRangeStandardHook';

interface ISurplusFilter {
  date?: Date;
  search?: string;
  startDate?: string;
  endDate?: string;
}

type Keys = 'date' | 'search' | 'startDate' | 'endDate';

const Surplus = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { accountData } = useSupport();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const updatePage2Zero = () => {
    setPage(0);
  };

  const { updateValue, values } = useKeyValHook<ISurplusFilter, Keys>(
    {
      search: undefined,
    },
    updatePage2Zero
  );

  const { startDate2Pass, endDate2Pass } = useDateRangeStandardHook(
    values.startDate,
    values.endDate
  );

  const { data, isFetching } = useSurplusListQuery({
    accountId: accountData?.accountId || '',
    pageNumber: page,
    pageSize,
    search: values.search,
    startDate: startDate2Pass,
    endDate: endDate2Pass,
  });

  const updateRecord = (key: Keys) => (e: any) => {
    updateValue(
      key,
      key === 'search' && e.target.value === ''
        ? undefined
        : (e.target.value as string)
    );
  };

  const clearSearch = () => {
    updateValue('search', undefined);
  };

  const records = data?.records;

  const structuredTableData = getSurplusListStructuredData(records);

  const toggleDrawer = () => {
    setOpenDrawer((val) => !val);
  };

  const clearDate = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    updateValue('startDate', undefined);
    updateValue('endDate', undefined);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitAndClose = (startDate: string, endDate: string) => {
    updateValue('startDate', startDate);
    updateValue('endDate', endDate);
    handleClose();
  };

  const openRange = Boolean(anchorEl);
  const id = openRange ? 'simple-popover' : undefined;

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={1}
        alignItems="flex-start"
      >
        <Stack gap={0.5}>
          <PageHeadingTypo>Surplus</PageHeadingTypo>
          <ComponentBody1Typo sx={{ color: QUICK_ACCESS.text[100] }}>
            Surplus available -{' '}
            {convert2IndianNumberSystem(accountData?.accountLimits?.surplus)}
          </ComponentBody1Typo>
        </Stack>
        <VegaButtonV4 variant="mellowed" onClick={toggleDrawer}>
          Surplus Payout
        </VegaButtonV4>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={1}>
          <VegaTextfieldV4
            onClick={handleClick}
            value={
              Boolean(values.endDate && values.endDate)
                ? `${dayjs(values.startDate).format(
                    DATE_FORMATE_SHORT
                  )} - ${dayjs(values.endDate).format(DATE_FORMATE_SHORT)}`
                : undefined
            }
            standardWidth={Boolean(values.endDate && values.endDate) ? 190 : 95}
            placeholder="By Date"
            sx={{ caretColor: 'transparent' }}
            InputProps={getDateInputIcons(Boolean(values.startDate), clearDate)}
          />
        </Stack>
        <VegaSearchfieldV4
          value={values.search}
          onChange={updateRecord('search')}
          onClear={clearSearch}
          placeholder="Search by surplus Id..."
        />
      </Stack>
      {!Boolean(records?.length) && !isFetching ? (
        <VegaNoResults errorText="No Surplus found" />
      ) : (
        <VegaTableV4
          head={[
            'Transaction Time',
            'Transaction ID',
            'Transaction Amount',
            'Surplus Amount',
            'Surplus Type',
            'Indicator',
            'Status',
          ]}
          data={structuredTableData}
          isLoading={isFetching}
          pagination={{
            rowsPerPage: pageSize,
            setRowsPerPage: setPageSize,
            setPage,
            page,
            totalRecords: data?.totalItems || 0,
          }}
        />
      )}
      <SurplusPayoutDrawer
        open={openDrawer}
        header="Surplus Payout"
        onClose={toggleDrawer}
      />
      <VegaDateRangePickerPopoverV4
        open={openRange}
        onClose={handleClose}
        submitDateRange={submitAndClose}
        popoverProps={{
          anchorEl,
          onClose: handleClose,
          open: openRange,
          id,
        }}
        initialValues={{
          startDate: values.startDate || '',
          endDate: values.endDate || '',
        }}
      />
    </Stack>
  );
};

export default Surplus;
