import { Box, Stack } from '@mui/material';
import { MouseEvent, useMemo, useState } from 'react';
import { PageHeadingTypo } from '../../../../../common/Typography';
import VegaSearchfieldV4 from '../../../../../common/v4/VegaSearchfieldV4';
import VegaTableV4 from '../../../../../common/v4/VegaTableV4';
import VegaButtonV4 from '../../../../../common/v4/VegaButtonV4';
import WaiveAmountDrawer from './WaiveAmountDrawer';
import { useWaiverListQuery } from '../../../../../../store/queries/transactionQueries';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { getWaiverListStructuredData } from '../../../supportUtils';
import VegaNoResults from '../../../../../common/VegaNoResults';
import { useKeyValHook } from '../../../../../../hooks/useKeyValHook';
import VegaTextfieldV4 from '../../../../../common/v4/VegaTextfieldV4';
import dayjs from 'dayjs';
import { DATE_FORMATE_SHORT } from '../../../../../../constants/url';
import VegaDateRangePickerPopoverV4 from '../../../../../common/v4/VegaDateRangePickerPopoverV4';
import { getDateInputIcons } from '../../../../../../utils/common.utils';
import useDateRangeStandardHook from '../../../../../../hooks/useDateRangeStandardHook';

interface IWaiverFilter {
  date?: Date;
  search?: string;
  startDate?: string;
  endDate?: string;
}

type Keys = 'date' | 'search' | 'startDate' | 'endDate';

const Waiver = () => {
  const { accountData } = useSupport();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const updatePage2Zero = () => {
    setPage(0);
  };

  const { updateValue, values } = useKeyValHook<IWaiverFilter, Keys>(
    {
      search: undefined,
    },
    updatePage2Zero
  );

  const { startDate2Pass, endDate2Pass } = useDateRangeStandardHook(
    values.startDate,
    values.endDate
  );

  const { data, isFetching } = useWaiverListQuery({
    accountId: accountData?.accountId || '',
    pageNumber: page,
    pageSize,
    search: values.search,
    startDate: startDate2Pass,
    endDate: endDate2Pass,
  });

  const structuredData = getWaiverListStructuredData(data?.records);

  const toggleOpenDrawer = () => {
    setOpenDrawer((val) => !val);
  };

  const updateRecord = (key: Keys) => (e: any) => {
    updateValue(
      key,
      key === 'search' && e.target.value === ''
        ? undefined
        : (e.target.value as string)
    );
  };

  const clearSearch = () => {
    updateValue('search', undefined);
  };

  const clearDate = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    updateValue('startDate', undefined);
    updateValue('endDate', undefined);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitAndClose = (startDate: string, endDate: string) => {
    updateValue('startDate', startDate);
    updateValue('endDate', endDate);
    handleClose();
  };

  const openRange = Boolean(anchorEl);
  const id = openRange ? 'simple-popover' : undefined;

  return (
    <Box>
      <Stack gap={2}>
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <PageHeadingTypo>Waiver</PageHeadingTypo>
          <VegaButtonV4 variant="mellowed" onClick={toggleOpenDrawer}>
            Waive Amount
          </VegaButtonV4>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={1}>
            <VegaTextfieldV4
              onClick={handleClick}
              value={
                Boolean(values.endDate && values.endDate)
                  ? `${dayjs(values.startDate).format(
                      DATE_FORMATE_SHORT
                    )} - ${dayjs(values.endDate).format(DATE_FORMATE_SHORT)}`
                  : undefined
              }
              standardWidth={
                Boolean(values.endDate && values.endDate) ? 190 : 95
              }
              placeholder="By Date"
              sx={{ caretColor: 'transparent' }}
              InputProps={getDateInputIcons(
                Boolean(values.startDate),
                clearDate
              )}
            />
          </Stack>
          <VegaSearchfieldV4
            value={values.search}
            onChange={updateRecord('search')}
            onClear={clearSearch}
            placeholder="Search by Transaction Id..."
          />
        </Stack>
        {!Boolean(data?.records.length) && !isFetching ? (
          <VegaNoResults errorText="Waiver list is Empty" />
        ) : (
          <VegaTableV4
            head={[
              'Transaction Time',
              'Transaction ID',
              'Transaction Amount',
              'Waiver Amount',
              'Waiver Type',
              'Added by',
              'Status',
            ]}
            data={structuredData}
            isLoading={isFetching}
            pagination={{
              rowsPerPage: pageSize,
              setRowsPerPage: setPageSize,
              setPage,
              page,
              totalRecords: data?.totalItems || 0,
            }}
          />
        )}
      </Stack>
      <WaiveAmountDrawer open={openDrawer} onClose={toggleOpenDrawer} />
      <VegaDateRangePickerPopoverV4
        open={openRange}
        onClose={handleClose}
        submitDateRange={submitAndClose}
        popoverProps={{
          anchorEl,
          onClose: handleClose,
          open: openRange,
          id,
        }}
        initialValues={{
          startDate: values.startDate || '',
          endDate: values.endDate || '',
        }}
      />
    </Box>
  );
};

export default Waiver;
