import { Box, Grid, Stack } from '@mui/material';
import {
  ComponentBody1Typo,
  PageHeadingTypo,
  SectionHeading2Typo,
} from '../../common/Typography';
import { QUICK_ACCESS } from '../../../constants/style';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import VegaBadgeStatusTagV4 from '../../common/v4/VegaBadgeStatusTagV4';
import VegaVerticalStepper from '../../common/v4/VegaVerticalStepperV4';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from 'react';
import {
  downloadPdf,
  getOfferByApplicationId,
} from '../../../api/applications';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { VegaLosLimitOffer } from '../../../types/los';
import { DateUtility } from '../../../utils/dateTime';
import {
  applicationMappingObject,
  getApplicationStatusColor,
} from '../../BackOffice/Support_New/supportUtils';
import { ApplicationsResponse } from '../../../types/applications';
enum ConsentType {
  LOAN_AGGREMENT = 'SANCTION_LETTER',
  KEY_FACT_STATEMENT = 'KEY_FACT_STATEMENT',
}
const EndIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.81006 13.6154C3.07261 13.8801 3.42871 14.0289 3.80001 14.0289H12.2C12.5713 14.0289 12.9274 13.8801 13.19 13.6154M8.00079 1.97107V9.97107M8.00079 9.97107L11.2008 6.9143M8.00079 9.97107L4.80079 6.9143"
        stroke="#1047DC"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default function UserApplicationDetail({
  onBtnClick,
  applicationData,
}: {
  onBtnClick: () => void;
  applicationData: ApplicationsResponse | undefined;
}) {
  const [offerData, setOfferData] = useState<VegaLosLimitOffer | undefined>(
    undefined
  );
  const applicationId = applicationData?.id;
  const { setSnackbar } = useSnackbar();
  const updatedAt = DateUtility.formatToDDMMYYHHMMA(
    applicationData?.updatedAt ?? ''
  );

  const fetchOffers = async () => {
    try {
      const res = await getOfferByApplicationId(applicationId ?? '');
      if (res.status !== 200) {
        throw new Error('something went wrong');
      }
      setOfferData(res?.data);
    } catch (err) {
      setSnackbar(getErrorMessageFromErrorObj(err), 'error');
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [applicationId]);

  const personalDetails = [
    {
      key: 'Customer Id',
      value: applicationData?.customerId,
    },
    {
      key: 'Name',
      value: applicationData?.users[0]?.userBasicInfo?.name,
    },
    {
      key: 'Mobile',
      value: applicationData?.phone,
    },
    {
      key: 'Offer Id',
      value: offerData?.id ?? 'NA',
    },
    {
      key: 'Offer Limit',
      value: offerData?.offerAmount ?? 'NA',
    },
    {
      key: 'Expiry Date',
      value: offerData?.offerExpiryTime
        ? DateUtility.formatToMMYY(offerData?.offerExpiryTime)
        : 'NA',
    },
  ];
  const totalSteps = [
    'Aadhar Verification',
    'Selfie',
    'Address Verification',
    'vKyc',
    'CAF',
    'Offer Acceptance',
  ];

  let completedSteps: string[] = applicationData?.completedStates || [];

  completedSteps = completedSteps.map((ele: string, index) => {
    return applicationMappingObject[ele];
  });

  const getPdf = async (consentType: string, applicationId: string) => {
    try {
      const res = await downloadPdf(consentType, applicationId);
      const url = res?.data?.documentUrl;
      if (!url) {
        throw new Error('Unable to download Pdf ');
      }
      window.open(url, '__blank');
    } catch (err) {
      setSnackbar('Unable to download Pdf', 'error');
    }
  };

  return (
    <Stack gap={2}>
      <Stack
        sx={{
          marginLeft: '24px',
        }}
        direction={'row'}
        gap={1.5}
      >
        <ArrowBackIcon
          onClick={onBtnClick}
          fontSize="small"
          className="cursor-pointer"
        />
        <PageHeadingTypo>View Application Details</PageHeadingTypo>
      </Stack>
      <Box px={3}>
        <Grid container spacing={3}>
          <Grid item sm={6} md={12}>
            <Box
              bgcolor={'#FFF'}
              p={3}
              sx={{
                borderRadius: '8px',
                border: `1px solid ${QUICK_ACCESS.border[50]}`,
              }}
            >
              <Stack gap={3}>
                <SectionHeading2Typo>Personal Details</SectionHeading2Typo>
                <Stack direction={'row'} gap={6}>
                  <Stack gap={2}>
                    {personalDetails
                      .slice(0, 3)
                      .map(({ key, value }: any, index: any) => (
                        <Stack direction={'row'}>
                          <ComponentBody1Typo
                            sx={{
                              color: '#676B76',
                              width: '120px',
                            }}
                          >
                            {key}
                          </ComponentBody1Typo>
                          <ComponentBody1Typo
                            sx={{
                              color: '#1B1D22',
                              minWidth: '300px',
                            }}
                          >
                            {value}
                          </ComponentBody1Typo>
                        </Stack>
                      ))}
                    <Stack gap={2} sx={{ marginTop: '16px' }}>
                      {personalDetails.slice(3).map(({ key, value }: any) => (
                        <Stack direction={'row'}>
                          <ComponentBody1Typo
                            sx={{
                              color: '#676B76',
                              width: '120px',
                            }}
                          >
                            {key}
                          </ComponentBody1Typo>
                          <ComponentBody1Typo
                            sx={{
                              color: '#1B1D22',
                              minWidth: '300px',
                            }}
                          >
                            {value}
                          </ComponentBody1Typo>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                  <Stack gap={2} sx={{ width: '100%' }}>
                    <Box>
                      <ComponentBody1Typo
                        sx={{
                          color: '#676B76',
                        }}
                      >
                        Key Fact Statement
                      </ComponentBody1Typo>
                      <VegaButtonV4
                        onClick={() => {
                          getPdf(
                            ConsentType.KEY_FACT_STATEMENT,
                            applicationId ?? ''
                          );
                        }}
                        endIcon={<EndIcon />}
                        sx={{
                          borderRadius: '6px',
                          padding: '8px',
                          width: '100%',
                          backgroundColor: '#F7F7F7',
                          borderColor: 'none',
                          color: '#1047DC',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        variant="contained"
                      >
                        <ComponentBody1Typo>
                          keyfactstatement.pdf
                        </ComponentBody1Typo>
                      </VegaButtonV4>
                    </Box>
                    <Box>
                      <ComponentBody1Typo
                        sx={{
                          color: '#676B76',
                        }}
                      >
                        Loan Aggrement
                      </ComponentBody1Typo>
                      <VegaButtonV4
                        onClick={() => {
                          getPdf(
                            ConsentType.LOAN_AGGREMENT,
                            applicationId ?? ''
                          );
                        }}
                        endIcon={<EndIcon />}
                        sx={{
                          borderRadius: '6px',
                          padding: '8px',
                          width: '100%',
                          backgroundColor: '#F7F7F7',
                          borderColor: 'none',
                          color: '#1047DC',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        variant="contained"
                      >
                        <ComponentBody1Typo>
                          loanaggrement.pdf
                        </ComponentBody1Typo>
                      </VegaButtonV4>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Box
              bgcolor={'#FFF'}
              p={3}
              sx={{
                borderRadius: '8px',
                border: `1px solid ${QUICK_ACCESS.border[50]}`,
              }}
            >
              <Stack gap={2} sx={{ marginBottom: '24px' }}>
                <Stack justifyContent={'space-between'} direction={'row'}>
                  <PageHeadingTypo>Application Status</PageHeadingTypo>
                  <VegaBadgeStatusTagV4
                    variant={'stroked'}
                    text={applicationData?.status || '--'}
                    color={getApplicationStatusColor(applicationData?.status)}
                  />
                </Stack>
                <Stack direction={'row'}>
                  <ComponentBody1Typo
                    sx={{
                      color: QUICK_ACCESS.text[100],
                    }}
                  >
                    Application Id
                  </ComponentBody1Typo>
                  <ComponentBody1Typo
                    sx={{
                      color: QUICK_ACCESS.text[200],
                      marginLeft: '8px',
                    }}
                  >
                    {applicationId}
                  </ComponentBody1Typo>
                </Stack>
              </Stack>
              <VegaVerticalStepper
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                updatedAt={updatedAt}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
