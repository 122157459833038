import { SVGProps } from 'react';

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.58398 5.646H13.084M3.94113 1.3335V2.45863M11.584 1.3335V2.4585M11.584 2.4585H4.08398C2.84134 2.4585 1.83398 3.46586 1.83398 4.7085V12.2086C1.83398 13.4512 2.84134 14.4586 4.08398 14.4586H11.584C12.8266 14.4586 13.834 13.4512 13.834 12.2086L13.834 4.7085C13.834 3.46586 12.8266 2.4585 11.584 2.4585ZM13.834 10.146H9.70898M9.70898 10.146H5.95898M9.70898 10.146V13.896M9.70898 10.146V6.02103M5.95898 10.146H1.83398M5.95898 10.146V13.896M5.95898 10.146V6.02103"
      stroke="#999DA8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CalendarIcon;
