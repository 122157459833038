import dayjs from 'dayjs';
import { useClientAuth } from '../providers/ClientProvider';

// export const clientId = '0b9fafc9-0f42-4fb9-89d4-10dccd80c643';
// export const branchId = '4c8dd74e-7277-4fe5-b08b-901a26902be4';
export const CORPORATE_ID = '008c6b89-1f6c-4924-aed3-28259cf55875';

export const DEFAULT_CURRENCY = [
  'USD',
  'CAD',
  'EUR',
  'AUD',
  'GBP',
  'SGD',
  'SAR',
  'CHF',
  'THB',
  'AED',
];
export const DEFAULT_CURRENCY_INR = [
  'INR',
  'USD',
  'CAD',
  'EUR',
  'AUD',
  'GBP',
  'SGD',
  'SAR',
  'CHF',
  'THB',
  'AED',
];

export const dateFormat = (date: any) => {
  return dayjs(date).format('DD-MM-YYYY');
};

export const STATUS_DATA = [
  {
    value: 'APPROVED',
    label: 'APPROVED',
  },
  {
    value: 'REJECTED',
    label: 'REJECTED',
  },
  {
    value: 'CHANGES_REQUESTED',
    label: 'CHANGES_REQUESTED',
  },
];

export const STATUS = [
  'ALL',
  'REVIEWER_PENDING',
  'CHECKER_PENDING',
  'CHANGES_REQUESTED',
  'APPROVED',
  'REJECTED',
];
