const EditIconV4 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M7.36582 2.23437H3.36582C2.04034 2.23437 0.96582 3.30889 0.96582 4.63437V12.6344C0.96582 13.9599 2.04034 15.0344 3.36582 15.0344H11.3658C12.6913 15.0344 13.7658 13.9599 13.7658 12.6344L13.7658 8.63441M4.96582 11.0344L7.87648 10.4479C8.03099 10.4168 8.17287 10.3407 8.2843 10.2292L14.8001 3.70982C15.1125 3.39725 15.1123 2.8906 14.7996 2.57829L13.4193 1.19957C13.1068 0.887393 12.6004 0.887605 12.2881 1.20005L5.77166 7.72008C5.66045 7.83135 5.58452 7.97294 5.55336 8.12714L4.96582 11.0344Z"
      stroke={props.color || '#1047DC'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EditIconV4;
