import { Box, Stack } from '@mui/material';
import { ListHead2Typo } from '../Typography';
import { PRIMARY_COLOR, QUICK_ACCESS } from '../../../constants/style';
import VegaDateRangePickerPopoverV4 from './VegaDateRangePickerPopoverV4';
import CloseIcon from '@mui/icons-material/Close';
import { MouseEvent, useState } from 'react';

interface IVegaFilterButtonsV4Props {
  data: {
    text: string;
    icon?: React.ReactNode;
    iconActive?: React.ReactNode;
    values?: {
      startDate: string;
      endDate: string;
    };
  }[];
  activeIdx?: number;
  onCellClick: (idx: number) => void;
  submitDateRange: (startDate: string, endDate: string) => void;
  customIdx?: number;
  onCloseCustomRangeClick?: () => void;
}

const VegaFilterButtonsV4 = ({
  data,
  activeIdx,
  onCellClick,
  submitDateRange,
  customIdx,
  onCloseCustomRangeClick,
}: IVegaFilterButtonsV4Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitAndClose = (startDate: string, endDate: string) => {
    submitDateRange(startDate, endDate);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Stack
        sx={{
          borderRadius: '4px',
          border: `1px solid ${QUICK_ACCESS.border[50]}`,
          width: 'fit-content',
        }}
        direction="row"
        className="cursor-pointer"
      >
        {data.map((item, idx) => {
          const isActive = idx === activeIdx;
          const isCustomActive = customIdx === activeIdx;
          const isCustomIdx = idx === 0;

          return (
            <>
              <div
                aria-describedby={isCustomIdx ? id : undefined}
                onClick={isCustomIdx ? handleClick : undefined}
              >
                <Stack
                  onClick={() => onCellClick(idx)}
                  direction="row"
                  gap={0.5}
                  alignItems={'center'}
                  px={1.5}
                  py={1}
                  borderLeft={
                    isCustomIdx
                      ? undefined
                      : `1px solid ${QUICK_ACCESS.border[50]}`
                  }
                  sx={{
                    backgroundColor: isActive
                      ? PRIMARY_COLOR.blue[50]
                      : undefined,
                  }}
                  key={item.text || idx}
                >
                  {isActive ? item.iconActive || item.icon : item.icon}
                  <ListHead2Typo
                    sx={{
                      color: isActive
                        ? PRIMARY_COLOR.blue[300]
                        : QUICK_ACCESS.text[100],
                    }}
                  >
                    {item.text}
                  </ListHead2Typo>
                  {isCustomActive && isActive && (
                    <CloseIcon
                      sx={{ color: PRIMARY_COLOR.blue[300], fontSize: 17 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onCloseCustomRangeClick?.();
                      }}
                    />
                  )}
                </Stack>
              </div>
              {isCustomIdx && (
                <VegaDateRangePickerPopoverV4
                  open={open}
                  onClose={handleClose}
                  submitDateRange={submitAndClose}
                  popoverProps={{
                    anchorEl,
                    onClose: handleClose,
                    open,
                    id,
                  }}
                  initialValues={data[0]?.values}
                />
              )}
            </>
          );
        })}
      </Stack>
    </Box>
  );
};

export default VegaFilterButtonsV4;
