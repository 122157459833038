import { useMemo } from 'react';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import DataTile from '../DataTile';
import {
  InterestPolicyInterface,
  InterestRuleInterface,
} from '../../../types/policy';
import type { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import ProgramDataGrid from './ProgramDataGrid';
import { convertCamelCase } from '../../../utils/stringUtils';
import capitalize from 'lodash/capitalize';
import { QUICK_ACCESS } from '../../../constants/style';
import { SectionHeadingTypo } from '../../common/Typography';
import VegaAccordionV4, {
  VegaAccordionSummary,
  VegaAccordionDetails,
} from '../../common/v4/VegaAccordionV4';

type InterestPolicyTableData = InterestRuleInterface & {
  type: 'Cash' | 'Non Cash';
};

const getInterestPolicyTableColumns =
  (): GridColDef<InterestPolicyTableData>[] => [
    {
      field: 'type',
      headerName: 'Position Type',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
    {
      field: 'interest_rate_value_type',
      headerName: 'Value Type',
      flex: 1,
      sortable: false,
      minWidth: 180,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        convertCamelCase(params.value),
    },
    {
      field: 'default_rate',
      headerName: 'APR',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
  ];

const getInterestPolicyTransactionTableColumns =
  (): GridColDef<InterestPolicyTableData>[] => [
    {
      field: 'from_amount',
      headerName: 'From Amount',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
    {
      field: 'to_amount',
      headerName: 'To Amount',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      flex: 1,
      sortable: false,
      minWidth: 180,
    },
  ];

export const POSTING_CATEGORIES = ['CASH_PRINCIPAL', 'RETAIL_PRINCIPAL'];
export const POSTING_CATEGORIES_WITH_EMI = [
  'CASH_PRINCIPAL',
  'RETAIL_PRINCIPAL',
  'EMI_PRINCIPAL',
];

type ViewInterestPolicyProps = {
  policy: InterestPolicyInterface;
};

function ViewInterestPolicy({ policy }: ViewInterestPolicyProps) {
  const columnsRef = useMemo(() => getInterestPolicyTableColumns(), []);
  const transactionColumnsRef = useMemo(
    () => getInterestPolicyTransactionTableColumns(),
    []
  );

  const transactionPolicy =
    policy?.default_interest_rate_rule?.posting_category_to_interest_rate_value;

  const interestRateRules = policy?.interest_status_to_interest_rate_rules;

  const interestRateTableData = useMemo(() => {
    return Object.keys(interestRateRules).map((category) => {
      const data =
        interestRateRules[category]?.posting_category_to_interest_rate_value;
      const tableData = POSTING_CATEGORIES_WITH_EMI.filter(
        (principal) => data[principal]
      ).map((principal) => ({
        type:
          principal === 'CASH_PRINCIPAL'
            ? 'Cash'
            : principal === 'RETAIL_PRINCIPAL'
            ? 'Non Cash'
            : 'EMI',
        ...data[principal],
      }));
      return {
        title: capitalize(category),
        data: tableData,
      };
    });
  }, [interestRateRules]);

  if (!policy) {
    return null;
  }

  return (
    <Box
      sx={{
        py: 3,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <VegaAccordionV4>
        <VegaAccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="small" />}
          sx={{ flexDirection: 'row' }}
        >
          <SectionHeadingTypo
            sx={{
              color: QUICK_ACCESS.text[200],
            }}
          >
            Transaction
          </SectionHeadingTypo>
        </VegaAccordionSummary>
        <VegaAccordionDetails>
          <>
            {POSTING_CATEGORIES.map((category) => (
              <Box
                key={category}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  mt: 2,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={4}>
                    <DataTile
                      title="Position Type"
                      value={
                        category === 'RETAIL_PRINCIPAL' ? 'Non Cash' : 'Cash'
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <DataTile
                      title="Interest Rate Value Type"
                      value={
                        transactionPolicy[category]?.interest_rate_value_type
                          ? convertCamelCase(
                              transactionPolicy[category]
                                ?.interest_rate_value_type
                            )
                          : '-'
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <DataTile
                      title="Default APR"
                      value={transactionPolicy[category]?.default_rate ?? '-'}
                    />
                  </Grid>
                </Grid>
                {transactionPolicy[category].amount_slab_interest_rates ? (
                  <ProgramDataGrid
                    data={
                      transactionPolicy[category].amount_slab_interest_rates
                    }
                    columns={transactionColumnsRef}
                    idColumn={'from_amount'}
                  />
                ) : null}
              </Box>
            ))}
          </>
        </VegaAccordionDetails>
      </VegaAccordionV4>
      {interestRateTableData.map((value, index) => (
        <VegaAccordionV4 key={index}>
          <VegaAccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="small" />}
            sx={{ flexDirection: 'row' }}
          >
            <SectionHeadingTypo
              sx={{
                color: QUICK_ACCESS.text[200],
              }}
            >
              {value.title}
            </SectionHeadingTypo>
          </VegaAccordionSummary>
          <VegaAccordionDetails>
            <Box
              sx={{
                mt: 2,
              }}
            >
              <ProgramDataGrid
                data={value.data}
                columns={columnsRef}
                idColumn={'type'}
              />
            </Box>
          </VegaAccordionDetails>
        </VegaAccordionV4>
      ))}
    </Box>
  );
}

export default ViewInterestPolicy;
