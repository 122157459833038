import { ISVGProps } from '../../mcc/types/Commons';

const ProfileIcon = (props: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M2.79998 13.2003C3.02955 12.9432 4.57187 11.7319 5.33848 11.1347C5.61768 10.9172 5.96016 10.8003 6.31407 10.8003C7.16984 10.8003 8.81892 10.8003 9.67881 10.8003C10.0369 10.8003 10.3827 10.9222 10.671 11.1346C11.6609 11.8638 12.5888 12.4071 13.6 13.2003M3.99998 14.4001H12C13.3255 14.4001 14.4 13.3256 14.4 12.0001V4.0001C14.4 2.67461 13.3255 1.6001 12 1.6001H3.99998C2.67449 1.6001 1.59998 2.67461 1.59998 4.0001V12.0001C1.59998 13.3256 2.67449 14.4001 3.99998 14.4001ZM10.2923 6.18146C10.2923 4.96045 9.26158 3.96282 7.99997 3.96282C6.73839 3.96282 5.70761 4.96045 5.70761 6.18146C5.70761 7.40247 6.73839 8.4001 7.99997 8.4001C9.26156 8.4001 10.2923 7.40247 10.2923 6.18146Z"
        stroke={props.stroke || '#EBEDEF'}
      />
    </svg>
  );
};

export default ProfileIcon;
