import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import debounce from 'lodash/debounce';
import { GridEventListener } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { VPHeader } from '../ui-components/common';
import FilterProgramManagement from '../components/ProgramManagement/filters/FilterProgramManagement';
import {
  VegaProgramCategoryType,
  VegaProgramWithMetaDataType,
} from '../types/program';
import { VegaPage } from '../components/common';
import { listForClientWithMetaData } from '../api/program';
import { useClientAuth } from '../providers/ClientProvider';
import { getProgramManagementDashboardColumns } from '../components/ProgramManagement/columns';
import { useSnackbar } from '../providers/SnackbarProvider';
import type { PaginatedResponse } from '../mcc/types/Commons';
import ProgramDetailPage from '../components/ProgramManagement/ProgramDetailPage';
import { useAppDispatch, useAppSelector } from '../store/hook';
import {
  clearProgramManagement,
  setSelectedProgram,
} from '../store/common/programManagementSlice';
import Stack from '@mui/material/Stack';
import AddProgram from '../components/ProgramManagement/AddProgram';
import VegaNoResults from '../components/common/VegaNoResults';
import VegaButtonV4 from '../components/common/v4/VegaButtonV4';
import AddSquare02Icon from '../components/icon/AddSquare02Icon';
import VegaDataGridV4 from '../components/common/v4/VegaDataGridV4';

type ProgramsWithMetaDataPayload = {
  clientId: string;
  page?: number;
  pageSize?: number;
  type?: string;
  category?: VegaProgramCategoryType;
  name?: string;
};

function fetchProgramsWithMetaData(
  data: ProgramsWithMetaDataPayload
): Promise<PaginatedResponse<VegaProgramWithMetaDataType>> {
  return new Promise((resolve, reject) => {
    listForClientWithMetaData(data)
      .then((resp) => {
        const programsFromResponse = resp.data;
        if (!programsFromResponse) {
          reject(new AxiosError('Failed to fetch programs for client'));
        }
        resolve(programsFromResponse);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function ProgramManagement() {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();

  const [activeTableType, setActiveTableType] =
    useState<VegaProgramCategoryType>(VegaProgramCategoryType.CREDIT_CARD);
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState<VegaProgramWithMetaDataType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const activeProgram = useAppSelector(
    (state) => state.programManagement.selectedProgram
  );
  const [isOnCreatePage, setIsOnCreatePage] = useState(false);
  const dispatch = useAppDispatch();

  const fetchPrograms = useCallback((data: ProgramsWithMetaDataPayload) => {
    setIsLoading(true);
    fetchProgramsWithMetaData({
      ...data,
    })
      .then((res) => {
        setPrograms(res.records);
        setRowCount(res.totalItems);
      })
      .catch((err) => {
        setSnackbar(
          'Unfortunately, there was an error. Please try again!',
          'error'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const debouncedFetch = React.useRef(debounce(fetchPrograms, 500)).current;

  useEffect(() => {
    if (!clientId) return;

    fetchPrograms({
      clientId,
      category: activeTableType,
      page,
      pageSize,
      name: searchValue,
    });
  }, [activeTableType, page, pageSize]);

  useEffect(() => {
    return () => {
      dispatch(clearProgramManagement());
    };
  }, []);
  const onProgramViewDetailsClicked = (
    program: VegaProgramWithMetaDataType
  ) => {
    dispatch(setSelectedProgram(program));
  };
  const columnsRef = useMemo(
    () => getProgramManagementDashboardColumns(onProgramViewDetailsClicked),
    []
  );

  const onSearchValueChange = (value: string) => {
    if (!clientId) return;

    setSearchValue(value);

    debouncedFetch({
      clientId,
      category: activeTableType,
      page,
      pageSize,
      name: value,
    });
  };

  const resetActiveProgram = () => {
    dispatch(clearProgramManagement());
  };

  if (activeProgram) {
    return <ProgramDetailPage handleOnBreadcrumbClick={resetActiveProgram} />;
  }

  if (isOnCreatePage) {
    return <AddProgram handleGoBackClicked={() => setIsOnCreatePage(false)} />;
  }

  const errorMessage =
    activeTableType === VegaProgramCategoryType.CREDIT_CARD
      ? 'No Credit Cards Found'
      : activeTableType === VegaProgramCategoryType.PREPAID_CARD
      ? 'No Prepaid Cards Found'
      : activeTableType === VegaProgramCategoryType.FOREX_CARD
      ? 'No Forex Cards Found'
      : 'No Loans Found';

  return (
    <VegaPage sx={{ gap: 3 }}>
      <VPHeader
        text="Program Management"
        component={
          <Stack direction="row" justifyContent="flex-end">
            <VegaButtonV4
              startIcon={<AddSquare02Icon />}
              onClick={() => setIsOnCreatePage(true)}
              variant="contained"
            >
              Add Program
            </VegaButtonV4>
          </Stack>
        }
      />
      <Box
        sx={{
          px: 3.25,
        }}
      >
        <FilterProgramManagement
          activeTable={activeTableType}
          onTableChange={setActiveTableType}
          searchValue={searchValue}
          handlesSearchValueChange={onSearchValueChange}
        />
        {!isLoading && programs.length === 0 ? (
          <VegaNoResults errorText={errorMessage} />
        ) : (
          <VegaDataGridV4
            data={programs ?? []}
            columns={columnsRef}
            idColumn={'programId'}
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            rowCount={rowCount}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            loading={isLoading}
            bgColor="transparent"
            getRowClassName={() => 'vegatable--white'}
            disableColumnFilter
          />
        )}
      </Box>
    </VegaPage>
  );
}

export default ProgramManagement;
