/* eslint-disable react/jsx-pascal-case */
import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../../../common/Typography';
import VegaTableV4 from '../../../../../common/v4/VegaTableV4';
import { MouseEvent, useState } from 'react';
import EMIDetails from './EMIDetails';
import VegaNoResults from '../../../../../common/VegaNoResults';
import { useEMIListQuery } from '../../../../../../store/queries/emiQueries';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { getEMIListForStructuredData } from '../../../supportUtils';
import VegaSearchfieldV4 from '../../../../../common/v4/VegaSearchfieldV4';
import { EMIStatus } from '../../../../../../types/emis';
import { useKeyValHook } from '../../../../../../hooks/useKeyValHook';
import VegaSelectV4 from '../../../../../common/v4/VegaSelectV4';
import { pascalCaseFromCamelCase } from '../../../../../../utils/stringUtils';
import VegaTextfieldV4 from '../../../../../common/v4/VegaTextfieldV4';
import dayjs from 'dayjs';
import { DATE_FORMATE_SHORT } from '../../../../../../constants/url';
import VegaDateRangePickerPopoverV4 from '../../../../../common/v4/VegaDateRangePickerPopoverV4';
import { getDateInputIcons } from '../../../../../../utils/common.utils';
import useDateRangeStandardHook from '../../../../../../hooks/useDateRangeStandardHook';

interface IEMIFilter {
  date?: Date;
  status?: EMIStatus | 'All';
  search?: string;
  startDate?: string;
  endDate?: string;
}

type Keys = 'date' | 'status' | 'search' | 'startDate' | 'endDate';

const EMIs = () => {
  const { accountData, selectedEMI, setSelectedEMI } = useSupport();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const updatePage2Zero = () => {
    setPage(0);
  };

  const { updateValue, values } = useKeyValHook<IEMIFilter, Keys>(
    {
      status: 'All',
      search: undefined,
    },
    updatePage2Zero
  );

  const statusList =
    values.status && values.status !== 'All' ? [values.status] : undefined;

  const { startDate2Pass, endDate2Pass } = useDateRangeStandardHook(
    values.startDate,
    values.endDate
  );

  const { data, isFetching } = useEMIListQuery({
    accountId: accountData?.accountId || '',
    pageNumber: page,
    pageSize,
    statusList,
    search: values.search,
    startDate: startDate2Pass,
    endDate: endDate2Pass,
  });

  const goBack = () => {
    setSelectedEMI(undefined);
  };

  const records = data?.records;

  const structuredTableData = getEMIListForStructuredData(
    records,
    setSelectedEMI
  );

  const updateRecord = (key: Keys) => (e: any) => {
    updateValue(
      key,
      key === 'search' && e.target.value === ''
        ? undefined
        : (e.target.value as string)
    );
  };

  const clearSearch = () => {
    updateValue('search', undefined);
  };

  const clearDate = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    updateValue('startDate', undefined);
    updateValue('endDate', undefined);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitAndClose = (startDate: string, endDate: string) => {
    updateValue('startDate', startDate);
    updateValue('endDate', endDate);
    handleClose();
  };

  const openRange = Boolean(anchorEl);
  const id = openRange ? 'simple-popover' : undefined;

  return selectedEMI ? (
    <EMIDetails data={selectedEMI} goBack={goBack} />
  ) : (
    <Stack gap={2}>
      <PageHeadingTypo>EMIs</PageHeadingTypo>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={1}>
          <VegaTextfieldV4
            onClick={handleClick}
            value={
              Boolean(values.endDate && values.endDate)
                ? `${dayjs(values.startDate).format(
                    DATE_FORMATE_SHORT
                  )} - ${dayjs(values.endDate).format(DATE_FORMATE_SHORT)}`
                : undefined
            }
            standardWidth={Boolean(values.endDate && values.endDate) ? 190 : 95}
            placeholder="By Date"
            sx={{ caretColor: 'transparent' }}
            InputProps={getDateInputIcons(Boolean(values.startDate), clearDate)}
          />
          <VegaSelectV4
            onChange={updateRecord('status')}
            value={values.status}
            options={['All', ...Object.values(EMIStatus)].map((el) => ({
              name: el === 'All' ? 'All status' : pascalCaseFromCamelCase(el),
              id: el,
            }))}
            standardWidth={130}
          />
        </Stack>
        <VegaSearchfieldV4
          value={values.search}
          onChange={updateRecord('search')}
          onClear={clearSearch}
          placeholder="Search by EMI Id..."
        />
      </Stack>
      {!Boolean(records?.length) && !isFetching ? (
        <VegaNoResults errorText="No EMIs found for the account" />
      ) : (
        <VegaTableV4
          head={[
            'Loan ID',
            'EMI ID',
            'Generation Date',
            'Start Date',
            'End Date',
            'EMI Amount',
            'Actions',
            'EMI Status',
          ]}
          data={structuredTableData}
          isLoading={isFetching}
          pagination={{
            rowsPerPage: pageSize,
            setRowsPerPage: setPageSize,
            setPage,
            page,
            totalRecords: data?.totalItems || 0,
          }}
        />
      )}
      <VegaDateRangePickerPopoverV4
        open={openRange}
        onClose={handleClose}
        submitDateRange={submitAndClose}
        popoverProps={{
          anchorEl,
          onClose: handleClose,
          open: openRange,
          id,
        }}
        initialValues={{
          startDate: values.startDate || '',
          endDate: values.endDate || '',
        }}
      />
    </Stack>
  );
};

export default EMIs;
