import { configureStore } from '@reduxjs/toolkit';
import stateSlice from './common/stateSlice';
import programManagementSlice from './common/programManagementSlice';
import accountClosureSlice from './common/accountClosureSlice';
import SimulatorSlice from './common/simulatorSlice';

export const store = configureStore({
  reducer: {
    header: stateSlice.reducer,
    programManagement: programManagementSlice.reducer,
    accountClosure: accountClosureSlice,
    simulator: SimulatorSlice.reducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
