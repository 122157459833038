import { useEffect, useMemo, useState } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';
import VegaSelector from '../../../components/common/VegaSelector';
import { toLowerCase } from '../../../constants/commonFunction';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { BinAllocation } from '../../../types/card';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  IPaginationProps,
  fetchBinAlloctionListForClient,
  fetchListForBinAlloctionOrder,
  fetchListForInventory,
  fetchListForStockTransfer,
  updateBinAllocationStatus,
  updateStockTransferStatus,
} from '../../Back-office/api/inventory';
import MCCFilterSection from '../../Back-office/components/MCCUserManagement/MCCFilterSection';
import { IFilterProps } from '../../Back-office/pages/MCCAplicationManagement';
import { CARDS } from '../../Back-office/pages/MCCInventoryManagement';
import { ISectionProps } from '../../Back-office/pages/MCCUserManagement';
import {
  IBinAllocationOrderListProps,
  IBinProps,
  IStockTranseferListProps,
} from '../../types/Bin';
import { fetchBranchById } from '../api/Branch';
import FilterAction from '../components/FilterAction';
import ListOfCheckerInventoryManagement from '../components/MCCInventoryManagementChecker/ListOfCheckerInventoryManagement';
import ListOfCheckerStockTransfer from '../components/MCCInventoryManagementChecker/ListOfCheckerStockTransfer';
import ListOfInventoryMangement from '../components/MCCInventoryManagementChecker/ListOfInventoryMangement';
import { IBranchProps } from './MCCBranch';
import { listBinForClient } from '../../../api/bin';

type IExtAllocatedBins = {
  branchName: string;
} & BinAllocation;

const CheckerInventoryManagement = () => {
  const { setSnackbar } = useSnackbar();
  const { clientId, userId, mccPlatformId: branchId } = useClientAuth();

  const [rowMultiSelect, setRowMultiSelect] = useState<string[]>([]);
  const [binData, setBinData] = useState<IBinProps[]>([]);
  const [activeState, setActiveState] = useState<ISectionProps>(CARDS[0]);

  const [binOrderData, setBinOrderData] = useState<
    IBinAllocationOrderListProps[]
  >([]);
  const [stockTransferData, setStockTransferData] = useState<
    IStockTranseferListProps[]
  >([]);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [stockListLoading, setStockListLoading] = useState<boolean>(false);

  const [filterData, setFilterData] = useState<IFilterProps>({
    mobile: '',
    status: 'ALL',
  });
  console.log('filterData', filterData);

  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  const onPageChange = (page: number) => {
    setPage(page);
  };
  const onPageSizeChange = (pageSize: number) => {
    setSize(pageSize);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleFilter = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const selectedTab = (data: any) => {
    setActiveState(data);
  };
  // for Inventory order
  const onSubmitStatus = (data: any) => {
    updateBinAllocationStatus(data)
      .then(async (res) => {
        setSnackbar('Update Successfully!');
        await getBinAllocationOrderList(filterData.mobile);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to update'),
          'error'
        );
        setListLoading(false);
      });
  };
  const onSubmitTransferStatus = (data: any) => {
    updateStockTransferStatus(data)
      .then(async (res) => {
        setSnackbar('Update Successfully!');
        await getStockTransferList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to update'),
          'error'
        );
        setListLoading(false);
      });
  };

  const onApprove = () => {
    rowMultiSelect.map((val: any) => {
      if (activeState.id === 'ORDER_INVENTORY') {
        const obj = { status: 'APPROVED', userId, binAllocationOrderId: val };
        onSubmitStatus(obj);
      } else {
        const obj = {
          status: 'APPROVED',
          userId,
          binAllocationTransferId: val,
        };
        onSubmitTransferStatus(obj);
      }
    });
  };
  const onReject = () => {
    rowMultiSelect.map((val: any) => {
      if (activeState.id === 'ORDER_INVENTORY') {
        const obj = { status: 'REJECTED', userId, binAllocationOrderId: val };
        onSubmitStatus(obj);
      } else {
        const obj = {
          status: 'REJECTED',
          userId,
          binAllocationTransferId: val,
        };
        onSubmitTransferStatus(obj);
      }
    });
  };

  const onSendMaker = async (data: any, status: string) => {
    const obj = {
      binAllocationOrderId: data.id,
      status: status,
      userId,
    };
    onSubmitStatus(obj);
  };
  const onSendMakerForTransfer = async (data: any, status: string) => {
    const obj = {
      binAllocationTransferId: data.id,
      status: status,
      userId,
    };
    onSubmitTransferStatus(obj);
  };

  const getBinAllocationEntityList = () => {
    setListLoading(true);
    // fetchListForInventory(clientId as string)
    listBinForClient(clientId as string, page, size)
      .then((res) => {
        setBinData(res.data.records);
        setRowCount(res.data.totalItems);
        setListLoading(false);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to fetch bin list'),
          'error'
        );
        setListLoading(false);
      });
  };
  const getBinAllocationOrderList = (binValue: string) => {
    setListLoading(true);
    fetchListForBinAlloctionOrder({
      clientId,
    })
      .then((res) => {
        setBinOrderData(res.data);
        setListLoading(false);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to fetch bin list'),
          'error'
        );
        setListLoading(false);
      });
  };

  const getStockTransferList = () => {
    setStockListLoading(true);
    fetchListForStockTransfer({
      // sourceEntityLevel: 'l1',
      clientId,
      // sourceEntityId: branchId,
      //branchId,
      // makerCheckerStatusList: ['UNDER_REVIEW'],
    })
      .then((res) => {
        setStockTransferData(res.data);
        setStockListLoading(false);
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to fetch bin list'),
          'error'
        );
        setStockListLoading(false);
      });
  };

  useEffect(() => {
    activeState.id === 'ORDER_INVENTORY' && getBinAllocationEntityList();
  }, [page, size, activeState]);
  useEffect(() => {
    activeState.id === 'ORDER_INVENTORY' &&
      getBinAllocationOrderList(filterData.mobile);
  }, [filterData.mobile, activeState]);

  useEffect(() => {
    activeState.id === 'STOCK_TRANSFER' && getStockTransferList();
  }, [activeState]);

  return (
    <VegaPage>
      <VegaSelector
        data={CARDS}
        setActiveState={selectedTab}
        active={activeState}
        header={toLowerCase(activeState.id)}
        subHeader="All Live programs till date."
      />
      <MCCFilterSection
        // isStatusFilter
        // statusOption={STATUS}
        state={filterData}
        handleChange={handleFilter}
        label={tabValue === 1 ? 'Search By Branch Name' : 'Search By bin no'}

        // onSearch={onSearch}
      />
      {activeState.id === 'ORDER_INVENTORY' && (
        <ListOfInventoryMangement
          data={binData}
          loading={listLoading}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          filterData={filterData}
          page={page}
          size={size}
          rowCount={rowCount}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      )}

      <VegaCard fullHeight noPad>
        <FilterAction
          options={['PENDING', 'APPROVED', 'REJECTED']}
          selected={'PENDING'}
          selectedCount={rowMultiSelect.length}
          onApprove={onApprove}
          onReject={onReject}
        />
        {activeState.id === 'ORDER_INVENTORY' ? (
          <ListOfCheckerInventoryManagement
            data={binOrderData}
            loading={listLoading}
            onSendMaker={onSendMaker}
            setRowMultiSelect={setRowMultiSelect}
          />
        ) : (
          <ListOfCheckerStockTransfer
            data={stockTransferData}
            loading={listLoading}
            onSendMaker={onSendMakerForTransfer}
            setRowMultiSelect={setRowMultiSelect}
          />
        )}
      </VegaCard>
    </VegaPage>
  );
};

export default CheckerInventoryManagement;
