import { Box, Stack, Tooltip } from '@mui/material';
import { IRowDataProps, ITableDataProps } from '../../common/v4/VegaTableV4';
import { getLogoForNetwork } from '../../icon/Icons';
import { PRIMARY_COLOR, QUICK_ACCESS } from '../../../constants/style';
import {
  CardCategory,
  CardDto,
  CardStatus,
  CustomerPreferenceStatus,
  PhysicalCardOrder,
} from '../../../types/card';
import { ComponentBody1Typo } from '../../common/Typography';
import {
  LoanDto,
  LoanStatus,
  TransactionChargeResponse,
  TransactionDto,
  WaiverDto,
  WaiverStatus,
} from '../../../types/transaction';
import dayjs from 'dayjs';
import {
  DATE_FORMATE_SHORT,
  DATE_TIME_SHORT_FORMAT,
} from '../../../constants/url';
import {
  convert2IndianNumberSystem,
  pascalCaseFromCamelCase,
  standardizeStatusText,
} from '../../../utils/stringUtils';
import {
  TransactionCode,
  TransactionIndicator,
  TransactionStatus,
} from '../../../enums/transaction';
import { ChargebackDto, ChargebackStatus } from '../../../types/chargeBack';
import { RaiseADisputeDrawer } from '../Support/ChargeBack';
import { ViewDisputeDetailsModal } from '../Support/ChargeBack/ViewDisputeDetails';
import VegaButtonV4 from '../../common/v4/VegaButtonV4';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import MessageSquareInfo from '../../icon/MessageSquareInfo';
import EyeOpenIcon from '../../icon/EyeOpenIcon';
import { BadgeStatusTagColor } from '../../common/v4/VegaBadgeStatusTagV4';
import {
  BillStatus,
  IBillResponseData,
  ITransactionForBillResponse,
  PhysicalStatementOrderDto,
  PhysicalStatementStatus,
} from '../../../types/bill';
import {
  RewardResponseProps,
  RewardsLedgerStatus,
} from '../../../types/reward';
import { AddressUtils } from '../../../utils/addressUtils';
import { EMIData, EMIStatus } from '../../../types/emis';
import ShareIcon from '../../icon/ShareIcon';
import { getBillPdf, getDigitalBillPdf } from '../../../api/bill';
import { CardControlPreferenceType } from './ApplcaitonDetails/AccountDetailsStep/CardControls/CardControls';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { AccountStatus } from '../../../enums/account.enum';
import { DateUtility } from '../../../utils/dateTime';
import {
  ApplicationCompletedStates,
  ApplicationStatus,
  ApplicationsResponse,
} from '../../../types/applications';
import DownloadIcon from '../../icon/DownloadIcon';
import { IDelinquencyListProps } from '../../../services/DelinquencyServices';

export const getCardColor = (status: CardStatus): BadgeStatusTagColor => {
  switch (status) {
    case CardStatus.ACTIVATED:
    case CardStatus.IN_STOCK:
    case CardStatus.IN_TRANSIT:
    case CardStatus.ISSUED:
    case CardStatus.ASSIGNED:
    case CardStatus.BOOKED:
    case CardStatus.BINDING:
      return 'green';
    case CardStatus.PERM_BLOCKED:
      return 'grey';
    default:
      return 'red';
  }
};

export const getTransactionStatusColor = (
  status: TransactionStatus
): BadgeStatusTagColor => {
  switch (status) {
    case TransactionStatus.INIT:
    case TransactionStatus.SUCCESS:
      return 'green';
    case TransactionStatus.FAILED:
      return 'red';
    default:
      return 'yellow';
  }
};

export const getBillStatusColor = (
  status: BillStatus | undefined
): BadgeStatusTagColor => {
  switch (status) {
    case BillStatus.PAID:
    case BillStatus.CARRY_FORWARD_PAID:
      return 'green';
    case BillStatus.UNPAID:
    case BillStatus.SKIPPED_PAYMENT:
      return 'red';
    default:
      return 'yellow';
  }
};

export const getLoanStatusColor = (status: LoanStatus): BadgeStatusTagColor => {
  switch (status) {
    case LoanStatus.PAID:
    case LoanStatus.INIT:
      return 'green';
    case LoanStatus.CLOSED:
    case LoanStatus.UNPAID:
      return 'red';
    default:
      return 'yellow';
  }
};

export const getRewardStatusColor = (
  status: RewardsLedgerStatus
): BadgeStatusTagColor => {
  switch (status) {
    case RewardsLedgerStatus.ACCRUED:
    case RewardsLedgerStatus.REALIZED:
      return 'green';
    default:
      return 'red';
  }
};

export const getChargebackStatusColor = (
  status: ChargebackStatus
): BadgeStatusTagColor => {
  switch (status) {
    case ChargebackStatus.ARBITRATION_ACCEPTED:
    case ChargebackStatus.CHARGEBACK_ACCEPTED:
      return 'green';
    case ChargebackStatus.ARBITRATION_DECLINED:
    case ChargebackStatus.ARBITRATION_REJECTED:
    case ChargebackStatus.CHARGEBACK_CANCELLED:
    case ChargebackStatus.ISSUER_LOST:
    case ChargebackStatus.REJECTED_BY_NETWORK:
    case ChargebackStatus.REQUEST_CANCELLED:
      return 'red';
    default:
      return 'yellow';
  }
};

export const getEMIStatusColor = (status: EMIStatus): BadgeStatusTagColor => {
  switch (status) {
    case EMIStatus.PAID:
    case EMIStatus.CLOSED:
      return 'green';
    case EMIStatus.UNPAID:
    case EMIStatus.UNBILLED:
      return 'red';
    default:
      return 'yellow';
  }
};

export const getWaiverStatusColor = (
  status: WaiverStatus
): BadgeStatusTagColor => {
  switch (status) {
    case WaiverStatus.SUCCESS:
      return 'green';
    case WaiverStatus.FAILED:
      return 'red';
    default:
      return 'yellow';
  }
};

export const getPhysicalStatementStatusColor = (
  status: PhysicalStatementStatus
): BadgeStatusTagColor => {
  switch (status) {
    case PhysicalStatementStatus.ACCEPTED:
      return 'orange';
    case PhysicalStatementStatus.REQUESTED:
      return 'yellow';
    case PhysicalStatementStatus.DELIVERED:
      return 'green';
    case PhysicalStatementStatus.DECLINED:
      return 'red';
    default:
      return 'violet';
  }
};

export const getAccountStatusColor = (
  status: AccountStatus
): BadgeStatusTagColor => {
  switch (status) {
    case AccountStatus.ACTIVE:
    case AccountStatus.NEW:
      return 'green';
    case AccountStatus.CREATED:
    case AccountStatus.BALANCE_TRANSFER:
    case AccountStatus.PRODUCT_TRANSFER:
      return 'yellow';
    default:
      return 'red';
  }
};

export const getApplicationStatusColor = (
  status?: ApplicationStatus
): BadgeStatusTagColor => {
  switch (status) {
    case ApplicationStatus.APPROVED:
      return 'green';
    case ApplicationStatus.REJECTED:
    case ApplicationStatus.VALIDATION_FAILED:
      return 'red';
    default:
      return 'yellow';
  }
};

export const applicationMappingObject: Record<string, string> = {
  AADHAAR_VALIDATION: 'Aadhar Verification',
  PAN_VALIDATION: 'Address Verification',
  SELFIE_VERIFICATION: 'Selfie',
  VIDEO_KYC: 'vKyc',
  APPLICATION_FORM: 'CAF',
  AGGREMENT: 'Offer Acceptance',
};

export function getCardListStructuredData(
  records: CardDto[] | undefined,
  onStatusChangeClick: Function,
  onReissueClick: Function
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      const status = rec.cardStatus;
      return {
        rowData: [
          {
            children: (
              <Stack direction="row" gap={1} justifyContent="space-between">
                <ComponentBody1Typo>{rec.cardNumber}</ComponentBody1Typo>
                {rec.cardCategory === CardCategory.PRIMARY_CARD &&
                  [CardStatus.ACTIVATED].includes(status) && (
                    <ComponentBody1Typo color={QUICK_ACCESS.text[75]}>
                      Primary
                    </ComponentBody1Typo>
                  )}
              </Stack>
            ),
            type: 'jsx',
            cellSx: {
              width: 280,
            },
          },
          {
            value: rec.kitId || '',
            type: 'string',
            cellSx: {
              minWidth: 100,
            },
          },
          {
            value: rec.binId,
            type: 'string',
            cellSx: {
              minWidth: 100,
            },
          },
          {
            children: getLogoForNetwork(rec.network || undefined) || '--',
            type: 'jsx',
            cellSx: {
              width: 100,
            },
          },
          {
            value: standardizeStatusText(status),
            type: 'status',
            statusColor: getCardColor(status),
            statusVariant: 'light-filled',
            cellSx: {
              minWidth: 100,
            },
          },
          {
            value:
              status !== CardStatus.REPLACED
                ? status === CardStatus.PERM_BLOCKED
                  ? 'Reissue Card'
                  : 'Change'
                : 'NA',
            type: status === CardStatus.REPLACED ? 'string' : 'link',
            linkOnClick:
              status !== CardStatus.REPLACED
                ? status === CardStatus.PERM_BLOCKED
                  ? onReissueClick
                  : onStatusChangeClick
                : undefined,
            cellSx: {
              width: 150,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getTransactionListStructuredData(
  records: TransactionDto[] | undefined,
  viewDetails: () => void,
  setSelected: Dispatch<SetStateAction<TransactionDto | undefined>>
): ITableDataProps[] {
  const onBtnClick = (data: TransactionDto) => {
    viewDetails();
    setSelected(data);
  };

  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.merchantName || '--',
            type: 'string',
            cellSx: {
              minWidth: 200,
            },
          },
          {
            value: rec.transactionTime
              ? dayjs(rec.transactionTime).format(DATE_TIME_SHORT_FORMAT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 180,
            },
          },
          {
            value: rec.txnProxyId,
            type: 'string',
            cellSx: {
              minWidth: 180,
            },
          },

          {
            value: convert2IndianNumberSystem(rec.amount),
            type: 'string',
            cellSx: {
              minWidth: 100,
            },
          },
          {
            value: convert2IndianNumberSystem(rec.overlimitUsed || 0),
            type: 'string',
            cellSx: {
              minWidth: 100,
            },
          },
          {
            value: pascalCaseFromCamelCase(rec.transactionCode),
            type: 'string',
            cellSx: {
              minWidth: 180,
            },
          },
          {
            value: pascalCaseFromCamelCase(rec.transactionIndicator || '--'),
            type: 'string',
          },
          {
            children: (
              <Stack direction="row" justifyContent="flex-start" gap={1}>
                <VegaButtonV4
                  onClick={() => onBtnClick(rec)}
                  endIcon={<EyeOpenIcon width={13} height={13} />}
                  variant="mellowed"
                  sx={EyeBtnSx}
                />
                <TransactionActionButtons data={rec} btnLabel={false} />
              </Stack>
            ),
            type: 'jsx',
            cellSx: {
              minWidth: 100,
            },
          },
          {
            value: standardizeStatusText(rec.status),
            type: 'status',
            statusColor: getTransactionStatusColor(rec.status),
            statusVariant: 'text',
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getActiveCardListStructuredData(
  records: PhysicalCardOrder[] | undefined,
  openJourney: () => void
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.createdAt
              ? dayjs(rec.createdAt).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
          },
          {
            value: rec.id,
            type: 'string',
          },

          {
            value: rec.eta,
            type: 'string',
          },
          {
            value: rec.deliveryPartner,
            type: 'string',
          },
          {
            value: AddressUtils.formatToReadableString(rec.customerAddress),
            type: 'string',
          },
          {
            value: rec.trackingId,
            type: 'string',
          },
          {
            children: (
              <Stack direction="row" justifyContent="flex-start" gap={1}>
                <VegaButtonV4
                  onClick={openJourney}
                  endIcon={<EyeOpenIcon width={13} height={13} />}
                  variant="mellowed"
                  sx={EyeBtnSx}
                />
              </Stack>
            ),
            type: 'jsx',
            cellSx: {
              minWidth: 100,
            },
          },
          {
            value: standardizeStatusText(rec.physicalCardOrderStatus),
            type: 'status',
            statusColor: 'green',
            statusVariant: 'text',
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getTransactionChargesStructuredData(
  records: TransactionChargeResponse[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      const chargesData = rec.chargeDto;

      return {
        rowData: [
          {
            value: rec.transactionId,
            type: 'string',
          },
          {
            value: convert2IndianNumberSystem(chargesData.chargeAmount),
            type: 'string',
          },
          {
            value: convert2IndianNumberSystem(chargesData.totalTax),
            type: 'string',
          },
          {
            value: pascalCaseFromCamelCase(chargesData.chargeCode),
            type: 'string',
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getDelinquncyHistoryListStructuredData(
  records: IDelinquencyListProps[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.id,
            type: 'string',
            cellSx: {
              width: 150,
            },
          },
          {
            value: rec.dpd,
            type: 'string',
          },
          {
            value: rec.dpdCalculationDate
              ? dayjs(rec.dpdCalculationDate).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
          },
          {
            value: convert2IndianNumberSystem(rec.outstandingTad),
            type: 'string',
          },
          {
            value: rec.dueDate
              ? dayjs(rec.dueDate).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getTransactionRefundStructuredData(
  records: TransactionDto[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.transactionTime
              ? dayjs(rec.transactionTime).format(DATE_TIME_SHORT_FORMAT)
              : '--',
            type: 'string',
          },
          {
            value: rec.txnProxyId,
            type: 'string',
          },
          {
            value: convert2IndianNumberSystem(rec.amount),
            type: 'string',
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getTransactionChargebackStructuredData(
  records: ChargebackDto[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.createdAt
              ? dayjs(rec.createdAt).format(DATE_TIME_SHORT_FORMAT)
              : '--',
            type: 'string',
          },
          {
            value: rec.id,
            type: 'string',
          },
          {
            value: convert2IndianNumberSystem(rec.disputeAmount || 0),
            type: 'string',
          },
          {
            value: standardizeStatusText(rec.chargebackStatus),
            type: 'status',
            statusColor: getChargebackStatusColor(rec.chargebackStatus),
            statusVariant: 'text',
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getCardControlStructuredData(
  records: CardControlPreferenceType[] | undefined,
  onSwitchChange: Function,
  onEditModalOpen: (e: any) => void
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.type,
            type: 'string',
            cellSx: {
              width: 200,
            },
          },
          {
            value: rec.status,
            type: 'switch',
            isSwitchOn: rec.status === CustomerPreferenceStatus.ENABLED,
            onSwitchChange,
          },
          {
            type: 'jsx',
            children: (
              <Stack direction="row" gap={2} alignItems="center">
                <Box minWidth={100}>
                  {convert2IndianNumberSystem(rec.perTransaction)}
                </Box>
                <VegaButtonV4
                  onClick={() => onEditModalOpen(rec)}
                  endIcon={<EditOutlinedIcon sx={{ height: 15, width: 15 }} />}
                  variant="mellowed"
                />
              </Stack>
            ),
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getChargebackStructuredData(
  records: ChargebackDto[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.createdAt
              ? dayjs(rec.createdAt).format(DATE_TIME_SHORT_FORMAT)
              : '--',
            type: 'string',
          },
          {
            value: rec.id,
            type: 'string',
          },
          {
            value: rec.transactionId,
            type: 'string',
          },
          {
            value: convert2IndianNumberSystem(rec.transactionAmount),
            type: 'string',
          },
          {
            value: convert2IndianNumberSystem(rec.disputeAmount),
            type: 'string',
          },
          {
            value: standardizeStatusText(rec.chargebackStatus),
            type: 'status',
            statusColor: getChargebackStatusColor(rec.chargebackStatus),
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getPastCardListStructuredData(
  records: PhysicalCardOrder[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.id,
            type: 'string',
          },
          {
            value: rec.createdAt
              ? dayjs(rec.createdAt).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
          },
          {
            value: rec.deliveryPartner,
            type: 'string',
          },
          {
            value: rec.trackingId,
            type: 'string',
          },
          {
            value: rec.physicalCardOrderStatus,
            type: 'string',
          },
          {
            value: rec.trackingId,
            type: 'string',
          },
          {
            value: rec.updatedAt
              ? dayjs(rec.updatedAt).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getRewardListStructuredData(
  records: RewardResponseProps[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.id || '--',
            type: 'string',
            cellSx: {
              minWidth: 170,
            },
          },
          {
            value: rec.createdAt
              ? dayjs(rec.createdAt).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 160,
            },
          },
          {
            value: rec.loanId || '--',
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value:
              (rec.pointAccrued || 0) -
              (rec.pointRealized || 0) -
              (rec.pointRevoked || 0),
            type: 'string',
            cellSx: {
              minWidth: 160,
            },
          },
          {
            value: rec.pointRealized || 0,
            type: 'string',
            cellSx: {
              minWidth: 120,
            },
          },
          {
            value: rec.updatedAt
              ? dayjs(rec.updatedAt).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 130,
            },
          },
          {
            value: rec.pointAccrued || 0,
            type: 'string',
            cellSx: {
              minWidth: 120,
            },
          },
          {
            value: standardizeStatusText(rec.status),
            type: 'status',
            statusColor: getRewardStatusColor(rec.status),
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getBackOfficeBillListStructuredData(
  records: IBillResponseData[] | undefined,
  setSelected: Dispatch<SetStateAction<IBillResponseData | undefined>>,
  setSnackbar: any
): ITableDataProps[] {
  const onBtnClick = (data: IBillResponseData) => {
    setSelected(data);
  };

  const shareBill2Customer = (data: IBillResponseData) => {
    if (data?.id) {
      getDigitalBillPdf(data?.id)
        .then(() => {
          setSnackbar('Bill successfully shared with customer');
        })
        .catch(() => {
          setSnackbar('Not able to share bill to customer', 'error');
        });
    }
  };

  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.id,
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: rec.createdAt
              ? dayjs(rec.createdAt).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: rec.billStartDate
              ? dayjs(rec.billStartDate).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 100,
            },
          },
          {
            value: rec.billEndDate
              ? dayjs(rec.billEndDate).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 100,
            },
          },

          {
            value: convert2IndianNumberSystem(rec.dueAmount),
            type: 'string',
            cellSx: {
              minWidth: 130,
            },
          },

          {
            value: convert2IndianNumberSystem(rec.paidAmount),
            type: 'string',
            cellSx: {
              minWidth: 130,
            },
          },
          {
            children: (
              <Stack direction="row" gap={1}>
                <Tooltip title="Bill Details" followCursor>
                  <VegaButtonV4
                    onClick={() => onBtnClick(rec)}
                    endIcon={<EyeOpenIcon width={13} height={13} />}
                    variant="mellowed"
                    sx={EyeBtnSx}
                  />
                </Tooltip>
                <Tooltip title="Share bill with customer" followCursor>
                  <VegaButtonV4
                    onClick={() => shareBill2Customer(rec)}
                    endIcon={<ShareIcon width={13} height={13} />}
                    variant="mellowed"
                  />
                </Tooltip>
              </Stack>
            ),
            type: 'jsx',
            cellSx: {
              width: 120,
            },
          },
          {
            value: standardizeStatusText(rec.status),
            type: 'status',
            statusColor: getBillStatusColor(rec.status),
            cellSx: {
              minWidth: 100,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getTransactionForBillStructuredData(
  records: ITransactionForBillResponse[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      const ledgerBreakup = rec.ledgerBreakup;
      return {
        rowData: [
          {
            value: rec.transactionDate
              ? dayjs(rec.transactionDate).format(DATE_TIME_SHORT_FORMAT)
              : '--',
            type: 'string',
          },
          {
            value: rec.transactionId,
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: convert2IndianNumberSystem(rec.amount),
            type: 'string',
          },

          {
            value: convert2IndianNumberSystem(ledgerBreakup?.overlimit),
            type: 'string',
          },

          {
            value: rec.txnCode,
            type: 'string',
          },

          {
            value: rec.merchantName,
            type: 'string',
          },

          {
            value: pascalCaseFromCamelCase(rec.transactionIndicator || '--'),
            type: 'string',
          },

          {
            value: '--',
            type: 'string',
          },
          {
            value: standardizeStatusText(rec.status),
            type: 'status',
            statusColor: 'green',
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getEMIListForStructuredData(
  records: EMIData[] | undefined,
  setAction: Function
): ITableDataProps[] {
  const onBtnClick = (item: EMIData) => {
    setAction(item);
  };
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.loan_id,
            type: 'string',
          },
          {
            value: rec.id,
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: rec.created_at
              ? dayjs(rec.created_at).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
          },
          {
            value: rec.created_at
              ? dayjs(rec.created_at).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
          },
          {
            value: rec.due_date
              ? dayjs(rec.due_date).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
          },
          {
            value: rec.installment_amount,
            type: 'string',
          },
          {
            children: (
              <VegaButtonV4
                onClick={() => onBtnClick(rec)}
                endIcon={<EyeOpenIcon width={13} height={13} />}
                variant="mellowed"
                sx={EyeBtnSx}
              />
            ),
            type: 'jsx',
          },
          {
            value: standardizeStatusText(rec.status),
            type: 'status',
            statusColor: getEMIStatusColor(rec.status),
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getEMIsForBillStructuredData(
  records: EMIData[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.loan_id,
            type: 'string',
          },
          {
            value: rec.id,
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: '--',
            type: 'string',
          },

          {
            value: '--',
            type: 'string',
          },

          {
            value: convert2IndianNumberSystem(rec.installment_amount),
            type: 'string',
          },
          {
            value: standardizeStatusText(rec.status),
            type: 'status',
            statusColor: getEMIStatusColor(rec.status),
            cellSx: {
              minWidth: 130,
            },
          },
          {
            value: standardizeStatusText(rec.billing_status),
            type: 'status',
            statusColor: getEMIStatusColor(rec.billing_status),
            statusVariant: 'text',
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getRepaymentsForBillStructuredData(
  records: TransactionDto[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.transactionTime
              ? dayjs(rec.transactionTime).format(DATE_TIME_SHORT_FORMAT)
              : '--',
            type: 'string',
          },
          {
            value: rec.txnProxyId,
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: convert2IndianNumberSystem(rec.amount),
            type: 'string',
          },
          {
            value:
              rec.transactionCode === TransactionCode.PAYMENT_REWARDS
                ? rec.amount
                : '--',
            type: 'string',
          },
          {
            value: rec.merchantName,
            type: 'string',
          },
          {
            value: 'PostPe',
            type: 'string',
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getSurplusListStructuredData(
  records: TransactionDto[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.transactionTime
              ? dayjs(rec.transactionTime).format(DATE_TIME_SHORT_FORMAT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 166,
            },
          },
          {
            value: rec.txnProxyId,
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: convert2IndianNumberSystem(rec.amount),
            type: 'string',
            cellSx: {
              minWidth: 166,
            },
          },
          {
            value: convert2IndianNumberSystem(rec.amount),
            type: 'string',
          },
          {
            value: '--',
            type: 'string',
          },
          {
            value: pascalCaseFromCamelCase(rec.transactionIndicator || '--'),
            type: 'string',
          },
          {
            value: standardizeStatusText(rec.status),
            type: 'status',
            statusColor: getTransactionStatusColor(rec.status),
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getWaiverListStructuredData(
  records: WaiverDto[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.chargeTxnTime
              ? dayjs(rec.chargeTxnTime).format(DATE_TIME_SHORT_FORMAT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 166,
            },
          },
          {
            value: rec.chargeTxnId,
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: convert2IndianNumberSystem(rec.chargeAmount),
            type: 'string',
          },
          {
            value: convert2IndianNumberSystem(rec.waiverAmount),
            type: 'string',
          },
          {
            value: pascalCaseFromCamelCase(rec.waiverType),
            type: 'string',
          },
          {
            value: rec.addedBy || '--',
            type: 'string',
          },
          {
            value: standardizeStatusText(rec.status),
            type: 'status',
            statusColor: getWaiverStatusColor(rec.status),
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getPhysicalStatementStructuredData(
  records: PhysicalStatementOrderDto[] | undefined,
  isActionBtnAvailable?: boolean,
  setSnackbar?: any
): ITableDataProps[] {
  const downloadBill = async (rec: PhysicalStatementOrderDto) => {
    if (rec?.billId) {
      try {
        getBillPdf(rec.billId).then((res: any) => {
          const link = res.data?.url;
          window.open(link);
        });
      } catch (err) {
        setSnackbar?.('Not able to extract bill', 'error');
      }
    }
  };

  return (
    records?.map((rec) => {
      const rowData: IRowDataProps[] = [
        {
          value: rec.id,
          type: 'string',
          cellSx: {
            width: 180,
          },
        },
        {
          value: rec.billId,
          type: 'string',
          cellSx: {
            width: 180,
          },
        },
        {
          value: rec.billDate
            ? dayjs(rec.billDate).format(DATE_FORMATE_SHORT)
            : '--',
          type: 'string',
          cellSx: {
            width: 160,
          },
        },
        {
          value: rec.billStartDate
            ? dayjs(rec.billStartDate).format(DATE_FORMATE_SHORT)
            : '--',
          type: 'string',
          cellSx: {
            width: 165,
          },
        },
        {
          value: rec.billEndDate
            ? dayjs(rec.billEndDate).format(DATE_FORMATE_SHORT)
            : '--',
          type: 'string',
          cellSx: {
            width: 160,
          },
        },
        {
          value: rec.updatedAt
            ? dayjs(rec.updatedAt).format(DATE_TIME_SHORT_FORMAT)
            : '--',
          type: 'string',
          cellSx: {
            width: 160,
          },
        },
        {
          value: standardizeStatusText(rec.status),
          type: 'status',
          statusColor: getPhysicalStatementStatusColor(rec.status),
          statusVariant: 'text',
          cellSx: {
            minWidth: 130,
          },
        },
      ];
      if (isActionBtnAvailable) {
        rowData.push({
          children: (
            <Tooltip title="Download bill" followCursor>
              <VegaButtonV4
                onClick={() => downloadBill(rec)}
                endIcon={<DownloadIcon width={13} height={13} />}
                variant="mellowed"
                size="small"
              />
            </Tooltip>
          ),
          type: 'jsx',
        });
      }
      return {
        rowData,
        id: rec,
      };
    }) || []
  );
}

export function getInstallmentListStructuredData(
  records: LoanDto[] | undefined,
  setSelected: Dispatch<SetStateAction<LoanDto | undefined>>
): ITableDataProps[] {
  const onBtnClick = (data: LoanDto) => {
    setSelected(data);
  };

  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.id,
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: rec.created_at
              ? dayjs(rec.created_at).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: rec.loan_start_date
              ? dayjs(rec.loan_start_date).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 90,
            },
          },
          {
            value: rec.loan_closed_date
              ? dayjs(rec.loan_closed_date).format(DATE_FORMATE_SHORT)
              : '--',
            type: 'string',
            cellSx: {
              minWidth: 90,
            },
          },

          {
            value: convert2IndianNumberSystem(rec.over_due_amount),
            type: 'string',
            cellSx: {
              minWidth: 90,
            },
          },

          {
            value: convert2IndianNumberSystem(rec.paid_amount),
            type: 'string',
            cellSx: {
              minWidth: 90,
            },
          },

          {
            value: convert2IndianNumberSystem(rec.due_principal),
            type: 'string',
            cellSx: {
              minWidth: 180,
            },
          },

          {
            value: rec.installment_count,
            type: 'string',
            cellSx: {
              minWidth: 120,
            },
          },
          {
            children: (
              <Stack direction="row" gap={1}>
                <VegaButtonV4
                  onClick={() => onBtnClick(rec)}
                  endIcon={<EyeOpenIcon width={13} height={13} />}
                  variant="mellowed"
                  sx={EyeBtnSx}
                />
              </Stack>
            ),
            type: 'jsx',
            cellSx: {
              width: 120,
            },
          },
          {
            value: standardizeStatusText(rec.status),
            type: 'status',
            statusColor: getLoanStatusColor(rec.status),
            cellSx: {
              minWidth: 130,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export const TransactionActionButtons = ({
  data,
  btnLabel = true,
}: {
  data: TransactionDto;
  btnLabel?: boolean;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleClickModalOpen = () => {
    setOpenModal(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleClickDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const canCreateChargeBack =
    data.chargebackStatus === null &&
    data.status === TransactionStatus.SUCCESS &&
    ['CARD_ECOM', 'CARD_POS', 'CARD_NFC', 'CARD_CASH_WITHDRAW'].includes(
      data.transactionCode
    ) &&
    data.transactionIndicator === TransactionIndicator.DEBIT;

  const label = useMemo(() => {
    const isReissueStatus = [
      ChargebackStatus.CHARGEBACK_CANCELLED,
      ChargebackStatus.REJECTED_BY_NETWORK,
      ChargebackStatus.ARBITRATION_DECLINED,
      ChargebackStatus.ARBITRATION_REJECTED,
      ChargebackStatus.ISSUER_LOST,
    ];

    if (canCreateChargeBack) {
      return 'Raise a dispute';
    }
    if (isReissueStatus.find((status) => status === data.chargebackStatus)) {
      return 'Re-issue Chargeback';
    }
    if (!!data.chargebackStatus) {
      return 'View Dispute Details';
    }
    return null;
  }, [canCreateChargeBack, data]);

  return (
    <Box>
      {label === 'View Dispute Details' ? (
        <>
          <VegaButtonV4
            onClick={handleClickModalOpen}
            endIcon={
              btnLabel ? undefined : <MessageSquareInfo stroke="#FFFF" />
            }
            value={btnLabel && label ? label : undefined}
            variant="contained"
          />
          <ViewDisputeDetailsModal
            open={openModal}
            handleClose={handleModalClose}
            triggerLabel="View Dispute Details"
            transactionDto={data}
          />
        </>
      ) : label === 'Raise a dispute' ? (
        <>
          <VegaButtonV4
            onClick={handleClickDrawerOpen}
            endIcon={btnLabel ? undefined : <MessageSquareInfo />}
            value={btnLabel && label ? label : undefined}
            variant="mellowed"
          />
          <RaiseADisputeDrawer
            triggerLabel="Raise a dispute"
            data={data}
            open={openDrawer}
            handleClose={handleDrawerClose}
          />
        </>
      ) : null}
    </Box>
  );
};

export const getApplicationStructuredData = (
  records: ApplicationsResponse[],
  onBtnClick: () => void,
  setApplicationData: React.Dispatch<
    React.SetStateAction<ApplicationsResponse | undefined>
  >
) => {
  return (
    records?.map((rec: ApplicationsResponse, index: number) => {
      return {
        rowData: [
          {
            value: rec?.customerId,
            type: 'string',
          },
          {
            value: rec?.users[0]?.userBasicInfo?.name ?? 'NA',
            type: 'string',
            cellSx: {
              minWidth: 200,
            },
          },
          {
            value: rec?.phone,
            type: 'string',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            children: (
              <Tooltip title="Application Details" followCursor>
                <VegaButtonV4
                  endIcon={<EyeOpenIcon />}
                  onClick={() => {
                    onBtnClick();
                    setApplicationData(rec);
                  }}
                  variant="mellowed"
                  sx={EyeBtnSx}
                />
              </Tooltip>
            ),
            type: 'jsx',
            cellSx: {
              minWidth: 100,
            },
          },
          {
            value: rec.status,
            type: 'status',
            statusColor: getApplicationStatusColor(rec.status),
            statusVariant: 'stroked',
            cellSx: {
              minWidth: 150,
            },
          },
          {
            value: pascalCaseFromCamelCase(
              rec?.completedStates?.[(rec?.completedStates?.length || 0) - 1] ||
                ApplicationCompletedStates.AADHAAR_VALIDATION
            ),
            type: 'status',
            statusColor: getApplicationStatusColor(rec.status),
            statusVariant: 'text',
            cellSx: {
              minWidth: 200,
            },
          },
          {
            type: 'string',
            value: DateUtility.formatToDDMMMYYY(rec?.updatedAt),
            cellSx: {
              minWidth: 110,
            },
          },
          {
            type: 'string',
            value: rec?.programName,
            cellSx: {
              minWidth: 150,
            },
          },
        ],
        id: index + 1,
      };
    }) || []
  );
};
export const EyeBtnSx = {
  '.MuiButton-endIcon': {
    path: {
      strokeWidth: 0.5,
    },
    margin: 0,
  },
  ':hover': {
    stroke: PRIMARY_COLOR.blue[400],
    backgroundColor: PRIMARY_COLOR.blue[50],
    boxShadow: 'none',
    '.MuiButton-endIcon': {
      path: {
        strokeWidth: 0.1,
      },
    },
  },
};
