import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import VegaCardV4 from '../../common/v4/VegaCardV4';
import { useLedgerListQuery } from '../../../store/queries/accountQueries';
import VegaNoResults from '../../common/VegaNoResults';
import VegaTableV4 from '../../common/v4/VegaTableV4';
import {
  getLedgerListStructuredData,
  searchByOptionsForTransactions,
} from '../simulatorUtils';
import { ListGroupHeadingTypo } from '../../common/Typography';
import { useSimulatorContext } from '../SimulatorMainWrap';
import VegaSelectV4 from '../../common/v4/VegaSelectV4';
import VegaAutocompleteSearchV4 from '../../common/v4/VegaAutocompleteSearchV4';
import { TextFieldSearchProps } from '../../../utils/common.utils';

const LedgerListPage = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const {
    searchValue: value,
    updateSearchValue,
    customerRecords,
    customerListQuery,
    activeSearchBy,
    updateActiveSearchBy,
    onTextChange,
    isCustDataFetching,
    selectedAccId,
  } = useSimulatorContext();

  const { isFetching: isFetchingCustomerData } = customerListQuery || {};

  const { data: ledgerListData, isFetching: isFetchingLedgerListData } =
    useLedgerListQuery({
      accountId: selectedAccId as string,
      page,
      pageSize,
    });

  const records = value ? ledgerListData?.records : [];

  useEffect(() => {
    setPage(0);
  }, [value]);

  return (
    <Box>
      <VegaHeaderV4 title="Ledger" sx={{ marginTop: 4 }} />
      <Stack p={3} gap={5}>
        <VegaCardV4>
          <Stack direction="row" gap={1} alignItems={'center'}>
            <VegaSelectV4
              header="Search By"
              options={searchByOptionsForTransactions}
              value={activeSearchBy}
              onChange={updateActiveSearchBy}
              wrapperProps={{
                flex: 0.7,
              }}
              sx={{ height: 36.5 }}
              standardWidth={180}
              headerTypo={ListGroupHeadingTypo}
            />
            <VegaAutocompleteSearchV4
              textFieldProps={{
                ...TextFieldSearchProps,
                onChange: onTextChange,
              }}
              options={customerRecords || []}
              value={value || ''}
              onChange={updateSearchValue}
              freeSolo
              loading={isCustDataFetching}
            />
          </Stack>
        </VegaCardV4>
        {!records?.length &&
        !(isFetchingLedgerListData || isFetchingCustomerData) ? (
          <VegaNoResults
            errorText={
              value
                ? 'No Ledger Found for the customer. Please select another customer'
                : 'No Ledger Found. Please select the customer'
            }
          />
        ) : (
          <VegaTableV4
            head={[
              'Ledger Id',
              'Amount',
              'Currency',
              'Transaction Date',
              'Retail Principal',
              'Retail Interest',
              'Retail Fee',
              'Cash Principal',
              'Cash Interest',
              'Cash Fee',
              'Emi Principal',
              'Emi Interest',
              'Emi Fee',
              'Retail Principal Billed',
              'Retail Interest Billed',
              'Retail Fee Billed',
              'Retail Principal Unbilled',
              'Retail Interest Unbilled',
              'Retail Fee Unbilled',
              'Cash Principal Billed',
              'Cash Interest Billed',
              'Cash Fee Billed',
              'Cash Principal Unbilled',
              'Cash Interest Unbilled',
              'Cash Fee Unbilled',
              'Emi Principal Billed',
              'Emi Interest Billed',
              'Emi Fee Billed',
              'Emi Principal Unbilled',
              'Emi Interest Unbilled',
              'Emi Fee Unbilled',
              'Overlimit Unbilled',
            ]}
            data={getLedgerListStructuredData(records)}
            isLoading={isFetchingLedgerListData || isFetchingCustomerData}
            pagination={{
              rowsPerPage: pageSize,
              setRowsPerPage: setPageSize,
              setPage,
              page,
              totalRecords: ledgerListData?.totalItems || 0,
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

export default LedgerListPage;
