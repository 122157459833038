import { Paper, PaperProps } from '@mui/material';
import React from 'react';
import { QUICK_ACCESS } from '../../../constants/style';

const VegaPaperV4 = (props: PaperProps) => {
  return (
    <Paper
      {...props}
      sx={{
        p: 2,
        borderRadius: '6px',
        background: QUICK_ACCESS.background[50],
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.08)',
        ...props.sx,
      }}
    >
      {props.children}
    </Paper>
  );
};

export default VegaPaperV4;
