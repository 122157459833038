import { Popover, PopoverProps } from '@mui/material';

const VegaPopoverV4 = ({
  children,
  onClose,
  open,
  id,
  anchorEl,
  ...rest
}: PopoverProps) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...rest}
    >
      {children}
    </Popover>
  );
};

export default VegaPopoverV4;
