import { Search } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';
import VegaContainedButton from '../../../components/common/VegaContainedButton';
import VegaOutLineTextField from '../../../components/common/VegaOutLineTextField';
import { useClientAuth } from '../../../providers/ClientProvider';
import { IPartner } from '../../../types/partner';
import ListPartners from '../components/PartnerMangement/ListPartners/ListPartners';
import PartnerPrograms from '../components/PartnerMangement/PartnerPrograms/PartnerPrograms';
import CreatePartnersModal from './CreatePartnersModal';
import { usePartnersList } from '../../../store/queries/partnerQueries';

function PartnerManagement() {
  const { clientId } = useClientAuth();
  const [isCreatePartner, setIsCreatePartner] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<IPartner | null>(null);
  const [filterData, setFilterData] = useState({
    value: '',
  });
  const partnersQuery = usePartnersList({
    clientId: clientId || '',
    page: 0,
    pageSize: 50,
  });

  const filteredPartners = useMemo(() => {
    return partnersQuery.data?.records.filter((partner) => {
      return (partner?.name || '')
        .toLowerCase()
        .includes(filterData.value.toLowerCase());
    });
  }, [partnersQuery.data, filterData.value]);

  const handleFilter = (name: keyof typeof filterData, value: string) => {
    setFilterData({ ...filterData, [name]: value });
    onSearch();
  };

  const onSearch = () => {
    console.log('search');
  };

  return (
    <>
      {selectedPartner ? (
        <Box>
          <PartnerPrograms
            selectedPartener={selectedPartner}
            setSelectedPartner={setSelectedPartner}
            setIsCreatePartner={setIsCreatePartner}
          />
        </Box>
      ) : (
        <Box>
          <VegaPage>
            <VegaCard>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box>
                  <VegaOutLineTextField
                    value={filterData.value}
                    onChange={(e) => handleFilter('value', e.target.value)}
                    key="Search"
                    label={'Search'}
                    type={'text'}
                    placeholder={'Search co-brand partner '}
                    Icon={<Search />}
                  />
                </Box>
                <VegaContainedButton
                  text="Add Co-brand Partner"
                  isPrimary
                  py={1}
                  onClick={() => setIsCreatePartner(true)}
                />
              </Stack>
            </VegaCard>
            <ListPartners
              data={filteredPartners || []}
              // data={partnersQuery.data as any}
              isLoading={partnersQuery.isLoading}
              setSelectedPartner={setSelectedPartner}
            />
          </VegaPage>
        </Box>
      )}
      <CreatePartnersModal
        open={isCreatePartner}
        setOpen={setIsCreatePartner}
        selectedPartner={selectedPartner}
      />
    </>
  );
}
export default PartnerManagement;
