import { Box, Grid, Stack } from '@mui/material';
import { useSupport } from '../../../../providers/SupportProvider';
import Vega_Text from '../../../common/v3/Vega_Text';
import Vega_FormWrapper from '../../../common/v3/Vega_FormWrapper';
import { toLowerCase } from '../../../../constants/commonFunction';
import { dateFormat } from '../../../../mcc/variable';
import { AccountDataDto } from '../../../../types/account';
import VegaBadgeStatusTagV4 from '../../../common/v4/VegaBadgeStatusTagV4';
import { getAccountStatusColor } from '../supportUtils';

interface IProps {
  card: AccountDataDto;
}

const AccountCardContainer = ({ card }: IProps) => {
  const { setAccountData } = useSupport();
  return (
    <Grid
      sx={{
        cursor: 'pointer',
        transition: 'all .2s ease-in-out',
        ':hover': {
          transform: 'scale(1.02)',
        },
      }}
      onClick={() => setAccountData(card)}
      key={card.accountId}
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={4}
    >
      <Box
        sx={{
          border: `1px solid #EEEEEE`,
          borderRadius: '12px',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          p={2}
          sx={{
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            bgcolor: '#E7EDFB',
          }}
        >
          <Vega_Text
            text={card.programName}
            fontSize={13}
            fontWeight={450}
            color={'#181F28'}
          />
          {card.status && (
            <VegaBadgeStatusTagV4
              text={card.status}
              color={getAccountStatusColor(card.status)}
              variant="stroked"
            />
          )}
        </Stack>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Vega_FormWrapper header="PRODUCT TYPE">
            <Vega_Text
              text={`${toLowerCase(card.accountType ?? '--')} Card`}
              fontWeight={450}
              fontSize={13}
              color={'#181F28'}
            />
          </Vega_FormWrapper>
          <Stack direction={'row'} gap={1} mt={1.5}>
            <Vega_FormWrapper
              header="Account ID"
              sx={{
                width: '50%',
              }}
            >
              <Vega_Text
                text={card.accountId}
                fontWeight={450}
                fontSize={13}
                color={'#181F28'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              />
            </Vega_FormWrapper>
            <Vega_FormWrapper
              header="Activation Date"
              sx={{
                width: '50%',
              }}
            >
              <Vega_Text
                text={dateFormat(card.createdAt)}
                fontWeight={450}
                fontSize={13}
                color={'#181F28'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            </Vega_FormWrapper>
          </Stack>
        </Box>
      </Box>
    </Grid>
  );
};

export default AccountCardContainer;
