import { useQuery } from 'react-query';
import { QUERY_NAMES } from '../../enums/commons';
import { getAnalyticsDate } from '../../api/analyticsAPI';
import { ANALYTICS_QUERY_NAME } from '../../enums/analytics.enum';

export const useAnalyticsDataQuery = <T>({
  queryName,
  startDate,
  endDate,
  programId,
  page = 0,
  pageSize = 1000,
}: {
  queryName: ANALYTICS_QUERY_NAME | undefined;
  startDate: string;
  endDate: string;
  programId: string;
  page?: number;
  pageSize?: number;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.TRANSACTION_LIST_ACCOUNT,
      queryName,
      startDate,
      endDate,
      programId,
      { page, pageSize },
    ],
    queryFn: () =>
      getAnalyticsDate<T>(
        queryName,
        startDate,
        endDate,
        programId,
        page,
        pageSize
      ),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: Boolean(programId) && Boolean(queryName),
  });

  return { data: data?.data, ...rest };
};
