import { useQuery, useQueryClient } from 'react-query';
import {
  AddMerchantFormType,
  AddMerchantPayloadType,
  MccCode,
  Merchant,
} from '../../types/resources';
import VegaDrawerV4 from '../common/v4/VegaDrawerV4';
import {
  ResourceType,
  useInstitutionalResources,
} from '../../hooks/useInstitutionalResources';
import { FileValidatorBuilder, FileExtension } from '../../utils/FileValidator';
import { Controller, useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import VegaTextfieldV4 from '../common/v4/VegaTextfieldV4';
import { useMemo, useState } from 'react';
import VegaSelectV4, { VegaSelectOptionType } from '../common/v4/VegaSelectV4';
import VegaUploadFileInputV4 from '../common/v4/VegaUploadFileInputV4';
import {
  createMerchant,
  updateMerchant,
  updateMerchantLogo,
} from '../../api/resources';
import { useSnackbar } from '../../providers/SnackbarProvider';

type AddOrEditMerchantProps = {
  isOpen: boolean;
  onClose: () => void;
  merchant?: Merchant;
};
const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([
    FileExtension.JPEG,
    FileExtension.JPG,
    FileExtension.PNG,
  ])
  .withMultipleExtensionsCheck()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();
const stringToNumberArray = (str: string) =>
  str.split(',').map((item) => Number.parseInt(item, 10));
function AddOrEditMerchant({
  isOpen,
  onClose,
  merchant,
}: AddOrEditMerchantProps) {
  const { fetchResource } = useInstitutionalResources();
  const queryClient = useQueryClient();
  const [logoUploadValidationError, setLogoUploadValidationError] =
    useState('');
  const hookForm = useForm<AddMerchantFormType>({
    mode: 'onTouched',
    defaultValues: merchant
      ? {
          name: merchant.name,
          tids: merchant?.tids?.map((tid) => tid.tid).join(','),
          mids: merchant?.mids?.map((mid) => mid.mid).join(','),
          categoryCodes: merchant?.mccCodes?.map((mcc) => mcc.code),
        }
      : {},
  });
  const { setSnackbar } = useSnackbar();
  const isEdit = !!merchant;

  const { data: paginatedMCCData } = useQuery({
    queryKey: ['fetchMcc'],
    queryFn: () =>
      fetchResource<MccCode>({
        type: ResourceType.MCC,
        page: 0,
        pageSize: 1000,
      }),
  });

  const onSubmit = (data: AddMerchantFormType) => {
    const tids = data.tids && stringToNumberArray(data.tids);
    const mids = data.mids && stringToNumberArray(data.mids);

    const payload: AddMerchantPayloadType = {
      name: data.name,
      ...(mids && { mids: mids }),
      ...(tids && { tids: tids }),
      ...(data.categoryCodes && { categoryCodes: data.categoryCodes }),
    };
    if (isEdit) {
      updateMerchant({ ...payload, id: merchant.id })
        .then(() => {
          const merchantId = merchant.id;
          if (data.logo) {
            updateMerchantLogo(merchantId, data.logo)
              .then(() => {
                setSnackbar('Merchant updated successfully', 'success');
              })
              .catch((err) => {
                setSnackbar(
                  'Merchant updated, Failed saving logo for merchant',
                  'error'
                );
              })
              .finally(() => queryClient.invalidateQueries('fetchMerchants'));
            return;
          }
          setSnackbar('Merchant updated successfully', 'success');
          queryClient.invalidateQueries('fetchMerchants');
        })
        .catch(() =>
          setSnackbar('Something went wrong, please try again', 'error')
        );
    } else {
      createMerchant(payload)
        .then((res) => {
          const merchantId = res.data.id;
          if (data.logo) {
            updateMerchantLogo(merchantId, data.logo)
              .then(() => {
                setSnackbar('Merchant created successfully', 'success');
              })
              .catch((err) => {
                setSnackbar(
                  'Merchant created, Failed saving logo for merchant',
                  'error'
                );
              })
              .finally(() => queryClient.invalidateQueries('fetchMerchants'));
            return;
          }
          setSnackbar('Merchant created successfully', 'success');
          queryClient.invalidateQueries('fetchMerchants');
        })
        .catch(() =>
          setSnackbar('Something went wrong, please try again', 'error')
        );
    }
  };

  const mccCategoryOptions = useMemo(
    () =>
      paginatedMCCData?.records
        ? paginatedMCCData.records.map((item) => {
            const option: VegaSelectOptionType = {
              id: item.code,
              name: item.description,
            };
            return option;
          })
        : [],
    [paginatedMCCData?.records]
  );

  return (
    <VegaDrawerV4
      open={isOpen}
      onClose={onClose}
      header={isEdit ? 'Edit Merchant' : 'Add Merchant'}
      submitBtn={{
        show: true,
        props: {
          value: isEdit ? 'Edit Merchant' : 'Add Merchant',
          onClick: () => hookForm.handleSubmit(onSubmit)(),
        },
      }}
    >
      <form onSubmit={hookForm.handleSubmit(onSubmit)}>
        <Stack gap={3}>
          <Controller
            name="name"
            control={hookForm.control}
            rules={{ required: 'This field is required' }}
            render={({ field, fieldState }) => (
              <VegaTextfieldV4
                header="Merchant Name"
                placeholder="Enter Merchant Name"
                standardWidth="100%"
                {...field}
                {...(fieldState.error && {
                  error: true,
                  labelText: fieldState.error.message,
                })}
              />
            )}
          />
          <Controller
            name="mids"
            control={hookForm.control}
            rules={{ required: false, pattern: /^\d*(,*\d+)$/i }}
            render={({ field, fieldState }) => (
              <VegaTextfieldV4
                header="MIDs"
                placeholder="Enter MIDs"
                standardWidth="100%"
                {...field}
                {...(fieldState.error && {
                  error: true,
                  labelText:
                    fieldState.error.type === 'pattern'
                      ? 'MIDs should be on format comma separated numbers'
                      : fieldState.error.message,
                })}
              />
            )}
          />
          <Controller
            name="tids"
            control={hookForm.control}
            rules={{ required: false, pattern: /^\d*(,*\d+)$/i }}
            render={({ field, fieldState }) => (
              <VegaTextfieldV4
                header="TIDs"
                placeholder="Enter TIDs"
                standardWidth="100%"
                {...field}
                {...(fieldState.error && {
                  error: true,
                  labelText:
                    fieldState.error.type === 'pattern'
                      ? 'TIDs should be on format comma separated numbers'
                      : fieldState.error.message,
                })}
              />
            )}
          />
          <Controller
            name="categoryCodes"
            control={hookForm.control}
            rules={{
              required: false,
            }}
            render={({ field, fieldState }) => (
              <VegaSelectV4
                header="Category"
                placeholder="Select Categories"
                options={mccCategoryOptions}
                multiple
                standardWidth={400}
                {...field}
                value={field.value || ([] as any)}
                {...(fieldState.error && {
                  error: true,
                  labelText: fieldState.error.message,
                })}
              />
            )}
          />
          <Controller
            name="logo"
            control={hookForm.control}
            render={({ field, fieldState }) => (
              <VegaUploadFileInputV4
                header="Logo"
                buttonText="Upload Logo"
                onFileSelected={(file) => {
                  setLogoUploadValidationError('');
                  field.onChange(file);
                }}
                wrapperProps={{
                  sx: {
                    width: 400,
                    maxWidth: 400,
                  },
                }}
                currentFile={field.value}
                fileValidator={fileValidatorConfig}
                labelText={
                  fieldState.error?.message || logoUploadValidationError
                }
                error={!!fieldState.error || !!logoUploadValidationError}
                onFileSelectedError={(error) =>
                  error && setLogoUploadValidationError(error)
                }
              />
            )}
          />
        </Stack>
      </form>
    </VegaDrawerV4>
  );
}

export default AddOrEditMerchant;
