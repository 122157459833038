import { Box, Stack } from '@mui/material';
import { PageHeadingTypo } from '../common/Typography';
import VegaSearchfieldV4 from '../common/v4/VegaSearchfieldV4';
import { useEffect, useState } from 'react';
import VegaButtonV4 from '../common/v4/VegaButtonV4';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import { IAddUserRequest } from '../../mcc/Program-Dashboard/api/roles';
import VegaTableV4, { ITableDataProps } from '../common/v4/VegaTableV4';
import VegaNoResults from '../common/VegaNoResults';
import { VegaUserStatus } from '../../types/user';
import PartnerAccessSwitchConfirmModal from './PartnerAccessSwitchConfirmModal';
import PartnerTeamMemberAdditionDrawer from './PartnerTeamMemberAdditionDrawer';
import { useClientAuth } from '../../providers/ClientProvider';
import { usePartnerMgmtContext } from '../../mcc/Program-Dashboard/pages/PartnerManagementWrap';
import { useClientUserList } from '../../store/queries/clientQueries';

const PartnerTeamListView = () => {
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { clientId } = useClientAuth();
  const { partner } = usePartnerMgmtContext();
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IAddUserRequest | undefined>(
    undefined
  );

  const onSearchUpdate = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(e.target.value);
  };

  const { data, isFetching, refetch } = useClientUserList({
    clientId: clientId || '',
  });

  const partnerUsers = ((data || []) as IAddUserRequest[]).filter(
    (user) => user.platformId === partner?.id
  );

  const onSwitchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setSelectedUser(partnerUsers.find((user) => user.userId === id));
    setShowModal(true);
  };

  const openDrawer = () => {
    setShowDrawer(true);
  };

  useEffect(() => {
    if (!showModal) {
      setSelectedUser(undefined);
    }
  }, [showModal]);

  const structuredData: ITableDataProps[] =
    partnerUsers?.map((rec) => {
      return {
        rowData: [
          {
            value: rec.name,
            type: 'string',
          },
          {
            value: rec.email,
            type: 'string',
          },
          {
            value: rec.mobileNumber,
            type: 'string',
          },
          {
            value: rec.status,
            type: 'switch',
            isSwitchOn: rec.status === VegaUserStatus.ACTIVE,
            onSwitchChange,
          },
        ],
        id: rec.userId,
      };
    }) || [];

  return (
    <Box my={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <PageHeadingTypo>Team Members</PageHeadingTypo>
        <Stack direction="row" gap={1.5}>
          <VegaSearchfieldV4 value={search} onChange={onSearchUpdate} />
          <VegaButtonV4
            variant="outlined"
            startIcon={<ControlPointRoundedIcon fontSize="small" />}
            onClick={openDrawer}
          >
            Add Team Member
          </VegaButtonV4>
        </Stack>
      </Stack>

      <Box mt={2}>
        {!Boolean(partnerUsers.length) && !isFetching ? (
          <VegaNoResults errorText="No Team Members Found" />
        ) : (
          <VegaTableV4
            head={['Member Name', 'Email Is', 'Mobile No.', 'Access']}
            data={structuredData}
            isLoading={isFetching}
          />
        )}
      </Box>
      <PartnerAccessSwitchConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedUser={selectedUser}
        refetch={refetch}
      />
      <PartnerTeamMemberAdditionDrawer
        open={showDrawer}
        setOpen={setShowDrawer}
        refetch={refetch}
      />
    </Box>
  );
};

export default PartnerTeamListView;
