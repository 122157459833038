import React from 'react';
import VegaSelect from '../../common/VegaSelect';
import { VegaTextField } from '../../common';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { QUICK_ACCESS } from '../../../constants/style';
import VegaSelectV4 from '../../common/v4/VegaSelectV4';
import VegaTextfieldV4 from '../../common/v4/VegaTextfieldV4';
import { ComponentBody1Typo } from '../../common/Typography';

const OrderPrintedCard = () => {
  return (
    <Stack gap={3}>
      <Stack
        direction="column"
        gap={1.25}
        p={2}
        bgcolor={QUICK_ACCESS.background[100]}
        borderRadius={2}
      >
        <Stack direction={'row'} gap={1} justifyContent="space-between">
          <ComponentBody1Typo
            sx={{
              color: QUICK_ACCESS.text[100],
            }}
          >
            Card in Stock
          </ComponentBody1Typo>
          <ComponentBody1Typo
            sx={{
              color: QUICK_ACCESS.text[200],
            }}
          >
            2500
          </ComponentBody1Typo>
        </Stack>
      </Stack>
      <VegaSelectV4
        header="select printer"
        placeholder="Select Printer"
        standardWidth={400}
        options={[{ id: 'xyz', name: 'xyz' }]}
        value={'xyz'}
        onChange={() => {}}
      />
      <VegaTextfieldV4
        type="number"
        header="No. of card to be printed"
        placeholder="Enter No. of Cards to be Printed"
        standardWidth={400}
        onChange={() => {}}
      />
    </Stack>
  );
};

export default OrderPrintedCard;
