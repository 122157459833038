/** @format */

import { AdminPanelSettings, FolderShared } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import AgentView from '../pages/AgentView';
import Collections from '../pages/Collections';
import CrmProgram from '../pages/CrmProgram';
import Operations from '../pages/Operations';
import Policy from '../pages/Policy';
import Reports from '../pages/Reports';
import ApplicationManagmentProvider from '../providers/ApplicationManagmentProvider';
import { useClientAuth } from '../providers/ClientProvider';
import { BACK_OFFICE_ROUTES, ROUTES } from '../routes';
import { SupportProvider } from '../providers/SupportProvider';
import SupportWrap from '../pages/SupportWrap';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { CONSTANTS } from '../enums/commons';
import { AccountCloseDetails } from '../components/Operations/Account Closure/AccountCloseDetails';
import BackOfficeLendingPage from './BackOffice/BackOfficeLendingPage';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { getState, setShowLendingPage } from '../store/common/stateSlice';
import AnalyticsMainPage from '../components/BackOffice/Analytics/AnalyticsMainPage';
import SupportIcon from '../components/icon/SupportIcon';
import FolderIcon from '../components/icon/FolderIcon';
import ProfileIcon from '../components/icon/ProfileIcon';
import FileType2Icon from '../components/icon/FileType2Icon';
import SettingsIcon from '../components/icon/SettingsIcon';
import GraphIcon from '../components/icon/GraphIcon';
import OperationsWrap from '../components/BackOffice/Operations/OperationsWrap';

type Props = {};

const NAV_OPTIONS: SidebarListItemType[] = [
  // {
  //   id: ROUTES.SEARCH_BY_CUSTOMER,
  //   icon: <SupportAgent sx={{ fontSize: 18 }} />,
  //   label: 'Search By Customer',
  //   component: (
  //     <SupportProvider>
  //       <SupportLending />
  //     </SupportProvider>
  //   ),
  //   isProgramSelector: false,
  //   isAccountSelector: false,
  //   isCrmRoutes: true,
  // },
  // {
  //   id: ROUTES.SUPPORT,
  //   icon: <SupportAgent />,
  //   label: 'Support',
  //   component: <Support />,
  //   isProgramSelector: true,
  //   isAccountSelector: false,
  //   isCrmRoutes: true,
  // },
  {
    id: BACK_OFFICE_ROUTES.COLLECTIONS,
    icon: <FolderIcon />,
    label: 'Collections',
    component: <Collections />,
    isAccountSelector: false,
    isRestrictedForUser: true,
    clientRestricted: true,
    isCrmRoutes: true,
  },
  {
    id: BACK_OFFICE_ROUTES.AGENT_VIEW,
    icon: <ProfileIcon />,
    label: 'Agent View',
    component: <AgentView />,
    isAccountSelector: false,
    isRestrictedForUser: true,
    clientRestricted: true,
    isCrmRoutes: true,
  },
  {
    id: BACK_OFFICE_ROUTES.REPORTS,
    icon: <FileType2Icon stroke="#EBEDEF" />,
    label: 'Reports',
    component: <Reports />,
    isProgramSelector: true,
    isAccountSelector: false,
    isCrmRoutes: true,
  },
  {
    id: BACK_OFFICE_ROUTES.OPERATIONS,
    icon: <SettingsIcon />,
    label: 'Operations',
    component: <OperationsWrap />,
    isProgramSelector: false,
    isAccountSelector: false,
    isCrmRoutes: true,
    sx: {
      padding: 0,
    },
  },
  {
    id: BACK_OFFICE_ROUTES.ANALYTICS,
    icon: <GraphIcon />,
    label: 'Analytics',
    component: <AnalyticsMainPage />,
    isAccountSelector: false,
    isCrmRoutes: true,
    sx: {
      padding: 0,
    },
  },
  // {
  //   id: BACK_OFFICE_ROUTES.ANALYTICS + '1',
  //   icon: <AnalyticsIcon sx={{ fontSize: 18 }} />,
  //   label: 'Analytics 1',
  //   component: <Analytics />,
  //   isAccountSelector: false,
  //   isCrmRoutes: true,
  // },
  {
    id: BACK_OFFICE_ROUTES.SUPPORT,
    icon: <SupportIcon />,
    label: 'Support',
    component: (
      <SupportProvider>
        <SupportWrap />
      </SupportProvider>
    ),
    isAccountSelector: false,
    isCrmRoutes: true,
    sx: {
      padding: 0,
    },
  },
  {
    id: '/' + BACK_OFFICE_ROUTES.ACC_CLOSE_DETAILS,
    label: 'Check Request Account Closure',
    component: <AccountCloseDetails />,
    isProgramSelector: false,
    isAccountSelector: false,
    isCrmRoutes: true,
    hideForAdmin: true,
    hideForNonAdmin: true,
    showBackButton: true,
    showLabel: true,
  },
];

const NON_ADMIN = [
  {
    id: ROUTES.PROGRAM,
    icon: <FolderShared />,
    label: 'Programs',
    component: <CrmProgram />,
    hideForAdmin: true,
    isRestrictedForUser: true,
  },
  {
    id: ROUTES.POLICY,
    icon: <AdminPanelSettings />,
    label: 'Policy',
    component: <Policy />,
    hideForAdmin: true,
    isRestrictedForUser: true,
  },
];

const CrmContainer = (props: Props) => {
  const { isAdmin } = useClientAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showLendingPage } = useAppSelector(getState);
  const dispatch = useAppDispatch();
  const { loggedInUserDetails } = useClientAuth();
  const clientEmail = loggedInUserDetails?.email;
  const { restrictedUsers } = useAppSelector(({ header }) => ({
    restrictedUsers: header.restrictedUsers,
  }));

  const view = searchParams.get('view');

  const navigate2Details = (req_id: string) => {
    setSelectedItem(NAV_OPTIONS[6]);
    setSearchParams(
      createSearchParams({
        [CONSTANTS.SELECTED_VIEW]: NAV_OPTIONS[6].id,
        req_id,
      })
    );
  };

  // const NAV_OPTIONS_EDITED = NAV_OPTIONS.map((el) =>
  //   el.id === BACK_OFFICE_ROUTES.OPERATIONS
  //     ? {
  //         ...el,
  //         component: (
  //           <ApplicationManagmentProvider>
  //             <Operations navigate2Details={navigate2Details} />
  //           </ApplicationManagmentProvider>
  //         ),
  //       }
  //     : el
  // );

  const [selectedItem, setSelectedItem] = useState<
    SidebarListItemType | undefined
  >(NAV_OPTIONS.find((el) => el.id === view));

  useEffect(() => {
    setSelectedItem(
      (isAdmin ? NAV_OPTIONS : [...NON_ADMIN, ...NAV_OPTIONS]).find(
        (el) => el.id === view
      )
    );
  }, [view, isAdmin]);

  useEffect(() => {
    if (!selectedItem) {
      dispatch(setShowLendingPage(true));
    }
  }, [selectedItem, dispatch]);

  const isRestrictedUser =
    clientEmail && clientEmail.trim() !== ''
      ? restrictedUsers.includes(clientEmail)
      : true;

  return (
    <Box sx={{ height: '100%' }}>
      {showLendingPage ? (
        <BackOfficeLendingPage setSelectedItem={setSelectedItem} />
      ) : (
        <SidebarLayout
          sidebarListItems={
            isAdmin ? NAV_OPTIONS : [...NON_ADMIN, ...NAV_OPTIONS]
          }
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          isRestrictedUser={isRestrictedUser}
        />
      )}
    </Box>
  );
};

export default CrmContainer;
