import capitalize from 'lodash/capitalize';
import { Fragment, useState } from 'react';
import { ResourceType } from '../../hooks/useInstitutionalResources';
import { CountryCodeResource } from '../../types/resources';
import { useSearchContext } from './searchValueContext';
import VegaDataGridV4 from '../common/v4/VegaDataGridV4';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { useResourcesQuery } from '../../store/queries/resourcesQueries';

function CountryCodeTable() {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const { searchValue, searchBy } = useSearchContext();
  const { isLoading, data: paginatedData } =
    useResourcesQuery<CountryCodeResource>({
      type: ResourceType.COUNTRY_CODE,
      key: 'fetchCountryCodes',
      page,
      pageSize,
      searchBy: searchBy as string,
      searchQuery: searchValue,
    });

  return (
    <Fragment>
      <VegaDataGridV4
        data={paginatedData?.records ?? []}
        columns={columnCountryCode}
        idColumn={'id'}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        page={page}
        pageSize={pageSize}
        rowCount={paginatedData?.totalItems}
        paginationMode="server"
        loading={isLoading}
      />
    </Fragment>
  );
}

export default CountryCodeTable;

const columnCountryCode: GridColDef<CountryCodeResource>[] = [
  {
    field: 'country',
    headerName: 'Country Name',
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      capitalize(params.value),
    flex: 1,
  },

  {
    field: 'countryCode',
    headerName: 'ISO Code',
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      params?.value?.toUpperCase(),
    flex: 1,
  },
];
