import VegaCardV2 from '../../common/v2/VegaCardV2';
import { Stack } from '@mui/material';
import AccountCloseDetailsMain from './AccountCloseDetailsMain';
import AccounCloseRequestDetails from './AccounCloseRequestDetails';
import AccountClosePreReqChecks from './AccountClosePreReqChecks';
import AccountClosureActionBtns from './AccountClosureActionBtns';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { closeAccountRequestDetailsAPI } from '../../../api/accountClosureAPIs';
import AccountClosureStatusComponent from './AccountClosureStatusComponent';
import { AccountClosureStatus } from '../../../enums/account.enum';

export const AccountCloseDetails = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const id = searchParams.get('req_id');
  const { preChecks, status } = useAppSelector(({ accountClosure }) => ({
    status: accountClosure.accountClosureRequestDetails.payload?.status,
    preChecks:
      accountClosure.accountClosureRequestDetails.payload
        ?.preRequisiteCheckList,
  }));
  const isIssuePresent =
    preChecks?.find((check) => (check.flags?.length || 0) > 0) !== undefined;

  useEffect(() => {
    if (!id) return;
    dispatch(
      closeAccountRequestDetailsAPI({
        payload: {
          id,
        },
      })
    );
  }, [id, dispatch]);

  return (
    <Stack gap={1}>
      <VegaCardV2 padding={'24px'}>
        <AccountCloseDetailsMain />
      </VegaCardV2>
      <VegaCardV2 padding={'24px'}>
        <Stack gap={3}>
          <AccounCloseRequestDetails />
          <AccountClosePreReqChecks isIssuePresent={isIssuePresent} />
          {status === AccountClosureStatus.PENDING ? (
            <AccountClosureActionBtns isIssuePresent={isIssuePresent} />
          ) : (
            <AccountClosureStatusComponent />
          )}
        </Stack>
      </VegaCardV2>
    </Stack>
  );
};
