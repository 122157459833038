import { VegaSelectOptionType } from '../common/v4/VegaSelectV4';

export type ResourceTab = 'merchants' | 'mcc' | 'mcg' | 'country' | 'pinCode';

export type ResourceTabOptionType = {
  value: ResourceTab;
  label: string;
};

export const filterResourceTabOptions: ResourceTabOptionType[] = [
  { value: 'merchants', label: 'Merchants' },
  { value: 'mcc', label: 'MCC' },
  { value: 'mcg', label: 'MCG' },
  { value: 'country', label: 'Country' },
  { value: 'pinCode', label: 'Pin Code Mapper' },
];

export const mccOptions: VegaSelectOptionType[] = [
  {
    name: 'Code',
    id: 'code',
  },
  {
    name: 'Description',
    id: 'description',
  },
];
export const mcgOptions = [
  {
    name: 'Category',
    id: 'category',
  },
  {
    name: 'Risk Profile',
    id: 'riskProfile',
  },
];
export const merchantOptions = [
  {
    name: 'Name',
    id: 'name',
  },
  {
    name: 'MID',
    id: 'mid',
  },
  {
    name: 'TID',
    id: 'tid',
  },
];

export const countryOptions = [
  {
    name: 'Country Name',
    id: 'country',
  },
  {
    name: 'ISO Code',
    id: 'countryCode',
  },
];

export const pinCodeOptions = [
  {
    name: 'City',
    id: 'city',
  },
  {
    name: 'State',
    id: 'state',
  },
  {
    name: 'Country',
    id: 'country',
  },
  {
    name: 'Pincode',
    id: 'pincode',
  },
];

export const searchByOptions: Record<ResourceTab, VegaSelectOptionType[]> = {
  merchants: merchantOptions,
  mcc: mccOptions,
  mcg: mcgOptions,
  country: countryOptions,
  pinCode: pinCodeOptions,
};
