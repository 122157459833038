/** @format */

import axios from 'axios';
import { BASE_URL, GETWAY, PROGRAM } from './endpoints';
import { formContentType } from '../../../api/endpoints';

// const PROGRAM_URL = `${BASE_URL}${GETWAY}${PROGRAM.BASE}`;
const PROGRAM_URL = `${BASE_URL}${PROGRAM.BASE}`;

export const createProgramForMCC = (data: any) => {
  return axios.post(PROGRAM_URL, data);
};
export const updateProgramForMCC = (data: any) => {
  return axios.put(PROGRAM_URL, data, {
    headers: { ...formContentType },
  });
};

export const getMccProgramById = (programId: string) => {
  return axios.get(PROGRAM_URL + '/' + programId);
};

export const fetchMCCProgramList = (
  clientId: string,
  page: number,
  pageSize: number
) => {
  return axios.get(
    PROGRAM_URL + `/list?clientId=${clientId}&page=${page}&pageSize=${pageSize}`
  );
};

export const getProgramDocsSignedUrl = (privateUrl: string) => {
  return axios.post(
    PROGRAM_URL + `/getSignedUrlForDocs?privateUrl=${privateUrl}`
  );
};
