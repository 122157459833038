export interface RewardResponseProps {
  id: string;
  accountId: string;
  installmentId: string | null;
  createdAt: string;
  updatedAt: string;
  pointAccrued: number;
  pointRealized: number;
  pointRevoked: number;
  status: RewardsLedgerStatus;
  remarks: string;
  date: string;
  loanId: string | null;
}

export enum RewardsLedgerStatus {
  ACCRUED = 'ACCRUED',
  REALIZED = 'REALIZED',
  REVOKED = 'REVOKED',
}
