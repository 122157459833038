import { Checkbox, CheckboxProps, Stack } from '@mui/material';
import {
  INDICATIVE,
  NEUTRALS,
  PRIMARY_COLOR,
  QUICK_ACCESS,
  VEGA_COMMON,
} from '../../../constants/style';
import { LabelTypo, ListGroupHeadingTypo, TextProps } from '../Typography';
import DoneIcon from '@mui/icons-material/Done';

interface IVegaCheckboxV4Props {
  label: string;
  labelAlign?: 'right' | 'left';
  headerTypo?: (props: TextProps) => JSX.Element;
  helper?: string;
  error?: boolean;
  checkboxProps: CheckboxProps;
}

const VegaCheckboxV4 = ({
  headerTypo,
  label,
  labelAlign = 'right',
  helper,
  error,
  checkboxProps,
}: IVegaCheckboxV4Props) => {
  const HEADER_TYPO = headerTypo || ListGroupHeadingTypo;
  const { sx, required, disabled, checked, ...restCheckboxProps } =
    checkboxProps;

  return (
    <Stack gap={0.5}>
      <Checkbox
        {...restCheckboxProps}
        required={required}
        disabled={disabled}
        checked={checked || false}
        sx={{
          p: 0,
          opacity: disabled ? 0.5 : 1,
          ':hover': {
            background: 'none',
            '.checkbox-v4': {
              backgroundColor: PRIMARY_COLOR.blue[50],
              borderColor: PRIMARY_COLOR.blue[400],
            },
            '.checkbox-v4-tick': {
              color: PRIMARY_COLOR.blue[400],
            },
          },
          ...sx,
        }}
        disableRipple
        icon={getIcon(
          false,
          HEADER_TYPO,
          labelAlign,
          label,
          required,
          disabled
        )}
        checkedIcon={getIcon(
          true,
          HEADER_TYPO,
          labelAlign,
          label,
          required,
          disabled
        )}
      />
      {Boolean(helper) && (
        <LabelTypo
          sx={{
            color: error ? INDICATIVE.red[500] : VEGA_COMMON.text100,
          }}
        >
          {helper}
        </LabelTypo>
      )}
    </Stack>
  );
};

const getIcon = (
  checked: boolean,
  HEADER_TYPO: (props: TextProps) => JSX.Element,
  labelAlign?: 'left' | 'right',
  label?: string,
  required?: boolean,
  disabled?: boolean
) => {
  const bg =
    checked && !disabled
      ? PRIMARY_COLOR.blue[50]
      : checked && disabled
      ? NEUTRALS.grey[100]
      : undefined;
  const borderBg =
    checked && !disabled ? QUICK_ACCESS.primary.blue : NEUTRALS.grey[600];

  return (
    <Stack
      className="cursor-pointer"
      direction="row"
      gap={1}
      alignItems="baseline"
    >
      {labelAlign === 'left' && label && (
        <Stack direction="row" alignItems="center" gap={0.5}>
          <HEADER_TYPO sx={{ color: QUICK_ACCESS.text[200] }}>
            {label}
          </HEADER_TYPO>
          {required && <span style={{ color: 'red' }}>*</span>}
        </Stack>
      )}
      <Stack
        sx={{
          border: `1px solid ${borderBg}`,
          backgroundColor: bg,
          height: 16,
          width: 16,
          borderRadius: '4px',
        }}
        justifyContent="center"
        alignItems="center"
        className="checkbox-v4"
      >
        <DoneIcon
          sx={{
            fontSize: '12px',
            visibility: checked ? 'visible' : 'hidden',
            color: QUICK_ACCESS.primary.blue,
          }}
          className="checkbox-v4-tick"
        />
      </Stack>
      {labelAlign === 'right' && label && (
        <Stack direction="row" alignItems="center" gap={0.5}>
          <HEADER_TYPO sx={{ color: QUICK_ACCESS.text[200] }}>
            {label}
          </HEADER_TYPO>
          {required && !disabled && (
            <span style={{ color: INDICATIVE.red[500] }}>*</span>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default VegaCheckboxV4;
