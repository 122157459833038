import VegaDrawerV4, {
  IVegaDrawerV4Props,
} from '../../../../../../common/v4/VegaDrawerV4';
import { Stack } from '@mui/material';
import VegaBadgeStatusTagV4 from '../../../../../../common/v4/VegaBadgeStatusTagV4';
import { NEUTRALS, QUICK_ACCESS } from '../../../../../../../constants/style';
import { ComponentBody1Typo } from '../../../../../../common/Typography';
import dayjs from 'dayjs';
import { DATE_FORMATE } from '../../../../../../../constants/url';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import VegaSelectV4 from '../../../../../../common/v4/VegaSelectV4';
import {
  CARD_REISSUE_REASON,
  QUERY_NAMES,
} from '../../../../../../../enums/commons';
import { useEffect, useMemo, useState } from 'react';
import { pascalCaseFromCamelCase } from '../../../../../../../utils/stringUtils';
import { replaceCard, updateCardStatus } from '../../../../../../../api/card';
import { useSupport } from '../../../../../../../providers/SupportProvider';
import { AxiosError } from 'axios';
import { useSnackbar } from '../../../../../../../providers/SnackbarProvider';
import { useQueryClient } from 'react-query';
import { getCardColor } from '../../../../supportUtils';
import VegaTextfieldV4 from '../../../../../../common/v4/VegaTextfieldV4';
import { useClientAuth } from '../../../../../../../providers/ClientProvider';
import { CardDto, CardStatus } from '../../../../../../../types/card';

type ReissueFormValues = {
  reason: string;
};

type UpdateFormValues = {
  status: CardStatus;
  reason: string;
};

const CardUpdateDrawer = (
  props: IVegaDrawerV4Props & {
    drawerState: 'update' | 'reissue';
    selectedCard?: CardDto;
  }
) => {
  const { drawerState, selectedCard, submitBtn, open, onClose, ...rest } =
    props;
  const reissueForm = useForm<ReissueFormValues>();
  const updateForm = useForm<UpdateFormValues>();
  const [submitLoad, setSubmitLoad] = useState(false);
  const { clientId } = useClientAuth();
  const { accountData } = useSupport();
  const { setSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const watchReissueReason = reissueForm.watch('reason');
  const watchStatus = updateForm.watch('status');

  const data = useMemo(
    () => [
      { key: 'Customer name', value: selectedCard?.nameOnCard },
      { key: 'Card Number', value: selectedCard?.cardNumber },
      {
        key: 'Expiry date',
        value: selectedCard?.expiryDate,
      },
    ],
    [selectedCard]
  );

  const onFormSubmit = () => {
    setSubmitLoad(true);
    if (drawerState === 'reissue') {
      if (accountData?.accountId && selectedCard?.id) {
        replaceCard(
          selectedCard?.id,
          reissueForm.getValues().reason,
          clientId || ''
        )
          .then(function (res) {
            setSnackbar('New Card Reissued Successfully!');
            onClose?.({}, 'backdropClick');
            queryClient.invalidateQueries([
              QUERY_NAMES.CARD_LIST,
              accountData.accountId,
            ]);
          })
          .catch(function (res: AxiosError) {
            setSnackbar(res.message, 'error');
          })
          .finally(() => {
            setSubmitLoad(false);
          });
      }
    } else {
      if (selectedCard?.id && accountData?.accountId) {
        const { status, reason } = updateForm.getValues();
        updateCardStatus({
          cardId: selectedCard?.id,
          cardStatus: status,
          reason,
        })
          .then(function (res) {
            setSnackbar('Card Status Updated Successfully!');
            onClose?.({}, 'backdropClick');
            queryClient.invalidateQueries([
              QUERY_NAMES.CARD_LIST,
              accountData.accountId,
            ]);
          })
          .catch(function (res: AxiosError) {
            setSnackbar(res.message, 'error');
          })
          .finally(() => {
            setSubmitLoad(false);
          });
      }
    }
  };

  useEffect(() => {
    if (!open) {
      reissueForm.reset();
      updateForm.reset();
    }
  }, [open, reissueForm, updateForm]);

  return (
    <VegaDrawerV4
      {...rest}
      open={open}
      onClose={onClose}
      submitBtn={{
        props: {
          onClick: onFormSubmit,
          value:
            drawerState === 'reissue' ? 'Reissue Card' : 'Update card status',
          disabled:
            (drawerState === 'reissue' ? !watchReissueReason : !watchStatus) ||
            submitLoad,
          loading: submitLoad,
        },
        show: true,
      }}
    >
      <Stack gap={3}>
        <Stack
          p={2}
          gap={1.5}
          width={400}
          sx={{ background: QUICK_ACCESS.background[100], borderRadius: '8px' }}
        >
          {selectedCard?.cardStatus && (
            <VegaBadgeStatusTagV4
              text={pascalCaseFromCamelCase(selectedCard?.cardStatus || '')}
              color={getCardColor(selectedCard?.cardStatus)}
              variant={'light-filled'}
            />
          )}
          {data.map((item, idx) =>
            item.value ? (
              <Stack
                key={idx}
                gap={1}
                direction={'row'}
                justifyContent="space-between"
              >
                <ComponentBody1Typo sx={{ color: QUICK_ACCESS.text[100] }}>
                  {item.key}
                </ComponentBody1Typo>
                <ComponentBody1Typo>{item.value}</ComponentBody1Typo>
              </Stack>
            ) : null
          )}
        </Stack>
        {drawerState === 'reissue' ? (
          <CardReissue form={reissueForm} />
        ) : (
          <CardUpdate form={updateForm} />
        )}
      </Stack>
    </VegaDrawerV4>
  );
};

const CardUpdate = ({
  form,
}: {
  form: UseFormReturn<UpdateFormValues, any, undefined>;
}) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Stack gap={3}>
      <Controller
        name="status"
        control={control}
        rules={{
          required: 'Required field',
        }}
        render={({ field: { ref, ...rest } }) => (
          <VegaSelectV4
            {...rest}
            error={Boolean(errors.status)}
            header="Change status to"
            labelText={errors.status?.message}
            value={rest.value || ([] as any)}
            options={Object.values(CardStatus).map((el) => ({
              name: pascalCaseFromCamelCase(el),
              id: el,
            }))}
            standardWidth="100%"
            required
            wrapperProps={{
              sx: {
                flex: 0,
              },
            }}
            placeholder="Select Status"
          />
        )}
      />
      <Controller
        name="reason"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <VegaTextfieldV4
            {...rest}
            error={Boolean(errors.reason)}
            header="Reason"
            labelText={errors.reason?.message}
            placeholder="Write the reason for changing the card status"
            standardWidth="100%"
            multiline
            rows={4}
          />
        )}
      />
    </Stack>
  );
};

const CardReissue = ({
  form,
}: {
  form: UseFormReturn<ReissueFormValues, any, undefined>;
}) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Controller
      name="reason"
      control={control}
      rules={{
        required: 'Required field',
      }}
      render={({ field: { ref, ...rest } }) => (
        <VegaSelectV4
          {...rest}
          error={Boolean(errors.reason)}
          header="Reason"
          labelText={errors.reason?.message}
          value={rest.value || ([] as any)}
          options={Object.values(CARD_REISSUE_REASON).map((el) => ({
            name: pascalCaseFromCamelCase(el),
            id: el,
          }))}
          standardWidth="100%"
          wrapperProps={{
            sx: {
              flex: 0,
            },
          }}
          required
          placeholder="Select Reason"
        />
      )}
    />
  );
};

export default CardUpdateDrawer;
