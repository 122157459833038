import { Box } from '@mui/material';
import PartnerManagementBrandList from './PartnerManagementBrandList';
import VegaHeaderV4 from '../common/v4/VegaHeaderV4';
import { usePartnerMgmtContext } from '../../mcc/Program-Dashboard/pages/PartnerManagementWrap';
import AddSquare02Icon from '../icon/AddSquare02Icon';

const PartnerManagementBrandView = () => {
  const { setCurrState } = usePartnerMgmtContext();

  const go2AddCoBrandForm = () => {
    setCurrState?.('brand-form-add');
  };

  return (
    <Box>
      <VegaHeaderV4
        title="Partner Management"
        actionBtnProps={{
          value: 'Add Co-Brand Partner',
          startIcon: <AddSquare02Icon />,
          variant: 'contained',
          onClick: go2AddCoBrandForm,
        }}
        sx={{ pt: 5 }}
      />
      <Box p={3}>
        <PartnerManagementBrandList />
      </Box>
    </Box>
  );
};

export default PartnerManagementBrandView;
