import { Box, Stack } from '@mui/material';
import {
  TransactionDto,
  TransactionJourneyStatus,
} from '../../../../../../../types/transaction';
import {
  ActionLargeTypo,
  Label2Typo,
  ListHead1Typo,
} from '../../../../../../common/Typography';
import { INDICATIVE, QUICK_ACCESS } from '../../../../../../../constants/style';
import { useTransactionJourneyQuery } from '../../../../../../../store/queries/transactionQueries';
import dayjs from 'dayjs';
import { DATE_TIME_SHORT_FORMAT } from '../../../../../../../constants/url';

interface ITransactionJourneyProps {
  data: TransactionDto | undefined;
}

const TransactionJourney = ({ data }: ITransactionJourneyProps) => {
  const { data: jouneyData, isFetching } = useTransactionJourneyQuery({
    transactionId: 'f7c03e8a-2655-41f2-b0df-faa25045ea4f',
  });

  const getStructuredJourneyData = () => {
    const jouneyDataTemp = [...(jouneyData || [])].reverse();

    const isAuthSuccess = [
      TransactionJourneyStatus.AUTHORIZED,
      TransactionJourneyStatus.AUTH_NOT_REQUIRED,
    ].includes(jouneyDataTemp[0]?.status);
    const isConfirmSuccess =
      jouneyDataTemp[1]?.status === TransactionJourneyStatus.SUCCESS;
    const isSettlementSuccess =
      jouneyDataTemp[2]?.status === TransactionJourneyStatus.SETTLED;

    return [
      {
        label: 'Authentication',
        date: jouneyDataTemp[0]?.updateTime
          ? dayjs(jouneyDataTemp[0]?.updateTime).format(DATE_TIME_SHORT_FORMAT)
          : undefined,
        color: isAuthSuccess ? QUICK_ACCESS.text[200] : INDICATIVE.yellow[500],
        color2: isAuthSuccess ? INDICATIVE.green[500] : QUICK_ACCESS.text[75],
        success: isAuthSuccess,
      },
      {
        label: 'Confirmation',
        date: jouneyDataTemp[1]?.updateTime
          ? dayjs(jouneyDataTemp[1]?.updateTime).format(DATE_TIME_SHORT_FORMAT)
          : undefined,
        color: isConfirmSuccess
          ? QUICK_ACCESS.text[200]
          : isAuthSuccess
          ? INDICATIVE.yellow[500]
          : QUICK_ACCESS.text[75],
        color2: isConfirmSuccess
          ? INDICATIVE.green[500]
          : QUICK_ACCESS.text[75],
        success: isConfirmSuccess,
      },
      {
        label: 'Settlement',
        date: jouneyDataTemp[2]?.updateTime
          ? dayjs(jouneyDataTemp[2]?.updateTime).format(DATE_TIME_SHORT_FORMAT)
          : undefined,
        color: isSettlementSuccess
          ? QUICK_ACCESS.text[200]
          : isConfirmSuccess
          ? INDICATIVE.yellow[500]
          : QUICK_ACCESS.text[75],
        color2: isSettlementSuccess
          ? INDICATIVE.green[500]
          : QUICK_ACCESS.text[75],
        success: isSettlementSuccess,
      },
    ];
  };

  const structuredJourneyData = getStructuredJourneyData();

  return (
    <Box
      className="position-relative"
      p={3}
      sx={{
        borderRadius: '8px',
        border: `1px solid ${QUICK_ACCESS.border[50]}`,
      }}
    >
      <Stack gap={2.5}>
        <ActionLargeTypo sx={{ color: QUICK_ACCESS.text[200] }}>
          Transaction Journey
        </ActionLargeTypo>
        <Stack gap={2.45} ml={2.2}>
          {structuredJourneyData.map((item) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              pb={2}
              key={item.label}
            >
              <ListHead1Typo sx={{ color: item.color }}>
                {item.label}
              </ListHead1Typo>
              {item.date && (
                <Label2Typo sx={{ color: QUICK_ACCESS.text[100] }}>
                  {item.date}
                </Label2Typo>
              )}
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack
        className="position-absolute"
        top={67}
        left={25}
        alignItems="center"
      >
        <Box
          width={8}
          height={8}
          borderRadius={4}
          sx={{
            background: structuredJourneyData[0]?.success
              ? structuredJourneyData[0]?.color2
              : structuredJourneyData[0]?.color,
          }}
        />
        <Box
          width={'2px'}
          height={44}
          sx={{
            borderColor: structuredJourneyData[0]?.color2,
            borderStyle: structuredJourneyData[0]?.success ? 'solid' : 'dashed',
            borderWidth: 0,
            borderLeftWidth: '2px',
          }}
        />
        <Box
          width={8}
          height={8}
          borderRadius={4}
          sx={{
            background: structuredJourneyData[1]?.success
              ? structuredJourneyData[1]?.color2
              : structuredJourneyData[1]?.color,
          }}
        />
        <Box
          width={'2px'}
          height={44}
          sx={{
            borderColor: structuredJourneyData[1]?.color2,
            borderStyle: structuredJourneyData[1]?.success ? 'solid' : 'dashed',
            borderWidth: 0,
            borderLeftWidth: '2px',
          }}
        />
        <Box
          width={8}
          height={8}
          borderRadius={4}
          sx={{
            background: structuredJourneyData[2]?.success
              ? structuredJourneyData[2]?.color2
              : structuredJourneyData[2]?.color,
          }}
        />
      </Stack>
    </Box>
  );
};

export default TransactionJourney;
