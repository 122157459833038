import { Controller, UseFormReturn } from 'react-hook-form';
import { IPartnerManagementAddBrandFormValues } from './PartnerManagementAddBrandForm';
import VegaContainerV4 from '../common/v4/VegaContainerV4';
import { Stack } from '@mui/material';
import { VALIDATE_REGEX } from '../../utils/validation.utils';
import VegaTextfieldV4 from '../common/v4/VegaTextfieldV4';
import { ListHead2Typo } from '../common/Typography';

interface IPartnerAdminDetailsFormProps {
  partnerFormHook: UseFormReturn<
    IPartnerManagementAddBrandFormValues,
    any,
    undefined
  >;
}

const PartnerAdminDetailsForm = ({
  partnerFormHook,
}: IPartnerAdminDetailsFormProps) => {
  const {
    control,
    formState: { errors },
  } = partnerFormHook;

  return (
    <VegaContainerV4 title="Admin Details" mt={3}>
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        <Controller
          name="adminDetails.name"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.FULL_NAME,
              message: 'Not a valid name',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.adminDetails?.name)}
              header="Admin Name"
              labelText={errors.adminDetails?.name?.message}
              required
              placeholder="Enter Admin Name"
            />
          )}
        />
        <Controller
          name="adminDetails.email"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.EMAIL,
              message: 'Not a valid email',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.adminDetails?.email)}
              header="Admin Email ID"
              labelText={errors.adminDetails?.email?.message}
              required
              placeholder="Enter Admin Email ID"
            />
          )}
        />
        <Controller
          name="adminDetails.mobileNumber"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.MOBILE,
              message: 'Not a valid mobile number',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.adminDetails?.mobileNumber)}
              header="Admin Mobile Number"
              labelText={errors.adminDetails?.mobileNumber?.message}
              type="tel"
              placeholder="Enter Admin Mobile Number"
            />
          )}
        />
        <Controller
          name="adminDetails.designation"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.FULL_NAME,
              message: 'Not a valid designation',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.adminDetails?.designation)}
              header="Designation"
              labelText={errors.adminDetails?.designation?.message}
              required
              placeholder="Enter Designation"
            />
          )}
        />
      </Stack>
    </VegaContainerV4>
  );
};

export default PartnerAdminDetailsForm;
