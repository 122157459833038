import React, { useMemo } from 'react';
import VegaTableV4, { ITableDataProps } from '../common/v4/VegaTableV4';
import VegaButtonV4 from '../common/v4/VegaButtonV4';
import { Box, Stack } from '@mui/material';
import EditIconV4 from '../iconography/EditIconV4';
import DeleteIconV4 from '../iconography/DeleteIconV4';

const records = [
  {
    name: 'hol 1',
    data: 1699605818608,
    states: ['Andhra Pradesh', 'Arunachal Pradesh', 'Puducherry', 'Tamil Nadu'],
  },
  {
    name: 'hol 2',
    data: 1699605818608,
    states: ['Andhra Pradesh', 'Arunachal Pradesh', 'Puducherry', 'Tamil Nadu'],
  },
];

interface IInstitutionStandardGazettedHolidaysProps {
  openDialog: () => void;
  openDrawer: () => void;
}

const InstitutionStandardGazettedHolidays = ({
  openDialog,
  openDrawer,
}: IInstitutionStandardGazettedHolidaysProps) => {
  const structuredData: ITableDataProps[] = useMemo(() => {
    return (
      records.map((rec, idx) => {
        return {
          rowData: [
            {
              value: rec.name,
              type: 'string',
            },
            {
              value: rec.data,
              type: 'string',
            },
            {
              value: rec.states.join(', '),
              type: 'string',
            },
            {
              type: 'jsx',
              cellSx: {
                padding: 1,
              },
              children: (
                <Stack direction="row" alignItems="center" gap={1.5}>
                  <VegaButtonV4
                    startIcon={<EditIconV4 />}
                    variant="mellowed"
                    size="small"
                    onClick={openDrawer}
                  />
                  <VegaButtonV4
                    startIcon={<DeleteIconV4 />}
                    variant="mellowed"
                    size="small"
                    onClick={openDialog}
                  />
                </Stack>
              ),
            },
          ],
        };
      }) || []
    );
  }, []);

  return (
    <Box mt={2}>
      <VegaTableV4
        head={['Holiday Name', 'Date', 'States', 'Action']}
        data={structuredData}
      />
    </Box>
  );
};

export default InstitutionStandardGazettedHolidays;
