import axios from 'axios';
import { BinAllocation } from '../../../types/card';
import { BASE_URL } from './endpoints';
import { CARD_PROCESSOR } from '../../../api/endpoints';

const CARD_BASE_URL = BASE_URL + CARD_PROCESSOR.BASE;
const BIN_BASE_URL = CARD_BASE_URL + CARD_PROCESSOR.BIN;

export interface IPaginationProps {
  page: number;
  size: number;
  clientId?: string;
  entityId?: string;
  entityLevel: string;
}

export const fetchListForInventory = (clientId: string) => {
  return axios.get(`${BIN_BASE_URL}/listForClient/${clientId}`);
};
export const fetchListForBinAlloctionEntity = (data: any) => {
  return axios.post(BIN_BASE_URL + '/getBinForEntity', data);
};
export const fetchListForBinAlloctionOrder = (data: any) => {
  return axios.post(BIN_BASE_URL + '/listBinAllocationOrder', data);
};
export const fetchBinAlloctionListForClient = (data: IPaginationProps) => {
  return axios.post(`${BIN_BASE_URL}/listBinAllocation`, data);
};
export const addInventoryOrder = (data: any) => {
  return axios.post(BIN_BASE_URL + '/binAllocation/order', data);
};

export const addBinAllocationSubmit = (data: any) => {
  return axios.post(BIN_BASE_URL + '/binAllocation/submit', data);
};
export const updateBinAllocationOrder = (data: any) => {
  return axios.put(BIN_BASE_URL + '/updateBinAllocationOrder', data);
};
export const updateBinAllocationStatus = (data: any) => {
  return axios.patch(BIN_BASE_URL + '/updateBinAllocationOrderStatus', data);
};

// STOCK TRANSFER
export const fetchListForStockTransfer = (data: any) => {
  return axios.post(BIN_BASE_URL + '/listBinAllocationTransfer', data);
};
export const initialCheckerMakerFortransfer = (data: any) => {
  return axios.post(
    BIN_BASE_URL + '/binAllocation/transfer/initiateCheckerMaker',
    data
  );
};

export const addStockTransferRequest = (data: any) => {
  return axios.post(BIN_BASE_URL + '/binAllocation/transfer', data);
};
export const updateStockTransferRequest = (data: any) => {
  return axios.put(BIN_BASE_URL + '/updateBinAllocationTransfer', data);
};
export const updateStockTransferStatus = (data: any) => {
  return axios.patch(BIN_BASE_URL + '/updateBinAllocationTransferStatus', data);
};

export async function getAllocatedBinsForProgram(data: {
  programId: string;
  page: number;
  size: number;
}): Promise<BinAllocation[]> {
  try {
    const endpoint = BIN_BASE_URL + '/binProgram/list';
    const response = await axios.post(endpoint, {
      programId: data.programId,
      page: data.page,
      size: data.size,
    });
    return response.data.records;
  } catch (error) {
    throw error;
  }
}
