import { Stack, Box } from '@mui/material';
import React from 'react';
import Vega_Text from '../../../../../common/v3/Vega_Text';
import { NEW_PRIMARY } from '../../../../../../constants/style';
import { VerifyIcon } from '../../../icons/SupportIcons';
import Vega_OutlineTextField from '../../../../../common/v3/Vega_OutlineTextField';
import { Search } from '@mui/icons-material';
import { toLowerCase } from '../../../../../../constants/commonFunction';

interface IProps {
  header?: string;
  children: JSX.Element;
}

const ApplicationDetailsContainer = ({ header, children }: IProps) => {
  return (
    <Box
      sx={{
        border: `1px solid #E1E4EB`,
        borderRadius: '12px',
        bgcolor: 'white',
        py: 2,
        px: 2.5,
        overflow: 'scroll',
        height: '100%',
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={2}
      >
        <Vega_Text
          text={header}
          fontWeight={600}
          color={NEW_PRIMARY.BLACK}
          fontSize={17}
        />
      </Stack>
      {children}
    </Box>
  );
};

export default ApplicationDetailsContainer;
