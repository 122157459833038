import { useQuery } from 'react-query';
import PartnerService from '../../services/PartnerService';
import { ListPartnerRequest } from '../../types/partner';

export const usePartnersList = (request: Partial<ListPartnerRequest>) => {
  const data = useQuery({
    queryKey: [
      'partner-list',
      request.clientId,
      request.page,
      request.pageSize,
      request.name,
    ],
    queryFn: async () => await PartnerService.listPartners(request),
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  });

  return data;
};
