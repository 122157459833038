import { createContext, useContext } from 'react';

type SearchValueContextType = {
  searchValue: string;
  searchBy: string | number;
};
export const SearchValueContext = createContext<SearchValueContextType>({
  searchValue: '',
  searchBy: '',
});

export const useSearchContext = () => useContext(SearchValueContext);
