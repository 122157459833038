import { CopyrightRounded } from '@mui/icons-material';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { setShowLendingPage } from '../../store/common/stateSlice';
import { useAppDispatch } from '../../store/hook';
import { DashboardType } from './SidebarLayout';
import { BackOfficeHomeIcon } from '../icon/BackOfficeHomeIcon';

interface IProps {
  open: boolean;
}

const SidebarBottomComponent = ({ open }: IProps) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  return (
    <>
      {pathname === DashboardType.BACK_OFFICE ? (
        <Stack
          direction={'row'}
          alignItems="center"
          spacing={1}
          sx={{
            paddingLeft: !open ? 4 : isMobile || isSmallScreen ? 4 : 3,

            justifyContent: open ? 'initial' : 'flex-start',
            transition: 'all 500ms ease-in-out',
            position: 'relative',
          }}
        >
          <Stack
            direction={'row'}
            alignItems="center"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch(setShowLendingPage(true));
              navigate(DashboardType.BACK_OFFICE);
            }}
          >
            <BackOfficeHomeIcon stroke="white" />
          </Stack>
          {open && (
            <Typography
              className="font-aspekta-500"
              sx={{
                pl: 2,
                m: 0,
              }}
              fontSize="0.8125rem"
            >
              Home
            </Typography>
          )}
        </Stack>
      ) : (
        <>
          <Typography
            variant="caption"
            sx={{ color: 'white', fontWeight: 500 }}
            pl={5}
          >
            Vegapay Version 1.0.36
          </Typography>
          <Stack direction={'row'} pl={5} alignItems="center" spacing={1}>
            <CopyrightRounded />
            <Typography variant="caption">
              Vegapay technology pvt. ltd.
            </Typography>
          </Stack>
        </>
      )}
    </>
  );
};

export default SidebarBottomComponent;
