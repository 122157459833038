import CalenderIconV5 from '../icon/CalenderIconV5';
import { PRIMARY_COLOR } from '../../constants/style';
import { DATE_FILTERS } from '../../enums/commons';
import dayjs from 'dayjs';
import { DATE_FORMAT_LETTERS, DATE_FORMAT_US } from '../../constants/url';

export const getFilterOptions = (
  selectedDateRange: { endDate: string; startDate: string },
  width: 'longer' | 'shorter'
) => {
  return [
    {
      text: selectedDateRange.endDate
        ? `${dayjs(selectedDateRange.startDate).format(
            DATE_FORMAT_LETTERS
          )} - ${dayjs(selectedDateRange.endDate).format(DATE_FORMAT_LETTERS)}`
        : 'Custom',
      icon: <CalenderIconV5 />,
      iconActive: <CalenderIconV5 stroke={PRIMARY_COLOR.blue[300]} />,
      values: selectedDateRange.endDate ? selectedDateRange : undefined,
    },
    {
      text: width === 'longer' ? DATE_FILTERS['15D'] : DATE_FILTERS['7D'],
    },
    {
      text: DATE_FILTERS['30D'],
    },
    {
      text: DATE_FILTERS['3M'],
    },
    {
      text: DATE_FILTERS['6M'],
    },
    {
      text: DATE_FILTERS['12M'],
    },
    {
      text: DATE_FILTERS['All'],
    },
  ];
};
