import { useState } from 'react';
import VegaTabV4, { ITabData } from '../common/v4/VegaTabV4';
import { Box } from '@mui/material';
import PartnerProgramListView from './PartnerProgramListView';
import PartnerTeamListView from './PartnerTeamListView';
import VegaHeaderV4 from '../common/v4/VegaHeaderV4';
import { usePartnerMgmtContext } from '../../mcc/Program-Dashboard/pages/PartnerManagementWrap';
import {
  capitalizeString,
  pascalCaseFromCamelCase,
} from '../../utils/stringUtils';
import { QUICK_ACCESS } from '../../constants/style';

type PartnerDetailTab = 'programs' | 'team';

const tabData: ITabData[] = [
  {
    value: 'programs',
    label: 'Programs',
    children: <PartnerProgramListView />,
  },
  {
    value: 'team',
    label: 'Team Members',
    children: <PartnerTeamListView />,
  },
];

const PartnerDetailsView = () => {
  const [tab, setTab] = useState<PartnerDetailTab>('programs');
  const { partner, setCurrState } = usePartnerMgmtContext();

  const onTabChange = (
    e: React.SyntheticEvent<Element, Event>,
    val: PartnerDetailTab
  ) => {
    setTab(val);
  };

  const moveBack2PartnerMgmtMainPage = () => {
    setCurrState?.('brand');
  };

  const go2editPartner = () => {
    setCurrState?.('brand-form-edit');
  };

  return (
    <Box>
      <VegaHeaderV4
        actionBtnProps={{
          value: 'Edit Partner Details',
          onClick: go2editPartner,
        }}
        breadcrumbs={[
          { label: 'Partner Management', link: moveBack2PartnerMgmtMainPage },
          { label: partner?.name || 'User' },
        ]}
        details={[
          { key: 'Partner Name', value: partner?.name || '' },
          {
            key: 'COMPANY TYPE',
            value: pascalCaseFromCamelCase(partner?.companyType || ''),
          },
          { key: 'PAN Number', value: capitalizeString(partner?.panNo) || '' },
        ]}
        sx={{
          backgroundColor: QUICK_ACCESS.background[50],
        }}
      />
      <Box px={3} py={2}>
        <VegaTabV4 tabData={tabData} value={tab} onChange={onTabChange} />
      </Box>
    </Box>
  );
};

export default PartnerDetailsView;
