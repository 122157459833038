import { Box, Stack } from '@mui/material';
import VegaDrawerV4 from '../common/v4/VegaDrawerV4';
import { Dispatch, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IAddUserRequest } from '../../mcc/Program-Dashboard/api/roles';
import { VALIDATE_REGEX } from '../../utils/validation.utils';
import VegaTextfieldV4 from '../common/v4/VegaTextfieldV4';
import { ListHead2Typo } from '../common/Typography';
import { usePartnerMgmtContext } from '../../mcc/Program-Dashboard/pages/PartnerManagementWrap';
import { useClientAuth } from '../../providers/ClientProvider';
import { addUserAndRoleForPartner } from '../../mcc/Program-Dashboard/components/PartnerMangement/utils/addUserForPartner';
import { useSnackbar } from '../../providers/SnackbarProvider';

interface IPartnerTeamMemberAdditionDrawerProps {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  refetch: any;
}

const PartnerTeamMemberAdditionDrawer = ({
  open,
  setOpen,
  refetch,
}: IPartnerTeamMemberAdditionDrawerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { partner } = usePartnerMgmtContext();
  const { user } = useClientAuth();
  const {
    control,
    formState: { errors, isDirty },
    reset,
    getValues,
    handleSubmit,
  } = useForm<IAddUserRequest>({
    mode: 'onTouched',
  });

  const { setSnackbar } = useSnackbar();

  const onDrawerClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = () => {
    if (!partner) return;
    const data = getValues();
    setIsLoading(true);
    addUserAndRoleForPartner({
      partner,
      user: data,
      currentUser: user,
    })
      .then((res) => {
        console.log('🚀 ~ file: ListOfTeamMembers.tsx:64 ~ res:', res);
        setSnackbar(`${data.name} was successfully added.`, 'success');
      })
      .catch((err) => {
        console.log('🚀 ~ file: ListOfTeamMembers.tsx:66 ~ err:', err);
        setSnackbar(err?.message || `Issue adding the user.`, 'error');
      })
      .finally(() => {
        setIsLoading(false);
        onDrawerClose();
        refetch();
      });
  };

  return (
    <Box>
      <VegaDrawerV4
        open={open}
        header="Add team member"
        submitBtn={{
          show: true,
          props: {
            onClick: handleSubmit(onSubmit),
            disabled: !isDirty || isLoading,
            loading: isLoading,
          },
        }}
        onClose={onDrawerClose}
      >
        <Stack gap={3}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: 'Required field',
              pattern: {
                value: VALIDATE_REGEX.FULL_NAME,
                message: 'Not a valid name',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.name)}
                header="Name"
                labelText={errors.name?.message}
                standardWidth={400}
                required
                placeholder="Enter Name"
              />
            )}
          />
          <Controller
            name="mobileNumber"
            control={control}
            rules={{
              required: 'Required field',
              pattern: {
                value: VALIDATE_REGEX.MOBILE,
                message: 'Not a valid mobile number',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.mobileNumber)}
                header="Mobile Number"
                labelText={errors.mobileNumber?.message}
                standardWidth={400}
                required
                placeholder="Enter Mobile Number"
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Required field',
              pattern: {
                value: VALIDATE_REGEX.EMAIL,
                message: 'Not a valid Email ID',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.email)}
                header="Email ID"
                labelText={errors.email?.message}
                standardWidth={400}
                required
                placeholder="Enter Email ID"
              />
            )}
          />
          <Controller
            name="designation"
            control={control}
            rules={{
              required: 'Required field',
              pattern: {
                value: VALIDATE_REGEX.FULL_NAME,
                message: 'Not a valid designation',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaTextfieldV4
                {...rest}
                error={Boolean(errors.designation)}
                header="Designation"
                labelText={errors.designation?.message}
                standardWidth={400}
                required
                placeholder="Enter Designation"
              />
            )}
          />
        </Stack>
      </VegaDrawerV4>
    </Box>
  );
};

export default PartnerTeamMemberAdditionDrawer;
