/* eslint-disable react/jsx-pascal-case */
import { MouseEvent, useMemo, useState } from 'react';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { ChargeBackProvider } from '../../../../../ChargeBack/hooks';
import VegaTableV4 from '../../../../../common/v4/VegaTableV4';
import { useTransactionListQuery } from '../../../../../../store/queries/transactionQueries';
import { getTransactionListStructuredData } from '../../../supportUtils';
import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../../../common/Typography';
import VegaButtonV4 from '../../../../../common/v4/VegaButtonV4';
import VegaSearchfieldV4 from '../../../../../common/v4/VegaSearchfieldV4';
import AdjustTransactionDrawer from './AdjustTransactionDrawer';
import VegaNoResults from '../../../../../common/VegaNoResults';
import TransactionDetails from './TransactionDetails/TransactionDetails';
import { TransactionDto } from '../../../../../../types/transaction';
import { useKeyValHook } from '../../../../../../hooks/useKeyValHook';
import {
  TransactionCode,
  TransactionIndicator,
  TransactionStatus,
} from '../../../../../../enums/transaction';
import VegaSelectV4 from '../../../../../common/v4/VegaSelectV4';
import { pascalCaseFromCamelCase } from '../../../../../../utils/stringUtils';
import VegaDateRangePickerPopoverV4 from '../../../../../common/v4/VegaDateRangePickerPopoverV4';
import VegaTextfieldV4 from '../../../../../common/v4/VegaTextfieldV4';
import dayjs from 'dayjs';
import { DATE_FORMATE_SHORT } from '../../../../../../constants/url';
import { getDateInputIcons } from '../../../../../../utils/common.utils';
import useDateRangeStandardHook from '../../../../../../hooks/useDateRangeStandardHook';

interface ITransFilter {
  date?: Date;
  category?: TransactionCode | 'All';
  indicator?: TransactionIndicator | 'All';
  status?: TransactionStatus | 'All';
  search?: string;
  startDate?: string;
  endDate?: string;
}

type Keys =
  | 'date'
  | 'category'
  | 'indicator'
  | 'status'
  | 'search'
  | 'startDate'
  | 'endDate';

const Transactions = () => {
  const { accountData } = useSupport();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [viewTransactionDetails, setViewTransactionDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<
    TransactionDto | undefined
  >(undefined);

  const updatePage2Zero = () => {
    setPage(0);
  };

  const { updateValue, values } = useKeyValHook<ITransFilter, Keys>(
    {
      indicator: 'All',
      status: 'All',
      category: 'All',
      search: undefined,
    },
    updatePage2Zero
  );

  const toggleViewTransactionDetails = () => {
    setViewTransactionDetails((val) => !val);
  };

  const indicatorList =
    values.indicator && values.indicator !== 'All'
      ? [values.indicator]
      : undefined;

  const statusList =
    values.status && values.status !== 'All' ? [values.status] : undefined;

  const transactionCodeList =
    values.category && values.category !== 'All'
      ? [values.category]
      : undefined;

  const { startDate2Pass, endDate2Pass } = useDateRangeStandardHook(
    values.startDate,
    values.endDate
  );

  const { data, isFetching, refetch } = useTransactionListQuery({
    accountId: accountData?.accountId || '',
    pageNumber: page,
    transactionIndicatorList: indicatorList,
    statusList,
    transactionCodeList,
    pageSize,
    enabled: Boolean(accountData?.accountId),
    search: values.search,
    startDate: startDate2Pass,
    endDate: endDate2Pass,
  });

  const structuredData = getTransactionListStructuredData(
    data?.records,
    toggleViewTransactionDetails,
    setSelectedTransaction
  );

  const adjustTransactionDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const updateRecord = (key: Keys) => (e: any) => {
    updateValue(
      key,
      key === 'search' && e.target.value === ''
        ? undefined
        : (e.target.value as string)
    );
  };

  const clearSearch = () => {
    updateValue('search', undefined);
  };

  const clearDate = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    updateValue('startDate', undefined);
    updateValue('endDate', undefined);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitAndClose = (startDate: string, endDate: string) => {
    updateValue('startDate', startDate);
    updateValue('endDate', endDate);
    handleClose();
  };

  const openRange = Boolean(anchorEl);
  const id = openRange ? 'simple-popover' : undefined;

  return viewTransactionDetails ? (
    <TransactionDetails
      data={selectedTransaction}
      goBack={toggleViewTransactionDetails}
    />
  ) : (
    <ChargeBackProvider>
      <Stack gap={3} mb={2}>
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <PageHeadingTypo>Transactions</PageHeadingTypo>
          <VegaButtonV4
            variant="mellowed"
            onClick={adjustTransactionDrawerOpen}
          >
            Adjust Transaction
          </VegaButtonV4>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={1}>
            <VegaTextfieldV4
              onClick={handleClick}
              value={
                Boolean(values.endDate && values.endDate)
                  ? `${dayjs(values.startDate).format(
                      DATE_FORMATE_SHORT
                    )} - ${dayjs(values.endDate).format(DATE_FORMATE_SHORT)}`
                  : undefined
              }
              standardWidth={
                Boolean(values.endDate && values.endDate) ? 190 : 95
              }
              placeholder="By Date"
              sx={{ caretColor: 'transparent' }}
              InputProps={getDateInputIcons(
                Boolean(values.startDate),
                clearDate
              )}
            />
            <VegaSelectV4
              onChange={updateRecord('category')}
              value={values.category}
              options={['All', ...Object.values(TransactionCode)].map((el) => ({
                name:
                  el === 'All' ? 'All category' : pascalCaseFromCamelCase(el),
                id: el,
              }))}
              standardWidth={130}
            />
            <VegaSelectV4
              onChange={updateRecord('indicator')}
              value={values.indicator}
              options={['All', ...Object.values(TransactionIndicator)].map(
                (el) => ({
                  name:
                    el === 'All'
                      ? 'All indicator'
                      : pascalCaseFromCamelCase(el),
                  id: el,
                })
              )}
              defaultValue={values.indicator}
              standardWidth={130}
            />
            <VegaSelectV4
              onChange={updateRecord('status')}
              value={values.status}
              options={['All', ...Object.values(TransactionStatus)].map(
                (el) => ({
                  name:
                    el === 'All' ? 'All status' : pascalCaseFromCamelCase(el),
                  id: el,
                })
              )}
              standardWidth={130}
            />
          </Stack>
          <VegaSearchfieldV4
            value={values.search}
            onChange={updateRecord('search')}
            onClear={clearSearch}
            placeholder="Search by transaction Id..."
          />
        </Stack>
      </Stack>
      {!Boolean(data?.records.length) && !isFetching ? (
        <VegaNoResults errorText="No transactions found" />
      ) : (
        <VegaTableV4
          head={[
            'Merchant Name',
            'Transaction Time',
            'Transaction ID',
            'Amount',
            'Overlimit',
            'Transaction Category',
            'Indicator',
            'Actions',
            'Status',
          ]}
          data={structuredData}
          pagination={{
            rowsPerPage: pageSize,
            setRowsPerPage: setPageSize,
            setPage,
            page,
            totalRecords: data?.totalItems || 0,
          }}
          isLoading={isFetching}
        />
      )}
      <AdjustTransactionDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        refetch={refetch}
      />
      <VegaDateRangePickerPopoverV4
        open={openRange}
        onClose={handleClose}
        submitDateRange={submitAndClose}
        popoverProps={{
          anchorEl,
          onClose: handleClose,
          open: openRange,
          id,
        }}
        initialValues={{
          startDate: values.startDate || '',
          endDate: values.endDate || '',
        }}
      />
    </ChargeBackProvider>
  );
};

export default Transactions;
