import * as React from 'react';
import { SVGProps } from 'react';
import { NEUTRALS } from '../../constants/style';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66654 2.66732C4.4574 2.66732 2.66654 4.45818 2.66654 6.66732C2.66654 8.87646 4.4574 10.6673 6.66654 10.6673C8.87568 10.6673 10.6665 8.87646 10.6665 6.66732C10.6665 4.45818 8.87568 2.66732 6.66654 2.66732ZM1.33321 6.66732C1.33321 3.7218 3.72102 1.33398 6.66654 1.33398C9.61206 1.33398 11.9999 3.7218 11.9999 6.66732C11.9999 7.89979 11.5818 9.03463 10.8798 9.93775L14.4713 13.5292C14.7316 13.7896 14.7316 14.2117 14.4713 14.4721C14.2109 14.7324 13.7888 14.7324 13.5285 14.4721L9.93697 10.8806C9.03385 11.5826 7.89901 12.0007 6.66654 12.0007C3.72102 12.0007 1.33321 9.61284 1.33321 6.66732Z"
      fill={props.fill ?? NEUTRALS.grey[400]}
    />
  </svg>
);
export default SearchIcon;
