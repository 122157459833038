import { SVGProps } from 'react';

const SupportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M9.69706 9.69706C8.7598 10.6343 7.2402 10.6343 6.30294 9.69706M9.69706 9.69706C10.6343 8.7598 10.6343 7.2402 9.69706 6.30295M9.69706 9.69706L12.2426 12.2426M6.30294 9.69706C5.36569 8.7598 5.36569 7.2402 6.30294 6.30295M6.30294 9.69706L3.75736 12.2426M6.30294 6.30295C7.2402 5.36569 8.7598 5.36569 9.69706 6.30295M6.30294 6.30295L3.75736 3.75736M9.69706 6.30295L12.2426 3.75736M12.5255 12.5255C10.0261 15.0248 5.97387 15.0248 3.47452 12.5255C0.975162 10.0261 0.975162 5.97387 3.47452 3.47452C5.97387 0.975163 10.0261 0.975163 12.5255 3.47452C15.0248 5.97387 15.0248 10.0261 12.5255 12.5255Z"
      stroke={props.stroke || '#EBEDEF'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SupportIcon;
