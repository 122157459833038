import * as React from 'react';
import { SVGProps } from 'react';
import { QUICK_ACCESS } from '../../constants/style';
const EditContainedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M7.36582 2.23364H3.36582C2.04034 2.23364 0.96582 3.30815 0.96582 4.63364V12.6337C0.96582 13.9592 2.04034 15.0337 3.36582 15.0337H11.3658C12.6913 15.0337 13.7658 13.9592 13.7658 12.6337L13.7658 8.63367M4.96582 11.0336L7.87648 10.4472C8.03099 10.416 8.17287 10.3399 8.2843 10.2285L14.8001 3.70909C15.1125 3.39652 15.1123 2.88986 14.7996 2.57756L13.4193 1.19884C13.1068 0.88666 12.6004 0.886873 12.2881 1.19931L5.77166 7.71935C5.66045 7.83062 5.58452 7.97221 5.55336 8.12641L4.96582 11.0336Z"
      stroke={props.stroke ?? QUICK_ACCESS.primary.blue}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EditContainedIcon;
