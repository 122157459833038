/** @format */

import axios from 'axios';
import { getEnv } from '../constants/url';
import { ENV } from '../enums/commons';

const API_GATEWAY = 'https://prod-api.vegapay.tech';
const ALPHA_API_GATEWAY = 'https://alpha-api.vegapay.tech';
const SANDBOX_API_GATEWAY = 'https://sandbox-api.vegapay.tech';

const env = getEnv();

const getBaseURL = () => {
  switch (env) {
    case ENV.DEV:
      return ALPHA_API_GATEWAY;
    case ENV.SANDBOX:
      return SANDBOX_API_GATEWAY;
    default:
      return API_GATEWAY;
  }
};

export const API_BASE_URL = getBaseURL();

export const configureAxiosDefaults = () => {
  const BASE_URL = getBaseURL();
  axios.defaults.baseURL = BASE_URL;
};

//request interceptor
axios.interceptors.request.use(
  (config: any) => {
    // config.headers['Authorization'] = `${sessionStorage.getItem('token')}`;
    config.headers['Authorization'] = `${
      getEnv() === ENV.SANDBOX ? 'Bearer ' : ''
    }${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//response interceptor
// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.status == 401) {
//       Auth.currentSession();
//     }
//     return Promise.reject(error);
//   }
// );
