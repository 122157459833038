import { Box, CircularProgress, Stack } from '@mui/material';
import VegaDialogV4 from '../../common/v4/VegaDialogV4';
import { IAuditLogProps } from '../../customers/ListOfCustomers';
import VegaTableV4, { ITableDataProps } from '../../common/v4/VegaTableV4';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { DATE_FORMATE } from '../../../constants/url';
import { ComponentBody1Typo } from '../../common/Typography';
import { convertCamelCase } from '../../../utils/stringUtils';
import { useCustomerAuditQuery } from '../../../store/queries/customerQueries';

interface ICustomerViewAuditDialogProps {
  open: boolean;
  onClose: () => void;
  customerId?: string;
}

const CustomerViewAuditDialog = ({
  open,
  onClose,
  customerId,
}: ICustomerViewAuditDialogProps) => {
  const { data, isFetching } = useCustomerAuditQuery(customerId as string);

  const structuredData: ITableDataProps[] = useMemo(() => {
    return getStructuredData(data);
  }, [data]);

  console.log('data', data);

  return (
    <VegaDialogV4
      open={Boolean(open && customerId)}
      onClose={onClose}
      title="Audit Log Details"
      closeBtnRequired
    >
      <Box py={4} px={5}>
        {isFetching ? (
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress size={20} />
          </Stack>
        ) : Boolean(data?.length) ? (
          <VegaTableV4
            head={[
              'Event Name',
              'Action',
              'Entity',
              'Field Name',
              'Previous Value',
              'New Value',
              'Date',
            ]}
            data={structuredData}
            isLoading={isFetching}
          />
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <ComponentBody1Typo>
              This Customer do not have Audit Logs
            </ComponentBody1Typo>
          </Stack>
        )}
      </Box>
    </VegaDialogV4>
  );
};

function getStructuredData(
  records: IAuditLogProps[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: convertCamelCase(rec.eventName),
            type: 'string',
          },
          {
            value: convertCamelCase(rec.eventDescription),
            type: 'string',
          },
          {
            value: convertCamelCase(rec.entityType),
            type: 'string',
          },
          {
            value: rec.fieldName,
            type: 'string',
          },
          {
            value: convertCamelCase(rec.fromValue),
            type: 'string',
          },
          {
            value: convertCamelCase(rec.toValue),
            type: 'string',
            cellSx: {
              '&>p': {
                maxWidth: 250,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            },
          },
          {
            value: dayjs(rec.createdAt).format(DATE_FORMATE),
            type: 'string',
            cellSx: {
              minWidth: 120,
            },
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export default CustomerViewAuditDialog;
