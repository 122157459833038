import { DialogProps, Stack, SxProps } from '@mui/material';
import VegaDialogV4 from '../../common/v4/VegaDialogV4';
import { ComponentBody1Typo } from '../../common/Typography';
import { QUICK_ACCESS } from '../../../constants/style';
import { CSSProperties } from 'react';

interface IVegaDetailsDialogV4Props {
  data: IDialogDetailsData[];
  dialogProps: DialogProps;
  keyMinWidth?: CSSProperties['minWidth'];
  dialogMinWidth?: CSSProperties['minWidth'];
}

export interface IDialogDetailsData {
  key: string | number;
  keySx?: SxProps;
  value?: string | number;
  valueSx?: SxProps;
}

const VegaDetailsDialogV4 = ({
  data,
  dialogProps,
  keyMinWidth,
  dialogMinWidth,
}: IVegaDetailsDialogV4Props) => {
  return (
    <VegaDialogV4
      sx={{
        '.MuiPaper-root': {
          minWidth: dialogMinWidth || 516,
          maxHeight: '75vh',
        },
      }}
      closeBtnRequired
      {...dialogProps}
    >
      <Stack py={4} px={5} gap={2}>
        {data.map((item, idx) =>
          item.value !== undefined &&
          item.value !== null &&
          item.value !== '' ? (
            <Stack direction="row" gap={2} key={idx}>
              <ComponentBody1Typo
                sx={{
                  color: QUICK_ACCESS.text[100],
                  minWidth: keyMinWidth || 130,
                  ...item.keySx,
                }}
              >
                {item.key}
              </ComponentBody1Typo>
              <ComponentBody1Typo sx={item.valueSx}>
                {item.value}
              </ComponentBody1Typo>
            </Stack>
          ) : null
        )}
      </Stack>
    </VegaDialogV4>
  );
};

export default VegaDetailsDialogV4;
