import { useEffect, useState } from 'react';
import { VegaPage } from '../../../components/common';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { addFunds, fetchListFundLoad } from '../../Back-office/api/transaction';
import { IFilterCorporateCustomer } from '../../types/CorporateCustomer';
import { IReloadCardRequest } from '../components/CorporateCardReload/Card';
import FilterReloadCardOptions from '../components/CorporateCardReload/FilterReloadCardOptions';
import ListOfReloadCardDraftRequests from '../components/CorporateCardReload/ListOfReloadCardDraftRequests';
import ReloadCards from '../components/CorporateCardReload/ReloadCards';
import { useCorporate } from '../hooks/corporate';
import { STATUS } from '../../variable';
import MCCFilterSection from '../../Back-office/components/MCCUserManagement/MCCFilterSection';
import { IFilterProps } from './CorporateApplicationMangement';

const INITAL_FILTER_DATA = {
  value: '',
  startDate: '',
  endDate: '',
  programId: '',
  binNumber: '',
};

const CorporateCardTransaction = () => {
  const { setSnackbar } = useSnackbar();
  const { mccPlatformId: corporateId } = useClientAuth();
  const corporateQuery = useCorporate(corporateId);
  const corporate = corporateQuery.data;
  const [reloadCardDraftsList, setReloadCardDraftsList] = useState([]);

  // const [filterData, setFilterData] =
  //   useState<IFilterCorporateCustomer>(INITAL_FILTER_DATA);

  const [filterData, setFilterData] = useState<IFilterProps>({
    mobile: '',
    status: 'ALL',
  });
  const handleChange = (key: keyof IFilterCorporateCustomer, value: string) => {
    setFilterData((pre) => ({
      ...pre,
      [key]: value,
    }));
  };
  const handleFilter = (name: string, value: any) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSearch = () => {
    //
  };

  const getFundList = async () => {
    corporate?.entityId &&
      fetchListFundLoad({
        branchId: corporate.entityId,
        corporateId: corporateId,
      })
        .then((res) => {
          setReloadCardDraftsList(
            res.data.filter(
              (req: any) =>
                req.fundLoadOrderStatus === 'DRAFT' ||
                req.fundLoadOrderStatus === 'UNDER_REVIEW'
            )
          );
        })
        .catch((err) => {
          console.log('🚀 ~ file: CorporateCardTransaction.tsx:62 ~ err:', err);
          //
        });
  };

  const handleReloadAccount = (data: IReloadCardRequest) => {
    addFunds(data)
      .then((res) => {
        setSnackbar('Reload funds request raised!');
        // setFilterData({});
        getFundList();
      })
      .catch((err) => {
        setSnackbar(
          getErrorMessageFromErrorObj(err, 'Failed to add Funds'),
          'error'
        );
      });
  };

  useEffect(() => {
    getFundList();
  }, [corporate?.entityId]);

  return (
    <VegaPage>
      {/* <FilterReloadCardOptions
        filterData={filterData}
        handleChange={handleChange}
        handleSearch={handleSearch}
      /> */}
      <MCCFilterSection
        isStatusFilter
        statusOption={STATUS}
        state={filterData}
        handleChange={handleFilter}
        // onSearch={onSearch}
      />

      <ReloadCards
        filterData={filterData}
        handleChange={handleChange}
        handleReloadAccount={handleReloadAccount}
      />

      <ListOfReloadCardDraftRequests
        reloadCardDraftsList={reloadCardDraftsList}
        getFundList={getFundList}
      />
    </VegaPage>
  );
};

export default CorporateCardTransaction;
