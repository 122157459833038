import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  addOrUpdatePolicy,
  disablePolicy,
  fetchPolicy,
  getDefaultPolicy,
} from '../api/policy';
import { VegaAccordian, VegaCard } from '../components/common';
import VegaContainedButton from '../components/common/VegaContainedButton';
import { BulkUploadIcon } from '../components/icon/Icons';
import { GREY, GREEN } from '../constants/style';
import {
  PasswordPolicy,
  PasswordPolicyValueType,
  PolicyStatus,
  PolicyType,
} from '../types/policy';
import { useSnackbar } from '../providers/SnackbarProvider';
import { useClientAuth } from '../providers/ClientProvider';

const PASSWORD_OPTIONS: PasswordPolicyValueType[] = [
  PasswordPolicyValueType.MOBILE_NUMBER,
  PasswordPolicyValueType.PAN_NUMBER,
  PasswordPolicyValueType.DOB,
];

export interface StatementPasswordSetupProps {
  programId?: string;
  onSampleDownloadClick: () => void;
}

const StatementPasswordSetup = (props: StatementPasswordSetupProps) => {
  const { clientId } = useClientAuth();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<
    PasswordPolicyValueType[]
  >([]);
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy | null>(
    null
  );
  const [updatingPolicy, setUpdatingPolicy] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();

  function selectOption(option: PasswordPolicyValueType) {
    setSelectedOptions((prevOptions) => {
      const newOptions = [option, ...prevOptions];
      if (newOptions.length > 2) {
        newOptions.splice(-1, 1);
      }
      return newOptions;
    });
  }

  function removeOption(option: PasswordPolicyValueType) {
    const filteredOptions = selectedOptions.filter((item) => item != option);
    setSelectedOptions(filteredOptions);
  }

  const isOptionSelected = (option: PasswordPolicyValueType) =>
    selectedOptions.includes(option);

  const getPasswordFormat = () => {
    var format = '';
    selectedOptions.forEach((item) => {
      switch (item) {
        case PasswordPolicyValueType.DOB:
          format += 'DDMM';
          break;
        case PasswordPolicyValueType.MOBILE_NUMBER:
          format += 'MOBN';
          break;
        case PasswordPolicyValueType.PAN_NUMBER:
          format += 'PANN';
          break;
      }
    });
    return format;
  };

  const getPasswordHelperText = () => {
    var format = '';
    selectedOptions.forEach((item) => {
      switch (item) {
        case PasswordPolicyValueType.DOB:
          format += ' - DDMM(Date Month)';
          break;
        case PasswordPolicyValueType.MOBILE_NUMBER:
          format += ' - MOBN (last 4 digits of Mobile number)';
          break;
        case PasswordPolicyValueType.PAN_NUMBER:
          format += ' - PANN (last 4 digits of Pan number)';
          break;
      }
    });
    return format;
  };

  function didClickSave() {
    if (!isEnabled) {
      disablePasswordPolicy();
      return;
    }

    if (selectedOptions.length <= 0) {
      return;
    }

    updateOrAddPasswordPolicy();
  }

  function updateOrAddPasswordPolicy() {
    const passwordPolicy: PasswordPolicy = {
      selected_fields: selectedOptions,
      policy_name: 'PASSWORD POLICY',
      policy_type: PolicyType.PASSWORD_POLICY,
      policy_description: 'Password for bill PDF while sending email',
      is_override: false,
      start_date: '2022-10-01',
      end_date: '2026-10-01',
      status: PolicyStatus.ACTIVE,
      entity_policy_mapping_status: PolicyStatus.ENABLED,
      client_id: clientId,
    };
    const programId = props?.programId;
    if (!programId) {
      return;
    }
    setUpdatingPolicy(true);
    addOrUpdatePolicy(programId, passwordPolicy, PolicyStatus.ENABLED)
      .then((response) => {
        setSnackbar('Policy Saved', 'success');
        setPasswordPolicy(response.data.policy);
      })
      .catch((error) => {
        setSnackbar('Failed to save policy', 'error');
        console.error(error);
      })
      .finally(() => setUpdatingPolicy(false));
  }

  function fetchPasswordPolicy() {
    const programId = props?.programId;

    if (!programId) {
      return;
    }
    fetchPolicy(programId, PolicyType.PASSWORD_POLICY)
      .then((response) => {
        const existingPolicy = response.data;
        setPasswordPolicy(existingPolicy as PasswordPolicy);
      })
      .catch((error) => {
        setPasswordPolicy(null);
      });
  }

  function disablePasswordPolicy() {
    const policyId = passwordPolicy?.policy_id;
    if (!policyId) return;
    setUpdatingPolicy(true);
    disablePolicy({ policyId: policyId })
      .then((res) => {
        setSnackbar('Policy disabled', 'success');
        setPasswordPolicy(res.data);
      })
      .catch((err) => {
        setSnackbar('Failed to update policy', 'error');
        console.error(err);
      })
      .finally(() => setUpdatingPolicy(false));
  }

  useEffect(() => {
    fetchPasswordPolicy();
  }, [props.programId]);

  useEffect(() => {
    const isPolicyEnabled = passwordPolicy?.status == 'ACTIVE';
    const isInDraft = passwordPolicy?.status == 'DRAFT';
    setSelectedOptions(passwordPolicy?.selected_fields ?? []);
    setIsEnabled(isPolicyEnabled || isInDraft);
  }, [passwordPolicy]);

  return (
    <VegaCard>
      <VegaAccordian
        header={'Enable Password'}
        showSwitch
        expanded={isEnabled}
        onChange={() => setIsEnabled(!isEnabled)}
        content={
          <VegaCard>
            <Typography sx={{ mr: 1 }}>Password length of 8 chars</Typography>
            {PASSWORD_OPTIONS.map((item) => (
              <FormControlLabel
                key={item}
                checked={isOptionSelected(item)}
                control={
                  <Checkbox
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        selectOption(item);
                      } else {
                        removeOption(item);
                      }
                    }}
                    sx={{
                      color: GREY.dark,
                      '&.Mui-checked': {
                        color: GREEN.dark,
                      },
                    }}
                  />
                }
                label={_.startCase(_.toLower(item))}
                sx={{ fontSize: '14px' }}
              />
            ))}
            <Typography sx={{ mr: 1, mt: 5 }}>
              Password Format:{' '}
              <span style={{ fontWeight: 'bold' }}>{getPasswordFormat()}</span>
            </Typography>
            <Typography sx={{ color: 'gray' }}>
              {getPasswordHelperText()}
            </Typography>
          </VegaCard>
        }
      ></VegaAccordian>

      <Box
        display={'flex'}
        sx={{
          cursor: 'pointer',
          marginTop: '20px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={props.onSampleDownloadClick}
      >
        <Typography sx={{ color: GREEN.dark, mr: 1 }}>
          Download Sample Bill
        </Typography>
        <BulkUploadIcon />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <VegaContainedButton
          text="Save"
          onClick={didClickSave}
          isPrimary
          loading={updatingPolicy}
        />
      </Box>
    </VegaCard>
  );
};

export default StatementPasswordSetup;
