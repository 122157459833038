import { useQuery } from 'react-query';
import { QUERY_NAMES } from '../../enums/commons';
import { accountListForCustomers, listForCustomers } from '../../api/customer';
import { fetchAuditrByCustomerId } from '../../api/audit';
import useDebounce from '../../hooks/useDebounce';
import { CommonQuery } from '../../types/commonType';
import { SearchOptions } from '../../components/Simulator/simulatorUtils';
import { AccountStatus } from '../../enums/account.enum';
export type KeyValuePairs = {
  [key: string]: string | undefined;
};
export const useCustomerListQuery = ({
  clientId,
  programId,
  allAccounts,
  searchBy,
  pageSize,
  pageNumber,
  search,
}: {
  programId: string;
  clientId: string;
  allAccounts?: boolean;
  searchBy?: SearchOptions;
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(
    search || undefined,
    500
  );

  const res = useQuery({
    queryKey: [
      QUERY_NAMES.CUSTOMER_LIST,
      programId,
      clientId,
      pageNumber,
      pageSize,
      allAccounts,
      debouncedSearch && searchBy ? searchBy : undefined,
      debouncedSearch && searchBy ? debouncedSearch : undefined,
    ],
    queryFn: () =>
      listForCustomers({
        programId,
        clientId,
        page: pageNumber,
        pageSize,
        allAccounts,
        q: debouncedSearch,
        searchBy: searchBy,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: Boolean(programId && clientId),
  });

  return res;
};

export const useCustomerAuditQuery = (customerId: string) => {
  const res = useQuery({
    queryKey: [QUERY_NAMES.CUSTOMER_AUDIT, customerId],
    queryFn: () => fetchAuditrByCustomerId(customerId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: Boolean(customerId),
  });

  return res;
};

export const useAccountListQuery = ({
  clientId,
  programId,
  pageNumber,
  pageSize,
  searchBy,
  search,
  statusList,
}: {
  programId: string;
  clientId: string;
  searchBy?: SearchOptions;
  statusList?: AccountStatus[];
} & CommonQuery) => {
  const debouncedSearch = useDebounce<string | undefined>(
    search || undefined,
    500
  );

  const res = useQuery({
    queryKey: [
      QUERY_NAMES.CUSTOMER_ACCOUNT_LIST,
      programId,
      clientId,
      debouncedSearch && searchBy ? searchBy : undefined,
      debouncedSearch && searchBy ? debouncedSearch : undefined,
      statusList,
      { pageNumber, pageSize },
    ],
    queryFn: () =>
      accountListForCustomers({
        programId,
        clientId,
        page: pageNumber,
        pageSize,
        q: debouncedSearch,
        searchBy,
        statusList,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: Boolean(programId && clientId),
  });

  return res;
};
