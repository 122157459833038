import axios, { AxiosResponse } from 'axios';
import { BASE_URL, ONBOARDING } from './endpoints';
import { IFinanceInvoiceData } from '../types/financeCenter';

const ONBOARDING_BASE_URL = BASE_URL + ONBOARDING.BASE;
const ONBOARDING_CLIENT_URL = ONBOARDING_BASE_URL + ONBOARDING.CLIENT;

export const fetchInvoice = (
  clientId: any,
  year?: number | string,
  month?: number
): Promise<AxiosResponse<IFinanceInvoiceData[]>> => {
  return axios.get(`${ONBOARDING_CLIENT_URL}/invoice/${clientId}`, {
    params: {
      year,
      month,
    },
  });
};
