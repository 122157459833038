import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  getCardDesignConfiguration,
  saveCardDesignConfiguration,
} from '../api/card';
import { VegaProgramIssuanceType } from '../types/program';
import { useProgramData } from './ProgramDataProvider';

export enum CardType {
  PHYSICAL = 'PHYSICAL_PERSONALISED',
  VIRTUAL = 'VIRTUAL',
}

const PRE_DEFINED_CARD_BACKGROUND_COLORS = [
  'rgba(97, 110, 127, 1)',
  'rgba(237, 28, 36, 1)',
  'rgba(30, 57, 141, 1)',
  'rgba(162, 14, 55, 1)',
  'rgba(251, 188, 9, 1)',
  'rgba(245, 130, 32, 1)',
  'rgba(53, 56, 147, 1)',
  'rgba(0, 91, 170, 1)',
];

const PRE_DEFINED_CARD_GRADIEND_COLORS = [
  'linear-gradient(270deg, #159957 -25.45%, #155799 57.24%)',
  'linear-gradient(270deg, #7B4397 0%, #DC2430 100%)',
  'linear-gradient(270deg, #4286F4 0%, #155799 100%)',
  'linear-gradient(90deg, #6441A5 0%, #2A0845 118.1%)',
  'linear-gradient(0deg, #2DD4BF 27.32%, rgba(45, 212, 191, 0.5) 100%)',
  'linear-gradient(0deg, #3B82F6 27.32%, rgba(59, 130, 246, 0.5) 100%)',
  'linear-gradient(0deg, #001F6F 27.32%, #0E2C97 100%)',
];

const TEXT_COLORS = [
  'rgba(255, 255, 255, 1)',
  'rgba(24, 31, 40, 1)',
  'rgba(32, 41, 52, 1)',
  'rgba(41, 53, 67, 1)',
  'rgba(53, 67, 86, 1)',
  'rgba(58, 74, 95, 1)',
  'rgba(97, 110, 127, 1)',
];

enum Property {
  BACKGROUND = 'background',
  TEXT_COLOR = 'textColor',
  BANK_LOGO = 'bankLogo',
  COBRAND_LOGO = 'cobrandLogo',
  NETWORK_LOGO = 'networkLogo',
  IS_NFC_ENABLED = 'isNfcEnabled',
  NFC_LOGO = 'nfcLogo',
  SIGNATURE_PANEL_TEXT = 'signaturePanelText',
  MAGNETIC_PANEL_TEXT = 'aboveMagneticPanelText',
}

export interface CardConfigProperties {
  programId: string;
  background: string;
  textColor: string;
  bankLogo: string;
  cobrandLogo: string;
  networkLogo: string;
  isNfcEnabled: boolean;
  nfcLogo: string;
  signaturePanelText: string;
  aboveMagneticPanelText: string;
  cardType: CardType;
}
export interface CardConfigContextType {
  availableCardColors: string[];
  availableCardGradients: string[];
  availableTextColors: string[];
  canEnableSaveButton: boolean;
  fetchCardConfigs: () => void;
  onSave: (cardType: CardType) => void;
  onBackgroundChange: (value: string, cardType: CardType) => void;
  onTextColorChange: (value: string, cardType: CardType) => void;
  onBankLogoChange: (value: string, cardType: CardType) => void;
  onCobrandLogoChange: (value: string, cardType: CardType) => void;
  onNetworkLogoChange: (value: string, cardType: CardType) => void;
  onNfcStatusChange: (value: boolean, cardType: CardType) => void;
  onNfcLogoChange: (value: string, cardType: CardType) => void;
  onTopDescriptionChange: (value: string, cardType: CardType) => void;
  onBottomDescriptionChange: (value: string, cardType: CardType) => void;
  physicalCardConfig: Partial<CardConfigProperties>;
  virtualCardConfig: Partial<CardConfigProperties>;
}

const CardConfigContext = createContext<CardConfigContextType>({
  availableCardColors: PRE_DEFINED_CARD_BACKGROUND_COLORS,
  availableCardGradients: PRE_DEFINED_CARD_GRADIEND_COLORS,
  availableTextColors: TEXT_COLORS,
  canEnableSaveButton: false,
  fetchCardConfigs: () => Promise.reject(),
  onBackgroundChange: () => {},
  onTextColorChange: () => {},
  onBankLogoChange: () => {},
  onCobrandLogoChange: () => {},
  onNetworkLogoChange: () => {},
  onNfcLogoChange: () => {},
  onTopDescriptionChange: () => {},
  onBottomDescriptionChange: () => {},
  onSave: () => {},
  physicalCardConfig: {},
  virtualCardConfig: {},
  onNfcStatusChange: () => {},
});

export const useCardConfig = () => {
  return useContext(CardConfigContext) as CardConfigContextType;
};

function CardConfigProvider(props: { children: ReactNode }) {
  const { selectedProgram } = useProgramData();
  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const [physicalCardConfig, setPhysicalCardConfig] = useState<
    Partial<CardConfigProperties>
  >({
    background: PRE_DEFINED_CARD_BACKGROUND_COLORS[0],
    textColor: TEXT_COLORS[0],
    cardType: CardType.PHYSICAL,
  });
  const [virtualCardConfig, setVirtualCardConfig] = useState<
    Partial<CardConfigProperties>
  >({
    background: PRE_DEFINED_CARD_BACKGROUND_COLORS[0],
    textColor: TEXT_COLORS[0],
    cardType: CardType.VIRTUAL,
  });

  const isProgramCobrand = () => {
    const isCobrand =
      selectedProgram?.issuanceType == VegaProgramIssuanceType.CO_BRAND;
    return isCobrand;
  };

  const getConfig = (cardType: CardType) =>
    cardType == CardType.PHYSICAL ? physicalCardConfig : virtualCardConfig;

  function checkIsInputValid(config: Partial<CardConfigProperties>) {
    const bankLogo = config.bankLogo;
    const cobrandLogo = config.cobrandLogo;
    const networkLogo = config.networkLogo;
    const nfcLogo = config.nfcLogo;
    const isNfcEnabled = config.isNfcEnabled;
    const bottomDescription = config.signaturePanelText;
    const topDescription = config.aboveMagneticPanelText;
    const isBankLogoSelected = !!bankLogo && bankLogo.length > 0;
    var isCoBrandLogoSelected = true;
    if (isProgramCobrand()) {
      isCoBrandLogoSelected = !!cobrandLogo && cobrandLogo.length > 0;
    }
    var isNfcLogoSelected = true;
    if (isNfcEnabled == true) {
      isNfcLogoSelected = !!nfcLogo && nfcLogo.length > 0;
    }
    let isNetworkLogoSelected = !!networkLogo && networkLogo.length > 0;

    var isBottomDescriptionValid = true;
    var isTopDescriptionValid = true;
    if (config.cardType == CardType.PHYSICAL) {
      isBottomDescriptionValid =
        !!bottomDescription && bottomDescription.length > 0;
      isTopDescriptionValid = !!topDescription && topDescription.length > 0;
    }

    let isInputValid =
      isBankLogoSelected &&
      isCoBrandLogoSelected &&
      isNetworkLogoSelected &&
      isNfcLogoSelected &&
      isBottomDescriptionValid &&
      isTopDescriptionValid;
    setIsInputValid(isInputValid);
  }

  function updateCardConfig(data: {
    property: Property;
    value?: string | boolean | null;
    cardType: CardType;
  }) {
    if (data.cardType == CardType.PHYSICAL) {
      setPhysicalCardConfig((prevState) => {
        const updatedState = {
          ...prevState,
          [data.property]: data.value,
        };
        checkIsInputValid(updatedState);
        return updatedState;
      });
      return;
    } else if (data.cardType == CardType.VIRTUAL) {
      setVirtualCardConfig((prevState) => {
        const updatedState = {
          ...prevState,
          [data.property]: data.value,
        };
        checkIsInputValid(updatedState);
        return updatedState;
      });
    }
  }

  function _onBackgroundChange(value: string, cardType: CardType) {
    if (value.length == 0) {
      value = PRE_DEFINED_CARD_BACKGROUND_COLORS[0];
    }
    updateCardConfig({
      property: Property.BACKGROUND,
      value: value,
      cardType: cardType,
    });
  }

  function _onTextColorChange(value: string, cardType: CardType) {
    if (value.length == 0) {
      value = TEXT_COLORS[0];
    }
    updateCardConfig({
      property: Property.TEXT_COLOR,
      value: value,
      cardType: cardType,
    });
  }

  function _onBankLogoChange(value: string, cardType: CardType) {
    updateCardConfig({
      property: Property.BANK_LOGO,
      value: value,
      cardType: cardType,
    });
  }

  function _onCobrandLogoChange(value: string, cardType: CardType) {
    updateCardConfig({
      property: Property.COBRAND_LOGO,
      value: value,
      cardType: cardType,
    });
  }

  function _onNetworkLogoChange(value: string, cardType: CardType) {
    updateCardConfig({
      property: Property.NETWORK_LOGO,
      value: value,
      cardType: cardType,
    });
  }

  function _onNfcLogoChange(value: string, cardType: CardType) {
    updateCardConfig({
      property: Property.NFC_LOGO,
      value: value,
      cardType: cardType,
    });
  }

  function _onNfcStatusChange(value: boolean, cardType: CardType) {
    updateCardConfig({
      property: Property.IS_NFC_ENABLED,
      value: value,
      cardType: cardType,
    });
    updateCardConfig({
      property: Property.NFC_LOGO,
      value: undefined,
      cardType: cardType,
    });
  }

  function _onTopDescriptionChange(value: string, cardType: CardType) {
    updateCardConfig({
      property: Property.MAGNETIC_PANEL_TEXT,
      value: value,
      cardType: cardType,
    });
  }

  function _onBottomDescriptionChange(value: string, cardType: CardType) {
    updateCardConfig({
      property: Property.SIGNATURE_PANEL_TEXT,
      value: value,
      cardType: cardType,
    });
  }

  async function _onSaveClick(cardType: CardType) {
    const programId = selectedProgram?.programId;
    if (!programId) {
      return;
    }
    const config = getConfig(cardType);
    config.programId = programId;
    config.cardType = cardType;

    // remove nfc logo if switch was turned off later
    if ((config.isNfcEnabled ?? false) == false) {
      config.nfcLogo = undefined;
    }

    const configResponse = await saveCardDesignConfiguration(config);
  }

  async function _fetchCardConfigs() {
    const programId = selectedProgram?.programId;
    if (!programId) {
      throw Error('Program id not found');
    }

    try {
      const physicalCardConfig = await getCardDesignConfiguration({
        cardType: CardType.PHYSICAL,
        programId: programId,
      });

      setPhysicalCardConfig(physicalCardConfig);
    } catch (error) {
      console.error(error);
    }

    try {
      const virtualCardConfig = await getCardDesignConfiguration({
        cardType: CardType.VIRTUAL,
        programId: programId,
      });

      setVirtualCardConfig(virtualCardConfig);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    _fetchCardConfigs();
  }, [selectedProgram]);

  return (
    <CardConfigContext.Provider
      value={{
        availableCardColors: PRE_DEFINED_CARD_BACKGROUND_COLORS,
        availableCardGradients: PRE_DEFINED_CARD_GRADIEND_COLORS,
        availableTextColors: TEXT_COLORS,
        fetchCardConfigs: _fetchCardConfigs,
        onBackgroundChange: _onBackgroundChange,
        onTextColorChange: _onTextColorChange,
        onBankLogoChange: _onBankLogoChange,
        onCobrandLogoChange: _onCobrandLogoChange,
        onNetworkLogoChange: _onNetworkLogoChange,
        onNfcLogoChange: _onNfcLogoChange,
        onNfcStatusChange: _onNfcStatusChange,
        onTopDescriptionChange: _onTopDescriptionChange,
        onBottomDescriptionChange: _onBottomDescriptionChange,
        onSave: _onSaveClick,
        canEnableSaveButton: isInputValid,
        virtualCardConfig: virtualCardConfig,
        physicalCardConfig: physicalCardConfig,
      }}
    >
      {props.children}
    </CardConfigContext.Provider>
  );
}

export default CardConfigProvider;
