import { Box } from '@mui/material';
import { useMemo } from 'react';
import VegaTableV4, { ITableDataProps } from '../common/v4/VegaTableV4';

const year = new Date().getFullYear();
const records = [
  {
    month: 'Jan',
    days: '04, 11, 18, 25',
  },
  {
    month: 'Jan',
    days: '04, 11, 18, 25',
  },
];

const InstitutionStandardSundayHolidays = () => {
  const structuredData: ITableDataProps[] = useMemo(() => {
    return (
      records.map((rec, idx) => {
        return {
          rowData: [
            {
              value: rec.month,
              type: 'string',
              cellSx: {
                width: '200px',
              },
            },
            {
              value: rec.days,
              type: 'string',
            },
          ],
        };
      }) || []
    );
  }, []);

  return (
    <Box mt={2}>
      <VegaTableV4 head={[`Month ${year}`, 'Days']} data={structuredData} />
    </Box>
  );
};

export default InstitutionStandardSundayHolidays;
