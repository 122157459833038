import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { VegaProgramWithMetaDataType } from '../../types/program';
import { VegaPage } from '../common';
import VegaBreadcrumbs from '../common/v2/VegaBreadcrumbs';
import VegaTextV2 from '../common/v2/VegaTextV2';
import { getProgramMITCLink } from '../../api/program';
import { useSnackbar } from '../../providers/SnackbarProvider';
import ProgramStatus from './ProgramStatus';
import {
  Analytics,
  CardDetailsAndDesign,
  RiskChecks,
  OnboardingJourney,
  Policies,
  ProgramDetails,
  ProgramDueDates,
} from './tabs';
import DataTile from './DataTile';
import { AnyPolicyType } from '../../types/policy';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { setPolicies } from '../../store/common/programManagementSlice';
import { AxiosError } from 'axios';
import { fetchAllEffectivePolicesForProgram } from '../../api/policy';
import VegaButtonV4 from '../common/v4/VegaButtonV4';
import { QUICK_ACCESS } from '../../constants/style';
import VegaTabV4 from '../common/v4/VegaTabV4';

type ProgramDetailPageProps = {
  handleOnBreadcrumbClick: () => void;
};

const programDetailPageTabs = [
  { value: 'ProgramDetails', label: 'Program Details' },
  { value: 'Policies', label: 'Policies' },
  { value: 'OnboardingJourney', label: 'Onboarding Journey' },
  { value: 'RiskChecks', label: 'Risk Checks' },
  { value: 'CardDetailsAndDesign', label: 'Card Details & Design' },
  { value: 'ProgramDueDates', label: 'Program Due Dates' },
  { value: 'Analytics', label: 'Analytics' },
];

const fetchPolicies = (programId: string) => {
  return new Promise((resolve, reject) => {
    fetchAllEffectivePolicesForProgram(programId)
      .then((res) => {
        const allPolicies = res?.data;
        if (!allPolicies) {
          reject(new AxiosError('Failed to fetch policies of program'));
        }
        resolve(allPolicies);
      })
      .catch(reject);
  });
};

function ProgramDetailPage({
  handleOnBreadcrumbClick,
}: ProgramDetailPageProps) {
  const { setSnackbar } = useSnackbar();
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState('ProgramDetails');

  const dispatch = useAppDispatch();
  const { policies, selectedProgram: program } = useAppSelector(
    (state) => state.programManagement
  );

  useEffect(() => {
    if (!policies.length && program) {
      fetchPolicies(program.programId)
        .then((res) => {
          dispatch(setPolicies(res as AnyPolicyType[]));
        })
        .catch((err) => {
          setSnackbar('Failed to fetch policies!', 'error');
        })
        .finally(() => {});
    }
  }, []);

  const downloadMitcForProgram = () => {
    if (!program) return;
    setDownloadButtonDisabled(true);
    getProgramMITCLink(program.programId)
      .then((response) => {
        const url = response.data.mitcUrl;
        if (!url) {
          setSnackbar('Failed to download MITC', 'error');
          return;
        }
        window.open(url, '__BLANK__');
      })
      .catch((error) => {
        setSnackbar('Failed to download MITC', 'error');
      })
      .finally(() => {
        setDownloadButtonDisabled(false);
      });
  };

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  if (!program) {
    return null;
  }

  return (
    <VegaPage
      sx={{
        height: '100%',
        gap: 2,
      }}
    >
      <Box
        sx={{
          px: 3.25,
          bgcolor: 'white',
          pt: 3,
          pb: 1.5,
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          borderBottom: '1px solid #EEE',
        }}
      >
        <VegaBreadcrumbs
          separator="›"
          sx={{
            '& .MuiBreadcrumbs-separator': {
              color: '#1047DC',
            },
          }}
        >
          <VegaTextV2
            onClick={handleOnBreadcrumbClick}
            color={'#777B86'}
            text="Program Management"
            fontSize={12}
            sx={{
              cursor: 'pointer',
            }}
          />

          <VegaTextV2 fontSize={12} color="#1047DC" text={program.name} />
        </VegaBreadcrumbs>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            gap: {
              xs: 2,
              sm: 1,
            },
            py: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              gap: {
                xs: 1,
                sm: 0,
              },
            }}
          >
            <DataTile
              title="Program name"
              value={program.name || '-'}
              hasSeparator
              containerSx={{
                width: '100%',
              }}
            />
            <DataTile
              title="Program ID"
              value={program.programId || '-'}
              hasSeparator
              containerSx={{
                width: '100%',
              }}
            />
            <DataTile
              title="Co-Brand Partner"
              value={program.coBrandPartnerName || '-'}
              hasSeparator
              containerSx={{
                width: '100%',
              }}
            />
            <DataTile title="Status">
              {program.status ? <ProgramStatus status={program.status} /> : '-'}
            </DataTile>
          </Box>
          <VegaButtonV4
            variant="text"
            title="Download Program Summary"
            disabled={downloadButtonDisabled}
            onClick={downloadMitcForProgram}
            sx={{
              textDecoration: 'underline',
              textDecorationColor: QUICK_ACCESS.primary.blue,
            }}
          >
            Download Program Summary
          </VegaButtonV4>
        </Box>
      </Box>
      <Box
        sx={{
          px: 3.25,
        }}
      >
        <VegaTabV4
          tabData={
            program.status === 'ACTIVE'
              ? programDetailPageTabs
              : programDetailPageTabs.filter((tab) => tab.value !== 'Analytics')
          }
          value={activeTab}
          onChange={onTabChange}
        />
      </Box>
      <Box
        sx={{
          bgcolor: 'white',
          mx: 3.25,
          display: 'flex',
          borderRadius: 3,
          mb: { xs: 2, sm: 8 },
          flex: 1,
          border: '1px solid #EEE',
        }}
      >
        <ActiveProgramTab activeTab={activeTab} program={program} />
      </Box>
    </VegaPage>
  );
}

function ActiveProgramTab({
  activeTab,
  program,
}: {
  activeTab: string;
  program: VegaProgramWithMetaDataType;
}) {
  switch (activeTab) {
    case 'ProgramDetails':
      return <ProgramDetails program={program} />;
    case 'Policies':
      return <Policies programId={program.programId} />;
    case 'OnboardingJourney':
      return <OnboardingJourney />;
    case 'RiskChecks':
      return <RiskChecks />;
    case 'CardDetailsAndDesign':
      return <CardDetailsAndDesign program={program} />;
    case 'ProgramDueDates':
      return <ProgramDueDates />;
    case 'Analytics':
      return <Analytics />;
    default:
      return null;
  }
}
export default ProgramDetailPage;
