import { useMemo } from 'react';

export default function useDateRangeStandardHook(
  startDate: string | undefined,
  endDate: string | undefined
) {
  const { startDate2Pass, endDate2Pass } = useMemo(() => {
    const sd = startDate ? new Date(startDate) : undefined;
    const ed = endDate ? new Date(endDate) : undefined;
    sd?.setHours(0, 0, 0, 0);
    ed?.setHours(23, 59, 59, 999);
    return {
      startDate2Pass: sd?.toISOString(),
      endDate2Pass: ed?.toISOString(),
    };
  }, [startDate, endDate]);

  return { startDate2Pass, endDate2Pass };
}
