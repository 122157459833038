import { Box, Divider, Stack, SxProps } from '@mui/material';
import {
  ComponentBody1Typo,
  ComponentBody2Typo,
  ListGroupHeadingTypo,
  PageHeadingTypo,
} from '../Typography';
import { Fragment, MouseEventHandler, PropsWithChildren } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { QUICK_ACCESS } from '../../../constants/style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VegaButtonV4, { IVegaButtonV4Props } from './VegaButtonV4';

interface IVegaHeaderV4Props extends PropsWithChildren {
  title?: string;
  breadcrumbs?: IBreadcrumbProps[];
  onBackArrowClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  actionBtnProps?: IVegaButtonV4Props;
  details?: IHeaderProps[];
  sx?: SxProps;
}

export interface IBreadcrumbProps {
  label: string;
  link?: MouseEventHandler<HTMLSpanElement>;
}

export interface IHeaderProps {
  key: string;
  value: string;
}

const VegaHeaderV4 = ({
  title,
  breadcrumbs,
  onBackArrowClick,
  actionBtnProps,
  details,
  sx,
  children,
}: IVegaHeaderV4Props) => {
  return (
    <Box
      py={1.5}
      px={3}
      sx={{
        borderBottom: `1px solid ${QUICK_ACCESS.border[50]}`,
        ...sx,
      }}
    >
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        height={45}
      >
        <Stack gap={1.5}>
          <Stack direction="row" gap={0.5} alignItems="center">
            {breadcrumbs?.map((bc, idx) => {
              const isLast = breadcrumbs.length - 1 === idx;
              return (
                <Fragment key={idx}>
                  <ComponentBody2Typo
                    onClick={bc.link}
                    sx={{
                      color: isLast ? QUICK_ACCESS.primary.blue : undefined,
                    }}
                    className={!isLast ? 'cursor-pointer' : ''}
                  >
                    {bc.label}
                  </ComponentBody2Typo>
                  {!isLast && (
                    <ChevronRightIcon
                      sx={{
                        fontSize: '15px',
                      }}
                    />
                  )}
                </Fragment>
              );
            })}
          </Stack>
          <Stack direction="row" gap={1.5} alignItems="center">
            {onBackArrowClick && (
              <ArrowBackIcon
                onClick={onBackArrowClick}
                fontSize="small"
                className="cursor-pointer"
              />
            )}
            <PageHeadingTypo>{title}</PageHeadingTypo>
          </Stack>
          {Boolean(details?.length) && (
            <Stack direction="row" gap={2} py={2} alignItems="center">
              {details?.map((detail, idx) => {
                const isLast = idx === details.length - 1;
                return (
                  <Fragment key={idx}>
                    <Stack gap={1}>
                      <ListGroupHeadingTypo
                        sx={{ color: QUICK_ACCESS.text[100] }}
                      >
                        {detail.key}
                      </ListGroupHeadingTypo>
                      <ComponentBody1Typo>{detail.value}</ComponentBody1Typo>
                    </Stack>
                    {!isLast && (
                      <Divider
                        orientation="vertical"
                        sx={{ color: '#E1E4EB', height: '15px' }}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Stack>
          )}
        </Stack>
        {actionBtnProps && (
          <Box>
            <VegaButtonV4 variant="text" size="medium" {...actionBtnProps} />
          </Box>
        )}
      </Stack>
      {children}
    </Box>
  );
};

export default VegaHeaderV4;
