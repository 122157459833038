import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import VegaTextfieldV4, { VegaTextfieldV4Props } from './VegaTextfieldV4';
import { DateTimePickerProps as MuiDateTimePickerProps } from '@mui/x-date-pickers-pro';
import CalendarIcon from '../../icon/CalendarIcon04';
import { GENERIC_BORDER } from '../../../constants/style';

export type VegaDateTimePickerV4Props = {
  textProps?: VegaTextfieldV4Props;
  dateProps: MuiDateTimePickerProps<unknown>;
};

const VegaDateTimePickerV4 = ({
  textProps,
  dateProps,
}: VegaDateTimePickerV4Props) => {
  const { value, onChange, ...restDateProps } = dateProps;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={value || null}
        inputFormat="DD-MM-YYYY hh:mm a"
        toolbarPlaceholder="test"
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        PaperProps={{
          sx: {
            boxShadow: 'none',
            border: GENERIC_BORDER,
          },
        }}
        onChange={(e) => onChange?.(e, null as any)}
        {...(restDateProps as any)}
        renderInput={(params) => {
          return (
            <VegaTextfieldV4
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder:
                  textProps?.placeholder ?? params.inputProps?.placeholder,
              }}
              {...textProps}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default VegaDateTimePickerV4;
