import { Stack } from '@mui/material';
import { TransactionDto } from '../../../../../../../types/transaction';
import { NEUTRALS, QUICK_ACCESS } from '../../../../../../../constants/style';
import {
  ActionLargeTypo,
  ComponentBody1Typo,
} from '../../../../../../common/Typography';
import { DATE_FORMATE_SHORT } from '../../../../../../../constants/url';
import {
  convert2IndianNumberSystem,
  pascalCaseFromCamelCase,
  standardizeStatusText,
} from '../../../../../../../utils/stringUtils';
import dayjs from 'dayjs';
import VegaBadgeStatusTagV4 from '../../../../../../common/v4/VegaBadgeStatusTagV4';
import { getTransactionStatusColor } from '../../../../supportUtils';
import { useMccInfoQuery } from '../../../../../../../store/queries/transactionQueries';

interface ITransactionAndMerchantDetailsProps {
  data: TransactionDto | undefined;
}

const TransactionAndMerchantDetails = ({
  data,
}: ITransactionAndMerchantDetailsProps) => {
  const { data: mccInfo, isFetching } = useMccInfoQuery({ mcc: data?.mcc });

  const transactionDetails = [
    { key: 'Amount', value: convert2IndianNumberSystem(data?.amount) },
    {
      key: 'Value date',
      value: dayjs(data?.transactionTime).format(DATE_FORMATE_SHORT),
    },
    {
      key: 'Book date',
      value: dayjs(data?.transactionTime).format(DATE_FORMATE_SHORT),
    },
  ];

  const merchantDetails = [
    { key: 'Name', value: data?.merchantName || '--' },
    {
      key: 'Category',
      value: data?.mcc || '--',
    },
    {
      key: 'Group',
      value: pascalCaseFromCamelCase(mccInfo?.category || '--'),
    },
  ];

  return (
    <Stack
      direction={'row'}
      gap={2}
      justifyContent="space-evenly"
      width={'100%'}
    >
      <Stack
        sx={{ backgroundColor: NEUTRALS.white[100], borderRadius: '8px' }}
        px={2.5}
        py={2}
        gap={2}
        flex={1}
      >
        <Stack direction="row" gap={5} alignItems="center">
          <ActionLargeTypo sx={{ color: QUICK_ACCESS.text[200] }} width={160}>
            Transaction Status
          </ActionLargeTypo>
          {data?.status && (
            <VegaBadgeStatusTagV4
              text={standardizeStatusText(data?.status || '')}
              color={getTransactionStatusColor(data?.status)}
              variant="text"
            />
          )}
        </Stack>
        {transactionDetails.map((item) => (
          <Stack direction="row" gap={5} alignItems="center" key={item.key}>
            <ComponentBody1Typo
              sx={{ color: QUICK_ACCESS.text[100] }}
              width={160}
            >
              {item.key}
            </ComponentBody1Typo>
            <ComponentBody1Typo>{item.value}</ComponentBody1Typo>
          </Stack>
        ))}
      </Stack>
      <Stack
        sx={{ backgroundColor: NEUTRALS.white[100], borderRadius: '8px' }}
        px={2.5}
        py={2}
        gap={2}
        flex={1}
      >
        <Stack direction="row" gap={5} alignItems="center">
          <ActionLargeTypo sx={{ color: QUICK_ACCESS.text[200] }} width={160}>
            Merchant Details
          </ActionLargeTypo>
        </Stack>
        {merchantDetails.map((item) => (
          <Stack direction="row" gap={5} alignItems="center" key={item.key}>
            <ComponentBody1Typo
              sx={{ color: QUICK_ACCESS.text[100] }}
              width={160}
            >
              {item.key}
            </ComponentBody1Typo>
            <ComponentBody1Typo>{item.value}</ComponentBody1Typo>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default TransactionAndMerchantDetails;
