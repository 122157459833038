import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DataTile from '../DataTile';
import Grid from '@mui/material/Grid';
import { useAppSelector } from '../../../store/hook';
import { AddOnCardConfiguration } from '../../../types/card';
import Stack from '@mui/material/Stack';
import { SectionHeadingTypo } from '../../common/Typography';

type Props = {};

type FieldsType = {
  label: string;
  value: string;
};

const applicationFields = [
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Relationship',
    value: 'relationShip',
  },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'Phone Number',
    value: 'phoneNumber',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Gender',
    value: 'gender',
  },
];

const kycDocFields = [
  { label: 'PAN Card', value: 'panNo' },
  {
    label: 'Aadhaar Number',
    value: 'aadhaarNo',
  },
];

const transactionTypes = [
  { label: 'E-Commerce', value: 'addOnCardEcommerceStatus' },
  { label: 'POS', value: 'addOnCardPosStatus' },
  { label: 'Cash Withdrawal', value: 'addOnCardCashWithdrawalStatus' },
];

const getAddonCardFieldData = (
  addOnCardConfig: AddOnCardConfiguration | null,
  fields: FieldsType[]
) => {
  if (!addOnCardConfig) return '-';
  return fields
    .filter(
      ({ value }) => !!addOnCardConfig[value as keyof AddOnCardConfiguration]
    )
    .map(({ label }) => label)
    .join(', ');
};

function AddOnCard({}: Props) {
  const { addonCardConfig } = useAppSelector(
    (state) => state.programManagement
  );

  const applicationDetailsFields = getAddonCardFieldData(
    addonCardConfig,
    applicationFields
  );
  const kycDocumentFields = getAddonCardFieldData(
    addonCardConfig,
    kycDocFields
  );
  const transactionTypesFields = getAddonCardFieldData(
    addonCardConfig,
    transactionTypes
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 3,
        pb: 2,
      }}
    >
      <Typography className="font-aspekta-600" fontSize={17}>
        Add on Card
      </Typography>
      <Stack direction="column" gap={2}>
        <SectionHeadingTypo>Add on Card Details</SectionHeadingTypo>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item xs={6} lg={3}>
            <DataTile
              title="Scheme Name"
              value={addonCardConfig?.addOnCardSchemeName ?? '-'}
            />
          </Grid>
          <Grid item xs={12} sm={8} lg={3}>
            <DataTile
              title="Application Form Details"
              value={applicationDetailsFields}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <DataTile title="KYC Document" value={kycDocumentFields} />
          </Grid>
          <Grid item xs={6} lg={3}>
            <DataTile
              title="MAX cards to be allotted"
              value={`${addonCardConfig?.addOnCardCount ?? '-'}`}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <DataTile title="transaction type" value={transactionTypesFields} />
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="column" gap={2}>
        <SectionHeadingTypo>Supplementary Card Details</SectionHeadingTypo>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item xs={6} lg={4}>
            <DataTile
              title="Scheme Name"
              value={addonCardConfig?.supplementaryCardSchemeName ?? '-'}
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <DataTile
              title="MAX cards to be allotted"
              value={`${addonCardConfig?.supplementaryCardCount ?? '-'}`}
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}

export default AddOnCard;
