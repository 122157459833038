import { Controller, UseFormReturn } from 'react-hook-form';
import VegaContainerV4 from '../../../common/v4/VegaContainerV4';
import { IAddCustomerFormProps } from './CreateCustomerFormPage';
import { Stack } from '@mui/material';
import { VALIDATE_REGEX } from '../../../../utils/validation.utils';
import VegaTextfieldV4 from '../../../common/v4/VegaTextfieldV4';
import { ListHead2Typo } from '../../../common/Typography';
import VegaSelectV4 from '../../../common/v4/VegaSelectV4';
import { COUNTRY_CODES } from '../../../../enums/commons';

interface IAddCustomerPersonalDetails2Props {
  customerFormHook: UseFormReturn<IAddCustomerFormProps, any>;
}

const AddCustomerPersonalDetails2 = ({
  customerFormHook,
}: IAddCustomerPersonalDetails2Props) => {
  const {
    control,
    formState: { errors },
  } = customerFormHook;

  return (
    <VegaContainerV4 title="Personal Details 2">
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        <Controller
          name="personal2.countryCode"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaSelectV4
              {...rest}
              error={Boolean(errors.personal2?.countryCode)}
              header="Country Code"
              labelText={errors.personal2?.countryCode?.message}
              value={rest.value || ([] as any)}
              options={Object.values(COUNTRY_CODES).map((el) => ({
                name: el,
                id: el,
              }))}
              wrapperProps={{
                sx: {
                  flex: 0,
                },
              }}
              required
              placeholder="Select Country Code"
            />
          )}
        />
        <Controller
          name="personal2.mob"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.MOBILE,
              message: 'Not a valid mobile number',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.personal2?.mob)}
              header="Mobile Number"
              labelText={errors.personal2?.mob?.message}
              required
              placeholder="Enter Mobile Number"
            />
          )}
        />
        <Controller
          name="personal2.email"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.EMAIL,
              message: 'Not a valid email ID',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.personal2?.email)}
              header="Email ID"
              labelText={errors.personal2?.email?.message}
              required
              placeholder="Enter Email ID"
            />
          )}
        />
      </Stack>
    </VegaContainerV4>
  );
};

export default AddCustomerPersonalDetails2;
