import { API_BASE_URL } from '../../../api/axiosConfig';

export const BASE_URL = API_BASE_URL;

export const GETWAY = '/forex';

export const BRANCH = {
  BASE: '/branch',
};
export const PROGRAM = {
  BASE: '/program',
};
export const PLANS = {
  BASE: '/program/policy',
};
export const ROLES = {
  BASE: '/roleManagement',
};
export const CUSTOMER = {
  BASE: '/customer',
};
