function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as any);
}

export const convertToDateTime = (date: string) => {
  const temp = new Date(date);
  if (!isValidDate(temp)) return date;

  return `${temp.getDate()}/${
    temp.getMonth() + 1
  }/${temp.getFullYear()} ${temp.getHours()}:${temp.getMinutes()}`;
};

export class DateUtility {
  static formatToNDaysAgo(dateString: string): string {
    const date = new Date(dateString);
    const diff = Date.now() - date.getTime();
    const daysAgo = Math.round(diff / 86400000);
    if (daysAgo <= 0) {
      return 'Today';
    }
    return `${daysAgo} days ago`;
  }

  static formatToDDMMMYYY(dateString: string): string {
    if (!dateString || dateString.length <= 0) return '';
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    const parts = formattedDate.split(' ');

    return `${parts[1].replace(',', '')} ${parts[0]} ${parts[2]}`;
  }
  static formatToDDMMYYHHMMA(dateString: string): string {
    const parsedDate = new Date(dateString);
    const formattedDate = parsedDate.toLocaleString('en-US', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return formattedDate;
  }
  static formatToMMYY(dateString: string): string {
    const parsedDate = new Date(dateString);
    const formattedDate = parsedDate.toLocaleString('en-US', {
      month: '2-digit',
      year: '2-digit',
    });
    return formattedDate;
  }
  static monthNameToNumber = (value: string): number => {
    switch (value.toUpperCase()) {
      case 'JANUARY':
        return 1;
      case 'FEBRUARY':
        return 2;
      case 'MARCH':
        return 3;
      case 'APRIL':
        return 4;
      case 'MAY':
        return 5;
      case 'JUNE':
        return 6;
      case 'JULY':
        return 7;
      case 'AUGUST':
        return 8;
      case 'SEPTEMBER':
        return 9;
      case 'OCTOBER':
        return 10;
      case 'NOVEMBER':
        return 11;
      case 'DECEMBER':
        return 12;
      default:
        return 0;
    }
  };

  static monthNumberToName = (value: number) => {
    switch (value) {
      case 1:
        return 'JANUARY';
      case 2:
        return 'FEBRUARY';
      case 3:
        return 'MARCH';
      case 4:
        return 'APRIL';
      case 5:
        return 'MAY';
      case 6:
        return 'JUNE';
      case 7:
        return 'JULY';
      case 8:
        return 'AUGUST';
      case 9:
        return 'SEPTEMBER';
      case 10:
        return 'OCTOBER';
      case 11:
        return 'NOVEMBER';
      case 12:
        return 'DECEMBER';
    }
    return '';
  };
}

export const DATE_TIME_FORMATS = {
  SHORT_DATE: 'DD/MM/YYYY',
  DATE_TIME: 'DD/MM/YYYY HH:mm',
};
