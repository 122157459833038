/* eslint-disable react/jsx-pascal-case */
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { NEW_PRIMARY } from '../../../../../../../constants/style';
import { useSupport } from '../../../../../../../providers/SupportProvider';
import Vega_Text from '../../../../../../common/v3/Vega_Text';
import { useCardListQuery } from '../../../../../../../store/queries/cardQueries';
import VegaTableV4 from '../../../../../../common/v4/VegaTableV4';
import { getCardListStructuredData } from '../../../../supportUtils';
import CardUpdateDrawer from './CardUpdateDrawer';
import VegaNoResults from '../../../../../../common/VegaNoResults';
import { CardDto } from '../../../../../../../types/card';

const ActiveCard = () => {
  const { accountData, setCardsList, cardsList } = useSupport();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedCard, setSelectedCard] = useState<CardDto | undefined>();
  const [drawerState, setDrawerState] = useState<'reissue' | 'update'>(
    'reissue'
  );

  const { data, isFetching } = useCardListQuery({
    accountId: accountData?.accountId || '',
    pageSize,
    pageNumber: page,
    enabled: Boolean(accountData?.accountId),
  });

  useEffect(() => {
    setCardsList({ data: data?.records || [], isLoading: isFetching });
  }, [data?.records, isFetching]);

  const onDrawerUpdate = (val: boolean) => () => {
    setOpenDrawer(val);
  };

  const onStatusChangeClick = (card: CardDto) => {
    setDrawerState('update');
    setSelectedCard(card);
    onDrawerUpdate(true)();
  };

  const onReissueClick = (card: CardDto) => {
    setDrawerState('reissue');
    setSelectedCard(card);
    onDrawerUpdate(true)();
  };

  const structuredData = getCardListStructuredData(
    cardsList.data,
    onStatusChangeClick,
    onReissueClick
  );

  useEffect(() => {
    if (!openDrawer) {
      setSelectedCard(undefined);
    }
  }, [openDrawer]);

  return (
    <>
      <Vega_Text
        text={'Cards'}
        fontWeight={600}
        color={NEW_PRIMARY.BLACK}
        fontSize={17}
      />
      <Box mt={2}>
        {!Boolean(cardsList.data.length) && !cardsList.isLoading ? (
          <VegaNoResults errorText="No Cards Found" />
        ) : (
          <VegaTableV4
            head={['Card Number', 'Kit', 'BIN', 'Network', 'Status', 'Action']}
            data={structuredData}
            pagination={{
              rowsPerPage: pageSize,
              setRowsPerPage: setPageSize,
              setPage,
              page,
              totalRecords: data?.totalItems || 0,
            }}
            isLoading={cardsList.isLoading}
          />
        )}
        <CardUpdateDrawer
          open={openDrawer && Boolean(selectedCard)}
          onClose={onDrawerUpdate(false)}
          drawerState={drawerState}
          selectedCard={selectedCard}
          header={
            drawerState === 'reissue' ? 'Reissue card' : 'Change Card Status'
          }
          secondaryBtn={{
            show: false,
          }}
        />
      </Box>
    </>
  );
};

export default ActiveCard;
