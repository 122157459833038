import { Stack } from '@mui/material';
import { Fragment, useState } from 'react';
import { ResourceType } from '../../hooks/useInstitutionalResources';
import { MccCode, Mcg } from '../../types/resources';
import { getLogoForNetwork } from '../icon/Icons';
import VegaDataGridV4 from '../common/v4/VegaDataGridV4';
import { GridColDef } from '@mui/x-data-grid';
import { convertCamelCase } from '../../utils/stringUtils';
import { useSearchContext } from './searchValueContext';
import { useResourcesQuery } from '../../store/queries/resourcesQueries';

function MccTable() {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const { searchValue, searchBy } = useSearchContext();
  const { isLoading, data: paginatedData } = useResourcesQuery<MccCode>({
    type: ResourceType.MCC,
    key: 'fetchMccCodes',
    page,
    pageSize,
    searchBy: searchBy as string,
    searchQuery: searchValue,
  });

  return (
    <Fragment>
      <VegaDataGridV4
        data={paginatedData?.records ?? []}
        columns={columnMCC}
        idColumn={'code'}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        page={page}
        pageSize={pageSize}
        rowCount={paginatedData?.totalItems}
        paginationMode="server"
        loading={isLoading}
      />
    </Fragment>
  );
}

export default MccTable;

const columnMCC: GridColDef<MccCode>[] = [
  {
    field: 'code',
    headerName: 'Code',
    flex: 0.5,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1.5,
  },
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,
    valueGetter: (params) => params.row.category as Mcg,
    valueFormatter: (props: any) => {
      const category = props.value as Mcg;
      return convertCamelCase(category.name);
    },
  },
  {
    field: 'paymentBrands',
    headerName: 'Payment Brands',
    flex: 0.5,
    renderCell: (props: any) => {
      const networks: string[] = props.row.paymentBrands;
      if (!networks || networks.length <= 0) {
        return;
      }
      return (
        <Stack direction={'row'} alignItems="center" spacing={2}>
          {networks.map((item) => (
            <div>{getLogoForNetwork(item)}</div>
          ))}
        </Stack>
      );
    },
  },
];
