/** @format */

export const ROUTE_CODE = {
  CCMS: '/ccms',
  MCC: '/mcc',
};

export const DASHBOARD_ROUTES = {
  SIMULATOR: 'simulator',
  MCC_SIMULATOR: 'simulator',
  CLIENT_DASHBOARD: 'client',
  PROGRAM_DASHBOARD: 'program',
  BACK_OFFICE: 'back_office',
  MCC_PROGRAM: 'hq',
  MCC_BACK_OFFICE: 'branch',
  MCC_CORPORATE: 'corporate',
};

export const BACK_OFFICE_ROUTES = {
  ACC_CLOSE_DETAILS: 'acc-close-details',
  COLLECTIONS: '/collections',
  OPERATIONS: '/operations',
  AGENT_VIEW: '/agent-view',
  SUPPORT: '/support',
  REPORTS: '/reports',
  ANALYTICS: '/analytics',
  APPLICATIONS: '/applications',
};

export const SCOPE_MODULE_ROUTE_MAP: Record<string, any> = {
  hq: 'FOREX_HQ',
  back_office: 'FOREX_BRANCH',
  corporate: 'FOREX_CORPORATE',
  simulator: 'FOREX_SIMULATOR',
  branch: 'FOREX_BRANCH',
  BRANCH: 'FOREX_BRANCH',
  HQ: 'FOREX_HQ',
  BACK_OFFICE: 'FOREX_BRANCH',
  CORPORATE: 'FOREX_CORPORATE',
  SIMULATOR: 'FOREX_SIMULATOR',
};

export enum RewardsRoute {
  CAMPAIGN_CONFIGURATION = 'campaign-configuration',
  REWARDS_HOME = 'rewards-loyalty',
  POINT_BASED = 'point-based',
  NON_POINT_BASED = 'non-point-base',
  BENEFIT_INVENTORY = 'benefit-inventory',
}
export enum SupportRoute {
  SUPPORT = 'support',
  ACCOUNT_DETIAILS = 'account-details',
}
export enum BinCharterRoute {
  BIN_CONFIGURATION = 'bin-configuration',
  BIN_DETAILS = 'bin-details',
}
export const NESTED_VIEW = 'nested_view';

export const ROUTES = {
  LOGIN: '/login',
  SET_NEW_PASSWORD: '/new-password',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  GETTING_STARTED: '/',
  PROGRAM: '/program',
  POLICY: '/policy',
  APPLICATION_MANAGE: '/application-manage',
  LENDER_MANAGE: '/lender-manage',
  CUSTOMERS: '/customers',
  ACCOUNTS: '/accounts',
  TRANSACTIONS: '/transactions',
  GENERAL_LEDGER: '/general-ledger',
  BILLS: '/bills',

  HOME: '/home',
  RESOURCES: '/resources',
  EVENT: '/event',
  USER_ACCESS: '/user-access',
  UNI_STANDARDS: '/universal-standards',
  PAYMENT_DUE: '/payment-due-dates',
  PROGRAM_MANAGE: '/program-manage',
  PARTNER_MANAGE: '/partner-manage',
  SERVICE_SETUP: '/service-setup',
  BIN_CHARTER: '/bin-charter',
  SETTING: '/setting',
  SUPPORT: '/support',

  ADD_ON: '/add-on',
  REWARDS: '/rewards',
  REWARDS_LOYALTY: '/rewards-loyalty',
  CARD_MANAGEMENT: '/card-management',
  CHARGE_BACK: '/carge-back',
  MANAGE_TEAMS: '/manage-teams',
  ROLES_AND_ACCESS: '/roles-and-access',
  FINANCE_CENTER: '/finance-center',
  CLIENT_MORE_OPTIONS: '/client-more-options',
  FRAUD_AND_RISK_MANAGEMENT: '/fraud-and-risk-management',
};

export const SUB_ROUTES = {
  FINANCE: {
    INVOICE_VEGAPAY: '/finance/invoice-vegapay',
    INVOICE_THIRD_PARTY: '/finance/invoice-third-party',
    INCOME_EXPENSE: '/finance/income-expense',
    PROFIT_LOSS: '/finance/profit-loss',
  },
  INSTITUTION_STANDARD: {
    HOLIDAYS: '/holidays',
  },
  TRANSACTIONS: {
    TRANSACTIONS: '/main',
    LEDGER: '/ledger',
  },
};

export const NON_ROUTES = {
  COMPANY_PROFILE: '_company_profile',
  MEDIA: '_media',
  USER_PROFILE: '_user_profile',
  TEAM: '_team',
  INVOICES: '_invoices',
};

export const MCC_ROUTES = {
  // MCC_BACK_OFFICE //
  APPLICATION_MANAGEMENT: '/application-management',
  CORPORATE_MANAGEMENT: '/corporate-management',
  CORPORATE_TRANSACTION: '/corporate-transaction',
  TEAM_MANAGEMENT: '/team-management',

  USER_MANAGEMENT: '/user-management',
  INVENTORY_MANAGEMENT: '/inventory-management',
  RELEASE_TRANSACTION: '/release-transaction',
  SERVICES: '/services',
  CARD_CHECKER: '/card-checker',
  CARD_MAKER: '/card-maker',
  CARD_TRANSACTION: '/card-transaction',
  ANALYTICS: '/analytics',
  REPORT: '/report',

  // MCC_PROGRAM ROUTES
  CREATE_PROGRAM: '/create-program',
  PARTNER_MANAGEMENT: '/partner-management',
  CREATE_PARTNER: '/create-partner',
  CREATE_BRANCH: '/create-branch',
  CREATE_ROLE: '/create-role',
  POLICIES: '/policies',
  PROGRAM_ALLOCATION: '/program-allocation',
  INSTITUTIONAL_RESOURCES: '/institutional-resources',
  CHECKER_TEAM_MANAGEMENT: '/team-management',
  TEAM_MANAGEMENT_CHECKER: '/team-management-checker',
  CORPORATE_TRANSACTION_CHECKER: '/corporate-transaction-checker',
  TEAM_MANAGEMENT_MAKER: '/team-management-maker',
  CHECKER_APPLICATION_MANAGEMENT: '/application-management',
  CHECKER_CARD_TRANSACTION: '/card-transaction',
  CHECKER_INVENTORY_MANAGEMENT: '/inventory-management',

  // CORPORATE ROUTES
  CORPORATE_CARD_ANALYTICS: '/card-analytics',
  CORPORATE_CARD_SALE: '/card-sale',
  CORPORATE_APPLICATION_MANAGEMENT: '/application-management',
  CORPORATE_CARD_TRANSACTION: '/card-transaction',
  CORPORATE_INVOICES: '/invoices',

  // SIMULATOR
  SIMULATOR_TRANSACTION: '/transaction',
};
