import dayjs from 'dayjs';
import {
  BillingTableData,
  ITransactionLedgerAnalytics,
  ITransactionSummary,
} from '../../../types/analytics';
import { ITableDataProps } from '../../common/v4/VegaTableV4';
import { DATE_FORMATE } from '../../../constants/url';

export function getTransactionSummaryData(
  records: ITransactionSummary[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: dayjs(rec.transaction_date).format(DATE_FORMATE),
            type: 'string',
            cellSx: {
              minWidth: 130,
            },
          },
          {
            value: rec.total_transaction_count,
            type: 'string',
          },
          {
            value: rec.CARD_CASH_WITHDRAW_TRANSACTION_AMOUNT,
            type: 'string',
          },
          {
            value: rec.ECOM_TRANSACTION_COUNT,
            type: 'string',
          },
          {
            value: rec.ECOM_TRANSACTION_AMOUNT,
            type: 'string',
          },
          {
            value: rec.POS_TRANSACTION_COUNT,
            type: 'string',
          },
          {
            value: rec.POS_TRANSACTION_AMOUNT,
            type: 'string',
          },
          {
            value: rec.CARD_CASH_WITHDRAW_TRANSACTION_COUNT,
            type: 'string',
          },
          {
            value: rec.CARD_CASH_WITHDRAW_TRANSACTION_AMOUNT,
            type: 'string',
          },
          {
            value: rec.NFC_TRANSACTION_COUNT,
            type: 'string',
          },
          {
            value: rec.NFC_TRANSACTION_AMOUNT,
            type: 'string',
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getBillingAndRepaymentSummaryData(
  records: BillingTableData[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      return {
        rowData: [
          {
            value: dayjs(rec.created_at).format(DATE_FORMATE),
            type: 'string',
            cellSx: {
              minWidth: 130,
            },
          },
          {
            value: rec.bills_genearted,
            type: 'string',
          },
          {
            value: rec.total_due_amount,
            type: 'string',
          },
          {
            value: rec.total_mad_amount,
            type: 'string',
          },
          {
            value: rec.unpaid_bills_count,
            type: 'string',
          },
          {
            value: rec.unpaid_bills_amount,
            type: 'string',
          },
          {
            value: rec.mad_pay_count,
            type: 'string',
          },
          {
            value: rec.mad_pay_amount,
            type: 'string',
          },
          {
            value: rec.tad_pay_count,
            type: 'string',
          },
          {
            value: rec.tad_pay_amount,
            type: 'string',
          },
        ],
        id: rec,
      };
    }) || []
  );
}

export function getTransactionLedgerData(
  records: ITransactionLedgerAnalytics[] | undefined
): ITableDataProps[] {
  return (
    records?.map((rec) => {
      const retailFeeBilled =
        Number(rec.retail_fee_billed_closing_balance) -
        Number(rec.retail_fee_billed_opening_balance);
      const retailFeeUnBilled =
        Number(rec.retail_fee_unbilled_closing_balance) -
        Number(rec.retail_fee_unbilled_opening_balance);
      const cashFeeBilled =
        Number(rec.cash_fee_billed_closing_balance) -
        Number(rec.cash_fee_billed_opening_balance);
      const cashFeeUnBilled =
        Number(rec.cash_fee_unbilled_closing_balance) -
        Number(rec.cash_fee_unbilled_opening_balance);
      const interestBilled =
        Number(rec.cash_interest_billed_closing_balance) -
        Number(rec.cash_interest_billed_opening_balance);
      const interestUnBilled =
        Number(rec.cash_interest_unbilled_closing_balance) -
        Number(rec.cash_interest_unbilled_opening_balance);
      const feeBilled =
        Number(rec.cash_fee_billed_closing_balance) -
        Number(rec.cash_fee_billed_opening_balance);
      const feeUnBilled =
        Number(rec.cash_fee_unbilled_closing_balance) -
        Number(rec.cash_fee_unbilled_opening_balance);

      return {
        rowData: [
          {
            value: dayjs(rec.start_period).format(DATE_FORMATE),
            type: 'string',
            cellSx: {
              minWidth: 130,
            },
          },
          {
            value: retailFeeBilled + cashFeeBilled,
            type: 'string',
          },
          {
            value: retailFeeBilled,
            type: 'string',
          },
          {
            value: retailFeeUnBilled,
            type: 'string',
          },
          {
            value: cashFeeBilled,
            type: 'string',
          },
          {
            value: cashFeeUnBilled,
            type: 'string',
          },
          {
            value: interestBilled,
            type: 'string',
          },
          {
            value: interestUnBilled,
            type: 'string',
          },
          {
            value: feeBilled,
            type: 'string',
          },
          {
            value: feeUnBilled,
            type: 'string',
          },
        ],
        id: rec,
      };
    }) || []
  );
}
