import { Box, Stack } from '@mui/material';
import { QUICK_ACCESS } from '../../../constants/style';
import VegaTextfieldV4 from '../../common/v4/VegaTextfieldV4';
import { ComponentBody1Typo } from '../../common/Typography';

const IncreaseCardRange = () => {
  const cardData = [
    {
      key: 'Total Card Range',
      value: 524869,
    },
    {
      key: 'Total Card Issued',
      value: 13,
    },
    {
      key: 'Available Card Range',
      value: 544869,
    },
  ];
  return (
    <>
      <Stack
        direction="column"
        gap={1.25}
        p={2}
        bgcolor={QUICK_ACCESS.background[100]}
        borderRadius={2}
      >
        {cardData.map((card) => (
          <Stack
            key={card.key}
            direction={'row'}
            gap={1}
            justifyContent="space-between"
          >
            <ComponentBody1Typo
              sx={{
                color: QUICK_ACCESS.text[100],
              }}
            >
              {card.key}
            </ComponentBody1Typo>
            <ComponentBody1Typo
              sx={{
                color: QUICK_ACCESS.text[200],
              }}
            >
              {card.value}
            </ComponentBody1Typo>
          </Stack>
        ))}
      </Stack>
      <Box mt={4}>
        <VegaTextfieldV4
          type="number"
          key={''}
          standardWidth={400}
          header="No. of cards you want to increase"
        />
      </Box>
    </>
  );
};

export default IncreaseCardRange;
