/** @format */

import { Amplify } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import { configureAxiosDefaults } from './api/axiosConfig';
import { getCognitoConfiguration } from './aws';
import RoutingComponent from './components/routing/RoutingComponent';
import AppBarProvider from './providers/AppBarProvider';
import ClientProvider from './providers/ClientProvider';
import CustomerDataProvider from './providers/CustomerDataProvider';
import ProgramDataProvider from './providers/ProgramDataProvider';
import { SearchHeaderProvider } from './providers/SearchHeader';
import SnackbarProvider from './providers/SnackbarProvider';
import { VegaFullScreenDialogProvider } from './providers/VegaFullScreenDialogProvider';
import { CookiesProvider } from 'react-cookie';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: false },
  },
});

function App() {
  // configure AXIOS base
  configureAxiosDefaults();

  const cognitoConfig = getCognitoConfiguration();
  Amplify.configure(cognitoConfig);

  return (
    <VegaFullScreenDialogProvider>
      <SnackbarProvider>
        <ClientProvider>
          <ProgramDataProvider>
            <CustomerDataProvider>
              <AppBarProvider>
                <SearchHeaderProvider>
                  <QueryClientProvider client={queryClient}>
                    <CookiesProvider>
                      <RoutingComponent />
                    </CookiesProvider>
                  </QueryClientProvider>
                </SearchHeaderProvider>
              </AppBarProvider>
            </CustomerDataProvider>
          </ProgramDataProvider>
        </ClientProvider>
      </SnackbarProvider>
    </VegaFullScreenDialogProvider>
  );
}

export default App;
