import { Box, Stack } from '@mui/material';
import DelinquncyHistoryContainer from './components/DelinquncyHistoryContainer';
import ListOfCurrentDelinquncy from './components/ListOfCurrentDelinquncy';
import ListOfDPDStatus from './components/ListOfDPDStatus';
import ListOfDelinquncyHistory from './components/ListOfDelinquncyHistory';
import { PageHeadingTypo } from '../../../../../common/Typography';

const Delinquency = () => {
  return (
    <Stack gap={3}>
      <PageHeadingTypo>Delinquency Data</PageHeadingTypo>
      <Stack gap={1} direction="row">
        <Box flex={1}>
          <ListOfCurrentDelinquncy />
        </Box>
        <Box flex={1}>
          <ListOfDPDStatus />
        </Box>
      </Stack>
      <DelinquncyHistoryContainer header="Delinquency History">
        <ListOfDelinquncyHistory />
      </DelinquncyHistoryContainer>
    </Stack>
  );
};

export default Delinquency;
