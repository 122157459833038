import { Box, BoxProps, StackProps } from '@mui/material';
import { NEUTRALS } from '../../../constants/style';

const VegaCardV4_2 = ({ children, sx, ...rest }: BoxProps) => {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        px: 2,
        py: 1.5,
        backgroundColor: NEUTRALS.white[100],
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default VegaCardV4_2;
