import { VegaCard } from '../../common';
import VegaHorizontalSelector from '../../common/VegaHorizontalSelector';
import Vega_HeaderWrapper from '../../common/v3/Vega_HeaderWrapper';

function ScheduleSelection({ data, setActiveState, active }: any) {
  return (
    <VegaCard>
      <Vega_HeaderWrapper
        text="Schedules"
        subText="All reports generated by schedules can be seen here"
      />

      <VegaHorizontalSelector
        data={data}
        selected={active}
        onChange={setActiveState}
      />
    </VegaCard>
  );
}

export default ScheduleSelection;
