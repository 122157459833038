import Stack from '@mui/material/Stack';
import { NEUTRALS, QUICK_ACCESS } from '../../constants/style';
import { ComponentBody2Typo, SectionHeading2Typo } from '../common/Typography';
import VegaUploadFileInputV4 from '../common/v4/VegaUploadFileInputV4';
import Typography from '@mui/material/Typography';
import { addTimestampIfAwsUrl } from '../../utils/file';
import { useState } from 'react';
import {
  FileExtension,
  FileValidator,
  FileValidatorBuilder,
} from '../../utils/FileValidator';
import VegaButtonV4 from '../common/v4/VegaButtonV4';

const LogoTypes = {
  Favicon: 'Favicon',
  LargeLogo: 'LargeLogo',
  SmallLogo: 'SmallLogo',
  IOSLogo: 'IOSLogo',
} as const;

export type UploadLogoType = (typeof LogoTypes)[keyof typeof LogoTypes];

type LogoTypeInfo = {
  width: number;
  height?: number;
  title: string;
  description: string;
  sizeLimit: number;
};

const mapLogoTypeToInfo: Record<UploadLogoType, LogoTypeInfo> = {
  Favicon: {
    width: 32,
    title: 'Favicon',
    description: 'Used in Browser Tab & Bookmark bar',
    sizeLimit: 1,
  },
  LargeLogo: {
    width: 250,
    height: 150,
    title: 'Large Logo',
    description: 'Used in Dashboard & Website',
    sizeLimit: 200,
  },
  SmallLogo: {
    width: 160,
    title: 'Small Logo',
    description: 'Used in Dashboard & Website',
    sizeLimit: 200,
  },
  IOSLogo: {
    width: 1024,
    title: 'iOS Logo',
    description: 'Used in iOS Mobile App',
    sizeLimit: 1024,
  },
};

const getRenderLogoDimensions = (logoType: UploadLogoType) => {
  switch (logoType) {
    case 'Favicon':
      return { width: 36, height: 36 };
    case 'LargeLogo':
      return { width: 184, height: 80 };
    case 'SmallLogo':
    case 'IOSLogo':
      return { width: 80, height: 80 };
  }
};

function RenderLogo({
  logoType,
  fileUrl,
}: {
  logoType: UploadLogoType;
  fileUrl?: string;
}) {
  const { width, height } = getRenderLogoDimensions(logoType);

  return fileUrl ? (
    <img
      width={width}
      height={height}
      src={addTimestampIfAwsUrl(fileUrl)}
      style={{ borderRadius: '6px' }}
      alt="Logo"
    />
  ) : (
    <Stack
      borderRadius={logoType === 'Favicon' ? 1.75 : 2}
      border={`1px solid ${NEUTRALS.grey[50]}`}
      bgcolor={QUICK_ACCESS.background[50]}
      alignItems="center"
      justifyContent="center"
      width={width}
      height={height}
    >
      <Typography
        className={
          logoType === 'Favicon' ? 'font-aspekta-600' : 'font-aspekta-400'
        }
        fontWeight={logoType === 'Favicon' ? 600 : 400}
        fontSize={
          logoType === 'Favicon' ? 8 : logoType === 'LargeLogo' ? 24 : 16
        }
        lineHeight="120%"
        color={QUICK_ACCESS.text[75]}
      >
        LOGO
      </Typography>
    </Stack>
  );
}

type UploadMediaLogoContainerProps = {
  logotype: UploadLogoType;
  fileUrl?: string;
  onUpload: (logoType: UploadLogoType, file: File) => void;
};

function UploadMediaLogoContainer({
  logotype,
  fileUrl,
  onUpload,
}: UploadMediaLogoContainerProps) {
  const [file, setFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [isOnEdit, setIsOnEdit] = useState(false);

  const {
    width,
    height = width,
    description,
    title,
    sizeLimit,
  } = mapLogoTypeToInfo[logotype];

  const resetUploadedFile = () => {
    setFile(undefined);
    setFilePreview(null);
  };

  const [fileValidator] = useState(() =>
    new FileValidatorBuilder()
      .withAllowedExtensions([FileExtension.PNG])
      .withMaxSizeInKB(sizeLimit)
      .withMaxNameLength(50)
      .withSetDimensions(width, height)
      .build()
  );

  const onSubmitFile = () => {
    if (file) {
      onUpload(logotype, file);
      resetUploadedFile();
      setIsOnEdit(false);
    } else {
      setErrorMessage('Something went wrong');
    }
  };

  const handleFileSelected = async (selectedFile: File | null) => {
    if (selectedFile) {
      const validationResult = await fileValidator.validateFile(selectedFile);
      if (validationResult.isValid) {
        setFile(selectedFile);
        setErrorMessage(null);
        const objectUrl = URL.createObjectURL(selectedFile);
        console.log(objectUrl);
        console.log(selectedFile);
        setFilePreview(objectUrl);

        // Create a new Image object
        const img = new Image();

        // Set CORS policy if necessary
        img.crossOrigin = 'anonymous';

        // Set the source of the image
        img.src = objectUrl;

        // Handle image loading
        img.onload = () => {
          // Get the dimensions
          const width = img.width;
          const height = img.height;

          // Do what you want with the dimensions
          console.log(`Width: ${width}, Height: ${height}`);
        };
        img.onerror = () => {
          console.log('Oops, image not found or failed to load.');
        };
      } else {
        setErrorMessage(
          validationResult?.errorMessage ?? 'Error validating file'
        );
      }
    } else {
      resetUploadedFile();
    }
  };

  const onEditCancel = () => {
    setIsOnEdit(false);
    resetUploadedFile();
  };

  const disabled = !file || !!errorMessage;
  return (
    <Stack
      gap={3}
      p={3}
      borderRadius={2}
      border={`1px solid ${NEUTRALS.grey[50]}`}
      bgcolor={QUICK_ACCESS.background[75]}
      maxWidth={254}
    >
      <Stack
        sx={{
          width: 206,
          height: 80,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <RenderLogo logoType={logotype} fileUrl={filePreview || fileUrl} />
      </Stack>
      <Stack gap={0.5}>
        <SectionHeading2Typo
          color={QUICK_ACCESS.text[200]}
        >{`${title} (${width}X${height} px)`}</SectionHeading2Typo>
        <ComponentBody2Typo color={QUICK_ACCESS.text[100]}>
          {description}
        </ComponentBody2Typo>
      </Stack>
      {!fileUrl || isOnEdit ? (
        <>
          <Stack gap={1}>
            <VegaUploadFileInputV4
              buttonText={`Upload ${title}`}
              currentFile={file}
              onFileSelected={handleFileSelected}
              labelText={errorMessage || `Upto ${sizeLimit}kb, PNG file only`}
              error={!!errorMessage}
            />
          </Stack>

          <Stack direction="row" gap={1.5}>
            {(isOnEdit || !!file) && (
              <VegaButtonV4
                value="Upload"
                variant="contained"
                disabled={disabled}
                onClick={onSubmitFile}
              />
            )}
            {isOnEdit && (
              <VegaButtonV4
                value="Cancel"
                variant="text"
                onClick={onEditCancel}
              />
            )}
          </Stack>
        </>
      ) : (
        <Stack>
          <VegaButtonV4
            variant="outlined"
            value="Edit Logo"
            onClick={() => setIsOnEdit(true)}
          />
        </Stack>
      )}
    </Stack>
  );
}

export default UploadMediaLogoContainer;
