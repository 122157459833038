import { Divider, Stack } from '@mui/material';
import TransactionSummaryAnalytics from './TransactionSummaryAnalytics';
import LedgerSummaryAnalytics from './LedgerSummaryAnalytics';

const TransactionsAnalytics = () => {
  return (
    <Stack gap={5} mb={10}>
      <TransactionSummaryAnalytics />
      <Divider sx={{ height: 1 }} />
      <LedgerSummaryAnalytics />
    </Stack>
  );
};

export default TransactionsAnalytics;
