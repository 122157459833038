import { Box, Divider, Stack } from '@mui/material';
import { LoanDto } from '../../../../../../types/transaction';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  ActionLargeTypo,
  ComponentBody1Typo,
  ListHead1Typo,
  PageHeadingTypo,
  SectionHeadingTypo,
} from '../../../../../common/Typography';
import VegaCardV4_2 from '../../../../../common/v4/VegaCardV4_2';
import {
  INDICATIVE,
  QUICK_ACCESS,
  VEGA_COMMON,
} from '../../../../../../constants/style';
import VegaBadgeStatusTagV4 from '../../../../../common/v4/VegaBadgeStatusTagV4';
import { convert2IndianNumberSystem } from '../../../../../../utils/stringUtils';
import dayjs from 'dayjs';
import { DATE_FORMATE_SHORT } from '../../../../../../constants/url';
import { getLoanStatusColor } from '../../../supportUtils';
import CheckIcon from '../../../../../icon/CheckIcon';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Clock1Icon from '../../../../../icon/Clock1Icon';
import ClockPlus from '../../../../../icon/ClockPlus';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useEMIListFromLoanIDQuery } from '../../../../../../store/queries/emiQueries';
import { EMIData, EMIStatus } from '../../../../../../types/emis';
import { useMemo } from 'react';
import {
  AccountDetailsStep,
  useSupport,
} from '../../../../../../providers/SupportProvider';
import { useBillFromLoanQuery } from '../../../../../../store/queries/billQueries';

interface ILoanDetailsProps {
  data: LoanDto;
  goBack: () => void;
}

const LoanDetails = ({ data, goBack }: ILoanDetailsProps) => {
  const { data: emiData, isFetching } = useEMIListFromLoanIDQuery({
    loanId: data.id,
  });

  const { setSelectedTab, setSelectedEMI } = useSupport();

  const go2EMIDetailsPage = (emi: EMIData) => {
    setSelectedEMI(emi);
    setSelectedTab(AccountDetailsStep.EMI);
  };

  const { data: billData } = useBillFromLoanQuery({ loanId: data.id });

  const EMISchedules = useMemo(() => {
    return emiData?.map((item) => ({
      item,
      icon: getIcon(item.status),
    }));
  }, [emiData, getIcon]);

  const loanStatusDetails = [
    {
      key: 'Due amount',
      value: convert2IndianNumberSystem(
        (data?.loan_amount || 0) - (data.paid_amount || 0)
      ),
    },
    {
      key: 'Start-End date',
      value: `${
        data?.first_emi_date
          ? dayjs(data?.first_emi_date).format(DATE_FORMATE_SHORT)
          : '--'
      } - ${
        data?.tentative_loan_closing_date
          ? dayjs(data?.tentative_loan_closing_date).format(DATE_FORMATE_SHORT)
          : '--'
      }`,
    },
    {
      key: 'Generation date',
      value: data?.loan_start_date
        ? dayjs(data?.loan_start_date).format(DATE_FORMATE_SHORT)
        : '--',
    },
  ];
  console.log(data);

  const otherLoanDetails = [
    {
      key: 'Loan principal',
      value: convert2IndianNumberSystem(data?.loan_amount) ?? '--',
    },
    {
      key: 'Interest charged',
      value: data?.interest_rate ? data?.interest_rate + '%' : '--',
    },
    {
      key: 'Loan interest',
      value: convert2IndianNumberSystem(data?.interest_amount),
    },
    {
      key: 'Outstanding principal',
      value: convert2IndianNumberSystem(data?.due_principal),
    },
    {
      key: 'Outstanding interest',
      value: '--',
    },
  ];

  const loanPaymentStatusDetails = [
    {
      key: 'Due date',
      value: '--',
    },
    {
      key: 'Repayment date',
      value: '--',
    },
  ];

  return (
    <Box mx={-2.5} my={-2} height={'100%'}>
      <Stack
        py={2}
        px={2.5}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Stack direction="row" gap={1.5} alignItems="flex-start">
          <ArrowBackIcon
            fontSize="small"
            onClick={goBack}
            className="cursor-pointer"
          />
          <Stack gap={0.5}>
            <Stack direction="row" gap={1} alignItems="center">
              <PageHeadingTypo>Loan ID - </PageHeadingTypo>
              <SectionHeadingTypo>{data?.id}</SectionHeadingTypo>
            </Stack>
            <ComponentBody1Typo>
              Bill ID - {billData?.id || '###'}{' '}
            </ComponentBody1Typo>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ height: `calc(100% - 40px)` }} px={3}>
        <Stack direction="row" gap={2.5} alignItems="flex-start">
          <Stack>
            <VegaCardV4_2 sx={{ minWidth: 315 }}>
              <Stack gap={1.5}>
                <Stack direction="row" gap={5} alignItems="center">
                  <ActionLargeTypo
                    sx={{ color: QUICK_ACCESS.text[200] }}
                    width={160}
                  >
                    Loan status
                  </ActionLargeTypo>
                  <VegaBadgeStatusTagV4
                    text={data.status}
                    color={getLoanStatusColor(data.status)}
                    variant="stroked"
                  />
                </Stack>
                {loanStatusDetails.map((item) => (
                  <Stack
                    direction="row"
                    gap={5}
                    alignItems="center"
                    key={item.key}
                  >
                    <ComponentBody1Typo
                      sx={{ color: QUICK_ACCESS.text[100] }}
                      width={160}
                    >
                      {item.key}
                    </ComponentBody1Typo>
                    <ComponentBody1Typo sx={{ wordWrap: 'break-word' }}>
                      {item.value}
                    </ComponentBody1Typo>
                  </Stack>
                ))}
              </Stack>
            </VegaCardV4_2>
            <Stack gap={1.5} p={2}>
              {otherLoanDetails.map((item) => (
                <Stack
                  direction="row"
                  gap={5}
                  alignItems="center"
                  key={item.key}
                >
                  <ComponentBody1Typo
                    sx={{ color: QUICK_ACCESS.text[100] }}
                    width={160}
                  >
                    {item.key}
                  </ComponentBody1Typo>
                  <ComponentBody1Typo sx={{ wordWrap: 'break-word' }}>
                    {item.value}
                  </ComponentBody1Typo>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Divider
            sx={{
              borderStyle: 'dashed',
              borderColor: VEGA_COMMON.text100,
            }}
            orientation="vertical"
          />
          <VegaCardV4_2 sx={{ minWidth: 315 }}>
            <Stack gap={1.5}>
              <Stack direction="row" gap={5} alignItems="center">
                <ActionLargeTypo
                  sx={{ color: QUICK_ACCESS.text[200] }}
                  width={160}
                >
                  Loan payment status
                </ActionLargeTypo>
                <VegaBadgeStatusTagV4
                  text={data.status}
                  color={getLoanStatusColor(data.status)}
                  variant="text"
                />
              </Stack>
              {loanPaymentStatusDetails.map((item) => (
                <Stack
                  direction="row"
                  gap={5}
                  alignItems="center"
                  key={item.key}
                >
                  <ComponentBody1Typo
                    sx={{ color: QUICK_ACCESS.text[100] }}
                    width={160}
                  >
                    {item.key}
                  </ComponentBody1Typo>
                  <ComponentBody1Typo sx={{ wordWrap: 'break-word' }}>
                    {item.value}
                  </ComponentBody1Typo>
                </Stack>
              ))}
            </Stack>
          </VegaCardV4_2>
          <Divider
            sx={{
              borderStyle: 'dashed',
              borderColor: VEGA_COMMON.text100,
            }}
            orientation="vertical"
          />
          <Stack flex={1} gap={1}>
            {EMISchedules?.map((item, idx) => (
              <VegaCardV4_2
                key={idx}
                onClick={() => go2EMIDetailsPage(item.item)}
                className="cursor-pointer"
              >
                <Stack
                  gap={1}
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Stack gap={1} direction="row" alignItems="center">
                    {item.icon}
                    <ListHead1Typo>EMI {idx + 1}</ListHead1Typo>
                    <ComponentBody1Typo sx={{ color: QUICK_ACCESS.text[75] }}>
                      {item.item.id}
                    </ComponentBody1Typo>
                  </Stack>
                  <ChevronRightIcon fontSize="small" />
                </Stack>
              </VegaCardV4_2>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

const getIcon = (status: EMIStatus) => {
  switch (status) {
    case EMIStatus.PAID:
    case EMIStatus.CLOSED:
      return <CheckIcon />;
    case EMIStatus.UNPAID:
      return (
        <CancelOutlinedIcon
          sx={{ color: INDICATIVE.red[500], height: '18px', width: '18px' }}
        />
      );
    case EMIStatus.UNBILLED:
      return <ClockPlus />;
    default:
      return <Clock1Icon />;
  }
};

export default LoanDetails;
