import * as React from 'react';
import { SVGProps } from 'react';

const Clock1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M10.1628 10.3111C10.5558 10.4421 10.9805 10.2297 11.1115 9.83678C11.2425 9.44382 11.0301 9.01908 10.6371 8.8881L10.1628 10.3111ZM7.99997 8.79961H7.24997C7.24997 9.12243 7.45655 9.40903 7.7628 9.51112L7.99997 8.79961ZM8.74997 5.45445C8.74997 5.04024 8.41419 4.70445 7.99997 4.70445C7.58576 4.70445 7.24997 5.04024 7.24997 5.45445H8.74997ZM10.6371 8.8881L8.23714 8.0881L7.7628 9.51112L10.1628 10.3111L10.6371 8.8881ZM8.74997 8.79961V5.45445H7.24997V8.79961H8.74997ZM13.65 7.99961C13.65 11.12 11.1204 13.6496 7.99997 13.6496V15.1496C11.9488 15.1496 15.15 11.9484 15.15 7.99961H13.65ZM7.99997 13.6496C4.87957 13.6496 2.34998 11.12 2.34998 7.99961H0.849976C0.849976 11.9484 4.05114 15.1496 7.99997 15.1496V13.6496ZM2.34998 7.99961C2.34998 4.8792 4.87957 2.34961 7.99997 2.34961V0.849609C4.05114 0.849609 0.849976 4.05077 0.849976 7.99961H2.34998ZM7.99997 2.34961C11.1204 2.34961 13.65 4.8792 13.65 7.99961H15.15C15.15 4.05077 11.9488 0.849609 7.99997 0.849609V2.34961Z"
      fill={props.fill || '#C9A907'}
    />
  </svg>
);
export default Clock1Icon;
