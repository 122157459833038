/** @format */

import { useEffect, useState } from 'react';
import { toLowerCase } from '../../constants/commonFunction';
import { usePolicy } from '../../providers/PolicyProvider';
import { DEFAULT_INTEREST_POLICY } from '../../types/defaultPolicy';
import { EntityAuthStatus, UserMakerCheckerRole } from '../../types/entityAuth';
import {
  InterestPolicyInterface,
  PolicyComponentType,
  PolicyType,
} from '../../types/policy';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import InterestPolicyCard from './InterestPolicyCard';
import PolicyCommentCard from './PolicyCommentCard';
import { PolicyFooterActions } from './PolicyFooterActions';
import { PolicyWrapper } from './PolicyWrapper';

export const DEFAULT_RATE_RULE = {
  interest_rate_value_type: 'EQUAL',
  default_rate: 5,
};
export const DEFAULT_SLAB_RULE = {
  from_amount: 0,
  to_amount: 100,
  rate: 5,
};
export const DEFAULT_STATUS_TO_RATE_RULE = {
  posting_category_to_interest_rate_value: {
    RETAIL_PRINCIPAL: {
      interest_rate_value_type: 'EQUAL',
      default_rate: 42.0,
    },

    CASH_PRINCIPAL: {
      interest_rate_value_type: 'EQUAL',
      default_rate: 42.0,
    },
    // same as retails
    EMI_PRINCIPAL: {
      interest_rate_value_type: 'EQUAL',
      default_rate: 42.0,
    },
  },
};

export const POSTING_CATEGORIES = ['RETAIL_PRINCIPAL', 'CASH_PRINCIPAL'];
export const INTEREST_STATUS_TYPES = ['REVOLVING', 'DELINQUENT'];

const InterestPolicy = ({
  initialValue: initialPolicyValue,
  onSubmit,
  isLoading,
  onReject,
  onApprove,
  onPastCommentsClick,
}: PolicyComponentType) => {
  const {
    checkPolicyEditable,
    checkPolicyStatusUpdatable,
    entityAuthStatuses,
    loggedInMakerCheckerUser,
    addCommentOnPolicy,
    updateCommentOnPolicy,
    areAllPoliciesApproved,
    getDefaultPolicy,
  } = usePolicy();
  const [policy, setPolicy] = useState<InterestPolicyInterface | null>(
    DEFAULT_INTEREST_POLICY('Default Interest Policy')
  );
  const [canEditPolicy, setCanEditPolicy] = useState<boolean>(false);
  const [canUpdatePolicyStatus, setCanUpdatePolicyStatus] =
    useState<boolean>(false);
  const [entityAuthStatus, setEntityStatus] = useState<EntityAuthStatus | null>(
    null
  );
  const handleChange = (key: string, value: any) => {
    if (!policy) return;
    setPolicy({ ...policy, [key]: value });
  };

  const handleDefaultRateRuleToggle = (posting: string) => {
    if (!policy) return;
    const rateRule =
      policy.default_interest_rate_rule.posting_category_to_interest_rate_value;
    if (!rateRule?.[posting]) {
      handleChange('default_interest_rate_rule', {
        posting_category_to_interest_rate_value: {
          ...rateRule,
          [posting]: DEFAULT_RATE_RULE,
        },
      });
    } else {
      const copy = { ...rateRule };
      delete copy[posting];
      handleChange('default_interest_rate_rule', {
        posting_category_to_interest_rate_value: { ...copy },
      });
    }
  };

  const handleInterestStatusRateRuleToggle = (
    status: string,
    posting: string
  ) => {
    if (!policy) return;
    const rateRule = policy.interest_status_to_interest_rate_rules || {};
    if (!rateRule?.[status]) {
      handleChange('interest_status_to_interest_rate_rules', {
        ...(rateRule || {}),
        [status]: {
          posting_category_to_interest_rate_value: {
            [posting]: { ...DEFAULT_RATE_RULE },
          },
        },
      });
    } else if (
      !!rateRule?.[status] &&
      !rateRule[status]?.posting_category_to_interest_rate_value?.[posting]
    ) {
      const statusRule =
        rateRule[status].posting_category_to_interest_rate_value;
      handleChange('interest_status_to_interest_rate_rules', {
        ...rateRule,
        [status]: {
          posting_category_to_interest_rate_value: {
            ...statusRule,
            [posting]: { ...DEFAULT_RATE_RULE },
          },
        },
      });
    } else {
      const copy = rateRule[status].posting_category_to_interest_rate_value;
      delete copy[posting];
      handleChange('interest_status_to_interest_rate_rules', {
        ...rateRule,
        [status]: { posting_category_to_interest_rate_value: { ...copy } },
      });
    }
  };

  const handleInterestStatusRuleChange = (status: string, value: any) => {
    if (!policy) return;
    const editedRule = {
      ...policy.interest_status_to_interest_rate_rules,
      [status]: { posting_category_to_interest_rate_value: value },
    };
    handleChange('interest_status_to_interest_rate_rules', editedRule);
  };

  const getDefaultRate = (value: any) => {
    if (value['EMI_PRINCIPAL']) {
      return {
        ...value,
        EMI_PRINCIPAL: value['RETAIL_PRINCIPAL'],
      };
    } else {
      return value;
    }
  };

  const getInterestRate = (value: any) => {
    const newDELINQUENT = getDefaultRate(
      value['DELINQUENT'].posting_category_to_interest_rate_value
    );
    const newREVOLVING = getDefaultRate(
      value['REVOLVING'].posting_category_to_interest_rate_value
    );
    return {
      DELINQUENT: { posting_category_to_interest_rate_value: newDELINQUENT },
      REVOLVING: { posting_category_to_interest_rate_value: newREVOLVING },
    };
  };

  const handleSaveClick = () => {
    if (!policy) return;
    const defaultData = getDefaultRate(
      policy.default_interest_rate_rule?.posting_category_to_interest_rate_value
    );

    const interestData = getInterestRate(
      policy.interest_status_to_interest_rate_rules
    );
    const normalisedData: InterestPolicyInterface = {
      ...policy,
      default_interest_rate_rule: {
        posting_category_to_interest_rate_value: defaultData,
      },
      interest_status_to_interest_rate_rules: interestData,
    };
    onSubmit(normalisedData);
  };

  const transformPostingForDefalutRateRule = (initialValue: any) => {
    if (!initialValue?.posting_category_to_interest_rate_value) {
      return { ...DEFAULT_STATUS_TO_RATE_RULE };
    } else {
      const rateRule = initialValue?.posting_category_to_interest_rate_value;

      initialValue = {
        posting_category_to_interest_rate_value: { ...rateRule },
      };
    }
    return initialValue;
  };

  const transformInterestStatusRateRule = (initialValue: any) => {
    if (!initialValue) {
      return null;
    }
    INTEREST_STATUS_TYPES.forEach((status) => {
      if (initialValue?.[status]) {
        initialValue[status] = transformPostingForDefalutRateRule(
          initialValue[status]
        );
      } else {
        initialValue[status] = { ...DEFAULT_STATUS_TO_RATE_RULE };
      }
    });

    return { ...initialValue };
  };

  async function getEntityStatusAndCheckIfCanEdit() {
    const policyId = policy?.policy_id;
    if (!policyId) {
      setCanEditPolicy(false);
      setCanUpdatePolicyStatus(false);
      setEntityStatus(null);
      return;
    }
    const response = await checkPolicyEditable(policy);
    const canUpdateStatus = await checkPolicyStatusUpdatable(policy);
    const entityAuthStatus = entityAuthStatuses.get(policyId);
    setCanUpdatePolicyStatus(canUpdateStatus);
    setCanEditPolicy(response);
    setEntityStatus(entityAuthStatus ?? null);
  }

  function didClickApprove() {
    if (policy) onApprove(policy);
  }

  function addCommentOnEntity(comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    addCommentOnPolicy({
      policyId: policyId,
      comment: comment,
    });
  }

  function updateEntityComment(id: string, comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    updateCommentOnPolicy({
      policyId: policyId,
      commentId: id,
      comment: comment,
    });
  }

  const canShowCommentsCard = () => {
    if (areAllPoliciesApproved) return false;
    const isMaker =
      loggedInMakerCheckerUser?.role == UserMakerCheckerRole.MAKER;
    const areCommentsPresent =
      (entityAuthStatus?.commentsList ?? []).length > 0;
    return isMaker && areCommentsPresent;
  };

  function fetchDefaultPolicy() {
    getDefaultPolicy(PolicyType.INTEREST)
      .then((defaultPolicy) => {
        if (defaultPolicy) {
          const interestPolicy = defaultPolicy as InterestPolicyInterface;
          setPolicy(interestPolicy);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (initialPolicyValue) {
      initialPolicyValue['default_interest_rate_rule'] =
        transformPostingForDefalutRateRule(
          initialPolicyValue?.default_interest_rate_rule
        );
      initialPolicyValue['interest_status_to_interest_rate_rules'] =
        transformInterestStatusRateRule(
          initialPolicyValue?.interest_status_to_interest_rate_rules
        );
      if (!initialPolicyValue.rounding_method) {
        initialPolicyValue['rounding_method'] = 'NEAR';
      }
      if (!initialPolicyValue.interest_liquidation) {
        initialPolicyValue['interest_liquidation'] =
          'PAYMENT_END_DAY_BALANCE_V1';
      }
      if (!initialPolicyValue.day_count_method) {
        initialPolicyValue['day_count_method'] = 'ACTUAL_BY_ACTUAL';
      }
      setPolicy(initialPolicyValue);
    } else {
      fetchDefaultPolicy();
    }
  }, [initialPolicyValue]);

  useEffect(() => {
    getEntityStatusAndCheckIfCanEdit();
  }, [policy, loggedInMakerCheckerUser]);

  return (
    <PolicyWrapper
      user={loggedInMakerCheckerUser}
      policyId={policy?.policy_id}
      policyView={
        <>
          <VegaConditionalWrapper
            condition={canShowCommentsCard()}
            ifTrue={
              <PolicyCommentCard
                noMargin
                onPastCommentClick={() => {
                  onPastCommentsClick(policy?.policy_id);
                }}
                comments={entityAuthStatus?.commentsList}
              />
            }
          />

          <InterestPolicyCard
            headerPrefix="Transactor"
            rateRule={
              policy?.default_interest_rate_rule
                ?.posting_category_to_interest_rate_value
            }
            onChange={(rateRule: any) =>
              handleChange('default_interest_rate_rule', {
                posting_category_to_interest_rate_value: rateRule,
              })
            }
            onToggle={handleDefaultRateRuleToggle}
            disabled={canEditPolicy == false}
          />
          {INTEREST_STATUS_TYPES.map((status) => {
            return (
              <InterestPolicyCard
                rateRule={
                  policy?.interest_status_to_interest_rate_rules?.[status]
                    ?.posting_category_to_interest_rate_value
                }
                onChange={(rule: any) =>
                  handleInterestStatusRuleChange(status, rule)
                }
                onToggle={(posting) =>
                  handleInterestStatusRateRuleToggle(status, posting)
                }
                headerPrefix={`${toLowerCase(status)}`}
                disabled={canEditPolicy == false}
              />
            );
          })}
          <PolicyFooterActions
            role={loggedInMakerCheckerUser?.role}
            policy={initialPolicyValue}
            onSaveClick={handleSaveClick}
            onApproveClick={didClickApprove}
            isLoading={isLoading ?? false}
            isSaveDisabled={canUpdatePolicyStatus == false}
            onCommentAddClick={addCommentOnEntity}
            policyEntityAuth={entityAuthStatus}
            onPastCommentsClick={onPastCommentsClick}
            onCommentEdit={updateEntityComment}
          />
        </>
      }
    />
  );
};

export default InterestPolicy;
