import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { CSSProperties, Fragment } from 'react';
import { LabelTypo, SectionHeadingTypo, TextProps } from '../Typography';
import {
  INDICATIVE,
  QUICK_ACCESS,
  VEGA_COMMON,
} from '../../../constants/style';

interface IVegaRadioV4Props {
  componentProps: {
    formControl?: FormControlProps;
    radioGroup?: RadioGroupProps;
    formLabel?: FormLabelProps;
  };
  formControlLabels?: {
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    props?: Partial<Omit<FormControlLabelProps, 'label' | 'value'>>;
  }[];
  formControlComponents?: React.ReactNode[];
  error?: string;
  standardWidth?: CSSProperties['width'];
  formLabelTypo?: (props: TextProps) => JSX.Element;
  formLabelTypoProps?: TextProps;
  label?: string;
}

const VegaRadioV4 = ({
  componentProps,
  formControlLabels, // you can have either formControlLabels or formControlComponents, but cant have both
  formControlComponents,
  error,
  standardWidth,
  formLabelTypo,
  formLabelTypoProps,
  label,
}: IVegaRadioV4Props) => {
  const { formControl, radioGroup, formLabel } = componentProps;
  const FORM_LABEl_TYPO = formLabelTypo || SectionHeadingTypo;
  return (
    <FormControl
      {...formControl}
      sx={{ width: standardWidth, ...formControl?.sx }}
    >
      {label && (
        <FormLabel {...formLabel} sx={{ color: 'black', ...formLabel?.sx }}>
          <FORM_LABEl_TYPO
            {...formLabelTypoProps}
            sx={{ color: QUICK_ACCESS.text[100], ...formLabelTypoProps?.sx }}
          >
            {label}
          </FORM_LABEl_TYPO>
        </FormLabel>
      )}
      <RadioGroup {...radioGroup}>
        {formControlLabels?.map((item) => {
          const { label, value, onChange, props } = item;
          const { control, ...rest } = props || {};
          return (
            <FormControlLabel
              key={label}
              control={
                control || (
                  <Radio
                    onChange={onChange}
                    sx={{
                      color: 'black',
                      '&.Mui-checked': {
                        color: QUICK_ACCESS.primary.blue,
                      },
                      '.MuiSvgIcon-root': {
                        width: 15,
                        height: 15,
                      },
                    }}
                  />
                )
              }
              {...rest}
              value={value}
              label={label}
            />
          );
        })}
        {formControlComponents?.map((item, idx) => (
          <Fragment key={idx}>{item}</Fragment>
        ))}
      </RadioGroup>
      {error && (
        <LabelTypo
          sx={{
            color: error ? INDICATIVE.red[500] : VEGA_COMMON.text100,
          }}
        >
          {error}
        </LabelTypo>
      )}
    </FormControl>
  );
};

export default VegaRadioV4;
