/** @format */

import capitalize from 'lodash/capitalize';
import { Fragment, useState } from 'react';
import { ResourceType } from '../../hooks/useInstitutionalResources';
import VegaDataGridV4 from '../common/v4/VegaDataGridV4';
import { PinCodeResource } from '../../types/resources';
import { useSearchContext } from './searchValueContext';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { useResourcesQuery } from '../../store/queries/resourcesQueries';

function PincodeTable() {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const { searchValue, searchBy } = useSearchContext();
  const { isLoading, data: paginatedData } = useResourcesQuery<PinCodeResource>(
    {
      type: ResourceType.PINCODE,
      key: 'fetchPincodes',
      page,
      pageSize,
      searchBy: searchBy as string,
      searchQuery: searchValue,
    }
  );

  return (
    <Fragment>
      <VegaDataGridV4
        data={paginatedData?.records ?? []}
        columns={columnPinCode}
        idColumn={'id'}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        page={page}
        pageSize={pageSize}
        rowCount={paginatedData?.totalItems}
        paginationMode="server"
        loading={isLoading}
      />
    </Fragment>
  );
}
export default PincodeTable;

const columnPinCode: GridColDef<PinCodeResource>[] = [
  {
    field: 'pincode',
    headerName: '  Pin Code',

    flex: 1,
  },
  {
    field: 'city',
    headerName: '  City',
    valueFormatter: (props: GridValueFormatterParams<string>) => {
      return capitalize(props.value);
    },
    flex: 1,
  },
  {
    field: 'state',
    headerName: '  State',
    valueFormatter: (props: GridValueFormatterParams<string>) => {
      return capitalize(props.value);
    },
    flex: 1,
  },
  {
    field: 'country',
    headerName: '  Country',
    valueFormatter: (props: GridValueFormatterParams<string>) => {
      return capitalize(props.value);
    },
    flex: 1,
  },
];
