import capitalize from 'lodash/capitalize';
import { CustomerAddress } from '../types/customers';

const MAX_FILENAME_LENGTH = 30;

export function truncateFilename(filename: string, maxLength?: number): string {
  const MAX_LENGTH = maxLength ?? MAX_FILENAME_LENGTH;

  if (filename.length <= MAX_LENGTH) {
    return filename;
  }

  const ellipses = '...';
  const separator = '_';

  const extension = filename.split('.').pop();
  const filenameWithoutExtension = extension
    ? filename.substring(0, filename.length - extension.length - 1)
    : filename;

  const separatorIndex = filenameWithoutExtension.indexOf(separator);
  const firstPart = filenameWithoutExtension.substring(0, separatorIndex);
  const lastPart = filenameWithoutExtension.substring(
    separatorIndex + 1,
    filenameWithoutExtension.length
  );

  const availableLength = MAX_LENGTH - ellipses.length;
  const firstPartLength = Math.floor(availableLength / 2);
  const lastPartLength = availableLength - firstPartLength;

  return (
    firstPart.substring(0, firstPartLength) +
    ellipses +
    lastPart.substring(lastPart.length - lastPartLength, lastPart.length) +
    '.' +
    extension
  );
}

export const convertCamelCase = (str: string) => {
  if (!str) return str;
  let words = [];
  let currentWord = '';

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === '_' || char === ' ') {
      words.push(capitalize(currentWord));
      currentWord = '';
    } else {
      if (currentWord === '') {
        currentWord = char.toUpperCase();
      } else {
        currentWord += char.toLowerCase();
      }
    }
  }

  // words.push(currentWord);
  words.push(capitalize(currentWord));

  return words.join(' ');
};

// Macthes
// google.com
// vegapay.tech
export function isValidUrl(url: string): boolean {
  const urlRegex =
    /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:\S+(?:\.[^.\s]+)+)(?:\:\d+)?(?:\/\S*)?|(?:\S+(?:\.[^.\s]+)+)$/i;

  return urlRegex.test(url);
}

export function getInitials(fullName: string): string {
  const names = fullName.trim().split(' ');

  if (names.length === 1) {
    // If there's only one name, return the first character
    return names[0].charAt(0).toUpperCase();
  } else {
    // If there are multiple names, concatenate the first character of each name
    const initials = names.map((name) => name.charAt(0).toUpperCase());
    return initials.join('');
  }
}

export const checkIfAlphaNumeric = (val: string) => {
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;
  if (!ALPHA_NUMERIC_DASH_REGEX.test(val)) {
    return false;
  }
  return true;
};

export const checkIfMatchesMaxLetters = (
  word: string,
  totalLettersAllowed: number
) => {
  return word.length <= totalLettersAllowed;
};

export const makeFirstLetterCapitalAndRestLowerCase = (val: string) => {
  if (!val || val.length === 0) return '';
  if (val.length === 1) return val.toUpperCase();
  return val[0].toUpperCase() + val.slice(1).toLowerCase();
};

export const capitalizeString = (str?: string) => {
  return (str || '').toUpperCase();
};

export const lowerCaseString = (str: string) => {
  return str.toLowerCase();
};

export const captitalizeAndCamelCase = (str: string) => {
  return str.split(' ').join('_').toUpperCase();
};

export const pascalCaseFromCamelCase = (str: string) => {
  return str
    .split('_')
    .map((word) => {
      if (word.length > 1) {
        const firstLetter = capitalizeString(word[0]);
        const rest = lowerCaseString(word.slice(1));
        return firstLetter + rest;
      } else {
        return word;
      }
    })
    .join(' ');
};

export function stringifyAddress(add?: CustomerAddress) {
  return add
    ? add.line1 +
        ', ' +
        (add.line2 ? add.line2 + ', ' : '') +
        add.city +
        ', ' +
        add.state +
        ', ' +
        add.country +
        ' - ' +
        add.pincode
    : undefined;
}

export function getDatesBetween(
  startDate: Date,
  endDate: Date,
  asc: boolean = true
) {
  const dateArray = [];
  let currentDate = new Date(asc ? startDate : endDate);

  while (asc ? currentDate <= endDate : currentDate >= startDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(
      asc ? currentDate.getDate() + 1 : currentDate.getDate() - 1
    );
  }

  return dateArray;
}

export function getMonthsBetween(
  startMonth: Date,
  endMonth: Date,
  asc: boolean = true
) {
  const dateArray = [];
  let currentMonth = new Date(asc ? startMonth : endMonth);

  while (asc ? currentMonth <= endMonth : currentMonth >= startMonth) {
    dateArray.push(new Date(currentMonth));
    currentMonth.setMonth(
      asc ? currentMonth.getMonth() + 1 : currentMonth.getMonth() - 1
    );
  }

  return dateArray;
}

export function numberFormat2precisionDecimal(
  val: string | number,
  decimalPrecision: number = 2
) {
  const value = Number(val);
  if (isNaN(value)) {
    return '--';
  } else {
    const valStr = val + '';
    const valueArray = valStr.split('.');
    if (valueArray.length === 1) return val;
    else if (valueArray.length === decimalPrecision) {
      const decimal = valueArray[1].slice(0, 2);
      if (Number(decimal) === 0) return Number(valueArray[0]);
      return valueArray[0] + decimal;
    } else {
      return '--';
    }
  }
}

export function convert2IndianNumberSystem(val: string | number | undefined) {
  if (val === undefined) {
    return '₹--';
  }
  const valNumbered = Number(val);
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  }).format(valNumbered);
}

export function standardizeStatusText(val?: string) {
  if (val === undefined || val === null) return '--';
  const temp = val.trim().split('_');
  if (temp.length >= 1) {
    switch (temp[0]) {
      case 'PERM':
        temp[0] = 'PERMANENTLY';
        break;
      case 'TEMP':
        temp[0] = 'TEMPORARILY';
        break;
      case 'SUCCESS':
        temp[0] = 'SUCCESSFUL';
        break;
      case 'INIT':
        temp[0] = 'INITIALIZED';
        break;
    }
  }
  return temp.join(' ');
}
