import { ISVGProps } from '../../mcc/types/Commons';

const LockIcon = (props: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M4.39995 5.86664V5.25712C4.39995 3.23118 6.00567 1.59998 7.99995 1.59998C9.99424 1.59998 11.6 3.23118 11.6 5.25712V5.86664M4.39995 5.86664C3.73995 5.86664 3.19995 6.41521 3.19995 7.08569V13.1809C3.19995 13.8514 3.73995 14.4 4.39995 14.4H11.6C12.26 14.4 12.8 13.8514 12.8 13.1809V7.08569C12.8 6.41521 12.26 5.86664 11.6 5.86664M4.39995 5.86664H11.6M7.99995 10.8V9.19997"
        stroke={props.stroke || '#1047DC'}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default LockIcon;
