import { useEffect, useMemo, useState } from 'react';
import { VegaCard, VegaPage } from '../../../components/common';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { fetchStateOnPincode } from '../../../api/resources';
import { sorting } from '../../../constants/commonFunction';
import {
  CustomerIdentityTypes,
  CustomerStatus,
  CustomerType,
  KycStatus,
} from '../../../enums/commons';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import CustomerService from '../../../services/CustomerService';
import { ICustomer } from '../../../types/customers';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import CreateNewApplication, {
  ICreateApplication,
} from '../../Back-office/components/MCCAplicationManagement/CreateNewApplication';

import { Add } from '@mui/icons-material';
import VegaContainedButton from '../../../components/common/VegaContainedButton';
import VegaOutlineButton from '../../../components/common/VegaOutlineButton';
import AddUser from '../../Back-office/components/MCCAplicationManagement/AddUser';
import MCCFilterSection from '../../Back-office/components/MCCUserManagement/MCCFilterSection';
import { ICorporateEntity } from '../../types/CorporateCustomer';
import { STATUS } from '../../variable';
import { fetchCorporateById } from '../api/Corporate';
import ListOfCustomersInCorporate from '../components/CorporateApplicationManagement/ListOfCustomersInCorporate';
import { useForm } from 'react-hook-form';
import SubmitFormWrapper from '../../Back-office/components/SubmitFormWrapper';

export interface IUserProfileProps {
  firstName: string;
  lastName: string;
  address: string;
  dob: string;
  passportNo: string;
  panNo: string;
  mobileNumber: string;
  emailId: string;
  passportFile?: File | null;
  panFile?: File | null;
  kycFile?: File | null;
  ticketFile?: File | null;
  visaFile?: File | null;
  idType: CustomerIdentityTypes;
  idValue: string;
  state: string;
  city: string;
  pincode: string;

  // declaration: any;
}

const INITIAL_USER_DATA = {
  firstName: '',
  lastName: '',
  address: '',
  dob: '',
  passportNo: '',
  mobileNumber: '',
  emailId: '',
  passportFile: null,
  panFile: null,
  kycFile: null,
  ticketFile: null,
  visaFile: null,
  // declaration: '',
};

export interface IFilterProps {
  mobile: string;
  status: string;
}

const CorporateAplicationManagement = () => {
  const { clientId, mccPlatformId: corporateId } = useClientAuth();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [corporate, setCorporate] = useState<ICorporateEntity | null>(null);
  const { setSnackbar } = useSnackbar();
  const [filterCustomerOptions, setFilterData] = useState<IFilterProps>({
    mobile: '',
    status: 'ALL',
  });
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmptyKey, setIsEmptyKey] = useState<boolean>(false);

  const [listLoading, setListLoading] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<any>({
    ...INITIAL_USER_DATA,
  });

  const hookForm = useForm<ICreateApplication>({});

  const toggleUser = () => {
    setIsOpen(!isOpen);
  };

  const filteredCustomers = useMemo(() => {
    return customers.filter((cus) => {
      return (
        (cus.emailId
          .toLowerCase()
          .includes(filterCustomerOptions.mobile.toLowerCase()) ||
          cus.mobileNumber
            .toLowerCase()
            .includes(filterCustomerOptions.mobile.toLowerCase())) &&
        (filterCustomerOptions.status === 'ALL' ||
          filterCustomerOptions.status === cus.makerCheckerStatus)
      );
    });
  }, [filterCustomerOptions, customers]);

  const handleChange = (key: string, value: string | File | null) => {
    setUserProfile({ ...userProfile, [key]: value });
  };
  const handleFilter = (name: string, value: any) => {
    setFilterData({ ...filterCustomerOptions, [name]: value });
  };

  const onClear = () => {
    setUserProfile({ ...INITIAL_USER_DATA });
  };
  const onAddUser = async () => {
    setIsLoading(true);
    const formData = new FormData();
    const newObj = {
      ...userProfile,
      clientId,
      dob: dayjs(userProfile.dob).format('YYYY-MM-DD'),
      branchId: corporate?.branchId,
      //   branchId: '1c323af-c309-42d0-a374-db1b010f0b69',
      corporateId,
      countryCode: '+91',
      customerType: 'RETAIL_CUSTOMER',
    };

    Object.keys(newObj).forEach((value: any) => {
      if (newObj[value] != null) {
        formData.append(value, newObj[value]);
      }
    });

    if (clientId) {
      try {
        const customer = await CustomerService.createCustomer({
          clientId,
          dob: dayjs(userProfile.dob).format('YYYY-MM-DD'),
          corporateId,
          countryCode: '+91',
          identity: [
            {
              idNumber: userProfile.panNo,
              idType: CustomerIdentityTypes.PAN,
              idVerified: 'true',
            },
          ],
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          mobileNumber: userProfile.mobileNumber,
          emailId: userProfile.emailId,
          gender: 'M',
          status: CustomerStatus.ACTIVE,
          customerType: CustomerType.INDIVIDUAL,
          kycStatus: KycStatus.VERIFIED,
          currentAddress: {
            line1: userProfile.address,
            city: userProfile.city,
            state: userProfile.state,
            pincode: userProfile.pincode,
            country: 'IN',
          },
        });

        // upload docs
        await CustomerService.uploadCustomerDocs({
          customerId: customer.customerId,
          customerKycIdentityType: CustomerIdentityTypes.AADHAAR,
          kycFile: userProfile.kycFile,
          panFile: userProfile.panFile,
          passportFile: userProfile.passportFile,
          ticketFile: userProfile.ticketFile,
          visaFile: userProfile.visaFile,
        });

        setSnackbar('Customer added!');
        setIsLoading(false);
        getCustomerList();
        onClear();
      } catch (err) {
        setSnackbar(
          getErrorMessageFromErrorObj(
            err,
            'Error while creating the customer '
          ),
          'error'
        );
        setIsLoading(false);
      }
    }
  };

  const onApplicationSubmit = async (userData: ICreateApplication) => {
    // upload passport
    // validate the passport
    // fetch state , city thru pincode
    // create customer
    setIsLoading(true);
    console.log('🚀 submitted🚀');
    try {
      // let passportNo = '';
      // try {
      //   const passRes = await passportImageValidation({
      //     email: 'admin@signzy.com',
      //     imagesUrl: [''],
      //   });
      //   passportNo = passRes.result.passportNumber;
      // } catch (err) {
      //   setSnackbar('Error in passport validation ', 'error');
      //   setIsLoading(false);

      //   return;
      // }

      const pincodeRes = await fetchStateOnPincode(userData.pincode);
      if (pincodeRes.data.records.length === 0) {
        setSnackbar('Not a valid pincode', 'error');
        setIsLoading(false);
        return;
      }
      const pincodeData = pincodeRes.data.records[0];

      if (clientId) {
        const payload = {
          clientId,
          dob: dayjs(userData.dob).format('YYYY-MM-DD'),
          corporateId,
          branchId: corporate?.entityId,
          countryCode: '+91',
          identity: [
            {
              idNumber: userData.panNo,
              idType: CustomerIdentityTypes.PAN,
              idVerified: 'true',
            },
            // {
            //   idNumber: passportNo,
            //   idType: CustomerIdentityTypes.PASSPORT,
            //   idVerified: 'true',
            // },
          ],
          firstName: userData.firstName,
          lastName: userData.lastName,
          mobileNumber: userData.mobileNumber,
          emailId: userData.emailId,
          gender: 'M',
          status: CustomerStatus.DRAFT,
          customerType: CustomerType.INDIVIDUAL,
          kycStatus: KycStatus.VERIFIED,
          currentAddress: {
            line1: userData.address,
            pincode: userData.pincode,
            city: pincodeData.city,
            state: pincodeData.state,
            country: pincodeData.country,
            line2: '-',
          },
        };

        const customer = await CustomerService.createCustomer(payload);

        // upload docs
        await CustomerService.uploadCustomerDocs({
          customerId: customer.customerId,
          // customerId: '7766de60-625d-4aa5-8ab0-353e28395a61',
          customerKycIdentityType: CustomerIdentityTypes.AADHAAR,
          kycFile: userData.kycFile,
          panFile: userData.panFile,
          passportFile: userData.passportFile,
          ticketFile: userData.ticketFile,
          visaFile: userData.visaFile,
        });
      }
      getCustomerList();
      setIsLoading(false);
    } catch (err) {
      setSnackbar(
        getErrorMessageFromErrorObj(err, 'Error while creating the customer '),
        'error'
      );
      setIsLoading(false);
    }
  };

  const getCustomerList = async () => {
    setListLoading(true);
    CustomerService.listCustomers({
      // corporateId not branchId
      // branchId: corporate?.branchId,
      clientId: clientId || '',
      corporateId: corporateId,
      // status: ['DRAFT', 'UNDER_REVIEW', 'ACTIVE'],
    })
      .then((res) => {
        const sorted = sorting(res);
        setCustomers(sorted);

        // setFilterData({ mobile: '' });
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  // const getFilterData = (arr: ICustomer[]) => {
  //   const newArr = arr.filter((val) => {
  //     if (filterData.mobile === '') {
  //       return val;
  //     } else if (
  //       val.mobileNumber.toLowerCase().includes(filterData.mobile.toLowerCase())
  //     ) {
  //       return val;
  //     } else if (
  //       val.emailId.toLowerCase().includes(filterData.mobile.toLowerCase())
  //     ) {
  //       return val;
  //     }
  //     return false;
  //   });

  //   return newArr;
  // };

  // const filterStatus = customers.filter((val) => {
  //   if (filterData.status === 'ALL') {
  //     return val;
  //   } else if (val.checkerMakerStatus === filterData.status) {
  //     return val;
  //   }
  // });

  useEffect(() => {
    fetchCorporateById(corporateId).then((res) => {
      setCorporate(res.data);
    });
  }, [corporateId]);

  return (
    <VegaPage>
      <VegaCard noPad>
        {/* <AddUser
          state={userProfile}
          handleChange={handleChange}
          setIsEmptyKey={setIsEmptyKey}
        />
        <Grid container justifyContent={'end'} pb={2} px={3}>
          <Grid item>
            <VegaOutlineButton text="Clear" isDanger onClick={onClear} />
          </Grid>
          <Grid item>
            <VegaContainedButton
              text="Add User"
              isPrimary
              loading={isLoading}
              startIcon={<Add />}
              mx="0"
              onClick={onAddUser}
            />
          </Grid>
        </Grid>
         <CreateNewApplication
        onSubmit={onApplicationSubmit}
        loading={isLoading}
        hookForm={hookForm}
      /> */}
        <SubmitFormWrapper
          titleText="Add New Profile"
          submitText="Add User"
          cancelText="Clear"
          onSubmit={hookForm.handleSubmit(onApplicationSubmit)}
          onClear={hookForm.reset}
          toggle={toggleUser}
          isOpen={isOpen}
          loading={isLoading}
          disabled={isEmptyKey}
        >
          <CreateNewApplication
            onSubmit={onApplicationSubmit}
            loading={isLoading}
            hookForm={hookForm}
          />
        </SubmitFormWrapper>
      </VegaCard>

      <MCCFilterSection
        isStatusFilter
        statusOption={STATUS}
        state={filterCustomerOptions}
        handleChange={handleFilter}

        // onSearch={onSearch}
      />
      {customers.length ? (
        <ListOfCustomersInCorporate
          data={filteredCustomers}
          loading={listLoading}
          getCustomerList={getCustomerList}
        />
      ) : (
        <VegaCard>No Customer</VegaCard>
      )}
    </VegaPage>
  );
};

export default CorporateAplicationManagement;
