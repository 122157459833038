import { Fragment, useEffect, useState } from 'react';
import VegaDrawerV4, {
  IVegaDrawerV4Props,
} from '../../../../../common/v4/VegaDrawerV4';
import { Controller, useForm } from 'react-hook-form';
import { Divider, Radio, Stack } from '@mui/material';
import VegaSelectV4 from '../../../../../common/v4/VegaSelectV4';
import { pascalCaseFromCamelCase } from '../../../../../../utils/stringUtils';
import VegaTextfieldV4 from '../../../../../common/v4/VegaTextfieldV4';
import VegaButtonV4 from '../../../../../common/v4/VegaButtonV4';
import { SurplusType } from '../../../../../../enums/surplus.enum';
import VegaRadioV4 from '../../../../../common/v4/VegaRadioV4';
import hdfc from '../../../../../../../src/assets/images/hdfc.png';
import icici from '../../../../../../../src/assets/images/icici.png';
import sbi from '../../../../../../../src/assets/images/sbi.png';
import { QUICK_ACCESS } from '../../../../../../constants/style';
import {
  ComponentBody2Typo,
  SectionHeadingTypo,
} from '../../../../../common/Typography';

interface ISurplusPayoutFormProps {
  type: SurplusType;
  amt: number;
  reason: string;
  comment: string;
  bank: string;
}

const SurplusPayoutDrawer = (props: IVegaDrawerV4Props) => {
  const { open, onClose, ...rest } = props;
  const [showBanks, setShowbanks] = useState(false);
  const {
    control,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
    getValues,
  } = useForm<ISurplusPayoutFormProps>({
    mode: 'onTouched',
  });

  const onSubmit = () => {
    onClose?.({}, 'backdropClick');
  };

  const onShowBankClick = () => {
    setShowbanks(true);
  };

  const resetValues = () => {
    reset();
    setShowbanks(false);
  };

  useEffect(() => {
    if (!open) {
      setShowbanks(false);
      reset();
    }
  }, [open, reset]);

  return (
    <VegaDrawerV4
      {...rest}
      secondaryBtn={{
        show: true,
        props: {
          onClick: resetValues,
          value: 'Clear',
        },
      }}
      submitBtn={{
        show: true,
        props: {
          onClick: handleSubmit(onSubmit),
          value: 'Surplus Payout',
          disabled: !isDirty || !showBanks,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Stack width={400} gap={3}>
        <Controller
          name="type"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaSelectV4
              {...rest}
              error={Boolean(errors.type)}
              header="Surplus Type"
              labelText={errors.type?.message}
              value={rest.value || ([] as any)}
              options={Object.values(SurplusType).map((el) => ({
                name: pascalCaseFromCamelCase(el),
                id: el,
              }))}
              standardWidth="100%"
              wrapperProps={{
                sx: {
                  flex: 0,
                },
              }}
              required
              placeholder="Select type"
            />
          )}
        />
        <Controller
          name="amt"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.amt)}
              header="Amount"
              labelText={errors.amt?.message}
              required
              placeholder="Enter Amount"
              type="number"
              standardWidth="100%"
            />
          )}
        />
        <Controller
          name="reason"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaSelectV4
              {...rest}
              error={Boolean(errors.reason)}
              header="Reason"
              labelText={errors.reason?.message}
              value={rest.value || ([] as any)}
              options={Object.values(['test 1', 'test 2']).map((el) => ({
                name: pascalCaseFromCamelCase(el),
                id: el,
              }))}
              standardWidth="100%"
              wrapperProps={{
                sx: {
                  flex: 0,
                },
              }}
              required
              placeholder="Select reason"
            />
          )}
        />
        <Controller
          name="comment"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.comment)}
              header="Comment"
              labelText={errors.comment?.message}
              placeholder="Type your comment here"
              standardWidth="100%"
              multiline
              rows={5}
            />
          )}
        />

        {showBanks ? (
          <Fragment>
            <Divider sx={{ width: '100%', borderStyle: 'dashed' }} />

            <Controller
              name="bank"
              control={control}
              rules={{
                required: 'Required field',
              }}
              render={({ field: { ref, onChange, value, ...rest } }) => {
                return (
                  <VegaRadioV4
                    label="Gender"
                    componentProps={{
                      radioGroup: {
                        ...rest,
                        value,
                        sx: { display: 'flex', gap: '20px', mt: 3, mb: 2 },
                      },
                    }}
                    formControlComponents={BANKS.map((item) => (
                      <Stack
                        key={item.name}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack direction={'row'} gap={1.5}>
                          <Stack
                            border={`1px solid ${QUICK_ACCESS.border[50]}`}
                            p={0.5}
                            width="fit-content"
                            borderRadius={'50%'}
                          >
                            <img
                              src={item.img}
                              alt={item.name}
                              width={30}
                              height={30}
                            />
                          </Stack>
                          <Stack>
                            <SectionHeadingTypo>{item.name}</SectionHeadingTypo>
                            <ComponentBody2Typo
                              sx={{ color: QUICK_ACCESS.text[75] }}
                            >
                              {item.name}
                            </ComponentBody2Typo>
                          </Stack>
                        </Stack>
                        <Radio
                          onChange={(e) =>
                            onChange(e.target.checked ? item.name : undefined)
                          }
                          value={item.name}
                          sx={{
                            color: 'black',
                            '&.Mui-checked': {
                              color: QUICK_ACCESS.primary.blue,
                            },
                            '.MuiSvgIcon-root': {
                              width: 15,
                              height: 15,
                            },
                          }}
                          checked={value === item.name}
                        />
                      </Stack>
                    ))}
                    error={errors.bank?.message}
                    standardWidth="100%"
                  />
                );
              }}
            />
          </Fragment>
        ) : (
          <VegaButtonV4
            variant="outlined"
            onClick={handleSubmit(onShowBankClick)}
          >
            Fetch & Select a bank account
          </VegaButtonV4>
        )}
      </Stack>
    </VegaDrawerV4>
  );
};

const BANKS = [
  { name: 'State Bank of India', number: 'XXXX XXXX XXXX 3456', img: sbi },
  { name: 'HDFC', number: 'XXXX XXXX XXXX 9987', img: hdfc },
  { name: 'ICICI', number: 'XXXX XXXX XXXX 1234', img: icici },
];

export default SurplusPayoutDrawer;
