export class FileUtils {
  static downloadJsonFile = (data: { data: any; fileName: string }) => {
    const myData = JSON.stringify(data.data);

    // create file in browser
    const fileName = data.fileName;
    const json = JSON.stringify(myData, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };
  static downloadFileFromURI(uri: string, name: string): void {
    const link = document.createElement('a');
    link.setAttribute('download', name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

export function downloadFileFromURI(uri: string, name: string): void {
  const link = document.createElement('a');
  link.setAttribute('download', name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
}
/**
 * THIS WAS REQUIRED AS BROWSER WAS CACHING IMAGES
 *
 *
 * Adds a timestamp query parameter to an AWS URL, if the URL is from AWS.
 *
 * @param url - The URL to check and modify.
 * @returns The modified URL with a timestamp query parameter, or the original URL if it's not from AWS.
 */
export function addTimestampIfAwsUrl(url: string): string {
  const awsUrlPattern = /^https?:\/\/.*\.amazonaws\.com/;
  if (awsUrlPattern.test(url)) {
    const timestamp = Date.now();
    return `${url}?t=${timestamp}`;
  }
  return url;
}
