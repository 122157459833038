import { useState } from 'react';
import {
  InformationCircleIcon,
  UserProfileIcon,
} from '../../../ui-components/icon/VPIcons';
import { DetailsSection, MakerChecker } from '../containers';
import VPMenuDrivenContent from '../../../ui-components/common/VPMenuDrivenContent';
import type { VegaProgramWithMetaDataType } from '../../../types/program';

type ProgramDetailsProps = {
  program: VegaProgramWithMetaDataType;
};

const listItems = [
  { id: 'details', title: 'Details', Icon: InformationCircleIcon },
  { id: 'makerChecker', title: 'Maker Checker', Icon: UserProfileIcon },
];

function ProgramDetails({ program }: ProgramDetailsProps) {
  const [selectedListItem, setSelectedListItem] = useState(listItems[0].id);
  const isCurrentListItemDetails = selectedListItem === listItems[0].id;

  const handleListItemClick = (id: string) => {
    setSelectedListItem(id);
  };

  return (
    <VPMenuDrivenContent
      menuItems={listItems}
      selectedMenuItem={selectedListItem}
      onMenuItemChange={handleListItemClick}
    >
      {isCurrentListItemDetails ? (
        <DetailsSection program={program} />
      ) : (
        <MakerChecker />
      )}
    </VPMenuDrivenContent>
  );
}

export default ProgramDetails;
