import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Drawer, DrawerProps, Stack, Toolbar } from '@mui/material';
import Box from '@mui/material/Box/Box';
import { ComponentBody2Typo, PageHeadingTypo } from '../Typography';
import {
  INDICATIVE,
  NEUTRALS,
  QUICK_ACCESS,
  VEGA_COMMON,
} from '../../../constants/style';
import VegaButtonV4, { IVegaButtonV4Props } from './VegaButtonV4';

export interface IVegaDrawerV4Props extends DrawerProps {
  header?: string | React.ReactNode;
  description?: string;
  footer?: JSX.Element;
  submitBtn?: {
    props?: IVegaButtonV4Props;
    show: boolean;
  };
  secondaryBtn?: {
    props?: IVegaButtonV4Props;
    show?: boolean;
  };
}

const VegaDrawerV4 = ({
  anchor,
  open,
  onClose,
  children,
  footer,
  secondaryBtn,
  header,
  submitBtn,
  description,
  sx,
  ...rest
}: IVegaDrawerV4Props) => {
  const secondaryBtnShow =
    secondaryBtn?.show === undefined ? true : secondaryBtn?.show;
  const secondaryBtnProps = secondaryBtn?.props;

  return (
    <Drawer
      anchor={anchor ?? 'right'}
      PaperProps={{
        sx: {
          minWidth: '320px',
        },
      }}
      open={open}
      onClose={onClose}
      sx={{
        '.MuiToolbar-root': {
          px: 5,
        },
        background: 'rgba(19, 20, 50, 0.50)',
        backdropFilter: 'blur(12.5px)',
        ...sx,
      }}
      {...rest}
    >
      <AppBar
        position="static"
        sx={{
          bgcolor: INDICATIVE.blue[50],
          borderBottom: `1px solid ${NEUTRALS.white[300]}`,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Stack alignItems="flex-start" gap={0.5}>
            <PageHeadingTypo
              sx={{
                flexGrow: 1,
                color: QUICK_ACCESS.text[200],
              }}
            >
              {header}
            </PageHeadingTypo>
            {description && (
              <ComponentBody2Typo
                sx={{
                  color: QUICK_ACCESS.text[100],
                }}
              >
                {description}
              </ComponentBody2Typo>
            )}
          </Stack>

          {onClose && (
            <CloseIcon
              sx={{ color: NEUTRALS.grey[400] }}
              className="cursor-pointer"
              onClick={(e) => onClose(e, 'backdropClick')}
            />
          )}
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          p: 2,
          bgcolor: 'white',
          borderRadius: 1,
          height: '88%',
          overflow: 'auto',
          py: 4,
          px: 5,
          flex: 'auto',
          '::-webkit-scrollbar': {
            width: 0,
          },
          '::-moz-scrollbar': {
            width: 0,
          },
        }}
      >
        {children}
      </Box>

      <Stack
        direction="row"
        justifyContent="flex-end"
        gap={2}
        py={3}
        px={5}
        borderTop={`1px solid ${VEGA_COMMON.border[50]}`}
      >
        {footer ? (
          footer
        ) : Boolean(secondaryBtnShow) ? (
          <VegaButtonV4
            value={secondaryBtnProps?.value || 'Close'}
            onClick={
              secondaryBtnProps?.onClick ||
              ((e) => onClose?.(e, 'escapeKeyDown'))
            }
            {...secondaryBtnProps}
          />
        ) : null}
        {Boolean(submitBtn?.show) && (
          <VegaButtonV4
            variant="contained"
            value="Submit"
            {...submitBtn?.props}
          />
        )}
      </Stack>
    </Drawer>
  );
};

export default VegaDrawerV4;
