/** @format */

import axios, { AxiosResponse } from 'axios';
import { BASE_URL, POSTPE, REWARDS } from './endpoints';
import { PaginatedResponse } from '../utils/api';
import { RewardResponseProps } from '../types/reward';
import { CommonRequestQuery } from '../types/commonType';

const REWARDS_BASE_URL = BASE_URL + REWARDS.BASE;
const LIST_FOR_ACCOUNT = REWARDS_BASE_URL + REWARDS.LIST_FOR_ACCOUNT;

export const getRewardsForAccount = (accountId: string) => {
  return axios.get(LIST_FOR_ACCOUNT.replace('{accountId}', accountId));
};

export const fetchCampaignList = (programId: string) => {
  return axios.get(`${REWARDS.CAMPAIGN}/program/${programId}`);
};

export const getRewardsList = (
  data: {
    accountId: string;
    page?: number;
    pageSize?: number;
  } & CommonRequestQuery
): Promise<AxiosResponse<PaginatedResponse<RewardResponseProps>>> => {
  return axios.post(POSTPE.REWARD, {
    ...data,
    accountId: data.accountId,
    page: data.page ?? 0,
    pageSize: data.pageSize ?? 100,
  });
};
