import { useCallback } from 'react';
import { useInstitutionStandardContext } from '../../mcc/Program-Dashboard/pages/InstitutionStandardWrap';
import InstitutionStandardHolidays from './InstitutionStandardHolidays';

const InstitutionStandard = () => {
  const { currState } = useInstitutionStandardContext();

  const getDisplay = useCallback(() => {
    switch (currState) {
      case 'holidays':
        return <InstitutionStandardHolidays />;
      default:
        return <InstitutionStandardHolidays />;
    }
  }, [currState]);

  return getDisplay();
};

export default InstitutionStandard;
