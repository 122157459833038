import { TransactionCode, TransactionIndicator } from '../enums/transaction';
import { Currency } from './cardRefund';
import { LedgerBreakupData } from './ledger';
import { LoanDto } from './transaction';

export interface IBillResponseData {
  id: string;
  accountId: string;
  billStartDate: string;
  billEndDate: string;
  billDate: string;
  billDueDate: string;
  billPaidDate: string;
  billPdfLink: string;
  loanId: string;
  loanConversionDate: string;
  createdAt: string;
  updatedAt: string;
  previousBillId: string;
  status: BillStatus;
  billAmount: number;
  debitTxnCount: number;
  debitAmount: number;
  creditTxnCount: number;
  creditAmount: number;
  dueAmount: number;
  dueCharge: number;
  dueInterest: number;
  dueRetail: number;
  dueCash: number;
  dueEmi: number;
  dueOverlimit: number;
  mad: number;
  paidAmount: number;
  paidCharge: number;
  paidInterest: number;
  paidPrincipal: number;
  previousPendingBillAmount: number;
  clientId: string;
  programId: string;
  statementId: string;
  originalBillId: string;
  loan: LoanDto;
  dueCashCharges: number;
  dueNonCashCharges: number;
  dueCashInterest: number;
  dueNonCashInterest: number;
  openingBalance: number;
  closingBalance: number;
  limitUsed: number;
  limitAvailable: number;
}

export interface ITransactionForBillResponse {
  id: string;
  accountId: string;
  status: string;
  amount: number;
  amountDue: number;
  transactionIndicator: TransactionIndicator;
  currency: Currency;
  txnCode: TransactionCode;
  billId: string;
  merchantName: string;
  transactionId: string;
  transactionDate: string;
  chargeBackId: string;
  createdAt: string;
  updatedAt: string;
  ledgerBreakup: LedgerBreakupData;
}

export enum BillStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  CARRY_FORWARD = 'CARRY_FORWARD',
  CARRY_FORWARD_PAID = 'CARRY_FORWARD_PAID',
  SKIPPED_PAYMENT = 'SKIPPED_PAYMENT',
  CONVERTED_TO_EMI = 'CONVERTED_TO_EMI',
}

export enum PhysicalStatementStatus {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  PRINTED = 'PRINTED',
  DELIVERED = 'DELIVERED',
  DECLINED = 'DECLINED',
}

export interface PhysicalStatementOrderDto {
  id: string;
  programId: string;
  accountId: string;
  billId: string;
  billDate: string;
  billStartDate: string;
  billEndDate: string;
  status: PhysicalStatementStatus;
  createdAt: string;
  updatedAt: string;
}
