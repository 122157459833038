import { Controller, useForm } from 'react-hook-form';
import VegaDrawerV4, {
  IVegaDrawerV4Props,
} from '../../../../../common/v4/VegaDrawerV4';
import VegaDatePickerV4 from '../../../../../common/v4/VegaDatePickerV4';
import { Stack } from '@mui/material';
import { VALIDATE_REGEX } from '../../../../../../utils/validation.utils';
import VegaTextfieldV4 from '../../../../../common/v4/VegaTextfieldV4';
import VegaSelectV4 from '../../../../../common/v4/VegaSelectV4';
import { pascalCaseFromCamelCase } from '../../../../../../utils/stringUtils';
import {
  TransactionCode,
  TransactionIndicator,
} from '../../../../../../enums/transaction';
import { useEffect, useState } from 'react';
import {
  createRepaymentForAccount,
  createTransactionForAccount,
  updateTransactionStatus,
} from '../../../../../../api/transaction';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { PolicyTransactionCode } from '../../../../../../types/policy';
import { useSnackbar } from '../../../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../../../utils/api';

type FormValues = {
  valueDate: string;
  category: string;
  indicator: string;
  reason: string;
  amount: number;
};

const AdjustTransactionDrawer = (
  props: IVegaDrawerV4Props & { refetch?: any }
) => {
  const { open } = props;
  const [loading, setLoading] = useState(false);
  const { accountData } = useSupport();
  const { setSnackbar } = useSnackbar();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<FormValues>({
    mode: 'onTouched',
  });

  const resetForm = () => {
    reset();
  };

  const submitForm = () => {
    setLoading(true);
    const values = getValues();
    const indicator = values.indicator as TransactionIndicator;

    if (indicator === TransactionIndicator.DEBIT) {
      createTransactionForAccount({
        accountId: accountData?.accountId || '',
        amount: values.amount,
        time: values.valueDate || undefined,
        code: values.category as TransactionCode,
        merchantLocation: 'CMR',
        indicator: indicator,
        reason: values.reason,
      })
        .then((res) => {
          setSnackbar('Transaction created!');
          props.refetch?.();
          props.onClose?.({}, 'escapeKeyDown');
          reset();
        })
        .catch((err) => {
          setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const values = getValues();
      createRepaymentForAccount(
        accountData?.accountId || '',
        values.amount,
        values.valueDate || undefined
      )
        .then(async (res) => {
          const dataRes = res.data;
          if (dataRes.status) {
            try {
              const { data } = await updateTransactionStatus(
                dataRes.transactionId,
                'SUCCESS'
              );
              if (data.status) {
                setSnackbar('Transaction Status Successfully Updated!');
              } else {
                throw new Error('Transaction in Pending State');
              }
            } catch (e) {
              setSnackbar(getErrorMessageFromErrorObj(e), 'error');
            }
          }
          props.refetch?.();
          setSnackbar('Transaction created!');
          props.onClose?.({}, 'escapeKeyDown');
          reset();
        })
        .catch((err) => {
          setSnackbar(getErrorMessageFromErrorObj(err), 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const watchIndicator = watch('indicator');

  return (
    <VegaDrawerV4
      {...props}
      header="Adjust transaction"
      secondaryBtn={{
        props: {
          value: 'Clear',
          onClick: resetForm,
          disabled: loading,
        },
      }}
      submitBtn={{
        show: true,
        props: {
          value: 'Adjust transaction',
          onClick: handleSubmit(submitForm),
          disabled: loading,
          loading: loading,
        },
      }}
    >
      <Stack gap={3} width={400}>
        <Controller
          name="valueDate"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => {
            return (
              <VegaDatePickerV4
                dateProps={{ ...rest, views: ['year', 'month', 'day'] }}
                textProps={{
                  header: 'Value date',
                  required: true,
                  labelText: errors.valueDate?.message,
                  error: Boolean(errors.valueDate),
                  standardWidth: '100%',
                  placeholder: 'Select Date',
                }}
              />
            );
          }}
        />
        <Controller
          name="valueDate"
          control={control}
          render={({ field: { ref, ...rest } }) => {
            return (
              <VegaDatePickerV4
                dateProps={{ ...rest, views: ['year', 'month', 'day'] }}
                textProps={{
                  header: 'Book date',
                  error: Boolean(errors.valueDate),
                  disabled: true,
                  standardWidth: '100%',
                  placeholder: 'Select Date',
                }}
              />
            );
          }}
        />
        <Controller
          name="indicator"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaSelectV4
              {...rest}
              error={Boolean(errors.indicator)}
              header="Indicator"
              labelText={errors.indicator?.message}
              value={rest.value || ([] as any)}
              standardWidth={'100%'}
              options={Object.values(TransactionIndicator).map((el) => ({
                name: pascalCaseFromCamelCase(el),
                id: el,
              }))}
              required
              placeholder="Select Debit/Credit"
            />
          )}
        />
        {watchIndicator === 'DEBIT' && (
          <Controller
            name="category"
            control={control}
            rules={{
              required: 'Required field',
            }}
            render={({ field: { ref, ...rest } }) => (
              <VegaSelectV4
                {...rest}
                error={Boolean(errors.category)}
                header="Category"
                labelText={errors.category?.message}
                value={rest.value || ([] as any)}
                standardWidth={'100%'}
                options={Object.values(PolicyTransactionCode).map((el) => ({
                  name: pascalCaseFromCamelCase(el),
                  id: el,
                }))}
                required
                placeholder="Select Category"
              />
            )}
          />
        )}
        <Controller
          name="reason"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.COMPANY_NAME,
              message: 'Not a valid reason - Only Alphanumeric values allowed',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.reason)}
              header="Reason"
              labelText={errors.reason?.message}
              standardWidth={'100%'}
              required
              placeholder="Enter Reason"
            />
          )}
        />
        <Controller
          name="amount"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.ALPHA_NUMERIC,
              message: 'Not a valid amount',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.amount)}
              header="Amount"
              type="number"
              labelText={errors.amount?.message}
              standardWidth={'100%'}
              required
              placeholder="Enter Amount"
            />
          )}
        />
      </Stack>
    </VegaDrawerV4>
  );
};

export default AdjustTransactionDrawer;
