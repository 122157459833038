import axios from 'axios';
import { BASE_URL, PROGRAM } from './endpoints';

const PROGRAM_BASE = BASE_URL + PROGRAM.BASE;

export const updateProgramPDDList = (programId: string, pddList: any) => {
  const data = {
    programId,
    pddList,
  };
  return axios.patch(`${PROGRAM_BASE}/paymentDueDates`, data);
};
