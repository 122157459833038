import { Box, Divider, Stack } from '@mui/material';
import imgAccepted from '../../../assets/images/StampAccepted.png';
import imgRejected from '../../../assets/images/StampRejected.png';
import {
  ComponentBody1Typo,
  ComponentBody2Typo,
  ListGroupHeadingTypo,
} from '../../common/Typography';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { AccountClosureStatus } from '../../../enums/account.enum';
import { useAppSelector } from '../../../store/hook';
import { IAccountClosureRequestDetails } from '../../../types/accountClosure';
import dayjs from 'dayjs';

const AccountClosureStatusComponent = () => {
  const { status, details, isLoading } = useAppSelector(
    ({ accountClosure }) => ({
      status: accountClosure.accountClosureRequestDetails.payload?.status,
      details: accountClosure.accountClosureRequestDetails.payload,
      isLoading:
        accountClosure.accountClosureRequestDetails.apiStatus?.isLoading,
    })
  );

  const isApproved = status === AccountClosureStatus.APPROVED;

  const statusDesc = getStatusDesc(details, isApproved);

  return isLoading || !details ? null : (
    <Stack p={3} sx={{ borderTop: '1px solid #EEE' }} direction="row" gap={3}>
      <img
        src={isApproved ? imgAccepted : imgRejected}
        alt="status"
        width={80}
        height={80}
      />
      <Box>
        <Divider orientation="vertical" sx={{ color: '#E1E4EB' }} />
      </Box>
      <Stack gap={2}>
        {statusDesc.map((item, idx) =>
          item.val ? (
            <Stack key={idx} gap={0.5} sx={{ width: '424px' }}>
              <ListGroupHeadingTypo sx={{ color: '#7B8694' }}>
                {item.title}
              </ListGroupHeadingTypo>
              <Box
                p={1.5}
                sx={{ borderRadius: '8px', border: '1px solid #EEE' }}
              >
                <ComponentBody1Typo>{item.val}</ComponentBody1Typo>
              </Box>
            </Stack>
          ) : null
        )}
        {!isApproved && (
          <Stack
            p={1.5}
            sx={{
              borderRadius: '8px',
              border: '1px dashed #EFDF8D',
              backgroundColor: '#FCF8E6',
              width: '424px',
            }}
            direction="row"
            alignItems="center"
            gap={1.5}
          >
            <WarningAmberRoundedIcon
              sx={{ color: '#9D8406', fontSize: '16px' }}
            />
            <Stack gap={0.5} direction="row" alignItems="center">
              <ListGroupHeadingTypo sx={{ color: '#7B8694' }}>
                note:
              </ListGroupHeadingTypo>
              <ComponentBody2Typo sx={{ color: '#616E7F' }}>
                It will be evaluated in 24 hours.
              </ComponentBody2Typo>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const getStatusDesc = (
  details?: IAccountClosureRequestDetails,
  isApproved?: boolean
) => {
  if (isApproved) {
    return [
      {
        title: 'Approved on',
        val: details?.updatedAt
          ? dayjs(details?.updatedAt).format('DD/MM/YYYY')
          : '--',
      },
      { title: 'Approved by', val: details?.updatedBy || '--' },
    ];
  } else {
    return [
      {
        title: 'reason',
        val: details?.cancelReason || '--',
      },
      {
        title: 'remarks',
        val: details?.cancelRemarks || '--',
      },
    ];
  }
};

export default AccountClosureStatusComponent;
