import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import {
  ActionMediumTypo,
  ComponentBody1Typo,
  ComponentHeadingTypo,
  PageHeadingTypo,
} from '../Typography';
import { INDICATIVE, QUICK_ACCESS } from '../../../constants/style';
import { Skeleton, Stack, SxProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VegaBadgeStatusTagV4, {
  BadgeStatusTagColor,
  BadgeStatusTagVariant,
} from './VegaBadgeStatusTagV4';
import { ValueType } from '../../../mcc/types/Commons';
import { VegaSwitchV4 } from './VegaSwitchV4';
import VegaCheckboxV4 from './VegaCheckboxV4';

interface IRowProps {
  row: ITableDataProps;
  rowNum: number;
  labelId: string;
  handleClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number
  ) => void;
  isItemSelected: boolean;
  isLastRow: boolean;
  isCheckBoxRequired?: boolean;
  colSpan: number;
  isLoading?: boolean;
}

interface IVegaTableV4Props {
  checkBoxSelectFunc?: (idxs: number[]) => void;
  onFilterClick?: () => void;
  data: ITableDataProps[];
  head: string[];
  pagination?: {
    rowsPerPage: number;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    totalRecords: number;
  };
  isLoading?: boolean;
  tableTitle?: string;
  refreshNum?: number; // this is to undo all the check box selection => keep incrementing number to undo all selection
}

export interface IRowDataProps extends React.PropsWithChildren {
  value?: number | string;
  type: ValueType;
  statusColor?: BadgeStatusTagColor;
  statusVariant?: BadgeStatusTagVariant;
  isSwitchOn?: boolean;
  onSwitchChange?: Function;
  linkHref?: string;
  linkPath?: string;
  linkOnClick?: Function;
  cellSx?: SxProps;
  typoSx?: SxProps;
}

export interface ITableDataProps {
  rowData: IRowDataProps[];
  collapseData?: {
    value?: number | string;
  } & React.PropsWithChildren;
  id?: any;
}

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  isCheckBoxRequired: boolean;
  values: string[];
  isCollapsePresent: boolean;
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  onFilterClick?: () => void;
  tableTitle: string;
  onSelectsClick?: () => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    isCheckBoxRequired,
    values,
    isCollapsePresent,
  } = props;

  return (
    <TableHead sx={{ backgroundColor: QUICK_ACCESS.background[100] }}>
      <TableRow>
        {isCheckBoxRequired && (
          <TableCell
            sx={{
              padding: 2,
              borderRight: `1px solid ${QUICK_ACCESS.border[50]}`,
            }}
          >
            <VegaCheckboxV4
              label=""
              checkboxProps={{
                checked: rowCount > 0 && numSelected === rowCount,
                onChange: onSelectAllClick,
                inputProps: {
                  'aria-label': 'select all desserts',
                },
              }}
            />
          </TableCell>
        )}
        {values.map((val, idx) => (
          <TableCell
            key={val}
            sx={{
              padding: 2,
              borderLeft:
                idx !== 0 ? `1px solid ${QUICK_ACCESS.border[50]}` : undefined,
            }}
          >
            <ComponentHeadingTypo sx={{ color: QUICK_ACCESS.text[100] }}>
              {val}
            </ComponentHeadingTypo>
          </TableCell>
        ))}
        {isCollapsePresent && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, onFilterClick, onSelectsClick, tableTitle } = props;

  return (
    <Toolbar
      sx={{
        pl: { xs: 0 },
        pr: { xs: 0 },
      }}
    >
      {numSelected > 0 ? (
        <PageHeadingTypo sx={{ flex: '1 1 100%' }}>
          {numSelected} selected
        </PageHeadingTypo>
      ) : (
        <PageHeadingTypo sx={{ flex: '1 1 100%' }}>
          {tableTitle}
        </PageHeadingTypo>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={onSelectsClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : onFilterClick ? (
        <Tooltip title="Filter list">
          <IconButton onClick={onFilterClick}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

interface ITableCellCustomProps extends TableCellProps {
  borderLeftRequired?: boolean;
  data?: IRowDataProps;
  data2Send?: any;
  isLoading?: boolean;
}

const TableCellCustom = ({
  children,
  borderLeftRequired,
  data,
  sx,
  data2Send,
  isLoading,
  ...rest
}: ITableCellCustomProps) => {
  const navigate = useNavigate();

  const onLinkClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (data?.linkOnClick) {
      data?.linkOnClick?.(data2Send);
    } else if (data?.linkPath) {
      navigate(data?.linkPath);
    } else {
      const newWindow = window.open(
        data?.linkHref,
        '_blank',
        'noopener,noreferrer'
      );
      if (newWindow) newWindow.opener = null;
    }
    e.stopPropagation();
  };

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    data?.onSwitchChange?.(data2Send);
  };

  const display = () => {
    switch (data?.type) {
      case undefined:
        return children;
      case 'link':
        return data?.children ? (
          data?.children
        ) : (
          <ActionMediumTypo onClick={onLinkClick} sx={data.typoSx}>
            {data?.value}
          </ActionMediumTypo>
        );
      case 'switch':
        return (
          <Stack direction="row" gap={1} alignItems="center">
            {data?.onSwitchChange && (
              <VegaSwitchV4
                size="small"
                checked={data?.isSwitchOn}
                onChange={onSwitchChange}
              />
            )}
            <ComponentBody1Typo sx={data.typoSx}>
              {data?.value || data?.children}
            </ComponentBody1Typo>
          </Stack>
        );
      case 'status':
        if (data.value) {
          return (
            <VegaBadgeStatusTagV4
              variant={data.statusVariant || 'stroked'}
              text={data.value}
              color={data.statusColor || 'yellow'}
            />
          );
        }
        return null;
      default:
        return (
          data?.children || (
            <ComponentBody1Typo sx={data?.typoSx}>
              {data?.value}
            </ComponentBody1Typo>
          )
        );
    }
  };

  return (
    <TableCell
      {...rest}
      sx={{
        ...sx,
        ...(data?.cellSx as any),
      }}
    >
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="22px"
          sx={{ borderRadius: '5px' }}
        />
      ) : (
        display()
      )}
    </TableCell>
  );
};

interface ICellDataProps {
  data: IRowDataProps;
  isFirstColumn?: boolean;
  labelId: string;
  borderLeftRequired?: boolean;
  isLastRow?: boolean;
  sx?: SxProps;
  data2Send?: any;
  isLoading?: boolean;
}

const CellData = ({
  data,
  labelId,
  isFirstColumn,
  borderLeftRequired,
  isLastRow,
  sx,
  data2Send,
  isLoading,
}: ICellDataProps) => {
  return isFirstColumn ? (
    <TableCellCustom
      component="th"
      id={labelId}
      scope="row"
      borderLeftRequired={borderLeftRequired}
      sx={{
        borderBottom: isLastRow ? 0 : undefined,
        px: 2,
        py: 1,
        borderLeft: borderLeftRequired
          ? `1px solid ${QUICK_ACCESS.border[50]}`
          : undefined,
        ...sx,
      }}
      data={data}
      data2Send={data2Send}
      isLoading={isLoading}
    />
  ) : (
    <TableCellCustom
      borderLeftRequired
      sx={{
        borderBottom: isLastRow ? 0 : undefined,
        px: 2,
        py: 1,
        borderLeft: borderLeftRequired
          ? `1px solid ${QUICK_ACCESS.border[50]}`
          : undefined,
        ...sx,
      }}
      data={data}
      data2Send={data2Send}
      isLoading={isLoading}
    />
  );
};

function Row({
  row,
  rowNum,
  labelId,
  handleClick,
  isItemSelected,
  isCheckBoxRequired,
  isLastRow,
  isLoading,
}: IRowProps) {
  const [expandRow, setExpandRow] = React.useState(false);
  const data = row.rowData;
  const data2Send = row.id;
  const collapseData = row.collapseData;

  return (
    <>
      <TableRow
        hover
        onClick={(event) => isCheckBoxRequired && handleClick(event, rowNum)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        sx={{
          cursor: isCheckBoxRequired ? 'pointer' : undefined,
        }}
      >
        {isCheckBoxRequired && (
          <TableCellCustom
            sx={{
              borderBottom: isLastRow ? 0 : undefined,
              px: 2,
              py: 1,
            }}
          >
            <VegaCheckboxV4
              label=""
              checkboxProps={{
                checked: isItemSelected,
                inputProps: {
                  'aria-labelledby': labelId,
                },
              }}
            />
          </TableCellCustom>
        )}
        {Boolean(data.length && data[0]) && (
          <CellData
            labelId={labelId}
            borderLeftRequired={isCheckBoxRequired}
            isLastRow={Boolean(collapseData) ? false : isLastRow}
            data={data[0]}
            isFirstColumn
            isLoading={isLoading}
          />
        )}
        {data.map((item, idx) =>
          idx === 0 ? null : (
            <CellData
              key={idx}
              data={item}
              labelId={labelId}
              borderLeftRequired
              isLastRow={Boolean(collapseData) ? false : isLastRow}
              data2Send={data2Send}
              isLoading={isLoading}
            />
          )
        )}
        {Boolean(collapseData) && (
          <TableCellCustom
            sx={{
              borderBottom: isLastRow && !Boolean(collapseData) ? 0 : undefined,
              p: 2,
            }}
            align="right"
            borderLeftRequired={false}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                setExpandRow(!expandRow);
                e.stopPropagation();
              }}
            >
              {expandRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCellCustom>
        )}
      </TableRow>
      {Boolean(collapseData) && (
        <TableRow>
          <TableCell
            style={{ padding: 0 }}
            colSpan={7}
            sx={{ borderBottom: isLastRow ? 0 : undefined }}
          >
            <Collapse in={expandRow} timeout="auto" unmountOnExit>
              <Box>
                {Boolean(collapseData?.value) ? (
                  <Typography variant="h6" component="div">
                    {collapseData?.value}
                  </Typography>
                ) : (
                  collapseData?.children
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default function VegaTableV4({
  checkBoxSelectFunc,
  onFilterClick,
  data,
  head,
  pagination,
  isLoading,
  tableTitle,
  refreshNum,
}: IVegaTableV4Props) {
  const [selected, setSelected] = React.useState<number[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(
      event.target.checked ? Array.from(Array(data.length).keys()) : []
    );
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    pagination?.setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    pagination?.setRowsPerPage(parseInt(event.target.value, 10));
    pagination?.setPage(0);
  };

  const onSelectsClick = () => {
    console.log('onSelectsClick');
    // checkBoxSelectFunc?.(selected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = React.useMemo(
    () => Math.max(0, (pagination?.rowsPerPage || 10) - data.length),
    [pagination?.rowsPerPage, data.length]
  );

  const colSpan = React.useMemo(() => {
    return (
      (data?.[0]?.rowData.length || head?.length || 0) +
      (checkBoxSelectFunc ? 1 : 0) +
      (data?.[0]?.collapseData ? 1 : 0)
    );
  }, [checkBoxSelectFunc, data, head?.length]);

  React.useEffect(() => {
    if (refreshNum !== 0) {
      setSelected([]);
    }
  }, [refreshNum]);

  React.useEffect(() => {
    checkBoxSelectFunc?.(selected);
  }, [selected, checkBoxSelectFunc]);

  return (
    <Box sx={{ width: '100%' }}>
      {Boolean(tableTitle) && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          onFilterClick={onFilterClick}
          onSelectsClick={onSelectsClick}
          tableTitle={tableTitle || ''}
        />
      )}
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          borderRadius: '8px',
          overflow: 'hidden',
          border: `1px solid ${QUICK_ACCESS.border[50]}`,
          boxShadow: 'none',
        }}
      >
        <TableContainer
          sx={{
            overflow: isLoading ? 'hidden' : undefined,
            boxShadow: 'none',
          }}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'small'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data.length}
              isCheckBoxRequired={Boolean(checkBoxSelectFunc)}
              values={head}
              isCollapsePresent={Boolean(data?.[0]?.collapseData)}
            />

            {/* loading progress bar - deprecated */}
            {/* <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={colSpan} sx={{ padding: 0 }}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody> */}

            <TableBody
              sx={{
                '.Mui-selected': {
                  backgroundColor: `${INDICATIVE.blue[50]} !important`,
                },
                '.Mui-selected:hover': {
                  backgroundColor: `${INDICATIVE.blue[100]} !important`,
                },
              }}
            >
              {data.map((item, index) => {
                const isItemSelected = isSelected(index);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Row
                    rowNum={index}
                    labelId={labelId}
                    isItemSelected={isItemSelected}
                    handleClick={handleClick}
                    row={item}
                    key={index}
                    isLastRow={
                      data.length - 1 === index && !(isLoading && emptyRows > 0)
                    }
                    isCheckBoxRequired={Boolean(checkBoxSelectFunc)}
                    colSpan={colSpan}
                    isLoading={isLoading}
                  />
                );
              })}
              {emptyRows > 0 &&
                isLoading &&
                new Array(emptyRows).fill(undefined).map((el, index) => {
                  const isItemSelected = isSelected(index);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row
                      rowNum={index}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      handleClick={handleClick}
                      row={{ rowData: new Array(head.length).fill({}) }}
                      key={index}
                      isLastRow={emptyRows - 1 === index}
                      isCheckBoxRequired={Boolean(checkBoxSelectFunc)}
                      colSpan={colSpan}
                      isLoading={isLoading}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {Boolean(pagination) && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pagination?.totalRecords || 0}
          rowsPerPage={pagination?.rowsPerPage || 0}
          page={pagination?.page || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

// for tetsing purpose

/* <VegaTableV4
  data={rows}
  head={['abc', 'def', 'ghi', 'klm', 'nop', 'six']}
  isLoading
  // checkBoxSelectFunc={() => {}}
  pagination={{
    rowsPerPage: 5,
    setRowsPerPage: () => {},
    page: 0,
    setPage: () => {},
  }}
  emptyRowsRequired
/> */

// const onSwitchChange = (
//   e: React.ChangeEvent<HTMLInputElement>,
//   data: any
// ) => {
//   console.log('id', data);
// };

// const rows: ITableDataProps[] = [
//   {
//     rowData: [
//       {
//         value: 'abc',
//         type: 'string',
//         cellSx: {
//           background: 'red',
//           '.MuiTypography-root': {
//             fontSize: '30px',
//           },
//           width: '300px',
//         },
//       },
//       {
//         value: 123,
//         type: 'number',
//       },
//       {
//         type: 'jsx',
//         children: <h3>testing</h3>,
//       },
//       {
//         type: 'link',
//         value: 'google',
//         linkHref: 'https://www.google.com',
//       },
//       {
//         type: 'status',
//         value: 'LIVE',
//         statusColor: 'green',
//       },
//       {
//         type: 'switch',
//         isSwitchOn: true,
//         value: 'Active',
//         onSwitchChange,
//       },
//     ],
//     collapseData: {
//       value: 'History',
//     },
//   },
//   {
//     rowData: [
//       {
//         value: 'abc',
//         type: 'string',
//       },
//       {
//         value: 123,
//         type: 'number',
//       },
//       {
//         type: 'jsx',
//         children: <h3>testing</h3>,
//       },
//       {
//         type: 'link',
//         value: 'google',
//         linkPath: '/test',
//       },
//       {
//         type: 'status',
//         value: 'DRAFT',
//         statusColor: 'yellow',
//       },
//       {
//         type: 'switch',
//         isSwitchOn: false,
//         value: 'Inactive',
//         onSwitchChange,
//       },
//     ],
//     collapseData: {
//       children: <h1 style={{ backgroundColor: 'red' }}>testing</h1>,
//     },
//   },
// ];
