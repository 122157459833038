/** @format */
//  If admin show view as checker
import {
  Cancel,
  CheckCircleOutlineRounded,
  ErrorOutlineOutlined,
  Receipt,
  RefreshRounded,
} from '@mui/icons-material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Loading, NoResults, VegaPage } from '../components/common';
import { VegaHorizontalSelectorDataType } from '../components/common/VegaHorizontalSelector';
import {
  ApprovedFilledIcon,
  ChangesRequestedIcon,
  CorporateCardIcon,
  CreditCardIcon,
  RePaymentIcon,
  TaxIcon,
  TransactionIcon,
  UserIcon,
  WalletIcon,
} from '../components/icon/Icons';
import AllPolicyModal from '../components/policy/AllPolicyModal';
import EntityCommentsModal from '../components/policy/EntityCommentsModal';
import PolicySelection from '../components/policy/PolicySelection';
import { SelectedPolicyCard } from '../components/policy/SelectedPolicyCard';
import SubmitToCheckerModal from '../components/policy/SubmitToCheckerModal';
import { BLUE, GREEN, ORANGE, PINK } from '../constants/style';
import { useMakerCheckerEntity } from '../providers/MakerCheckerEntityProvider';
import { usePolicy } from '../providers/PolicyProvider';
import { useProgramData } from '../providers/ProgramDataProvider';
import { EntityStatus, UserMakerCheckerRole } from '../types/entityAuth';
import { PolicyType, PolicySelections, AnyPolicyType } from '../types/policy';
import { useSnackbar } from '../providers/SnackbarProvider';

const POLICY_OPTIONS: VegaHorizontalSelectorDataType[] = [
  {
    id: PolicyType.BILLING,
    text: 'Billing',
    image: <CorporateCardIcon strokeColor={'#414141'} />,
    bgColor: BLUE.light,
    endIcon: <CheckCircleOutlineRounded color="success" />,
  },
  {
    id: PolicyType.MAD,
    text: 'MAD & TAD ',
    image: <CreditCardIcon strokeColor={'#414141'} />,
    bgColor: PINK.light,
    endIcon: <CheckCircleOutlineRounded color="success" />,
  },
  {
    id: PolicyType.ACCOUNT,
    text: 'Account',
    image: <UserIcon strokeColor={'#414141'} />,
    bgColor: ORANGE.light,
    endIcon: <CheckCircleOutlineRounded color="success" />,
  },
  {
    id: PolicyType.INTEREST,
    text: 'Interest',
    image: <Receipt color="primary" />,
    bgColor: GREEN.light,
    endIcon: <CheckCircleOutlineRounded color="success" />,
  },
  {
    id: PolicyType.CHARGES,
    text: 'Charges',
    image: <WalletIcon />,
    bgColor: BLUE.light,
    endIcon: <CheckCircleOutlineRounded color="success" />,
  },
  {
    id: PolicyType.TAX,
    text: 'Tax',
    image: <TaxIcon />,
    bgColor: PINK.light,
    endIcon: <RefreshRounded color="warning" />,
  },
  {
    id: PolicyType.REPAYMENT_WATERFALL,
    text: 'Repayment',
    image: <RePaymentIcon />,
    bgColor: ORANGE.light,
    endIcon: <CheckCircleOutlineRounded color="success" />,
  },
  {
    id: PolicyType.TRANSACTION_TO_INSTALLMENT,
    text: ' Transaction to EMI',
    image: <TransactionIcon />,
    bgColor: GREEN.light,
    endIcon: <CheckCircleOutlineRounded color="success" />,
  },
  {
    id: PolicyType.TRANSACTION,
    text: ' Transaction Limit',
    image: <TransactionIcon />,
    bgColor: GREEN.light,
    endIcon: <CheckCircleOutlineRounded color="success" />,
  },
];

interface ShowEntityCommentsModalData {
  show: boolean;
  entityId?: string;
}

const Policy = () => {
  const {
    policySelections,
    fetchPolicyOfType,
    addOrUpdatePolicy,
    updatePolicyEntityStatus,
    submitPoliciesToChecker,
    submitPoliciesToMaker,
    loading,
    fetchedPolicies,
    entityAuthStatuses,
  } = usePolicy();
  const { user } = useMakerCheckerEntity();
  const { selectedProgram, loading: programLoading } = useProgramData();
  const { setSnackbar } = useSnackbar();

  const [poliyOptions, setPolicyOptions] =
    useState<VegaHorizontalSelectorDataType[]>(POLICY_OPTIONS);
  const [selectedPolicyInTab, setSelectedInTab] =
    useState<VegaHorizontalSelectorDataType>({ ...POLICY_OPTIONS[0] });

  const [showAllPolicyModal, setShowAllPolicyModal] = useState<boolean>(false);
  const [showSubmitToCheckerModal, setShowSubmitToCheckerModal] =
    useState<boolean>(false);
  const [showEntityCommentsModalData, setShowEntityCommentsModalData] =
    useState<ShowEntityCommentsModalData>({
      show: false,
    });

  const handleSelectedPolicyChange = (
    selectedPolicy: VegaHorizontalSelectorDataType
  ) => {
    setSelectedInTab(selectedPolicy);
    if (
      !fetchedPolicies?.[selectedPolicy.id] &&
      selectedProgram?.programId &&
      !loading
    ) {
      fetchPolicyOfType(selectedPolicy.id as PolicyType);
    }
  };

  const getIconForPolicyStatus = (
    policyStatus?: EntityStatus,
    role?: UserMakerCheckerRole
  ) => {
    if (!policyStatus || !role) return null;

    if (role == UserMakerCheckerRole.MAKER) {
      switch (policyStatus) {
        case EntityStatus.DRAFT:
          return <ErrorOutlineOutlined color="info" />;
        case EntityStatus.APPROVED:
          return <ApprovedFilledIcon />;
        case EntityStatus.CHANGES_REQUESTED:
          return <ChangesRequestedIcon />;
        case EntityStatus.REJECTED:
          return <Cancel color="error" />;
      }
    }

    if (role == UserMakerCheckerRole.CHECKER) {
      switch (policyStatus) {
        case EntityStatus.DRAFT:
          return <ErrorOutlineOutlined color="info" />;
        case EntityStatus.DRAFT_APPROVED:
          return <ApprovedFilledIcon />;
        case EntityStatus.DRAFT_REJECTED:
          return <Cancel color="error" />;
        case EntityStatus.APPROVED:
          return <ApprovedFilledIcon />;
        case EntityStatus.CHECKER_PENDING:
          return <ChangesRequestedIcon />;
        case EntityStatus.REJECTED:
          return <Cancel color="error" />;
      }
    }

    return null;
  };

  function updatePolicyStatusIcon() {
    const updatedPolicyOptions = poliyOptions.map((item) => {
      const currentPolicy = policySelections[item.id as keyof PolicySelections];
      const policyId = currentPolicy?.policy_id;
      if (policyId) {
        const policyStatus = entityAuthStatuses.get(policyId);
        const icon = getIconForPolicyStatus(policyStatus?.status, user?.role);
        return {
          ...item,
          endIcon: icon,
        };
      }
      return {
        ...item,
        endIcon: null,
      };
    });
    setPolicyOptions(updatedPolicyOptions);
  }

  function onPastCommentsClick(entityId?: string) {
    setShowEntityCommentsModalData({
      show: true,
      entityId: entityId,
    });
  }

  async function updatePolicyStatus(data: {
    updatedStatus: EntityStatus;
    policy: AnyPolicyType;
  }) {
    const policyId = data.policy.policy_id;
    if (!policyId) return;
    await updatePolicyEntityStatus({
      policyId: policyId,
      updatedStatus: data.updatedStatus,
    });
  }

  useEffect(() => {
    updatePolicyStatusIcon();
  }, [entityAuthStatuses]);

  useEffect(() => {
    updatePolicyStatusIcon();
  }, [policySelections]);

  if (programLoading) return <Loading />;
  if (!selectedProgram?.programId)
    return <NoResults text="No program selected" />;

  // needs to be removed when we an idea abt what to do with checker modal
  const showChekerModalNotAvailError = () => {
    setSnackbar('Issue submitting policies to checker: Not implemented');
    setShowSubmitToCheckerModal(false);
  };

  return (
    <>
      <VegaPage>
        <PolicySelection
          selected={selectedPolicyInTab}
          setSelected={handleSelectedPolicyChange}
          policies={poliyOptions}
          onAllPolicyClick={() => {
            setShowAllPolicyModal(true);
          }}
          onSubmitToMakerClick={submitPoliciesToMaker}
          onSubmitToCheckerClick={() => setShowSubmitToCheckerModal(true)}
        />
        <SelectedPolicyCard
          isLoading={loading}
          selectedPolicy={selectedPolicyInTab.id}
          addPolicy={(policy) =>
            addOrUpdatePolicy(policy, selectedPolicyInTab.id)
          }
          policySelections={policySelections}
          approvePolicy={(policy) => {
            updatePolicyStatus({
              updatedStatus: EntityStatus.DRAFT_APPROVED,
              policy: policy,
            });
          }}
          rejectPolicy={(policy) => {
            updatePolicyStatus({
              updatedStatus: EntityStatus.DRAFT_REJECTED,
              policy: policy,
            });
          }}
          onPastCommentsClick={onPastCommentsClick}
        />

        <SubmitToCheckerModal
          onKeepEditingClick={() => {}}
          onSubmitToCheckerClick={submitPoliciesToChecker}
          // onSubmitToCheckerClick={showChekerModalNotAvailError}
          onClose={() => setShowSubmitToCheckerModal(false)}
          open={showSubmitToCheckerModal}
          policies={policySelections}
        />

        <AllPolicyModal
          onClose={() => setShowAllPolicyModal(false)}
          open={showAllPolicyModal}
          programName={selectedProgram.name}
          policies={policySelections}
        />

        <EntityCommentsModal
          open={showEntityCommentsModalData.show}
          onClose={() =>
            setShowEntityCommentsModalData({
              show: false,
              entityId: undefined,
            })
          }
          title={'Comments by Checker'}
          entityId={showEntityCommentsModalData.entityId}
        />
      </VegaPage>
    </>
  );
};

export default Policy;
