import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../../../../common/Typography';
import VegaTableV4 from '../../../../../../common/v4/VegaTableV4';
import { IBillResponseData } from '../../../../../../../types/bill';
import { useEMIsForBillListQuery } from '../../../../../../../store/queries/billQueries';
import VegaNoResults from '../../../../../../common/VegaNoResults';
import { getEMIsForBillStructuredData } from '../../../../supportUtils';

interface IBillDetailsEMIProps {
  data: IBillResponseData | undefined;
}

const BillDetailsEMI = ({ data }: IBillDetailsEMIProps) => {
  const { data: EMIDataList, isFetching } = useEMIsForBillListQuery({
    billId: data?.id || '',
  });

  const structuredTableData = getEMIsForBillStructuredData(EMIDataList);

  return (
    <Stack gap={1.5}>
      <PageHeadingTypo>EMIs</PageHeadingTypo>
      {!Boolean(EMIDataList?.length) && !isFetching ? (
        <VegaNoResults errorText="No EMIs found" />
      ) : (
        <VegaTableV4
          head={[
            'Loan ID',
            'EMI ID',
            'Billing Cycle - Start Date',
            'Billing Cycle - End Date',
            'EMI Amount',
            'EMI Status',
            'EMI Payment Status',
          ]}
          data={structuredTableData}
          isLoading={isFetching}
        />
      )}
    </Stack>
  );
};

export default BillDetailsEMI;
