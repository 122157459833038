import SearchIcon from '../../icon/SearchIcon';
import VegaTextfieldV4, { VegaTextfieldV4Props } from './VegaTextfieldV4';
import { NEUTRALS, QUICK_ACCESS } from '../../../constants/style';
import CloseIcon from '@mui/icons-material/Close';

function VegaSearchfieldV4({
  sx,
  wrapperProps,
  InputProps,
  onIconClick,
  onClear,
  value,
  standardWidth,
  ...rest
}: VegaTextfieldV4Props & { onIconClick?: () => void; onClear?: () => void }) {
  return (
    <VegaTextfieldV4
      placeholder="Search..."
      standardWidth={standardWidth ?? 240}
      type="text"
      value={value}
      InputProps={{
        sx: {
          borderRadius: '6px',
        },
        startAdornment: (
          <SearchIcon onClick={onIconClick} className="cursor-pointer" />
        ),
        endAdornment:
          onClear && value ? (
            <CloseIcon
              sx={{ color: NEUTRALS.grey[400], fontSize: 17 }}
              className="cursor-pointer"
              onClick={onClear}
            />
          ) : undefined,
        ...InputProps,
      }}
      sx={{
        minWidth: {
          xs: 0,
        },
        '& .MuiInputBase-root': {
          pl: 1.5,
          pr: 2,
          py: 1,
          gap: 1,
          bgcolor: 'white',
        },
        '& .MuiInputBase-input': {
          fontFamily: `Aspekta !important`,
          fontSize: '14px',
          lineHeight: '140%',
          color: QUICK_ACCESS.text[200],
          p: 0,
        },
        '& .MuiInputBase-input:placeholder': {
          color: NEUTRALS.grey[400],
        },
        ...sx,
      }}
      wrapperProps={{
        ...wrapperProps,
      }}
      {...rest}
    />
  );
}

export default VegaSearchfieldV4;
