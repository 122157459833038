import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { GREEN, GREY } from '../../../constants/style';
import { IReportConfig } from '../../../types/reports';
import { VegaTextField } from '../../common';
import VegaSelect from '../../common/VegaSelect';

type IScheduleTime = {
  hrs: number;
  mins: number;
  onChange: (key: string, val: any) => void;
};

const ScheduleTime = ({ hrs, mins, onChange }: IScheduleTime) => {
  return (
    <Box alignItems="center" display={'flex'} flexDirection="row" width={'60%'}>
      <Typography ml={2} mr={1}>
        At
      </Typography>
      <Box sx={{ width: '100px' }}>
        <VegaTextField
          key="hour"
          type="number"
          value={hrs}
          onChange={(e) => onChange('hours', Number(e.target.value))}
          maxNumber={24}
          minNumber={0}
        ></VegaTextField>
      </Box>
      <Box sx={{ width: '100px' }}>
        <VegaTextField
          key="mins"
          type="number"
          onChange={(e) => onChange('minutes', Number(e.target.value))}
          value={mins}
          sx={{ mx: 2 }}
          maxNumber={60}
          minNumber={0}
        ></VegaTextField>
      </Box>
    </Box>
  );
};

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

type IScheduleWeekly = {
  dayOfWeek: number;
  onChange: (key: string, val: any) => void;
};
const ScheduleWeekly = ({ dayOfWeek, onChange }: IScheduleWeekly) => {
  return (
    <Box>
      <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
        {DAYS.map((day, index) => {
          return (
            <Box>
              <FormControlLabel
                key={index}
                sx={{ display: 'flex' }}
                control={
                  <Checkbox
                    color="secondary"
                    checked={index === dayOfWeek}
                    onChange={(e) =>
                      onChange('dayOfWeek', {
                        index: index,
                        checked: e.target.checked,
                      })
                    }
                    inputProps={{ 'aria-label': 'dayOfWeek' }}
                    sx={{
                      color: GREY.dark,
                      '&.Mui-checked': {
                        color: GREEN.dark,
                      },
                      m: 2,
                    }}
                  />
                }
                label={day}
              />
            </Box>
          );
        })}
      </FormGroup>
    </Box>
  );
};

const DAYSOFMONTH = Array.from({ length: 31 }, (_, i) => i + '');

interface IScheduleMonthly extends IScheduleTime {
  dayOfMonth: number;
}

const ScheduleMonthly = ({
  dayOfMonth,
  hrs,
  mins,
  onChange,
}: IScheduleMonthly) => {
  return (
    <Box
      display={'flex'}
      flexDirection="row"
      sx={{
        marginTop: '10px',
      }}
    >
      <VegaSelect
        size="small"
        key={'DayOfMonth'}
        selected={dayOfMonth + ''}
        onChange={(selected: string) =>
          onChange('dayOfMonth', Number(selected))
        }
        options={DAYSOFMONTH}
      ></VegaSelect>
      <ScheduleTime hrs={hrs} mins={mins} onChange={onChange} />
    </Box>
  );
};

type IScheduleReportTime = {
  initialData: {
    frequency: 'DAILY' | 'WEEKLY' | 'MONTHLY';
    dayOfWeek: number;
    dayOfMonth: number;
    hours: number;
    minutes: number;
  };
  setReportConfig: React.Dispatch<React.SetStateAction<IReportConfig>> | null;
};

export type Props = {
  reportConfig: IReportConfig;
  setReportConfig: React.Dispatch<React.SetStateAction<IReportConfig>> | null;
};
// convert to array for daysOfWeek and daysOfMonth
// currently only single value
function ScheduleReportTime({ reportConfig, setReportConfig }: Props) {
  const onChange = (key: string, val: any) => {
    if (!setReportConfig) return;

    if (key === 'dayOfWeek') {
      setReportConfig((pre) => {
        let temp = pre.dayOfWeek;
        if (val.checked) {
          temp = val.index;
        } else {
          // by default on Mon
          temp = 0;
        }

        return {
          ...pre,
          dayOfWeek: temp,
        };
      });
      return;
    }

    setReportConfig((pre) => {
      return {
        ...pre,
        [key]: val,
      };
    });
  };

  return (
    <Box width={'100%'}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Box sx={{ width: '100px' }}>
          <VegaSelect
            size="small"
            key={'freq'}
            selected={reportConfig.frequency}
            onChange={(selected) => onChange('frequency', selected)}
            options={['DAILY', 'WEEKLY', 'MONTHLY']}
            // sx={{ width: '156px' }}
          ></VegaSelect>
        </Box>

        {reportConfig?.frequency !== 'MONTHLY' && (
          <ScheduleTime
            hrs={reportConfig.hours}
            mins={reportConfig.minutes}
            onChange={onChange}
          />
        )}
      </Box>

      {reportConfig?.frequency === 'WEEKLY' && (
        <ScheduleWeekly
          dayOfWeek={reportConfig.dayOfWeek}
          onChange={onChange}
        />
      )}
      {reportConfig?.frequency === 'MONTHLY' && (
        <ScheduleMonthly
          dayOfMonth={reportConfig.dayOfMonth}
          hrs={reportConfig.hours}
          mins={reportConfig.minutes}
          onChange={onChange}
        />
      )}
    </Box>
  );
}

export default ScheduleReportTime;
