import { Add, AddCircleOutline, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { fetchStateOnPincode } from '../../../api/resources';
import { VegaCard, VegaPage } from '../../../components/common';
import VegaAspektaText from '../../../components/common/VegaAspektaText';
import VegaContainedButton from '../../../components/common/VegaContainedButton';
import VegaModal from '../../../components/common/VegaModal';
import VegaOutlineButton from '../../../components/common/VegaOutlineButton';
import VegaTextButton from '../../../components/common/VegaTextButton';
import { UploadBulkIcon } from '../../../components/icon/Icons';
import { BLUE, COMMON } from '../../../constants/style';
import {
  CustomerIdentityTypes,
  CustomerStatus,
  CustomerType,
  KycStatus,
} from '../../../enums/commons';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import CustomerService from '../../../services/CustomerService';
import { ICustomer } from '../../../types/customers';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  IBulkUploadProps,
  IPastUploadFilesProps,
} from '../../types/applicationManagement';
import { STATUS } from '../../variable';
import {
  bulkSaveCustomers,
  bulkUploadCustomers,
  fetchPastUploadForCustomers,
} from '../api/cutomer';
import AddUser from '../components/MCCAplicationManagement/AddUser';
import BulkUpload from '../components/MCCAplicationManagement/BulkUpload';
import CreateNewApplication, {
  ICreateApplication,
} from '../components/MCCAplicationManagement/CreateNewApplication';
import ListOfMCCcustomer from '../components/MCCAplicationManagement/ListOfMCCcustomer';
import PastUpload from '../components/MCCAplicationManagement/PastUpload';
import MCCFilterSection from '../components/MCCUserManagement/MCCFilterSection';
import ReviewEntity from '../components/MCCAplicationManagement/ReviewEntity';
import SubmitFormWrapper from '../components/SubmitFormWrapper';
import BulkUploadWrapper from '../components/BulkUploadWrapper';
import { useForm } from 'react-hook-form';
import { BULK_UPLOAD_TEMPLATE } from '../../constants';
import {
  FileValidatorBuilder,
  FileExtension,
} from '../../../utils/FileValidator';

export interface IUserProfileProps {
  firstName: string;
  lastName: string;
  address: string;
  dob: string;
  passportNo: string;
  panNo: string;
  mobileNumber: string;
  emailId: string;
  passportFile?: File | null;
  panFile?: File | null;
  kycFile?: File | null;
  ticketFile?: File | null;
  visaFile?: File | null;
  idType: CustomerIdentityTypes;
  idValue: string;
  state: string;
  city: string;
  pincode: string;

  // declaration: any;
}

const INITIAL_USER_DATA: IUserProfileProps = {
  firstName: '',
  lastName: '',
  address: '',
  dob: '',
  passportNo: '',
  panNo: '',
  mobileNumber: '',
  emailId: '',
  passportFile: null,
  panFile: null,
  kycFile: null,
  ticketFile: null,
  visaFile: null,
  idType: CustomerIdentityTypes.PAN,
  idValue: '',
  state: '',
  city: '',
  pincode: '',
  // declaration: '',
};

export interface IFilterProps {
  mobile: string;
  status?: string;
}

const bulkUploadProps: IBulkUploadProps = {
  filename: '',
  invalidEntriesCount: null,
  invalidEntriesFileName: '',
  invalidEntriesFileUrl: '',
  totalEntries: null,
  uploadDate: '',
  validEntriesCount: null,
  validEntriesFileName: '',
  validEntriesFileUrl: '',
};

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([FileExtension.EXCEL, FileExtension.CSV])
  .withMultipleExtensionsCheck()
  .withSvgFileScan()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const MCCAplicationManagement = () => {
  let { clientId, mccPlatformId: branchId, scope } = useClientAuth();
  const roles = scope.scope[Object.keys(scope.scope)[0]];
  let branchIdFromRole = roles[Object.keys(roles)[0]];
  const bId = Object.keys(branchIdFromRole)[0];
  if (!branchId) {
    branchId = bId;
  }
  const { setSnackbar } = useSnackbar();

  const [customers, setCustomers] = useState<any[]>([]);
  const [pastUploadFiles, setPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [passedPastUploadFiles, setPassedPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [failedPastUploadFiles, setFailedPastUploadFiles] = useState<
    IPastUploadFilesProps[]
  >([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filterCustomerOptions, setFilterCustomerOpts] = useState<IFilterProps>(
    {
      mobile: '',
      status: 'ALL',
    }
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [userProfile, setUserProfile] =
    useState<IUserProfileProps>(INITIAL_USER_DATA);

  const [open, setOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEmptyKey, setIsEmptyKey] = useState<boolean>(false);
  const [toggleButton, setToggleButton] = useState<boolean>(false);
  const [openPastUpload, setOpenPastUpload] = useState<boolean>(false);
  const [openEntities, setOpenEntities] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [bulkUploadData, setBulkUploadData] = useState<IBulkUploadProps>({
    ...bulkUploadProps,
  });

  const hookForm = useForm<ICreateApplication>({
    defaultValues: {
      gender: 'MALE',
    },
  });

  const filteredCustomers = useMemo(() => {
    return customers.filter((cus) => {
      return (
        (cus?.emailId
          ?.toLowerCase()
          ?.includes(filterCustomerOptions.mobile.toLowerCase()) ||
          cus.mobileNumber
            .toLowerCase()
            .includes(filterCustomerOptions.mobile.toLowerCase())) &&
        (filterCustomerOptions.status === 'ALL' ||
          filterCustomerOptions.status === cus.makerCheckerStatus)
      );
    });
  }, [filterCustomerOptions, customers]);

  const handleFilter = (name: string, value: any) => {
    setFilterCustomerOpts((pre) => ({ ...pre, [name]: value }));
  };

  const onOpen = () => {
    setOpen(!open);
    setSelectedFile(null);
  };

  const onEntitiesOpen = () => {
    setOpenEntities(!openEntities);
    setSelectedFile(null);
  };
  const onPastOpen = () => {
    setOpenPastUpload(!openPastUpload);
    setSelectedFile(null);
  };

  const toggleUser = () => {
    setIsOpen(!isOpen);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert to MB
      if (
        fileType === 'text/csv' ||
        fileType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        if (fileSize <= 2) {
          setSelectedFile(file || null);
        } else {
          setError('File size exceeds 2MB. Please upload a smaller file.');
        }
      } else {
        setError('Please upload only CSV or XLSX files.');
      }
    }
  };

  const handleOnError = (message: string | null) => {
    setSelectedFile(null);
    setError(message ?? 'Something went wrong');
  };

  const onFileProceed = () => {
    if (!selectedFile) {
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    console.log('onFileProceed', selectedFile);

    bulkUploadCustomers(formData).then(async (res) => {
      setBulkUploadData(res.data);
      setFileName(res.data.validEntriesFileName);
      setToggleButton(true);
    });
  };

  const onFileSave = (url: string) => {
    console.log('onFileProceed', selectedFile);
    if (!url) {
      return;
    }

    const obj = {
      branchId,
      fileName,
      url,
    };

    bulkSaveCustomers(obj).then(async (res) => {
      onEntitiesOpen();
      setBulkUploadData({ ...bulkUploadProps });
      getCustomerList();
    });
  };

  const onApplicationSubmit = async (userData: ICreateApplication) => {
    userData.gender.substring(0, 1).toUpperCase();

    try {
      // let passportNo = '';
      // try {
      //   const passRes = await passportImageValidation({
      //     email: 'admin@signzy.com',
      //     imagesUrl: [],
      //   });
      //   passportNo = passRes.result.passportNumber;
      // } catch (err) {
      //   setSnackbar('Error in passport validation ', 'error');
      //   setIsLoading(false);

      //   return;
      // }

      // const pincodeRes = await fetchStateOnPincode(userData.pincode);
      // if (pincodeRes.data.records.length === 0) {
      //   setSnackbar('Not a valid pincode', 'error');
      //   setIsLoading(false);
      //   return;
      // }
      // const pincodeData = pincodeRes.data.records[0];

      if (clientId) {
        const customer = await CustomerService.createCustomer({
          clientId,
          dob: dayjs(userData.dob).format('YYYY-MM-DD'),
          branchId,
          countryCode: '+91',
          identity: [
            {
              idNumber: userData.panNo,
              idType: CustomerIdentityTypes.PAN,
              idVerified: 'true',
            },
            // {
            //   idNumber: passportNo,
            //   idType: CustomerIdentityTypes.PASSPORT,
            //   idVerified: 'true',
            // },
          ],
          firstName: userData.firstName,
          lastName: userData.lastName,
          mobileNumber: userData.mobileNumber,
          emailId: userData.emailId,
          gender: userData.gender.substring(0, 1).toUpperCase(),
          status: CustomerStatus.DRAFT,
          customerType: CustomerType.INDIVIDUAL,
          kycStatus: KycStatus.VERIFIED,
          currentAddress: {
            line1: userData.address,
            pincode: userData.pincode,
            city: userData.city,
            state: userData.state,
            country: userData.country,
          },
        });

        // upload docs
        await CustomerService.uploadCustomerDocs({
          customerId: customer.customerId,
          // customerId: '7766de60-625d-4aa5-8ab0-353e28395a61',
          customerKycIdentityType: CustomerIdentityTypes.AADHAAR,
          kycFile: userData.kycFile,
          panFile: userData.panFile,
          passportFile: userData.passportFile,
          ticketFile: userData.ticketFile,
          visaFile: userData.visaFile,
        });
      }
      getCustomerList();
      setSnackbar('Customer Added', 'success');
      setIsLoading(false);
    } catch (err) {
      setSnackbar(
        getErrorMessageFromErrorObj(err, 'Error while creating the customer '),
        'error'
      );
      setIsLoading(false);
    }
  };

  const onFetchPastUpload = () => {
    fetchPastUploadForCustomers(branchId).then((res) => {
      const singleArray = [
        ...res.data.completed,
        ...res.data.unknown,
        ...res.data.failed,
      ];
      setPastUploadFiles(singleArray);

      setPassedPastUploadFiles(res.data.completed);

      setFailedPastUploadFiles([...res.data.unknown, ...res.data.failed]);
    });
    onPastOpen();
  };

  const getCustomerList = async () => {
    setListLoading(true);
    CustomerService.listCustomers({
      clientId: clientId || '',
      entityId: branchId,
      // status: ['DRAFT', 'UNDER_REVIEW', 'ACTIVE'],
    })
      .then((res) => {
        // const sorted: ICustomer[] = sorting(res);
        setCustomers(res);

        // setFilterData({ mobile: '' });
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  console.log('toggleButton', toggleButton);

  return (
    <>
      <VegaPage>
        <VegaCard noPad>
          <SubmitFormWrapper
            titleText="Add New Profile"
            submitText="Add User"
            cancelText="Clear"
            onSubmit={hookForm.handleSubmit(onApplicationSubmit)}
            onClear={hookForm.reset}
            toggle={toggleUser}
            isOpen={isOpen}
            loading={isLoading}
            disabled={isEmptyKey}
          >
            {/* <AddUser
              state={userProfile}
              handleChange={handleChange}
              setIsEmptyKey={setIsEmptyKey}
            /> */}

            <CreateNewApplication
              onSubmit={onApplicationSubmit}
              loading={isLoading}
              hookForm={hookForm}
            />
          </SubmitFormWrapper>
        </VegaCard>
        <VegaCard>
          <BulkUploadWrapper
            onFetchPastUpload={onFetchPastUpload}
            onOpen={onOpen}
            templateUrl={BULK_UPLOAD_TEMPLATE.BRANCH_APPLICATION}
          />
        </VegaCard>

        <MCCFilterSection
          isStatusFilter
          statusOption={STATUS}
          state={filterCustomerOptions}
          handleChange={handleFilter}

          // onSearch={onSearch}
        />
        {customers.length ? (
          <ListOfMCCcustomer
            data={filteredCustomers}
            loading={listLoading}
            getCustomerList={getCustomerList}
          />
        ) : (
          <VegaCard>No Customer</VegaCard>
        )}
      </VegaPage>
      <VegaModal
        header={'Bulk Upload'}
        open={open}
        handleClose={onOpen}
        submitText={toggleButton ? 'Review Entity' : 'Process'}
        disabled={!selectedFile || error.length > 0}
        isButton={true}
        handleSubmit={
          toggleButton
            ? () => {
                onOpen();
                onEntitiesOpen();
                setToggleButton(false);
              }
            : onFileProceed
        }
        buttonIcon={<AddCircleOutline />}
      >
        <BulkUpload
          handleFileChange={handleFileChange}
          selectedFile={selectedFile}
          error={error}
          bulkUploadData={bulkUploadData}
          onError={(message) => {
            handleOnError(message);
          }}
          fileValidator={fileValidatorConfig}
        />
      </VegaModal>
      <VegaModal
        header={'Review Entity'}
        open={openEntities}
        handleClose={() => {
          onEntitiesOpen();
          setBulkUploadData({ ...bulkUploadProps });
        }}
        // submitText={getComponents(status).submitText}
        width={900}
        isButton={false}
        disabled={!selectedFile || error.length > 0}
        handleSubmit={() => {}}
        buttonIcon={<AddCircleOutline />}
      >
        <ReviewEntity
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          bulkUploadData={bulkUploadData}
          onSaveFile={onFileSave}
        />
      </VegaModal>
      <VegaModal
        header={'Past Upload'}
        open={openPastUpload}
        handleClose={onPastOpen}
        // submitText={getComponents(status).submitText}
        width={900}
        isButton={false}
        disabled={!selectedFile || error.length > 0}
        handleSubmit={() => {}}
        buttonIcon={<AddCircleOutline />}
      >
        <PastUpload
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          pastUploadFiles={pastUploadFiles}
          passedPastUploadFiles={passedPastUploadFiles}
          failedPastUploadFiles={failedPastUploadFiles}
          // bulkUploadData={bulkUploadData}
        />
      </VegaModal>
    </>
  );
};

export default MCCAplicationManagement;
