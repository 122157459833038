import React, { SVGProps } from 'react';

function NoResultsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={241}
      height={197}
      viewBox="0 0 241 197"
      fill="none"
      {...props}
    >
      <path
        d="M7.55859 90.4414V92.4897"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M2.95703 91.5508L4.63075 93.2737"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M1 96.1367H3.36661"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M2.76562 100.805L4.43934 99.082"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M7.55957 101.365V99.3164"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M10.9729 99.9983L9.60742 98.6328"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M13.7026 96.5859H11.6543"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M12.3382 91.8047L10.9727 93.1702"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M117.482 1V2.3655"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M113.385 5.09766H114.75"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M117.482 9.19363V7.82812"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M120.895 5.09766H119.529"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M38.2832 58.3516V60.3998"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M32.1387 64.4958H34.1869"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M38.2832 69.9584V67.9102"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M43.7436 64.4961H41.6953"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        d="M185.247 132.797C186.879 132.797 188.202 134.121 188.202 135.753C188.202 137.385 186.879 138.708 185.247 138.708C183.615 138.708 182.292 137.385 182.292 135.753"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.771 105.982C200.525 105.435 203.215 103.289 203.837 100.98C204.402 103.021 206.768 105.435 208.904 105.618C206.5 106.469 204.202 108.997 203.837 110.983C203.589 108.959 200.41 106.277 198.771 105.982Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.668 131.887C19.5455 131.614 20.8897 130.541 21.2009 129.387C21.4832 130.407 22.6662 131.614 23.7339 131.705C22.5323 132.13 21.3835 133.395 21.2009 134.388C21.0769 133.376 19.4876 132.035 18.668 131.887Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9527 36.913C34.9527 38.1792 33.9267 39.2048 32.6614 39.2048C31.3955 39.2048 30.3701 38.1792 30.3701 36.913C30.3701 35.6473 31.3955 34.6211 32.6614 34.6211C33.9267 34.6211 34.9527 35.6473 34.9527 36.913Z"
        fill="#D1D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.955 43.065C187.955 45.1806 186.24 46.8956 184.125 46.8956C182.009 46.8956 180.295 45.1806 180.295 43.065C180.295 40.9494 182.009 39.2344 184.125 39.2344C186.24 39.2344 187.955 40.9494 187.955 43.065Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M241.001 32.6871C241.001 34.3655 239.641 35.7257 237.964 35.7257C236.286 35.7257 234.926 34.3655 234.926 32.6871C234.926 31.0086 236.286 29.6484 237.964 29.6484C239.641 29.6484 241.001 31.0086 241.001 32.6871Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7749 174.599C77.7749 175.657 76.9176 176.515 75.8598 176.515C74.8026 176.515 73.9453 175.657 73.9453 174.599C73.9453 173.541 74.8026 172.684 75.8598 172.684C76.9176 172.684 77.7749 173.541 77.7749 174.599Z"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.6353 22.1864C76.6353 23.4526 75.6093 24.4783 74.344 24.4783C73.0787 24.4783 72.0527 23.4526 72.0527 22.1864C72.0527 20.9207 73.0787 19.8945 74.344 19.8945C75.6093 19.8945 76.6353 20.9207 76.6353 22.1864Z"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.081 195.814C133.617 196.371 131.979 195.635 131.422 194.171C130.865 192.707 131.599 191.067 133.064 190.51C134.528 189.953 136.166 190.688 136.723 192.153C137.281 193.617 136.545 195.257 135.081 195.814Z"
        stroke="#D2D8DF"
        strokeWidth={1.99432}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.208 141.646H78.2192C74.2785 141.646 71.0527 138.41 71.0527 134.456V54.6134C71.0527 50.6594 74.2785 47.4258 78.2192 47.4258H134.208C138.15 47.4258 141.376 50.6594 141.376 54.6134V134.456C141.376 138.41 138.15 141.646 134.208 141.646Z"
        fill="#E0E2EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.429 145.06H73.4399C69.4992 145.06 66.2734 141.824 66.2734 137.87V58.0275C66.2734 54.0735 69.4992 50.8398 73.4399 50.8398H129.429C133.371 50.8398 136.597 54.0735 136.597 58.0275V137.87C136.597 141.824 133.371 145.06 129.429 145.06Z"
        fill="#E8EBF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.78 147.755H68.6755C64.7265 147.755 61.4941 144.534 61.4941 140.596V61.0837C61.4941 57.146 64.7265 53.9258 68.6755 53.9258H124.78C128.731 53.9258 131.963 57.146 131.963 61.0837V140.596C131.963 144.534 128.731 147.755 124.78 147.755Z"
        fill="#D8DBEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.78 147.755H68.6755C64.7265 147.755 61.4941 144.534 61.4941 140.596V61.0837C61.4941 57.146 64.7265 53.9258 68.6755 53.9258H124.78C128.731 53.9258 131.963 57.146 131.963 61.0837V140.596C131.963 144.534 128.731 147.755 124.78 147.755Z"
        fill="#F1F2F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.693 78.1512H75.2527C74.0693 78.1512 73.1006 77.2296 73.1006 76.1037C73.1006 74.9763 74.0693 74.0547 75.2527 74.0547H116.693C117.876 74.0547 118.845 74.9763 118.845 76.1037C118.845 77.2296 117.876 78.1512 116.693 78.1512Z"
        fill="#E0E2EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.693 90.4403H75.2527C74.0693 90.4403 73.1006 89.5187 73.1006 88.3928C73.1006 87.2653 74.0693 86.3438 75.2527 86.3438H116.693C117.876 86.3438 118.845 87.2653 118.845 88.3928C118.845 89.5187 117.876 90.4403 116.693 90.4403Z"
        fill="#E0E2EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.693 102.729H75.2527C74.0693 102.729 73.1006 101.808 73.1006 100.682C73.1006 99.5559 74.0693 98.6328 75.2527 98.6328H116.693C117.876 98.6328 118.845 99.5559 118.845 100.682C118.845 101.808 117.876 102.729 116.693 102.729Z"
        fill="#E0E2EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.693 115.018H75.2527C74.0693 115.018 73.1006 114.097 73.1006 112.971C73.1006 111.843 74.0693 110.922 75.2527 110.922H116.693C117.876 110.922 118.845 111.843 118.845 112.971C118.845 114.097 117.876 115.018 116.693 115.018Z"
        fill="#E0E2EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.8838 127.307H75.262C74.0734 127.307 73.1006 126.386 73.1006 125.26C73.1006 124.133 74.0734 123.211 75.262 123.211H96.8838C98.0723 123.211 99.0452 124.133 99.0452 125.26C99.0452 126.386 98.0723 127.307 96.8838 127.307Z"
        fill="#E0E2EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.526 111.426C132.24 119.711 119.412 120.646 110.098 114.249C108.908 113.417 107.775 112.482 106.719 111.426C106.175 110.884 105.662 110.325 105.179 109.736C104.213 108.56 103.367 107.305 102.674 106.009C101.452 103.82 100.622 101.498 100.168 99.0968C98.7053 91.5675 100.893 83.4633 106.719 77.64C112.559 71.7994 120.664 69.6261 128.195 71.0749C130.595 71.5422 132.919 72.3726 135.109 73.58C136.407 74.2888 137.644 75.1349 138.822 76.1008C139.409 76.582 139.969 77.0962 140.511 77.64C141.569 78.6962 142.518 79.8272 143.334 81.0189C149.734 90.3289 148.797 103.157 140.526 111.426Z"
        fill="white"
        fillOpacity={0.1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.209 109.792C129.588 118.414 115.618 118.413 106.995 109.792C98.3839 101.181 98.3839 87.2082 107.006 78.5873C115.618 69.9761 129.588 69.9761 138.199 78.5873C146.82 87.2082 146.82 101.181 138.209 109.792ZM142.633 74.1586C131.567 63.093 113.636 63.093 102.57 74.1586C91.5064 85.2226 91.4968 103.165 102.561 114.231C112.632 124.299 128.417 125.207 139.519 116.94C140.604 116.132 141.648 115.225 142.644 114.231C143.639 113.235 144.545 112.19 145.352 111.106C153.618 100.002 152.702 84.228 142.633 74.1586Z"
        fill="#E0E2EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.181 155.943L185.716 156.407C182.643 159.482 177.61 159.482 174.535 156.407L146.156 128.029L157.802 116.383L186.181 144.761C189.256 147.836 189.256 152.868 186.181 155.943Z"
        fill="url(#paint0_linear_315_10163)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.964 110.238L154.35 118.624L148.396 124.576L140.012 116.19C141.118 115.365 142.182 114.44 143.2 113.425C144.215 112.41 145.14 111.345 145.964 110.238Z"
        fill="#E0E2EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.757 144.413L174.185 155.983L172.102 153.898L183.672 142.328L185.757 144.413Z"
        fill="#EF545F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.446 116.786L146.557 128.675L144.791 126.908L156.68 115.02L158.446 116.786Z"
        fill="url(#paint1_linear_315_10163)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.5 75.6232L103.344 104.778C102.246 102.81 101.5 100.722 101.094 98.5632L126.286 73.3711C128.443 73.7928 130.532 74.5378 132.5 75.6232Z"
        fill="white"
        fillOpacity={0.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.059 82.5511L110.538 112.286C109.409 111.543 108.335 110.707 107.333 109.761C106.817 109.275 106.33 108.776 105.873 108.249L137.778 78.1484C138.336 78.5806 138.867 79.0408 139.381 79.5274C140.383 80.4726 141.284 81.4846 142.059 82.5511Z"
        fill="white"
        fillOpacity={0.5}
      />
      <defs>
        <linearGradient
          id="paint0_linear_315_10163"
          x1={167.322}
          y1={95.2175}
          x2={124.991}
          y2={137.548}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8960" />
          <stop offset={1} stopColor="#FF62A5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_315_10163"
          x1={151.619}
          y1={108.192}
          x2={137.963}
          y2={121.847}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF404E" />
          <stop offset={1} stopColor="#FF62A5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default NoResultsIcon;
