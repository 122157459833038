import Box from '@mui/material/Box';
import React from 'react';
import { PRIMARY_COLOR, QUICK_ACCESS } from '../../constants/style';

type DueDateCalendarProps = {
  selectDate: any[];
  year?: number;
  month?: number;
};
function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}

function DueDateCalendar({
  selectDate,
  year = new Date().getFullYear(),
  month = new Date().getMonth() + 1,
}: DueDateCalendarProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '600px',
      }}
    >
      {[...Array(getDaysInMonth(year, month))].map((e, i) => {
        return (
          <Box
            sx={{
              width: {
                xs: '20px',
                sm: '45px',
              },
              height: {
                xs: '20px',
                sm: '45px',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              mx: 2.5,
              my: 2,
              cursor: 'pointer',
              bgcolor: selectDate.find((val: number) => val === i + 1)
                ? PRIMARY_COLOR.blue[50]
                : 'transparent',
              color: QUICK_ACCESS.text[200],
            }}
            key={i}
          >
            {i + 1}
          </Box>
        );
      })}
    </Box>
  );
}

export default DueDateCalendar;
