import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResponse } from '../../types/commonType';
import {
  getAPICommonFulfilledStatus,
  getAPICommonPendingStatus,
  getAPICommonRejectedStatus,
} from '../../utils/common.utils';
import { PaginatedResponse } from '../../utils/api';
import {
  IAccountClosureRecord,
  IAccountClosureRequestDetails,
} from '../../types/accountClosure';
import {
  cancelCloseAccountRequestAPI,
  closeAccountAPI,
  closeAccountRequestAPI,
  closeAccountRequestDetailsAPI,
  getAllCloseAccountRequestAPI,
} from '../../api/accountClosureAPIs';
import { STORE_SLICE } from '../../enums/commons';

interface IAccountClosureState {
  accountClosureRequestData: IResponse<{}>;
  accountClosureList: IResponse<PaginatedResponse<IAccountClosureRecord>>;
  accountClosureCancelRequestData: IResponse<{}>;
  accountClosureData: IResponse<{}>;
  accountClosureRequestDetails: IResponse<IAccountClosureRequestDetails>;
}

const initialState: IAccountClosureState = {
  accountClosureRequestData: {},
  accountClosureList: {},
  accountClosureCancelRequestData: {},
  accountClosureData: {},
  accountClosureRequestDetails: {},
};

const accountClosureSlice = createSlice({
  name: STORE_SLICE.ACCOUNT_CLOSURE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // account closure request - POST
    builder.addCase(
      closeAccountRequestAPI.pending,
      (state: IAccountClosureState) => {
        state.accountClosureRequestData.apiStatus = getAPICommonPendingStatus(
          state.accountClosureRequestData.apiStatus
        );
        state.accountClosureRequestData.apierror = undefined;
      }
    );
    builder.addCase(
      closeAccountRequestAPI.fulfilled,
      (state: IAccountClosureState, action: PayloadAction<IResponse<{}>>) => {
        if (action.payload.apierror) {
          state.accountClosureRequestData.apiStatus =
            getAPICommonRejectedStatus(
              state.accountClosureRequestData.apiStatus,
              action.payload.apierror?.debugMessage ||
                action.payload.apierror?.message ||
                'Error Requesting for Account Closure'
            );
          state.accountClosureRequestData.apierror = action.payload.apierror;
        } else {
          state.accountClosureRequestData.apiStatus =
            getAPICommonFulfilledStatus(
              state.accountClosureRequestData.apiStatus
            );
        }
      }
    );
    builder.addCase(
      closeAccountRequestAPI.rejected,
      (state: IAccountClosureState) => {
        state.accountClosureRequestData.apiStatus = getAPICommonRejectedStatus(
          state.accountClosureRequestData.apiStatus,
          'Error Requesting for Account Closure'
        );
      }
    );

    // list of all the account closure request - GET
    builder.addCase(
      getAllCloseAccountRequestAPI.pending,
      (state: IAccountClosureState) => {
        state.accountClosureList.apiStatus = getAPICommonPendingStatus(
          state.accountClosureList.apiStatus
        );
        state.accountClosureList.apierror = undefined;
      }
    );
    builder.addCase(
      getAllCloseAccountRequestAPI.fulfilled,
      (
        state: IAccountClosureState,
        action: PayloadAction<
          IResponse<PaginatedResponse<IAccountClosureRecord>>
        >
      ) => {
        if (action.payload.apierror) {
          state.accountClosureList.apiStatus = getAPICommonRejectedStatus(
            state.accountClosureList.apiStatus,
            action.payload.apierror?.debugMessage ||
              action.payload.apierror?.message ||
              'Error Getting for List of Account Closure Requests'
          );
          state.accountClosureList.apierror = action.payload.apierror;
          state.accountClosureList.payload = undefined;
        } else {
          state.accountClosureList.payload = action.payload.payload;
          state.accountClosureList.apiStatus = getAPICommonFulfilledStatus(
            state.accountClosureList.apiStatus
          );
        }
      }
    );
    builder.addCase(
      getAllCloseAccountRequestAPI.rejected,
      (state: IAccountClosureState) => {
        state.accountClosureList.apiStatus = getAPICommonRejectedStatus(
          state.accountClosureList.apiStatus,
          'Error Getting for List of Account Closure Requests'
        );
        state.accountClosureList.payload = undefined;
      }
    );

    // cancel account closure request - POST
    builder.addCase(
      cancelCloseAccountRequestAPI.pending,
      (state: IAccountClosureState) => {
        state.accountClosureCancelRequestData.apiStatus =
          getAPICommonPendingStatus(
            state.accountClosureCancelRequestData.apiStatus
          );
        state.accountClosureCancelRequestData.apierror = undefined;
      }
    );
    builder.addCase(
      cancelCloseAccountRequestAPI.fulfilled,
      (state: IAccountClosureState, action: PayloadAction<IResponse<{}>>) => {
        if (action.payload.apierror) {
          state.accountClosureCancelRequestData.apiStatus =
            getAPICommonRejectedStatus(
              state.accountClosureCancelRequestData.apiStatus,
              action.payload.apierror?.debugMessage ||
                action.payload.apierror?.message ||
                'Error Cancelling Account Closure Requests'
            );
          state.accountClosureCancelRequestData.apierror =
            action.payload.apierror;
        } else {
          state.accountClosureCancelRequestData.apiStatus =
            getAPICommonFulfilledStatus(
              state.accountClosureCancelRequestData.apiStatus
            );
        }
      }
    );
    builder.addCase(
      cancelCloseAccountRequestAPI.rejected,
      (state: IAccountClosureState) => {
        state.accountClosureCancelRequestData.apiStatus =
          getAPICommonRejectedStatus(
            state.accountClosureCancelRequestData.apiStatus,
            'Error Cancelling Account Closure Requests'
          );
      }
    );

    // close account - POST
    builder.addCase(closeAccountAPI.pending, (state: IAccountClosureState) => {
      state.accountClosureData.apiStatus = getAPICommonPendingStatus(
        state.accountClosureData.apiStatus
      );
      state.accountClosureData.apierror = undefined;
    });
    builder.addCase(
      closeAccountAPI.fulfilled,
      (state: IAccountClosureState, action: PayloadAction<IResponse<{}>>) => {
        if (action.payload.apierror) {
          state.accountClosureData.apiStatus = getAPICommonRejectedStatus(
            state.accountClosureData.apiStatus,
            action.payload.apierror?.debugMessage ||
              action.payload.apierror?.message ||
              'Error Closing Account'
          );
          state.accountClosureData.apierror = action.payload.apierror;
        } else {
          state.accountClosureData.apiStatus = getAPICommonFulfilledStatus(
            state.accountClosureData.apiStatus
          );
        }
      }
    );
    builder.addCase(closeAccountAPI.rejected, (state: IAccountClosureState) => {
      state.accountClosureData.apiStatus = getAPICommonRejectedStatus(
        state.accountClosureData.apiStatus,
        'Error Closing Account'
      );
    });

    // close account request details - GET
    builder.addCase(
      closeAccountRequestDetailsAPI.pending,
      (state: IAccountClosureState) => {
        state.accountClosureRequestDetails.apiStatus =
          getAPICommonPendingStatus(
            state.accountClosureRequestDetails.apiStatus
          );
        state.accountClosureRequestDetails.apierror = undefined;
      }
    );
    builder.addCase(
      closeAccountRequestDetailsAPI.fulfilled,
      (
        state: IAccountClosureState,
        action: PayloadAction<IResponse<IAccountClosureRequestDetails>>
      ) => {
        if (action.payload.apierror) {
          state.accountClosureRequestDetails.apiStatus =
            getAPICommonRejectedStatus(
              state.accountClosureRequestDetails.apiStatus,
              action.payload.apierror?.debugMessage ||
                action.payload.apierror?.message ||
                'Error Retrieving Account Closure Request Details'
            );
          state.accountClosureRequestDetails.apierror = action.payload.apierror;
          state.accountClosureRequestDetails.payload = undefined;
        } else {
          state.accountClosureRequestDetails.payload = action.payload.payload;
          state.accountClosureRequestDetails.apiStatus =
            getAPICommonFulfilledStatus(
              state.accountClosureRequestDetails.apiStatus
            );
        }
      }
    );
    builder.addCase(
      closeAccountRequestDetailsAPI.rejected,
      (state: IAccountClosureState) => {
        state.accountClosureRequestDetails.apiStatus =
          getAPICommonRejectedStatus(
            state.accountClosureRequestDetails.apiStatus,
            'Error Retrieving Account Closure Request Details'
          );
        state.accountClosureRequestDetails.payload = undefined;
      }
    );
  },
});

export default accountClosureSlice.reducer;
