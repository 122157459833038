import * as React from 'react';
import { SVGProps } from 'react';
import { QUICK_ACCESS } from '../../constants/style';

const CardType2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M5.49923 15.4998H35.4992M11.4992 22.4998H16.4992M8.5 8.5H32.4994C34.7085 8.5 36.4993 10.2895 36.4994 12.4985L36.4998 27.5017C36.4999 29.7108 34.709 31.5 32.4998 31.5L8.50039 31.4997C6.29131 31.4997 4.50049 29.7089 4.50043 27.4998L4.5 12.5001C4.49994 10.2909 6.29082 8.5 8.5 8.5Z"
      stroke={props.stroke ?? QUICK_ACCESS.primary.blue}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CardType2Icon;
