import { SVGProps } from 'react';

const CalenderIconV5 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M4.83337 11.7022V11.6458M8.20837 11.7022V11.6458M8.20837 8.64579V8.58939M11.2084 8.64579V8.58939M2.58337 5.64575H13.0834M3.94052 1.33325V2.45839M11.5834 1.33325V2.45825M11.5834 2.45825H4.08337C2.84073 2.45825 1.83337 3.46561 1.83337 4.70825V12.2083C1.83337 13.451 2.84073 14.4583 4.08337 14.4583H11.5834C12.826 14.4583 13.8334 13.451 13.8334 12.2083L13.8334 4.70825C13.8334 3.46561 12.826 2.45825 11.5834 2.45825Z"
        stroke={props.stroke || '#676B76'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalenderIconV5;
