import { Box, Grid, Typography } from '@mui/material';
import { BLUE, BORDER_GRAY, NEW_PRIMARY } from '../../constants/style';
import { ICenterData } from '../../pages/ClientDashBoard';
import { NavigateIcon } from '../icon/Icons';
import VegaCard from './VegaCard';
import Vega_HeaderWrapper from './v3/Vega_HeaderWrapper';
import Vega_Button from './v3/Vega_Button';
import { useClientAuth } from '../../providers/ClientProvider';
import { DASHBOARD_ROUTES } from '../../routes';

interface ICenterProps {
  data: any[];
  isButton?: boolean;
  header: string;
  subHeader: string;
  buttonText?: string;
  selected?: ICenterData;
  onClick?: (e: any) => void;
  onSelect: (e: any) => void;
}

const VegaCenterSelector = ({
  data,
  isButton,
  header,
  subHeader,
  buttonText,
  selected,
  onClick,
  onSelect,
}: ICenterProps) => {
  const { canAccessSubModule } = useClientAuth();
  const filteredData = data.filter((el) =>
    canAccessSubModule(
      el.id.replace('/', ''),
      DASHBOARD_ROUTES.BACK_OFFICE.toUpperCase()
    )
  );

  return (
    <VegaCard>
      <Grid container alignItems={'center'} mb={1}>
        <Grid item xs={12} sm={6} md={7} lg={8} xl={9}>
          <Vega_HeaderWrapper text={header} subText={subHeader} />
        </Grid>
        {isButton && (
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
            xl={3}
            display="flex"
            alignItems={'center'}
            justifyContent="end"
          >
            <Vega_Button
              variant="outlined"
              bgColor="transaparant"
              sx={{
                py: 0.5,
              }}
              textColor={NEW_PRIMARY.GRAY}
              text={buttonText ?? ''}
              startIcon={<NavigateIcon />}
              onClick={onClick}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={1} columns={12}>
        {filteredData.map((value: any, idx: number) => (
          <Grid
            key={idx}
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            xl={2}
            sx={{ cursor: 'pointer' }}
          >
            <Box
              sx={{
                p: 2,

                borderRadius: '20px',
                height: '170px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                ...(selected?.text === value.text
                  ? {
                      border: `2px solid ${BLUE.lighter}`,
                      bgcolor: BLUE.lightest,
                    }
                  : { border: BORDER_GRAY }),
              }}
              onClick={() => onSelect(value)}
            >
              <div
                className=""
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexGrow: 1,
                }}
              >
                {value.image}
              </div>
              <Typography>{value.text}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </VegaCard>
  );
};

export default VegaCenterSelector;
