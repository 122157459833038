/** @format */

import { Grid } from '@mui/material';

import { useEffect, useState } from 'react';
import { listBinForClient } from '../../api/bin';
import { fetchListOfUsersForClient } from '../../api/roleManagement';
import {
  AddProgramFormState,
  AddProgramHelperFormState,
} from '../../pages/ClientDashBoard';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { AcceptFileType } from '../../types/acceptFileType';
import { VegaBin } from '../../types/client';
import {
  VegaCreditCardProductType,
  VegaForexCardProductType,
  VegaLoanProductType,
  VegaPrepaidCardProductType,
  VegaProgramCategoryType,
  VegaProgramIssuanceType,
  VegaProgramNetworkType,
} from '../../types/program';
import { VegaUser } from '../../types/user';
import { FileExtension, FileValidatorBuilder } from '../../utils/FileValidator';
import { VegaFileUpload, VegaTextField } from '../common';
import VegaSelect, { VegaSelectSelectOptionType } from '../common/VegaSelect';
import { MasterCardIcon, RupayIcon, VisaIcon } from '../icon/Icons';
import {
  checkIfAlphaNumeric,
  checkIfMatchesMaxLetters,
} from '../../utils/stringUtils';

interface IAddProgramProps {
  state: AddProgramFormState;
  handleChange: (
    name: string,
    value: string | File | null | undefined,
    ishelper?: boolean
  ) => void;
  helperState: AddProgramHelperFormState;
}

enum ValueType {
  ProgramName = 'programName',
  ProgramCode = 'programCode',
  ProductType = 'productType',
  Category = 'category',
  Maker = 'maker',
  Checker = 'checker',
  Network = 'network',
  CoBrandPartner = 'coBrandPartner',
  IssuanceType = 'issuanceType',
  TermsAndConditions = 'termsAndCondition',
  agreement = 'agreement',
  Bin = 'bin',
}

const LOAN_PRODUCTS = [
  VegaLoanProductType.HOME_LOAN,
  VegaLoanProductType.TWO_WHEELER_LOAN,
  VegaLoanProductType.EDUCATION_LOAN,
];

const CREDIT_CARD_PRODUCTS = [
  VegaCreditCardProductType.CHARGE_CARD,
  VegaCreditCardProductType.CORPORATE_CARD,
  VegaCreditCardProductType.RETAIL_UNSECURED,
  VegaCreditCardProductType.RETAIL_SECURED,
];

const FOREX_CARD_PRODUCTS = [
  VegaForexCardProductType.CORPORATE_CARD,
  VegaForexCardProductType.RETAIL_CARD,
];
const PREPAID_CARD_PRODUCTS = [
  VegaPrepaidCardProductType.FUEL_CARD,
  VegaPrepaidCardProductType.MEAL_CARD,
  VegaPrepaidCardProductType.TRAVEL_CARD,
  VegaPrepaidCardProductType.GIFT_CARD,
];

const CATEGORIES = [
  VegaProgramCategoryType.CREDIT_CARD,
  VegaProgramCategoryType.PREPAID_CARD,
  VegaProgramCategoryType.FOREX_CARD,
  VegaProgramCategoryType.LOANS,
];
export const NETWORKS: VegaSelectSelectOptionType[] = [
  {
    id: VegaProgramNetworkType.MASTERCARD,
    name: VegaProgramNetworkType.MASTERCARD,
    icon: <MasterCardIcon />,
  },
  {
    id: VegaProgramNetworkType.RUPAY,
    name: VegaProgramNetworkType.RUPAY,
    icon: <RupayIcon />,
  },
  {
    id: VegaProgramNetworkType.VISA,
    name: VegaProgramNetworkType.VISA,
    icon: <VisaIcon />,
  },
];

const ISSUANCE_TYPES = [
  VegaProgramIssuanceType.SELF,
  VegaProgramIssuanceType.CO_BRAND,
];

const fileValidatorConfig = new FileValidatorBuilder()
  .withAllowedExtensions([FileExtension.PDF])
  .withMultipleExtensionsCheck()
  .withMaxSizeInMB(1)
  .withMaxNameLength(50)
  .build();

const AddProgram = ({ state, handleChange, helperState }: IAddProgramProps) => {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [binList, setBinList] = useState<VegaBin[] | null>(null);
  const [team, setTeam] = useState<VegaUser[] | null>(null);
  const [issuanceTransitionEnded, setIssuanceTransitionEnded] = useState(false);

  const shouldShowCoBrandPartnerField =
    state.issuanceType &&
    state.issuanceType == VegaProgramIssuanceType.CO_BRAND;
  const isLoansSelected =
    state.category && state.category == VegaProgramCategoryType.LOANS;

  function updateValue(type: ValueType, updatedValue: string | File | null) {
    handleChange(type, updatedValue);
  }

  const udpateProgramCodeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const val = e.target.value;
    if (
      (checkIfAlphaNumeric(val) && checkIfMatchesMaxLetters(val, 10)) ||
      val === ''
    ) {
      updateValue(ValueType.ProgramCode, val?.toUpperCase());
      if (val && val.length < 3) {
        handleChange(
          ValueType.ProgramCode,
          'Program code must be between 3-10 characters',
          true
        );
      } else {
        handleChange(ValueType.ProgramCode, undefined, true);
      }
    }
  };

  function handleErrorOnFileSelect(
    type: ValueType,
    errorMessage: string | null
  ) {
    setSnackbar(errorMessage ?? 'Something went wrong', 'error');
    handleChange(type, null);
  }

  const getProductsForCategory = (category: VegaProgramCategoryType) => {
    switch (category) {
      case VegaProgramCategoryType.CREDIT_CARD:
        return CREDIT_CARD_PRODUCTS;
      case VegaProgramCategoryType.LOANS:
        return LOAN_PRODUCTS;
      case VegaProgramCategoryType.FOREX_CARD:
        return FOREX_CARD_PRODUCTS;
      case VegaProgramCategoryType.PREPAID_CARD:
        return PREPAID_CARD_PRODUCTS;
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchBinList();
    fetchClientTeams();
  }, []);

  useEffect(() => {
    fetchBinList();
    fetchClientTeams();
  }, [clientId]);

  const mapBinListToSelectOptionType = () => {
    return (
      binList?.map((item) => {
        return {
          id: item.id.toString(),
          name: `${item.binValue}  ${item.binName} `,
        };
      }) ?? []
    );
  };

  const mapVegaUserToSelectOptionType = () => {
    return (
      team?.map((item) => {
        return {
          id: item.userId,
          name: item.name,
        };
      }) ?? []
    );
  };

  const getSelectedBinFromId = (id?: number) => {
    if (!id) return '';
    const selectedBins = binList?.filter((item) => item.id == id);
    if (selectedBins && selectedBins.length > 0) {
      return selectedBins[0].id.toString();
    }
    return '';
  };

  const getSelectedUserFromId = (id?: string) => {
    if (!id) return '';
    const selectedUser = team?.filter((item) => item.userId == id);
    if (selectedUser && selectedUser.length > 0) {
      return selectedUser[0].userId;
    }
    return '';
  };

  function fetchClientTeams() {
    if (!clientId) {
      return;
    }
    fetchListOfUsersForClient(clientId)
      .then((response) => {
        const team = response.data as VegaUser[];
        setTeam(team);
      })
      .catch((error) => {});
  }

  async function fetchBinList() {
    if (!clientId) {
      return;
    }
    listBinForClient(clientId, 0, 100)
      .then((response) => {
        const binList = response.data.records as VegaBin[];
        setBinList(binList);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function didUpdateCategory(category: string) {
    if (category == VegaProgramCategoryType.LOANS) {
      updateValue(ValueType.Bin, '');
      updateValue(ValueType.Network, '');
    }
    updateValue(ValueType.Category, category);
    updateValue(ValueType.ProductType, '');
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
        <VegaTextField
          focused
          value={state.programName}
          onChange={(e) => updateValue(ValueType.ProgramName, e.target.value)}
          key="programName"
          label="Program Name"
          type={'text'}
          placeholder="Enter Program Name"
        />
      </Grid>

      <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
        <VegaTextField
          focused
          value={state.programCode}
          onChange={udpateProgramCodeValue}
          key={ValueType.ProgramCode}
          label="Program Code"
          type={'text'}
          placeholder="Enter Program Code"
          errorText={helperState.programCode}
          errorTextSx={{
            position: 'initial',
            paddingLeft: 2,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
        <VegaSelect
          focused
          placeholder="Select Product Type"
          label="Product Type"
          selected={state.category ?? ''}
          onChange={(e) => {
            didUpdateCategory(e);
          }}
          key="category"
          options={CATEGORIES}
        />
      </Grid>

      {state.category && (
        <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
          <VegaSelect
            focused
            placeholder="Select Product"
            label="Product"
            selected={state.productType}
            onChange={(e) => updateValue(ValueType.ProductType, e)}
            key="productType"
            options={getProductsForCategory(state.category) ?? []}
          />
        </Grid>
      )}

      {isLoansSelected == false && (
        <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
          <VegaSelect
            focused
            placeholder="Select Network"
            label="Network"
            selected={state.network ?? ''}
            onChange={(e) => updateValue(ValueType.Network, e)}
            key="sub_category"
            options={NETWORKS}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sm={shouldShowCoBrandPartnerField ? 6 : 12}
        sx={{
          mb: 2,
          transition: 'all 0.3s ease-out',
        }}
        onTransitionEnd={(e) => {
          if (e.propertyName == 'max-width') {
            setIssuanceTransitionEnded(true);
          }
        }}
      >
        <VegaSelect
          focused
          placeholder="Select Issuance Type"
          label="Issuance Type"
          selected={state.issuanceType ?? ''}
          onChange={(e) => {
            setIssuanceTransitionEnded(false);
            updateValue(ValueType.IssuanceType, e);
          }}
          options={ISSUANCE_TYPES}
        />
      </Grid>

      {issuanceTransitionEnded && shouldShowCoBrandPartnerField && (
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            mb: 2,
          }}
        >
          <VegaTextField
            focused
            value={state.coBrandPartner ?? ''}
            onChange={(e) =>
              updateValue(ValueType.CoBrandPartner, e.target.value)
            }
            key="name"
            label="Co-Brand Partner name"
            type={'text'}
            placeholder="Enter Co-Brand Partner Name"
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
        <VegaFileUpload
          placeholder="Agreement"
          file={state.agreement ?? null}
          onUpload={(file) => updateValue(ValueType.agreement, file)}
          fileValidator={fileValidatorConfig}
          onError={(message) => {
            handleErrorOnFileSelect(ValueType.agreement, message);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
        <VegaFileUpload
          acceptFileType={AcceptFileType.PDF}
          placeholder="TnC"
          file={state.termsAndCondition ?? null}
          onUpload={(file) => updateValue(ValueType.TermsAndConditions, file)}
          fileValidator={fileValidatorConfig}
          onError={(message) => {
            handleErrorOnFileSelect(ValueType.TermsAndConditions, message);
          }}
        />
      </Grid>

      {isLoansSelected == false && (
        <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
          <VegaSelect
            size="medium"
            placeholder="Select Bin"
            label="Select Bin (Optional)"
            selected={getSelectedBinFromId(state.bin)}
            onChange={(e) => updateValue(ValueType.Bin, e)}
            options={mapBinListToSelectOptionType()}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
        <VegaSelect
          size="medium"
          label="Maker (Optional)"
          selected={getSelectedUserFromId(state.maker)}
          onChange={(e) => updateValue(ValueType.Maker, e)}
          options={mapVegaUserToSelectOptionType()}
        />
      </Grid>

      <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
        <VegaSelect
          size="medium"
          label="Checker (Optional)"
          selected={getSelectedUserFromId(state.checker)}
          onChange={(e) => updateValue(ValueType.Checker, e)}
          options={mapVegaUserToSelectOptionType()}
        />
      </Grid>
    </Grid>
  );
};

export default AddProgram;
