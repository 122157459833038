import * as React from 'react';
import { SVGProps } from 'react';

const ClockPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M10 9.59961L7.60001 8.79961V5.45445M14 7.99961C14 4.46499 11.1346 1.59961 7.60001 1.59961C4.06539 1.59961 1.20001 4.46499 1.20001 7.99961C1.20001 11.5342 4.06539 14.3996 7.60001 14.3996C8.01017 14.3996 8.41131 14.361 8.80001 14.2873M12.8 10.3996V12.3996M12.8 12.3996V14.3996M12.8 12.3996H14.8M12.8 12.3996H10.8"
      stroke={props.stroke || '#BA1C1C'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ClockPlus;
