import axios, { AxiosResponse } from 'axios';
import {
  LoanDto,
  LoanStatus,
  TransactionToEmiPreview,
} from '../types/transaction';
import { BASE_URL, INSTALLMENT } from './endpoints';
import { PaginatedResponse } from '../utils/api';
import { CommonRequestQuery } from '../types/commonType';

const INSTALLMENT_BASE_URL = BASE_URL + INSTALLMENT.BASE;

export async function listInstallmentsForAccount(
  data: {
    accountId: string;
    statusList?: LoanStatus[];
  } & CommonRequestQuery
): Promise<AxiosResponse<PaginatedResponse<LoanDto>>> {
  const endpoint = INSTALLMENT_BASE_URL + INSTALLMENT.LIST_FOR_ACCOUNT;

  try {
    const response = await axios.get(endpoint, {
      params: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function fetchLoanFromLoanId(data: {
  loanId: string;
}): Promise<AxiosResponse<LoanDto>> {
  const endpoint = INSTALLMENT_BASE_URL + INSTALLMENT.LOAN + '/' + data.loanId;

  try {
    const response = await axios.get(endpoint);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function fetchPreviewTransactionToEMI(data: {
  transactionId: string;
}): Promise<TransactionToEmiPreview> {
  const endpoint = INSTALLMENT_BASE_URL + INSTALLMENT.PREVIEW;

  try {
    const response = await axios.post(endpoint, data);
    if (response.data) {
      const preview = response.data as TransactionToEmiPreview;
      return preview;
    }
    return Promise.reject('No response data received');
  } catch (error) {
    throw error;
  }
}

export async function convertTransactionToEmi(data: {
  transactionId: string;
  tenure: number;
}) {
  const endpoint = INSTALLMENT_BASE_URL + INSTALLMENT.CONVERT_TO_EMI;
  try {
    const response = await axios.post(endpoint, data);
    return response;
  } catch (error) {
    throw error;
  }
}
