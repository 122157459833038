/** @format */

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useClickOutSide } from '../../constants/commonFunction';
import { BLUE, BORDER_GRAY, GREY } from '../../constants/style';
import { usePolicy } from '../../providers/PolicyProvider';
import { DEFAULT_BILLING_POLICY } from '../../types/defaultPolicy';
import { EntityAuthStatus, UserMakerCheckerRole } from '../../types/entityAuth';
import {
  BillingPolicyInterface,
  BILLING_POLICY_KEYS,
  PolicyComponentType,
  PolicyType,
} from '../../types/policy';
import { addNumberSuffix } from '../../utils/numberUtils';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import BillingPolicyFieldSelectors from './BillingPolicyFieldSelectors';
import PolicyCard from './PolicyCard';
import PolicyCommentCard from './PolicyCommentCard';
import { PolicyWrapper } from './PolicyWrapper';

export const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const BillingPolicy = ({
  initialValue,
  onSubmit,
  isLoading,
  onApprove,
  onPastCommentsClick,
}: PolicyComponentType) => {
  const {
    pddList,
    checkPolicyEditable,
    checkPolicyStatusUpdatable,
    entityAuthStatuses,
    addCommentOnPolicy,
    updateCommentOnPolicy,
    loggedInMakerCheckerUser,
    areAllPoliciesApproved,
    getDefaultPolicy,
  } = usePolicy();
  const [billingPolicy, setBillingPoilicy] =
    useState<BillingPolicyInterface | null>();
  const [errorEnd, setErrorEnd] = useState<any>('');
  const [settingShow, setSettingShow] = useState<boolean>(false);
  const [canEditPolicy, setCanEditPolicy] = useState<boolean>(false);
  const [canUpdatePolicyStatus, setCanUpdatePolicyStatus] =
    useState<boolean>(false);
  const [entityAuthStatus, setEntityStatus] = useState<EntityAuthStatus | null>(
    null
  );
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const handleChange = (key: BILLING_POLICY_KEYS, value: any) => {
    if (!billingPolicy) return;
    let dateVal = parseInt(value);
    if (pddList) {
      const isValidDay = pddList.some((val: any) => val === dateVal);

      if (key === 'due_date') {
        if (isValidDay) {
          setBillingPoilicy({ ...billingPolicy, [key]: dateVal });
          setErrorEnd('');
        } else {
          setErrorEnd('Please enter Valid Day');
        }
      }
    }
    setBillingPoilicy({ ...billingPolicy, [key]: value });
  };

  let domNode = useClickOutSide(() => {
    setSettingShow(false);
  });

  async function getEntityStatusAndCheckIfCanEdit() {
    const policyId = billingPolicy?.policy_id;
    if (!policyId) {
      setCanEditPolicy(false);
      setCanUpdatePolicyStatus(false);
      setEntityStatus(null);
      return;
    }
    const response = await checkPolicyEditable(billingPolicy);
    const canUpdateStatus = await checkPolicyStatusUpdatable(billingPolicy);
    const entityAuthStatus = entityAuthStatuses.get(policyId);
    setCanUpdatePolicyStatus(canUpdateStatus);
    setCanEditPolicy(response);
    setEntityStatus(entityAuthStatus ?? null);
  }

  function didClickSubmit() {
    if (billingPolicy) {
      const updatedPolicy: BillingPolicyInterface = {
        ...billingPolicy,
      };
      onSubmit(updatedPolicy);
    }
  }

  function didClickApprove() {
    if (billingPolicy) onApprove(billingPolicy);
  }

  function addCommentOnEntity(comment: string) {
    const policyId = billingPolicy?.policy_id;
    if (!policyId) {
      return;
    }
    addCommentOnPolicy({
      policyId: policyId,
      comment: comment,
    });
  }

  function updateEntityComment(id: string, comment: string) {
    const policyId = billingPolicy?.policy_id;
    if (!policyId) {
      return;
    }
    updateCommentOnPolicy({
      policyId: policyId,
      commentId: id,
      comment: comment,
    });
  }

  const canShowCommentsCard = () => {
    if (areAllPoliciesApproved) return false;
    const isMaker =
      loggedInMakerCheckerUser?.role == UserMakerCheckerRole.MAKER;
    const areCommentsPresent =
      (entityAuthStatus?.commentsList ?? []).length > 0;
    return isMaker && areCommentsPresent;
  };

  function fetchDefaultPolicy() {
    getDefaultPolicy(PolicyType.BILLING)
      .then((defaultPolicy) => {
        if (defaultPolicy) {
          const billilngPolicy = defaultPolicy as BillingPolicyInterface;
          setBillingPoilicy(billilngPolicy);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  useEffect(() => {
    getEntityStatusAndCheckIfCanEdit();
  }, [billingPolicy, loggedInMakerCheckerUser]);

  useEffect(() => {
    if (billingPolicy?.due_date && pddList) {
      const isValid = pddList?.some(
        (val: any) => val === billingPolicy?.due_date
      );
      if (!isValid) {
        setErrorEnd('Invalid Date. Pick a date from allowed dates');
      } else {
        setErrorEnd('');
      }
    }
  }, [pddList, billingPolicy]);

  useEffect(() => {
    if (initialValue) setBillingPoilicy({ ...initialValue });
    else {
      fetchDefaultPolicy();
    }
  }, [initialValue]);

  return (
    <PolicyWrapper
      user={loggedInMakerCheckerUser}
      policyId={billingPolicy?.policy_id}
      policyView={
        <Box>
          <VegaConditionalWrapper
            condition={canShowCommentsCard()}
            ifTrue={
              <PolicyCommentCard
                onPastCommentClick={() => {
                  onPastCommentsClick(billingPolicy?.policy_id);
                }}
                comments={entityAuthStatus?.commentsList}
              />
            }
          />
          <PolicyCard
            onSaveClick={didClickSubmit}
            isLoading={isLoading}
            policy={initialValue}
            isSaveDisabled={canUpdatePolicyStatus == false}
            onApproveClick={didClickApprove}
            viewFor={loggedInMakerCheckerUser?.role}
            onCommentAddClick={addCommentOnEntity}
            policyEntityAuth={entityAuthStatus}
            onPastCommentsClick={onPastCommentsClick}
            onCommentEdit={updateEntityComment}
          >
            <Box sx={{ gap: 5, display: 'flex', flexDirection: 'column' }}>
              <BillingPolicyFieldSelectors
                text="Billing Cycle Type"
                value={billingPolicy?.billing_cycle_type}
                onChange={(value) => handleChange('billing_cycle_type', value)}
                isSelect
              />
              <BillingPolicyFieldSelectors
                text="Cycle End Day"
                subtext="default"
                name="cycle_end_date"
                value={billingPolicy?.cycle_end_date}
                onChange={(value: any) => {
                  let parseValue = parseInt(value);
                  if (parseValue > 27) parseValue = 27;
                  if (parseValue < 1) parseValue = 1;
                  handleChange('cycle_end_date', parseValue);
                }}
                postInputText="of the month"
                helperText={`The cycle starts on ${addNumberSuffix(
                  +Number(billingPolicy?.cycle_end_date) + 1
                )} of the month and ends on ${
                  billingPolicy?.cycle_end_date &&
                  addNumberSuffix(Number(billingPolicy?.cycle_end_date))
                } of the next month`}
                min={1}
                max={27}
                disabled={canEditPolicy == false}
              />
              <BillingPolicyFieldSelectors
                text="Bill Generation"
                subtext="Days past cycle end day"
                value={billingPolicy?.days_between_end_date_and_bill_date}
                onChange={(value) =>
                  handleChange('days_between_end_date_and_bill_date', value)
                }
                postInputText="days"
                helperText={`The bill shall be generated ${billingPolicy?.days_between_end_date_and_bill_date} day(s) after cycle end date`}
                min={1}
                max={14}
                disabled={canEditPolicy == false}
              />
              <BillingPolicyFieldSelectors
                text="Due Date"
                subtext="Default"
                value={billingPolicy?.due_date}
                error={errorEnd}
                onChange={(value: any) => {
                  let parseValue = parseInt(value);
                  if (parseValue > 27) parseValue = 27;
                  if (parseValue < 1) parseValue = 1;
                  handleChange('due_date', parseValue);
                }}
                postInputText="day"
                helperText={`The bill will be due on ${addNumberSuffix(
                  Number(billingPolicy?.due_date)
                )} of every month`}
                min={1}
                max={28}
                disabled={canEditPolicy == false}
              />
            </Box>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={3}></Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ position: 'relative' }} ref={domNode}>
                  <Typography
                    sx={{
                      color: canEditPolicy ? BLUE.lighter : 'gray',
                      cursor: canEditPolicy ? 'pointer' : '',
                    }}
                    onClick={() => {
                      if (canEditPolicy) setSettingShow(!settingShow);
                    }}
                  >
                    View all Due Dates{' '}
                  </Typography>
                  <Typography sx={{ fontSize: '16px' }}>
                    Pre-defined due dates
                  </Typography>
                  {settingShow && (
                    <Box
                      sx={{
                        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                        p: 2,
                        borderRadius: '5px',
                        position: 'absolute',
                        bottom: '30px',
                        left: '180px',
                        bgcolor: 'white',
                        width: '300px',
                        height: 'auto',
                        zIndex: 9999,
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: 'center',
                          pb: 1,
                          borderBottom: BORDER_GRAY,
                          color: GREY.grey_light,
                        }}
                      >
                        {month[currentMonth]} {currentYear}
                      </Typography>
                      <Grid container spacing={1} columns={7}>
                        {[...Array(27)].map((e, i) => {
                          return (
                            <Grid item sm={1} key={i}>
                              <Box
                                className={
                                  pddList &&
                                  pddList.map(
                                    (val: number) => val === i + 1 && 'active'
                                  )
                                }
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '5px',
                                  my: 1,
                                }}
                              >
                                {i + 1}
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </PolicyCard>
        </Box>
      }
    />
  );
};

export default BillingPolicy;
