import * as React from 'react';
import { SVGProps } from 'react';
import { PRIMARY_COLOR } from '../../constants/style';

const CardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M1.49996 4.64973H10.5M3.29996 6.74973H4.79997M2.4002 2.5498H9.60001C10.2627 2.5498 10.8 3.08664 10.8 3.74937L10.8001 8.25031C10.8002 8.91306 10.2629 9.4498 9.60015 9.44979L2.40031 9.44972C1.73759 9.44971 1.20034 8.91248 1.20032 8.24976L1.2002 3.74984C1.20018 3.08708 1.73744 2.5498 2.4002 2.5498Z"
      stroke={props.color || PRIMARY_COLOR.orange[600]}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CardIcon;
