import { Save, Warning } from '@mui/icons-material';
import { Grid, Stack, Box } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getBinSettings, saveBinSettings } from '../../api/card';
import { useBins } from '../../hooks/useBins';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { BinSettings, BinType, CardBin, NewCardBin } from '../../types/card';
import { VegaProgramNetworkType, VegaProgramType } from '../../types/program';
import { VegaPage } from '../common';
import VegaCardV2 from '../common/v2/VegaCardV2';
import VegaFormInputFieldV2 from '../common/v2/VegaFormInputField';
import VegaPrimaryButton from '../common/v2/VegaPrimaryButton';
import VegaSelectV2, { VegaSelectOptionV2 } from '../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../common/v2/VegaTextFieldV2';
import VegaTextV2 from '../common/v2/VegaTextV2';
import {
  MasterCardIconLarge,
  RupayIconLarge,
  VisaIconLarge,
} from '../icon/Icons';
import { CardHeading } from './CardHeading';

type Props = {
  selectedProgram: VegaProgramType | null;
};

function CardBinDetails({ selectedProgram }: Props) {
  const [cardsNumber, setCardsNumber] = useState<number>();
  const [allocatedBins, setAllocatedBins] = useState<NewCardBin[]>([]);
  const [selectedBin, setSelectedBin] = useState<CardBin | null>();
  const [binSettings, setBinSettings] = useState<BinSettings | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const { setSnackbar } = useSnackbar();
  const { fetchBinsForProgram, loading: loadingBins } = useBins();

  function fetchBinsForSelectedProgram() {
    const programId = selectedProgram?.programId;
    setBinSettings(null);
    setSelectedBin(null);
    setAllocatedBins([]);
    if (!programId) return;
    fetchBinsForProgram(programId, page, 100)
      .then((bins) => {
        console.log('yutyuy', bins);
        setAllocatedBins(bins);
        fetchSettings();
      })
      .catch((err) => {
        setAllocatedBins([]);
      });
  }

  function selectBin(binId: string) {
    const filteredBins = allocatedBins.filter(
      (item) => item.id == Number(binId)
    );
    if (filteredBins.length < 0) return;
    setSelectedBin(filteredBins[0]);
  }

  function isInputValid() {
    let isProgramIdValid = Boolean(selectedProgram?.programId);
    let isCardsNumberValid = cardsNumber != null && cardsNumber > 0;
    let isBinIdValid = selectedBin?.id != null;
    let isValid = isBinIdValid && isProgramIdValid && isCardsNumberValid;
    return isValid;
  }

  function fetchSettings() {
    const programId = selectedProgram?.programId;
    if (!programId) {
      return;
    }
    setLoading(true);
    getBinSettings({ programId: programId })
      .then((res) => {
        setBinSettings(res);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  }

  function onSave() {
    const programId = selectedProgram?.programId;
    const binid = selectedBin?.id;
    const cardAllocated = cardsNumber;
    if (
      !programId ||
      !binid ||
      !cardAllocated ||
      !cardAllocated ||
      cardAllocated <= 0
    ) {
      setSnackbar('Input is Invalid', 'error');
      return;
    }
    setLoading(true);
    saveBinSettings({
      binSettings: {
        programId: programId,
        binId: binid,
        cardAllocated: cardAllocated,
      },
    })
      .then((res) => {
        setCardsNumber(res.cardAllocated);
        setSnackbar('Bin Settings Saved');
      })
      .catch((err) => {
        console.error(err);
        setSnackbar('Failed to save Settings', 'error');
      })
      .finally(() => setLoading(false));
  }

  function didUpdateCardsAllocation(value: string) {
    const largestNumberSupported = 9999999999;
    const numberValue = Number(value);
    if (numberValue <= largestNumberSupported) {
      setCardsNumber(numberValue);
    }
  }

  const getBinTypeValue = () => {
    const binType = selectedBin?.binType;
    if (!binType) return '';
    return _.startCase(_.toLower(binType));
  };

  useEffect(() => {
    fetchBinsForSelectedProgram();
  }, [selectedProgram, page]);

  useEffect(() => {
    if (!binSettings) return;
    setCardsNumber(binSettings.cardAllocated);

    const filteredBins = allocatedBins.filter(
      (item) => item.id == binSettings.binId
    );

    if (filteredBins.length <= 0) {
      return;
    }
    setSelectedBin(filteredBins[0]);
  }, [binSettings]);

  return (
    <VegaPage>
      <VegaCardV2>
        <CardHeading text={'Program Details'} bold />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <VegaFormInputFieldV2 label={'Program Name'}>
              <VegaTextFieldV2 disabled value={selectedProgram?.name} />
            </VegaFormInputFieldV2>
          </Grid>
          <Grid item xs={3}>
            <VegaFormInputFieldV2 label={'Program Id'}>
              <VegaTextFieldV2 disabled value={selectedProgram?.programId} />
            </VegaFormInputFieldV2>
          </Grid>
        </Grid>
      </VegaCardV2>

      <VegaCardV2>
        <Grid container>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <CardHeading text={'Bin Details'} bold />
              <div>
                <VegaTextV2 variant="caption" style={{ fontWeight: 'bold' }}>
                  Bank Identification Number(BIN):{' '}
                </VegaTextV2>
                <VegaTextV2
                  text="BIN refers to the first six digits of a credit or debit card that identify the issuing bank or financial institution."
                  variant="caption"
                />
              </div>
              <div>
                <VegaTextV2 variant="caption" style={{ fontWeight: 'bold' }}>
                  BIN Range:{' '}
                </VegaTextV2>
                <VegaTextV2
                  text="9 digit after BIN indicates the range of card number that a BIN can issue"
                  variant="caption"
                />
              </div>
              <div>
                <VegaTextV2 variant="caption" style={{ fontWeight: 'bold' }}>
                  Check Digit:{' '}
                </VegaTextV2>
                <VegaTextV2
                  text="Last digit of the card number which is randomly generated to avoid error in number generation."
                  variant="caption"
                />
              </div>
              <Grid
                container
                rowSpacing={2}
                columns={5}
                style={{ marginLeft: '0' }}
              >
                <Grid item xs={2}>
                  <VegaFormInputFieldV2 label="Select Bin">
                    <VegaSelectV2
                      options={allocatedBinOptions(allocatedBins)}
                      value={selectedBin?.binValue ?? ''}
                      onChange={(e) => {
                        selectBin(e.target.value as string);
                      }}
                    />
                  </VegaFormInputFieldV2>
                </Grid>
                <Grid item xs={2} marginLeft={'8px'}>
                  <VegaFormInputFieldV2 label="Network">
                    <VegaSelectV2
                      disabled
                      hidePlaceholder
                      hideDropDownIcon
                      renderSelectedValue={() => {
                        return (
                          <div
                            style={{
                              minHeight: '28px',
                              maxHeight: '28px',
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '.2rem',
                            }}
                          >
                            {getLogoForProgramNetwork(selectedProgram?.network)}
                          </div>
                        );
                      }}
                    ></VegaSelectV2>
                  </VegaFormInputFieldV2>
                </Grid>
                <Grid item xs={2}>
                  <VegaFormInputFieldV2 label="Bin Type">
                    <VegaTextFieldV2 value={getBinTypeValue()} disabled />
                  </VegaFormInputFieldV2>
                </Grid>
                {selectedBin?.binType == BinType.SHARED && (
                  <Grid item xs={2} marginLeft={'8px'}>
                    <VegaFormInputFieldV2 label="No of cards">
                      <VegaTextFieldV2
                        value={cardsNumber == 0 ? '' : cardsNumber}
                        type={'number'}
                        onChange={(e) => {
                          didUpdateCardsAllocation(e.target.value);
                        }}
                      />
                    </VegaFormInputFieldV2>
                  </Grid>
                )}
              </Grid>
              {allocatedBins.length == 0 && (
                <Stack direction={'row'} spacing={2} alignItems="center">
                  <Warning color="error" />
                  <VegaTextV2
                    text="No bins allocated for program"
                    variant="caption"
                    color={'red'}
                  />
                </Stack>
              )}
            </Stack>
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Box
              style={{
                height: '250px',
                width: '388px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                height={'90%'}
                width={'90%'}
                src={require('./../../assets/images/bin_card_details.png')}
              />
            </Box>
          </Grid>
        </Grid>
      </VegaCardV2>

      <Stack direction={'row'} justifyContent="end" style={{ padding: 12 }}>
        <VegaPrimaryButton
          text="Save"
          startIcon={<Save />}
          disabled={isInputValid() == false}
          onClick={onSave}
          loading={loading || loadingBins}
        />
      </Stack>
    </VegaPage>
  );
}

export default CardBinDetails;

const getLogoForProgramNetwork = (network?: string) => {
  switch (network) {
    case VegaProgramNetworkType.MASTERCARD:
      return <MasterCardIconLarge />;
    case VegaProgramNetworkType.RUPAY:
      return <RupayIconLarge />;
    case VegaProgramNetworkType.VISA:
      return <VisaIconLarge />;
  }
};

const allocatedBinOptions = (allocatedBins: CardBin[]) => {
  console.log(
    '🚀 ~ file: CardBinDetails.tsx:328 ~ allocatedBinOptions ~ allocatedBins:',
    allocatedBins
  );
  const options = allocatedBins.map((item) => {
    const option: VegaSelectOptionV2 = {
      id: item.id.toString(),
      value: item.id.toString(),
      label: item.binValue,
    };
    return option;
  });
  return options;
};
