import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getCardById } from '../../../../api/card';
import { toLowerCase } from '../../../../constants/commonFunction';
import { CARD_NETWORK } from '../../../../mcc/types/Card';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { ChargebackService } from '../../../../services/ChargeBackService';
import {
  ChargebackType,
  CreateChargebackRequest,
  DisputeType,
} from '../../../../types/chargeBack';
import { TransactionDto } from '../../../../types/transaction';
import {
  VPButton,
  VPDrawer,
  VPSelect,
  VPTextField,
  VegaUploadNPreview,
} from '../../../../ui-components/common';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import VegaHeader from '../../../common/VegaHeader';
import { getLogoForNetwork } from '../../../icon/Icons';

interface IProps {
  triggerLabel: string;
  data: TransactionDto | null;
  open: boolean;
  handleClose: () => void;
}

export function RaiseADisputeDrawer({
  data,
  triggerLabel,
  open,
  handleClose,
}: IProps) {
  const { clientId } = useClientAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const [selectedNetwork, setSelectedNetwork] = useState<CARD_NETWORK>(
    CARD_NETWORK.MASTERCARD
  );
  const disputeReasonsQuery = useQuery({
    queryKey: ['disputes_list_reason'],
    queryFn: () =>
      ChargebackService.listNetworkChargebackDisputeReason({
        network: selectedNetwork,
        pageNumber: 0,
        pageSize: 100,
      }),
    enabled: !!selectedNetwork && open,
    select: (data) => {
      return data.records.reduce((pre, curr) => {
        return [
          ...pre,
          {
            name: `${curr.disputeReasonCode} - ${curr.reason}`,
            id: curr.id,
          },
        ];
      }, [] as any[]);
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const hookForm = useForm<CreateChargebackRequest>({
    mode: 'all',
    defaultValues: {
      network: selectedNetwork,
      disputeAmount: data?.amount || 0,
      transactionId: data?.id,
      clientId: clientId || '',
      disputeType: DisputeType.CHARGEBACK,
    },
  });

  const selectedChargeBackType = hookForm.watch('chargebackType');

  const onSubmit = () => {
    setLoading(true);
    const data = hookForm.getValues();

    const { disputeProofs, ...rest } = data;
    const finalObj = disputeProofs?.length ? data : rest;
    ChargebackService.createChargeback(finalObj)
      .then((res) => {
        setSnackbar('Successfully created chargeback');
        hookForm.reset();
        handleClose();
        // refetch transactions
      })
      .catch((err) => {
        setSnackbar('Successfully created chargeback'); // temporary
        hookForm.reset(); // temporary
        handleClose(); // temporary

        // setSnackbar(
        //   getErrorMessageFromErrorObj(err, 'Error creating chargeback'),
        //   'error'
        // );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (data?.cardId) {
      getCardById({ cardId: data.cardId }).then((res) => {
        setSelectedNetwork(res.network as CARD_NETWORK);
        return;
      });
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      disputeReasonsQuery.refetch();
    }
  }, [open]);

  return (
    <VPDrawer
      open={open}
      onClose={() => {
        handleClose();
      }}
      header={triggerLabel}
      footer={
        <Stack direction={'row'} justifyContent={'end'} flex={1} p={1}>
          <VPButton
            variant="contained"
            onClick={onSubmit}
            type="submit"
            disabled={!hookForm.formState.isValid}
            isLoading={loading}
            autoFocus={true}
          >
            {triggerLabel}
          </VPButton>
        </Stack>
      }
    >
      <Stack>
        <FormProvider {...hookForm}>
          <Stack spacing={2}>
            <Controller
              name="network"
              control={hookForm.control}
              rules={{
                required: 'Required',
              }}
              defaultValue={selectedNetwork}
              render={({ field }) => (
                <Stack>
                  <VegaHeader text="network" />
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    bgcolor={'rgba(246, 246, 246, 1)'}
                    p={1}
                    px={2}
                    sx={{
                      borderRadius: '9px',
                      height: '35px',
                    }}
                  >
                    <Typography>
                      {toLowerCase(selectedNetwork || '') || '- -'}
                    </Typography>
                    <Box>{getLogoForNetwork(selectedNetwork)}</Box>
                  </Stack>
                </Stack>
              )}
            />

            <Box>
              <Controller
                name="disputeReasonId"
                control={hookForm.control}
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <VPSelect
                    header="Select Reason category"
                    value={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    disabled={!disputeReasonsQuery.data?.length}
                    options={disputeReasonsQuery.data || []}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="chargebackType"
                control={hookForm.control}
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <VPSelect
                    header="select payment type"
                    value={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      if (event.target.value === ChargebackType.FULL_AMOUNT) {
                        hookForm.setValue('disputeAmount', data?.amount || 0);
                      }
                    }}
                    options={Object.keys(ChargebackType)}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="disputeAmount"
                control={hookForm.control}
                rules={{
                  required: 'Required',
                  max: {
                    value: parseInt(data?.amount + ''),
                    message: 'Max amount is ' + data?.amount,
                  },
                }}
                defaultValue={
                  selectedChargeBackType === ChargebackType.PARTIAL_AMOUNT
                    ? 0
                    : data?.amount || 0
                }
                render={({ field }) => (
                  <VPTextField
                    header="DISPUTE AMOUNT"
                    sx={
                      {
                        // bgcolor: 'rgba(246, 246, 246, 1)',
                      }
                    }
                    type="number"
                    {...field}
                    disabled={
                      selectedChargeBackType === ChargebackType.PARTIAL_AMOUNT
                        ? false
                        : true
                    }
                    error={!!hookForm.formState.errors.disputeAmount}
                    helperText={
                      hookForm.formState.errors.disputeAmount?.message
                    }
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="issue"
                control={hookForm.control}
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <VPTextField
                    header="Explain the issue in brief"
                    multiline
                    maxRows={3}
                    isTextArea
                    sx={{
                      bgcolor: 'rgba(246, 246, 246, 1)',
                    }}
                    {...field}
                  />
                )}
              />
            </Box>

            <Stack spacing={0.5}>
              <Controller
                name="disputeProofs"
                control={hookForm.control}
                render={({ field }) => (
                  <>
                    <VegaHeader text="Upload a document, if any" />
                    <VegaUploadNPreview
                      value={field.value || []}
                      onChange={field.onChange}
                      preview2
                      max={5}
                      accept={{
                        'application/pdf': ['.pdf'],
                      }}
                    />
                  </>
                )}
              />
            </Stack>
          </Stack>
        </FormProvider>
      </Stack>
    </VPDrawer>
  );
}
