import { GridCallbackDetails } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { VegaPage } from '../components/common';
import CountryCodeTable from '../components/Resources/CountryCodeTable';
import MccTable from '../components/Resources/MccTable';
import McgTable from '../components/Resources/McgTable';
import MerchantTable from '../components/Resources/MerchantTable';
import PincodeTable from '../components/Resources/PincodeTable';
import VegaHeaderV4 from '../components/common/v4/VegaHeaderV4';
import AddSquare02Icon from '../components/icon/AddSquare02Icon';
import Stack from '@mui/material/Stack';
import VegaTabV4 from '../components/common/v4/VegaTabV4';
import VegaSearchfieldV4 from '../components/common/v4/VegaSearchfieldV4';
import useDebounce from '../hooks/useDebounce';
import { SearchValueContext } from '../components/Resources/searchValueContext';
import AddOrEditMerchant from '../components/Resources/AddOrEditMerchant';
import VegaSelectV4 from '../components/common/v4/VegaSelectV4';
import {
  ResourceTab,
  filterResourceTabOptions,
  searchByOptions,
} from '../components/Resources/resourceUtils';
import VegaButtonV4 from '../components/common/v4/VegaButtonV4';

export interface ITileDataProps {
  id: string;
  text: string;
  image?: JSX.Element;
  bgColor?: string;
  color?: string;
  selected?: string;
}

export interface IProgramDataProps {
  program: string;
  data: ITileDataProps[];
}

export interface IResourcesProps {
  active?: any;
  data?: ITileDataProps[];
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
  page: number;
  pageSize: number;
  rowCount?: any;
  loading?: boolean;
}

const tabContents = {
  merchants: <MerchantTable />,
  mcc: <MccTable />,
  mcg: <McgTable />,
  country: <CountryCodeTable />,
  pinCode: <PincodeTable />,
};

const ActiveTab = ({ activeTab }: { activeTab: ResourceTab }) => {
  return tabContents[activeTab] || null;
};

const Resources = () => {
  const [activeTab, setActiveTab] = useState<ResourceTab>('merchants');
  const [isAddMerchantOpen, setIsAddMerchantOpen] = useState(false);
  const [activeSearchBy, setActiveSearchBy] = useState(
    searchByOptions['merchants'][0].id
  );
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebounce<string>(searchValue, 500);

  const onSelectedTabChanged = (
    event: React.SyntheticEvent,
    newValue: ResourceTab
  ) => {
    setActiveTab(newValue);
    setActiveSearchBy(searchByOptions[newValue][0].id);
    setSearchValue('');
  };

  const closeAddMerchantModal = () => {
    setIsAddMerchantOpen(false);
  };

  const searchQuery = useMemo(
    () => ({
      searchValue: debouncedSearch,
      searchBy: activeSearchBy,
    }),
    [debouncedSearch, activeSearchBy]
  );

  return (
    <VegaPage sx={{ gap: 3 }}>
      <VegaHeaderV4 title="Resources" sx={{ pt: 5 }} />

      <Stack px={3.25} gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <VegaTabV4
            tabData={filterResourceTabOptions}
            onChange={onSelectedTabChanged}
            value={activeTab}
          />
          <Stack direction="row" alignItems="center" gap={2}>
            <VegaSelectV4
              options={searchByOptions[activeTab]}
              value={activeSearchBy}
              onChange={(e) => setActiveSearchBy(e.target.value)}
              wrapperProps={{
                flex: 0.7,
              }}
              standardWidth={140}
            />
            <VegaSearchfieldV4
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {activeTab === 'merchants' && (
              <VegaButtonV4
                value="Add Merchant"
                startIcon={<AddSquare02Icon />}
                variant="contained"
                onClick={() => setIsAddMerchantOpen(true)}
              />
            )}
          </Stack>
        </Stack>
        <SearchValueContext.Provider value={searchQuery}>
          <ActiveTab activeTab={activeTab} />
        </SearchValueContext.Provider>
      </Stack>
      <AddOrEditMerchant
        isOpen={isAddMerchantOpen}
        onClose={closeAddMerchantModal}
      />
    </VegaPage>
  );
};

export default Resources;
