import { useEffect, useState } from 'react';
import { getPhysicalCardOrders } from '../../../../../../../api/card';
import { useSupport } from '../../../../../../../providers/SupportProvider';
import { PhysicalCardOrder } from '../../../../../../../types/card';
import VegaTableV4 from '../../../../../../common/v4/VegaTableV4';
import { getPastCardListStructuredData } from '../../../../supportUtils';
import VegaNoResults from '../../../../../../common/VegaNoResults';

interface IPastCardsProps {
  data: PhysicalCardOrder[];
  loading: boolean;
}

const ListOfPastCardOrders = () => {
  const { accountData } = useSupport();
  const [pastCardsListState, setPastCardsListState] = useState<IPastCardsProps>(
    {
      data: [],
      loading: false,
    }
  );

  const getPastCardsForAccount = async (accountId: string) => {
    setPastCardsListState({ data: [], loading: true });
    try {
      const body = {
        accountId,
        physicalCardOrderStatusList: ['REJECTED', 'DELIVERED', 'RTO'],
      };
      const response = await getPhysicalCardOrders(body);
      setPastCardsListState({ data: response, loading: false });
    } catch (error) {
      setPastCardsListState({ data: [], loading: false });
    }
  };

  useEffect(() => {
    if (accountData?.accountId) {
      getPastCardsForAccount(accountData.accountId);
    }
  }, [accountData?.accountId]);

  const structuredData = getPastCardListStructuredData(pastCardsListState.data);

  return !pastCardsListState?.data?.length && !pastCardsListState.loading ? (
    <VegaNoResults errorText="No Past Card records available" />
  ) : (
    <VegaTableV4
      head={[
        'Order ID',
        'Creation Date',
        'Delivery Partner',
        'Tracking ID',
        'Status',
        'Tracking ID',
        'Last Updated Date',
      ]}
      data={structuredData}
      isLoading={pastCardsListState.loading}
    />
  );
};

export default ListOfPastCardOrders;
