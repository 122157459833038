import { Box, Stack, SxProps } from '@mui/material';
import {
  LineChart,
  Line,
  LineProps,
  CartesianGrid,
  CartesianGridProps,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
  ResponsiveContainerProps,
  XAxisProps,
  YAxisProps,
  Legend,
  LegendProps,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { NEUTRALS, QUICK_ACCESS } from '../../../constants/style';
import {
  ComponentBody1Typo,
  ComponentBody2Typo,
  SectionHeadingTypo,
} from '../Typography';

interface IVegaLineChartV4Props {
  mainData?: any[];
  linePropsArray: LineProps[];
  cartesianData?: CartesianGridProps;
  toolTipData?: TooltipProps<ValueType, NameType>;
  respContainerData?: Omit<ResponsiveContainerProps, 'children'>;
  XAxisData: XAxisProps;
  YAxisData?: YAxisProps;
  YAxisData2?: YAxisProps;
  YAxisLabels?: {
    left?: {
      text: string;
      sx?: SxProps;
    };
    right?: {
      text: string;
      sx?: SxProps;
    };
  };
  legendRequired?: boolean;
  wrapperSx?: SxProps;
}

const VegaLineChartV4 = ({
  mainData,
  linePropsArray,
  YAxisLabels,
  cartesianData,
  toolTipData,
  respContainerData,
  XAxisData,
  YAxisData,
  YAxisData2,
  legendRequired,
  wrapperSx,
}: IVegaLineChartV4Props) => {
  return (
    <Stack direction="row" alignItems="center" flex={1} sx={{ ...wrapperSx }}>
      {YAxisLabels?.left?.text && (
        <SectionHeadingTypo
          sx={{
            writingMode: 'vertical-lr',
            transform: 'scale(-1)',
            ...YAxisLabels?.left?.sx,
          }}
        >
          {YAxisLabels?.left.text}
        </SectionHeadingTypo>
      )}
      <ResponsiveContainer
        height={459}
        style={{ flex: 1 }}
        {...respContainerData}
      >
        <LineChart data={mainData}>
          {linePropsArray?.map((lineProps, idx) => (
            <Line
              dot={false}
              strokeWidth={2}
              activeDot={{ r: 8 }}
              {...(lineProps as any)}
              key={lineProps.name || idx}
            />
          ))}
          <CartesianGrid
            stroke="#ccc"
            strokeDasharray="3"
            vertical={false}
            {...(cartesianData as any)}
          />
          <XAxis style={axisLabelStyle} tickMargin={16} {...XAxisData} />
          <YAxis
            style={axisLabelStyle}
            tickMargin={8}
            axisLine={false}
            {...YAxisData}
          />
          {YAxisData2 && (
            <YAxis
              style={axisLabelStyle}
              tickMargin={8}
              axisLine={false}
              {...YAxisData2}
            />
          )}
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ stroke: NEUTRALS.grey[300] }}
            {...toolTipData}
          />
          {legendRequired && (
            <Legend
              iconType="circle"
              margin={{ top: 20 }}
              align="left"
              content={<CustomLegend />}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      {YAxisLabels?.right?.text && (
        <SectionHeadingTypo
          sx={{ writingMode: 'vertical-lr', ...YAxisLabels?.right?.sx }}
        >
          {YAxisLabels?.right.text}
        </SectionHeadingTypo>
      )}
    </Stack>
  );
};

const CustomLegend = (props: LegendProps) => {
  const { payload } = props;

  return (
    <Stack
      style={{ listStyleType: 'none', padding: 0, marginTop: 24 }}
      direction="row"
      gap={2}
      alignItems="center"
      flexWrap={'wrap'}
    >
      {payload?.map((entry, index) => (
        <Stack
          key={`item-${index}`}
          direction="row"
          gap={1}
          alignItems="center"
        >
          <Box
            width={'16px'}
            height={'16px'}
            borderRadius={'8px'}
            sx={{ backgroundColor: entry.color }}
          />
          <ComponentBody1Typo>{entry.value}</ComponentBody1Typo>
        </Stack>
      ))}
    </Stack>
  );
};

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: QUICK_ACCESS.background[50],
          borderRadius: '6px',
          border: `1px solid ${QUICK_ACCESS.border[50]}`,
          overflow: 'hidden',
        }}
      >
        <Box p={'8px'} sx={{ backgroundColor: QUICK_ACCESS.background[100] }}>
          <ComponentBody1Typo>{label}</ComponentBody1Typo>
        </Box>
        <Stack p={'8px'} gap={'12px'}>
          {payload.map((item, idx) => (
            <Stack key={idx} direction="row" gap={'12px'}>
              <Stack direction="row" gap={'12px'} flex={1}>
                <Box
                  width={'16px'}
                  height={'16px'}
                  borderRadius={'8px'}
                  sx={{ backgroundColor: item.color }}
                />
                <ComponentBody2Typo color={QUICK_ACCESS.text[100]}>
                  {item.name || item.dataKey}
                </ComponentBody2Typo>
              </Stack>

              <ComponentBody1Typo>{item.value}</ComponentBody1Typo>
            </Stack>
          ))}
        </Stack>
      </Box>
    );
  }
  return null;
};

const axisLabelStyle = {
  fontFamily: 'Aspekta',
  fontSize: '12px',
  fontStyle: 'normal',
  lineHeight: '130%',
  marginTop: '16px',
};

export default VegaLineChartV4;
