import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  fetchKYCFlow,
  saveKYCFlow,
  saveNewVersionOfKycFlow,
} from '../../../api/los';
import { useProgramData } from '../../../providers/ProgramDataProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { VegaAccordian, VegaCard } from '../../common';
import VegaContainedButton from '../../common/VegaContainedButton';
import {
  KycStepStatus,
  VegaKycBaseStep,
  VegaKycStepType,
} from './KYCFlowDataTypes';
import { KycStepCard } from './KycStepCard';
import { KycStepAccordianContent } from './KycStepAccordianContent';
import VegaRadio from '../../common/VegaRadio';
import { GREEN, GREY } from '../../../constants/style';
import { VegaProgramNetworkType } from '../../../types/program';

function KYCflow() {
  const [kycSteps, setKycSteps] = useState<VegaKycBaseStep[]>([]);
  const [kycFlowExistsForProgram, setKycFlowExistsForProgram] =
    useState<boolean>(false);
  const [shouldResetFlow, setShouldResetFlow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectNetwork, setSelectNetwork] = useState<string>(
    VegaProgramNetworkType.MASTERCARD
  );
  const { selectedProgram } = useProgramData();
  const { setSnackbar } = useSnackbar();

  function updateStepData(step: VegaKycBaseStep) {
    step.stepUpdated = true;
    kycSteps.forEach((item, index) => {
      if (item.name === step.name) {
        kycSteps.splice(index, 1, step);
        return;
      }

      const subSteps = item.metaData.subSteps;
      if (!subSteps) return;
      subSteps.forEach((subItem, subIndex) => {
        if (subItem.name === step.name) {
          // update parent step
          item.stepUpdated = true;
          subSteps.splice(subIndex, 1, step);
        }
      });
    });

    // update the state with the new array of steps
    setKycSteps([...kycSteps]);
  }

  async function getKYCFlowSteps() {
    const programId = selectedProgram?.programId;
    if (!programId) {
      return;
    }
    setIsLoading(true);
    await fetchKYCFlow(programId)
      .then((res) => {
        const idExists = !!res.id;
        setKycFlowExistsForProgram(idExists);
        const flow = res.flow;
        const mappedFlow = addProgramIdToStepsIfRequired(flow, programId);
        const orderedFlow = addOrderToStepsIfRequired(mappedFlow);
        const sortedFlow = sortKycSteps(orderedFlow);
        setKycSteps(sortedFlow);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  }

  async function updateKycFlowForProgram() {
    const programId = selectedProgram?.programId;
    if (!programId) {
      setSnackbar('No Program Selected', 'error');
      return;
    }
    try {
      validateKycFlow();
      if (kycFlowExistsForProgram) {
        const response = await saveNewVersionOfKycFlow({
          programId: programId,
          flow: kycSteps,
          resetApplicationFlow: shouldResetFlow,
        });
      } else {
        const response = await saveKYCFlow({
          programId: programId,
          flow: kycSteps,
          resetApplicationFlow: false,
        });
      }

      setSnackbar('Kyc Flow Saved', 'success');
    } catch (e: any) {
      console.log(e);
      var errorMessage = e.message;
      if (e.response) {
        errorMessage = e.response.data.apierror.debugMessage;
      }
      setSnackbar(errorMessage, 'error');
    }
  }

  const addProgramIdToStepsIfRequired = (
    steps: VegaKycBaseStep[],
    programId: string
  ) => {
    return steps.map((item) => {
      if (item.programId) return item;

      if (item.metaData.subSteps) {
        const updatedSubSteps = item.metaData.subSteps.map((subItem) => {
          if (subItem.programId) return subItem;
          return {
            ...subItem,
            programId: programId,
          };
        });
        return {
          ...item,
          metaData: {
            ...item.metaData,
            subSteps: updatedSubSteps,
          },
          programId: programId,
        };
      }
      return {
        ...item,
        programId: programId,
      };
    });
  };

  const addOrderToStepsIfRequired = (steps: VegaKycBaseStep[]) => {
    return steps.map((item, index) => {
      var mappedSubSteps = item.metaData.subSteps;
      if (item.metaData.subSteps) {
        mappedSubSteps = item.metaData.subSteps.map((subItem, subItemIndex) => {
          if (subItem.order) return subItem;
          return { ...subItem, order: subItemIndex };
        });
      }

      if (item.order) {
        return {
          ...item,
          metaData: {
            ...item.metaData,
            subSteps: mappedSubSteps,
          },
        };
      }

      return {
        ...item,
        order: index,
        metaData: {
          ...item.metaData,
          subSteps: mappedSubSteps,
        },
      };
    });
  };

  function sortKycSteps(steps: VegaKycBaseStep[]): VegaKycBaseStep[] {
    return steps
      .sort((a, b) => a.order - b.order)
      .map((step) => {
        if (step.metaData.subSteps && step.metaData.subSteps.length > 0) {
          step.metaData.subSteps = sortKycSteps(step.metaData.subSteps);
        }
        return step;
      });
  }

  const validateKycFlow = () => {
    return kycSteps.forEach((item) => validateKycStep(item, true));
  };

  const validateKycStep = (step: VegaKycBaseStep, isParentStep: boolean) => {
    if (step.status == KycStepStatus.ACTIVE) {
      const subSteps = step.metaData.subSteps;
      const stepName = step.name;
      if (
        stepName == VegaKycStepType.Application_Form ||
        stepName == VegaKycStepType.PIN_SETUP ||
        stepName == VegaKycStepType.SET_BILLING_DATE ||
        stepName == VegaKycStepType.APPLICATION_APPROVED ||
        stepName == VegaKycStepType.AVAILABLE_PRODUCTS ||
        stepName == VegaKycStepType.OFFER ||
        stepName == VegaKycStepType.BANK_STATEMENT
      ) {
        return;
      }
      if (subSteps) {
        // validate recursively substeps
        subSteps.forEach((item) => validateKycStep(item, false));
      }

      if (isParentStep && subSteps) {
        return;
      }

      const selectedPartners = step.metaData.partners?.filter(
        (partner) => partner.status == KycStepStatus.ACTIVE
      );
      const selectedWorkFlow = step.workFlowDecision;

      if (!selectedPartners || selectedPartners.length <= 0) {
        const errorMessage =
          'Please Select Partner for step : ' +
          _.startCase(_.toLower(step.name));
        throw new Error(errorMessage);
      }

      if (!selectedWorkFlow) {
        const errorMessage =
          'Please Select work flow for step : ' +
          _.startCase(_.toLower(step.name));
        throw new Error(errorMessage);
      }
    }
  };

  useEffect(() => {
    getKYCFlowSteps();
  }, [selectedProgram]);

  if (isLoading) {
    return (
      <VegaCard
        fullHeight
        sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </VegaCard>
    );
  }

  return (
    <>
      <Grid spacing={2} container sx={{ py: 2 }}>
        {kycSteps.map((step: VegaKycBaseStep, i: any) => {
          return (
            <KycStepCard
              stepNumber={i + 1}
              step={step}
              updateStepData={(data) => updateStepData(data)}
            />
          );
        })}

        {/* ///// stetic accordian \\\\\\\ */}
        <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
          <Box
            sx={{
              bgcolor: 'white',
              py: 1.5,
              px: 2,
              borderRadius: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography className="font-aspekta-500" variant="body2">
              {' '}
              Step {13}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9} md={10} lg={10} xl={11}>
          <VegaCard noPad>
            <VegaAccordian
              showSwitch
              accordianSummaryMargin="8px 0px"
              showAccordianDetails={true}
              header={'Network Selection'}
              content={
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ color: GREY.lightGray, fontWeight: 400 }}
                  >
                    Network
                  </Typography>

                  <VegaRadio
                    name="approval"
                    radioData={redioOption()}
                    onChange={(e: any) => setSelectNetwork(e.target.value)}
                    value={selectNetwork}
                    labelPlacement="end"
                    color={GREEN.dark}
                  />
                </Box>
              }
            />
          </VegaCard>
        </Grid>
      </Grid>

      <Grid spacing={2} container sx={{ py: 2 }} alignItems="center">
        {/* Dummy item to  add left margin*/}
        <Grid item xs={12} sm={3} md={2} lg={2} xl={1}></Grid>
        <Grid item xs={12} sm={9} md={10} lg={10} xl={11}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'end',
              justifyContent: kycFlowExistsForProgram ? 'space-between' : 'end',
            }}
          >
            {kycFlowExistsForProgram && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="info"
                    value={shouldResetFlow}
                    onChange={(e) => {
                      setShouldResetFlow(e.currentTarget.checked);
                    }}
                  />
                }
                label="Reset Flow for existing applications"
              />
            )}

            <VegaContainedButton
              text="Submit"
              loading={isLoading}
              onClick={updateKycFlowForProgram}
              isPrimary
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default KYCflow;

const redioOption = () => {
  const options = Object.keys(VegaProgramNetworkType).map((value: string) => ({
    value,
    label: value,
  }));
  return options;
};
