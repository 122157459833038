import { Stack } from '@mui/material';
import { TransactionDto } from '../../../../../../../types/transaction';
import { PageHeadingTypo } from '../../../../../../common/Typography';
import VegaTableV4 from '../../../../../../common/v4/VegaTableV4';
import { useTransactionRefundsListQuery } from '../../../../../../../store/queries/transactionQueries';
import { getTransactionRefundStructuredData } from '../../../../supportUtils';
import VegaNoResults from '../../../../../../common/VegaNoResults';

interface ITransactionRefundProps {
  data: TransactionDto | undefined;
}

const TransactionRefund = ({ data }: ITransactionRefundProps) => {
  const { data: refundData, isFetching } = useTransactionRefundsListQuery({
    transactionId: data?.id || '',
  });

  const structuredTableData = getTransactionRefundStructuredData(refundData);

  return (
    <Stack gap={1.5}>
      <PageHeadingTypo>Refund</PageHeadingTypo>
      {refundData?.length || isFetching ? (
        <VegaTableV4
          head={['Transaction Time', 'Transaction ID', 'Amount']}
          data={structuredTableData}
          isLoading={isFetching}
        />
      ) : (
        <VegaNoResults errorText="No Refunds to display" />
      )}
    </Stack>
  );
};

export default TransactionRefund;
