import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
} from '@mui/material';
import { COMMON, PRIMARY } from '../../../constants/style';
import VegaHeader from '../../common/VegaHeader';
import { getLogoForNetwork } from '../../icon/Icons';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import { toLowerCase } from '../../../constants/commonFunction';
import { listBinsByIds } from '../../../api/bin';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { IBinDtoProps } from '../../../types/binType';
import { IBinProps } from './BinDetails';
import { NewCardBin } from '../../../types/card';
import VegaDataTileV4 from '../../common/v4/VegaDataTileV4';

interface IProps {
  loading: boolean;
  binData: NewCardBin[];
}

const BinDetailsComponent = ({ loading, binData }: IProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setSnackbar } = useSnackbar();

  const binDetails = [
    {
      key: 'bin name',
      value: binData[0]?.binName,
    },
    {
      key: 'bin value',
      value: binData[0]?.binValue,
    },
    {
      key: 'network',
      value: binData[0]?.network
        ? getLogoForNetwork(binData[0].network ?? '')
        : '-',
      // value: binData[0]?.binName,
    },
    {
      key: 'bin type',
      value: toLowerCase(binData[0]?.binType ?? '--'),
    },
    {
      key: 'bin usage',
      value: `${toLowerCase(binData[0]?.productType ?? '--')} Card`,
    },
  ];

  const summaryData = [
    {
      key: 'total bin range',
      value: `${binData[0]?.maxNoOfKits ?? 0}`,
    },
    {
      key: 'BIN Range Assigned',
      value: `${binData[0]?.noOfKitsSold ?? 0}`,
    },
    {
      key: 'BIN range unassigned',
      value: `${binData[0]?.noOfKitsAvailable ?? 0}`,
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          justifyContent: 'space-between',
          gap: {
            xs: 2,
            sm: 1,
          },
          py: 2,
        }}
      >
        <Stack direction="row" gap={2} flexWrap="wrap">
          {binDetails.map((data, index) => (
            <VegaDataTileV4
              title={data.key}
              value={typeof data.value === 'string' ? data.value : undefined}
              key={data.key}
              hasSeparator={index !== binDetails.length - 1}
            >
              {data.value && typeof data.value !== 'string' ? data.value : null}
            </VegaDataTileV4>
          ))}
        </Stack>
        <Stack direction="row" gap={2} flexWrap="wrap">
          {summaryData.map((data, index) => (
            <VegaDataTileV4
              title={data.key}
              value={typeof data.value === 'string' ? data.value : undefined}
              key={data.key}
              hasSeparator={index !== summaryData.length - 1}
            >
              {data.value && typeof data.value !== 'string' ? data.value : null}
            </VegaDataTileV4>
          ))}
        </Stack>
      </Box>
    </>
  );
};

export default BinDetailsComponent;
