/** @format */

import React, { useEffect } from 'react';
import { listForCustomers } from '../api/customer';
import { VegaCreateCustomersType } from '../types/customers';
import { useProgramData } from './ProgramDataProvider';

export type CustomerAccountDataContextTyep = {
  accounts: VegaCreateCustomersType[];
  selectedAccount: VegaCreateCustomersType | null;
  setSelectedCustomerAccount: (program: VegaCreateCustomersType) => void;
  fetchCustomerAccounts: () => void;
  loading: boolean;
  error: boolean;
};

export const CustomerAccountDataContext =
  React.createContext<CustomerAccountDataContextTyep | null>(null);
export const useCustomerAccountData = () =>
  React.useContext(
    CustomerAccountDataContext
  ) as CustomerAccountDataContextTyep;

const CustomerDataProvider = (props: any) => {
  const { selectedProgram } = useProgramData();
  const [accounts, setAccounts] = React.useState<VegaCreateCustomersType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [selectedAccount, _setSelectedAccount] =
    React.useState<VegaCreateCustomersType | null>(null);

  const fetchCustomerAccounts = () => {
    if (
      selectedProgram &&
      selectedProgram.programId &&
      selectedProgram.clientId
    ) {
      setLoading(true);
      setError(false);
      listForCustomers({
        programId: selectedProgram?.programId as string,
        clientId: selectedProgram?.clientId as string,
        page: 0,
        pageSize: 100000,
        allAccounts: true,
      })
        .then((res) => {
          const customerAccountsData = res?.records;
          if (!customerAccountsData) {
            console.error('Failed to fetch Accounts for program');
            setError(true);
            handleFailedToLoadAccounts();
          } else {
            setAccounts(customerAccountsData);
            const selectedCustomerAccount = customerAccountsData?.[0];
            setSelectedCustomerAccount(selectedCustomerAccount);
          }
        })
        .catch((err) => {
          setError(true);
          handleFailedToLoadAccounts();
          // setSnackbar(getErrorMessageFromErrorObj(err, 'Failed to fetch accouns for program'), 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleFailedToLoadAccounts = () => {
    setAccounts([]);
    setSelectedCustomerAccount(null);
  };
  const setSelectedCustomerAccount = (
    customerAccountsData: VegaCreateCustomersType | null
  ) => {
    _setSelectedAccount(customerAccountsData);
  };

  useEffect(() => {
    if (selectedProgram) {
      fetchCustomerAccounts();
    }
  }, [selectedProgram]);

  return (
    <CustomerAccountDataContext.Provider
      value={{
        accounts,
        selectedAccount,
        setSelectedCustomerAccount,
        fetchCustomerAccounts,
        loading,
        error,
      }}
    >
      {props.children}
    </CustomerAccountDataContext.Provider>
  );
};

export default CustomerDataProvider;
