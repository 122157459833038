import { Stack } from '@mui/material';
import { PageHeadingTypo } from '../../../../../../common/Typography';
import VegaTableV4 from '../../../../../../common/v4/VegaTableV4';
import { IBillResponseData } from '../../../../../../../types/bill';
import { useRepaymentsForBillListQuery } from '../../../../../../../store/queries/billQueries';
import { getRepaymentsForBillStructuredData } from '../../../../supportUtils';
import VegaNoResults from '../../../../../../common/VegaNoResults';

interface IBillDetailsRepaymentProps {
  data: IBillResponseData | undefined;
}

const BillDetailsRepayment = ({ data }: IBillDetailsRepaymentProps) => {
  const { data: repaymentsData, isFetching } = useRepaymentsForBillListQuery({
    billId: data?.id || '',
  });

  const records = repaymentsData?.records;

  const structuredTableData = getRepaymentsForBillStructuredData(records);

  return (
    <Stack gap={1.5}>
      <PageHeadingTypo>Repayments</PageHeadingTypo>
      {!Boolean(records?.length) && !isFetching ? (
        <VegaNoResults errorText="No Repayments found" />
      ) : (
        <VegaTableV4
          head={[
            'Transaction Time',
            'Transaction ID',
            'Amount',
            'Reward Points',
            'Payment Mode',
            'Payment Source',
          ]}
          data={structuredTableData}
          isLoading={isFetching}
        />
      )}
    </Stack>
  );
};

export default BillDetailsRepayment;
