import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import DataTile from '../DataTile';
import Grid from '@mui/material/Grid';
import type {
  RepaymentPolicyInterface,
  RepaymentWaterfall,
} from '../../../types/policy';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import ProgramDataGrid from './ProgramDataGrid';
import { useAppSelector } from '../../../store/hook';
import startsWith from 'lodash/startsWith';
import { convertCamelCase } from '../../../utils/stringUtils';

const getRepaymentPolicyTableColumns = (): GridColDef<RepaymentWaterfall>[] => [
  {
    field: 'amount_type',
    headerName: 'Amount Type',
    flex: 1,
    sortable: false,
    minWidth: 180,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      convertCamelCase(params.value),
  },
  {
    field: 'priority_order',
    headerName: 'Priority Order',
    flex: 1,
    sortable: false,
    minWidth: 180,
  },
];

type ViewRepaymentPolicyProps = {
  policy: RepaymentPolicyInterface;
};

function ViewRepaymentPolicy({ policy }: ViewRepaymentPolicyProps) {
  const columnsRef = useMemo(() => getRepaymentPolicyTableColumns(), []);

  const [billedData, unBilledData] = useMemo(
    () => [
      policy.repayment_waterfall?.filter((data) =>
        startsWith(data.amount_type, 'BILLED')
      ),
      policy.repayment_waterfall?.filter((data) =>
        startsWith(data.amount_type, 'UNBILLED')
      ),
    ],
    [policy.repayment_waterfall]
  );

  return (
    <Box
      sx={{
        py: 3,

        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          gap: 3,
        }}
      >
        <DataTile
          title="Auto Debit Number of Retries"
          value={`${policy?.auto_debit_number_of_retries ?? '-'}`}
        />
        <DataTile
          title="Min Number of Installments"
          value={`${
            policy?.partial_pay_off_minimum_number_of_installments ?? '-'
          }`}
        />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <ProgramDataGrid
            data={billedData}
            columns={columnsRef}
            idColumn={'amount_type'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProgramDataGrid
            data={unBilledData}
            columns={columnsRef}
            idColumn={'amount_type'}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ViewRepaymentPolicy;
