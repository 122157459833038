import { Dispatch, useState } from 'react';
import { IAddUserRequest } from '../../mcc/Program-Dashboard/api/roles';
import VegaDialogV4 from '../common/v4/VegaDialogV4';
import { Box } from '@mui/material';
import { VegaUserStatus } from '../../types/user';
import { updateUserStatus } from '../../api/roleManagement';
import { useSnackbar } from '../../providers/SnackbarProvider';

interface IPartnerAccessSwitchConfirmModalProps {
  showModal: boolean;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  selectedUser?: IAddUserRequest;
  refetch: any;
}

const PartnerAccessSwitchConfirmModal = ({
  showModal,
  setShowModal,
  selectedUser,
  refetch,
}: IPartnerAccessSwitchConfirmModalProps) => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const modalClose = () => {
    setShowModal(false);
  };

  const onSwitchClick = () => {
    setLoading(true);
    updateUserStatus({
      userId: selectedUser?.userId as string,
      status:
        selectedUser?.status === VegaUserStatus.ACTIVE
          ? VegaUserStatus.INACTIVE
          : VegaUserStatus.ACTIVE,
    })
      .then(() => {
        setSnackbar(
          `${selectedUser?.name}'s status updated to ${selectedUser?.status}`,
          'success'
        );
      })
      .catch(() => {
        setSnackbar(`Error Updating status. Please try again`, 'error');
      })
      .finally(() => {
        modalClose();
        setLoading(false);
        refetch();
      });
  };

  return (
    <VegaDialogV4
      open={showModal && Boolean(selectedUser)}
      title="Team Member Status"
      onClose={modalClose}
      closeBtnRequired
      sx={{
        '.MuiPaper-root': {
          minWidth: '516px',
        },
      }}
      actionBtnPrimaryText="No, thanks"
      actionBtnPrimaryProps={{
        onClick: modalClose,
        disabled: loading,
      }}
      actionBtnSecondaryText="Yes"
      actionBtnSecondaryProps={{
        onClick: onSwitchClick,
        disabled: loading,
        loading,
      }}
    >
      <Box py={4} px={5}>
        Are you sure you want to make {selectedUser?.name || 'user'}'s status{' '}
        {selectedUser?.status === VegaUserStatus.ACTIVE ? 'inactive' : 'active'}
        ?
      </Box>
    </VegaDialogV4>
  );
};

export default PartnerAccessSwitchConfirmModal;
