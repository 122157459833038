import { useState } from 'react';
import VegaDrawerV4, { IVegaDrawerV4Props } from '../../common/v4/VegaDrawerV4';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { Controller, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import VegaTextfieldV4 from '../../common/v4/VegaTextfieldV4';
import { ListHead2Typo } from '../../common/Typography';
import VegaDateTimePickerV4 from '../../common/v4/VegaDateTimePicker';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import {
  createRepaymentForAccount,
  updateTransactionStatus,
} from '../../../api/transaction';

interface ITransactionRepaymentFormProps {
  amount: number;
  date: string;
}

const TransactionRepaymentDrawer = (
  props: Omit<IVegaDrawerV4Props, 'children' | 'header'> & {
    accountId?: string;
    refetch: any;
  }
) => {
  const [loading, setLoading] = useState(false);
  const { setSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
    getValues,
  } = useForm<ITransactionRepaymentFormProps>({
    mode: 'onTouched',
  });

  const onDrawerClose = (e: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    onClose?.(e, reason);
    reset();
  };

  const resetForm = () => {
    reset();
  };

  const createRepayment = () => {
    setLoading(true);
    const values = getValues();
    createRepaymentForAccount(
      accountId || '',
      values.amount,
      values.date || undefined
    )
      .then(async (res) => {
        const dataRes = res.data;
        if (dataRes.status) {
          try {
            const { data } = await updateTransactionStatus(
              dataRes.transactionId,
              'SUCCESS'
            );
            if (data.status) {
              setSnackbar('Transaction Status Successfully Updated!');
            } else {
              throw new Error('Transaction in Pending State');
            }
          } catch (e) {
            setSnackbar(getErrorMessageFromErrorObj(e), 'error');
          }
        }
        refetch();
        setSnackbar('Transaction created!');
        onClose?.({}, 'escapeKeyDown');
        reset();
      })
      .catch((err) => {
        setSnackbar(getErrorMessageFromErrorObj(err), 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { onClose, open, accountId, refetch, ...rest } = props;

  return (
    <VegaDrawerV4
      {...rest}
      open={Boolean(open && accountId)}
      onClose={onDrawerClose}
      header="Create Repayment"
      submitBtn={{
        show: true,
        props: {
          value: 'Create Transaction',
          disabled: !isDirty || loading,
          loading: loading,
          onClick: handleSubmit(createRepayment),
        },
      }}
      secondaryBtn={{
        props: {
          value: 'Clear',
          onClick: resetForm,
          disabled: loading,
        },
      }}
    >
      <Stack gap={3}>
        <Controller
          name="amount"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.amount)}
              header="Amount"
              type="number"
              labelText={errors.amount?.message}
              standardWidth={400}
              required
              placeholder="Enter Amount"
            />
          )}
        />
        <Controller
          name="date"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => {
            return (
              <VegaDateTimePickerV4
                dateProps={{
                  ...rest,
                  views: ['year', 'month', 'day', 'hours', 'minutes'],
                }}
                textProps={{
                  header: 'Date',
                  required: true,
                  headerTypo: ListHead2Typo,
                  labelText: errors.date?.message,
                  error: Boolean(errors.date),
                  standardWidth: 400,
                  placeholder: 'Select Date',
                }}
              />
            );
          }}
        />
      </Stack>
    </VegaDrawerV4>
  );
};

export default TransactionRepaymentDrawer;
