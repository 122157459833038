/** @format */

import { Box, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { usePolicy } from '../../providers/PolicyProvider';
import { DEFAULT_MAD_POLICY } from '../../types/defaultPolicy';
import { EntityAuthStatus, UserMakerCheckerRole } from '../../types/entityAuth';
import {
  MadComponent,
  MadPolicyInterface,
  MAD_POLICY_KEYS,
  PolicyComponentType,
  PolicyType,
} from '../../types/policy';
import VegaConditionalWrapper from '../common/VegaConditionalWrapper';
import VegaSelectItem from '../common/VegaSelectItem';
import PolicyCard from './PolicyCard';
import PolicyCommentCard from './PolicyCommentCard';
import { PolicyWrapper } from './PolicyWrapper';
import ThreeColumnInputFields, {
  DEFAULT_COL_WIDTHS,
} from './ThreeColumnInputFields';

enum ColumnType {
  TRANSACTION_CATEGORY = 'transaction_category',
  VALUE_TYPE = 'value_type',
  VALUE = 'value',
}

const HEADERS = Object.values(ColumnType);
const MadTadPolicy = ({
  initialValue,
  onSubmit,
  isLoading,
  onApprove,
  onReject,
  onPastCommentsClick,
}: PolicyComponentType) => {
  const {
    checkPolicyEditable,
    checkPolicyStatusUpdatable,
    entityAuthStatuses,
    loggedInMakerCheckerUser,
    addCommentOnPolicy,
    updateCommentOnPolicy,
    areAllPoliciesApproved,
    getDefaultPolicy,
  } = usePolicy();
  const [policy, setPolicy] = useState<MadPolicyInterface | null>(
    // DEFAULT_MAD_POLICY('Default MAD Policy')
    null
  );
  const [canEditPolicy, setCanEditPolicy] = useState<boolean>(false);
  const [canUpdatePolicyStatus, setCanUpdatePolicyStatus] =
    useState<boolean>(false);
  const [entityAuthStatus, setEntityStatus] = useState<EntityAuthStatus | null>(
    null
  );

  const handleChange = (
    key: MAD_POLICY_KEYS,
    value: string | number | MadComponent[]
  ) => {
    if (!policy) return;
    setPolicy({ ...policy, [key]: value });
  };

  const handleMadComponentChange = (index: number, key: string, value: any) => {
    if (!policy) return;
    if (key === 'value') {
      value = parseFloat(value);
      if (value > 100) value = 100;
      if (value < 0) value = 0;
    }
    const current = [...policy.mad_components];
    const editedElement = { ...current[index], [key]: value };
    current.splice(index, 1, editedElement);
    handleChange('mad_components', current);
  };

  const handleCheckClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    handleChange('minimum_mad', !checked ? 0 : 1);
  };

  async function getEntityStatusAndCheckIfCanEdit() {
    const policyId = policy?.policy_id;
    if (!policyId) {
      setCanEditPolicy(false);
      setCanUpdatePolicyStatus(false);
      setEntityStatus(null);
      return;
    }
    const response = await checkPolicyEditable(policy);
    const canUpdateStatus = await checkPolicyStatusUpdatable(policy);
    const entityAuthStatus = entityAuthStatuses.get(policyId);
    setCanUpdatePolicyStatus(canUpdateStatus);
    setCanEditPolicy(response);
    setEntityStatus(entityAuthStatus ?? null);
  }

  const handleSubmit = () => {
    const policyCopy: any = { ...policy };
    delete policyCopy['tad_components'];
    onSubmit(policyCopy);
  };

  const getDisabledColumns = () => {
    if (canEditPolicy == false) {
      return ['transaction_category', 'value_type', 'value'];
    }
    return ['transaction_category', 'value_type'];
  };

  function didClickApprove() {
    if (policy) onApprove(policy);
  }

  function addCommentOnEntity(comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    addCommentOnPolicy({
      policyId: policyId,
      comment: comment,
    });
  }

  function updateEntityComment(id: string, comment: string) {
    const policyId = policy?.policy_id;
    if (!policyId) {
      return;
    }
    updateCommentOnPolicy({
      policyId: policyId,
      commentId: id,
      comment: comment,
    });
  }

  const canShowCommentsCard = () => {
    if (areAllPoliciesApproved) return false;
    const isMaker =
      loggedInMakerCheckerUser?.role == UserMakerCheckerRole.MAKER;
    const areCommentsPresent =
      (entityAuthStatus?.commentsList ?? []).length > 0;
    return isMaker && areCommentsPresent;
  };

  function fetchDefaultPolicy() {
    getDefaultPolicy(PolicyType.MAD)
      .then((defaultPolicy) => {
        if (defaultPolicy) {
          const madPolicy = defaultPolicy as MadPolicyInterface;
          setPolicy(madPolicy);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (initialValue) {
      setPolicy(initialValue as MadPolicyInterface);
    } else {
      fetchDefaultPolicy();
    }
  }, [initialValue]);

  useEffect(() => {
    getEntityStatusAndCheckIfCanEdit();
  }, [policy, loggedInMakerCheckerUser]);

  return (
    <PolicyWrapper
      user={loggedInMakerCheckerUser}
      policyId={policy?.policy_id}
      policyView={
        <Box>
          <VegaConditionalWrapper
            condition={canShowCommentsCard()}
            ifTrue={
              <PolicyCommentCard
                onPastCommentClick={() => {
                  onPastCommentsClick(policy?.policy_id);
                }}
                comments={entityAuthStatus?.commentsList}
              />
            }
          />
          <PolicyCard
            policy={initialValue}
            onSaveClick={handleSubmit}
            isLoading={isLoading}
            isSaveDisabled={canUpdatePolicyStatus == false}
            onApproveClick={didClickApprove}
            viewFor={loggedInMakerCheckerUser?.role}
            onCommentAddClick={addCommentOnEntity}
            policyEntityAuth={entityAuthStatus}
            onPastCommentsClick={onPastCommentsClick}
            onCommentEdit={updateEntityComment}
          >
            <Box sx={{ mb: 4 }}>
              <Typography sx={{ my: 1 }}>
                Minimum Amount Due Definition:
              </Typography>
              <Grid container spacing={2} sx={{ my: 1 }}>
                <Grid item xs={12} sm={4}>
                  <VegaSelectItem
                    value={'ALL'}
                    onChange={(e: any) => {}}
                    key="interest_status"
                    label="Interest Status"
                    placeholder="Interest Status"
                    data={[
                      {
                        text: 'ANY',
                        value: 'ALL',
                      },
                    ]}
                  />
                </Grid>
              </Grid>

              <ThreeColumnInputFields
                headerTexts={HEADERS}
                data={policy?.mad_components ?? []}
                columnWidths={DEFAULT_COL_WIDTHS}
                onChange={handleMadComponentChange}
                disabledKeys={getDisabledColumns()}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  mt: 2,
                }}
              >
                <Checkbox
                  sx={{
                    color: 'primary.main',
                  }}
                  checked={(policy?.minimum_mad ?? 0) > 0}
                  onChange={handleCheckClick}
                  color="success"
                  disabled={canEditPolicy == false}
                />
                <Typography variant="body2">Minimum MAD Amount</Typography>
                <TextField
                  size="small"
                  type={'number'}
                  value={policy?.minimum_mad}
                  onChange={(e) => handleChange('minimum_mad', +e.target.value)}
                  sx={{ width: 80, mx: 1 }}
                  disabled={canEditPolicy == false}
                />
                <Typography variant="caption">
                  {'or = Total Amount Due, in case TAD < min MAD'}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  mt: 2,
                }}
              >
                <Checkbox
                  sx={{
                    color: 'primary.main',
                  }}
                  checked={(policy?.minimum_mad ?? 0) > 0}
                  onChange={handleCheckClick}
                  color="success"
                  disabled={canEditPolicy == false}
                />
                <Typography variant="body2">Minimum TAD Amount</Typography>
                <TextField
                  size="small"
                  type={'number'}
                  value={policy?.minimum_tad}
                  onChange={(e) => handleChange('minimum_mad', +e.target.value)}
                  sx={{ width: 80, mx: 1 }}
                  disabled={canEditPolicy == false}
                />
                <Typography variant="caption">
                  {'or = Total Amount Due, in case TAD < min MAD'}
                </Typography>
              </Box>
            </Box>
          </PolicyCard>
        </Box>
      }
    />
  );
};

export default MadTadPolicy;
