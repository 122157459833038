import { ISVGProps } from '../../mcc/types/Commons';

const GraphIcon = (props: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M2.39998 10.4001L4.96648 8.00013L6.7997 9.71439L10.8328 5.94302M8.0889 5.6001H11.2V8.50929M3.19997 14.4001C2.31632 14.4001 1.59998 13.6838 1.59998 12.8001V3.2001C1.59998 2.31644 2.31632 1.6001 3.19998 1.6001H12.8C13.6836 1.6001 14.4 2.31644 14.4 3.2001V12.8001C14.4 13.6838 13.6836 14.4001 12.8 14.4001H3.19997Z"
        stroke={props.stroke || '#EBEDEF'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GraphIcon;
