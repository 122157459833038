import React from 'react';
import VegaDataGridV4, {
  VegaDataGridV4Props,
} from '../../common/v4/VegaDataGridV4';

function ProgramDataGrid({
  data,
  columns,
  idColumn,
  ...rest
}: VegaDataGridV4Props) {
  return (
    <VegaDataGridV4
      data={data}
      columns={columns}
      idColumn={idColumn}
      pageSize={100}
      disableColumnFilter
      hideFooter
      wrapperSx={{
        alignItems: 'flex-start',
      }}
      {...rest}
    />
  );
}

export default ProgramDataGrid;
