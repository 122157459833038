import React, { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DataTile from '../DataTile';
import {
  AnyPolicyType,
  PasswordPolicy,
  PasswordPolicyValueType,
} from '../../../types/policy';
import { fetchAllEffectivePolicesForProgram } from '../../../api/policy';
import { AxiosError } from 'axios';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { setPolicies } from '../../../store/common/programManagementSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { convertCamelCase } from '../../../utils/stringUtils';

type Props = {};
const getPasswordFormat = (options: PasswordPolicyValueType[]) => {
  var format = '';
  options.forEach((item) => {
    switch (item) {
      case PasswordPolicyValueType.DOB:
        format += 'DDMM';
        break;
      case PasswordPolicyValueType.MOBILE_NUMBER:
        format += 'MOBN';
        break;
      case PasswordPolicyValueType.PAN_NUMBER:
        format += 'PANN';
        break;
    }
  });
  return format;
};
const fetchPolicies = (programId: string) => {
  return new Promise((resolve, reject) => {
    fetchAllEffectivePolicesForProgram(programId)
      .then((res) => {
        const allPolicies = res?.data;
        if (!allPolicies) {
          reject(new AxiosError('Failed to fetch policies of program'));
        }
        resolve(allPolicies);
      })
      .catch(reject);
  });
};

function StatementPassword({}: Props) {
  const { policies, selectedProgram } = useAppSelector(
    (state) => state.programManagement
  );
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    if (!policies.length && selectedProgram) {
      fetchPolicies(selectedProgram.programId)
        .then((res) => {
          dispatch(setPolicies(res as AnyPolicyType[]));
        })
        .catch((err) => {
          setSnackbar('Failed to fetch policies!', 'error');
        })
        .finally(() => {});
    }
  }, []);

  const passwordPolicy = useMemo(
    () =>
      policies.find((policy) => policy.policy_type === 'PASSWORD_POLICY') as
        | PasswordPolicy
        | undefined,
    [policies]
  );

  const passwordContent =
    passwordPolicy?.selected_fields
      ?.map((field) =>
        field === PasswordPolicyValueType.DOB ? field : convertCamelCase(field)
      )
      ?.join(', ') ?? '-';

  const passwordFormat = passwordPolicy?.selected_fields
    ? getPasswordFormat(passwordPolicy.selected_fields)
    : '-';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography color="#1B1D22" fontSize={17} className="font-aspekta-700">
        Statement Password
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 3,
        }}
      >
        <DataTile title="Password length of 8 chars" value={passwordContent} />
        <DataTile title="Password Format" value={passwordFormat} />
      </Box>
    </Box>
  );
}

export default StatementPassword;
