import { Stack } from '@mui/material';
import { MouseEvent, useMemo, useState } from 'react';
import { useSupport } from '../../../../../../providers/SupportProvider';
import { PageHeadingTypo } from '../../../../../common/Typography';
import VegaSearchfieldV4 from '../../../../../common/v4/VegaSearchfieldV4';
import VegaTableV4 from '../../../../../common/v4/VegaTableV4';
import { getRewardListStructuredData } from '../../../supportUtils';
import { useRewardList } from '../../../../../../store/queries/rewardQueries';
import VegaNoResults from '../../../../../common/VegaNoResults';
import { useKeyValHook } from '../../../../../../hooks/useKeyValHook';
import VegaTextfieldV4 from '../../../../../common/v4/VegaTextfieldV4';
import dayjs from 'dayjs';
import { DATE_FORMATE_SHORT } from '../../../../../../constants/url';
import VegaDateRangePickerPopoverV4 from '../../../../../common/v4/VegaDateRangePickerPopoverV4';
import { getDateInputIcons } from '../../../../../../utils/common.utils';
import useDateRangeStandardHook from '../../../../../../hooks/useDateRangeStandardHook';

interface IRewardFilter {
  date?: Date;
  search?: string;
  startDate?: string;
  endDate?: string;
}

type Keys = 'date' | 'search' | 'startDate' | 'endDate';

const Rewards = () => {
  const { accountData } = useSupport();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const updatePage2Zero = () => {
    setPage(0);
  };

  const { updateValue, values } = useKeyValHook<IRewardFilter, Keys>(
    {
      search: undefined,
    },
    updatePage2Zero
  );

  const { startDate2Pass, endDate2Pass } = useDateRangeStandardHook(
    values.startDate,
    values.endDate
  );

  const { data, isFetching } = useRewardList({
    accountId: accountData?.accountId || '',
    pageNumber: page,
    pageSize,
    search: values.search,
    startDate: startDate2Pass,
    endDate: endDate2Pass,
  });

  const updateRecord = (key: Keys) => (e: any) => {
    updateValue(
      key,
      key === 'search' && e.target.value === ''
        ? undefined
        : (e.target.value as string)
    );
  };

  const clearSearch = () => {
    updateValue('search', undefined);
  };

  const records = data?.records;

  const structuredData = getRewardListStructuredData(records);

  const clearDate = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    updateValue('startDate', undefined);
    updateValue('endDate', undefined);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitAndClose = (startDate: string, endDate: string) => {
    updateValue('startDate', startDate);
    updateValue('endDate', endDate);
    handleClose();
  };

  const openRange = Boolean(anchorEl);
  const id = openRange ? 'simple-popover' : undefined;

  return (
    <Stack gap={2}>
      <PageHeadingTypo>Rewards</PageHeadingTypo>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={1}>
          <VegaTextfieldV4
            onClick={handleClick}
            value={
              Boolean(values.endDate && values.endDate)
                ? `${dayjs(values.startDate).format(
                    DATE_FORMATE_SHORT
                  )} - ${dayjs(values.endDate).format(DATE_FORMATE_SHORT)}`
                : undefined
            }
            standardWidth={Boolean(values.endDate && values.endDate) ? 190 : 95}
            placeholder="By Date"
            sx={{ caretColor: 'transparent' }}
            InputProps={getDateInputIcons(Boolean(values.startDate), clearDate)}
          />
        </Stack>
        <VegaSearchfieldV4
          value={values.search}
          onChange={updateRecord('search')}
          onClear={clearSearch}
          placeholder="Search by loan Id..."
        />
      </Stack>
      {!Boolean(records?.length) && !isFetching ? (
        <VegaNoResults errorText="No Rewards to display" />
      ) : (
        <VegaTableV4
          head={[
            'Reward ID',
            'Reward Created on',
            'Loan ID',
            'Accumulated Points',
            'Earned Points',
            'Last Modified on',
            'Total Points',
            'Status',
          ]}
          data={structuredData}
          isLoading={isFetching}
          pagination={{
            rowsPerPage: pageSize,
            setRowsPerPage: setPageSize,
            setPage,
            page,
            totalRecords: data?.totalItems || 0,
          }}
        />
      )}
      <VegaDateRangePickerPopoverV4
        open={openRange}
        onClose={handleClose}
        submitDateRange={submitAndClose}
        popoverProps={{
          anchorEl,
          onClose: handleClose,
          open: openRange,
          id,
        }}
        initialValues={{
          startDate: values.startDate || '',
          endDate: values.endDate || '',
        }}
      />
    </Stack>
  );
};

export default Rewards;
