import { Controller, UseFormReturn } from 'react-hook-form';
import VegaContainerV4 from '../../../common/v4/VegaContainerV4';
import { IAddCustomerFormProps } from './CreateCustomerFormPage';
import { Stack } from '@mui/material';
import VegaSelectV4 from '../../../common/v4/VegaSelectV4';
import { GENDER, TITLES } from '../../../../enums/commons';
import { ListHead2Typo } from '../../../common/Typography';
import { VALIDATE_REGEX } from '../../../../utils/validation.utils';
import VegaTextfieldV4 from '../../../common/v4/VegaTextfieldV4';
import VegaDatePickerV4 from '../../../common/v4/VegaDatePickerV4';
import dayjs from 'dayjs';

interface IAddCustomerPersonalDetails1Props {
  customerFormHook: UseFormReturn<IAddCustomerFormProps, any>;
}

const AddCustomerPersonalDetails1 = ({
  customerFormHook,
}: IAddCustomerPersonalDetails1Props) => {
  const {
    control,
    formState: { errors },
  } = customerFormHook;

  return (
    <VegaContainerV4 title="Personal Details 1">
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        <Controller
          name="personal1.title"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaSelectV4
              {...rest}
              error={Boolean(errors.personal1?.title)}
              header="Title"
              labelText={errors.personal1?.title?.message}
              value={rest.value || ([] as any)}
              options={Object.values(TITLES).map((el) => ({
                name: el,
                id: el,
              }))}
              wrapperProps={{
                sx: {
                  flex: 0,
                },
              }}
              required
              placeholder="Select Title"
            />
          )}
        />
        <Controller
          name="personal1.firstName"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.FULL_NAME,
              message: 'Not a valid name',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.personal1?.firstName)}
              header="First Name"
              labelText={errors.personal1?.firstName?.message}
              required
              placeholder="Enter First Name"
            />
          )}
        />
        <Controller
          name="personal1.middleName"
          control={control}
          rules={{
            pattern: {
              value: VALIDATE_REGEX.FULL_NAME,
              message: 'Not a valid name',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.personal1?.middleName)}
              header="Middle Name (Optional)"
              labelText={errors.personal1?.middleName?.message}
              placeholder="Enter Middle Name"
            />
          )}
        />
        <Controller
          name="personal1.lastName"
          control={control}
          rules={{
            required: 'Required field',
            pattern: {
              value: VALIDATE_REGEX.FULL_NAME,
              message: 'Not a valid name',
            },
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaTextfieldV4
              {...rest}
              error={Boolean(errors.personal1?.lastName)}
              header="Last Name"
              labelText={errors.personal1?.lastName?.message}
              required
              placeholder="Enter Last Name"
            />
          )}
        />
        <Controller
          name="personal1.gender"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => (
            <VegaSelectV4
              {...rest}
              error={Boolean(errors.personal1?.gender)}
              header="Gender"
              labelText={errors.personal1?.gender?.message}
              value={rest.value || ([] as any)}
              options={Object.values(GENDER).map((el) => ({
                name: el,
                id: el,
              }))}
              wrapperProps={{
                sx: {
                  flex: 0,
                },
              }}
              required
              placeholder="Select Gender"
            />
          )}
        />
        <Controller
          name="personal1.dob"
          control={control}
          rules={{
            required: 'Required field',
          }}
          render={({ field: { ref, ...rest } }) => {
            return (
              <VegaDatePickerV4
                dateProps={{
                  ...rest,
                }}
                textProps={{
                  header: 'Date of Birth',
                  required: true,
                  headerTypo: ListHead2Typo,
                  labelText: errors.personal1?.dob?.message,
                  error: Boolean(errors.personal1?.dob),
                  placeholder: 'Select Your DOB',
                }}
              />
            );
          }}
        />
      </Stack>
    </VegaContainerV4>
  );
};

export default AddCustomerPersonalDetails1;
