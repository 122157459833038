import { useQuery } from 'react-query';
import { QUERY_NAMES } from '../../enums/commons';
import { getCardListByCustomer } from '../../api/card';
import { CardStatus } from '../../types/card';

export const useCardListQuery = ({
  accountId,
  pageNumber,
  pageSize,
  cardStatusList,
  enabled,
}: {
  accountId: string;
  pageNumber?: number;
  pageSize?: number;
  cardStatusList?: CardStatus[];
  enabled?: boolean;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: [
      QUERY_NAMES.CARD_LIST,
      accountId,
      { pageNumber, pageSize, cardStatusList },
    ],
    queryFn: () =>
      getCardListByCustomer({
        accountId,
        pageNumber: pageNumber || 0,
        pageSize: pageSize || 5,
        cardStatusList,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: enabled && Boolean(accountId),
  });

  return { data: data?.data, ...rest };
};
