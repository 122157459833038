import { Box } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import VegaTabV4 from '../../common/v4/VegaTabV4';
import { QUICK_ACCESS } from '../../../constants/style';
import PhysicalStatementOperations from './PhysicalStatementOperations';
import OffersOperations from './OffersOperations';
import CardBlockOperations from './CardBlockOperations';
import ChargeBackOperations from './ChargeBackOperations';

type OperationsTabValue =
  | 'physical-statement'
  | 'offers'
  | 'card-block'
  | 'chargeback';

const OperationsWrap = () => {
  const [tabValue, setTabValue] =
    useState<OperationsTabValue>('physical-statement');

  const onTabChange = (e: SyntheticEvent, val: OperationsTabValue) => {
    setTabValue(val);
  };

  return (
    <Box pt={3}>
      <VegaHeaderV4 title="Operations" sx={{ borderBottom: 'none', pb: 3 }} />
      <VegaTabV4
        tabData={tabData}
        value={tabValue}
        onChange={onTabChange}
        styles={{
          tabSx: {
            borderBottom: `1px solid ${QUICK_ACCESS.border[50]}`,
            width: '100%',
            px: 3,
          },
          panelSx: {
            p: 3,
          },
        }}
      />
    </Box>
  );
};

const tabData = [
  {
    value: 'physical-statement',
    label: 'Physical Statement',
    children: <PhysicalStatementOperations />,
  },
  {
    value: 'offers',
    label: 'Offers',
    children: <OffersOperations />,
  },
  {
    value: 'card-block',
    label: 'Card Block',
    children: <CardBlockOperations />,
  },
  {
    value: 'chargeback',
    label: 'Charge Back',
    children: <ChargeBackOperations />,
  },
];

export default OperationsWrap;
