/** @format */

import axios, { AxiosResponse } from 'axios';
import { BASE_URL, LEDGER } from './endpoints';
import { LedgerData } from '../types/ledger';
import { PaginatedResponse } from '../utils/api';

const LEDGER_BASE_URL = BASE_URL + LEDGER.BASE;

interface GetPostingeMetaDataForAccountProps {
  accountId: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  pageSize?: number;
}

export async function getLedgerData(
  data: GetPostingeMetaDataForAccountProps
): Promise<AxiosResponse<PaginatedResponse<LedgerData>>> {
  return await axios.get(LEDGER_BASE_URL, {
    params: {
      pageNo: data.page,
      pageSize: data.pageSize,
      accountId: data.accountId,
    },
  });
}

export async function getTcsPostings(accountId: string): Promise<any> {
  try {
    const endpoint =
      LEDGER_BASE_URL + LEDGER.TCS.replace('{accountId}', accountId);
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getTcsSummary(accountId: string): Promise<any> {
  try {
    const endpoint =
      LEDGER_BASE_URL + LEDGER.TCS_SUMMARY.replace('{accountId}', accountId);
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
}
