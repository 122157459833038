import { ISVGProps } from '../../mcc/types/Commons';

const DowloadIcon = (props: ISVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.80957 13.6155C3.07212 13.8803 3.42822 14.029 3.79952 14.029H12.1995C12.5708 14.029 12.9269 13.8803 13.1895 13.6155M8.0003 1.97119V9.97119M8.0003 9.97119L11.2003 6.91442M8.0003 9.97119L4.8003 6.91442"
        stroke="#1047DC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DowloadIcon;
