import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useBinCharter } from '../../../providers/BinCharterProvider';
import { BinType } from '../../../types/card';
import { VegaProgramNetworkType } from '../../../types/program';
import VegaFormInputFieldV2 from '../../common/v2/VegaFormInputField';
import VegaSelectV2, { VegaSelectOptionV2 } from '../../common/v2/VegaSelectV2';
import VegaTextFieldV2 from '../../common/v2/VegaTextFieldV2';
import { getLogoForNetwork } from '../../icon/Icons';
import VegaTextfieldV4 from '../../common/v4/VegaTextfieldV4';
import VegaSelectV4, {
  VegaSelectOptionType,
} from '../../common/v4/VegaSelectV4';

const AddBin = () => {
  const { bin, loading, isAddBinOpen, addBin, isInputValid, onHandleChange } =
    useBinCharter();
  const handleBinChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    if (value.length <= 6) onHandleChange('binValue', value);
  };

  return (
    <>
      <Stack gap={3}>
        <VegaTextfieldV4
          header="Bin Name"
          value={bin?.binName}
          key="binName"
          type={'text'}
          onChange={(e) => onHandleChange('binName', e.target.value)}
          standardWidth={400}
        />
        <VegaTextfieldV4
          header="Bin Value"
          value={bin?.binValue}
          key="binValue"
          type={'number'}
          onChange={(e) => handleBinChange(e)}
          standardWidth={400}
        />
        <VegaSelectV4
          header="Bin Type"
          options={getBinTypeOptions()}
          key="binType"
          value={bin.binType ?? ''}
          onChange={(e) => {
            onHandleChange('binType', e.target.value as string);
          }}
          standardWidth={400}
        />
        <VegaSelectV4
          header="Network"
          options={getNetworkTypeOptions()}
          key="network"
          value={bin.network ?? ''}
          onChange={(e) => {
            onHandleChange('network', e.target.value as string);
          }}
          standardWidth={400}
        />
        <VegaSelectV4
          header="Bin Usage Type"
          options={getBinUsageType()}
          key="productType"
          value={bin.productType ?? ''}
          onChange={(e) => {
            onHandleChange('productType', e.target.value as string);
          }}
          standardWidth={400}
        />
      </Stack>
    </>
  );
};

export default AddBin;

const getBinTypeOptions = () =>
  Object.values(BinType).map((item) => {
    const option: VegaSelectOptionType = {
      id: item,
      name: _.startCase(_.toLower(item)),
    };
    return option;
  });

const getBinUsageType = () =>
  ['CREDIT', 'DEBIT', 'Prepaid- Domestic[IND]', 'Prepaid- Forex'].map(
    (item) => {
      const option: VegaSelectOptionType = {
        id: item,
        name: _.startCase(_.toLower(item)),
      };
      return option;
    }
  );

const getNetworkTypeOptions = () =>
  Object.values(VegaProgramNetworkType).map((item) => {
    const option: VegaSelectOptionType = {
      id: item,
      name: _.startCase(_.toLower(item)),
      icon: getLogoForNetwork(item),
    };
    return option;
  });
