import { Box } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import VegaHeaderV4 from '../../common/v4/VegaHeaderV4';
import VegaTabV4 from '../../common/v4/VegaTabV4';
import AccountHolderAnalytics from './AccountHolder/AccountHolderAnalytics';
import TransactionsAnalytics from './Transactions/TransactionsAnalytics';
import { QUICK_ACCESS } from '../../../constants/style';
import CollectionAnalytics from './Collections/CollectionAnalytics';
import BillingAndRepaymentAnalytics from './Billing/BillingAndRepaymentAnalytics';

type AnalyticsTabValue =
  | 'acc-holder'
  | 'transactions'
  | 'billing'
  | 'collections';

const AnalyticsMainPage = () => {
  const [tabValue, setTabValue] = useState<AnalyticsTabValue>('acc-holder');

  const onTabChange = (e: SyntheticEvent, val: AnalyticsTabValue) => {
    setTabValue(val);
  };

  return (
    <Box pt={3}>
      <VegaHeaderV4 title="Analytics" sx={{ borderBottom: 'none', pb: 3 }} />
      <VegaTabV4
        tabData={tabData}
        value={tabValue}
        onChange={onTabChange}
        styles={{
          tabSx: {
            borderBottom: `1px solid ${QUICK_ACCESS.border[50]}`,
            width: '100%',
            px: 3,
          },
          panelSx: {
            px: 3,
            py: 5,
          },
        }}
      />
    </Box>
  );
};

const tabData = [
  {
    value: 'acc-holder',
    label: 'Account Holder',
    children: <AccountHolderAnalytics />,
  },
  {
    value: 'transactions',
    label: 'Transactions',
    children: <TransactionsAnalytics />,
  },
  {
    value: 'billing',
    label: 'Billing',
    children: <BillingAndRepaymentAnalytics />,
  },
  {
    value: 'collections',
    label: 'Collections',
    children: <CollectionAnalytics />,
  },
];

export default AnalyticsMainPage;
