import { Box, Grid } from '@mui/material';
import FilterPage from './FilterPage';
import Searchbar from '../Support_New/Searchbar';
import NoDataFound from '../Support_New/NoUserFound';
import CustomerLoadingPage from './CustomerLoadingPage';
import SupportLeftContainer from '../Support_New/SupportLeftContainer';
import SupportRightContainer from '../Support_New/SupportRightContainer';
import { useSupport } from '../../../providers/SupportProvider';

export enum SupportStep {
  ACCOUNT = 'ACCOUNT',
  KYC = 'KYC',
  APPLICATIONS = 'APPLICATIONS',
  USER_AUDIT = 'USER_AUDIT',
}

export const supportStepOptions = [
  'ACCOUNT',
  'KYC',
  'APPLICATIONS',
  'USER_AUDIT',
];

const SupportSearch = () => {
  const { filteredCustomer, loading, isEmptyFilter } = useSupport();
  console.log('filteredCustomer', filteredCustomer);

  return (
    <>
      {isEmptyFilter ? (
        <FilterPage />
      ) : (
        <>
          <Searchbar />
          <Box
            sx={{
              height: 'calc(100vh - 165px)',
              p: 2.5,
              overflow: 'auto',
            }}
          >
            {!filteredCustomer ? (
              <NoDataFound text="User does not exist" />
            ) : loading ? (
              <CustomerLoadingPage />
            ) : (
              <Grid sx={{ height: '100%' }} container spacing={2}>
                <Grid
                  // sx={{ height: '100%' }}
                  item
                  xs={12}
                  sm={5}
                  md={4}
                  lg={3}
                  xl={3}
                >
                  <SupportLeftContainer />
                </Grid>
                <Grid
                  sx={{ height: '100%' }}
                  item
                  xs={12}
                  sm={7}
                  md={8}
                  lg={9}
                  xl={9}
                >
                  <SupportRightContainer />
                </Grid>
              </Grid>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default SupportSearch;
