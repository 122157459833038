import { TransactionCode } from '../enums/transaction';
import { CARD_NETWORK } from '../mcc/types/Card';
import { Currency } from './cardRefund';
import { ICustomer } from './customers';

export enum ChargebackType {
  FULL_AMOUNT = 'FULL_AMOUNT',
  PARTIAL_AMOUNT = 'PARTIAL_AMOUNT',
}
export enum ChargebackStatus {
  REQUEST_CREATED = 'REQUEST_CREATED',
  CHARGEBACK_CREATED = 'CHARGEBACK_CREATED',
  REQUEST_CANCELLED = 'REQUEST_CANCELLED',
  CHARGEBACK_CANCELLED = 'CHARGEBACK_CANCELLED',
  CHARGEBACK_ACCEPTED = 'CHARGEBACK_ACCEPTED',
  REJECTED_BY_NETWORK = 'REJECTED_BY_NETWORK',
  FIRST_PRESENTMENT = 'FIRST_PRESENTMENT',
  ISSUER_LOST = 'ISSUER_LOST',
  CHARGEBACK_REINITIATED = 'CHARGEBACK_REINITIATED',
  SECOND_PRESENTMENT = 'SECOND_PRESENTMENT',
  ARBITRATION_PENDING = 'ARBITRATION_PENDING',
  ARBITRATION_ACCEPTED = 'ARBITRATION_ACCEPTED',
  ARBITRATION_DECLINED = 'ARBITRATION_DECLINED',
  ARBITRATION_REJECTED = 'ARBITRATION_REJECTED',
}
export enum TatStatus {
  WITHIN_TAT = 'WITHIN_TAT',
  EXCEEDED_TAT = 'EXCEEDED_TAT',
}
export enum DisputeType {
  CHARGEBACK = 'CHARGEBACK',
}

export enum RemarksType {
  REASON = 'REASON',
  REJECTION_REASON = 'REJECTION_REASON',
  REMARKS = 'REMARKS',
}

export interface ChargebackDto {
  id: string;
  accountId: string;
  programId: string;
  clientId: string;
  transactionId: string;
  network: CARD_NETWORK;
  disputeType: DisputeType;
  chargebackType: ChargebackType;
  currency: Currency;
  transactionAmount: number;
  disputeAmount: number;
  chargebackStatus: ChargebackStatus;
  disputeReasonId: string;
  issue: string;
  remarks: string;
  reason: string;
  rejectionReasonId: string;
  disputeProofs: string[];
  arbitrationProofs: string[];
  acquirerRejectionProofs: string[];
  raisedBy: string;
  tatStatus: TatStatus;
  tatValue: number | null;
  networkClaimId: string;
  customerDto: ICustomer;
  createdAt: string;
  updatedAt: string;
}

export interface ChargebackAuditDto {
  id: string;
  chargebackId: string;
  fromChargebackStatus: ChargebackStatus;
  toChargebackStatus: ChargebackStatus;
  modifiedBy: string;
  remarksType: RemarksType;
  fromChargebackDto: ChargebackDto;
  toChargebackDto: ChargebackDto;
  createdAt: string;
  updatedAt: string;
}
export interface ChargebackDaysLimitDto {
  id: string;
  clientId: string;
  network: CARD_NETWORK;
  transactionCode: TransactionCode;
  days: number;
  createdAt: string;
  updatedAt: string;
}

export interface ChargebackRejectionReasonDto {
  id: string;
  rejectionReasonCode: string;
  reason: string;
  reasonDescription: string;
  createdAt: string;
  updatedAt: string;
}

export interface NetworkChargebackDisputeReasonDto {
  id: string;
  network: CARD_NETWORK;
  disputeReasonCode: string;
  reason: string;
  reasonDescription: string;
  createdAt: string;
  updatedAt: string;
}

export interface AcquirerRejectionRequest {
  chargebackId: string;
  reason: string;
  acquirerRejectionProofs: File[];
  chargebackStatus: ChargebackStatus;
}

export interface AddChargebackAuditRequest {
  chargebackId: string;
  fromChargebackStatus: ChargebackStatus;
  toChargebackStatus: ChargebackStatus;
  modifiedBy: string;
  remarksType: string;
  fromChargebackDto: ChargebackDto;
  toChargebackDto: ChargebackDto;
}

export interface AddChargebackRejectionReasonRequest {
  rejectionReasonCode: string;
  reason: string;
  reasonDescription: string;
}

export interface AddNetworkChargebackDisputeReasonRequest {
  network: CARD_NETWORK;
  disputeReasonCode: string;
  reason: string;
  reasonDescription: string;
}

export interface CreateChargebackRequest {
  clientId: string;
  transactionId: string;
  network: CARD_NETWORK;
  disputeType: DisputeType;
  chargebackType: ChargebackType;
  disputeAmount: number;
  disputeReasonId: string;
  issue: string;
  disputeProofs?: File[] | null;
}

export interface CreateNetworkChargebackRequest {
  chargebackId: string;
  networkClaimId: string;
  remarks: string;
}

export interface FileArbitrationRequest {
  chargebackId: string;
  disputeAmount: number;
  remarks: string;
  arbitrationProofs: File[];
}

export interface ListChargebackAuditRequest {
  chargebackId?: string;
  fromChargebackStatus?: ChargebackStatus;
  toChargebackStatus?: ChargebackStatus;
  modifiedBy?: string;
  pageNumber: number;
  pageSize: number;
}

export interface ListChargebackDaysLimitRequest {
  clientId: string;
  networkList: CARD_NETWORK[];
  transactionCodeList: TransactionCode[];
  days: number;
  pageNumber: number;
  pageSize: number;
}

export interface ListChargebackRejectionReasonRequest {
  rejectionReasonCode?: string;
  pageNumber: number;
  pageSize: number;
}

export interface ListChargebackRequest {
  accountId: string;
  transactionId: string;
  programId: string;
  clientId: string;
  disputeTypeList: DisputeType[];
  chargebackTypeList: ChargebackType[];
  chargebackStatusList: ChargebackStatus[];
  disputeReasonId: string;
  rejectionReasonId: string;
  raisedBy: string;
  tatStatusList: TatStatus[];
  tatValue: number;
  networkClaimId: string;
  parentChargebackId: string;
  pageNumber: number;
  pageSize: number;
}

export interface ListNetworkChargebackDisputeReasonRequest {
  network?: CARD_NETWORK;
  disputeReasonCode?: string;
  pageNumber: number;
  pageSize: number;
}

export interface ReopenChargebackRequest {
  chargebackId: string;
  chargebackStatus: ChargebackStatus;
  remarks: string;
  disputeProofs: File[];
}

export interface UpdateChargebackStatusRequest {
  chargebackId: string;
  status: ChargebackStatus;
  remarksType: RemarksType;
  reason: string;
  remarks: string;
  rejectionReasonId: string;
}
