import { Stack } from '@mui/material';
import { TransactionDto } from '../../../../../../../types/transaction';
import { PageHeadingTypo } from '../../../../../../common/Typography';
import VegaTableV4, {
  ITableDataProps,
} from '../../../../../../common/v4/VegaTableV4';
import { useTransactionChargebackListQuery } from '../../../../../../../store/queries/transactionQueries';
import { getTransactionChargebackStructuredData } from '../../../../supportUtils';
import { useState } from 'react';
import VegaNoResults from '../../../../../../common/VegaNoResults';

interface ITransactionChargebackProps {
  data: TransactionDto | undefined;
}

const TransactionChargeback = ({ data }: ITransactionChargebackProps) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const { data: chargeBackData, isFetching } =
    useTransactionChargebackListQuery({
      transactionId: data?.id || '',
      pageNumber: page,
      pageSize,
    });

  const records = chargeBackData?.records;

  const structuredTableData = getTransactionChargebackStructuredData(records);

  return (
    <Stack gap={1.5}>
      <PageHeadingTypo>Chargeback</PageHeadingTypo>
      {!Boolean(chargeBackData?.records.length) && !isFetching ? (
        <VegaNoResults errorText="No chargeback found" />
      ) : (
        <VegaTableV4
          head={[
            'Chargeback Time',
            'Chargeback ID',
            'Chargeback Request Amount',
            'Status',
          ]}
          data={structuredTableData}
          isLoading={isFetching}
          pagination={{
            rowsPerPage: pageSize,
            setRowsPerPage: setPageSize,
            setPage,
            page,
            totalRecords: chargeBackData?.totalItems || 0,
          }}
        />
      )}
    </Stack>
  );
};

export default TransactionChargeback;
