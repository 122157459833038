/** @format */

import { IVegaClientScope } from '../types/client';

export const DEFAULT_SCOPE: IVegaClientScope = {
  scope: {
    BACK_OFFICE: {
      ALL: {
        ALL: ['EDIT'],
      },
    },
    CLIENT: {
      ALL: {
        ALL: ['EDIT'],
      },
    },
    PROGRAM: {
      ALL: {
        ALL: ['EDIT'],
      },
    },
    SIMULATOR: {
      ALL: {
        ALL: ['EDIT'],
      },
    },
  },
};

export const getParsedScope = (scopeStr: string) => {
  if (!scopeStr) return { ...DEFAULT_SCOPE };
  try {
    console.log('Scope STring here', scopeStr);
    const scopeObj = JSON.parse(scopeStr);
    console.log('Parsed sope obj: ', scopeObj);
    return scopeObj;
  } catch {
    console.log('Failed to parse Scope String, using default scope string');
    return { ...DEFAULT_SCOPE };
  }
};
